import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/loader";
import PaginationComponent from "../../../PaginationComponent";
import { GetPermission, getApi, postApi } from "../../service/employService";
import { toast } from "react-hot-toast";
import MultiSelectAll from "../../../components/multiselectdropdown/multiselect";

const AddNFCEmployees = () => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [loader, setloader] = useState(true)
    const [multinfctag, setmultinfctag] = useState([])
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate();
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX
    const [sortedBy, setSortedBy] = useState("updatedAt")
    const [orderBy, setOrderBy] = useState('desc')
    const employer = JSON.parse(localStorage.getItem("employer"));
    const employerId = employer.user_id;

    const getEmployeesData = async (token) => {
        // const response = await GetEmployees(token, employerId, pagesCount, search, sortedBy, orderBy);
        try {
            const response = await getApi("/get-employee", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

            console.log("🚀  file: epmloyer.js:25  getEmployeesData ~ response:", response)
            if (response.status == true) {
                setTimeout(() => {
                    // setEmployee(response.data);
                    setDataArray(response.data);
                    setTotalPagesCount(response.allEmployeeCount)
                    setloader(false)
                }, 1000);
                setloader(true)
                //setTotalPagesCount(response.data["count"])
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else if (response.message == "Access denied! you're not allowed to access this tab") {
                navigate('/employer/dashboard')
            }
            else {
                console.log("get employees data response", response);
                toast.error(response.message)

            }
        } catch (error) {
            if (error.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
                console.log(error)
            }
        }

    }

    useEffect(() => {
        // setpagesCount(1);
        getEmployeesData(employer.employer_token);
    }, [pagesCount, search, sortedBy, orderBy]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const [NFCdetail, setNFCdetail] = useState('')
    const GetNFC = async (token) => {
        try {
            // const response = await GetNFCDetails(token);
            const response = await getApi(`/get-nfc-list`);

            // console.log('response', response);
            console.log(response.data.length)
            if (response.status == true) {
                setNFCdetail(response.data);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: GetNFC.js:66 ~ error:", error)
        }
    }

    useEffect(() => {
        GetNFC(employer.employer_token);
    }, []);

    const [toggle, setToggle] = useState(true)
    const Registration = async (e, customer_id) => {
        // console.log(e , customer_id)
        // let data = {
        //     "isNFC_Active": e == true ? '1' : '0',
        //     "customerId": customer_id,
        // }
        try {
            // 
            // const response = await employeeStatus(employer.employer_token, data)
            const response = await postApi("/edit-employee-nfc-status", {
                isNFC_Active: e == true ? '1' : '0',
                customerId: customer_id,
            })

            console.log('changeStatus', response)
            if (response.status == true) {
                toast.success(response.message)
            }
            //   changeStatusToast();
        } catch (error) {
            console.log(error)
        }
    }
    const handleNavigate = (id) => {
        navigate(`${prefix}/EditEmployer/` + id.user_id, { state: id })
    }

    const handleChangeQueryBuilder = async (e, customer_id) => {

        let data = {
            "customerId": customer_id || multippleArray,
            "nfc_id": e,
            "is_nfc_active": 1
        }
        try {
            // const response = await AssignNFC(employer.employer_token, JSON.stringify(data))
            const response = await postApi("/edit-employee-nfc-detail", {
                customerId: customer_id || multippleArray,
                nfc_id: e,
                is_nfc_active: 1
            })

            getEmployeesData(employer.employer_token);
            if (response.status == true) {
                toast.success(response.message)
                setMultippleArray("")
                setIsChecked(false)
            }

        } catch (error) {
            console.log(error)
        }
    }
    // const handleChangeQueryBuilder = async (selectedOptions, customer_id) => {
    //     const selectedValues = Array.from(selectedOptions)
    //         .filter((option) => option.selected)
    //         .map((option) => option.value);

    //     let data = {
    //         "customerId": customer_id,
    //         "nfc_id": selectedValues, 
    //         "is_nfc_active": 1
    //     };

    //     try {
    //         const response = await AssignNFC(employer.employer_token, JSON.stringify(data));
    //         getEmployeesData(employer.employer_token);
    //         if (response.status === true) {
    //             toast.success(response.message);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    const [multippleArray, setMultippleArray] = useState([]);
    const [isChecked, setIsChecked] = useState(false);


    const selectTransactions = async (id) => {
        let checkArray = await multippleArray;
        let rowIndex = checkArray.findIndex(v => v == id);

        if (rowIndex > -1) {

            //   let newAmount = parseFloat(totalAmoumt) - parseFloat(amount);
            //   setTotalAmount(newAmount)
            checkArray.splice(rowIndex, 1);

        } else {
            checkArray.push(id);
            //   let newAmount = parseFloat(totalAmoumt) + parseFloat(amount)
            //   setTotalAmount(newAmount)
        }

        setMultippleArray(checkArray);
        setDataArray((old_data) => [...old_data]);

        if (dataArray.length == multippleArray.length) {
            setIsChecked(true);
        }
        if (dataArray.length != multippleArray.length) {
            setIsChecked(false);
        }

        // console.log(multippleArray)

    }
    const checheked = (item) => {
        // console.log(multippleArray.includes(item))
        return multippleArray.includes(item) ? 1 : 0;
    }

    function handleCheckClick() {
        if (isChecked == false) {
            let newdata = dataArray.map(v => parseInt(v.customer_id)).join(',')
            let arrayData = newdata.split(',').map(Number);
            let alltagID = NFCdetail.map(v => parseInt(v.nfc_id)).join(',')
            let alltagIDData = alltagID.split(',').map(Number);
            //   let newAmount = (dataArray.reduce((a, v) => a = a + parseFloat(v.dues_amount), 0))
            //   setTotalAmount(newAmount)
            setmultinfctag(() => [...alltagIDData])
            setMultippleArray((old_data) => [...arrayData]);
            setIsChecked(true);
        } else {
            //   setTotalAmount(0)
            setIsChecked(false);
            setMultippleArray([]);
        }
        setDataArray((old_data) => [...old_data]);

    }

    const handleMultiSelectChange = (selectedOptions, i) => {
        setSelectedOptions(selectedOptions);

        console.log("loan_offered", selectedOptions)
        let array = []
        selectedOptions.map((e, i) => {
            array.push({ value: e.value, label: e.label })

        })
        console.log(array, "for testing")

    }
    const optionss = [
        { value: '1', label: 'Business Loan' },
        { value: '2', label: 'Personal Loan' },
        { value: '3', label: 'Loan Against Property' },
    ]
    // console.log(multippleArray, "____________")
    const [permission_data, setPermission_data] = useState([])
    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response.data);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            // toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        if (user_type == "SUB-EMPLOYER") {
            GetPermissions(employer.employer_token);
        }
    }, []);

    // const employerId = employer.user_id;
    const user_type = employer.user_type
    // const role = JSON.parse(employer.role?.permissions)
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);

    } else {

    }

    const [nfc_view, setnfc_view] = useState(false)
    const [nfc_active_inactive, setnfc_active_inactive] = useState(false)

    useEffect(() => {
        if (role) {
            console.log(role, "role")
            const options = role;
            console.log(options, "options")
            options.length > 0 && options.forEach((option) => {
                if (option.key == "nfc_view") {
                    setnfc_view(true);
                }
                if (option.key == "nfc_active_inactive") {
                    setnfc_active_inactive(true);
                }
            });
        }
    }, [role]);

    // console.log(multinfctag , "____________________")
    // console.log(multippleArray , "++++++++++++++++")
    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header my-3 py-4 pb-0">
                    <div className="page-leftheader">
                        <Breadcrumbs order={{ first: { name: "NFC" }, second: { name: "View NFC Employees" } }} />
                        {/* <button className='btn btn-outline-primary'
                            disabled={multippleArray.length > 0 ? false : true} >+ Register For NFC</button> */}
                    </div>

                </div>

                <div className="row">
                    {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
               <div className="form-group">
                  <label className="form-label">Loan Type</label>
                  <div className="row g-xs">
                      <div className="wd-200 mg-b-30">
                          <div className="input-group">
                              <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                  <option>Select Loan</option>
                                  <option value="1">BNPL</option>
                                  <option value="2">Digital Lending</option>
                              </select>
                          </div>
                      </div>
                  </div>
              </div> */}
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        {multippleArray.length > 0 ? <span>
                            <label className="form-label">Assign NFC Tag</label>
                            {/* <select className="form-control text-dark"
                                onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                <option value='0'>Select NFC</option>
                                {NFCdetail.map((option) => {
                                    return (
                                        <option key={option.id} value={option.nfc_id}>{option.site_name}</option>
                                    )
                                })
                                }
                            </select> */}
                            <MultiSelectAll data={NFCdetail} handleChangeQueryBuilder={handleChangeQueryBuilder} />
                        </span>
                            : ""}
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div> </div>
            </div>
            {/* <div className="row">
              </div> 
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
          </div>*/}


            {/* <div className="row">
          <div className="col-sm-12 col-lg-12">
              <div className="card">
                  <div className="card-header br-0">
                      <h3 className="card-title">EMPLOYEE REPORT</h3>
                  </div>
              </div>
          </div>
      </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" /* ref={tableRef} */>
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            {NFCdetail.length > 0 && <th><input type="checkbox" name="customer_id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>}
                                            <th className="font-weight-bold">Employee Details  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                            <th className="font-weight-bold">Employee Code  {sortedBy == "employee_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }}></i>}</th>
                                            {/* {nfc_active_inactive &&   <th className="font-weight-bold">Active/Inactive</th>}
                                         {user_type == "employer" &&   <th className="font-weight-bold">Active/Inactive</th>} */}
                                            <th className="font-weight-bold">Action</th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td />
                                        <div>
                                            <Loader />
                                        </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((option, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    // const { id, customer_id, employee_code, mobile_number,nfc_id, name, email } = option;
                                                    const { id, customer_id, employee_code, mobile_number, name, email, first_name, last_name, nfc_id } = option;
                                                    //    console.log(nfc_id)
                                                    return (
                                                        <tr key={serial_num}>
                                                            {NFCdetail.length > 0 && <td><input type="checkbox" name={customer_id} checked={checheked(customer_id) === 1 ? true : false} onChange={(e) => selectTransactions(customer_id)} /></td>}
                                                            <td> <Link className="text-primary" to={{ pathname: `${prefix}/viewemployee_detail/${customer_id}`, state: { customer_id } }} ><span className="font-weight-normal1">{first_name ? first_name : "NA"} {last_name}<br />{mobile_number}<br />{email}</span></Link></td>
                                                            <td>{employee_code}</td>
                                                            {/* {nfc_active_inactive &&  <td>{option.is_nfc_active == '1' &&
                                                                <div className="col-auto ms-auto d-print-none">
                                                                    <Switch checkedChildren="Active" onClick={(e) => Registration(e, option.customer_id)} unCheckedChildren="Inactive" defaultChecked
                                                                        onChange={(e) => setToggle(e)} />
                                                                    <span>
                                                                    </span>
                                                                </div>}
                                                                {option.is_nfc_active == '0' &&
                                                                    <div className="col-auto ms-auto d-print-none">
                                                                        <Switch checkedChildren="Active" onClick={(e) => Registration(e, option.customer_id)} unCheckedChildren="Inactive"
                                                                            onChange={(e) => setToggle(e)} />
                                                                        <span>
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </td>}
                                                            {user_type == "employer" &&  <td>{option.is_nfc_active == '1' &&
                                                                <div className="col-auto ms-auto d-print-none">
                                                                    <Switch checkedChildren="Active" onClick={(e) => Registration(e, option.customer_id)} unCheckedChildren="Inactive" defaultChecked
                                                                        onChange={(e) => setToggle(e)} />
                                                                    <span>
                                                                    </span>
                                                                </div>}
                                                                {option.is_nfc_active == '0' &&
                                                                    <div className="col-auto ms-auto d-print-none">
                                                                        <Switch checkedChildren="Active" onClick={(e) => Registration(e, option.customer_id)} unCheckedChildren="Inactive"
                                                                            onChange={(e) => setToggle(e)} />
                                                                        <span>
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </td>} */}
                                                            <td>
                                                                {NFCdetail?.length > 0 ? <MultiSelectAll data={NFCdetail} customer_id={customer_id} nfc_id={nfc_id} handleChangeQueryBuilder={handleChangeQueryBuilder} /> : "No NFC Tag Register"}
                                                            </td>
                                                            {/* <select className="form-control mb-0 text-dark" value={option.nfc_id}
                                                                    onChange={(e) => handleChangeQueryBuilder(e.target.value, customer_id)}>
                                                                    <option value='0'>Select NFC</option>
                                                                    {NFCdetail.map((option) => {
                                                                        return (
           
                                                                            <option key={option.id} value={option.nfc_id}>{option.site_name}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </select> */}
                                                            {/* <Select   className="mb-0 text-dark"  value={optionss.value}
                                                                onChange={(e) => handleMultiSelectChange(e, customer_id)}
                                                                options={optionss} isMulti ></Select>*/}

                                                        </tr>
                                                    )
                                                })
                                                } </tbody>

                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody> </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </>
    )
}

export default AddNFCEmployees