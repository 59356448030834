import Header from '../website/header';
import Footer from '../website/footer';
import toast from 'react-hot-toast';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { AccountDeletion, Findmore } from './Service/webservice';
const Accountdelete = () => {

    const Addform = useFormik({
        initialValues: {
            email: "",
            user: "",
            message: "",
            wrong_message: ""

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
            user: yup.string().required('Please Select User Type'),
            message: yup.string().required('Please Enter Message'),
        }),

        onSubmit: async (values, { resetForm }) => {
            const data = JSON.stringify({
                email: values.email,
                user: values.user,
                message: values.message

            });

            const response = await AccountDeletion(data)
            if (response.status == true) {
                console.log('inside ', response);
                toast.success(response.message);
                resetForm({ values: '' });
            } else if (response.status == false) {
                console.log('inside', response);
                toast.error(response.message);
            }
        }
    });

    return (
        <>
            <Header />
            <div className="back-contact pt-120 md-pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 md-mb-30">
                            <div className="contact-img"><img src="./website/assets/img/contact-img.jpg" alt="Contact Image" /></div>
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-form-part">
                                <div className="back-sec-title mb-40 md-mb-30">
                                    <h1 className="text-primary mb-10">Delete Account</h1>
                                    {/* <h2 className="back-title mb-0">Let's get in touch <br />only takes a minute.</h2> */}
                                </div>
                                <div id="form-messages"></div>
                                <form id="contact-form" action="" onSubmit={Addform.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="form-field">
                                                <input type="email" name="email" placeholder="Enter Email" className="form-control" {...Addform.getFieldProps("email")} />
                                                {Addform.touched.email && Addform.errors.email ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{Addform.errors.email}</div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        {/* <label className="form-label">Pay Cycle</label> */}
                                        <div className="row g-xs">
                                            <div className="wd-200 mg-b-30">
                                                <div className="input-group">
                                                    <select className="form-control mb-0" name="user" {...Addform.getFieldProps("user")} >
                                                        <option>Select User Type</option>
                                                        <option value='customer'>Customer (Employee)</option>
                                                        <option value='lender'>Lender</option>
                                                        <option value='employer'>Employer</option>
                                                        <option value='merchant'>Merchant</option>
                                                    </select>
                                                </div>
                                                {Addform.touched.user && Addform.errors.user ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{Addform.errors.user}</div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="form-field">
                                                <textarea placeholder="Your Message" rows="4" id="message" name="message"
                                                    {...Addform.getFieldProps("message")}
                                                    required=""></textarea>
                                                {Addform.touched.message && Addform.errors.message ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{Addform.errors.message}</div> : ''}
                                            </div>
                                            <div className="form-button"><button type="submit" className="back-btn secondary">Submit</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="back-contact-box">
                <div className="container">
                    <div className="back-sec-title text-center mb-50 md-mb-30">
                        <h5 className="back-subtitle white-color">Contact Us</h5>
                        <h2 className="back-title white-color">Get in touch by <br />Selecting an Option.</h2>
                    </div>
                    <div className="row ">
                        <div className="offset-lg-2 wAuto"></div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <div className="contact-box"><span className="icon-part bg-phone"><a href="tel:+800 567.890.576 "><img src="./website/assets/img/phone.svg" alt="Phone Icon" /></a></span><span className="text-part"><a href="tel:+800 567.890.576 ">+61 405 763 625</a></span></div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-box"><span className="icon-part primary-bg"><a href="mailto:support@thirdroc.com"><img src="./website/assets/img/chat.svg" alt="Phone Icon" /></a></span><span className="text-part"><a href="/contact">support@thirdroc.com</a></span></div>
                        </div>
                        <div className="offset-lg-2 wAuto"></div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    )
}

export default Accountdelete;