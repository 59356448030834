import React, { useEffect, useRef, useState } from 'react'
import { EXCEL_BULK_SMS_TEMPLATE } from '../../constant';
import { SampleTemplete } from '../../components/Base64Templete/sampleTemplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GetCountry } from '../../employer/service/employService';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { getApi, postApi } from '../service/adminService';
import PaginationComponent from '../../PaginationComponent';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Search from '../../components/Search/Search';

const ManageSMS = () => {
  const [handleStatus, setHandleStatus] = useState('')
  const [excelFile, setExcelFile] = useState('')
  const [country, setCountry] = useState([]);
  const ref = useRef()
  const [dataArray, setDataArray] = useState([]);
  const [pagesCount, setpagesCount] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(10);
  const [search, setSearch] = useState('');
  const [sortedBy, setSortedBy] = useState("updated_at")
  const [orderBy, setOrderBy] = useState('desc')
  const [ValidPagination, setValidPagination] = useState(false)

  const GetsmsReport = async (id) => {
    try {
      const response = await getApi(`/sms-send-report`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
      if (response.status == true) {
        setDataArray(response.data.rows);
        setTotalPagesCount(response.data.count)
      } else {
        console.log(response.message);
      }

    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    GetsmsReport();
  }, [search, pagesCount, sortedBy, orderBy]);

  const sortChange = (col) => {
    if (col === sortedBy) {
      setSortedBy(col);
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
    } else {
      setSortedBy(col)
      setOrderBy("desc")
    }


  }

  const bulksmsform = useFormik({
    initialValues: {
      send_type: handleStatus,
      country_code: "",
      mobile_number: "",
      file: "",
      message: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      country_code: handleStatus == "single" ? yup.string().required('Select Phone Code') : "",
      mobile_number: handleStatus == "single" ? yup.string().required('Please Enter Mobile Number').min(7, "Mobile Number Must Contain 7 Number").max(13, "Mobile Number Max Contain 13 Number") : "",
      file: handleStatus == "bulk" && !excelFile ? yup.mixed().required("File must be selected").nullable() : yup.mixed().notRequired(),
      message: yup.string().required('Please Enter Message'),

    }),
    onSubmit: async () => {
      const formData = new FormData();
      formData.append("send_type", bulksmsform.getFieldProps('send_type').value);
      formData.append("country_code", bulksmsform.getFieldProps('country_code').value);
      formData.append("mobile_number", bulksmsform.getFieldProps('mobile_number').value);
      formData.append("file", excelFile);
      formData.append("message", bulksmsform.getFieldProps('message').value);
      const response = await postApi("/bulk-sms-send", formData, true)
      if (response.status) {
        console.log(response)
        toast.success(response.message)
        GetsmsReport();
        ref.current.click()

      } else {
        console.log('error', response)
        toast.error(response.message)
      }
    }

  })

  // console.log(bulksmsform.values.file)

  const handlefile = (file) => {
    if (file?.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file?.type == "application/vnd.ms-excel") {
      setExcelFile(file)
    } else {
      setExcelFile("")
      bulksmsform.setFieldValue('file', "")
      toast.error("Invalid file type Please select Excel file only")
    }
  }

  const HandleRemoveFile = () => {
    setExcelFile("")
    bulksmsform.setFieldValue('file', "")
  }

  const GetCountrys = async () => {
    try {

      const response = await GetCountry();
      if (response.status) {
        setCountry(response.data);
      } else {
        console.log(response.message)
        toast.error(response.message)
      }
    } catch (error) {
      toast.error("error :", error);
      console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
    }
  }

  useEffect(() => {
    GetCountrys()
  }, [])

  return (

    <div className="main-container container-fluid px-0">
      <div className="page-header mb-3">
        <div className="page-leftheader">
          <h4 className="page-title mb-0 text-primary">Manage SMS </h4>
        </div>
        <div className="page-rightheader">
          <div className="btn-list">
            <button className="btn btn-primary btn-pill"
              data-bs-toggle="modal" data-bs-target="#staticBackdrop"
              onClick={() => setHandleStatus("single")}>
              <i className="fa-solid fa-plus"></i> Single SMS</button>
            <button className="btn btn-primary btn-pill"
              data-bs-toggle="modal" data-bs-target="#staticBackdrop"
              onClick={() => setHandleStatus("bulk")}>
              <i className="fa-solid fa-plus"></i> Bulk SMS</button>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
          <div className="form-group">
            <label className="form-label">Search</label>
            <div className="row g-xs">
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
              </div>
            </div>
          </div>
        </div> */}
        <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive" >
                <table className="table table-hover card-table table-vcenter text-nowrap">
                  <thead className="border-bottom-0 pt-3 pb-3">
                    <tr>
                      <th className="font-weight-bold">Country Code   {sortedBy == "country_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('country_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('country_code') }}></i>}</th>
                      <th className="font-weight-bold">Mobile Number  {sortedBy == "mobile_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }}></i>}</th>
                      <th className="font-weight-bold">Sent Type  {sortedBy == "sent_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('sent_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('sent_type') }}></i>}</th>
                      <th className="font-weight-bold">Message  {sortedBy == "message" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('message') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('message') }}></i>}</th>
                      <th className="font-weight-bold">status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>}</th>
                      <th className="font-weight-bold">Response Message  {sortedBy == "response" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('response') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('response') }}></i>}</th>
                      <th className="font-weight-bold">Created Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }}></i>}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
                      let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                      const { country_code, mobile_number, sent_type, message, status, response } = option;
                      return (
                        <tr key={serial_num} >
                          <td><span className="font-weight-normal1">{country_code ? country_code : "--"}</span></td>
                          <td><span className="font-weight-normal1">{mobile_number ? mobile_number : "--"}</span></td>
                          <td><span className="font-weight-normal1">{sent_type ? sent_type : ""}</span></td>
                          <td><span className="font-weight-normal1 dropdown-item5">{message ? message : "--"}</span></td>
                          <td><span className={`font-weight-bold ${status === "Success" ? 'text-primary' : 'text-danger'}`}>{status ? status : "--"}</span></td>
                          <td><span className="font-weight-normal1 dropdown-item5">{response ? response : "--"}</span></td>
                          <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                        </tr>
                      )
                    }
                    )}
                  </tbody>
                  <tbody>
                    {dataArray.length == 0 &&
                      <tr>
                        <td colSpan={10} className='text-center'>No Messages Available</td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
              <>
                <div className="col-md-12">
                  <div className="card-body">
                    <PaginationComponent className="justify-content-center"
                      totalItems={totalPagesCount}
                      pageSize={10}
                      maxPaginationNumbers={3}
                      onSelect={(e) => setpagesCount(e)}
                    />
                  </div>
                </div>
              </>
              : ''}
            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
              <>
                <div className="col-md-12">
                  <div className="card-body">
                    <PaginationComponent className="justify-content-center"
                      totalItems={totalPagesCount}
                      pageSize={10}
                      maxPaginationNumbers={3}
                      onSelect={(e) => setpagesCount(e)}
                    />
                  </div>
                </div>
              </>
              : ''}
          </div>
        </div></div>


      {/************************  SINGLE/BULK UPLOAD MODAL START ************************/}

      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{handleStatus == "single" ? "Single SMS" : "Bulk SMS"} </h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              {handleStatus == "bulk" &&
                <button onClick={() => SampleTemplete(EXCEL_BULK_SMS_TEMPLATE, 'Bulk Upload SMS template.xlsx')}
                  className="btn btn-primary btn-pill">
                  <i className="fa fa-download me-2"></i>Sample Template</button>}
            </div>
            <form onSubmit={bulksmsform.handleSubmit}>
              <div className="modal-body">
                {handleStatus == "single" && <div className='row'>
                  <div className="col-md-6 mb-2">
                    <label className="col-form-label">Phone Code </label>
                    <select className="form-control mb-0" name="country_code"  {...bulksmsform.getFieldProps('country_code')} >
                      <option>Select Phone Code</option>
                      {country.map((e) => {
                        return (
                          <option key={e.id} value={e.phone_code}>{e.phone_code}</option>
                        )
                      })
                      }
                    </select>

                    {bulksmsform.touched.country_code && bulksmsform.errors.country_code ?
                      <div className="invalid-feedback" style={{ display: "block", marginTop: '10px' }}>{bulksmsform.errors.country_code}</div> : ''}
                  </div>
                  <div className="col-md-6 mb-2">
                    <label className="col-form-label">Mobile Number:</label>
                    <input type='number' className='form-control' {...bulksmsform.getFieldProps('mobile_number')}
                      placeholder='Enter Borrower Mobile No.'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                          e.preventDefault();
                        }
                      }}
                    />
                    {bulksmsform.touched.mobile_number && bulksmsform.errors.mobile_number ?
                      <div className="invalid-feedback" style={{ display: "block" }}>{bulksmsform.errors.mobile_number}</div> : ''}
                  </div>
                </div>}
                {handleStatus == "bulk" && <div className="mb-3">
                  <label className="col-form-label">File:</label>
                  <input type='file' className='form-control' placeholder='Select Excel File'
                    {...bulksmsform.getFieldProps('file')}
                    onChange={(e) => { setExcelFile(e.target.files[0]); handlefile(e.target.files[0]) }} />
                  {excelFile.type && (
                    <div className="position-relative m-1" style={{ display: 'inline-block' }}>
                      <img
                        src="/assets/img/excel.webp"
                        style={{ width: '70px', height: '80px', border: '1px solid silver' }}
                        className="card-img-top"
                        alt="PDF"
                      />
                      <Link className="upload__img-close position-absolute" onClick={() => { HandleRemoveFile() }} style={{ top: '0px', right: '0px' }}>
                        <i

                          className="fa-solid fa-close fa-fw text-dark"

                        ></i>
                      </Link>
                    </div>
                  )}

                  {bulksmsform.touched.file && bulksmsform.errors.file ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{bulksmsform.errors.file}</div> : ''}
                </div>}
                <div className="mb-3">
                  <label className="col-form-label">Text Message</label>
                  <textarea className='form-control' placeholder='Enter Text Here...'
                    cols={1}
                    {...bulksmsform.getFieldProps('message')} />
                  {bulksmsform.touched.message && bulksmsform.errors.message ?
                    <div className="invalid-feedback" style={{ display: "block" }}>{bulksmsform.errors.message}</div> : ''}
                </div>
              </div>
              <div className="modal-footer">
                <button ref={ref} type="button" className="btn btn-dark"
                  onClick={() => { bulksmsform.resetForm(); HandleRemoveFile() }}
                  data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Send</button>
              </div>
            </form>

          </div>
        </div>
      </div>

      {/************************ SINGLE/BULK UPLOAD MODAL END ***************************/}
    </div>
  )
}

export default ManageSMS
