import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../components';
import { GetPermission, getApi } from '../../service/employService';
import Loader from '../../../components/Loader/loader';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import PaginationComponent from '../../../PaginationComponent';
import { TimeZone } from '../../../components/TimeZone/TimeZone';

const Paidtransaction = () => {
    const [copied, setCopied] = useState(false);
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [functioncheck, setfunctioncheck] = useState()
    const [loader, setloader] = useState(true)
    const employer = JSON.parse(localStorage.getItem("employer"));
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate();
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const getsettlementData = async (token) => {
        try {
            // const response = await GetpaidEWAtransaction(token, pagesCount, search, sortedBy, orderBy);
            const response = await getApi("/ewa-payment-history", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });


            console.log(response)
            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    setloader(false)
                }, 2000);
                setloader(true)
                //setTotalPagesCount(response.data["count"])
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else if (response.message == "Access denied! you're not allowed to access this tab") {
                navigate('/employer/dashboard')
                toast.error(response.message)
            }
            else {
                console.log("get employees data response", response);
                toast.error(response.message)
            }
        } catch (error) {
            if (error.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }

    }

    useEffect(() => {
        getsettlementData(employer.employer_token, pagesCount, search, sortedBy, orderBy)
    }, [pagesCount, search, sortedBy, orderBy])


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // getEmployeesData(employer.employer_token);
    }

    const setCopiedy = () => {
        toast.success("Copied")
    }
    const [permission_data, setPermission_data] = useState([])
    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response.data);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            // toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        if (user_type == "SUB-EMPLOYER") {
            GetPermissions(employer.employer_token);
        }
    }, []);

    // const employerId = employer.user_id;
    const user_type = employer.user_type
    // const role = JSON.parse(employer.role?.permissions)
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);

    } else {

    }

    const [settlement_ewa_transaction, setsettlement_ewa_transaction] = useState(false)
    const [settlement_transaction_export, setsettlement_transaction_export] = useState(false)


    useEffect(() => {
        if (role) {
            console.log(role, "role")
            const options = role;
            console.log(options, "options")
            options.length > 0 && options.forEach((option) => {
                if (option.key == "settlement_ewa_transaction") {
                    setsettlement_ewa_transaction(true);
                }

                if (option.key == "settlement_transaction_export") {
                    setsettlement_transaction_export(true);
                }
            });
        }
    }, [role]);
    return (
        <div>
            <div className="main-container container-fluid px-0">
                <div className="page-header my-3 py-4 pb-0">
                    <div className="page-leftheader">
                        <Breadcrumbs order={{ first: { name: "Settlement" }, second: { name: "EWA Transaction" } }} />
                    </div>
                    <div style={{ marginLeft: '450px', marginTop: '40px' }}>
                        {/* <h4 className="ml-2">{totalAmoumt}</h4> */}
                        {/* <h5 > {totalAmoumt ? `Total: $${num2}` : ""}</h5> */}
                    </div>

                    <div className="page-rightheader">
                        <div className="btn-list">
                            {/* <button className='btn btn-outline-primary mt-3' data-bs-target="#add_settlement"
               data-bs-toggle="modal"
          disabled={multippleArray.length > 0 ? false : true} >+ Pay to Lender</button> */}
                            {/* <Link to={prefix + "/add_employer"} className="btn btn-outline-primary mt-3" ><i className="fa fa-plus me-2"></i> Add Employee</Link> */}
                            {/* <CSVLink {...csvReport}  className="btn btn-outline-primary mt-3"><i className="fa fa-download me-2"></i>Import CSV</CSVLink>  */}

                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        {/* <div className="form-group">
  return (
    <>
    {loader == true ?                                                       
        <div    style={{ marginLeft: '400px'  , marginTop: '200px'}}  >
        <Oval
                height={150}
                width={120}              
                color="#4239a4"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="white"
                strokeWidth={2}
                strokeWidthSecondary={2}
                
                /> </div> :
       <div className="main-container container-fluid px-0">
          <div className="page-header my-3 py-4 pb-0">
              <div className="page-leftheader">
                  <h4 className="page-title mb-0 text-primary">Settlement</h4>
              </div>
              <div style={{ marginLeft: '450px',marginTop:'25px' }}>        
        {/* <h4 className="ml-2">{totalAmoumt}</h4> */}
                        {/* <h5 > {totalAmoumt ? `Total: $${num2}` : ""}</h5> */}
                    </div>

                    <div className="page-rightheader">
                        <div className="btn-list">
                            {/* <button className='btn btn-outline-primary mt-3' data-bs-target="#add_settlement"
               data-bs-toggle="modal"
          disabled={multippleArray.length > 0 ? false : true} >+ Pay to Lender</button> */}
                            {/* <Link to={prefix + "/add_employer"} className="btn btn-outline-primary mt-3" ><i className="fa fa-plus me-2"></i> Add Employee</Link> */}
                            {/* <CSVLink {...csvReport}  className="btn btn-outline-primary mt-3"><i className="fa fa-download me-2"></i>Import CSV</CSVLink>  */}

                        </div>
                    </div>

                </div>
                <div className="row">
                    {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                   <div className="form-group">
                      <label className="form-label">Loan Type</label>
                      <div className="row g-xs">
                          <div className="wd-200 mg-b-30">
                              <div className="input-group">
                                  <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                      <option>Select Loan</option>
                                      <option value="1">BNPL</option>
                                      <option value="2">Digital Lending</option>
                                  </select>
                              </div>
                          </div>
                      </div>
                  </div> */}
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div> </div>
            </div>
            {/* <div className="row">
                  </div> 
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              </div>*/}


            {/* <div className="row">
              <div className="col-sm-12 col-lg-12">
                  <div className="card">
                      <div className="card-header br-0">
                          <h3 className="card-title">EMPLOYEE REPORT</h3>
                      </div>
                  </div>
              </div>
          </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Transaction No.  {sortedBy == "txn_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_number') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Payment Mode  {sortedBy == "payment_mode" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('payment_mode') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('payment_mode') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Amount   {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('amount') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Comment  {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('comment') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Payment Date  {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">View Settlements </th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td /><td />
                                        <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                            <Loader />
                                        </div> </tbody> :
                                        <>

                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { id, txn_number, payment_txn_number, payment_mode, comment, updated_at, amount } = data;
                                                    // var timezone = new Date(data.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });

                                                    return (
                                                        <tr key={serial_num}>
                                                            <td>{txn_number ? txn_number : "NA"}
                                                                <span>
                                                                    <CopyToClipboard text={data.txn_number} onCopy={() => setCopiedy(true)}>
                                                                        <button className='btn btn-primary btn-sm ms-3'><i className="fa-solid fa-copy"></i></button>
                                                                    </CopyToClipboard>
                                                                </span>
                                                            </td>
                                                            <td>{payment_mode ? payment_mode : "NA"}</td>
                                                            <td>{amount ? "$" + amount : "NA"}</td>
                                                            <td>{comment ? comment : "NA"}</td>
                                                            <td><span className="font-weight-normal1">{TimeZone(data.updated_at).timeZone}</span></td>
                                                            <td><span className="font-weight-normal1">
                                                                <Link to={{ pathname: prefix + `/ewa_paidhistory/${data.id}`, state: { id } }} className="btn btn-outline-primary me-3">View All Settlements</Link>

                                                                {settlement_transaction_export && <a href={exportfix + `/export-ewa-settlement/${data.id} `} className="btn btn-outline-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>}
                                                                {user_type == "employer" && <a href={exportfix + `/export-ewa-settlement/${data.id} `} className="btn btn-outline-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>}

                                                            </span></td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>
        </div>
    )
}

export default Paidtransaction
