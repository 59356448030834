import React from 'react'

const Income = ({ employeeDetail }) => {
    const annualSalary = parseFloat(employeeDetail?.annual_salary ? employeeDetail?.annual_salary : 0);
    const otherIncome = parseFloat(employeeDetail?.other_income ? employeeDetail?.other_income : 0);

    const Total_Monthly_Income = ((annualSalary / 12) + otherIncome).toFixed(2);

    return (
        <div style={{ marginTop: "30px" }}>
            <h3>Income</h3>
            <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                <div className="media-list p-0">

                    <div className="media py-4 mt-0">
                        <div className="media-body">
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt " /* style={{verticalAlign: "text-top" , marginTop: "-2px"}} */></i> </div>
                                <div className="w-70"> <label>Pay Cycle : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.pay_cycle ? employeeDetail?.pay_cycle : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt " /* style={{verticalAlign: "text-top" , marginTop: "-2px"}} */></i> </div>
                                <div className="w-70"> <label>Annual Salary : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.annual_salary ? "$" + employeeDetail?.annual_salary : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                <div className="w-70"> <label>Other Monthly Income : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.other_income ? "$" + employeeDetail?.other_income : '--'}</span> </div>
                            </div>
                        </div>
                    </div>
                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                <div className="w-70"> <label>Total Monthly Salary:</label> <span className="font-weight-normal1 fs-14">
                                    {/* {employeeDetail?.monthly_income ? "$" + employeeDetail?.monthly_income : '--'} */}
                                    {Total_Monthly_Income ? "$" + Total_Monthly_Income : '--'}
                                </span> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Income