const TalktoUS = async (data) => {
    // const check = process.env.REACT_APP_WEB_PRIFIX 
    // console.log(check)
    try {
        let requestOptions = {
            method: 'POST',
            body: data,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },


            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + "/web" + "/talk-to-us", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: webService.js:13 ~ TalktoUs ~ error:", error)
    }
}

const Findmore = async (data) => {
    // const check = process.env.REACT_APP_WEB_PRIFIX 
    // console.log(check)
    try {
        let requestOptions = {
            method: 'POST',
            body: data,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },


            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + "/web" + "/find-out-more", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: webService.js:13 ~ TalktoUs ~ error:", error)
    }
}

const AccountDeletion = async (data) => {
    try {
        let requestOptions = {
            method: 'POST',
            body: data,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + "/web" + "/account-delete-request", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: webService.js:13 ~ TalktoUs ~ error:", error)
    }
}

const GetAgreementdoc = async (data) => {
    try {

        let requestOptions = {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + "/web" + `/agreement-doc?mobile_number=${data}`, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:39 ~ error:GetTransferRequest", error)
    }

}


export { TalktoUS, Findmore, AccountDeletion, GetAgreementdoc }
