import React, { useEffect, useState, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Link, Navigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast'
import moment from 'moment/moment'
import { useDispatch } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { getApi, postApi } from '../../../../service/employService'
import { setPublishShiftData } from '../../../../../Redux/modalerrorReducer'


// ************************************************************************** //
// *********** THIS IS OLD SCHEDULE FOR SECURITY BUSINESS TYPE  *************//
// ************************************************************************ //


export default function Schdular() {

    const calendarRef = useRef(null);
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [dataArray, setDataArray] = useState([]);
    const [apiShift, setApiShift] = useState([]);
    const [shiftList, setShiftList] = useState([]);
    const [currentshift, setCurrentshift] = useState([])
    const [puslishShift, setPuslishShift] = useState([])
    const [draftShift, setDraftShift] = useState([])
    const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM'))
    const [sortedBy, setSortedBy] = useState("first_name")
    const [orderBy, setOrderBy] = useState('desc')
    const [search, setSearch] = useState('')
    const closeProfileModal = useRef()
    const [currentExtendedProps, setCurrentExtendedProps] = useState({})
    const [schedulerModal, setSchedulerModal] = useState(false)
    const [editShiftModal, setEditShiftModal] = useState(false)
    const [eventModal, setEventModal] = useState(false)
    const [checkstatus, setCheckStatus] = useState(true)
    const [selectedShiftDate, setSelectedShiftDate] = useState("")
    const [copiedShifts, setCopiedShifts] = useState([])
    const [editShifts, setEditShifts] = useState({})
    const [selectedTeamMember, setSelectedTeamMember] = useState([])
    const [useDBShifts, setUseDBShifts] = useState([])
    const [viewTeamMember, SetViewTeamMember] = useState()
    const [droppedItem, setDroppedItem] = useState(null)
    const [currentCustomerID, setCurrentCustomerID] = useState("")
    const [dragStart, setDragStart] = useState(false)
    const [viewSkills, setViewSkills] = useState([]);
    const dispatch = useDispatch()
    const [viewDate, setViewDate] = useState("")
    // localStorage.setItem('publish', JSON.stringify(puslishShift));

    // useEffect(() => {
    //     // Set minimum date using jQuery
    //     $('.fc-datepicker').daterangepicker({
    //         minDate: new Date()
    //     });
    // }, []);

    const getEmployeesData = async (token) => {
        const response = await getApi("/employee-list-v2", { search: search, sortby: sortedBy, orderby: orderBy });
        try {
            if (response.status == true) {

                setDataArray(response.data);     //setTotalPagesCount(response.data["count"])
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                Navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
            }
        }
    }

    useEffect(() => {
        getEmployeesData();
    }, [sortedBy, orderBy, search]);

    const handlecloseModal = () => {
        closeProfileModal.current.click()
    }

    const getEmployeeDetailByID = async (id) => {
        const response = await getApi(`/employee-detail-v2/${id}`);
        // console.log(response)
        try {
            if (response.status == true) {
                SetViewTeamMember(response.data);
                if (response?.data?.skills) {
                    setViewSkills(JSON.parse(response?.data?.skills));
                }
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                Navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
            }
        }
    }

    function handleDateSelect(e) {
        setSchedulerModal(true)
        setSelectedShiftDate(moment(e.startStr).format('YYYY-MM-DD'))

        const eventsToCopy = shiftList.filter(event => event.start === moment(e.startStr).format('YYYY-MM-DD'))
        console.log("🚀 ~ handleDateSelect ~ eventsToCopy:", eventsToCopy)

        setUseDBShifts(eventsToCopy)

    }

    const handleEventClick = (clickInfo) => {
        setCurrentExtendedProps({ selectedDate: moment(clickInfo.event.start).format("YYYY-MM-DD"), data: clickInfo.event._def.extendedProps })
        setEditShifts({ selectedDate: moment(clickInfo.event.start).format("YYYY-MM-DD"), data: clickInfo.event._def.extendedProps })
        setEventModal(true)
    };

    const GetAllShift = async () => {
        try {
            const response = await getApi("/get-all-shift");

            if (response.status) {
                setCurrentshift(response.data.rows);

                const updatedShiftArray = response.data.rows.map(shift => ({
                    ...shift,
                    teamMemberArray: []
                }))

                setCurrentshift(updatedShiftArray);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetAllShift();
    }, []);

    const handleDragStart = (event, dataObj) => {
        setDragStart(true)
        event.dataTransfer.setData('text/plain', JSON.stringify(dataObj));
    };

    const handleDrop = (event, data, edit = false) => {
        event.preventDefault();

        const droppedData = JSON.parse(event.dataTransfer.getData('text/plain'));
        setDroppedItem(droppedData);

        if (edit) {

            setEditShifts({ ...editShifts, data: { ...editShifts.data, teamMemberArray: [...editShifts.data.teamMemberArray, droppedData] } })
        }
        else {

            const updatedShiftArray = currentshift.map(shift => (
                shift.id == data.id ? { ...shift, teamMemberArray: [...shift.teamMemberArray, droppedData] } : shift
            ))

            setCurrentshift(updatedShiftArray);
        }

        setDragStart(false)
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handlePublishShift = async () => {

        const createCustomerShifts = async () => {
            try {
                const response = await postApi('/create-customer-shift', {
                    shiftDataArray: puslishShift
                })

                if (response.status) {

                    setSelectedTeamMember([])
                    setPuslishShift([])
                    setDraftShift([])
                    setSchedulerModal(false)
                    GetMonthShiftList()
                    toast.success(response.message)
                    setViewDate('')
                }
            } catch (error) {
                console.log(error)
            }
        }

        createCustomerShifts()
    }


    const GetMonthShiftList = async () => {
        try {
            const response = await getApi(`/current-month-shifts?current_month=${currentMonth}`);

            if (response.status) {
                setCheckStatus(!checkstatus)
                setApiShift(response?.data)
                const formattedEvents = response?.data?.flatMap(event => (
                    event.shiftArray.map(shift => ({
                        // id: event.id,
                        title: shift.shift_name,
                        start: event.shift_date,
                        extendedProps: shift,
                        isPublished: true
                    }))
                ));
                setShiftList(formattedEvents);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetMonthShiftList();
    }, [currentMonth]);


    const eventContent = (eventInfo) => {
        const { event } = eventInfo;
        const isPublished = event._def.extendedProps.isPublished

        return (
            <div className={isPublished ? 'bg-success text-center' : 'bg-warning text-center'}>{event.title}</div>
        );
    }

    const dateContent = ({ date, view }) => {

        const copyAllShiftsForTheDay = () => {

            setSchedulerModal(false)

            const publishedEventsToCopy = shiftList.filter(event => event.start === selectedShiftDate);
            const draftEventsToCopy = draftShift.filter(event => event.start === selectedShiftDate);
            setCopiedShifts([...publishedEventsToCopy, ...draftEventsToCopy])

            console.log("🚀 ~ copyAllShiftsForTheDay ~ eventsToCopy:", [...publishedEventsToCopy, ...draftEventsToCopy])
            console.log("🚀 ~ copyAllShiftsForTheDay ~ puslishShift:", puslishShift)

            toast.success("Copied All Shifts")

            // navigator.clipboard.writeText("Hello World!")
            //     .then(() => {
            //         toast.success("Copied All Shifts")
            //     })
            //     .catch((error) => {
            //         toast.error("Copied All Shifts for")
            //         console.error('Error copying text to clipboard:', error);
            //     });
        }

        return (

            <div className="d-flex justify-content-between align-items-center">
                <div className="me-5">{date.getDate()}</div>
                <div className="copy-icon-container" onClick={() => copyAllShiftsForTheDay()} role="button">
                    <i className="fa-solid fa-copy text-primary"></i>

                </div>
            </div>
        );
    };

    const handleDatesSet = (arg) => {
        const calendarApi = calendarRef.current?.getApi();

        if (calendarApi) {
            setCurrentMonth(moment(calendarApi.getDate()).format("YYYY-MM"))
            console.log("🚀 ~ handleDatesSet ~ :", moment(calendarApi.getDate()).format("YYYY-MM"))
        }
    };

    const handleDatePicker = (e, picker) => {
        const startDate = moment(picker.startDate)
        const endDate = moment(picker.endDate)
        // const newStartDate = picker.startDate.format('YYYY-MM-DD');
        // const newEndDate = picker.endDate.format('YYYY-MM-DD');
        // setViewDate(newStartDate + ' - ' + newEndDate)
        // picker.element.val(newStartDate + ' - ' + newEndDate);
        const datesArray = []

        while (startDate <= endDate) {
            datesArray.push(startDate.format('YYYY-MM-DD'));
            startDate.add(1, 'days');
        }


        const addedCopiedShifts = datesArray.flatMap(date => {
            return copiedShifts.map(event => ({
                ...event,
                start: date,
                isPublished: false
            }));
        })

        const updatedDataPublishArray = datesArray.flatMap(date => {
            return copiedShifts.map(event => ({
                ...event.extendedProps,
                id: event.extendedProps?.shift_id ? event.extendedProps.shift_id : event.extendedProps?.id,
                shift_date: date,
                isPublished: false
            }));
        })

        // console.log("🚀 ~ updatedDataPublishArray ~ copiedShifts:", copiedShifts)
        // console.log("🚀 ~ updatedDataPublishArray ~ updatedDataPublishArray:", updatedDataPublishArray)


        setPuslishShift([...puslishShift, updatedDataPublishArray])
        setDraftShift([...draftShift, ...addedCopiedShifts])
        // setShiftList([...shiftList, ...addedCopiedShifts])

        setCheckStatus(!checkstatus)
        dispatch(setPublishShiftData(updatedDataPublishArray));

    }
    // const handleCallbackOnCancel = (event, picker) => {
    //     picker.element.val('');
    //     setViewDate('')

    // }

    // const today = new Date();
    // const minDate = today.toISOString().split('T')[0];

    // console.log(minDate, "***************")

    return (
        <div className="main-container container-fluid px-0 mt-5">

            <div className='demo-app'>
                <div className="d-flex justify-content-between page-leftheader">
                    <h5 className="page-title mb-0 text-primary">WFM / Schedule</h5>
                    <div className='d-flex'>
                        <DateRangePicker
                            onApply={handleDatePicker}
                            // onCancel={handleCallbackOnCancel}
                            initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                            <input
                                placeholder="Search Dates to copy"
                                className="form-control fc-datepicker hasDatepicker mx-2"
                                type="text"
                                // value={viewDate}
                                style={{ cursor: 'pointer' }}
                            />
                        </DateRangePicker>
                        <button
                            className="btn btn-primary"
                            style={{ height: '3rem' }}
                            onClick={() => {
                                handlePublishShift();
                                dispatch(setPublishShiftData([]));
                            }}

                            disabled={puslishShift.length === 0}
                        >Publish</button>

                    </div>
                </div>
                <div className='row mt-2'>

                    <div className='col-md-3'>
                        <span>Search</span> <br />
                        <div className='d-flex align-items-center my-2'>
                            <input
                                type="text"
                                className="form-control m-0"
                                style={{ width: "120px", fontSize: "12px", height: "40px", padding: "0px 10px" }}
                                value={search} onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search here..."
                            />

                            <Form.Select aria-label="Default select example" onChange={(e) => { setSortedBy(e.target.value) }}>

                                <option value="first_name">First Name</option>
                                <option value="last_name">Last Name</option>
                                <option value="mobile_number">Mobile Number</option>
                                <option value="">Clear Sorting</option>

                            </Form.Select>

                            <i className={orderBy != 'asc' ? "fa-solid fa-arrow-up-a-z" : "fa-solid fa-arrow-down-z-a"} onClick={e => { orderBy == 'asc' ? setOrderBy("desc") : setOrderBy("asc") }} style={{ fontSize: "20px" }}></i>
                        </div>


                        <div style={{ overflow: 'scroll', height: '650px' }}>
                            <div class="accordion" id="accordionExample">
                                {dataArray.length > 0 ? dataArray.map((e, id) => {
                                    return (
                                        <div key={id} className='accordion-item'>
                                            <div class="accordion-header" id={`heading${id}`}>
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="true" aria-controls={`collapse${id}`}>
                                                    <div className='fas fa-user me-3' style={{ fontSize: "15px" }}></div>
                                                    <div>
                                                        <span style={{ fontSize: "13px" }}>{e.first_name ? e.first_name : "--"}&nbsp;{e?.last_name ? e.last_name : ""}</span> <br />
                                                        <small className='me-3'>{e?.mobile_number ? e.mobile_number : ""}</small>
                                                    </div>
                                                    {/* <div className="ml-auto fas fa-ellipsis-vertical" style={{ fontSize: "20px" }}></div> */}
                                                </button>
                                            </div>
                                            {/* <div id={`collapse${id}`} class={id == 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={`heading${id}`} data-bs-parent="#accordionExample"> */}
                                            <div id={`collapse${id}`} class="accordion-collapse collapse" aria-labelledby={`heading${id}`} data-bs-parent="#accordionExample">
                                                <div class="accordion-body p-3" style={{ fontSize: '12px' }}>
                                                    <i className='fas fa-edit d-flex justify-content-end text-primary'
                                                        onClick={() => { getEmployeeDetailByID(e?.customer_id); setCurrentCustomerID(e?.customer_id) }}
                                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop" />
                                                    <div className='d-flex'>
                                                        <div className='me-2'>
                                                            <p className='m-0 fw-bold text-wrap'>Job</p>
                                                            <p className='m-0 fw-bold text-wrap'>Location</p>
                                                            <p className='m-0 fw-bold text-wrap'>Sub-Location</p>
                                                        </div>
                                                        <div>
                                                            <p className='m-0 text-wrap'>: {e.job_type ? e.job_type : "--"}</p>
                                                            <p className='m-0 text-wrap'>: {e.job_location ? e.job_location : "--"} </p>
                                                            <p className='m-0 text-wrap'>: {e.sub_location ? e.sub_location : "--"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }) :
                                    <div className='d-flex justify-content-center align-items-center mt-5'>No Data Found</div>
                                }
                            </div>
                        </div>
                        {/**************************** View Profile Modal Start *************************/}

                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                            aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4>Team Member Profile</h4>
                                        <Link
                                            className="btn btn-primary"
                                            onClick={handlecloseModal}
                                            // to={{
                                            //     pathname: `${prefix}/edit_employee/${currentCustomerID}`,
                                            //     state: dataArray[0]
                                            // }}
                                            to={`${prefix}/edit_employee/${currentCustomerID}`}
                                            state={{ data: viewTeamMember, from: 'Scheduler' }}
                                        >
                                            Edit Profile
                                        </Link>

                                    </div>
                                    <div className="modal-body" style={{ fontSize: "14px" }}>
                                        <div className="mb-3">
                                            <h5 className='text-primary mb-3'>Personal Details</h5>
                                            <div className='col-md-12 row m-0' style={{ backgroundColor: "rgb(241 241 241)", borderRadius: "15px", padding: "10px 0px", border: "1px solid #2c4891" }}>
                                                <div className="col-md-3">
                                                    <label style={{ color: '#6c6a72' }} >Member Name:</label>

                                                    <h6>{viewTeamMember?.first_name} {viewTeamMember?.last_name}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <label style={{ color: '#6c6a72' }} >Mobile Number</label>
                                                    <h6>{viewTeamMember?.mobile_number}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <label style={{ color: '#6c6a72' }}>Created Date:</label>
                                                    <h6>{viewTeamMember?.created_at ? moment(viewTeamMember.created_at).format('YYYY-MM-DD hh:mm A') : "--"}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <label style={{ color: '#6c6a72' }} >Email:</label>
                                                    <h6>{viewTeamMember?.personal_email}</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <h5 className='text-primary mb-3'>Work Detail</h5>
                                            <div className='col-md-12 row m-0' style={{ backgroundColor: "rgb(241 241 241)", borderRadius: "15px", padding: "10px 0px", border: "1px solid #2c4891" }}>
                                                <div className="col-md-4">
                                                    <div className='' style={{ color: '#6c6a72' }}> Employee Code:</div >
                                                    <h6>{viewTeamMember?.employee_code ? viewTeamMember.employee_code : "--"}</h6>
                                                </div>
                                                <div className="col-md-4">
                                                    <label style={{ color: '#6c6a72' }}>Monthly Salary</label>
                                                    <h6>{viewTeamMember?.salary ? "$" + viewTeamMember.salary : "--"}</h6>
                                                </div>
                                                <div className="col-md-4">
                                                    <label style={{ color: '#6c6a72' }}>Pay Cycle</label>
                                                    <h6>{viewTeamMember?.pay_cycle ? viewTeamMember.pay_cycle : "NA"}</h6>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label style={{ color: '#6c6a72' }}>Country</label>
                                                    <h6>{viewTeamMember?.country_name ? viewTeamMember.country_name : "NA"}</h6>
                                                </div>

                                                <div className="col-md-4 mt-3">
                                                    <label style={{ color: '#6c6a72' }}>Department:</label>
                                                    <h6>{viewTeamMember?.department_name ? viewTeamMember?.department_name : "--"} </h6>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label style={{ color: '#6c6a72' }} >Skills:</label>
                                                    {/* <h6>{viewTeamMember?.skills ? viewTeamMember?.skills.join(', ') : "NA"}</h6> */}
                                                    <h6> {viewTeamMember?.skills && viewTeamMember.skills && viewTeamMember.skills != "null"
                                                        ? JSON.parse(viewTeamMember.skills).join(', ')
                                                        : "NA"}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <h5 className='text-primary mb-3'>Location & Job Type</h5>
                                            <div className='col-md-12 row m-0' style={{ backgroundColor: "rgb(241 241 241)", borderRadius: "15px", padding: "10px 0px", border: "1px solid #2c4891" }}>
                                                <div className="col-md-4">
                                                    <label className='' style={{ color: '#6c6a72' }}> Location:</label >
                                                    <h6>{viewTeamMember?.job_location ? viewTeamMember.job_location : "--"}</h6>
                                                </div>
                                                <div className="col-md-4">
                                                    <label style={{ color: '#6c6a72' }}>Sub Location</label>
                                                    <h6>{viewTeamMember?.sub_location ? viewTeamMember.sub_location : "--"}</h6>
                                                </div>
                                                <div className="col-md-4">
                                                    <label style={{ color: '#6c6a72' }}>Job Type</label>
                                                    <h6>{viewTeamMember?.job_type ? viewTeamMember.job_type : "NA"}</h6>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <h5 className='text-primary mb-3'>Hour & Wages</h5>
                                            <div className='col-md-12 row m-0' style={{ backgroundColor: "rgb(241 241 241)", borderRadius: "15px", padding: "10px 0px", border: "1px solid #2c4891" }}>
                                                <div className="col-md-3">
                                                    <label className='' style={{ color: '#6c6a72' }}> Normal Hour:</label >
                                                    <div className='d-flex' style={{ fontSize: "14px" }}>
                                                        <div className='me-2'>
                                                            <p className='m-0 fw-bold' style={{ lineHeight: "20px" }}>Total Hours</p>
                                                            <p className='m-0 fw-bold' style={{ lineHeight: "20px" }}>Per Hrs Wages</p>
                                                        </div>
                                                        <div>
                                                            <p className='m-0' style={{ lineHeight: "20px" }}>: {viewTeamMember?.NT_total_hrs ? viewTeamMember.NT_total_hrs : "--"}</p>
                                                            <p className='m-0' style={{ lineHeight: "20px" }}>: {viewTeamMember?.NT_per_hrs_wages ? viewTeamMember.NT_per_hrs_wages : "--"}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-3">
                                                    <label className='' style={{ color: '#6c6a72' }}> Time & Half:</label >
                                                    <div className='d-flex' style={{ fontSize: "14px" }}>
                                                        <div className='me-2'>
                                                            <p className='m-0 fw-bold' style={{ lineHeight: "20px" }}>Total Hours</p>
                                                            <p className='m-0 fw-bold' style={{ lineHeight: "20px" }}>Per Hrs Wages</p>
                                                        </div>
                                                        <div>
                                                            <p className='m-0' style={{ lineHeight: "20px" }}>: {viewTeamMember?.TH_total_hrs ? viewTeamMember.TH_total_hrs : "--"}</p>
                                                            <p className='m-0' style={{ lineHeight: "20px" }}>: {viewTeamMember?.TH_per_hrs_wages ? viewTeamMember.TH_per_hrs_wages : "--"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className='' style={{ color: '#6c6a72' }}> Meal:</label >
                                                    <div className='d-flex' style={{ fontSize: "14px" }}>
                                                        <div className='me-2'>
                                                            <p className='m-0 fw-bold' style={{ lineHeight: "20px" }}>Meal Hours</p>
                                                            <p className='m-0 fw-bold' style={{ lineHeight: "20px" }}>Per Meal Rate</p>
                                                        </div>
                                                        <div>
                                                            <p className='m-0' style={{ lineHeight: "20px" }}>: {viewTeamMember?.MEAL_total_hrs ? viewTeamMember.MEAL_total_hrs : "--"}</p>
                                                            <p className='m-0' style={{ lineHeight: "20px" }}>: {viewTeamMember?.MEAL_per_hrs_wages ? viewTeamMember.MEAL_per_hrs_wages : "--"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className='' style={{ color: '#6c6a72' }}> Double Time:</label >
                                                    <div className='d-flex' style={{ fontSize: "14px" }}>
                                                        <div className='me-2'>
                                                            <p className='m-0 fw-bold' style={{ lineHeight: "20px" }}>Total Hours</p>
                                                            <p className='m-0 fw-bold' style={{ lineHeight: "20px" }}>Per Hrs Wages</p>
                                                        </div>
                                                        <div>
                                                            <p className='m-0' style={{ lineHeight: "20px" }}>: {viewTeamMember?.DT_total_hrs ? viewTeamMember.DT_total_hrs : "--"}</p>
                                                            <p className='m-0' style={{ lineHeight: "20px" }}>: {viewTeamMember?.DT_per_hrs_wages ? viewTeamMember.DT_per_hrs_wages : "--"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button ref={closeProfileModal} type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/********************** View Profile Modal End **************************/}

                    </div>

                    <div className='demo-app-main col-md-9'>
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: 'today'
                            }}
                            initialView='dayGridMonth'
                            selectable={true} // ? ******** Add new something on the date using OnChick ***********
                            selectMirror={true}
                            dayMaxEvents={true} // ? ******* Enabel to show the count of shift in a day in month mode ***********
                            // weekends={weekendsVisible}
                            events={[...shiftList, ...draftShift]}
                            // initialEvents={shiftList}
                            select={handleDateSelect}
                            eventClick={handleEventClick}
                            // eventsSet={handleEvents}
                            // eventReceive={handleEventDrop}
                            eventContent={eventContent}
                            dayCellContent={dateContent}
                            datesSet={handleDatesSet}
                        />
                        {/* {checkstatus && typeof (shiftList) == "object" ?
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    left: 'prev,next',
                                    center: 'title',
                                    right: 'today'
                                }}
                                initialView='dayGridMonth'
                                selectable={true} // ? ******** Add new something on the date using OnChick ***********
                                selectMirror={true}
                                dayMaxEvents={true} // ? ******* Enabel to show the count of shift in a day in month mode ***********
                                // weekends={weekendsVisible}
                                // events={shiftList}
                                initialEvents={shiftList}
                                select={handleDateSelect}
                                eventClick={handleEventClick}
                                // eventsSet={handleEvents}
                                // eventReceive={handleEventDrop}
                                eventContent={eventContent}
                                dayCellContent={dateContent}
                                datesSet={handleDatesSet}
                            /> : ""}
                        {!checkstatus && typeof (shiftList) == "object" ?
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    left: 'prev,next',
                                    center: 'title',
                                    right: 'today'
                                }}
                                initialView='dayGridMonth'
                                selectable={true} // ? ******** Add new something on the date using OnChick ***********
                                selectMirror={true}
                                dayMaxEvents={true} // ? ******* Enabel to show the count of shift in a day in month mode ***********
                                // weekends={weekendsVisible}
                                events={shiftList}
                                select={handleDateSelect}
                                eventClick={handleEventClick}
                                // eventsSet={handleEvents}
                                // eventReceive={handleEventDrop}
                                eventContent={eventContent}
                                dayCellContent={dateContent}
                                datesSet={handleDatesSet}
                            /> : ""} */}
                    </div>
                </div>
                {/* </div> */}
            </div>

            {
                schedulerModal &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 text-center" id="staticBackdropLabel">Create Roaster for {selectedShiftDate}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ padding: "0.50rem" }}>

                                    <div className='row '>

                                        <div className='col-md-3'>

                                            <div className='d-flex align-items-center my-2'>
                                                <input
                                                    type="text"
                                                    className="form-control m-0"
                                                    style={{ width: "120px", fontSize: "12px", height: "40px", padding: "0px 10px" }}
                                                    value={search} onChange={(e) => setSearch(e.target.value)}
                                                    placeholder="Search here..."
                                                />

                                                <Form.Select aria-label="Default select example" onChange={(e) => { setSortedBy(e.target.value) }}>

                                                    <option value="first_name">First Name</option>
                                                    <option value="last_name">Last Name</option>
                                                    <option value="mobile_number">Mobile Number</option>
                                                    <option value="">Clear Sorting</option>

                                                </Form.Select>

                                                <i className={orderBy != 'asc' ? "fa-solid fa-arrow-up-a-z" : "fa-solid fa-arrow-down-z-a"} onClick={e => { orderBy == 'asc' ? setOrderBy("desc") : setOrderBy("asc") }} style={{ fontSize: "20px" }}></i>
                                            </div>

                                            <div style={{ overflow: 'scroll', height: '400px' }}>
                                                {dataArray.length > 0 ? dataArray.map((e, id) => {

                                                    const checkIfMemberIsDragable = currentshift?.some(obj => obj.teamMemberArray?.some(member => member.customer_id === e.customer_id)) || useDBShifts?.some(dbItem => dbItem.extendedProps.teamMemberArray?.some(member => member.customer_id === e.customer_id))

                                                    return (
                                                        <>
                                                            <div

                                                                draggable={!checkIfMemberIsDragable}

                                                                onDragStart={(event) => handleDragStart(event, e)}
                                                                key={e.customer_id}
                                                                className='d-flex  align-items-center py-2 mb-2'
                                                                style={{ borderBottom: "1px solid #d1cbcb", cursor: "pointer", userSelect: "none", MozUserSelect: "none", msUserSelect: "none", WebkitUserSelect: "none" }}
                                                            >

                                                                <div className='mx-2'>
                                                                    <div className='fas fa-user mx-3' style={{ fontSize: "20px" }}></div>
                                                                </div>
                                                                <div className='text-start'>
                                                                    <span style={{ fontSize: "13px" }}>{e.first_name ? e.first_name : "--"}&nbsp;{e?.last_name ? e.last_name : ""}</span>
                                                                    <br />
                                                                    <small>{e?.mobile_number ? e.mobile_number : ""}</small> <br />
                                                                    <small>{e.job_type}</small> <br />
                                                                    <small>{e.job_location} {e.sub_location ? `(${e.sub_location})` : ""}</small> <br />
                                                                    {/* currentshift?.some(obj => obj.teamMemberArray?.some(member => member.customer_id === e.customer_id))  */}
                                                                    {checkIfMemberIsDragable &&
                                                                        <small className='text-success'>Shift Assigned</small>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }) :
                                                    <div className='d-flex justify-content-center align-items-center mt-5'>No Data Found</div>
                                                }

                                            </div>
                                        </div>


                                        <div className='col-md-9'>

                                            <div className='d-flex justify-content-center h-100' style={{ overflowX: "scroll" }}>
                                                {currentshift.length > 0 && currentshift.map((data, id) => {

                                                    return (
                                                        <>
                                                            <div className='border border-top-0 border-bottom-0' style={{ padding: "0.50rem", width: "30rem" }}>
                                                                <h6 className='text-center m-0'>{data.shift_name} ({data?.teamMemberArray?.length})</h6>
                                                                <p className='m-0 text-center text-primary' style={{ fontSize: "smaller" }}>{moment(`${selectedShiftDate} ${data.shift_start_time}`).format("HH:mm A")} - {moment(`${selectedShiftDate} ${data.shift_end_time}`).format("HH:mm A")}</p>

                                                                <div
                                                                    onDrop={(e) => handleDrop(e, data)}
                                                                    onDragOver={handleDragOver}
                                                                >
                                                                    {dragStart && <div className='text-center' style={{ padding: '10px', minHeight: '30px', border: "2px solid black", borderStyle: "dashed" }}>
                                                                        Drop it here
                                                                    </div>
                                                                    }
                                                                </div>

                                                                <div style={{ overflow: 'scroll', height: '400px' }}>

                                                                    {data?.teamMemberArray?.length > 0 && data.teamMemberArray.reverse().map((memberData, id) => {
                                                                        return (
                                                                            <>
                                                                                <div key={id} className='d-flex justify-content-between align-items-center py-2 my-2' style={{ borderBottom: "1px solid #d1cbcb" }}>

                                                                                    <div className='d-flex align-items-center'>
                                                                                        <div className='mx-2'>
                                                                                            <div className='fas fa-user mx-3' style={{ fontSize: "20px" }}></div>
                                                                                        </div>
                                                                                        <div className='text-start'>
                                                                                            <span style={{ fontSize: "13px" }}>{memberData.first_name ? memberData.first_name : "--"}&nbsp;{memberData?.last_name ? memberData.last_name : ""}</span> <br />
                                                                                            <small>{memberData?.mobile_number ? memberData.mobile_number : ""}</small> <br />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=''>
                                                                                        <div
                                                                                            className='fas fa-trash mx-3 text-danger'
                                                                                            style={{ fontSize: "20px" }}
                                                                                            onClick={() => {

                                                                                                const updatedCreateShift = currentshift.map(shift => ({
                                                                                                    ...shift,
                                                                                                    teamMemberArray: shift.teamMemberArray.filter(member => member.customer_id !== memberData.customer_id)
                                                                                                }));

                                                                                                setCurrentshift(updatedCreateShift);

                                                                                            }}
                                                                                        ></div>
                                                                                    </div>

                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}

                                                                    {useDBShifts?.length > 0 && useDBShifts?.map((items, id) => {

                                                                        return items?.title == data?.shift_name && items?.extendedProps?.teamMemberArray.length > 0 && items?.extendedProps?.teamMemberArray.map((memberData, id) => {

                                                                            return (
                                                                                <>
                                                                                    <div key={id} className='d-flex justify-content-between align-items-center py-2 my-2' style={{ borderBottom: "1px solid #d1cbcb" }}>

                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='mx-2'>
                                                                                                <div className='fas fa-user mx-3' style={{ fontSize: "20px" }}></div>
                                                                                            </div>
                                                                                            <div className='text-start'>
                                                                                                <span style={{ fontSize: "13px" }}>{memberData.first_name ? memberData.first_name : "--"}&nbsp;{memberData?.last_name ? memberData.last_name : ""}</span> <br />
                                                                                                <small>{memberData?.mobile_number ? memberData.mobile_number : ""}</small> <br />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='text-success text-center me-2 px-1' style={{ fontSize: "12px", border: "1px solid green", borderRadius: "5px" }}>
                                                                                            <span>Already</span> <br />
                                                                                            <span>Published</span>
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-dark" onClick={() => setSchedulerModal(false)}>Cancel</button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            //  dispatch(puslishShift)
                                            const addDateToCurrentShifts = currentshift.map(shift => ({
                                                ...shift,
                                                shift_date: selectedShiftDate
                                            }))


                                            setPuslishShift([...puslishShift, addDateToCurrentShifts])
                                            GetAllShift()
                                            setSchedulerModal(false)
                                            setCheckStatus(!checkstatus)
                                            let updatedApiArray = [
                                                {
                                                    "shift_date": selectedShiftDate,
                                                    "shiftArray": addDateToCurrentShifts.filter(data => data.teamMemberArray.length > 0)
                                                }
                                            ]
                                            dispatch(setPublishShiftData(updatedApiArray));
                                            const formattedEvents = updatedApiArray?.flatMap(event => (
                                                event.shiftArray.map(shift => ({
                                                    // id: event.id,
                                                    title: shift.shift_name,
                                                    start: event.shift_date,
                                                    extendedProps: shift,
                                                    isPublished: false
                                                }))
                                            ));
                                            // setShiftList([...shiftList, ...formattedEvents]);
                                            setDraftShift([...draftShift, ...formattedEvents]);

                                        }}
                                        disabled={currentshift.some(obj => obj.teamMemberArray.length > 0) ? false : true}
                                    >Save Shifts</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-backdrop fade show"></div>
                </>
            }

            {
                eventModal &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 text-center" id="staticBackdropLabel">
                                        {currentExtendedProps?.data?.shift_name} {currentExtendedProps?.selectedDate} ({moment(`${currentExtendedProps?.selectedDate} ${currentExtendedProps?.data?.shift_start_time}`).format("HH:mm A")} to {moment(`${currentExtendedProps?.selectedDate} ${currentExtendedProps?.data?.shift_end_time}`).format("HH:mm A")})
                                    </h1>

                                    <div className='d-flex align-items-center justify-content-center'>
                                        {!currentExtendedProps?.data?.isPublished && <strong className='text-warning'>DRAFT</strong>}
                                        {currentExtendedProps?.data?.isPublished && <strong className='text-success'>PUBLISHED</strong>}
                                        {!currentExtendedProps?.data?.isPublished &&
                                            <button
                                                className='btn btn-primary ms-2'
                                                style={{ height: '2rem' }}
                                                onClick={() => {
                                                    setEditShiftModal(true);
                                                    setEventModal(false);

                                                    const existingDBShifts = shiftList.filter(event => event.start === editShifts.selectedDate && event.isPublished == true)

                                                    setUseDBShifts(existingDBShifts[0]?.extendedProps.teamMemberArray)
                                                }}
                                            >Edit Shift</button>}
                                    </div>

                                </div>
                                <div className="modal-body" style={{ padding: "0.50rem" }}>
                                    <table className="table table-hover card-table table-vcenter text-nowrap">
                                        <thead className="border-bottom">
                                            <tr>
                                                <th className="font-weight-bold">Team Member Name</th>
                                                <th className="font-weight-bold">Mobile Number</th>
                                                <th className="font-weight-bold">JOB Type</th>
                                                <th className="font-weight-bold">JOB Location</th>
                                                <th className="font-weight-bold">SUB Location</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentExtendedProps?.data?.teamMemberArray?.length > 0 && currentExtendedProps.data.teamMemberArray.map((option, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td><span className="font-weight-normal1">{option?.first_name} {option?.last_name}</span> </td>
                                                        <td><span className="font-weight-normal1">{option?.mobile_number}</span></td>
                                                        <td><span className="font-weight-normal1">{option?.job_type}</span></td>
                                                        <td><span className="font-weight-normal1">{option?.job_location}</span></td>
                                                        <td><span className="font-weight-normal1">{option?.sub_location ? option.sub_location : "--"}</span></td>

                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-dark" onClick={() => setEventModal(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-backdrop fade show"></div>
                </>
            }

            {
                editShiftModal &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 text-center" id="staticBackdropLabel">Edit Shift {editShifts.selectedDate}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ padding: "0.50rem" }}>

                                    <div className='row '>

                                        <div className='col-md-3'>

                                            <div className='d-flex align-items-center my-2'>
                                                <input
                                                    type="text"
                                                    className="form-control m-0"
                                                    style={{ width: "120px", fontSize: "12px", height: "40px", padding: "0px 10px" }}
                                                    value={search} onChange={(e) => setSearch(e.target.value)}
                                                    placeholder="Search here..."
                                                />

                                                <Form.Select aria-label="Default select example" onChange={(e) => { setSortedBy(e.target.value) }}>

                                                    <option value="first_name">First Name</option>
                                                    <option value="last_name">Last Name</option>
                                                    <option value="mobile_number">Mobile Number</option>
                                                    <option value="">Clear Sorting</option>

                                                </Form.Select>

                                                <i className={orderBy != 'asc' ? "fa-solid fa-arrow-up-a-z" : "fa-solid fa-arrow-down-z-a"} onClick={e => { orderBy == 'asc' ? setOrderBy("desc") : setOrderBy("asc") }} style={{ fontSize: "20px" }}></i>
                                            </div>

                                            <div style={{ overflow: 'scroll', height: '400px' }}>
                                                {dataArray.length > 0 ? dataArray.map((e, id) => {

                                                    const checkIfMemberIsDragable = editShifts?.data?.teamMemberArray?.some(member => member.customer_id === e.customer_id) || useDBShifts?.some(member => member.customer_id === e.customer_id)

                                                    return (
                                                        <>
                                                            <div
                                                                draggable={!checkIfMemberIsDragable}
                                                                onDragStart={(event) => handleDragStart(event, e)}
                                                                key={e.customer_id}
                                                                className='d-flex  align-items-center py-2 mb-2'
                                                                style={{ borderBottom: "1px solid #d1cbcb", cursor: "pointer", userSelect: "none", MozUserSelect: "none", msUserSelect: "none", WebkitUserSelect: "none" }}
                                                            >

                                                                <div className='mx-2'>
                                                                    <div className='fas fa-user mx-3' style={{ fontSize: "20px" }}></div>
                                                                </div>
                                                                <div className='text-start'>
                                                                    <span style={{ fontSize: "13px" }}>{e.first_name ? e.first_name : "--"}&nbsp;{e?.last_name ? e.last_name : ""}</span>
                                                                    <br />
                                                                    <small>{e?.mobile_number ? e.mobile_number : ""}</small> <br />
                                                                    <small>{e.job_type}</small> <br />
                                                                    <small>{e.job_location} {e.sub_location ? `(${e.sub_location})` : ""}</small> <br />
                                                                    {checkIfMemberIsDragable &&
                                                                        <small className='text-success'>Shift Assigned</small>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }) :
                                                    <div className='d-flex justify-content-center align-items-center mt-5'>No Data Found</div>
                                                }

                                            </div>
                                        </div>


                                        <div className='col-md-9'>

                                            <div className='d-flex justify-content-center h-100' style={{ overflowX: "scroll" }}>

                                                <div className='border border-top-0 border-bottom-0' style={{ padding: "0.50rem", width: "30rem" }}>
                                                    <h6 className='text-center m-0'>{editShifts.data.shift_name} ({editShifts.data?.teamMemberArray?.length})</h6>
                                                    <p className='m-0 text-center text-primary' style={{ fontSize: "smaller" }}>{moment(`${selectedShiftDate} ${editShifts.data.shift_start_time}`).format("HH:mm A")} - {moment(`${selectedShiftDate} ${editShifts.data.shift_end_time}`).format("HH:mm A")}</p>

                                                    <div
                                                        onDrop={(e) => handleDrop(e, editShifts.data, true)}
                                                        onDragOver={handleDragOver}
                                                    >
                                                        {dragStart && <div className='text-center' style={{ padding: '10px', minHeight: '30px', border: "2px solid black", borderStyle: "dashed" }}>
                                                            Drop it here
                                                        </div>
                                                        }
                                                    </div>

                                                    <div style={{ overflow: 'scroll', height: '400px' }}>

                                                        {editShifts.data?.teamMemberArray?.length > 0 && editShifts.data.teamMemberArray.map((memberData, id) => {
                                                            return (
                                                                <>
                                                                    <div key={id} className='d-flex justify-content-between align-items-center py-2 my-2' style={{ borderBottom: "1px solid #d1cbcb" }}>

                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='mx-2'>
                                                                                <div className='fas fa-user mx-3' style={{ fontSize: "20px" }}></div>
                                                                            </div>
                                                                            <div className='text-start'>
                                                                                <span style={{ fontSize: "13px" }}>{memberData.first_name ? memberData.first_name : "--"}&nbsp;{memberData?.last_name ? memberData.last_name : ""}</span> <br />
                                                                                <small>{memberData?.mobile_number ? memberData.mobile_number : ""}</small> <br />
                                                                            </div>
                                                                        </div>

                                                                        <div className=''>
                                                                            <div
                                                                                className='fas fa-trash mx-3 text-danger'
                                                                                style={{ fontSize: "20px" }}
                                                                                onClick={() => {
                                                                                    const updatedEditMemberArray = editShifts?.data?.teamMemberArray?.filter(member => member.customer_id !== memberData.customer_id)
                                                                                    setEditShifts({ ...editShifts, data: { ...editShifts.data, teamMemberArray: updatedEditMemberArray } })

                                                                                }}
                                                                            ></div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        })}

                                                        {useDBShifts?.length > 0 && useDBShifts.map((memberData, id) => {
                                                            console.log("🚀 ~ {useDBShifts?.length>0&&useDBShifts.map ~ useDBShifts:", useDBShifts)
                                                            return (
                                                                <>
                                                                    <div key={id} className='d-flex justify-content-between align-items-center py-2 my-2' style={{ borderBottom: "1px solid #d1cbcb" }}>

                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='mx-2'>
                                                                                <div className='fas fa-user mx-3' style={{ fontSize: "20px" }}></div>
                                                                            </div>
                                                                            <div className='text-start'>
                                                                                <span style={{ fontSize: "13px" }}>{memberData.first_name ? memberData.first_name : "--"}&nbsp;{memberData?.last_name ? memberData.last_name : ""}</span> <br />
                                                                                <small>{memberData?.mobile_number ? memberData.mobile_number : ""}</small> <br />
                                                                            </div>
                                                                        </div>

                                                                        <div className='text-success text-center me-2 px-1' style={{ fontSize: "12px", border: "1px solid green", borderRadius: "5px" }}>
                                                                            <span>Already</span> <br />
                                                                            <span>Published</span>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        })}

                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-dark" onClick={() => setEditShiftModal(false)}>Cancel</button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {

                                            const updatememberDataInPublishArray = puslishShift.map(subArray => (
                                                subArray.map(list => {
                                                    if (list.shift_date === editShifts.selectedDate && list.shift_name === editShifts.data.shift_name) {
                                                        // Create a new object with updated teamMemberArray
                                                        return {
                                                            ...list,
                                                            teamMemberArray: editShifts.data.teamMemberArray
                                                        };
                                                    }
                                                    return list; // Return the original object unchanged
                                                })
                                            ));

                                            // console.log("******** shiftList **********", shiftList)

                                            const updateCalendarData = draftShift.map(data => {
                                                if (data.start == editShifts.selectedDate && data.title == editShifts.data.shift_name && data.isPublished == false) {
                                                    // Create a new object with updated teamMemberArray
                                                    return {
                                                        ...data,
                                                        extendedProps: { ...data.extendedProps, teamMemberArray: editShifts.data.teamMemberArray }
                                                    };
                                                }
                                                return data; // Return the original object unchanged
                                            })

                                            console.log("🚀 ~ updateCalendarData ~ updateCalendarData:", updateCalendarData)

                                            // setShiftList(updateCalendarData);
                                            setDraftShift(updateCalendarData);
                                            setEditShiftModal(false)
                                            setCheckStatus(!checkstatus)
                                            setPuslishShift(updatememberDataInPublishArray)
                                        }}
                                    // disabled={currentshift.some(obj => obj.teamMemberArray.length > 0) ? false : true}
                                    >Update Shifts</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-backdrop fade show"></div>
                </>
            }

        </div >
    )
}