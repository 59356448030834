import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { GetCountry, postApi } from '../../service/MerchantService';
import { Breadcrumbs } from '../../../components';

const AddStore = () => {

  const urlR = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;
  console.log(prefix);
  const [pancardImageFile, setPancardImageFile] = useState({});
  const [s3path, setS3path] = useState({});
  const [country, setCountry] = useState([]);
  const [apiError, setApiError] = useState({
    email: '',
    mobile_number: ''
  })
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [valuesConfP, setValuesConfP] = React.useState({
    password: "",
    showConfPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfPassword = () => {
    setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
  };
  const eyeBtnStyle = {
    position: 'absolute',
    marginLeft: '0px',
    marginTop: '0px',
    marginRight: '10px'
  }

  const eyeBtnStyle1 = {
    position: 'absolute',
    marginLeft: '510px',
    marginTop: '16px',
  }

  const [logoValue, setDocValue] = useState({})
  const navigate = useNavigate();

  const uploadLogoFunc = async (e) => {
    console.log('e.target.files[0]', e.target.files[0]);
    const file = e.target.files[0];
    setDocValue(file);
    form.setValues({
      ...form.values,
      logo: file
    })
  }
  const urlR1 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const regex = /[A-Za-z]/;
  const getCountry = async () => {
    const response = await GetCountry();
    if (response.status == true) {
      setCountry(response.data);
    } else {
      console.log("get Country data response", response);
    }
  }

  useEffect(() => {
    getCountry();
  }, [])

  const form = useFormik({

    initialValues: {
      company_name: "",
      full_name: "",
      mobile_number: "",
      gst_number: "",
      address: "",
      website: "",
      password: "",
      email: "",
      confirmedPassword: "",
      country: "",
      logo: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      company_name: yup.string().required('Please Enter Merchant Name'),
      full_name: yup.string().required('Please Enter Full Name').matches(regex, 'Please Enter Only Character Values'),
      mobile_number: yup.string().required('Please Enter Mobile Number')
        // .matches(phoneRegExp, 'Please enter only number values')
        .min(7, "Mobile number must contain 7 number").max(15, "Mobile number must contain 15 number"),
      gst_number: yup.string().required('Please enter Company Registration/Tax ID'),
      address: yup.string().required('Please enter address'),
      email: yup.string().required('Please Enter Email').matches(urlR1, 'Please Enter Valid Email Url'),
      website: yup.string().required('Please enter website').matches(urlR, 'Please enter valid website url'),
      password: yup.string().required('Please enter password'),
      confirmedPassword: yup.string().required('Please enter confirm password').oneOf([yup.ref("password"), null], "Password Must Be Same"),
      country: yup.string().required('Please enter country'),
      logo: yup.string().required('Please upload your logo'),
    }),


    onSubmit: async (values) => {
      setApiError({ ...apiError, mobile_number: '', email: '' });

      const formData = new FormData();
      // formData.append("merchant_id", merchant.user_id);
      formData.append("logo", logoValue);
      formData.append("company_name", form.getFieldProps('company_name').value);
      formData.append("country", form.getFieldProps('country').value);
      formData.append("full_name", form.getFieldProps('full_name').value);
      formData.append("address", form.getFieldProps('address').value);
      formData.append("mobile_number", form.getFieldProps('mobile_number').value);
      formData.append("gst_number", form.getFieldProps('gst_number').value);
      formData.append("email", form.getFieldProps('email').value);
      formData.append("website", form.getFieldProps('website').value);
      formData.append("password", form.getFieldProps('password').value);
      formData.append("confirmedPassword", form.getFieldProps('confirmedPassword').value);
      const response = await postApi("/add-store", formData, true);
      if (response.status === true) {
        toast.success(response.message);
        navigate(process.env.REACT_APP_MARCHNT_PRIFIX + '/store_list');
      } else {
        if (response.message == 'mobile_number must be unique') {
          toast.error('Mobile Number Already Exists');
        } else {
          toast.error(response.message);
        }
      }
    },
  });

  const imageUpload = (e, path, s3_path, co_index = 0) => {
    console.log("event pancard", e);
    if (e.target.files.length) {
      console.log('logoValue', logoValue);

      const formData1 = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData1.append('image', e.target.files[i])
      }
      formData1.append('folder', s3_path)
      console.log('file length', e.target.files.length);
      const file = e.target.files[0]
      console.log('file', file);
      setPancardImageFile(file)
      setS3path(s3_path)
    }
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="page my-3 py-2">
        <div className="page-leftheader">
          <h6 className="page-title mb-0 text-primary">
            <Breadcrumbs order={{ first: { name: "Stores", path: `${prefix}/store_list` }, second: { name: "Add Store" } }} />
          </h6>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 p-0 mx-auto">
              <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                <div className="card-body">
                  <div className=" mb-3">
                    <h3 className="mb-2">Add Store Details</h3>
                  </div>
                  <form className="mt-5 row lendersignup" id='registrationForm' onSubmit={form.handleSubmit}>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text">
                        <i className="fa-solid fa-building fa-fw" /> </div>
                      <input type="text" name="company_name" {...form.getFieldProps("company_name")} className="form-control" placeholder="Merchant Name" />
                      {form.touched.company_name && form.errors.company_name ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.company_name}</div> : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text">
                        <i className="fa-solid fa-user fa-fw" /> </div>
                      <input type="text" name="full_name" {...form.getFieldProps("full_name")} className="form-control" placeholder="Name Of Contact Person" />
                      {form.touched.full_name && form.errors.full_name ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text">
                        <i className="fa-solid fa-phone fa-fw" /> </div>
                      <input type="number" name="mobile_number" {...form.getFieldProps("mobile_number")} className="form-control" placeholder="Mobile Number" />
                      {form.touched.mobile_number && form.errors.mobile_number ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                      {apiError.mobile_number ?
                        <div className="invalid-feedback mb-3 mt-2" style={{ display: "block", textAlign: "center" }}>{apiError.mobile_number}</div>
                        : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text">
                        <i className="fa-solid fa-address-card fa-fw" /> </div>
                      <input type="text" name="gst_number" {...form.getFieldProps("gst_number")} className="form-control" placeholder="ID (Company Registration/Tax ID)" />
                      {form.touched.gst_number && form.errors.gst_number ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.gst_number}</div> : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text">
                        <i className="fa-solid fa-location-dot fa-fw" /> </div>
                      <input type="text" name="address" {...form.getFieldProps("address")} className="form-control" placeholder="Address" />
                      {form.touched.address && form.errors.address ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.address}</div> : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text"> <i className="fa-solid fa-globe fa-fw" /></div>
                      <input className="form-control" type="text" name="website" {...form.getFieldProps("website")} placeholder="Website" id="password-field" />
                      {form.touched.website && form.errors.website ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.website}</div> : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text">
                        <i className="fas fa-mail-bulk fa-fw" /></div>
                      <input className="form-control" type="text" name="email" {...form.getFieldProps("email")} placeholder="Email ID" id="password-field" />
                      {form.touched.email && form.errors.email ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text">
                        <i className="fa-solid fa-earth-americas"></i> </div>
                      <select className="form-control" name="country" {...form.getFieldProps("country")}>
                        <option value="">Select Country</option>
                        {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}
                      </select>
                      {form.touched.country && form.errors.country ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.country}</div> : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true"></i> </div>
                      <input className="form-control" {...form.getFieldProps("password")} type={values.showPassword ? "text" : "password"} name="password" placeholder="Password" id="password-field" autoComplete="new-password" />
                      {!values.showPassword ?
                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                        :
                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                      }
                      {form.touched.password && form.errors.password ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                    </div>
                    <div className="input-group mb-4 col-lg-4">
                      <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true"></i> </div>
                      <input className="form-control" {...form.getFieldProps("confirmedPassword")} type={valuesConfP.showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />
                      {form.touched.confirmedPassword && form.errors.confirmedPassword ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirmedPassword}</div> : ''}
                      {!valuesConfP.showConfPassword ?
                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                        :
                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                      }
                    </div>
                    <div className="input-group file-browser mb-0 widthHundred">
                      <input type="text" className="form-control browse-file" placeholder="Upload your logo" />
                      <label className="input-group-text btn btn-primary"> Browse <input type="file" className="file-browserinput" onChangeCapture={uploadLogoFunc} onChange={(e) => imageUpload(e, 'dsa_doc', 'uploads/merchant/pancard')} style={{ display: 'none' }} multiple /> </label>
                      {form.touched.logo && form.errors.logo ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}
                    </div>
                    {logoValue && logoValue.name ? <ul className="imgpreview-newbx">
                      <li className="registerboximg">
                        <img src={URL.createObjectURL(logoValue)} alt="" />
                        <Link to="" onClick={() => setDocValue({})} className="upload__img-close"><i className="fa-solid fa-close fa-fw"></i></Link>
                        <p>img preview name here</p>
                      </li>
                    </ul> : ""}
                    <div type="submit" className="form-group text-left mt-4 mb-3"> <button type='submit' className="btn btn-primary btn-lg w-30 br-7">Register</button> </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default AddStore
