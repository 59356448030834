import React, { useEffect, useState } from 'react'
import { Breadcrumbsecond } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom'
import PaginationComponent from '../../../PaginationComponent';
import Loader from '../../../components/Loader/loader';
import { Getmerchantpaidtxn, getApi } from '../../service/lenderService';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { TimeZone } from '../../../components/TimeZone/TimeZone';


const Merchantpaidtxn = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_LENDER_PRIFIX
    const navigate = useNavigate();
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX
    const Lender_id = JSON.parse(lenderData)?.user_id

    const setCopiedy = () => {
        toast.success("Copied")
    }

    const merchantpaidtxn = async (token) => {
        // const response = await Getmerchantpaidtxn(token, id, search,pagesCount,  sortedBy, orderBy);
        const response = await getApi(`/merchant-paid/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
        console.log(response);
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.rows);
                setTotalPagesCount(response.count)

                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message === 'Session expired') {
            localStorage.removeItem("lender");
            navigate('/lender/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        }
        else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        merchantpaidtxn(token)
    }, [search, pagesCount, sortedBy, orderBy])


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }


    }
    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">
                    <Breadcrumbsecond order={{ first: { name: "Paid to Merchant", path: `${prefix}/merchanttransaction` }, second: { name: "Employee Transaction" } }} />
                    {/* <h4 className="page-title mb-0 text-primary">Employee Details</h4> */}
                </div>
                <div style={{ marginLeft: '450px', marginTop: '25px' }}>
                    {/* <h4 className="ml-2">{totalAmoumt}</h4> */}
                    {/* <h5 > {totalAmoumt ? `Total: $${num2}` : ""}</h5> */}
                </div>

                <div className="page-rightheader">
                    <div className="btn-list">
                        {/* <button className='btn btn-outline-primary mt-3' data-bs-target="#add_settlement"
         data-bs-toggle="modal"
    disabled={multippleArray.length > 0 ? false : true} >+ Pay to Lender</button> */}
                        {/* <Link to={prefix + "/add_employer"} className="btn btn-outline-primary mt-3" ><i className="fa fa-plus me-2"></i> Add Employee</Link> */}
                        {/* <CSVLink {...csvReport}  className="btn btn-outline-primary mt-3"><i className="fa fa-download me-2"></i>Import CSV</CSVLink>  */}
                        {/* <CSVLink {...csvReport}  :id className="btn btn-outline-primary mt-3"><i className="fa fa-download me-2"></i>Import CSV</CSVLink>          */}
                        <a href={exportfix + `/export-merchant-paid/${Lender_id}/${id} `} className="btn btn-outline-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div>

            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Transaction No.  {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_id') }}></i>}
                                            </th>
                                            {/* <th className="font-weight-bold">Customer Detail  {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_name') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Merchant Detail  {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('merchant_name') }}></i>}
                                            </th> */}
                                            <th className="font-weight-bold">Paid Amount  {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('paid_amount') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Lender Comm   {sortedBy == "lender_commission_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('lender_commission_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('lender_commission_amount') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Merchant Comm  {sortedBy == "mrch_commission" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mrch_commission') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mrch_commission') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Thirdroc Income  {sortedBy == "thirdroc_income" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_income') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('thirdroc_income') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Payment Date  {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }}></i>}
                                            </th>
                                            {/* <th className="font-weight-bold">View Settlements </th> */}
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td /><td />
                                        <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                            <Loader />
                                        </div> </tbody> :
                                        <>

                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { id, txn_id, merchant_name, merchant_settled_amount, mrch_commission, merchant_mobile_number, paid_amount, lender_commission_amount, thirdroc_income, payment_txn_number, customer_name, customer_mobile_number, payment_mode, comment, updated_at, amount } = data;
                                                    // var timezone = new Date(data.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });

                                                    return (
                                                        <tr key={serial_num}>
                                                            <td>{txn_id ? txn_id : "NA"}
                                                                <span>
                                                                    <CopyToClipboard text={data.txn_id} onCopy={() => setCopiedy(true)}>
                                                                        <button className='btn btn-primary btn-sm ms-3'><i className="fa-solid fa-copy"></i></button>
                                                                    </CopyToClipboard>
                                                                </span>
                                                            </td>
                                                            {/* <td>{customer_name ? customer_name : "NA"}<br/>
                                                            {customer_mobile_number ? customer_mobile_number: ""}</td>
                                                            <td>{merchant_name ? merchant_name : "NA"}<br/>
                                                            {merchant_mobile_number ? merchant_mobile_number: ""}</td> */}
                                                            <td>{paid_amount ? "$" + paid_amount : "NA"}</td>
                                                            <td>{lender_commission_amount ? "$" + lender_commission_amount : "NA"}</td>
                                                            <td>{mrch_commission ? "$" + mrch_commission : "NA"}</td>
                                                            <td>{thirdroc_income ? "$" + thirdroc_income : "NA"}</td>
                                                            <td>{TimeZone(data?.updated_at).timeZone}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>



        </div>
    )
}

export default Merchantpaidtxn
