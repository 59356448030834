import React, { useEffect, useRef, useState } from 'react'
import PaginationComponent from '../../../../PaginationComponent';
import { getApi, postApi } from '../../../service/adminService';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GetCountry } from '../../../../employer/service/employService';

const TaxSlab = () => {
    const [pagesCount, setpagesCount] = useState(1);
    const [search, setSearch] = useState('');
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const tableRef = useRef(null);
    const [country, setCountry] = useState([]);
    const closemodal = useRef()
    const [updateID, setUpdateID] = useState('1')

    const viewPlan = async () => {
        try {
            const response = await getApi(`/get-tax-slab/${updateID}`, { sortby: 'min_amount', orderby: 'Asc' });
            if (response.status) {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
            } else {
                console.log(response)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        viewPlan()
    }, [search, pagesCount, updateID])


    const AddForm = useFormik({
        initialValues: {
            reside_type: "",
            min_amount: "",
            max_amount: "",
            actual_income_tax: "",
            excess_income_tax: "",
            actual_social_responsibility: "",
            excess_social_responsibility: "",
            actual_environment_and_climate: "",
            excess_environment_and_climate: ""

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            reside_type: yup
                .string()
                .required('Please select Type'),
            min_amount: yup
                .string()
                // .matches(/^\d{1,5}$/, 'Min Price must be up to 5 digits')
                .typeError('Please Enter a valid Min Price')
                .required('Please Enter Min Price'),
            max_amount: yup
                .string()
                // .matches(/^\d{1,5}$/, 'Max Price must be up to 5 digits')
                .typeError('Please Enter a valid Max Price')
                .required('Please Enter Max Price'),
        }),
        onSubmit: async (values, { resetForm }) => {
            const url = "/add-tax-slab";
            const data = {
                reside_type: values.reside_type,
                min_amount: values.min_amount,
                max_amount: values.max_amount,
                country_id: updateID,
                actual_income_tax: values.actual_income_tax,
                excess_income_tax: values.excess_income_tax,
                actual_social_responsibility: values.actual_social_responsibility,
                excess_social_responsibility: values.excess_social_responsibility,
                actual_environment_and_climate: values.actual_environment_and_climate,
                excess_environment_and_climate: values.excess_environment_and_climate
            };
            const response = await postApi(url, data);
            if (response.status) {
                viewPlan();
                toast.success(response.message);
                resetForm({ values: '' });
                closemodal.current.click();
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    });

    const GetCountrys = async () => {
        try {

            const response = await GetCountry();

            console.log('response', response);
            if (response.status) {
                setCountry(response.data);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetCountrys();
    }, []);
    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Tax Slab List</h4>
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    <Link to="" className="btn btn-primary btn-pill"

                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <i className="fa fa-plus me-1"></i> Add Slab</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Select Country</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name="loan_type"
                                                    value={updateID}
                                                    onChange={(e) => setUpdateID(e.target.value)} >
                                                    {/* <option value="">-- Select Country --</option> */}
                                                    {country.map(item => (<option key={item.id} value={item.id}>{item.name ? item.name : "NA"}</option>))}
                                                    {country.length == 0 &&
                                                        <option className='text-bold'>No Country Available</option>

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <h6 className='my-3 page-title text-center text-primary '>RESIDENT TAXPAYERS TAX TABLE</h6>
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold text-wrap ">Chargeable Income ($)</th>
                                                        <th className="font-weight-bold text-wrap">Actual Income Tax ($)</th>
                                                        <th className="font-weight-bold text-wrap">Access Income Tax ($)</th>

                                                        <th className="font-weight-bold text-wrap">Actual Social Responsibility Tax ($)</th>
                                                        <th className="font-weight-bold text-wrap">Access Social Responsibility Tax ($)</th>

                                                        <th className="font-weight-bold text-wrap">Actual Environment and Climate Adaptation Levy ($)</th>
                                                        <th className="font-weight-bold text-wrap">Access Environment and Climate Adaptation Levy ($)</th>
                                                    </tr>
                                                </thead>
                                                {dataArray?.length > 0 && dataArray.filter(data => data.reside_type == 'Resident')?.map((Item, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    // const { actual_income_tax, excess_income_tax,excess_income_tax } = Item;
                                                    return (

                                                        <tbody>
                                                            <tr key={serial_num}>
                                                                <td><span className="font-weight-normal1">{Item?.min_amount && Item?.min_amount} - {Item?.max_amount && Item?.max_amount}</span></td>
                                                                <td><span className="font-weight-normal1">{Item?.actual_income_tax ? Item?.actual_income_tax : "--"} </span></td>
                                                                <td><span className="font-weight-normal1">{Item?.excess_income_tax ? Item?.excess_income_tax : "--"}</span></td>

                                                                <td><span className="font-weight-normal1">{Item?.actual_social_responsibility ? Item?.actual_social_responsibility : "--"} </span></td>
                                                                <td><span className="font-weight-normal1">{Item?.excess_social_responsibility ? Item?.excess_social_responsibility : "--"}</span></td>

                                                                <td><span className="font-weight-normal1">{Item?.actual_environment_and_climate ? Item?.actual_environment_and_climate : "--"} </span></td>
                                                                <td><span className="font-weight-normal1">{Item?.excess_environment_and_climate ? Item?.excess_environment_and_climate : "--"}</span></td>

                                                            </tr>
                                                        </tbody>


                                                    )
                                                }
                                                )}
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>}
                                                </tbody>
                                            </table>





                                            <h6 className='mb-3 mt-5 text-center page-title text-primary'>NON-RESIDENT TAXPAYERS TAX TABLE</h6>
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold text-wrap ">Chargeable Income ($)</th>
                                                        <th className="font-weight-bold text-wrap">Actual Income Tax ($)</th>
                                                        <th className="font-weight-bold text-wrap">Access Income Tax ($)</th>

                                                        <th className="font-weight-bold text-wrap">Actual Social Responsibility Tax ($)</th>
                                                        <th className="font-weight-bold text-wrap">Access Social Responsibility Tax ($)</th>

                                                        <th className="font-weight-bold text-wrap">Actual Environment and Climate Adaptation Levy ($)</th>
                                                        <th className="font-weight-bold text-wrap">Access Environment and Climate Adaptation Levy ($)</th>
                                                    </tr>
                                                </thead>
                                                {dataArray?.length > 0 && dataArray.filter(data => data.reside_type == 'Non-resident')?.map((Item, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    // const { actual_income_tax, excess_income_tax,excess_income_tax } = Item;
                                                    return (

                                                        <tbody>
                                                            <tr key={serial_num}>
                                                                <td><span className="font-weight-normal1">{Item?.min_amount && Item?.min_amount} - {Item?.max_amount && Item?.max_amount}</span></td>
                                                                <td><span className="font-weight-normal1">{Item?.actual_income_tax ? Item?.actual_income_tax : "--"} </span></td>
                                                                <td><span className="font-weight-normal1">{Item?.excess_income_tax ? Item?.excess_income_tax : "--"}</span></td>

                                                                <td><span className="font-weight-normal1">{Item?.actual_social_responsibility ? Item?.actual_social_responsibility : "--"} </span></td>
                                                                <td><span className="font-weight-normal1">{Item?.excess_social_responsibility ? Item?.excess_social_responsibility : "--"}</span></td>

                                                                <td><span className="font-weight-normal1">{Item?.actual_environment_and_climate ? Item?.actual_environment_and_climate : "--"} </span></td>
                                                                <td><span className="font-weight-normal1">{Item?.excess_environment_and_climate ? Item?.excess_environment_and_climate : "--"}</span></td>

                                                            </tr>
                                                        </tbody>


                                                    )
                                                }
                                                )}
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>











                        {pagesCount > 0 && dataArray.length > 0 ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}







                    </div>
                </div>



            }


            {/*********************** Add and Update Plan Modal Start **************************/}

            <div className="modal fade" id="staticBackdrop"
                data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Add Slab</h5>
                            {/* <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">x</button> */}
                        </div>
                        <form onSubmit={AddForm.handleSubmit}>
                            <div className="modal-body">
                                <div className=" row">

                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label" >Tax Payer Type:</label>
                                        <select className="form-control" {...AddForm.getFieldProps('reside_type')}>
                                            <option value="">Select Type</option>
                                            <option value='Resident'>Resident</option>
                                            <option value='Non-resident'>Non-Resident</option>
                                        </select>
                                        {AddForm.touched.reside_type && AddForm.errors.reside_type ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.reside_type}</div> : ''}
                                    </div>
                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label">Min Income:</label>
                                        <input type="number" className="form-control" id="recipient-name" placeholder='Enter Min Income' {...AddForm.getFieldProps('min_amount')} />
                                        {AddForm.touched.min_amount && AddForm.errors.min_amount ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.min_amount}</div> : ''}
                                    </div>
                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label" >Max Income</label>
                                        <input type="number" className="form-control" id="recipient-name" placeholder='Enter Min Income' {...AddForm.getFieldProps('max_amount')} />
                                        {AddForm.touched.max_amount && AddForm.errors.max_amount ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.max_amount}</div> : ''}
                                    </div>



                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label">Actual Income Tax:</label>
                                        <input type="text" className="form-control" id="recipient-name" placeholder='Enter Actual Income Tax'  {...AddForm.getFieldProps('actual_income_tax')} />
                                        {AddForm.touched.actual_income_tax && AddForm.errors.actual_income_tax ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.actual_income_tax}</div> : ''}
                                    </div>
                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label">Access Income Tax:</label>
                                        <input type="text" className="form-control" id="recipient-name" placeholder='Enter Access Income Tax'  {...AddForm.getFieldProps('excess_income_tax')} />
                                        {AddForm.touched.excess_income_tax && AddForm.errors.excess_income_tax ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.excess_income_tax}</div> : ''}
                                    </div>
                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label">Actual Social Responsibility:</label>
                                        <input type="text" className="form-control" id="recipient-name" placeholder='Actual Social Responsibility'  {...AddForm.getFieldProps('actual_social_responsibility')} />
                                        {AddForm.touched.actual_social_responsibility && AddForm.errors.actual_social_responsibility ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.actual_social_responsibility}</div> : ''}
                                    </div>
                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label">Excess Social Responsibility:</label>
                                        <input type="text" className="form-control" id="recipient-name" placeholder='Excess Social Responsibility'  {...AddForm.getFieldProps('excess_social_responsibility')} />
                                        {AddForm.touched.excess_social_responsibility && AddForm.errors.excess_social_responsibility ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.excess_social_responsibility}</div> : ''}
                                    </div>
                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label">Actual Environment and Climate:</label>
                                        <input type="text" className="form-control" id="recipient-name" placeholder=' Actual Environment & Climate'  {...AddForm.getFieldProps('actual_environment_and_climate')} />
                                        {AddForm.touched.actual_environment_and_climate && AddForm.errors.actual_environment_and_climate ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.actual_environment_and_climate}</div> : ''}
                                    </div>
                                    <div className=" col-md-4 mb-3">
                                        <label for="recipient-name" className="col-form-label">Excess Environment and Climate:</label>
                                        <input type="text" className="form-control" id="recipient-name" placeholder='Excess Environment & Climate'  {...AddForm.getFieldProps('excess_environment_and_climate')} />
                                        {AddForm.touched.excess_environment_and_climate && AddForm.errors.excess_environment_and_climate ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.excess_environment_and_climate}</div> : ''}
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button ref={closemodal} type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            {/*********************** Add and Update Plan Modal End **************************/}
        </>
        // <>
        //     <>
        //         <Toaster position="top-right" reverseOrder={false} />
        //         {!showAddNewCases &&
        //             <div>
        //                 <div className="main-container container-fluid px-0">
        //                     <div className="page-header mb-3">
        //                         <div className="page-leftheader">
        //                             <h6 className="page-title mb-0 text-primary">
        //                                 <nav aria-label="breadcrumb">
        //                                     <ol className="breadcrumb">
        //                                         <li className="breadcrumb-item active" aria-current="page">Tax Slab List({dataArray.length})</li>
        //                                     </ol>
        //                                 </nav>
        //                             </h6>
        //                         </div>

        //                     </div>
        //                     <div className="row">
        //                     </div>
        //                     <div className="row">
        //                         <div className="col-md-12">
        //                             <div className="card">
        //                                 <div className="card-body p-0">
        //                                     <div className="table-responsive">
        //                                         <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
        //                                             <thead className="border-bottom-0 pt-3 pb-3">
        //                                                 <tr>
        //                                                     <th className="font-weight-bold">Country ID</th>
        //                                                     <th className="font-weight-bold">Name</th>
        //                                                     <th className="font-weight-bold">Country Code</th>
        //                                                     <th className="font-weight-bold">status</th>
        //                                                     <th className="font-weight-bold">Action</th>
        //                                                 </tr>
        //                                             </thead>

        //                                             {loader == true ? <tbody>
        //                                                 <td />
        //                                                 <div>
        //                                                     <Loader />
        //                                                 </div> </tbody> :
        //                                                 <tbody>
        //                                                     {dataArray.length > 0 && dataArray.map((option, index) => {
        //                                                         let serial_num = ((10 * (pagesCount - 1)) + index + 1);

        //                                                         return (
        //                                                             <tr key={serial_num} >
        //                                                                 <td><span className="font-weight-normal1">{option.id ? option.id : ""}</span></td>
        //                                                                 <td><span className="font-weight-normal1">{option.name ? option.name : ""}</span></td>
        //                                                                 <td><span className="font-weight-normal1">{option.phone_code ? option.phone_code : ""}</span></td>
        //                                                                 <td><span className="font-weight-normal1">Active</span></td>
        //                                                                 <td><Link to={prefix + `/taxslab_by_id/${option.id}`} className='btn btn-primary' > <span className="font-weight-normal1">Slab History</span></Link></td>
        //                                                             </tr>
        //                                                         )
        //                                                     }
        //                                                     )}
        //                                                 </tbody>}
        //                                         </table>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         {pagesCount > 0 && dataArray.length > 0 ?
        //                             <>
        //                                 <div className="col-md-12">
        //                                     <div className="card-body">
        //                                         <PaginationComponent className="justify-content-center"
        //                                             totalItems={totalPagesCount}
        //                                             pageSize={10}
        //                                             maxPaginationNumbers={3}
        //                                             onSelect={(e) => setpagesCount(e)}
        //                                         />
        //                                     </div>
        //                                 </div>
        //                             </>
        //                             : ''}
        //                     </div>
        //                 </div>
        //             </div>
        //         }
        //         <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog">
        //             <div className="modal-dialog" role="document">
        //                 <div className="modal-content modal-content-demo">
        //                     <div className="modal-header"> <h6 className="modal-title">Assign Employer</h6>
        //                         <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

        //                     <div className="modal-body application-modal">
        //                         <div className="input-group">
        //                             <input type="text" className="form-control" placeholder="Search By Keyword"
        //                                 value={searchpage} onChange={(e) => setSearchpage(e.target.value)} /* onKeyDown={getEmployeesData} */ />
        //                             <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
        //                         </div><hr />
        //                         {pagesCheck > 0 && dataArray.length > 0 ?
        //                             <>
        //                                 <div className="col-md-12">
        //                                     <div className="card-body">
        //                                         <PaginationComponent className="justify-content-center"
        //                                             totalItems={setTotalPagesCount}
        //                                             pageSize={10}
        //                                             maxPaginationNumbers={3}
        //                                             onSelect={(e) => setpagesCheck(e)}
        //                                         />
        //                                     </div>
        //                                 </div>
        //                             </>
        //                             : ''}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </>
        // </>
    )
}

export default TaxSlab