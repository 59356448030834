import React, { useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import Loader from '../../../components/Loader/loader';
import { getApi, postApi } from '../../service/employService';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as XLSX from 'xlsx';

const Repaymentreport = () => {
    const [ValidPagination, setValidPagination] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState([]);
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    // const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [totalAmoumt, setTotalAmount] = useState(0);
    const [Count, setCount] = useState();
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const ref = useRef()
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [multippleArray, setMultippleArray] = useState([]);
    const [imageUpload, setimageUpload] = useState(null)

    const ViewLoanCases = async () => {
        const response = await getApi(`/due-ewa-advance-emi`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data)
                setCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        ViewLoanCases()

    }, [search, pagesCount, sortedBy, orderBy])


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const selectTransactions = async (e, id, amount, user_loan_id, dueEmis) => {
        let checkArray = await multippleArray;
        let rowIndex = checkArray.findIndex(v => v == id);
        if (rowIndex > -1) {
            setTotalAmount(parseFloat(totalAmoumt) - parseFloat(amount));
            checkArray.splice(rowIndex, 1);
            setIsChecked1(isChecked1.filter(item => item.user_loan_id != user_loan_id))

        } else {
            checkArray.push(id);
            setTotalAmount(parseFloat(totalAmoumt) + parseFloat(amount));

            if (multippleArray.filter(item => dueEmis.map(item => item.id).includes(item)).length == dueEmis.length) {
                setIsChecked1([...isChecked1, { user_loan_id: user_loan_id }])
            }
        }

        setMultippleArray(checkArray);
        setDataArray((old_data) => [...old_data]);
        let actualEMICount = 0
        for (const element of dataArray) {
            actualEMICount += element.dueEmis.length
        }
        if (dataArray.length === 0) {
            setIsChecked(false);
        } else if (checkArray.length === actualEMICount) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }

    const handleCheckClick = () => {
        if (isChecked == false) {
            let newdata = dataArray?.flatMap(v => v?.dueEmis?.map(emi => parseInt(emi.id))) || [];
            setMultippleArray((old_data) => [...newdata]);
            let newAmount = dataArray?.flatMap(v => v?.dueEmis?.map(emi => parseFloat(emi.amount))) || [];
            let totalAmount = newAmount.reduce((a, v) => a + v, 0);
            setTotalAmount(totalAmount);
            setIsChecked(true);
            setIsChecked1(dataArray.map(item => { return { user_loan_id: item.user_loan_id } }))
        } else {
            setTotalAmount(0)
            setIsChecked(false);
            setMultippleArray([]);
            setIsChecked1([])
        }
        setDataArray((old_data) => [...old_data]);
    }

    const handleCheckClick1 = (dueEmis, user_loan_id) => {
        let tempMultArr = []

        if (isChecked1.filter((item) => item.user_loan_id == user_loan_id).length == 0) {

            const dueEmisIds = dueEmis.map(item => item.id)
            const filterIds = dueEmisIds?.filter(item => !multippleArray.includes(item))

            let addAmount = 0
            for (const mainElement of dueEmis) {
                for (const element of filterIds) {
                    if (mainElement.id == element) {
                        addAmount += parseFloat(mainElement.amount)
                    }
                }
            }
            setTotalAmount(totalAmoumt + addAmount);

            setMultippleArray((item) => [...item, ...filterIds]);

            tempMultArr = [...multippleArray, ...filterIds]
            setIsChecked1([...isChecked1, { user_loan_id: user_loan_id }]);
        } else {
            setIsChecked1(isChecked1.filter((item) => item.user_loan_id != user_loan_id))
            const dueEmisIds = new Set(dueEmis.map(item => item.id));
            setMultippleArray(multippleArray.filter((emiIds) => !dueEmisIds.has(emiIds)));
            tempMultArr = multippleArray.filter((emiIds) => !dueEmisIds.has(emiIds))

            const dueEmisIdsAmount = dueEmis.map(item => item.id)
            const filterIds = dueEmisIdsAmount?.filter(item => multippleArray.includes(item))

            let removeAmount = 0
            for (const mainElement of dueEmis) {
                for (const element of filterIds) {
                    if (mainElement.id == element) {
                        removeAmount += parseFloat(mainElement.amount)
                    }
                }
            }
            setTotalAmount(totalAmoumt - removeAmount);
        }
        setDataArray((old_data) => [...old_data]);



        let actualEMICount = 0
        for (const element of dataArray) {
            actualEMICount += element.dueEmis.length
        }
        if (dataArray.length === 0) {
            setIsChecked(false);
        } else if (tempMultArr.length == actualEMICount) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    };

    const checheked = (item) => {
        return multippleArray.includes(item) ? 1 : 0;
    }

    var Total_Amount = parseFloat(totalAmoumt).toFixed(2)

    const addSettlementForm = useFormik({
        initialValues: {
            emiIdArray: '',
            employerNote: "",
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            employerNote: yup.string().required('Please add a comment'),
            //   payment_mode: yup.string().required('Please add a payment mode '),
            // image: yup.string().required('Please Select Image'),

        }),
        onSubmit: async values => {
            const formData = new FormData();
            formData.append("emiIdArray", multippleArray);
            formData.append("employerNote", addSettlementForm.getFieldProps('employerNote').value);
            formData.append("image", imageUpload);
            const response = await postApi("/pay-due-ewa-advance-emi", formData, true);
            if (response.status) {
                toast.success(response.message);
                ViewLoanCases()
                ref.current.click()
                setMultippleArray([])
                setTotalAmount(0)
                setimageUpload()
            } else {
                toast.error(response.message);
            }
        }
    });

    const exportTableToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const range = XLSX.utils.decode_range(ws['!ref']);
        const newData = [];
        for (let rowIndex = range.s.r; rowIndex <= range.e.r; rowIndex++) {
            const newRow = [];
            for (let colIndex = range.s.c + 1; colIndex <= range.e.c; colIndex++) {
                const cell = ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })];
                newRow.push(cell);
            }
            newData.push(newRow);
        }
        const newWs = XLSX.utils.aoa_to_sheet(newData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, newWs, "Data");
        XLSX.writeFile(wb, "Pending_Repayment_Data.xlsx");
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            {/* <li className="breadcrumb-item"><Link className='breadcrumbslink' >Manage EWA </Link></li> */}
                                            <li className="breadcrumb-item active" aria-current="page">Pending Repayment {Count ? "(" + Count + ")" : ""}</li>
                                        </ol>
                                    </nav>
                                </h4>
                            </div>
                            <div className="page-rightheader">
                                <div className="d-flex align-items-center">
                                    <h6 className="me-3 mt-2">
                                        {totalAmoumt ? `Total Amount: $${Total_Amount}` : ""}
                                    </h6>
                                    <button
                                        className="btn btn-primary btn-pill me-3"
                                        data-bs-target="#add_settlement"
                                        data-bs-toggle="modal"
                                        disabled={multippleArray.length === 0}
                                    > + Pay
                                    </button>
                                    {/* <Link to="" className="btn btn-primary btn-pill">
                                        <i className="fa fa-download me-2"></i> Download Details
                                    </Link> */}
                                    <button className="btn btn-primary btn-pill" onClick={exportTableToExcel}>
                                        <i className="fa fa-download me-2" />Export Data</button>

                                </div>


                                <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content modal-content-demo">
                                            <div className="modal-header">
                                                <h3 className="modal-title">Settlement</h3>
                                                <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                    aria-hidden="true">×</span></button>
                                            </div>
                                            <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                                <h4>  {Total_Amount ? `Total: $${Total_Amount}` : ""}</h4>
                                            </div>
                                            <div className="modal-body application-modal">
                                                <form className=" row" onSubmit={addSettlementForm.handleSubmit}>
                                                    {addSettlementForm.values.wrong_message ?
                                                        <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{addSettlementForm.values.wrong_message}</div>
                                                        : ''}
                                                    <div className="row">
                                                        <div className="row g-xs">
                                                            <div className="col-12">
                                                                <input type="text" placeholder='comment' {...addSettlementForm.getFieldProps("employerNote")} value={addSettlementForm?.employerNote?.values} className="form-control" />
                                                                {addSettlementForm.touched.employerNote && addSettlementForm.errors.employerNote ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.employerNote}</div> : ''}
                                                            </div>
                                                            <div className="input-group file-browser mb-5">
                                                                <input type="file" className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                                                                {/* {addSettlementForm.touched.image && addSettlementForm.errors.image ?
                          <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.image}</div> : ''} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-footer mt-2">
                                                        <button type="submit" className="btn btn-primary" >
                                                            Ready Pay </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search}
                                                onChange={(e) => {
                                                    setSearch(e.target.value);
                                                    setpagesCount('1');
                                                    setValidPagination(!ValidPagination);
                                                    setTotalAmount(0);
                                                    setIsChecked(false);
                                                    setMultippleArray([]);
                                                    setIsChecked1([]);
                                                }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} /> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr style={{ background: '#a9a4a4' }}>
                                                        {dataArray.length > 0 && (
                                                            <th>
                                                                <input type="checkbox" name="id" checked={isChecked} readOnly
                                                                    onClick={() => handleCheckClick()}
                                                                />
                                                            </th>
                                                        )}
                                                        <th className="font-weight-bold">Loan ID {/* {sortedBy === "user_loan_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('user_loan_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('user_loan_id') }}> </i>} */}</th>
                                                        <th className="font-weight-bold">Borrower Name {/* {sortedBy === "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}> </i>} */}</th>
                                                        <th className="font-weight-bold">Borrower Email {/* {sortedBy === "personal_email" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('personal_email') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('personal_email') }}> </i>} */}</th>
                                                        <th className='font-weight-bold'> Amount {/* {sortedBy === "total_due_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_due_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_due_amount') }}> </i>} */}</th>
                                                        {/* <th className='font-weight-bold'>Action</th> */}
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><td /><td /><Loader /></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { user_loan_id, total_due_amount, due_date, personal_email } = option;
                                                                // const rowStyle = moment(due_date).isBefore(moment(), 'day') ? { backgroundColor: '#eba3a3' } : {};
                                                                const isParentChecked = isChecked1.filter((item) => item.user_loan_id == user_loan_id).length > 0
                                                                return (
                                                                    < >
                                                                        <tr key={serial_num}>
                                                                            <td></td>
                                                                            <td><span className="font-weight-normal1"> {user_loan_id ? user_loan_id : "- -"} </span></td>
                                                                            <td><span className="font-weight-normal1">{option?.first_name ? option?.first_name : "--"}  {option?.last_name ? option?.last_name : ""}</span></td>
                                                                            <td className='fw-bold text-bold' style={{ textTransform: 'none' }}>{option?.personal_email ? personal_email : "-"}</td>
                                                                            <td className='fw-bold text-bold'>{option?.total_due_amount ? total_due_amount : "-"}</td>
                                                                            {/* <td>
                                                                        <button className='btn btn-primary' onClick={() => setSubTableOpen(!isSubTableOpen)}>
                                                                            {isSubTableOpen ? 'Hide Details' : 'Show Details'}
                                                                        </button>
                                                                    </td> */}
                                                                        </tr>
                                                                        {/* {isSubTableOpen && ( */}
                                                                        <tr >
                                                                            <td colSpan={5}>
                                                                                <div className="sub-table-container">
                                                                                    <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                                        <thead className="border-bottom-0 pt-3 pb-3">
                                                                                            <tr style={{ background: '#b6b7cc' }}>
                                                                                                <th>
                                                                                                    <input type="checkbox" name="id"
                                                                                                        // checked={checheked1(option?.dueEmis) === 1 ? true : false}
                                                                                                        checked={isParentChecked}
                                                                                                        readOnly
                                                                                                        onClick={() => handleCheckClick1(option?.dueEmis, option?.user_loan_id)}
                                                                                                    />
                                                                                                </th>
                                                                                                <th>Repayment No.</th>
                                                                                                <th>Amount</th>
                                                                                                <th>Due Date</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {option?.dueEmis?.length > 0 && option?.dueEmis?.map((Items, index) => {
                                                                                                const { emi_number, amount, due_date } = Items;
                                                                                                return (
                                                                                                    <tr key={index}>
                                                                                                        <td><input type="checkbox" name={Items?.id} checked={checheked(Items?.id) === 1 ? true : false} onChange={(e) => selectTransactions(e, Items?.id, amount, user_loan_id, option?.dueEmis)} /></td>
                                                                                                        <td>{Items?.emi_number ? emi_number : "--"}</td>
                                                                                                        <td>{Items?.amount ? amount : "--"}</td>
                                                                                                        <td>{Items?.due_date ? due_date : "--"}</td>
                                                                                                    </tr>)
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                            {dataArray.length === 0 && (
                                                                <tr>
                                                                    <td colSpan={4} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            )}
                                                        </tbody>

                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''} */}


                        </div>
                    </div>


                </div>
            }
        </>
    )
}

export default Repaymentreport
