import React from 'react'

const Search = ({ search, setSearch, setpagesCount, setValidPagination, ValidPagination }) => {
    // console.log(search, setSearch);

    return (


        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
            <div className="form-group">
                <label className="form-label">Search</label>
                <div className="row g-xs">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setpagesCount('1'); setValidPagination(!ValidPagination) }} />
                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search