import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader/loader'
import { Breadcrumbs } from '../../components'
import { useNavigate, useParams } from 'react-router-dom';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import { toast } from 'react-hot-toast';
import { SettlementBYid, getApi } from '../service/adminService';
import CopyToClipboard from 'react-copy-to-clipboard';
import PaginationComponent from '../../PaginationComponent';
import Search from '../../components/Search/Search';

const SettlementById = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const employer = JSON.parse(localStorage.getItem("employer"));
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const navigate = useNavigate();
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [Count, setCount] = useState();
    const [ValidPagination, setValidPagination] = useState(false)


    const SettlementBYidDetail = async (token) => {
        // `/bnpl-settlements-detail/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`
        // const response = await SettlementBYid(token, id , search, pagesCount, sortedBy, orderBy);
        const response = await getApi(`/bnpl-settlements-detail/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
        if (response.data) {

            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.count)
                setCount(response.count)
                setloader(false)
            }, 2000);
            setloader(true)


        } else {
            console.log("get merchant data response", response);
        }
    }
    useEffect(() => {
        SettlementBYidDetail(admin.admin_token)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }


    }
    const copiedInfo = () => {
        toast.success("Copied")
    }

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">
                    <Breadcrumbs order={{ first: { name: "Settlement", path: `${prefix}/bnpl_settlement` }, second: { name: `Transaction Details ${Count ? "(" + Count + ")" : ""}` } }} />
                </div>
                <div style={{ marginLeft: '450px', marginTop: '25px' }}>
                </div>

                <div className="page-rightheader">
                    <div className="btn-list">
                        <a /* href={exportfix + `/export-ewa-settlement/${id} `}  */ className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div>

            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">TXN ID. {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('txn_id') }}></i>} </th>
                                            <th className="font-weight-bold">Merchant Detail. {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>} </th>
                                            <th className="font-weight-bold">Paid Amount {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('amount') }}></i>}</th>
                                            <th className="font-weight-bold">ROI  {sortedBy == "deduction_amount_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_amount_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_amount_limit') }}></i>}</th>
                                            <th className="font-weight-bold">Merchant Commission  {sortedBy == "deduction_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }}></i>} </th>
                                            <th className="font-weight-bold">Thirdroc Income  {sortedBy == "deduction_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }}></i>} </th>
                                            <th className="font-weight-bold">Paid Date   {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                            <th className="font-weight-bold">Status </th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <div  >
                                            <Loader /> </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { first_name, last_name, mobile_number, txn_id, thirdroc_income, paid_amount, merchant_settled_amount, mrch_commission, thirdroc_roi, amount, deduction_amount_limit, deduction_code, updated_at, status } = data;

                                                    return (
                                                        <tr key={serial_num}>
                                                            <td>{txn_id ? txn_id : "NA"}
                                                                <span><CopyToClipboard text={txn_id} ><button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button></CopyToClipboard></span></td>
                                                            <td>{data.merchantDetail.full_name ? data.merchantDetail.full_name : "NA"} <br />{data.merchantDetail.company_name ? data.merchantDetail.company_name : ""} </td>
                                                            <td>{paid_amount ? "$" + paid_amount : "NA"}</td>
                                                            <td>{thirdroc_roi ? thirdroc_roi + "%" : "NA"}</td>
                                                            <td>{mrch_commission ? "$" + mrch_commission : "NA"}</td>
                                                            <td>{thirdroc_income ? "$" + thirdroc_income : "NA"}</td>
                                                            <td>{TimeZone(updated_at).timeZone}</td>
                                                            <td><span className="font-weight-normal1"></span>{status}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>



        </div>
    )
}

export default SettlementById
