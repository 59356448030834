import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import PaginationComponent from '../../../PaginationComponent';
import { deleteApi, getApi, postApi } from '../../service/adminService';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';

const StripePlan = () => {
    const [id, setId] = useState('')
    const [planName, setPlanName] = useState('')
    const [amount, setAmount] = useState('')
    const [interval, setIntervals] = useState('')
    const [trailPeriod, setTrailPeriod] = useState('')
    const [description, setDescription] = useState('')
    const [currency, setCurrency] = useState('')
    // const [benefits, setBenefits] = useState('')
    const [pagesCount, setpagesCount] = useState(1);
    const [submitButton, setSubmitButton] = useState(false)
    const [search, setSearch] = useState('');
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [interval, setInterval] = useState('')
    const [dataArray, setDataArray] = useState([]);
    const tableRef = useRef(null);
    const [addBenefits, setaddBenefits] = useState([]);
    const [HandleName, setHandleName] = useState('')
    // const Admin = JSON.parse(localStorage.getItem("admin"));
    // const token = Admin.admin_token;
    const closemodal = useRef()

    const viewPlan = async () => {
        try {
            const response = await getApi('/wfm-plan-list');
            // console.log(response, "***************************");
            if (response.status) {
                setDataArray(response.plansWithProducts)
                setTotalPagesCount(response.count)
            } else {
                console.log(response)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        viewPlan()
    }, [search, pagesCount])

    const AddForm = useFormik({
        initialValues: {
            planName: planName ? planName : "",
            planDescription: description ? description : "",
            planPrice: amount ? amount : "",
            planCurrency: currency ? currency : "",
            planInterval: interval ? interval : "",
            planTrialPeriod: trailPeriod ? trailPeriod : "",
            benefits: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            planName: yup
                .string()
                .required('Please Enter Plan Name')
                .matches(/^\S.*$/, 'Plan Name cannot start with a space')
                .matches(/^[\w\s]+$/, 'Plan Name cannot contain special characters'),
            planDescription: yup
                .string()
                .required('Please Enter Plan Description')
                .matches(/^\S.*$/, 'Plan Description cannot start with a space')
                /* .matches(/^[\w\s]+$/, 'Plan Description cannot contain special characters') */,
            planPrice: yup
                .string()
                .matches(/^\d{1,5}$/, 'Plan Price must be up to 5 digits')
                .typeError('Please Enter a valid Plan Price')
                .required('Please Enter Plan Price'),
            planCurrency: yup
                .string()
                .required('Please select Currency'),
            planInterval: yup
                .string()
                .required('Please select Plan Interval'),
            planTrialPeriod: yup
                .string()
                .matches(/^\d{1,3}$/, 'Plan Trail Period must be up to 3 digits')
                .nullable()
                .test('is-valid-trial-period', 'Please Enter a valid Plan Trial Period', value => {
                    if (!value) return true; // Not mandatory, so pass if empty
                    return /^[0-9]*$/.test(value);
                }),
            benefits: addBenefits == 0 && yup.string()
                .required("Please Enter Benefits")
                .matches(/^\S.*$/, 'Benefits cannot start with a space')

        }),
        onSubmit: async (values, { resetForm }) => {
            setSubmitButton(true)
            const url = HandleName === "Edit Plan" ? `/edit-wfm-plan/${id}` : "/create-wfm-plan";
            const data = {
                planName: values.planName,
                planDescription: values.planDescription === "" ? null : values.planDescription,
                planPrice: values.planPrice ? values.planPrice : null,
                planCurrency: values.planCurrency,
                planInterval: values.planInterval !== 'Every 3 Month' && values.planInterval !== 'Every 6 Month' ? values.planInterval : "month",
                interval_count: values.planInterval === 'Every 3 Month' ? 3 : values.planInterval === 'Every 6 Month' ? 6 : null,
                planTrialPeriod: values.planTrialPeriod,
                benefits: addBenefits.length > 0 ? JSON.stringify(addBenefits) : ""
            };
            const response = await postApi(url, data);
            // const response = await postApi("/create-wfm-plan", {
            //     planName: values.planName,
            //     planDescription: values.planDescription === "" ? null : values.planDescription,
            //     planPrice: values.planPrice ? values.planPrice * 100 : null,
            //     planCurrency: values.planCurrency,
            //     planInterval: values.planInterval !== 'Every 3 Month' && values.planInterval !== 'Every 6 Month' ? values.planInterval : "month",
            //     interval_count: values.planInterval === 'Every 3 Month' ? 3 : values.planInterval === 'Every 6 Month' ? 6 : null,
            //     planTrialPeriod: values.planTrialPeriod,
            //     benefits: JSON.stringify(addBenefits) != "[]" ? JSON.stringify(addBenefits) : ""
            // });

            if (response.status) {
                viewPlan();
                toast.success(response.message);
                resetForm({ values: '' });
                closemodal.current.click();
                setaddBenefits([])
                setSubmitButton(false)
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    });

    const currencies = [
        { code: 'usd', name: 'US Dollar' },
        { code: 'inr', name: 'Indian Rupee' },
        { code: 'aud', name: 'Australian Dollar' },
        { code: 'nzd', name: 'New Zealand Dollar' },
    ];

    const handleUpdatedetial = () => {
        setaddBenefits([])
        setId('')
        setAmount('')
        setCurrency('')
        setPlanName('')
        setDescription('')
        setIntervals('')

        AddForm.setFieldValue('planName', '')
        AddForm.setFieldValue('planDescription', '')
        AddForm.setFieldValue('planPrice', '')
        AddForm.setFieldValue('planCurrency', '')
        AddForm.setFieldValue('planInterval', '')
        AddForm.setFieldValue('planTrialPeriod', '')
    }

    const addSkill = () => {
        const NewBenefits = AddForm.values.benefits;
        setaddBenefits(prevSkills => [...prevSkills, NewBenefits]);

        AddForm.setFieldValue('benefits', "");
        // console.log("🚀 ~ addSkill ~ addBenefits:", addBenefits)
    }

    const removeName = (index) => {
        const newNames = [...addBenefits];
        newNames.splice(index, 1);
        setaddBenefits(newNames);
    };

    const handleDelete = (Plan_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // const response = await deleteEmployer(token, data)
                const response = await deleteApi(`/delete-wfm-plan/${Plan_id}`)
                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'Plan has been deleted Successfully.',
                        'success'
                    )
                    viewPlan()
                }
                else {
                    toast.error("something went wrong")
                }
            }
        })
    }

    console.log(id, planName, description, interval, amount)


    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Stripe Plan</h4>
                            </div>
                            <div className="page-rightheader">
                                {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                                <div className="btn-list">
                                    <Link to="" className="btn btn-primary btn-pill"
                                        onClick={() => setHandleName('Add Plan')}
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <i className="fa fa-plus me-1"></i> Add Plan</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                {/* <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="form-group">
                            <label className="form-label">All Status</label>
                            <div className="row g-xs">
                                <div className="wd-200 mg-b-30">
                                    <div className="input-group">
                                        <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                            <option>Select Status</option>
                                            <option value="all task" >All Status</option>
                                            <option value="Assigned"  >Assigned</option>
                                            <option value="Unassigned" >Unassigned</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Plan</th>
                                                        <th className="font-weight-bold">Amount</th>
                                                        <th className="font-weight-bold">Interval</th>
                                                        {/* <th className="font-weight-bold">Currency Type</th> */}
                                                        <th className="font-weight-bold">Trial Period</th>
                                                        <th className="font-weight-bold">Description</th>
                                                        <th className="font-weight-bold">Benefits</th>
                                                        <th className="font-weight-bold">Action</th>
                                                    </tr>
                                                </thead>
                                                {dataArray?.length > 0 && dataArray?.map((Item, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    const { amount, interval, currency, trial_period_days } = Item;
                                                    const benefits = Item?.benefits ? JSON.parse(Item.benefits) : [];
                                                    const benefitsList = benefits?.map((benefit, index) => <li key={index}>{benefit},</li>);
                                                    return (
                                                        <tbody>
                                                            <tr key={serial_num}>
                                                                <td><span className="font-weight-normal1">{Item?.product?.name ? Item?.product?.name : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">
                                                                    {Item?.amount ? Item?.currency == "inr" ? "₹" + Item?.amount / 100 : "$" + Item?.amount / 100 : ""}
                                                                    {/*  {Item?.amount ? "$" + amount / 100 : "--"} */}</span></td>
                                                                <td><span className="font-weight-normal1">
                                                                    {Item?.interval ? Item?.interval_count > 1 ? `Every ${Item?.interval_count} ${Item?.interval}` : Item?.interval : "--"}
                                                                    {/* {Item?.interval ? interval : "--"} */}
                                                                </span></td>
                                                                {/* <td><span className="font-weight-normal1">{Item?.currency ? currency : "--"}</span></td> */}
                                                                <td><span className="font-weight-normal1">{Item?.trial_period_days ? trial_period_days + " Days" : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{Item?.product?.description ? Item?.product?.description : "--"}</span></td>
                                                                {/* <td><span className="font-weight-normal1">{Item?.product?.benefits ? JSON.stringify.Item?.product?.benefits : "--"}</span></td> */}
                                                                <td><span className="font-weight-normal1">{benefits.length > 0 ? <ul>{benefitsList}</ul> : "--"}</span></td>
                                                                {/* <td><span className="font-weight-normal1"><Moment format="YYYY-MM-DD h:MM A">{"2024-05-21 12:10 PM"}</Moment></span></td> */}
                                                                <td><button className='btn btn-primary me-2'
                                                                    onClick={() => {
                                                                        setId(Item?.id); setPlanName(Item?.product?.name); setAmount(Item?.amount);
                                                                        setIntervals(Item?.interval); setTrailPeriod(Item?.trial_period_days);
                                                                        setDescription(Item?.product?.description); setCurrency(Item?.currency); setaddBenefits(benefits)
                                                                        setHandleName('Edit Plan')
                                                                    }}
                                                                    data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                                >Edit</button>
                                                                    <button className='btn btn-danger'
                                                                        onClick={() => { handleDelete(Item?.id) }} >Delete</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    )
                                                }
                                                )}
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}


                            {/*********************** Add and Update Plan Modal Start **************************/}

                            <div className="modal fade" id="staticBackdrop"
                                data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel">{HandleName}</h5>
                                            {/* <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">x</button> */}
                                        </div>
                                        <form onSubmit={AddForm.handleSubmit}>
                                            <div className="modal-body">
                                                <div className=" row">
                                                    <div className=" col-md-6 mb-3">
                                                        <label for="recipient-name" className="col-form-label">Plan Name:</label>
                                                        <input type="text" className="form-control" id="recipient-name" placeholder='Enter Plan Name'  {...AddForm.getFieldProps('planName')} />
                                                        {AddForm.touched.planName && AddForm.errors.planName ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.planName}</div> : ''}
                                                    </div>
                                                    <div className=" col-md-6 mb-3">
                                                        <label for="recipient-name" className="col-form-label">Plan Description:</label>
                                                        <input type="text" className="form-control" id="recipient-name" placeholder='Enter Plan Description' {...AddForm.getFieldProps('planDescription')} />
                                                        {AddForm.touched.planDescription && AddForm.errors.planDescription ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.planDescription}</div> : ''}
                                                    </div>
                                                    <div className=" col-md-6 mb-3">
                                                        <label for="recipient-name" className="col-form-label">Plan Currency:</label>
                                                        <select className="form-control" {...AddForm.getFieldProps('planCurrency')}>
                                                            <option value=''>Please Select Currencies </option>
                                                            {currencies.map(currency => (
                                                                <option key={currency.code} value={currency.code}>
                                                                    {/* {currency.code} - */} {currency.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {AddForm.touched.planCurrency && AddForm.errors.planCurrency ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.planCurrency}</div> : ''}
                                                    </div>
                                                    <div className=" col-md-6 mb-3">
                                                        <label for="recipient-name" className="col-form-label" style={{ textTransform: 'none' }}>Plan Price <span className='text-danger'>(in cents)</span>:</label>
                                                        <input type="number" className="form-control" id="recipient-name" placeholder='Enter Plan Price' {...AddForm.getFieldProps('planPrice')} />
                                                        {AddForm.touched.planPrice && AddForm.errors.planPrice ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.planPrice}</div> : ''}
                                                    </div>
                                                    <div className=" col-md-6 mb-3">
                                                        <label for="recipient-name" className="col-form-label" >Plan Interval:</label>
                                                        <select className="form-control" {...AddForm.getFieldProps('planInterval')}>
                                                            <option value=''>Select Interval</option>
                                                            <option value='day'>Day</option>
                                                            <option value='week'>Week</option>
                                                            <option value='month'>Month</option>
                                                            <option value='Every 3 Month'>Every 3 Month</option>
                                                            <option value='Every 6 Month'>Every 6 Month</option>
                                                            <option value='year'>Year</option>
                                                        </select>
                                                        {AddForm.touched.planInterval && AddForm.errors.planInterval ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.planInterval}</div> : ''}
                                                    </div>

                                                    <div className=" col-md-6 mb-3">
                                                        <label for="recipient-name" className="col-form-label">Plan Trial Period:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                            placeholder="Please Enter plan Trail Period"
                                                            {...AddForm.getFieldProps('planTrialPeriod')} />
                                                        {AddForm.touched.planTrialPeriod && AddForm.errors.planTrialPeriod ?
                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.planTrialPeriod}</div> : ''}
                                                    </div>

                                                    <div className="col-md-9">
                                                        <label>Benefits {/* {AddForm.touched.skills && !AddForm.errors.skills && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                                        <div style={{ position: 'relative' }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Add Benefits"
                                                                // value={inputNames}
                                                                // onChange={(e) => setInputNames([e.target.value])}
                                                                {...AddForm.getFieldProps('benefits')}
                                                                style={{ paddingRight: '30px' }} // Add padding for the icon
                                                            />
                                                            {AddForm.values.benefits.replace(/\s/g, "").length > 0 && <i
                                                                className="fas fa-plus text-primary "
                                                                style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                                                onClick={() => addSkill()}
                                                            />}
                                                            {AddForm.touched.benefits && AddForm.errors.benefits && addBenefits.length == 0 ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.benefits}</div> : ''}
                                                        </div>
                                                        {addBenefits != null && addBenefits.length > 0 && addBenefits.map((item, index) => {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                    className="m-1 px-1 py-1 rounded border text-white text-wrap"
                                                                    style={{ cursor: 'pointer', fontSize: '12px', backgroundColor: '#0F2F81' }}
                                                                >
                                                                    {item} <span onClick={() => removeName(index)}>&times;</span>
                                                                </span>
                                                            );
                                                        })}


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button ref={closemodal} type="button" className="btn btn-dark" onClick={() => handleUpdatedetial()} data-bs-dismiss="modal">Close</button>
                                                <button type="submit" className="btn btn-primary" disabled={submitButton}>{HandleName == "Add Plan" ? "Add" : "Update"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                            {/*********************** Add and Update Plan Modal End **************************/}

                        </div>

                    </div>
                </div>

            }
        </>
    )
}

export default StripePlan
