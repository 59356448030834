import React, { useEffect, useState } from 'react'
import QRCode from "react-qr-code";
import { getApi } from '../service/MerchantService';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/loader';
const saveSvgAsPng = require('save-svg-as-png')

const MerchantQRCode = () => {
    const [loader, setloader] = useState(true)
    const [Qrvalue, setQrvalue] = useState([])
    const navigate = useNavigate()
    const merchant = JSON.parse(localStorage.getItem("merchant"));

    const getProfile = async () => {
        const response = await getApi("/get-profile");
        if (response.status) {
            setTimeout(() => {
                setQrvalue(response.data.mobile_number)
                setloader(false)
            }, 2000);

        } else if (response.message == 'Session expired') {
            localStorage.removeItem("merchant");
            navigate('/merchant/login')

        }
    }
    useEffect(() => {
        getProfile(merchant.merchant_token)
    }, [])

    const imageOptions = {
        scale: 5,
        encoderOptions: 1,
        backgroundColor: 'white',
    }

    const downloadQR = async () => {
        var canvas = document.getElementById('myCanvasId');
        saveSvgAsPng.saveSvgAsPng(canvas, 'qr_code.png', imageOptions);
    };

    return (
        <>
            {loader == true ?
                <div style={{ marginLeft: '400px', marginTop: '200px' }}  >
                    <Loader /> </div> :
                <div className="main-container container-fluid px-0">
                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">QR Code</h4>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='text-center'>
                            <div style={{ height: "auto", margin: "0 auto", maxWidth: "15rem", width: "100%" }}>
                                <QRCode
                                    id="myCanvasId"
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={"" + Qrvalue}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <Link className='btn btn-primary mt-5'
                                onClick={() => downloadQR()} > Download QR</Link>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MerchantQRCode;