import Header  from '../website/header';
import Footer  from '../website/footer';
const KnowUs = () => {


    return (
       <>
        <Header/>
       <section className="pt-50 pb-50" style={{background: "rgb(251, 251, 251)" }}>
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-md-4">
                    <div className="card card-shadow card--team1">
                    <div className="card-body text-center">
                        <img src="website/assets/img/maharaj.png" alt="" className="teamimg" />
                        <h6><a href="/know-us">Shivnil Maharaj</a></h6>
                        <span className="team-designation">Co-Founder, CEO </span>
                        <ul className="team-social d-flex justify-content-center">
                            <li><a href="https://www.linkedin.com/in/riteshnain" target="blank"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card card-shadow card--team1">
                    <div className="card-body text-center">
                        <img src="website/assets/img/ritesh.png" alt="" className="teamimg" />
                        <h6><a href="/know-us">Ritesh Nain</a></h6>
                        <span className="team-designation">Co-Founder, CTO </span>
                        <ul className="team-social d-flex justify-content-center">
                            <li><a href="https://www.linkedin.com/in/riteshnain" target="blank"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        
        <section className="content-block p-top-50">
        <div className="container content_above">
            <div className="row justify-content-center align-items-center">
                <div className="col-lg-5">
                    <p>Thirdroc.ai, a technology company that specializes in providing credit risk analysis through buy-now-pay-later (BNPL), API &amp; Digital lending offerings. Thirdroc was co-founded by Shivnil Maharaj and Ritesh Nain, two seasoned professionals with strong domain expertise, solid technological skill &amp; a passion for leveraging technology to revolutionize the financial industry.</p>
                </div>
                <div className="col-lg-5 margin-md-60 orderimg"><img src="website/assets/img/Learning-And-Machine-Learning.svg" className="knowteamimg" alt="" /></div>
            </div>
        </div>
        </section>
        
        <section className="content-block p-top-50">
        <div className="container content_above">
            <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 margin-md-60"><img src="website/assets/img/Basiq-Payments.svg" className="knowteamimg" alt="" /></div>
                <div className="col-lg-5">
                    <p>Backed by the early-stage investment company Antler, Thirdroc.ai is dedicated to developing innovative solutions that enable businesses to manage and mitigate credit risk. Leveraging the latest advancements in machine learning and artificial intelligence, the company provides businesses with a comprehensive credit risk analysis platform that helps them make informed decisions about extending credit to their customers.</p>
                </div>
            </div>
        </div>
        </section>
        
        <section className="content-block p-top-50">
        <div className="container content_above">
            <div className="row justify-content-center align-items-center">
                <div className="col-lg-5">
                    <p>At Thirdroc.ai, we are committed to driving positive change in the financial industry by providing businesses with the tools they need to manage and mitigate credit risk in various ways. Our cloud based platform leverages the latest advancements in machine learning to provide businesses with a comprehensive credit risk analysis that helps them make informed decisions about extending credit to their customers.</p>
                </div>
                <div className="col-lg-5 margin-md-60 orderimg"><img src="website/assets/img/support-and-application.jpg" className="knowteamimg" alt="" /></div>
            </div>
        </div>
        </section>
        
        <Footer/>
        
       </>
    )
}

export default KnowUs;