const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)

// const AdminLoign = async (data) => {
//     try {
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");
//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/login", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }


// const Adminchangepassword = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/changePassword", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ Admin ~ error: ", error)
//     }

// }


// const GetProfile = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/admin-profile", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const AddSettlement = async (data) => {
//     try {
//         const merchant = JSON.parse(localStorage.getItem("admin"));
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${merchant.admin_token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-settlement", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

const GetBorrowerAdmin = async (token, pagesCount, search, sortby, orderBy, employeeDataID, date_Format) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        let requestOptions = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Timezone': Timezone,

            },
            redirect: 'follow'
        };
        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-borrower-list?page_number=${pagesCount}&search=${search}&sortby=${sortby}&orderby=${orderBy}&employer_id=${employeeDataID}&time_zone=${date_Format}`, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error);
    }
}

// const GetEmployeeborrower  = async (token , id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//              redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-employee-num/${id}`, requestOptions);
//         const result = await res.json();
//         // get-employeebyid
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const ViewEmployeeData = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-employee-byId/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const GetLenders = async (token = "", pageNumber = 1, searchVlu = "", sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-lender-list?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }
// }

// const GetLenderDetail = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/get-lender/" + id, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }
// }

// const AdminChangePasword = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/changeUserPasswordByAdmin", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ Merchant ~ error: ", error)
//     }

// }
// const changeStatus = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/verify/${data.id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ Merchant ~ error: ", error)
//     }

// }

// const changeNFCStatus = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/nfc-toggle/${data.id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ Merchant ~ error: changeStatus", error)
//     }

// }

// const GetMerchants = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-merchant-list?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const GetTalktoUs = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/talk-to-us?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }
// const GetmanageEWApending = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/ewa-merchant-dues?filter=&page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const MerchantDuesdetails = async (token = "", id, pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/ewa-merchant-dues-detail/${id}?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const MerchantBNPLDetail = async (token = "", id, pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/bnpl-transactions-detail/${id}?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:306 ~  error:", error)
//     }

// }

// const PaytoMerchant = async (token, data) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/ewa-pay-to-merchant", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:", error)
//     }

// }
// const Reconciliation = async (token, data) => {
//     try {
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/update-ewa-merchant-paid", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:Reconciliation", error)
//     }
// }

// const Getcities = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-all-country?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const GetLenderCommission = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-lender-commission?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const GetMerchantCommission = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-merchant-commission?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const GetEmployerEWAcharges = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/employer-ewa-charges?page=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const GetEmployer = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-employer-dropdown`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const UpdateLenderCommission = async (token = "", user_id, commission) => {
//     try {
//         let data = {
//             "user_id": user_id,
//             "mini_rate_of_intrest": commission
//         }
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/update-lender-commission`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

const postVerification = async (token = "", data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-borrwer", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
    }

}
// const Addborrower = async (token = "", data) => {
//     // alert("gdfgdfgf")
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/customer-detail-submit", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const UpdateMerchantCommission = async (token = "", user_id, commission) => {
//     try {
//         let data = {
//             "user_id": user_id,
//             "mini_rate_of_intrest": commission
//         }
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/update-merchant-commission", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const UpdateEmployerCommission = async (token = "", user_id, commission, Type) => {
//     try {
//         let data = {
//             "user_id": user_id,
//             "ewa_txn_charge": commission,
//             "ewa_txn_charge_type": Type,

//         }
//         // console.log(data)
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/update-employer-ewa-charges", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const UpdateEmployerPlatformCommission = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/update-employer-ewa-charges", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const selfAssignEmployee = async (token = "", updata) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(updata),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/assign-employer", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }

// const AssignEmployercheck = async (token = "", updata) => {
//     try {
//         // let data = {
//         //     "user_id":user_id,
//         //     "mini_rate_of_intrest":commission
//         // }
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(updata),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/assign-employer", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:AssignEmployercheck", error)
//     }

// }

// const PostSelfAssign = async (token = "",data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body:JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/assign-employer", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }
// const GetMerchantrDetail = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/get-merchant/" + id, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const GetTransactionDetail = async (token = "", id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/ewa-merchant-txn-detail/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const GetEmployers = async (token = "", pageNumber = 1, searchVlu = "", sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-employer-list?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
//     }

// }

// const GetEmployersdatas = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-employer-list`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }


// const GetEmployersdirect = async (token = "", pageNumber = 1, searchVlu) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-employer-list?page_number=${pageNumber}&search=${searchVlu}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const GetLenderInterest = async (token = "", pageNumber = 1, searchVlu = "", sortby, orderby) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/lender-interest?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortby}&orderby=${orderby}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const GetEmployerDetail = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/get-employer/" + id, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const getAllEmployeeByEmployer = async (token = "", id, search = "", pageno = 1, sortby, orderby) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-employee-by-employerId/${id}?search=${search}&page_number=${pageno}&sortby=${sortby}&orderby=${orderby}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }
// }



// const DeleteEmployees = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/deleteEmployee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const GetSettlement = async (token = "", page_number, search, sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-settlements?page_number=${page_number}&search=${search}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const GetTransaction = async (token = "", page_number, search, sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-bnpl-transactions?page_number=${page_number}&search=${search}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const GetBNPLSettlementDetails = async (token = "", page_number, search, sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-bnpl-settlements?page_number=${page_number}&search=${search}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const addSettlement = async (idArr) => {
//     try {


//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ merchatTransctionId: idArr }),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-settlment", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }


// const AddEmployeeDocument = async (token, data) => {
//     try {
//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/document-upload", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ AddEmployee ~ error:", error)
//     }

// }

const getEmployeeDocument = async (token, data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        var requestOptions = {
            method: 'POST',
            headers: {

                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                'Timezone': Timezone,
            },

            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/get-doc", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:31 ~ AddEmployee ~ error:", error)
    }

}

// updated in single function
// const GetDashboard = async (token, days, startDate, endDate) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/dashboard-admin?days=${days}&start_date=${startDate}&end_date=${endDate}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const GetAllPendingSettlement = async (token, pageNumber, searchVlu, sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/pending-settlement?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:831 ~ GetAllPendingSettlement ~ error:", error)
//     }

// }
// const GetAllTransactionsByMerchantId = async (token, merchantId, pageNumber, searchVlu) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/Pending-settalemntBy-merchantId/${merchantId}?page_number=${pageNumber}&search=${searchVlu}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:850 ~ GetAllTransactionsByMerchantId ~ error:", error)
//     }

// }

// const GetEmployee = async (token, pageNumber, searchVlu, sortby, orderby) => {
//     try {

//         let requestOptions = {
//             method: "GET",
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-employee-list?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortby}&orderby=${orderby}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:870 ~ GetEmployee ~ error:", error);
//     }
// }

// const viewplandetail = async (/* token,search , pageCount = 1 */) => {
//     const Admin = JSON.parse(localStorage.getItem("admin"));
//     const token = Admin.admin_token;
//     try {
//         let requestOptions = {
//             method: "GET",
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/list-create-emi-plans?search=&page_number=1`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:888 ~ viewplandetail ~ error:", error)
//     }
// }

// const DeleteEmployeePlan = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-create-emi-plans/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:907 ~ DeleteEmployeePlan ~ error:", error)
//     }

// }

// const getEmiplanbyID = async (token, id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/create-emi-plans-byId/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:929 ~ getEmiplanbyID ~   error:", error)
//     }

// }

// const GetlenderEmployeeDetailview = async (token, id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-employee-by-lenderId/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:949 ~ GetlenderEmployeeDetailview ~ error:", error)
//     }

// }

// const GetEmployeeDetail = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/gethome", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:969 ~ error:", error)
//     }

// }

// const GetPaidTransactiondetail = async (token = "", search, pagesCount, sortby, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             redirect: "follow"
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/ewa-merchant-paid?search=${search}&page_number=${pagesCount}&sortby=${sortby}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:990 ~ GetPaidTransactiondetail ~ error: ", error)
//     }
// }


// const SettlementBYid = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/bnpl-settlements-detail/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }

// const getEmployeeLoginlogs = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/employee-login-logs?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }

// const getUserLoginlogs = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/users-login-logs?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }
// const GetReconciliation = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-reconciliation?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }

// const AddEmployerOnboard = async (token, data) => {
//     try {
//         console.log(data, "dataaaaaaaaa")
//         var requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-employer", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AddEmployerOnboard ~ error:", error)
//     }
// }

// const AddLenderOnboarding = async (token, data) => {
//     try {
//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-lender", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AddEmployerOnboard ~ error:", error)
//     }
// }

// const Merchantonboarding = async (token, data) => {
//     try {
//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-merchant", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AddEmployerOnboard ~ error:", error)
//     }
// }

// const EditEmployerDetail = async (token = "", data, id) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/edit-employer/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:1144 ~ EditEmployerDetail ~ error:", error)
//     }
// }

// const EditMerchantDetail = async (token = "", data, id) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/edit-merchant/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:1166 ~ EditMerchantDetail ~ error:", error)
//     }
// }

// const EditLenderDetail = async (token = "", data, id) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/edit-lender/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:1188 ~ EditLenderDetail ~ error:", error)
//     }
// }

// const deleteEmployer = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-employer/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AdminService ~ error:", error)
//     }
// }

// const deleteLender = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-lender/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AdminService ~ error:", error)
//     }
// }

// const deleteMerchant = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-merchant/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AdminService ~ error:", error)
//     }
// }
// const GetEwaEarningDetails = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/ewa-earning?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }

// const GetEwaEarningDetailsbyID = async (token, id) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/ewa-earning-detail/${id}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }

// const GetBNPLEarningDetails = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/bnpl-earning?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }

// const GetBNPLEarningDetailsbyID = async (token, id) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/bnpl-earning-detail/${id}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }

// const GetAttendancebyID = async (token = "", id, date, year) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/borrower-attendance/${id}?month=${date}&year=${year}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const GetEmployeeTransactionDetail = async (token = "", id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/borrower-transaction?c_id=${id}&search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const GetEmployeeallTXNDetail = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/borrower-transaction?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:306 ~ error:", error)
//     }

// }

// const GetEmployeeWalletTransaction = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/borrower-wallet/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:306 ~ error:", error)
//     }

// }

// const manageEmployeelimit = async (token = "", data, id) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/manage-borrower-wallet/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:1188 ~ EditLenderDetail ~ error:", error)
//     }
// }


// const Addpromotion = async (token, data) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-promotion", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:", error)
//     }

// }

// const Promotiondetail = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/promotion-banner-list?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const deletePromotion = async (token, id) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-promotion-banner/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AdminService ~ error:", error)
//     }
// }

// const EditPromotionDetail = async (token = "", data, id) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/edit-promotion/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:1166 ~ EditMerchantDetail ~ error:", error)
//     }
// }

// const GetmanageWalletpending = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/wallet-merchant-dues?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const GetWalletTXNDetail = async (token = "", id, pageNumber = 1, search, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/wallet-merchant-dues-detail/${id}?page_number=${pageNumber}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const PaytoWalletMerchant = async (token, data) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/pay-merchant-wallet-dues", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:", error)
//     }

// }

// const getWalletPaidTransaction = async (token = "", search, pagesCount, sortby, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             redirect: "follow"
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/wallet-paid-transaction?search=${search}&page_number=${pagesCount}&sortby=${sortby}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:990 ~ GetPaidTransactiondetail ~ error: ", error)
//     }
// }


// const GetWalletDetailByID = async (token = "", id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/wallet-merchant-txn-detail/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const PaidWalletUpdate = async (token, data) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/update-wallet-merchant-paid", requestOptions);

//         const result = await res.json();


//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:Reconciliation", error)

//     }

// }


// const getAttendaceErrorlogs = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-type": "application/json",
//             },
//             redirect: 'follow'
//         };
//         const response = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/attendance-error-log?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions)
//         const result = await response.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1116 ~ SettlementBYid ~ error:", error)
//     }
// }

// const getErrorMessage = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/ewa-display-message`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1907 ~ getErrorMessage ~ error:", error)
//     }

// }

// const EditAgreeMessage = async (token, data) => {
//     try {    

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-display-message", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1927 ~ EditAgreeMessage ~ error:", error)
//     }

// }

// const GetLoanCasesDetail = async (token, search, pagesCount, sortedBy, orderBy, id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-loan-application?lender_id=${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1951 ~ GetLoanCasesDetail ~ error:", error)
//     }
// }

// const ViewEmployeeDetail = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/user-loan-application/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1970 ~ ViewEmployeeDetail ~ error:", error)
//     }

// }

// const GetLender = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-lender-list`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1990 ~ GetLender ~ error:", error)
//     }
// }
// const GetsubemployeeDetail = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-sub-employer-list?parent_id=${id}&search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2008 ~ GetsubemployeeDetail ~ error:", error)
//     }
// }

// const GetsubLenderDetail = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-sub-lender-list?parent_id=${id}&search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2027 ~ GetsubLenderDetail ~ error:", error)
//     }
// }

// const GetstoreDetail = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-store-and-counter-list?parent_id=${id}&search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2046 ~ GetstoreDetail ~ error:", error)
//     }
// }

// const GetcounterDetail = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-counter-list?parent_id=${id}&search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2046 ~ GetstoreDetail ~ error:", error)
//     }
// }

// const ViewActivityLogs = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-loan-application-activity/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2084 ~ ViewActivityLogs ~ error:", error)
//     }

// }

// const Deletedocument = async (token, id) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-doc-img/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AdminService ~ error:", error)
//     }
// }

// const GetEmployertrashData = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-employers-list?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2008 ~ GetsubemployeeDetail ~ error:", error)
//     }
// }

// const RecoverEmployer = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/restore-employer/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:31 ~ RecoverEmployer ~ error:", error)
//     }
// }

// const GetLendertrashData = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-lenders-list?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2008 ~ GetsubemployeeDetail ~ error:", error)
//     }
// }

// const RecoverLender = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/restore-lender/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:31 ~ RecoverEmployer ~ error:", error)
//     }
// }

// const GetMerchanttrashData = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-merchants-list?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2008 ~ GetsubemployeeDetail ~ error:", error)
//     }
// }

// const RecoverMerchant = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/restore-merchant/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:31 ~ RecoverEmployer ~ error:", error)
//     }
// }

// const borrowerStatus = async (token, id) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             },

//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/manage-borrower-active-status/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:31 ~ RecoverEmployer ~ error:", error)
//     }

// }

// const sendnotification = async (token = "", data) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/banner-notification`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:1188 ~ EditLenderDetail ~ error:", error)
//     }
// }

// const GetMerchant = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-merchant-list`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }


// const AddCashback = async (token, data) => {
//     try {
//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/add-cashback-to-all", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:", error)
//     }

// }

// const GetCashbackdetail = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/main-bulk-cashback-txn?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }

// const GetCashbackDetailByID = async (token = "", id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/get-bulk-cashback-txn/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:", error)
//     }

// }



// const GetTransferRequest = async (token = "", search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/user-bank_tranfer_request?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ error:GetTransferRequest", error)
//     }

// }

// const UpdateTransferStatus = async (token, data) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/update-transfer-status", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:", error)
//     }

// }

// const deleteCashback = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/delete-bulk-cashback-txn/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ AdminService ~ error:", error)
//     }
// }

// const UpdateTransferCharges = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + "/set-transfer-charges", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~  error:", error)
//     }

// }



// const UpdateAssignLender = async (token = "", data) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + `/assign-loan-to-lender`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:2465 ~ UpdateAssignLender ~ error:", error)
//     }
// }

const getApi = (path, parameter = '', object = {}) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        const admin = JSON.parse(localStorage.getItem("admin"));
        let headers = { 'Authorization': `Bearer ${admin.admin_token}` }
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone, 'time_zone': date_Format }
        if (object) {
            headers = { ...headers, ...object }
        }

        const queryParams = parameter ? '?' + new URLSearchParams(parameter) : '';

        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + '' + path + queryParams, {
            method: 'GET',
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        reject(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((error) => {
                console.log(error)
            });
    });
}


const postApi = (path, body = {}, is_formData = false, object = { 'time_zone': date_Format, 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("admin"))?.admin_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        if (is_formData == false) {
            headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json'/* , 'Timezone': Timezone, 'time_zone': date_Format */ }
        } else {
            headers = { ...headers, /* 'Content-Type': 'multipart/form-data' */ }
        }
        if (object) {
            headers = { ...headers, ...object }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + '' + path, {
            method: 'POST',
            headers: { ...headers, 'Timezone': Timezone },
            body: is_formData ? body : JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}


const deleteApi = (path, body = {}, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("admin"))?.admin_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone, 'time_zone': date_Format }

        if (object) {
            headers = { ...headers, ...object }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX + '' + path, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}


export { deleteApi, GetBorrowerAdmin, getApi, postApi, getEmployeeDocument, postVerification };

