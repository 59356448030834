import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GetEmployerDetail, getApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';

const EmployeeDetail = (props) => {

    const [employeeDetail, setEmployeeDetail] = useState({});
    const [loader, setloader] = useState(true)
    console.log("🚀 ~ file: EmployerDetail.js:8 ~ EmployeeDetail ~ employeeDetail:", employeeDetail)
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const { id } = useParams();
    // const { Name, Email, Mobile, Working_since_date, Salary, House, Gender, Age, Role, Location, Home_address_permanent, Home_address_communication } = employeeDetail;
    const { company_name, full_name, mobile_number, email, age, website, address, gst_number, country, pay_cycle, platform_fee } = employeeDetail;
    const admin = JSON.parse(localStorage.getItem("admin"));


    const getEmployeesData = async (token, id) => {
        // const response = await GetEmployerDetail(token, id);
        const response = await getApi(`/get-employer/${id}`);

        if (response.status == true) {
            setTimeout(() => {
                setEmployeeDetail(response.data);
                setloader(false)
            }, 2000);

        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        getEmployeesData(admin.admin_token, id);
    }, [id]);

    return (
        <>
            {loader == true ?
                <div style={{ marginLeft: '400px', marginTop: '200px' }}  >
                    <Loader /> </div> :
                <div className="main-container container-fluid px-0">
                    <div className="page-header my-3 py-2">
                        <div className="modal-header w-100">
                            <div className="page-leftheader">
                                <h6 className="page-title mb-0 text-primary">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link className='breadcrumbslink' to={prefix + '/employer'}>Employer</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Details </li>
                                        </ol>
                                    </nav>
                                </h6>
                            </div>
                        </div>
                        <div className="modal-body application-modal">
                            <div className="row">
                                <div className="col-lg-12 col-xl-12">
                                    <div className="">
                                        <div className="main-content-body main-content-body-contacts">
                                            <div className="main-contact-info-header bg-contacthead">
                                                <div className="media">
                                                    <div className="media-body text-white">
                                                        <h4 className="text-white mb-4">Authorised Person Name: {full_name}</h4>
                                                        <p className="mb-1">Company Name : {company_name}</p>
                                                        <p className="">Mobile no  : {mobile_number}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-contact-info-body">
                                                <div className="media-list p-0">

                                                    <div className="media py-4 mt-0">
                                                        <div className="media-body">
                                                            {/* <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                            <div className="w-70"> <label>Mobile no :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? mobile_number : ''}</span> </div>
                                                        </div> */}
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                <div className="w-70"> <label>Email :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? email : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                <div className="w-70"> <label>Location :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? address : ''}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                <div className="w-70"> <label>Pay Cycle. :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? pay_cycle : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-globe fa-fw" /> </div>
                                                                <div className="w-70"> <label>Website :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? website : ''}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media py-4 border-top mt-0">
                                                        <div className="media-body">
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar fa-fw" /> </div>
                                                                <div className="w-70"> <label>PlateForm Fee :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? platform_fee : ''}</span> </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-globe-asia"></i> </div>
                                                                <div className="w-70"> <label>Country :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? country : ''}</span> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default EmployeeDetail