import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv';
import { Link, useNavigate } from 'react-router-dom';
import PaginationComponent from '../../../PaginationComponent';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { GetPermission, getApi, postApi } from '../../service/employService';
import { Oval } from 'react-loader-spinner';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader/loader';


const BnplTransaction = () => {
  const [empty, setempty] = useState(false)
  const [dsaDetail, setdsaDetail] = useState(false);
  const [multippleArray, setMultippleArray] = useState([]);
  const [totalAmoumt, setTotalAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [search, setSearch] = useState('');
  const [sortedBy, setSortedBy] = useState("customer_id")
  const [orderBy, setOrderBy] = useState('desc')
  const [pagesCount, setpagesCount] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(10);
  const [dataArray, setDataArray] = useState([]);
  const [imageUpload, setimageUpload] = useState(null)
  const [disable, setDisable] = useState(false);
  const [loader, setloader] = useState(true)
  const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
  const navigate = useNavigate();

  const employer = JSON.parse(localStorage.getItem("employer"));
  const employerId = employer.user_id;

  const getEmployeesRepayment = async (token) => {
    // const response = await GetEmployeesRepayment(token, employerId, pagesCount, search, sortedBy, orderBy);
    const response = await getApi("/get-employee-repayment", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

    try {
      if (response.status == true) {
        setTimeout(() => {
          setDataArray(response.data);
          setTotalPagesCount(response.allEmployeeCount)
          setloader(false)
          //setTotalPagesCount(response.data["count"])          
        }, 2000);
        setloader(true)
      } else if (response.message === 'Session expired') {
        localStorage.removeItem("employer");
        navigate('/employer/login')
      } else if (response.data) {
        setempty(true)
        console.log("successfull test")
      } else {
        console.log("get employees data response", response);
        toast.error(response.message)
        if (response.message == "Access denied! you're not allowed to access this tab") {
          navigate('/employer/dashboard')
        }
      }
    } catch (error) {
      console.log(error)
      if (error.message === 'Session expired') {
        localStorage.removeItem("employer");
        navigate('/employer/login')
      }
    }
  }

  const checheked = (item) => {
    return multippleArray.includes(item) ? 1 : 0;

  }

  const addSettlementForm = useFormik({
    initialValues: {

      //   merchant_id: datas,
      customer_ids: '',
      //   payment_mode: '',
      comment: "",
      image: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      comment: yup.string().required('Please add a comment'),
      //   payment_mode: yup.string().required('Please add a payment mode '),
      // image: yup.string().required('Please Select Image'),

    }),
    onSubmit: values => {
      const formData = new FormData();
      let emptyAtr = ""
      let arr = multippleArray
      arr.forEach((element, i) => {
        if (i == 0) {
          emptyAtr = element + ""
        }
        else {
          emptyAtr = emptyAtr + "," + element
        }

      });
      // console.log(emptyAtr, "???????????????????")
      formData.append("customer_ids", multippleArray);
      formData.append("comment", addSettlementForm.getFieldProps('comment').value);
      formData.append("image", imageUpload);
      postApi("/pay-to-lender", formData, true).then((res) => {
        console.log('res', res);
        if (res.status == true) {
          toast.success("Payment Done Successfully");
          getEmployeesRepayment(employer.employer_token);
          setMultippleArray([])
          setTotalAmount(0)
          setimageUpload()
        } else if (res.status == false) {
          toast.error(res.message)
        }

      }).catch((err) => {
        console.log(err)
      })
    }
  });

  const handleInputChange = (event) => {
    addSettlementForm.handleChange(event);
    if (event?.target?.value) {
      setDisable(true)
    }
  }


  const selectTransactions = async (e, id, amount) => {
    let checkArray = await multippleArray;
    let rowIndex = checkArray.findIndex(v => v == id);

    if (rowIndex > -1) {

      let newAmount = parseFloat(totalAmoumt) - parseFloat(amount);
      setTotalAmount(newAmount)
      checkArray.splice(rowIndex, 1);

    } else {
      checkArray.push(id);
      let newAmount = parseFloat(totalAmoumt) + parseFloat(amount)
      setTotalAmount(newAmount)
    }

    setMultippleArray(checkArray);
    setDataArray((old_data) => [...old_data]);

    if (dataArray.length == multippleArray.length) {
      setIsChecked(true);
    }
    if (dataArray.length != multippleArray.length) {
      setIsChecked(false);
    }

    // console.log(multippleArray)

  }

  function handleCheckClick() {
    if (isChecked == false) {
      let newdata = dataArray.map(v => parseInt(v.customer_id)).join(',')
      let arrayData = newdata.split(',').map(Number);

      let newAmount = (dataArray.reduce((a, v) => a = a + parseFloat(v.dues_amount), 0))
      setTotalAmount(newAmount)
      setMultippleArray((old_data) => [...arrayData]);
      setIsChecked(true);
    } else {
      setTotalAmount(0)
      setIsChecked(false);
      setMultippleArray([]);
    }
    setDataArray((old_data) => [...old_data]);

  }
  // console.log(multippleArray)
  // console.log(totalAmoumt)
  const headers = [
    { label: "Employee Code", key: "employee_code" },
    { label: "Deduction Code ", key: "deduction_code" },
    { label: "Employee Name", key: `first_name + " " + last_name` },
    { label: "Mobile Number", key: "mobile_number" },
    { label: "Total Repayment", key: "dues_amount" }
  ];

  const csvReport = {
    data: dataArray,
    headers: headers,
    filename: 'Repayment_Report.csv'
  };


  useEffect(() => {
    // setpagesCount(1);
    getEmployeesRepayment(employer.employer_token);
  }, [pagesCount, search, , sortedBy, orderBy]);

  const sortChange = (col) => {
    if (col === sortedBy) {
      setSortedBy(col);
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
    } else {
      setSortedBy(col)
      setOrderBy("desc")
    }
    // getEmployeesData(employer.employer_token);
  }
  var num2 = parseFloat(totalAmoumt).toFixed(2)
  const [permission_data, setPermission_data] = useState([])
  const GetPermissions = async (token) => {
    try {
      const response = await GetPermission(token);
      console.log('response', response.data);
      if (response.status) {
        setPermission_data(response.data);
      }
    } catch (error) {
      // toast.error("error :", error);
      console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
    }
  }
  useEffect(() => {
    if (user_type == "SUB-EMPLOYER") {
      GetPermissions(employer.employer_token);
    }
  }, []);

  // const employerId = employer.user_id;
  const user_type = employer.user_type
  // const role = JSON.parse(employer.role?.permissions)
  const permissions = permission_data?.permissions;
  if (permissions) {
    var role = JSON.parse(permissions);

  } else {

  }

  const [repayment_view_bnpl_list, setrepayment_view_bnpl_list] = useState(false)
  const [repayment_pay_to_lender_bnpl, setrepayment_pay_to_lender_bnpl] = useState(false)
  const [repayment_list_export, setrepayment_list_export] = useState(false)



  useEffect(() => {
    if (role) {
      console.log(role, "role")
      const options = role;
      console.log(options, "options")
      options.length > 0 && options.forEach((option) => {
        if (option.key == "repayment_view_bnpl_list") {
          setrepayment_view_bnpl_list(true);
        }
        if (options.key == "repayment_pay_to_lender_bnpl") {
          setrepayment_pay_to_lender_bnpl(true);
        }
        if (options.key == "repayment_list_export") {
          setrepayment_list_export(true);
        }
      });
    }
  }, [role]);

  return (
    <>
      {!dsaDetail &&

        <div className="main-container container-fluid px-0">
          <div className="page-header my-3 py-2">
            <div className="page-leftheader">
              <h4 className="page-title mb-0 text-primary">BNPL Repayments</h4>
            </div>
            <div style={{ marginLeft: '450px', marginTop: '40px' }}>
              {/* <h4 className="ml-2">{totalAmoumt}</h4> */}
              <h5 > {totalAmoumt ? `Total: $${num2}` : ""}</h5>
            </div>

            <div className="page-rightheader">
              <div className="btn-list">
                {(repayment_pay_to_lender_bnpl || user_type == "employer") && (<button className='btn btn-primary btn-pill mt-3' data-bs-target="#add_settlement"
                  data-bs-toggle="modal"
                  disabled={multippleArray.length > 0 ? false : true} >+ Pay to Lender</button>)}

                {/* <Link to={prefix + "/add_employer"} className="btn btn-outline-primary mt-3" ><i className="fa fa-plus me-2"></i> Add Employee</Link> */}
                {(repayment_list_export || user_type == "employer") && (<CSVLink {...csvReport} className="btn btn-primary btn-pill mt-3"><i className="fa fa-download me-2"></i>Export CSV</CSVLink>)}

              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
            {/*   <div className="form-group">
                      <label className="form-label">Loan Type</label>
                      <div className="row g-xs">
                          <div className="wd-200 mg-b-30">
                              <div className="input-group">
                                  <select className="form-control mb-0" name="loan_type"  value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)} >
                                      <option>Select Loan</option>
                                      <option value="1">BNPL</option>
                                      <option value="2">Digital Lending</option>
                                  </select>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Search</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* <div className="row">
              <div className="col-sm-12 col-lg-12">
                  <div className="card">
                      <div className="card-header br-0">
                          <h3 className="card-title">EMPLOYEE REPORT</h3>
                      </div>
                  </div>
              </div>
          </div> */}


          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover card-table table-vcenter text-nowrap" >
                      <thead className="border-bottom-0 pt-3 pb-3">
                        <tr>
                          {(repayment_pay_to_lender_bnpl || user_type == "employer") && (<>   {dataArray.length > 0 ? <th><input type="checkbox" name="customer_id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th> : ""}</>)}

                          <th className="font-weight-bold">Employee Code {sortedBy == "employee_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }}></i>}</th>
                          <th className="font-weight-bold">Deduction Code {sortedBy == "deduction_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }}></i>}</th>
                          <th className="font-weight-bold">Employee Name  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>}</th>
                          <th className="font-weight-bold">Mobile Number  {sortedBy == "mobile_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }}></i>}</th>
                          <th className="font-weight-bold">Total Repayment {sortedBy == "dues_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('dues_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('dues_amount') }}></i>}</th>
                          <th className="font-weight-bold">Action</th>
                        </tr>
                      </thead>
                      {loader == true ? <tbody>
                        <td /><td />
                        <div>
                          <Loader />
                        </div> </tbody> :
                        <>
                          <tbody>
                            {dataArray.length > 0 && dataArray.map((data, i) => {
                              let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                              const { customer_id, employee_code, mobile_number, deduction_code, dues_amount, first_name, last_name } = data;
                              return (
                                <tr key={serial_num}>
                                  {(repayment_list_export || user_type == "employer") && (<td><input type="checkbox" name={customer_id} checked={checheked(customer_id) === 1 ? true : false} onChange={(e) => selectTransactions(e, customer_id, dues_amount)} /></td>)}

                                  <td>{employee_code} </td>
                                  <td>{deduction_code}</td>
                                  {data.first_name == null ?
                                    <td>Null</td> :
                                    <td><Link className="text-primary" to={{ pathname: `${prefix}/viewemployee_detail/${customer_id}`, state: { customer_id } }} >
                                      <span className="font-weight-normal1">{first_name + " " + last_name}</span></Link></td>
                                  }
                                  <td>{mobile_number ? mobile_number : "NA"}</td>
                                  <td>{data.dues_amount ? "$" + dues_amount : "$0"}</td>
                                  <td><Link className="btn btn-primary" to={{ pathname: `${prefix}/bnpl_transaction_by_id/${customer_id}`, state: { customer_id } }} ><span className="font-weight-normal1">View TXN</span></Link></td>
                                </tr>
                              )
                            })
                            }
                          </tbody>

                          <tbody>
                            {dataArray.length == 0 &&
                              <tr>
                                <td colSpan={10} className='text-center'>No Data Available</td>
                              </tr>
                            }
                          </tbody>
                        </>}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {pagesCount > 0 && dataArray.length > 0 ?
              <>
                <div className="col-md-12">
                  <div className="card-body">
                    <PaginationComponent className="justify-content-center"
                      totalItems={totalPagesCount}
                      pageSize={10}
                      maxPaginationNumbers={3}
                      onSelect={(e) => setpagesCount(e)}
                    />
                  </div>
                </div>
              </>
              : ''}

          </div>

          <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content modal-content-demo">
                <div className="modal-header">
                  <h3 className="modal-title">Settlement</h3>
                  <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                    aria-hidden="true">×</span></button>
                </div>
                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                  <h4>  {totalAmoumt ? `Total: $${num2}` : ""}</h4>
                </div>
                <div className="modal-body application-modal">
                  <form className=" row" onSubmit={addSettlementForm.handleSubmit}>
                    {addSettlementForm.values.wrong_message ?
                      <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{addSettlementForm.values.wrong_message}</div>
                      : ''}
                    <div className="row">
                      <div className="row g-xs">
                        {/* <div className="col-12">
                        <select className="form-control" name="payment_mode" {...addSettlementForm.getFieldProps("payment_mode")}
                          placeholder="Enter Payment Mode"  >
                          <option value="null">Select One</option>
                          <option value="cash" >Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="bank transfer">Bank Transfer</option>
                        </select>
                        {addSettlementForm.touched.payment_mode && addSettlementForm.errors.payment_mode ?
                          <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.payment_mode}</div> : ''}
                      </div>*/}
                        <div className="col-12">
                          <input type="text" onKeyUp={(e) => handleInputChange(e)} placeholder='comment' {...addSettlementForm.getFieldProps("comment")} value={addSettlementForm?.comment?.values} className="form-control" />
                          {addSettlementForm.touched.comment && addSettlementForm.errors.comment ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.comment}</div> : ''}
                        </div>
                        <div className="input-group file-browser mb-5">
                          <input type="file" className="form-control browse-file" placeholder="Upload your logo" onKeyUp={(e) => handleInputChange(e)} onChange={(e) => setimageUpload(e.target.files[0])} />
                          {/* {addSettlementForm.touched.image && addSettlementForm.errors.image ?
                          <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.image}</div> : ''} */}
                        </div>
                      </div>
                    </div>
                    <div className="form-footer mt-2">
                      <button type="submit" className="btn btn-primary" data-bs-dismiss={"modal"} aria-hidden="true" disabled={!disable ? true : false} >
                        Ready Pay </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>


        </div>
      }
    </>
  )
}

export default BnplTransaction
