import { valueToPercent } from '@mui/base';
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup';
import { addNFCdetails, postApi } from '../../service/employService';
import { Breadcrumbs } from '../../../components';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const AddNFC = () => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate()
    const employer = JSON.parse(localStorage.getItem("employer"))
    const token = employer.employer_token
    const form = useFormik({
        initialValues: {
            nfc_id: "",
            site_name: "",
            other: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            nfc_id: yup.string().required('Please Enter NFC ID'),
            site_name: yup.string().required('Please Enter Site Name'),
            other: yup.string().required('Please Enter Other Details')
        }),
        onSubmit: async (values) => {
            // const data = JSON.stringify({
            //     nfc_id: values.nfc_id,
            //     site_name: values.site_name,
            //     other: values.other
            // })

            const response = await postApi("/add-nfc-detail", {
                nfc_id: values.nfc_id,
                site_name: values.site_name,
                other: values.other
            })
            // const response = await addNFCdetails(token, data)
            console.log(response)
            if (response.status == true) {
                toast.success(response.message)
                setTimeout(() => {
                    navigate(prefix + '/add_nfc_employees')
                }, 1000)

            } else if (response.status == false) {
                console.log(response)
                toast.error(response.message)
            }
        }
    })

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">

                    <h4 className="page-title mb-0 text-primary">

                        <Breadcrumbs order={{ first: { name: "NFC" }, second: { name: "Add NFC" } }} />
                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">NFC ID</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter NFC ID" {...form.getFieldProps("nfc_id")} />
                                </div>
                                {form.touched.nfc_id && form.errors.nfc_id ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.nfc_id}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Site Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Site Name" {...form.getFieldProps("site_name")} />
                                </div>
                                {form.touched.site_name && form.errors.site_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.site_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Other Info</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Other Details" {...form.getFieldProps("other")} />
                                </div>
                                {form.touched.other && form.errors.other ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.other}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Add</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddNFC
