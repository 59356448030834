import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Image } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { getApi, postApi } from '../service/adminService';
import Limitfilter from '../../components/Limitfilter/Limitfilter';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Search from '../../components/Search/Search';

const TxnSettlement = () => {
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loader, setloader] = useState(true)
    const { id } = useParams('');
    const S3_prefix = process.env.REACT_APP_S3_BUCKET_URL
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [Entrieslimit, setEntrieslimit] = useState("10")
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const ref = useRef()
    const [disable, setDisable] = useState(false);
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [merchantData, setMerchantData] = useState([])
    const [multippleArray, setMultippleArray] = useState([]);
    const [totalAmoumt, setTotalAmount] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [imageUpload, setimageUpload] = useState(null)
    const [datas, setDatas] = useState([])
    const [Count, setCount] = useState();
    const [ValidPagination, setValidPagination] = useState(false)
    const admin = JSON.parse(localStorage.getItem("admin"));

    const getWalletTXNDetail = async (token) => {
        // const response = await GetWalletTXNDetail(token, id, pagesCount, search, sortedBy, orderBy);
        const response = await getApi(`/wallet-merchant-dues-detail/${id}`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy, limit: Entrieslimit });

        console.log("fdgdgdgddfgdg", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows);
                setTotalPagesCount(response.data.count);
                setMerchantData(response.data.merchant)
                setDatas(response.data.merchant.user_id)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }


    useEffect(() => {
        // setpagesCount(1);
        getWalletTXNDetail(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy, Entrieslimit]);

    const Form = useFormik({
        initialValues: {
            merchantId: "",
            merchantTransctionId: "",
            payment_mode: '',
            comment: "",
            image: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            comment: yup.string().required('Please add a comment'),
            payment_mode: yup.string().required('Please add a payment mode '),
        }),

        onSubmit: async values => {
            // console.log(multippleArray, typeof (multippleArray), "??????????????????????##############3")
            const formData = new FormData();
            formData.append("merchant_id", datas);
            formData.append("merchantTransctionId[]", multippleArray);
            formData.append("payment_mode", Form.getFieldProps('payment_mode').value);
            formData.append("comment", Form.getFieldProps('comment').value);
            formData.append("image", imageUpload);
            // console.log(multippleArray)
            /* PaytoWalletMerchant(admin.admin_token, formData) */
            postApi("/pay-merchant-wallet-dues", formData, true).then((res) => {
                if (res.status == true) {
                    toast.success(res.message);
                    ref.current.click()
                    getWalletTXNDetail(admin.admin_token);
                    setMultippleArray([])
                    setTotalAmount(0)
                }
            }).catch((err) => {

            })
        }
    })
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const selectTransactions = async (e, id, amount) => {

        let checkArray = multippleArray;
        let rowIndex = checkArray.findIndex(v => v == id);

        if (rowIndex > -1) {

            let newAmount = parseFloat(totalAmoumt) - parseFloat(amount);
            setTotalAmount(newAmount)
            checkArray.splice(rowIndex, 1);

        } else {
            checkArray.push(id);
            let newAmount = parseFloat(totalAmoumt) + parseFloat(amount)
            setTotalAmount(newAmount)
        }

        setMultippleArray(checkArray);
        setDataArray((old_data) => [...old_data]);
        if (dataArray.length == multippleArray.length) {
            setIsChecked(true);
        }
        if (dataArray.length != multippleArray.length) {
            setIsChecked(false);
        }

    }

    function handleCheckClick() {
        if (isChecked == false) {
            let newdata = dataArray.map(v => parseInt(v.id)).join(',')
            let arrayData = newdata.split(',').map(Number);

            let newAmount = (dataArray.reduce((a, v) => a = a + parseFloat(v.merchant_settled_amount), 0))
            setTotalAmount(newAmount)
            setMultippleArray((old_data) => [...arrayData]);
            setIsChecked(true);
        } else {
            setTotalAmount(0)
            setIsChecked(false);
            setMultippleArray([]);
        }
        setDataArray((old_data) => [...old_data]);

    }

    var num3 = parseFloat(totalAmoumt).toFixed(2)

    const checheked = (item) => {

        return multippleArray.includes(item) ? 1 : 0;

    }
    const handleInputChange = (event) => {
        Form.handleChange(event);
        if (event?.target?.value) {
            setDisable(true)
        }
    }
    const copiedInfo = () => {
        toast.success("Copied")
    }

    // console.log(multippleArray)

    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Merchant Name: <span className=''>  {merchantData.company_name ? merchantData.company_name : "NA"} </span>  </h4>

                                {/*  {merchantData.logo == "" ? <Image style={{ width: 100, height: 100, borderRadius: '10px'  }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='' />
                                    : */} <Image className='ms-5' style={{ width: 100, height: 100, borderRadius: '10px' }} alt="..."
                                    src={S3_prefix + 'logo/' + merchantData.logo} />
                            </div>
                            <div style={{ marginLeft: '450px', marginTop: '10px' }}>
                                <h4 className="ml-2">{totalAmoumt ? `Total: ${num3}` : ""}</h4>

                            </div>
                            <div className='page-rightheader'>
                                <button className='btn btn-primary' data-bs-target="#add_settlement"
                                    data-bs-toggle="modal" disabled={multippleArray.length > 0 ? false : true} >+ Add Settlement</button>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" /> */}
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            <Limitfilter Entrieslimit={Entrieslimit} setEntrieslimit={setEntrieslimit} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />


                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap"/*  ref={tableRef} */>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        {dataArray.length > 0 && <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>}
                                                        <th className="font-weight-bold">TXN ID  {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                                                        <th className="font-weight-bold">Customer Details  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Employer   {sortedBy == "employer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                        <th className="font-weight-bold">TXN Charges  {sortedBy == "txn_charge" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_charge') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_charge') }}></i>}</th>
                                                        <th className="font-weight-bold">Thirdroc Income  {sortedBy == "thirdroc_income" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('thirdroc_income') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('thirdroc_income') }}></i>}</th>
                                                        <th className="font-weight-bold">Merchant Settled Amount  {sortedBy == "merchant_settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>

                                                    </tr>
                                                </thead>


                                                {loader == true ? <tbody>
                                                    <td /><td /><td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { first_name, last_name, mobile_number, txn_id, employer_name, created_at, employer_mobile, amount, txn_charge, thirdroc_income, merchant_settled_amount } = option;
                                                                var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });


                                                                return (
                                                                    <tr key={serial_num} >
                                                                        {dataArray.length > 0 && <td><input type="checkbox" name={option.id} checked={checheked(option.id) === 1 ? true : false} onChange={(e) => selectTransactions(e, option.id, option.merchant_settled_amount)} /></td>}
                                                                        <td><span className="font-weight-normal1"> {txn_id ? txn_id : "NA"} </span>
                                                                            <span>
                                                                                <CopyToClipboard text={txn_id} >
                                                                                    <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                                </CopyToClipboard>
                                                                            </span>
                                                                        </td>

                                                                        <td><span className="font-weight-normal1">{first_name ? first_name : "NA"}&nbsp;{last_name ? last_name : ""}<br />
                                                                            {mobile_number ? mobile_number : ""}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{employer_name ? employer_name : "NA"}
                                                                            <br />{employer_mobile ? employer_mobile : ""}</span></td>
                                                                        <td><span className="font-weight-normal1"> {amount ? "$" + amount : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">{txn_charge ? "$" + txn_charge : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">{thirdroc_income ? "$" + thirdroc_income : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">{merchant_settled_amount ? "$" + merchant_settled_amount : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(created_at).timeZone} </span></td>

                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody></>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={Entrieslimit}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>

                    <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header">
                                    <h3 className="modal-title">Settlement</h3>
                                    <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                        aria-hidden="true">×</span></button>
                                </div>
                                <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                                    <h4> {totalAmoumt ? `Total: ${"$" + totalAmoumt.toFixed(2)}` : ""}</h4>
                                </div>
                                <div className="modal-body application-modal">

                                    <form className="mt-1 row" onSubmit={Form.handleSubmit}>
                                        {Form.values.wrong_message ?
                                            <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{Form.values.wrong_message}</div>
                                            : ''}
                                        <div className="row">
                                            <div className="row g-xs">
                                                <div className="col-12">
                                                    <select className="form-control" name="payment_mode"
                                                        {...Form.getFieldProps("payment_mode")}
                                                        placeholder="Enter Payment Mode"  >
                                                        <option value="null">Select One</option>
                                                        <option value="cash" >Cash</option>
                                                        <option value="cheque">Cheque</option>
                                                        <option value="bank transfer">Bank Transfer</option>
                                                    </select>
                                                    {Form.touched.payment_mode && Form.errors.payment_mode ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.payment_mode}</div> : ''}
                                                </div>
                                                <div className="col-12">
                                                    <input type="text" onKeyUp={(e) => handleInputChange(e)} placeholder='comment' {...Form.getFieldProps("comment")} value={Form?.comment?.values} className="form-control" />
                                                    {Form.touched.comment && Form.errors.comment ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.comment}</div> : ''}
                                                </div>
                                                <div className="input-group file-browser mb-5">
                                                    <input type="file" onKeyUp={(e) => handleInputChange(e)} className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                                                </div>
                                                {/* {Form.touched.image && Form.errors.image ?
                                                 <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.image}</div> : ''} */}
                                            </div>
                                            <div className="form-footer mt-2">
                                                <button type="submit" className="btn btn-primary" aria-hidden="true" disabled={!disable ? true : false}>
                                                    Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            }

        </>
    )
}

export default TxnSettlement
