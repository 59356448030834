import { Image } from 'antd'
import React from 'react'

const Documents = ({ bank, employ, income, loan, pay, photo, tin, signature, companyID, vendor_Invoice, country_id }) => {

    return (
        <div style={{ marginTop: "30px" }}>
            <h6>Documents</h6>
            <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                <div className="media-list p-0">
                    <div className="row row-cols-1 row-cols-md-2 g-4">

                        <div className="col">
                            <div className="card " style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Signature</h3>
                                </div>
                                {signature.map((Signature, index) => {
                                    return (
                                        <>
                                            {Signature?.file_type === '.pdf' || Signature?.file_type === '.PDF' || Signature?.file_type === '.docx' ? (
                                                <a href={Signature.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Signature ? Signature.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                            )} </>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col">
                            <div className="card  " style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Photo ID</h3>
                                </div>
                                {photo.map((Photo, index) => {
                                    return (
                                        <>
                                            {(Photo?.file_type === '.pdf' || Photo?.file_type === '.PDF' || Photo?.file_type === '.docx') ? (
                                                <a href={Photo.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Photo ? Photo.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                            )}
                                        </>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col">
                            <div className="card  " style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Bank Statement</h3>
                                </div>
                                {bank.map((Bank, index) => {
                                    return (
                                        <>
                                            {(Bank?.file_type === '.pdf' || Bank?.file_type === '.PDF' || Bank?.file_type === '.docx') ? (
                                                <a href={Bank.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Bank ? Bank.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                            )}</>
                                    )
                                })}

                            </div>
                        </div>
                        <div className="col">
                            <div className="card" style={{ border: "1px solid silver" }} >
                                <div className="card-body" style={{ background: '#4239a4' }}>
                                    <h3 className="card-title text-light ">Pay Slip</h3>
                                </div>
                                {pay.map((Pay, index) => {
                                    return (
                                        <>
                                            {(Pay?.file_type === '.pdf' || Pay?.file_type === '.PDF' || Pay?.file_type === '.docx') ? (
                                                <a href={Pay.doc_file} download>
                                                    <img
                                                        src="/assets/img/pdf.jpeg"
                                                        style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                        className="card-img-top m-1"
                                                        alt="PDF"
                                                    />
                                                </a>
                                            ) : (
                                                <Image src={Pay ? Pay.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                            )}</>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {country_id != 5 &&
                        <div className="row row-cols-1 row-cols-md-2 g-4">
                            <div className="col">
                                <div className="card  " style={{ border: "1px solid silver" }} >
                                    <div className="card-body" style={{ background: '#4239a4' }}>
                                        <h3 className="card-title text-light ">Income Statement</h3>
                                    </div>
                                    {income.map((Income, index) => {
                                        return (
                                            <>
                                                {(Income?.file_type === '.pdf' || Income?.file_type === '.PDF' || Income?.file_type === '.docx') ? (
                                                    <a href={Income.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                    </a>
                                                ) : (
                                                    <Image src={Income ? Income.doc_file : ''}
                                                        style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..."
                                                    />)}
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                            <div className="col">
                                <div className="card " style={{ border: "1px solid silver" }} >
                                    <div className="card-body" style={{ background: '#4239a4' }}>
                                        <h3 className="card-title text-light ">Loan Statement</h3>
                                    </div>
                                    {loan.map((Loan, index) => {
                                        return (
                                            <>
                                                {(Loan?.file_type === '.pdf' || Loan?.file_type === '.PDF' || Loan?.file_type === '.docx') ? (
                                                    <a href={Loan.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                    </a>
                                                ) : (
                                                    <Image src={Loan ? Loan.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                )}</>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>}
                    {country_id != 5 &&
                        <div className="row row-cols-1 row-cols-md-2 g-4">
                            <div className="col">
                                <div className="card  " style={{ border: "1px solid silver" }}>
                                    <div className="card-body" style={{ background: '#4239a4' }}>
                                        <h3 className="card-title text-light ">Tin Number</h3>
                                    </div>
                                    {tin.map((Tin, index) => {
                                        return (
                                            <>
                                                {(Tin?.file_type === '.pdf' || Tin?.file_type === '.PDF' || Tin?.file_type === '.docx') ? (
                                                    <a href={Tin.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                    </a>
                                                ) : (
                                                    <Image src={Tin ? Tin.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                )}</>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col">
                                <div className="card" style={{ border: "1px solid silver" }} >
                                    <div className="card-body" style={{ background: '#4239a4' }}>
                                        <h3 className="card-title text-light ">Employment Letter</h3>
                                    </div>
                                    {employ.map((Employ, index) => {
                                        return (
                                            <>
                                                {(Employ?.file_type === '.pdf' || Employ?.file_type === '.PDF' || Employ?.file_type === '.docx') ? (
                                                    <a href={Employ.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                    </a>
                                                ) : (
                                                    <Image src={Employ ? Employ.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                )}

                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>}


                    {country_id == 5 &&
                        <div className="row row-cols-1 row-cols-md-2 g-4">
                            <div className="col">
                                <div className="card  " style={{ border: "1px solid silver" }} >
                                    <div className="card-body" style={{ background: '#4239a4' }}>
                                        <h3 className="card-title text-light ">Company ID Certificate</h3>
                                    </div>
                                    {companyID.map((CompanyID, index) => {
                                        return (
                                            <>
                                                {(CompanyID?.file_type === '.pdf' || CompanyID?.file_type === '.PDF' || CompanyID?.file_type === '.docx') ? (
                                                    <a href={CompanyID.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                    </a>
                                                ) : (
                                                    <Image src={CompanyID ? CompanyID.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                )}</>
                                        )
                                    })}

                                </div>
                            </div>
                            <div className="col">
                                <div className="card" style={{ border: "1px solid silver" }} >
                                    <div className="card-body" style={{ background: '#4239a4' }}>
                                        <h3 className="card-title text-light ">Vendor Invoice</h3>
                                    </div>
                                    {vendor_Invoice.map((Vendor, index) => {
                                        return (
                                            <>
                                                {(Vendor?.file_type === '.pdf' || Vendor?.file_type === '.PDF' || Vendor?.file_type === '.docx') ? (
                                                    <a href={Vendor.doc_file} download>
                                                        <img
                                                            src="/assets/img/pdf.jpeg"
                                                            style={{ width: '70px', height: '100px', border: '1px solid silver' }}
                                                            className="card-img-top m-1"
                                                            alt="PDF"
                                                        />
                                                    </a>
                                                ) : (
                                                    <Image src={Vendor ? Vendor.doc_file : ''} style={{ width: '70px', height: '100px', border: "1px solid silver" }} className="card-img-top m-1" alt="..." />
                                                )}

                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default Documents