import React, { useRef, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import QRCode from 'react-qr-code';
import { deleteApi, getApi, postApi } from '../../service/MerchantService';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { toast } from 'react-hot-toast';
import PaginationComponent from '../../../PaginationComponent';
import { Breadcrumbs } from '../../../components';
import Loader from '../../../components/Loader/loader';


const Counter = (props) => {
    const [loader, setloader] = useState(true)
    const saveSvgAsPng = require('save-svg-as-png')
    const [value, setValue] = useState('');
    const [counterid, setcounterid] = useState();
    const ref = useRef()
    const [needid, setNeedID] = useState()
    const [datasArray, setDatasArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [state, setState] = useState(false)
    const [error, setError] = useState(false);
    const [mbnumber, setmbnumber] = useState()
    const merchant = JSON.parse(localStorage.getItem("merchant"));
    const [showSecondModal, setshowSecondModal] = useState(false);
    const handleClose2 = () => { EditForm.resetForm(); setshowSecondModal(false); }
    const handleShow2 = (data) => {
        const { counter_number, full_name, mobile_number, unique_id, id } = data
        setmbnumber(mobile_number)
        setError(false)
        // console.log(data)
        setNeedID(data.user_id)
        EditForm.setFieldValue("counter_number", counter_number)
        EditForm.setFieldValue("full_name", full_name)
        EditForm.setFieldValue("mobile_number", mobile_number)
        EditForm.setFieldValue("unique_id", unique_id)
        EditForm.setFieldValue("id", id)

        setshowSecondModal(true);
    }
    // let location = useLocation().state
    const navigate = useNavigate()
    const getCounterDetail = async (token) => {
        try {
            const response = await getApi(`/counter-list/0`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
            if (response?.status) {
                setTimeout(() => {
                    setDatasArray(response.data.rows)
                    setTotalPagesCount(response.data.count)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("merchant");
                navigate('/merchant/login')
            } else {
                console.log("get merchant data response", response);
            }
        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        getCounterDetail(merchant.merchant_token)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const qrValues = (id) => {
        setValue(id)
    }
    const imageOptions = {
        scale: 5,
        encoderOptions: 1,
        backgroundColor: 'white',
    }
    const downloadQR = async () => {
        var canvas = document.getElementById('myCanvasId');
        saveSvgAsPng.saveSvgAsPng(canvas, 'qr_code.png', imageOptions);
    };

    const EditForm = useFormik({
        initialValues: {
            counter_number: "",
            full_name: "",
            mobile_number: "",
            unique_id: "",
            id: '',
            user_id: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            mobile_number: yup.number().typeError('Please Enter A Valid Number').required('Please Enter Amount'),
            full_name: yup.string().required('Please Enter Name'),
            counter_number: yup.string().required('Please Enter Counter number'),
        }),
        onSubmit: async (values, actions) => {
            let { mobile_number, full_name, counter_number, user_id, unique_id, id } = values
            let response = await postApi("/update-counter", {
                counter_id: `${needid}`,
                name: full_name,
                mobile_number: mobile_number,
                counter_number: `${counter_number}`,
            })

            if (response.status == true) {
                setState(!state)
                toast.success("Edit Successfully");
                getCounterDetail(merchant.merchant_token)
                EditForm.resetForm()
            } else if (response.status == false) {
                toast.error(response.message)
            }
            setshowSecondModal(false);
        }
    });

    const handleDelete = (data) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await deleteApi(`/delete-counter/${data}`)
                    if (response?.status) {
                        Swal.fire(
                            'Deleted!',
                            'Your Counter has been deleted.',
                            'success'
                        )

                        getCounterDetail(merchant.merchant_token)

                    }
                    else {
                        toast.error("something went wrong")
                    }

                }
            })
        } catch (error) {
            console.log(error)
        }

    }

    const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;

    const form = useFormik({
        initialValues: {
            user_id: "",
            new_password: "",
            confirm_password: "",
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            new_password: yup.string().required('Please Enter New Password'),
            confirm_password: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("new_password"), null], "Password Must Be Same"),

        }),
        onSubmit: async (values, { resetForm }) => {
            console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
            const response = await postApi("/user-change-password", {
                user_id: counterid,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            })
            resetForm({ values: '' });
            ref.current.click()
            if (response.status == false) {
                console.log(response.message);
                toast.error(response.message);
                ref.current.click()
            }
            if (response.status == true) {
                console.log(response.message);
                toast.success(response.message);
                ref.current.click()
            } else {
                console.log("#@#@#1234", response);
            }

        }
    });
    const [values, setValues] = React.useState({
        new_password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        new_password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',

    }

    const eyeBtnStyle2 = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle3 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',
    }

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbs order={{ first: { name: "Counter", path: `${prefix}/counter` }/* , second: { name: "Add Store" } */ }} />

                        </h4>
                    </div>

                    <div className="page-rightheader">
                        <div className="btn-list">
                            <Link to={props.prefix + "/store_addcounter"} className="btn btn-outline-primary" ><i className="fa fa-plus"></i> Add Counter</Link>                    </div>
                    </div>

                </div>

                <div className="row">
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                            <thead className="border-bottom-0 pt-3 pb-3">
                                <tr>
                                    {/* <th className="font-weight-bold">S No.</th> */}
                                    <th className="font-weight-bold">Counter No.   {sortedBy == "counter_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('counter_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('counter_number') }}></i>}</th>
                                    <th className="font-weight-bold">Name   {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('full_name') }}></i>}</th>
                                    <th className="font-weight-bold">Mobile No. {sortedBy == "mobile_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }}></i>}</th>
                                    <th className="font-weight-bold">View QR </th>
                                    <th className="font-weight-bold">Actions </th>
                                    {/* <th className="font-weight-bold"> </th> */}
                                    {/* <th className="font-weight-bold"> </th> */}
                                </tr>
                            </thead>
                            {loader == true ? <tbody>
                                <td /><td />
                                <Loader />
                            </tbody> :
                                <>
                                    <tbody>
                                        {datasArray?.length > 0 && datasArray?.map((data, i) => {
                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                            const { user_id, counter_number, name, full_name, mobile_number, id } = data;
                                            var timezone = new Date(data.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                            return (
                                                <tr key={serial_num}>

                                                    <td>{counter_number}</td>
                                                    <td>{full_name}</td>
                                                    <td>{mobile_number}</td>
                                                    <td> <span className="font-weight-normal1">
                                                        <button className='btn btn-primary' onClick={() => qrValues(mobile_number)}
                                                            data-bs-target="#pay_emi" data-bs-toggle="modal">view</button>
                                                    </span>
                                                    </td>
                                                    <td style={{ width: "1rem" }} >
                                                        {/* <button className="btn btn-primary" onClick={() => transactionbyCounterID(data.user_id)}><i className="fa-solid fa-user-pen"></i>View Counter</button> */}

                                                        <button onClick={() => { handleShow2(data) }} type="button" className="btn btn-success" style={{ border: 'none' }}><i className="fa-solid fa-user-pen"></i>{/* Edit */}</button> &nbsp;&nbsp;
                                                        <button onClick={() => { handleDelete(user_id) }} type="button" className="btn btn-danger "><i className='fas fa-trash' />{/* Delete */}</button> &nbsp;&nbsp;
                                                        <button onClick={() => { setcounterid(user_id) }} type="button" className="btn btn-primary"
                                                            data-bs-target="#add_settlement" data-bs-toggle="modal" ><i className="fas fa-lock-open" />{/* Change Password */}</button>  </td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </>}
                            {/* QR Modal Start */}
                            <div className="modal fade effect-scale show" id="pay_emi" aria-modal="true" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content modal-content-demo bg-white">
                                        <div className="modal-header">
                                            <h6 className="modal-title">QR</h6>
                                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                aria-hidden="true">×</span></button>
                                        </div>
                                        <div className="modal-body application-modal">
                                            {/* <h6>{value}</h6> */}
                                            <QRCode
                                                id="myCanvasId"
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={"" + value}
                                                viewBox={`0 0 256 256`} />
                                        </div>
                                        <Link className='btn btn-primary '
                                            onClick={() => downloadQR()} > Download QR</Link>
                                    </div>
                                </div>
                            </div>
                            {/* QR Modal End */}

                        </table>
                    </div>

                </div>
                {pagesCount > 0 && datasArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

                {/* Change Password Start */}
                <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content modal-content-demo">
                            <div className="modal-header">
                                <h3 className="modal-title">Change Password</h3>
                                <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                    aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body application-modal">

                                <form className="row" onSubmit={form.handleSubmit}>

                                    <div className="row">
                                        <div className="row g-xs">

                                            <div className="col-12">
                                                {/* <input type="password"  placeholder='Enter New Password' className="form-control" {...form.getFieldProps("new_password")}/>   */}
                                                <input className="form-control" type={values.showPassword ? "text" : "password"} placeholder="New Password"
                                                    name="password" {...form.getFieldProps("new_password")} />
                                                {!values.showPassword ?
                                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                    :
                                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                }
                                                {form.touched.new_password && form.errors.new_password ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.new_password}</div> : ''}
                                            </div>
                                        </div>
                                        <div className="row g-xs">
                                            <div className="col-12">
                                                {/* <input type="password"  placeholder='Enter Confirm Password' className="form-control"  {...form.getFieldProps("confirm_password")}/>  */}
                                                <input className="form-control" type={valuesConfP.showConfPassword ? "text" : "password"} placeholder="Confirm Password"
                                                    name="confirmedPassword"  {...form.getFieldProps("confirm_password")} />

                                                {!valuesConfP.showConfPassword ?
                                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                    :
                                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                }
                                                {form.touched.confirm_password && form.errors.confirm_password ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_password}</div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-footer mt-2">
                                        <button type="submit" className="btn btn-primary" aria-hidden="true" >
                                            Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Change Password End */}

                {/* Modal Code */}
                <div className={`modal-backdrop ${showSecondModal ? 'show' : ''}`} />
                <Modal show={showSecondModal} onHide={handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Counter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-xl-12 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Counter Number</label>
                                <div style={{ width: "29rem" }} className="row g-xs">
                                    <div className="input-group">
                                        <input  {...EditForm.getFieldProps("counter_number")} type="text" className="form-control" placeholder="Enter Counter Number" />
                                        {/* {form.touched.full_name && form.errors.full_name ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''} */}
                                    </div>
                                    {EditForm.touched.counter_number && EditForm.errors.counter_number ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.counter_number}</div> : ''}
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="form-label">Name</label>
                                <div style={{ width: "29rem" }} className="row g-xs">
                                    <div className="input-group">
                                        <input  {...EditForm.getFieldProps("full_name")} type="text" className="form-control" placeholder="Enter Name" />
                                        {/* {form.touched.full_name && form.errors.full_name ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''} */}
                                    </div>
                                    {EditForm.touched.full_name && EditForm.errors.full_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Mobile Number</label>
                                <div style={{ width: "29rem" }} className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" /* placeholder="Enter Mobile Number" */ value={mbnumber} />
                                    </div>
                                    {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''}
                                </div>
                            </div>
                        </div>

                        {/* Logo Modal Start */}
                        <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content modal-content-demo">
                                    <div className="modal-header"> <h6 className="modal-title">Logo</h6>
                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                    <div className="modal-body application-modal" >
                                        {/* <img src={copied} alt="..." width={80} style={{ marginLeft: "75px" }}></img> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Logo Modal End */}
                        {/* {error && <small>* Fields are Required</small>} */}



                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose2}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => { EditForm.handleSubmit() }}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Counter;