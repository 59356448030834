import { Link, useLocation, useParams, useNavigate, Navigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import $, { error } from 'jquery';
import PaginationComponent from '../../PaginationComponent';
import { deleteApi, getApi, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import moment from 'moment/moment';
import { Breadcrumbs } from '../../components';
import Swal from 'sweetalert2';
import { Toast } from 'reactstrap';

const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const ViewplanDetail = (props) => {
    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [searchpage, setSearchpage] = useState('');
    const [endDate, setendDate] = useState('');
    const tableRef = useRef(null);
    const ref = useRef()
    const { id } = useParams()
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [plan_id, setplan_id] = useState();
    const [nfc_site_name, setSite_Name] = useState()
    const [Min, setMin] = useState()
    const [Max, setMax] = useState()

    const [ID, setID] = useState()
    // const [salaryModeList, setSalaryModeList] = useState([]);


    const admin = JSON.parse(localStorage.getItem("admin"));

    const GetEWAPlan = async (token, pageNumber, filterVlu) => {
        // const response = await Getcities(token, pageNumber, filterVlu, sortedBy, orderBy);
        const response = await getApi(`/get-all-amount-range/${id}`, { page_number: pageNumber, search: filterVlu, sortby: sortedBy, orderby: orderBy });

        console.log("fdgdgdgddfgdg", response)
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data.rows);
                setTotalPagesCount(response.data.count);
                settotalItems(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get city data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        GetEWAPlan(admin.admin_token, pagesCount, search);
    }, [search, pagesCount]);




    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        GetEWAPlan(admin.admin_token, pagesCount, search);
    }

    const FormEdit = useFormik({
        initialValues: {
            charges: nfc_site_name,
            min_amount: Min,
            max_amount: Max,
            plan_id: plan_id
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            min_amount: yup.number().required('Please add Min Amount'),
            max_amount: yup.number().required('Please add Max Amount'),
            charges: yup.string().required('Please Enter Charges'),

        }),
        onSubmit: async values => {
            // const response = await EditNFCSites(employer.employer_token, data)
            try {
                const response = await postApi(`/edit-amount-range/${ID}`, {
                    charges: values.charges,
                    min_amount: values.min_amount,
                    max_amount: values.max_amount,
                    plan_id: plan_id
                })
                if (response.status === true) {
                    toast.success(response.message);
                    tableRef.current.click()
                    GetEWAPlan(admin.admin_token, pagesCount, search);
                } else if (response.status == false) {
                    console.log('error', response);
                    toast.error(response.message);
                } else {
                    console.log('error', response);
                    toast.error(response);
                }
            } catch (error) {
                // toast.error(error.message)
                console.log(error)
            }

        }
    });

    const Form = useFormik({
        initialValues: {
            plan_id: id,
            min_amount: "",
            max_amount: "",
            charges: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            min_amount: yup.number().required('Please add Min Amount'),
            max_amount: yup.number().required('Please add Max Amount'),
            charges: yup.number().required('Please add Charges'),
        }),

        onSubmit: async (values, { resetForm }) => {
            postApi("/add-amount-range", {
                plan_id: id,
                min_amount: values.min_amount,
                max_amount: values.max_amount,
                charges: values.charges
            }).then((res) => {
                if (res.status == true) {
                    toast.success(res.message);
                    resetForm({ values: '' });
                    ref.current.click()
                    GetEWAPlan(admin.admin_token, pagesCount, search);
                } else if (res.status == false) {
                    toast.error(res.message)
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    })
    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // const response = await deleteCashback(admin.admin_token, data)
                const response = await deleteApi(`/delete-amount-range/${data}`)
                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'EWA Plan Detail has been deleted Successfully.',
                        'success'
                    )
                    GetEWAPlan(admin.admin_token, pagesCount, search);
                } else if (response?.status == false) {
                    toast.error(response.message)
                }
                else {
                    toast.error("something went wrong")
                }
            }
        })
    }


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <Breadcrumbs order={{ first: { name: "View EWA Plan", path: `${prefix}/viewplan` }, second: { name: "Plan Detail" } }} />
                            </div>
                            <div className='page-rightheader'>
                                <button className='btn btn-primary' data-bs-target="#add_settlement"
                                    data-bs-toggle="modal" >+ Add Range</button>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Min Range</th>
                                                        <th className="font-weight-bold">Max Range</th>
                                                        <th className="font-weight-bold">Charges</th>
                                                        <th className="font-weight-bold">Created Date</th>
                                                        <th className="font-weight-bold">Updated Date</th>
                                                        <th className="font-weight-bold">Action</th>
                                                        {/* <th className="font-weight-bold " > <span style={{ marginLeft: "50px" }}>Action</span></th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td />
                                                    <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { max_amount, min_amount, charges, created_at, updated_at } = option;
                                                                return (
                                                                    <tr key={serial_num}>
                                                                        <td><span className="font-weight-normal1">{option?.min_amount == 0 ? "$0" : "$" + min_amount}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.max_amount ? "$" + max_amount : ""}</span></td>
                                                                        <td><span className="font-weight-normal1">{option?.charges == 0 ? "$0" : "$" + charges}</span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(created_at).timeZone}</span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(updated_at).timeZone}</span></td>
                                                                        <td><>{option?.is_deletable != 0 && <><button type="button" className="btn btn-primary m-1"
                                                                            data-bs-target="#edit_settlement" data-bs-toggle="modal" onClick={() => { setID(option.id); setSite_Name(option.charges); setMin(option.min_amount); setMax(option?.max_amount); setplan_id(option.plan_id) }}><i className="fas fa-edit " /></button>
                                                                            <button type="button" className='btn btn-danger mr-2' onClick={() => { handleDelete(option.id) }} ><i className="fas fa-trash " /></button>
                                                                        </>}{option?.is_deletable == 0 && "--"}</></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={9} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody></>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''} */}
                        </div>
                    </div>
                </div>
            }
            {/* Edit Department Modal start */}
            <div className="modal fade effect-scale show" id="edit_settlement" aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h3 className="modal-title">Edit Plan Charges</h3>
                            <button ref={tableRef} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body application-modal">
                            <form className="mt-1 row" onSubmit={FormEdit.handleSubmit}>
                                {FormEdit.values.wrong_message ?
                                    <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{FormEdit.values.wrong_message}</div>
                                    : ''}
                                <div className="row">
                                    <div className="row g-xs">
                                        <div className="col-12">
                                            <label>Enter Min Amount:</label>
                                            <input type="number" placeholder='Min Amount' {...FormEdit.getFieldProps("min_amount")}
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} className="form-control" />
                                            {FormEdit.touched.min_amount && FormEdit.errors.min_amount ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.min_amount}</div> : ''}
                                        </div>
                                        <div className="col-12">
                                            <label>Enter Max Amount:</label>
                                            <input type="number" placeholder='Max Amount' {...FormEdit.getFieldProps("max_amount")}
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} className="form-control" />
                                            {FormEdit.touched.max_amount && FormEdit.errors.max_amount ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.max_amount}</div> : ''}
                                        </div>
                                        <div className="col-12">
                                            <label>Enter Charges Amount:</label>
                                            <input type="number" placeholder='Enter Plan Charges' {...FormEdit.getFieldProps("charges")}
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} className="form-control" />
                                            {FormEdit.touched.charges && FormEdit.errors.charges ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.charges}</div> : ''}
                                        </div>
                                    </div>
                                    <div className="form-footer mt-2">
                                        <button type="submit" className="btn btn-primary" aria-hidden="true">
                                            Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Department Modal End */}

            <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Plan Range</h3>
                            <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body application-modal">

                            <form className="mt-1 row" onSubmit={Form.handleSubmit}>
                                {Form.values.wrong_message ?
                                    <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{Form.values.wrong_message}</div>
                                    : ''}
                                <div className="row">
                                    <div className="row g-xs">
                                        <div className="col-12">
                                            <input type="number" placeholder='Min Amount' {...Form.getFieldProps("min_amount")}
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} className="form-control" />
                                            {Form.touched.min_amount && Form.errors.min_amount ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.min_amount}</div> : ''}
                                        </div>
                                        <div className="col-12">
                                            <input type="number" placeholder='Max Amount' {...Form.getFieldProps("max_amount")}
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} className="form-control" />
                                            {Form.touched.max_amount && Form.errors.max_amount ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.max_amount}</div> : ''}
                                        </div>
                                        <div className="col-12">
                                            <input type="number" placeholder='Charges' {...Form.getFieldProps("charges")}
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} className="form-control" />
                                            {Form.touched.charges && Form.errors.charges ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.charges}</div> : ''}
                                        </div>
                                    </div>
                                    <div className="form-footer mt-2">
                                        <button type="submit" className="btn btn-primary" aria-hidden="true" >
                                            Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ViewplanDetail