import React, { useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import Loader from '../../../components/Loader/loader';
import { getApi } from '../../service/employService';
import PaginationComponent from '../../../PaginationComponent';
import { Image } from 'antd';
import Search from '../../../components/Search/Search';

const EWApaid = () => {
    const [ValidPagination, setValidPagination] = useState(false)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [adminData, setDsaData] = useState(localStorage.getItem("employer"));
    const [token, setToken] = useState(JSON.parse(adminData)?.employer_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [getByID, setgetByID] = useState([]);
    const [Count, setCount] = useState();
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [errorModal, seterrorModal] = useState(true)

    const ViewLoanCases = async (token) => {
        const response = await getApi(`/paid-ewa-advance-repayment`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        ViewLoanCases(token, search, pagesCount)

    }, [search, pagesCount, sortedBy, orderBy])


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const navigate = useNavigate()

    const SettlementBYidDetail = async (id) => {
        try {
            const response = await getApi(`/paid-ewa-advance-emi-list/${id}`, { /* search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy, limit: Entrieslimit */ });

            // console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
            if (response.data) {

                setgetByID(response.data.rows);
                // setTimeout(() => {
                //      setTotalPagesCount(response.data.count)
                //      setloader(false)
                // }, 2000);
                // setloader(true)


            } else {
                console.log("get merchant data response", response);
            }
        } catch (error) {
            if (error.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }

    }
    useEffect(() => {
        SettlementBYidDetail()
    }, [/* search, pagesCount, sortedBy, orderBy, Entrieslimit */])


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Paid EWA Advance {Count ? "(" + Count + ")" : ""}</h4>
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <button className="btn btn-primary" onClick={() => handleNavigate()}><span className="font-weight-normal1">Report</span></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setpagesCount('1'); setValidPagination(!ValidPagination) }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Docs </th>
                                                        <th className="font-weight-bold">TXN ID  {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}> </i>}</th>
                                                        <th className='font-weight-bold'> Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}> </i>}</th>
                                                        <th className='font-weight-bold'>Paid Amount  {sortedBy == "total_paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_paid_amount') }}> </i>}</th>
                                                        {/* <th className="font-weight-bold">Late Charges  {sortedBy == "late_charges" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }}> </i>}</th> */}
                                                        <th className="font-weight-bold">Paid Date  {sortedBy == "paid_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }}> </i>}</th>
                                                        {/* <th className="font-weight-bold">Status {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}> </i>}</th> */}
                                                        <th className="font-weight-bold">Notes {sortedBy == "employer_note" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_note') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_note') }}> </i>}</th>
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}> </i>}</th>
                                                        <th className="font-weight-bold">View TXN </th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><td /><td /><td /><td /><Loader /></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { id, docs, txn_id, status, paid_date, total_paid_amount, late_charges, amount, employer_note } = option;
                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td><span className="font-weight-normal1" ><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.docs != null ? option.docs : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' /></span></td>                                                                        <td><span className="font-weight-normal1"> {option?.txn_id ? txn_id : "- -"} </span></td>
                                                                        <td className='fw-bold text-bold'>{option?.amount ? amount : "-"}</td>
                                                                        <td className='fw-bold text-success'>{option?.total_paid_amount ? total_paid_amount : "-"}</td>
                                                                        {/* <td ><span className="font-weight-normal1">{option?.late_charges ? late_charges : "--"}</span></td> */}
                                                                        <td><span className="font-weight-normal1"> {option?.paid_date ? paid_date : "- -"} </span></td>
                                                                        {/* <td ><span className="font-weight-normal1">{option?.status ? status : "--"}</span></td> */}
                                                                        <td><span className="font-weight-normal1"> {option?.employer_note ? employer_note : "- -"} </span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                        <td><span className='font-weight-normal1'>
                                                                            <div className='btn btn-primary' onClick={() => SettlementBYidDetail(id)} data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                                        /* to={{ pathname: `${prefix}/ewa_advance_transaction_id/${id}`, state: { id } }} */>View All TXN</div></span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}


                        </div>
                    </div>
                    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdropLabel">Data By TXN ID</h5>
                                    <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover card-table table-vcenter text-nowrap">
                                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                                <tr>
                                                                    <th className="font-weight-bold">Loan ID  {sortedBy == "user_loan_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('user_loan_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('user_loan_id') }}> </i>}</th>
                                                                    <th className="font-weight-bold">Repayment No.  {sortedBy == "emi_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }}> </i>}</th>
                                                                    <th className='font-weight-bold'> Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}> </i>}</th>
                                                                    <th className="font-weight-bold">Due Date  {sortedBy == "due_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }}> </i>}</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {getByID.length > 0 && getByID.map((option, index) => {
                                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                    const { user_loan_id, due_date, amount, emi_number } = option;
                                                                    return (
                                                                        <tr key={serial_num}>
                                                                            <td><span className="font-weight-normal1"> {option?.user_loan_id ? user_loan_id : "- -"} </span></td>
                                                                            <td><span className="font-weight-normal1"> {option?.emi_number ? emi_number : "- -"} </span></td>
                                                                            <td className='fw-bold text-bold'>{option?.amount ? amount : "-"}</td>
                                                                            <td className='fw-bold text-success'>{option?.due_date ? due_date : "-"}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                )}
                                                            </tbody>
                                                            <tbody>
                                                                {getByID.length == 0 &&
                                                                    <tr>
                                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                                    </tr>
                                                                }
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={Entrieslimit}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''} */}

                                    </div>
                                </div>
                                <div class="modal-footer">
                                    {/* <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Close</button> */}
                                    {/* <button type="button" class="btn btn-primary">Understood</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default EWApaid
