import React, { useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import QRCode from 'react-qr-code';
import { deleteApi, deleteCounter, getApi, postApi } from '../../service/MerchantService';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { toast } from 'react-hot-toast';
import PaginationComponent from '../../../PaginationComponent';
import Loader from '../../../components/Loader/loader';

export const Merchantinformation = () => {
    const saveSvgAsPng = require('save-svg-as-png')
    const [value, setValue] = useState('');
    const [detail, setDetail] = useState([])
    const [counterid, setcounterid] = useState();
    const ref = useRef()
    const [needid, setNeedID] = useState()
    const [datasArray, setDatasArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [state, setState] = useState(false)
    const [error, setError] = useState(false);
    const [mbnumber, setmbnumber] = useState()
    const merchant = JSON.parse(localStorage.getItem("merchant"));
    const [showSecondModal, setshowSecondModal] = useState(false);
    const [loader, setloader] = useState(true)
    const navigate = useNavigate();
    // const locations = useLocation().state;
    // console.log("lokendra",locations)
    const handleClose2 = () => { EditForm.resetForm(); setshowSecondModal(false); }
    const handleShow2 = (data) => {
        const { counter_number, full_name, mobile_number, unique_id, id } = data
        setmbnumber(mobile_number)
        // console.log(mbnumber);
        setError(false)
        console.log(data)
        setNeedID(data.user_id)
        EditForm.setFieldValue("counter_number", counter_number)
        EditForm.setFieldValue("full_name", full_name)
        EditForm.setFieldValue("mobile_number", mobile_number)
        EditForm.setFieldValue("unique_id", unique_id)
        EditForm.setFieldValue("id", id)

        setshowSecondModal(true);
    }
    let location = useLocation().state
    const getDetail = async (token, Id) => {
        // const response = await GetMerchantrDetailbyID(token, Id);
        const response = await getApi(`/get-store-byId/${Id}`,);
        console.log(response)
        if (response?.status) {
            setDetail(response.data);

            //    navigate("/merchant/merchantInformation",{state:{detail:response.data,datasArray:response.merchant_counter,pagesCount,Id}})
        } else if (response?.message == 'Session expired') {
            // localStorage.removeItem("merchant");
            // navigate('/merchant/login')
        }
        else {
            console.log("get merchant data response", response);
        }
    }

    const getCounterDetail = async (Id) => {
        // const response = await GetgetCounterDetail(token, Id, search, pagesCount, sortedBy, orderBy);
        const response = await getApi(`/counter-list/${Id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        // console.log(response)
        if (response?.status) {
            setTimeout(() => {
                setDatasArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)

        } else if (response?.message == 'Session expired') {
            localStorage.removeItem("merchant");
            navigate('/merchant/login')
        }
        else {
            console.log("get merchant data response", response);
        }
    }
    //   console.log(counterid)
    useEffect(() => {
        getCounterDetail(location.Id)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // transction(merchant.merchant_token);
    }
    const qrValues = (id) => {
        setValue(id)
    }
    const imageOptions = {
        scale: 5,
        encoderOptions: 1,
        backgroundColor: 'white',
    }
    const downloadQR = async () => {
        var canvas = document.getElementById('myCanvasId');
        saveSvgAsPng.saveSvgAsPng(canvas, 'qr_code.png', imageOptions);
    };
    // console.log(needid , "0-9-9=============-")

    const EditForm = useFormik({

        initialValues: {
            counter_number: "",
            full_name: "",
            mobile_number: "",
            unique_id: "",
            id: '',
            user_id: ""

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            mobile_number: yup.number().typeError('Please Enter A Valid Number').required('Please Enter Mobile No.'),
            full_name: yup.string().required('Please Enter Name'),
            counter_number: yup.string().required('Please Enter Counter number'),
            // logo: yup.string().required('Please Upload Your Logo'),
        }),
        onSubmit: async (values, actions) => {
            let { mobile_number, full_name, counter_number } = values
            let response = await postApi("/update-counter", {
                counter_id: `${needid}`,
                name: full_name,
                mobile_number: mobile_number,
                counter_number: `${counter_number}`,
            })
            if (response.status == true) {
                setState(!state)
                toast.success("Edit Successfully");
                getCounterDetail(merchant.merchant_token, location.Id)
                // setstate(!state)
                EditForm.resetForm()
            }
            console.log({ response })
            setshowSecondModal(false);
        }
    });

    useEffect(() => {

        getDetail(merchant.merchant_token, location.Id)
    }, [state])


    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // const response = await deleteCounter(merchant.merchant_token, data)
                const response = await deleteApi(`/delete-counter/${data}`)
                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'Your Counter has been deleted.',
                        'success'
                    )
                    //   setState(!state)
                    getCounterDetail(merchant.merchant_token, location.Id)

                }
                else {
                    toast.error("something went wrong")
                }

            }
        })
    }

    const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;

    const form = useFormik({
        initialValues: {
            user_id: "",
            new_password: "",
            confirm_password: "",
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            new_password: yup.string().required('Please Enter New Password')/* .matches(regex, 'Please Enter Only Character Values') */,
            confirm_password: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("new_password"), null], "Password Must Be Same"),

        }),
        onSubmit: async (values, { resetForm }) => {
            console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
            const response = await postApi("/user-change-password", {
                user_id: counterid,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            })
            resetForm({ values: '' });
            ref.current.click()
            if (response.status == false) {
                console.log(response.message);
                toast.error(response.message);
                ref.current.click()
            }
            if (response.status == true) {
                console.log(response.message);
                toast.success(response.message);
                // window.$('#modal-center').modal('hide')	 
                ref.current.click()
            } else {
                console.log("#@#@#1234", response);
            }

        }
    });
    const [values, setValues] = React.useState({
        new_password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        new_password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',

    }

    const eyeBtnStyle2 = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle3 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',
    }
    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header my-3 py-2">
                    <div className="modal-header w-100">{/* 
                        <h3 className="modal-title">Employer Information</h3> */}
                        <div className="page-leftheader">
                            {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                            <h6 className="page-title mb-0 text-primary">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className='breadcrumbslink' to={prefix + '/store_list'}>Store</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Details </li>
                                    </ol>
                                </nav>
                            </h6>
                        </div>
                    </div>
                    <div className="modal-body application-modal">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12">
                                <div className="">
                                    <div className="main-content-body main-content-body-contacts">
                                        {/* <h6>Employer Information</h6> */}
                                        <div className="main-contact-info-header bg-contacthead">
                                            <div className="media">
                                                <div className="media-body text-white ml-0">
                                                    <h4 className="text-white">Company Name:   {detail && detail.company_name ? detail.company_name : ''} </h4>
                                                    <p className="mb-1">Person Name : {detail && detail.full_name ? detail.full_name : ''}</p>
                                                </div>
                                            </div>
                                            {/* <div className="main-contact-action"><Link className='btn btn-primary' to={prefix + `/employee_edit/${id}`}>Edit</Link></div> */}
                                        </div>
                                        <div className="main-contact-info-body">
                                            <div className="media-list p-0">

                                                <div className="media py-4 mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                            <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.mobile_number : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                            <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.email : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-male fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Age :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? age : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Salary :</label> <span className="font-weight-normal1 fs-14">{employeeDetail ? "Salary" : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Company Registration/Tax ID</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.gst_number : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                            <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14"> {detail && detail ? detail.address : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media py-4 border-top mt-0">
                                                    <div className="media-body">
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-globe fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Website:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.website : ''}</span> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-earth-americas fa-fw"></i> </div>
                                                            <div className="w-70"> <label>Country:</label> <span className="font-weight-normal1 fs-14"> {detail && detail ? detail.country : ''}</span> </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* Modal Code */}
                                                <Modal show={showSecondModal} onHide={handleClose2}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Edit Counter</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="col-xl-12 col-lg-6 col-md-6 col-xm-12">

                                                            <div className="form-group">
                                                                <label className="form-label">Counter Number</label>
                                                                <div style={{ width: "29rem" }} className="row g-xs">
                                                                    <div className="input-group">
                                                                        <input  {...EditForm.getFieldProps("counter_number")} type="text" className="form-control" placeholder="Enter Counter Number" />
                                                                        {/* {form.touched.full_name && form.errors.full_name ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''} */}
                                                                    </div>
                                                                    {EditForm.touched.counter_number && EditForm.errors.counter_number ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.counter_number}</div> : ''}
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label">Name</label>
                                                                <div style={{ width: "29rem" }} className="row g-xs">
                                                                    <div className="input-group">
                                                                        <input  {...EditForm.getFieldProps("full_name")} type="text" className="form-control" placeholder="Enter Name" />
                                                                        {/* {form.touched.full_name && form.errors.full_name ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''} */}
                                                                    </div>
                                                                    {EditForm.touched.full_name && EditForm.errors.full_name ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''}
                                                                </div>
                                                            </div>


                                                            <div className="form-group">
                                                                <label className="form-label">Mobile Number</label>
                                                                <div style={{ width: "29rem" }} className="row g-xs">
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" /* placeholder="Enter Mobile Number" */ value={mbnumber} />
                                                                    </div>
                                                                    {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''}
                                                                </div>
                                                            </div>



                                                        </div>

                                                        {/* Logo Modal Start */}
                                                        <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                                                            <div className="modal-dialog modal-sm" role="document">
                                                                <div className="modal-content modal-content-demo">
                                                                    <div className="modal-header"> <h6 className="modal-title">Logo</h6>
                                                                        <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                                    <div className="modal-body application-modal" >
                                                                        {/* <img src={copied} alt="..." width={80} style={{ marginLeft: "75px" }}></img> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Logo Modal End */}
                                                        {/* {error && <small>* Fields are Required</small>} */}



                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleClose2}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" onClick={() => { EditForm.handleSubmit() }}>
                                                            Submit
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>





                                                <div className="card-body p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                                <tr>
                                                                    {/* <th className="font-weight-bold">S No.</th> */}
                                                                    <th className="font-weight-bold">Counter No.   {sortedBy == "counter_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('counter_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('counter_number') }}></i>}
                                                                    </th>
                                                                    <th className="font-weight-bold">Name   {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('full_name') }}></i>}
                                                                    </th>
                                                                    <th className="font-weight-bold">Mobile No. {sortedBy == "mobile_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('mobile_number') }}></i>}
                                                                    </th>
                                                                    <th className="font-weight-bold">View QR </th>
                                                                    <th className="font-weight-bold"> </th>
                                                                    {/* <th className="font-weight-bold"> </th> */}
                                                                    {/* <th className="font-weight-bold"> </th> */}
                                                                </tr>
                                                            </thead>
                                                            {loader == true ? <tbody>
                                                                <td /><td />
                                                                <Loader />
                                                            </tbody> :
                                                                <>


                                                                    <tbody>
                                                                        {datasArray?.length > 0 && datasArray?.map((data, i) => {
                                                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                                            const { user_id, counter_number, name, full_name, mobile_number, id } = data;
                                                                            var timezone = new Date(data.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                                            return (
                                                                                <tr key={serial_num}>
                                                                                    {/* <td>{serial_num}</td> */}
                                                                                    <td>{counter_number}</td>
                                                                                    <td>{full_name}</td>
                                                                                    <td>{mobile_number}</td>
                                                                                    <td> <span className="font-weight-normal1">
                                                                                        <button className='btn btn-primary' onClick={() => qrValues(mobile_number)}
                                                                                            data-bs-target="#pay_emi" data-bs-toggle="modal">view</button>
                                                                                    </span>
                                                                                    </td>
                                                                                    <td style={{ width: "1rem" }} >
                                                                                        <button onClick={() => { handleShow2(data) }} type="button" className="btn btn-outline-success">Edit</button> &nbsp;&nbsp;
                                                                                        <button onClick={() => { handleDelete(user_id) }} type="button" className="btn btn-outline-danger ">Delete</button> &nbsp;&nbsp;
                                                                                        <button onClick={() => { setcounterid(user_id) }} type="button" className="btn btn-outline-primary"
                                                                                            data-bs-target="#add_settlement" data-bs-toggle="modal" >Change Password</button>  </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                </>}
                                                            {/* QR Modal Start */}
                                                            <div className="modal fade effect-scale show" id="pay_emi" aria-modal="true" role="dialog">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content modal-content-demo bg-white">
                                                                        <div className="modal-header">
                                                                            <h6 className="modal-title">QR</h6>
                                                                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                                                aria-hidden="true">×</span></button>
                                                                        </div>
                                                                        <div className="modal-body application-modal">
                                                                            {/* <h6>{value}</h6> */}
                                                                            <QRCode
                                                                                id="myCanvasId"
                                                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                                value={"" + value}
                                                                                viewBox={`0 0 256 256`} />
                                                                        </div>
                                                                        <Link className='btn btn-primary '
                                                                            onClick={() => downloadQR()} > Download QR</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* QR Modal End */}

                                                        </table>
                                                    </div>

                                                </div>
                                                {pagesCount > 0 && datasArray.length > 0 ?
                                                    <>
                                                        <div className="col-md-12">
                                                            <div className="card-body">
                                                                <PaginationComponent className="justify-content-center"
                                                                    totalItems={totalPagesCount}
                                                                    pageSize={10}
                                                                    maxPaginationNumbers={3}
                                                                    onSelect={(e) => setpagesCount(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''}

                                                {/* Change Password Start */}
                                                <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content modal-content-demo">
                                                            <div className="modal-header">
                                                                <h3 className="modal-title">Change Password</h3>
                                                                <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                                    aria-hidden="true">×</span></button>
                                                            </div>

                                                            <div className="modal-body application-modal">

                                                                <form className="row" onSubmit={form.handleSubmit}>

                                                                    <div className="row">
                                                                        <div className="row g-xs">

                                                                            <div className="col-12">
                                                                                {/* <input type="password"  placeholder='Enter New Password' className="form-control" {...form.getFieldProps("new_password")}/>   */}
                                                                                <input className="form-control" type={values.showPassword ? "text" : "password"} placeholder="New Password"
                                                                                    name="password" {...form.getFieldProps("new_password")} />
                                                                                {!values.showPassword ?
                                                                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                                    :
                                                                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                                }
                                                                                {form.touched.new_password && form.errors.new_password ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.new_password}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row g-xs">
                                                                            <div className="col-12">
                                                                                {/* <input type="password"  placeholder='Enter Confirm Password' className="form-control"  {...form.getFieldProps("confirm_password")}/>  */}
                                                                                <input className="form-control" type={valuesConfP.showConfPassword ? "text" : "password"} placeholder="Confirm Password"
                                                                                    name="confirmedPassword"  {...form.getFieldProps("confirm_password")} />

                                                                                {!valuesConfP.showConfPassword ?
                                                                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                                    :
                                                                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                                }
                                                                                {form.touched.confirm_password && form.errors.confirm_password ?
                                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_password}</div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-footer mt-2">
                                                                        <button type="submit" className="btn btn-primary" aria-hidden="true" >
                                                                            Submit</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Change Password End */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>


        </>
    )
}




