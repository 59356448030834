import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Breadcrumbsecond } from '../../components';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import { GetCountry } from '../../employer/service/employService';
import * as yup from 'yup';
import { EditEmployerDetail, postApi } from '../service/adminService';

const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const EditEmployer = () => {
    const [country, setCountry] = useState([]);
    const ids = useLocation();
    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token
    const navigate = useNavigate();
    // console.log(ids)
    const getCountry = async () => {
        const response = await GetCountry();
        if (response.status == true) {
            setCountry(response.data);
        } else {
            console.log("get Country data response", response);
        }
    }

    useEffect(() => {
        getCountry();
    }, [])

    const profileData = ids.state
    const [image, setImage] = useState(profileData?.logo)
    const [selectedImage, setSelectedImage] = useState();
    // const imageChange = async (e = "") => {
    //     console.log(e.target.files[0], "event")
    //     if (e.target && e.target.files) {
    //         const file = e.target.files[0] || "";
    //         console.log(file, "fdile")
    //         if (Object.keys(image).length === 0 && e.target.files[0] &&
    //             (e.target.files[0].type === "image/png" ||
    //                 e.target.files[0].type === "image/jpeg" ||
    //                 e.target.files[0].type === "image/svg+xml")) {
    //             setImage(file);
    //             setSelectedImage(file)
    //             form.setValues({
    //                 ...form.values,
    //                 logo: file,
    //             });
    //         } else {
    //             toast.error("Invalid file type. Please upload a PNG, JPEG, or SVG file.")
    //             form.setValues({
    //                 ...form.values,
    //                 logo: null,
    //             });
    //             setImage({});
    //         }

    //     }
    //     else {
    //         setImage({});
    //         form.setValues({
    //             ...form.values,
    //             logo: null,
    //         });
    //     }
    // };
    // const imageChange = (e) => {
    //     setImage(e.target.files[0])
    //     setSelectedImage(e.target.files[0])
    //     form.setFieldValue('logo', e.target.files[0].name)

    //     // if (e.target.files && e.target.files.length > 0) {
    //     //   setSelectedImage(e.target.files[0]);
    //     // } else {
    //     //   setSelectedImage();
    //     //   console.log("==================")
    //     // }
    // };

    const imageChange = async (e = "") => {
        // console.log(e.target.files[0], "event")
        setImage({})
        if (e.target && e.target.files) {
            const file = e.target.files[0] || "";
            // console.log(file, "file", image, Object.keys(image).length)
            if (/* Object.keys(image).length === 0 && */ e.target.files[0]) {
                const allowedTypes = ["image/png", "image/jpeg", "image/svg+xml"];
                if (allowedTypes.includes(e.target.files[0].type)) {
                    setImage(file);
                    setSelectedImage(file)
                    form.setValues({
                        ...form.values,
                        logo: file,
                    });
                } else {
                    toast.error("Invalid file type. Please upload a PNG, JPEG, or SVG file.")
                }
            }
        } else {
            setImage({});
            form.setValues({
                ...form.values,
                logo: null,
            });
        }
    };
    useEffect(() => {

    }, [profileData])
    console.log(image, "for testing only", selectedImage)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const form = useFormik({
        initialValues: {
            company_name: profileData?.company_name,
            full_name: profileData?.full_name,
            email: profileData?.email,
            address: profileData?.address,
            mobile_number: profileData?.mobile_number,
            website: profileData?.website,
            country: profileData?.country,
            pay_cycle: profileData?.pay_cycle,
            platform_fee: profileData?.platform_fee,
            logo: image?.name,
            logo_true: "",
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            company_name: yup.string().required('Please Enter Company Name'),
            full_name: yup.string().required('Please Enter Your Name'),
            address: yup.string().required('Please Enter Address'),
            email: yup.string().email("Invalid Email Address Format").required('Please Enter Valid Email Address'),
            //   mobile_number: yup.number().typeError('please enter a valid number').required('Please enter mobile number').positive(),
            mobile_number: yup.string().required('Please Enter Mobile Number').min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Contain Max Upto 15 Number").matches(phoneRegExp, 'Please Enter Only Number Values'),
            platform_fee: yup.string().required('Please Enter Platform Fee'),
            website: yup.string().required('Please Enter Valid Website'),
            logo: yup.string().nullable().required('Please upload your logo'),
        }),
        onSubmit: async values => {
            const formData = new FormData();
            formData.append("company_name", form.getFieldProps('company_name').value);
            formData.append("full_name", form.getFieldProps('full_name').value);
            formData.append("address", form.getFieldProps('address').value);
            formData.append("email", form.getFieldProps('email').value);
            formData.append("platform_fee", form.getFieldProps('platform_fee').value);
            formData.append("mobile_number", form.getFieldProps('mobile_number').value);
            formData.append("website", form.getFieldProps('website').value);
            formData.append("country", form.getFieldProps('country').value);
            formData.append("pay_cycle", form.getFieldProps('pay_cycle').value);
            formData.append("logo", image);
            // `/edit-employer/${id}`
            // const response = await EditEmployerDetail(token, formData, profileData.user_id)
            const response = await postApi(`/edit-employer/${profileData.user_id}`, formData, true)

            if (response.status === true) {

                toast.success(response.message);
                navigate(prefix + '/employer');
            } else {
                console.log('error', response);
                toast.error(response);
            }


        }
    });

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">

                    <h4 className="page-title mb-0 text-primary">

                        <Breadcrumbsecond order={{ first: { name: "Employer", path: `${prefix}/employer` }, second: { name: "Update Employer's Profile" } }} />
                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Company Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Merchant Name" {...form.getFieldProps("company_name")} />
                                </div>
                                {form.touched.company_name && form.errors.company_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.company_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Name Of Contact Person</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Name" {...form.getFieldProps("full_name")} />
                                </div>
                                {form.touched.full_name && form.errors.full_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Country</label>
                            <div className="row g-xs">
                                <div className="input-group">

                                    <select className="form-control" name="country" {...form.getFieldProps("country")}>
                                        <option value="">Select Country</option>
                                        {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                                    </select>
                                </div>
                                {form.touched.email && form.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Pay Cycle</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <select className="form-control mb-0" name="pay_cycle" /* value={paycycle} onKeyUp={() => setValidate(true)} */
                                        /*  onChange={(e) => setpaycycle(e.target.value)} */ /* onSelect={() => setValidate(true)} */
                                        {...form.getFieldProps("pay_cycle")} >
                                        {/* <option>Select Pay Cycle</option> */}
                                        <option value='Weekly'>Weekly</option>
                                        <option value='Fortnightly'>Fortnightly</option>
                                        <option value='Monthly'>Monthly</option>
                                    </select>
                                </div>
                                {form.touched.country && form.errors.country ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.country}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Plateform Fee</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Platform Fee" {...form.getFieldProps("platform_fee")} />
                                </div>
                                {form.touched.platform_fee && form.errors.platform_fee ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.platform_fee}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Mobile Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Mobile Number" {...form.getFieldProps("mobile_number")} />
                                </div>
                                {form.touched.mobile_number && form.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Address</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Address" {...form.getFieldProps("address")} />
                                </div>
                                {form.touched.address && form.errors.address ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.address}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Website</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Website" {...form.getFieldProps("website")} />
                                </div>
                                {form.touched.website && form.errors.website ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.website}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Enter Email" {...form.getFieldProps("email")} />
                                </div>
                                {form.touched.email && form.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>


                    <div className="input-group file-browser mb-0 widthHundred">
                        <input type="text" className="form-control browse-file" placeholder="Upload your logo" />
                        <label className="input-group-text btn btn-primary"> Browse   {/* <p onChange={imageChange}> */}
                            <input type="file" className="file-browserinput" /* filename={image} */ onChange={(e) => imageChange(e)} style={{ display: 'none' }} multiple /> </label>
                        {form.touched.logo && form.errors.logo ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}
                    </div>

                    {!selectedImage ?
                        <span>
                            <img src={image ? image : process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt="/" height={100} style={{ width: 'auto' }} />
                            <h1>selectedImage1</h1>
                            <Link to="" onClick={() => { setImage({}); imageChange() }} className="upload__img-close">
                                <i className="fa-solid fa-close fa-fw"></i></Link></span> :
                        <div >

                            <h1>selectedImage2</h1>
                            <img
                                src={URL.createObjectURL(selectedImage)}
                           /*  src={process.env.REACT_APP_S3_BUCKET_URL +"logo/" + selectedImage.name} */ height={100} style={{ width: 'auto' }}
                                alt="..."
                            />
                            <Link to="" onClick={() => { setImage({}); imageChange() }} className="upload__img-close">
                                <i className="fa-solid fa-close fa-fw"></i></Link></div>}

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Save Changes</button>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default EditEmployer
