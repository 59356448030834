import { Link, useLocation, useParams, useNavigate, Navigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import $ from 'jquery';
import PaginationComponent from '../../PaginationComponent';
import { deleteApi, getApi, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import Search from '../../components/Search/Search';
import { create } from 'yup/lib/Reference';

const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const Viewplan = (props) => {
    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [searchpage, setSearchpage] = useState('');
    const [endDate, setendDate] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [pagesCheck, setpagesCheck] = useState(1);
    const [nfc_site_name, setSite_Name] = useState()
    const [ID, setID] = useState()
    const [ValidPagination, setValidPagination] = useState(false)
    // const [salaryModeList, setSalaryModeList] = useState([]);
    const admin = JSON.parse(localStorage.getItem("admin"));

    const GetEWAPlan = async (token, pageNumber, filterVlu) => {
        // const response = await Getcities(token, pageNumber, filterVlu, sortedBy, orderBy);
        const response = await getApi("/ewa-plan-list", { page_number: pageNumber, search: filterVlu, sortby: sortedBy, orderby: orderBy });

        console.log("fdgdgdgddfgdg", response)
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data.rows);
                setTotalPagesCount(response.data.count);
                settotalItems(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get city data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        GetEWAPlan(admin.admin_token, pagesCount, search);
    }, [search, pagesCount]);


    const form = useFormik({
        initialValues: {
            // nfc_id: "",
            ewa_plan_title: "",
            // location: "",
            // other: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // title: yup.string().required('Please Enter EWA Plan Title'),
            ewa_plan_title: yup.string().matches(/[a-zA-Z0-9]/, 'Title Must Contain At Least One Alphanumeric Character').required('Please Enter EWA Plan Title')
        }),
        onSubmit: async (values, { resetForm }) => {
            const response = await postApi("/create-ewa-plan", { title: values.ewa_plan_title, })

            console.log(response)
            if (response.status == true) {
                toast.success(response.message)
                resetForm({ values: '' });
                // navigate(prefix + '/view_department')
                GetEWAPlan(admin.admin_token, pagesCount, search);


            } else if (response.status == false) {
                console.log(response)
                toast.error(response.message)
                resetForm({ values: '' });
            }
        }
    })

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        GetEWAPlan(admin.admin_token, pagesCount, search);
    }

    const FormEdit = useFormik({
        initialValues: {
            title: nfc_site_name ? nfc_site_name : "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // title: yup.string().required('Please Enter Title'),
            title: yup.string().matches(/[a-zA-Z0-9]/, 'Title Must Contain At Least One Alphanumeric Character').required('Please Enter EWA Plan Title')

        }),
        onSubmit: async values => {
            // const response = await EditNFCSites(employer.employer_token, data)
            const response = await postApi(`/edit-ewa-plan/${ID}`, {
                title: values.title
            })
            if (response.status === true) {
                toast.success(response.message);
                tableRef.current.click()
                GetEWAPlan(admin.admin_token, pagesCount, search);
            } else if (response.status == false) {
                toast.error(response.message)
                tableRef.current.click()

            }
            else {
                console.log('error', response);
                toast.error(response);
            }
        }
    });

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // const response = await deleteCashback(admin.admin_token, data)
                const response = await deleteApi(`/delete-ewa-plan/${data}`)
                if (response?.status) {
                    console.log("first")
                    Swal.fire(
                        'Deleted!',
                        'EWA Plan has been deleted Successfully.',
                        'success'
                    )
                    GetEWAPlan(admin.admin_token, pagesCount, search);
                } else if (response?.status == false) {
                    toast.error(response.message)
                } else {
                    toast.error("something went wrong")
                }
            }
        })
    }

    return (
        <>
            <>
                <Toaster position="top-right" reverseOrder={false} />
                {!showAddNewCases &&
                    <div>
                        <div className="main-container container-fluid px-0">
                            <div className="page-header mb-3">
                                {/* <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">City({dataArray.length})</h4>
                            </div> */}
                                <div className="page-leftheader">
                                    {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                    <h6 className="page-title mb-0 text-primary">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a >Manage EWA Plan</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">View EWA Plan {totalItems ? "(" + totalItems + ")" : ""}</li>
                                            </ol>
                                        </nav>
                                    </h6>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                    <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                                        <div className="form-group">
                                            <label className="form-label">Add EWA Plan Title</label>
                                            <div className="row g-xs">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Enter EWA Plan Title" {...form.getFieldProps("ewa_plan_title")} />
                                                    <button type="submit" className="btn btn-primary ">Add</button>
                                                </div>
                                                {form.touched.ewa_plan_title && form.errors.ewa_plan_title ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.ewa_plan_title}</div> : ''}
                                            </div>
                                            {/* <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Add</button>
                    </div> */}
                                        </div>
                                    </form>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                    <div className="form-group">
                                        <label className="form-label">Search</label>
                                        <div className="row g-xs">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                        <tr>
                                                            <th className="font-weight-bold">View Plan Title</th>
                                                            <th className="font-weight-bold">Created Date</th>
                                                            <th className="font-weight-bold">Updated Date</th>
                                                            <th className="font-weight-bold">Actions</th>
                                                            {/* <th className="font-weight-bold " > <span style={{ marginLeft: "50px" }}>Action</span></th> */}
                                                            {/* <th></th> */}
                                                        </tr>
                                                    </thead>
                                                    {loader == true ? <tbody>
                                                        <td />
                                                        <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                            <Loader />
                                                        </div> </tbody> :
                                                        <>
                                                            <tbody>
                                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                    const { id, title, created_at, updated_at } = option;
                                                                    return (
                                                                        <tr key={serial_num} >
                                                                            <td><span className="font-weight-normal1 text-primary">{option?.title ? title : ""}</span></td>
                                                                            <td><span className="font-weight-normal1">{TimeZone(created_at).timeZone}</span></td>
                                                                            <td><span className="font-weight-normal1">{TimeZone(updated_at).timeZone}</span></td>

                                                                            <td>{option?.is_deletable != 0 && <span>
                                                                                <button type="button" className="btn btn-primary"
                                                                                    data-bs-target="#edit_settlement" data-bs-toggle="modal" onClick={() => { setID(option.id); setSite_Name(option.title) }}><i className="fas fa-edit " /></button> &nbsp;&nbsp;
                                                                                <button type="button" className='btn btn-danger mr-2' onClick={() => { handleDelete(option.id) }} ><i className="fas fa-trash " /></button>
                                                                            </span>}  <Link className="btn btn-primary" to={{ pathname: `${prefix}/viewplan/view_plan_detail/${id}`, state: { id } }} ><span className="font-weight-normal1">View Plan Detail</span></Link>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                )}
                                                            </tbody>
                                                            <tbody>
                                                                {dataArray.length == 0 &&
                                                                    <tr>
                                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                                    </tr>
                                                                }
                                                            </tbody></>
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCount}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCount(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCount}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCount(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                }
                {/* Edit Department Modal start */}
                <div className="modal fade effect-scale show" id="edit_settlement" aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content modal-content-demo">
                            <div className="modal-header">
                                <h3 className="modal-title">Edit EWA Plan Title</h3>
                                <button ref={tableRef} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                    aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body application-modal">
                                <form className="mt-1 row" onSubmit={FormEdit.handleSubmit}>
                                    {FormEdit.values.wrong_message ?
                                        <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{FormEdit.values.wrong_message}</div>
                                        : ''}
                                    <div className="row">
                                        <div className="row g-xs">
                                            <div className="col-12">
                                                <input type="text" placeholder='Enter Title' {...FormEdit.getFieldProps("title")} className="form-control" />
                                                {FormEdit.touched.title && FormEdit.errors.title ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.title}</div> : ''}
                                            </div>
                                        </div>
                                        <div className="form-footer mt-2">
                                            <button type="submit" className="btn btn-primary" aria-hidden="true">
                                                Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit Department Modal End */}
            </>
        </>
    )
}

export default Viewplan