import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { getApi } from '../service/adminService';
import Moment from 'react-moment';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const LenderEmployeedetail = () => {
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [search, setSearch] = useState('');
    const [dataArray, setDataArray] = useState([]);
    const tableRef = useRef(null);
    const { id } = useParams();
    const Admin = JSON.parse(localStorage.getItem("admin"));
    const token = Admin.admin_token;
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;


    const GetlenderEmployeeDetail = async (token, id) => {
        // 
        //  const response = await GetlenderEmployeeDetailview(token , id);
        const response = await getApi(`/get-employee-by-lenderId/${id}`);

        console.log(response);
        if (response.status == true) {
            setDataArray(response.data)
            //   setprofileData(response.data);
        } else {
            console.log("get employees data response", response);
        }
    }
    useEffect(() => {
        GetlenderEmployeeDetail(token, id)
    }, [])
    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Employee List</h4>
                            </div>
                            {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
                  <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
                  <h5 style={{marginTop: '10px'}}> <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}  >

                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Sr No.</th>
                                                        {/* <th className="font-weight-bold">Date</th> */}
                                                        <th className="font-weight-bold">
                                                            Employee Name {/* <button className='fas fa-user' onClick={strAscending}/> */}</th>
                                                        <th className="font-weight-bold">Company Name</th>
                                                        <th className="font-weight-bold">Total Amount</th>
                                                        <th className="font-weight-bold">Interest Rate</th>
                                                        <th className="font-weight-bold">Status </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
                                                        // let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                        const { id, full_name, company_name, mobile_number, total_amount, interest_rate, status } = option;
                                                        var num2 = parseFloat(option.interest_rate).toFixed(2)
                                                        var Capitalize = (option) => {
                                                            return option.charAt(0).toUpperCase() + option.slice(1);
                                                        }

                                                        return (
                                                            <tr key={id} >

                                                                <td><span className="font-weight-normal1">{index + 1}</span></td>
                                                                {/* <td><span className="font-weight-normal1"><Moment format="YYYY-MM-DD">{created_at}</Moment></span></td>  */}
                                                                <td><span className="font-weight-normal1">
                                                                    <Link className='text-primary' to={{ pathname: `${prefix}/employeeEmidetails/${id}`, state: { id } }} >
                                                                        {full_name ? Capitalize(full_name) : ""}<br /> {mobile_number ? mobile_number : ""}</Link></span></td>
                                                                <td><span className="font-weight-normal1">{company_name ? Capitalize(company_name) : ""}</span></td>
                                                                <td><span className="font-weight-normal1">${total_amount} </span></td>
                                                                <td><span className="font-weight-normal1">{num2}%</span></td>
                                                                <td><span className="font-weight-normal1">{status ? Capitalize(status) : ""}</span></td>

                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LenderEmployeedetail
