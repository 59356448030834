import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../../../components/Loader/loader';
import PaginationComponent from '../../../PaginationComponent';
import { Breadcrumbs } from '../../../components';
import { getApi } from '../../service/MerchantService';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { TimeZone } from '../../../components/TimeZone/TimeZone';


const ViewtransactionID = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const merchant = JSON.parse(localStorage.getItem("merchant"));
    const token = merchant.merchant_token
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const navigate = useNavigate();
    const ids = useLocation();
    const [merchantDataheader, setmerchantDataheader] = useState(null)
    const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;
    const txn_from = ids.state.txn_from
    const id = ids.state.id
    const merchant_id = ids.state.merchant_id

    const GETtransaction = async () => {
        // const response = await Viewtransaction(token, id,txn_from);
        const response = await getApi(`/settlement-detail/${id}`, { txn_from: txn_from });

        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.rows);
                setTotalPagesCount(response.count)
                setmerchantDataheader(response.merchantData)
                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message === 'Session expired') {
            localStorage.removeItem("employer");
            navigate('/employer/login')
        }
        else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        GETtransaction(token)
    }, [pagesCount, search, sortedBy, orderBy])

    const setCopied = () => {
        toast.success("Copied")
    }
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }


    }
    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">
                    <Breadcrumbs order={{ first: { name: "Settlement", path: `${prefix}/settlement` }, second: { name: "Employee Transaction" } }} />
                    {/* <h4 className="page-title mb-0 text-primary">Employee Details</h4> */}
                </div>
                <div style={{ marginLeft: '450px', marginTop: '25px' }}>
                    {/* <h4 className="ml-2">{totalAmoumt}</h4> */}
                    {/* <h5 > {totalAmoumt ? `Total: $${num2}` : ""}</h5> */}
                </div>

                <div className="page-rightheader">
                    <div className="btn-list">
                        {/* <button className='btn btn-outline-primary mt-3' data-bs-target="#add_settlement"
         data-bs-toggle="modal"
    disabled={multippleArray.length > 0 ? false : true} >+ Pay to Lender</button> */}
                        {/* <Link to={prefix + "/add_employer"} className="btn btn-outline-primary mt-3" ><i className="fa fa-plus me-2"></i> Add Employee</Link> */}
                        {/* <CSVLink {...csvReport}  className="btn btn-outline-primary mt-3"><i className="fa fa-download me-2"></i>Import CSV</CSVLink>  */}
                        {/* <CSVLink {...csvReport}  className="btn btn-outline-primary mt-3"><i className="fa fa-download me-2"></i>Import CSV</CSVLink>          */}
                        <a href={process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/settlement-export/${id}/${merchant_id}?txn_from=${txn_from} `} className="btn btn-outline-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div>

            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>

                                            <th className="font-weight-bold">TXN ID. {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Gross Amount {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('amount') }}></i>}
                                            </th>
                                            {txn_from == 'EWA' && <th className="font-weight-bold">Amount  {sortedBy == "deduction_amount_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_amount_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_amount_limit') }}></i>}
                                            </th>}
                                            <th className="font-weight-bold"> Net amount {/* less {merchantDataheader}% */}   {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }}></i>}</th>

                                            <th className="font-weight-bold">Merchant Settled Amount  {sortedBy == "deduction_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_code') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">Commission    {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }}></i>}
                                            </th>
                                            <th className="font-weight-bold">TXN Date </th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                            <Loader /> </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { txn_id, paid_amount, amount, merchant_settled_amount, mrch_commission, updated_at } = data;
                                                    var timezone = new Date(data.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                    // const percentage = ((  merchant_settled_amount/paid_amount) * 100).toFixed(2);                                                  
                                                    // const actualAmount = (data.paid_amount * (percentage / 100)).toFixed(2);
                                                    const commission = (merchantDataheader / 100) * paid_amount;
                                                    const actualAmount = paid_amount - commission;
                                                    console.log(actualAmount)
                                                    return (
                                                        <tr key={serial_num}>
                                                            <td>{txn_id ? txn_id : ""}
                                                                <span>
                                                                    <CopyToClipboard text={data.txn_id} onCopy={() => setCopied(true)}>
                                                                        <button className='btn btn-primary btn-sm ms-3'><i className="fa-solid fa-copy"></i></button>
                                                                    </CopyToClipboard>
                                                                </span>
                                                            </td>
                                                            <td>{paid_amount ? "$" + paid_amount : ""}</td>
                                                            {txn_from == 'EWA' && <td>{amount ? "$" + amount : ""}</td>}
                                                            <td><span className="font-weight-normal1">{typeof data.paid_amount == 'string' ? "$" + parseFloat(actualAmount).toFixed(2) : "NA"}</span></td>
                                                            <td><span className="font-weight-normal1">{data.merchant_settled_amount ? "$" + data.merchant_settled_amount : "NA"}</span></td>

                                                            {/* <td>{merchant_settled_amount ? "$" + merchant_settled_amount : ""}</td> */}
                                                            <td>{mrch_commission ? "$" + mrch_commission : ""}</td>
                                                            <td>{TimeZone(data.updated_at).timeZone}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>



        </div>
    )
}

export default ViewtransactionID
