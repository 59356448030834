import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import PaginationComponent from '../../PaginationComponent';
import { GetPaidTransactiondetail, GetTransactionDetail, GetmanageEWApending, PaidWalletUpdate, Reconciliation, getApi, getWalletPaidTransaction, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import { Breadcrumbs, Breadcrumbsecond } from '../../components';
import { Image } from 'antd';
import moment from 'moment';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Limitfilter from '../../components/Limitfilter/Limitfilter';
import Search from '../../components/Search/Search';
const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const ViewPaid = () => {
    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [Entrieslimit, setEntrieslimit] = useState("10")
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const ref = useRef()
    const [imageUpload, setimageUpload] = useState(null)
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [detail, setDetail] = useState([]);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [multippleArray, setMultippleArray] = useState([]);
    const [totalAmoumt, setTotalAmount] = useState(0);
    const [datas, setDatas] = useState([])
    const [disable, setDisable] = useState(false);
    const [Count, setCount] = useState();
    const [ValidPagination, setValidPagination] = useState(false)

    const GetWalletPaidTransaction = async (token) => {
        // const response = await getWalletPaidTransaction(token, search, pagesCount, sortedBy, orderBy)
        const response = await getApi("/wallet-paid-transaction", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy, limit: Entrieslimit })

        console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setCount(response.data.count)
                // setDatas(response.data.rows[0].merchant_id)
                setloader(false)
            }, 2000);
            setloader(true)

        } else if (response.status == false) {
            console.log(response)
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        GetWalletPaidTransaction(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy, Entrieslimit]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const copiedInfo = () => {
        toast.success("Copied")
    }

    const Form = useFormik({
        initialValues: {
            merchantTransctionId: "",
            payment_mode: '',
            comment: "",
            image: "",
            payment_status: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            comment: yup.string().required('Please Add a Comment'),
            payment_mode: yup.string().required('Please Select Payment Mode '),
            payment_status: yup.string().required('Please Select Status')
        }),

        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("id", multippleArray);
            formData.append("payment_mode", Form.getFieldProps('payment_mode').value);
            formData.append("comment", Form.getFieldProps('comment').value);
            formData.append("image", imageUpload);
            formData.append("payment_status", Form.getFieldProps('payment_status').value)

            /* PaidWalletUpdate(admin.admin_token, formData) */
            postApi("/update-wallet-merchant-paid", formData, true).then((res) => {
                if (res.status == true) {
                    toast.success(res.message);
                    resetForm({ values: '' });
                    ref.current.click()
                    GetWalletPaidTransaction(admin.admin_token);
                    setMultippleArray([])
                    setTotalAmount(0)
                }
            }).catch((err) => {

            })
        }
    })

    const selectTransactions = async (e, id, amount) => {
        setMultippleArray(id)
        setTotalAmount(amount)

    }
    var num3 = parseFloat(totalAmoumt).toFixed(2)

    const handleInputChange = (event) => {
        Form.handleChange(event);
        if (event?.target?.value) {
            setDisable(true)
        }
    }

    return (
        <>

            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h4 className="page-title mb-0 text-primary">Talk To Us </h4> */}
                                <Breadcrumbsecond order={{ first: { name: "Manage Wallet" }, second: { name: `Paid ${Count ? "(" + Count + ")" : ""}` } }} />
                            </div>

                            <div style={{ marginLeft: '450px', marginTop: '12px' }}>
                                <h5 className="ml-2">{totalAmoumt ? `Total: ${"$" + num3}` : ""}</h5>
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link> */}
                                    {/* <button className="btn btn-primary mt-2" data-bs-target="#add_settlement"
                                        data-bs-toggle="modal" disabled={multippleArray.length > 0 ? false : true} > Reconciliation</button> */}
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" /> */}
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            <Limitfilter Entrieslimit={Entrieslimit} setEntrieslimit={setEntrieslimit} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        {/* <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th> */}
                                                        {/* <th /> */}
                                                        {/* <th className="font-weight-bold">Logo  </th> */}
                                                        <th className="font-weight-bold">TXN Docs  </th>
                                                        <th className="font-weight-bold">Merchant Detail {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}></i>}</th>
                                                        {/* <th className="font-weight-bold"> Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th> */}
                                                        <th className="font-weight-bold">Paid Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                        <th className="font-weight-bold">TXN No.  {sortedBy == "txn_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }}></i>}</th>
                                                        <th className="font-weight-bold">Payment Mode  {sortedBy == "payment_mode" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }}></i>}</th>
                                                        <th className="font-weight-bold">Comment {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>}</th>
                                                        <th className="font-weight-bold">Payment Status {sortedBy == "payment_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_status') }}></i>}</th>
                                                        <th className="font-weight-bold">TXN Date {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                                        <th className="font-weight-bold">Action</th>


                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { logo, txn_docs, payment_status, reconciliation_status, merchant_name, merchant_mobile_number, txn_number, payment_mode, comment, updated_at, amount } = option;
                                                                // var timezone = new Date(option.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });


                                                                return (
                                                                    <tr key={serial_num} >
                                                                        {/* {option.logo != null ?
                                                                            <td><span className="font-weight-normal1">
                                                                                <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={logo} alt='' /></span></td>
                                                                            : <td><span className="font-weight-normal1" >
                                                                                <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='' /></span></td>
                                                                        } */}
                                                                        {/* {reconciliation_status == 'pending' ? <td><input type="checkbox" name={option.id} checked={checheked(option.id) === 1 ? true : false} onChange={(e) => selectTransactions(e, option.id, option.amount)} /></td>
                                                                            : <span style={{ marginLeft: "20px" }}>-</span>} */}
                                                                        {option.txn_docs != null ?
                                                                            <td><span className="font-weight-normal1">
                                                                                <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.txn_docs} alt='' /></span></td>
                                                                            : <td><span className="font-weight-normal1" >
                                                                                <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='' /></span></td>}
                                                                        <td><Link className="text-primary" to={prefix + `/merchantdetail/${option.merchant_id}`}><span className="font-weight-normal1">{merchant_name ? merchant_name : "NA"}<br />
                                                                            {merchant_mobile_number ? merchant_mobile_number : ""}</span></Link></td>
                                                                        {/* <td><span className="font-weight-normal1 ">{amount ? "$" + amount : "NA"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{amount ? + amount + "%" : "NA"}</span></td> */}
                                                                        <td><span className="font-weight-normal1 ">{amount ? "$" + amount : "NA"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{txn_number ? txn_number : "NA"}</span>
                                                                            <span>
                                                                                <CopyToClipboard text={txn_number} >
                                                                                    <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                                </CopyToClipboard>
                                                                            </span></td>
                                                                        <td><span className="font-weight-normal1">{payment_mode ? payment_mode : "NA"}</span></td>
                                                                        <td><span className="font-weight-normal1">{comment ? comment : "NA"}</span></td>
                                                                        {/* <td><span className="font-weight-normal1">{payment_status ? payment_status : "NA"}</span></td> */}
                                                                        <td>{(option.payment_status == 'initiate') ? <span className="badge rounded-pill" style={{ background: "#ff8f00" }}>{payment_status}</span>
                                                                            : <span> {option.payment_status == 'cancelled' ? <span className="badge rounded-pill " style={{ background: "#d44343" }}>{payment_status}</span>
                                                                                : <span className="badge rounded-pill" style={{ background: "green" }}>{payment_status}</span>}</span>}</td>
                                                                        <td><span className="font-weight-normal1">{option.updated_at ? TimeZone(option.created_at).timeZone : "NA"}</span></td>
                                                                        {/* <td><button className='btn btn-outline-primary' onClick={() => getDetail(admin.admin_token, option.id)}
                                                                    data-bs-target="#applicaiton-report" data-bs-toggle="modal">View TXN Details</button></td> */}

                                                                        <td><Link className='btn btn-primary ' to={{ pathname: `${prefix}/wallet_paidbyid/${option.id}` }}>{/* <i className='fas fa-eye'></i> */}View TXN</Link>&nbsp;
                                                                            {(option.payment_status == 'initiate') ? <button className='btn btn-primary' onClick={(e) => selectTransactions(e, option.id, option.amount)}
                                                                                data-bs-target="#add_settlement" data-bs-toggle="modal" >Update</button> :
                                                                                <button className='btn btn-primary' disabled>Update</button>}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>}
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={Entrieslimit}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={Entrieslimit}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>

                    {/* Merchant Information Start */}
                    <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header"> <h6 className="modal-title">Merchant Transaction Details</h6>
                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                                <div className="modal-body application-modal">

                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="">
                                                <div className="main-content-body main-content-body-contacts">
                                                    {/* <h6>Marchant Information</h6> */}
                                                    <div className="main-contact-info-header bg-contacthead pb15">
                                                        <div className="media">
                                                            <div className="media-body text-white ml-0">
                                                                <h4 className="text-white">Company Name: {detail && detail ? detail.company_name : ''}</h4>
                                                                <p className="mb-1">Person Name: {detail && detail ? detail.full_name : ''}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="main-contact-action"> <Link data-bs-dismiss="modal" onClick={() => hideModel(detail)} className="btn btn-primary"> Edit</Link></div> */}
                                                    </div>
                                                    <div className="main-contact-info-body">
                                                        <div className="media-list p-0">
                                                            <div className="media py-4 mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Total Amount</label> <span className="font-weight-normal1 fs-14">{detail && detail ? "$" + detail.amount : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Merchant Settled Amount</label> <span className="font-weight-normal1 fs-14">{detail && detail ? "$" + detail.merchant_settled_amount : ''}</span> </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">

                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-dollar "></i> </div>
                                                                        <div className="w-70"> <label>TXN Charges:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? "$" + detail.txn_charge : ''}</span> </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Merchant Commission</label> <span className="font-weight-normal1 fs-14">{detail && detail ? "$" + detail.mrch_commission : ''}</span> </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-percent "></i> </div>
                                                                        <div className="w-70"> <label>Merchant Commission %</label> <span className="font-weight-normal1 fs-14">{detail && detail ? +detail.thirdroc_roi + "%" : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                        <div className="w-70"> <label>Transaction ID</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.txn_id : ''}</span> </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Merchant Information End */}

                    {/* Reconciliation modal Start */}
                    <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header">
                                    <h3 className="modal-title">Update Transaction</h3>
                                    <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                        aria-hidden="true">×</span></button>
                                </div>
                                <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                                    <h4> {totalAmoumt ? `Total: ${"$" + totalAmoumt}` : ""}</h4>
                                </div>
                                <div className="modal-body application-modal">

                                    <form className="mt-1 row" onSubmit={Form.handleSubmit}>
                                        {Form.values.wrong_message ?
                                            <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{Form.values.wrong_message}</div>
                                            : ''}
                                        <div className="row">
                                            <div className="row g-xs">
                                                <div className="col-12">
                                                    <select className="form-control" name="payment_mode"
                                                        {...Form.getFieldProps("payment_mode")}
                                                        placeholder="Enter Payment Mode"  >
                                                        <option value="null">Select One</option>
                                                        <option value="cash" >Cash</option>
                                                        <option value="cheque">Cheque</option>
                                                        <option value="bank transfer">Bank Transfer</option>
                                                    </select>
                                                    {Form.touched.payment_mode && Form.errors.payment_mode ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.payment_mode}</div> : ''}
                                                </div>
                                                <div className="col-12">
                                                    <input type="text" onKeyUp={(e) => handleInputChange(e)} placeholder='comment' {...Form.getFieldProps("comment")} value={Form?.comment?.values} className="form-control" />
                                                    {Form.touched.comment && Form.errors.comment ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.comment}</div> : ''}
                                                </div>
                                                <div className="col-12">
                                                    <select className="form-control" name="payment_status"
                                                        {...Form.getFieldProps("payment_status")}
                                                        placeholder="Select Status Mode"  >
                                                        <option value="null">Select Payment Status</option>
                                                        <option value="success" >Success</option>
                                                        <option value="cancelled">Cancel</option>
                                                    </select>
                                                    {Form.touched.payment_status && Form.errors.payment_status ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.payment_status}</div> : ''}
                                                </div>
                                                <div className="input-group file-browser mb-5">
                                                    <input type="file" onKeyUp={(e) => handleInputChange(e)} className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                                                </div>
                                                {/* {Form.touched.image && Form.errors.image ?
                                                 <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.image}</div> : ''} */}

                                            </div>
                                            <div className="form-footer mt-2">
                                                <button type="submit" className="btn btn-primary" aria-hidden="true" disabled={!disable ? true : false}>
                                                    Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Reconciliation modal End */}
                </div>

            }
        </>
    )
}

export default ViewPaid
