import Header from '../website/header';
import Footer from '../website/footer';
const TermsOfUser = () => {


	return (
		<>
			<Header />
			<section className="her-bannerimg" style={{ paddingBottom: "100px" }}>
				<div className="container">
					<div className="contact-heading"><h1 className="mb-0">TERMS  OF USE</h1></div>
				</div>
			</section>
			<section className="p-top-100 p-bottom-55">
				<div className="container">
					<div className="row">
						<div className="col-md-12 mb-3">
							<h4 className="mb-1">1.	About the Website</h4>
							<p className="lead mb50">
								(a)	Welcome to Thirdroc (the 'Website'). The Website allows you to access and use the Thirdroc financial wellness (the 'Services').
							</p>
							<p className="lead mb50">
								(b)	The Website is operated by Thirdroc Pty Ltd, ACN/ABN 22 666 061 650 . Access to and use of the Website, or any of its associated Products or Services, is provided by Thirdroc Pty Ltd. Please read these terms and conditions (the 'Terms') carefully. By using, browsing and/or reading the Website, this signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of Services, immediately.
							</p>
							<p className="lead mb50">
								(c)	Thirdroc Pty Ltd reserves the right to review and change any of the Terms by updating this page at its sole discretion. When Thirdroc Pty Ltd updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">2.	Acceptance of the Terms</h4>
							<p className="lead mb50">
								You accept the Terms by remaining on the Website. Where the option is available in the user interface, you may also accept the Terms by clicking to accept or agree to the Terms.
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">3.	About the Service</h4>
							<p className="lead mb50">
								(a)	Thirdroc financial wellness is Earned Wage Access/ BNPL / Financial Wellness program.
							</p>
							<p className="lead mb50">
								(b)	Self-service and enterprise / employer approved accounts are currently offered. You acknowledge and agree that the accounts offered, as well as the account features may change from time to time, and may be governed by separate terms which apply specific to the account. Where special account-specific terms apply, you will be informed, and must accept those terms before you are given such an account. For the avoidance of doubt these Terms apply unless otherwise agreed or amended by account-specific terms.
							</p>
							<p className="lead mb50">
								(c)	Some accounts may be governed by a separate Software Licensing Agreement with Thirdroc Pty Ltd, which may amend the terms of use. For the avoidance of doubt these Terms apply unless otherwise agreed or amended by the terms of an applicable Software Licensing Agreement.
							</p>
						</div>


						<div className="col-md-12 mb-3">
							<h4 className="mb-1">4.	Acceptable use of the Service</h4>
							<p className="lead mb50">
								(a)	Thirdroc financial wellness, its related features, and website must only be used lawfully. Thirdroc Pty Ltd reserves the right to suspend, cancel, or otherwise deny access to users and accounts who use the service:
							</p>
							<ol>
								<li>(i)	To engage in any act that would disrupt the access, availability, and security of Thirdroc financial wellness and other Thirdroc Pty Ltd services, including but not limited to:</li>
								<li>(A)	Tampering with, reverse-engineering, or hacking our servers.</li>
								<li>(B)	Modifying, disabling, or compromising the performance Thirdroc financial wellness or other Thirdroc Pty Ltd services.</li>
								<li>(C)	Overwhelming, or attempting to overwhelm our infrastructure by imposing an unreasonably large load on our systems that consume extraordinary resources.</li>
								<li>(D)	Compromising the integrity of our system, including probing, scanning and testing the vulnerability of our system unless expressly permitted by Thirdroc Pty Ltd.</li>
							</ol>
							<p className="lead mb50">
								(b)	For any illegal purpose, or to violate any laws, including and without limitation to data, privacy, and export control laws.
							</p>
							<p className="lead mb50">
								(c)	To stalk, harass or threaten users and any member of the public.
							</p>
							<p className="lead mb50">
								(d)	To misrepresent or defraud any user or member of the public through phishing, spoofing, manipulating headers or other identifiers, impersonating anyone else, or falsely implying any sponsorship or association with Thirdroc Pty Ltd or any third party
							</p>
							<p className="lead mb50">
								(e)	To access or search any part of the Service, or any other Service owned by Thirdroc Pty Ltd other than our publicly supported interface, or otherwise allowed for in an applicable Software Licensing Agreement.
							</p>
							<p className="lead mb50">
								(f)	To post, upload, share, or otherwise circulate content in violation of Thirdroc financial wellness’s content policy
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">5.	Security and Data Privacy</h4>
							<p className="lead mb50">
								Thirdroc Pty Ltd takes your privacy seriously and information provided through your use of the Website and/or Services are subject to Thirdroc Pty Ltd’s Privacy Policy, which is available on the Website. The Privacy Policy also addresses Thirdroc Pty Ltd’s processes, policies, and obligations in respect of Thirdroc financial wellness security breaches.
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">6.	Data Use</h4>
							<p className="lead mb50">
								Thirdroc Pty Ltd collects, stores, and processes your data on Thirdroc financial wellness. The data is used to provide Services to you, as well as to facilitate Thirdroc Pty Ltd’s business operations. The Privacy Policy outlined how your data is collected, stored, and processed by Thirdroc Pty Ltd. The Privacy Policy also addresses Thirdroc Pty Ltd's processes, policies, and obligations in respect of data encryption and removal requests.
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">7.	Subscription to use the Service</h4>
							<p className="lead mb50">
								(a)	In order to access the Services, you must first purchase a subscription through the Website (the 'Subscription') and pay the applicable fee for the selected Subscription (the 'Subscription Fee'). After purchasing a Subscription, you will be considered a member (‘Member’).
							</p>
							<p className="lead mb50">
								(b)	In purchasing the Subscription, you acknowledge and agree that it is your responsibility to ensure that the Subscription you elect to purchase is suitable for your use.
							</p>
							<p className="lead mb50">
								(c)	Before, during or after you have purchased the Subscription, you will then be required to register for an account through the Website before you can access the Services (the ' Account').
							</p>
							<p className="lead mb50">
								(d)	As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including but not limited to personal details and any financial information pertaining to applying for a BNPL limit.
							</p>
							<p className="lead mb50">
								(e)	You warrant that any information you give to Thirdroc Pty Ltd in the course of completing the registration process will always be accurate, correct and up to date.
							</p>
							<p className="lead mb50">
								(f)	Once you have completed the registration process, you will be a registered user of the Website and agree to be bound by the Terms ("User"). As a Member you will be granted immediate access to the Services from the time you have completed the registration process until the subscription period expires (the 'Subscription Period').
							</p>
							<p className="lead mb50">
								(g)	You may not use the Services and may not accept the Terms if:
								<ol>
									<li>(i)	you are not of legal age to form a binding contract with Thirdroc Pty Ltd; or</li>
									<li>(ii)	you are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services</li>
								</ol>
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">8.	Payments</h4>
							<p className="lead mb50">
								(a)	Subject to the terms of any applicable Software License Agreement, theSubscription Fee may be paid by all payment methods available on the Website, and may change from time to time.</p>
							<p className="lead mb50">
								(b)	Payments made in the course of your use of Thirdroc financial wellness may be made using third-party applications and services not owned, operated, or otherwise controlled by Thirdroc Pty Ltd. You acknowledge and agree that Thirdroc Pty Ltd will not be liable for any losses or damage arising from the operations of third-party payment applications and services. You further acknowledge and warrant that you have read, understood and agree to be bound by the terms and conditions of the applicable third-party payment applications and services you choose to use as a payment method for Thirdroc financial wellness services.</p>
							<p className="lead mb50">
								(c)	You acknowledge and agree that where a request for the payment of the Subscription Fee is returned or denied, for whatever reason, by your financial institution or is unpaid by you for any other reason, then you are liable for any costs, including banking fees and charges, associated with the Subscription Fee.</p>
							<p className="lead mb50">
								(d)	You agree and acknowledge that Thirdroc Pty Ltd can vary the Subscription Fee at any time and that the varied Subscription Fee will come into effect following the conclusion of the existing Subscription.</p>

						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">9.	Refund Policy</h4>
							<p className="lead mb50">
								Thirdroc Pty Ltd will only provide you with a refund of the Subscription Fee in the event they are unable to continue to provide the Services or if the manager of Thirdroc Pty Ltd makes a decision, at its absolute discretion, that it is reasonable to do so under the circumstances . Where this occurs, the refund will be in the proportional amount of the Subscription Fee that remains unused by the Member (the 'Refund').</p>

						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">10. Copyright and Intellectual Property</h4>
							<p className="lead mb50">
								(a)	The Website, the Services and all of the related products of Thirdroc Pty Ltd are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips, Website code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved by Thirdroc Pty Ltd or its contributors.</p>
							<p className="lead mb50 mb-0">
								(b)	All trademarks, service marks and trade names are owned, registered and/or licensed by Thirdroc Pty Ltd, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a User to:</p>
							<ol>
								<li>(i)	use the Website pursuant to the Terms;</li>
								<li>(ii)	copy and store the Website and the material contained in the Website in your device's cache memory; and</li>
								<li>(iii)	print pages from the Website for your own personal and non-commercial use.</li>
							</ol>

							<p className="lead mb50 mb-0">
								(c)	Thirdroc Pty Ltd does not grant you any other rights whatsoever in relation to the Website or the Services. All other rights are expressly reserved by Thirdroc Pty Ltd.
							</p>

							<p className="lead mb50 mb-0">
								(d)	Thirdroc Pty Ltd retains all rights, title and interest in and to the Website and all related Services. Nothing you do on or in relation to the Website will transfer any:
							</p>
							<ol>
								<li>(i)	business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright, or</li>
								<li>(ii)	a right to use or exploit a business name, trading name, domain name, trade mark or industrial design, or</li>
								<li>(iii)	a thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a thing, system or process), to you.</li>
							</ol>
							<p className="lead mb50 mb-0">
								(e)	You may not, without the prior written permission of Thirdroc Pty Ltd and the permission of any other relevant rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain.
							</p>

						</div>


						<div className="col-md-12 mb-3">
							<h4 className="mb-1">11.	General Disclaimer</h4>
							<p className="lead mb50">
								(a)	Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.</p>

							<p className="lead mb50 mb-1">
								(b)	Subject to this clause, and to the extent permitted by law:
							</p>
							<ol>
								<li>(i)	all terms, guarantees, warranties, representations or conditions which are not expressly stated in the Terms are excluded; and</li>
								<li>(ii)	Thirdroc Pty Ltd will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.</li>
							</ol>

							<p className="lead mb50 mb-1">(c)	Use of the Website and the Services is at your own risk. Everything on the Website and the Services is provided to you "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors and licensors of Thirdroc Pty Ltd make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of Thirdroc Pty Ltd) referred to on the Website. This includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:</p>
							<ol>
								<li>(i)	failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records;</li>
								<li>(ii)	the accuracy, suitability or currency of any information on the Website, the Services, or any of its Services related products (including third party material and advertisements on the Website);</li>
								<li>(iii)	costs incurred as a result of you using the Website, the Services or any of the products of Thirdroc Pty Ltd; and</li>
								<li>(iv)	the Services or operation in respect to links which are provided for your convenience.</li>
							</ol>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">12.	Limitation of Liability</h4>
							<p className="lead mb50">(a)	Thirdroc Pty Ltd's total liability arising out of or in connection with the Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the resupply of the Services to you.</p>
							<p className="lead mb50">(b)	You expressly understand and agree that Thirdroc Pty Ltd, its affiliates, employees, agents, contributors and licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation and any other intangible loss.</p>
						</div>


						<div className="col-md-12 mb-3">
							<h4 className="mb-1">13.	Termination of Contract</h4>
							<p className="lead mb50">(a)	The Terms will continue to apply until terminated by either you or by Thirdroc Pty Ltd as set out below.</p>

							<p className="lead mb50 mb-1">(b)	If you want to terminate the Terms, you may do so by:</p>
							<ol>
								<li>(i)	not renewing the Subscription prior to the end of the Subscription Period;</li>
								<li>(ii) providing Thirdroc Pty Ltd with thirty days’ notice of your intention to terminate; and</li>
								<li>(iii) closing your accounts for all of the services which you use, where Thirdroc Pty Ltd has made this option available to you.</li>
							</ol>
							<p className="lead mb50">(c)	Any notices pursuant to Clause 13.2 above should be sent, in writing, to Thirdroc Pty Ltd via the 'Contact Us' link on our homepage.</p>

							<p className="lead mb50 mb-1">(d)	Thirdroc Pty Ltd may at any time, terminate the Terms with you if:</p>
							<ol>
								<li>(i)	you do not renew the Subscription at the end of the Subscription Period;</li>
								<li>(ii) you have breached any provision of the Terms or intend to breach any provision;</li>
								<li>(iii)	Thirdroc Pty Ltd is required to do so by law;</li>
								<li>(iv)	the provision of the Services to you by Thirdroc Pty Ltd is, in the opinion of Thirdroc Pty Ltd, no longer commercially viable.</li>
							</ol>

							<p className="lead mb50">(e)	Subject to local applicable laws, Thirdroc Pty Ltd reserves the right to discontinue or cancel your Subscription or Account at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts Thirdroc Pty Ltd's name or reputation or violates the rights of those of another party.</p>
						</div>


						<div className="col-md-12 mb-3">
							<h4 className="mb-1">14.	Indemnity</h4>
							<p className="lead mb50 mb-1">You agree to indemnify Thirdroc Pty Ltd, its affiliates, employees, agents, contributors, third party content providers and licensors from and against:</p>
							<ol>
								<li>(a)	all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with your content;</li>
								<li>(b)	any direct or indirect consequences of you accessing, using or transacting on the Website or attempts to do so; and/or</li>
								<li>(c)	any breach of the Terms.</li>
							</ol>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">15.	Dispute Resolution</h4>
							<h6 className="mb-1">15.1.	Compulsory:</h6>
							<p className="lead mb50">If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).</p>
							<h6 className="mb-1">15.2. Notice:</h6>
							<p className="lead mb50">A party to the Terms claiming a dispute ('Dispute') has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute.</p>


							<h6 className="mb-1">15.3. Resolution:</h6>
							<p className="lead mb50 mb-1">On receipt of that notice ('Notice') by that other party, the parties to the Terms ('Parties') must:</p>
							<ol>
								<li>(a)	Within thirty days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;</li>
								<li>(b)	If for any reason whatsoever, thirty days after the date of the Notice, the Dispute has not been resolved, the Parties must request that an appropriate mediator be appointed by the relevant agency;</li>
								<li>(c)	The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;</li>
								<li>(d)	The mediation will be held in Melbourne, Australia.</li>
							</ol>

							<h6 className="mb-1">15.4.	Confidential:</h6>
							<p className="lead mb50 mb-1">All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.</p>
							<h6 className="mb-1">15.5.	Termination of Mediation:</h6>
							<p className="lead mb50 mb-1">If thirty days have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either party may ask the mediator to terminate the mediation and the mediator must do so.</p>
						</div>




						<div className="col-md-12 mb-3">
							<h4 className="mb-1">16.	Venue and Jurisdiction</h4>
							<p className="lead mb50">The Services offered by Thirdroc Pty Ltd is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria.
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">17.	Governing Law</h4>
							<p className="lead mb50">The Terms are governed by the laws of Victoria. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of Victoria, Australia without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">18.	Independent Legal Advice</h4>
							<p className="lead mb50">Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties having taken the opportunity to obtain independent legal advice and declare the Terms are not against public policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.
							</p>
						</div>

						<div className="col-md-12 mb-3">
							<h4 className="mb-1">19.	Severance</h4>
							<p className="lead mb50">If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.
							</p>
						</div>






					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}

export default TermsOfUser;