import React from 'react'
import { useNavigate } from 'react-router-dom'

const Verifiedagreement = () => {
    const navigate = useNavigate()

    const handleButton = () => {
        navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/login')
        // localStorage.removeItem("ewa_advance");
    }
    return (
        <div className="congratulation-page">
            <div className="congratulation-header">
                <img src="/assets/img/logo-thirdroc1.png" alt="Confetti" />
                <h1>Thank You!</h1>
            </div>
            <div className="congratulation-content">
                <p>Thank You on successfully Verifying the agreement.</p>
                <p>We are thrilled to have you on board and look forward to a successful partnership.</p>
                <img src="/assets/img/hand-shake.png" alt="Handshake" />
                {/* <p>Please find the signed agreement attached below:</p>
                <a href="#" download="agreement.pdf">Download Agreement PDF</a> */}
                <br /> <button className="next-step-btn" onClick={() => handleButton()}>Go to Login Page</button>
            </div>
        </div>
    )
}

export default Verifiedagreement