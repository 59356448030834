import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { getApi } from '../../service/lenderService';
import { Breadcrumbs } from '../../../components';
import Loader from '../../../components/Loader/loader';

const Emibyorderid = () => {
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const [dataArray, setDataArray] = useState([]);
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [customer, setcustomer] = useState()
    const [loader, setloader] = useState(true)
    const lender = JSON.parse(localStorage.getItem("lender"));
    const tableRef = useRef(null);
    const { id } = useParams();
    const navigate = useNavigate()
    console.log(id)
    const GetemibyOrderID = async (token, id) => {
        const response = await getApi(`/transaction-emi-detail/${id}`,);
        if (response.status == true) {
            setTimeout(() => {
                // console.log(response.data);
                setDataArray(response.data);
                setcustomer(response.data[0].customer_id)
                setloader(false)
            }, 2000);

        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else {
            console.log("get employees data response", response);
        }
    }
    console.log(customer)
    useEffect(() => {
        GetemibyOrderID(lender.lender_token, id)
    }, [id])


    // const paytomerchant = async(txn_id,customer_id,merchantId) => {
    //     const check = {
    //         data: [
    //             {
    //                 "txnId":txn_id,
    //                 "borrowerId": customer_id,
    //                 "merchantId": merchantId,                  
    //                 "payment_mode": "cash"
    //             }
    //         ]
    //     }
    //     const response = await axios.post(`https://thirdroc.ai:8888/lender/pay-to-merchant`,(check), {
    //         headers: {
    //             "Authorization": `Bearer ${lender.lender_token}`
    //         }
    //     })
    //     console.log(response)
    //     if(response.data.status == true){
    //         // console.log("trueeeeeeeeeeee")
    //         toast.success("Pay to Merchant Successfully");
    //     }
    // }



    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h4 className="page-title mb-0 text-primary">Personal EMI</h4> */}
                                <Breadcrumbs order={{ first: { name: "Borrower" }, second: /* { name: "Details", path: `${prefix}/usedlimitdetails/${customer}/Existing` }, third: */ { name: "Repayment" } }} />

                                {/* <h6 className="page-title mb-0 text-primary"><Link  to={{pathname: `${prefix}/employee`}}>Borrower</Link> <i className='fas fa-arrow-right'/><Link  to={{pathname: `${prefix}/usedlimitdetails/${customer}`}}> Details</Link>  <i className='fas fa-arrow-right'/> EMI </h6> */}

                            </div>

                            <div className="page-rightheader">


                                {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                                <div className="btn-list">
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                                {/* <div className="form-group">
                                     <label className="form-label">All Status</label>
                                     <div className="row g-xs">
                                         <div className="wd-200 mg-b-30">
                                             <div className="input-group">
                                                 <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                     <option>Select Status</option>
                                                     <option value="all task" >All Status</option>
                                                     <option value="Assigned"  >Assigned</option>
                                                     <option value="Unassigned" >Unassigned</option>
 
                                                 </select>
                                             </div>
                                         </div>
                                     </div>
                                 </div> */}
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                {/* <div className="form-group">
                                     <label className="form-label">Search</label>
                                     <div className="row g-xs">
                                     <div className="input-group">
                                             <input type="text" className="form-control" placeholder="Search By Keyword"  value={search} onChange={(e) => setSearch(e.target.value)} />                                          
                                             <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                         </div>
                                     </div>
                                 </div> */}
                            </div>
                        </div>



                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef} >

                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Borrower Detail.</th>
                                                    {/* <th className="font-weight-bold">Employer Detail</th> */}
                                                    <th className="font-weight-bold"> Merchant Detail </th>
                                                    <th className="font-weight-bold">Due Date</th>
                                                    <th className="font-weight-bold">Amount</th>
                                                    <th className="font-weight-bold">Lender Interest</th>
                                                    {/* <th className="font-weight-bold">Merchant Amount</th>
                        <th className="font-weight-bold">ThirdRoc Amount</th> */}
                                                    <th className="font-weight-bold">Status</th>
                                                    {/* <th className="font-weight-bold">Action</th> */}

                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /><td />
                                                <Loader />
                                            </tbody> :
                                                <>
                                                    <tbody>

                                                        {dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
                                                            const { customer_id, customer_name, customer_mobile_number, status, lender_commission_amount, merchant_mobile_number, lender_roi, txn_id, employer_id, merchantId, mini_rate_of_intrest, first_name, mobile_number, employer_name, employer_mobile, merchant_name, merchant_mobile, due_date, amount, interest, paid_amount, merchantAmount, thirRocCom } = option;
                                                            var num = parseFloat(option.thirRocCom).toFixed(2)
                                                            // var Capitalize = (option) => {
                                                            //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                            //     } 

                                                            return (
                                                                <tr key={customer_id} >
                                                                    <td><Link className="text-primary" to={{ pathname: `${prefix}/employee_detail/${customer_mobile_number}/Existing`, state: { customer_mobile_number } }}><span className="font-weight-normal1">{customer_name}<br />{customer_mobile_number}</span></Link></td>
                                                                    {/* <td><Link className="text-primary text-primary" to={{pathname: `${prefix}/employer_detail/${employer_id}`, state: {employer_id}}}>
                                    <span className="font-weight-normal1">{employer_name}<br/>{employer_mobile}</span></Link></td> */}
                                                                    <td><span className="font-weight-normal1">{merchant_name}<br />{merchant_mobile_number}</span></td>
                                                                    <td><span className="font-weight-normal1">{due_date}</span></td>
                                                                    <td><span className="font-weight-normal1">{amount ? "$" + amount : ""}</span></td>
                                                                    <td><span className="font-weight-normal1">{lender_commission_amount ? "$" + lender_commission_amount + "(" + lender_roi + "%" + ")" : ""}</span></td>
                                                                    {/* <td><span className="font-weight-normal1">{merchantAmount ? "$" + merchantAmount +  "(98%)" : ""}</span></td>
                                <td><span className="font-weight-normal1">{thirRocCom ? "$" + num : ""}</span></td> */}
                                                                    <td><span className="font-weight-normal1">{status}</span></td>
                                                                    {/* <td> <button className='btn btn-primary'  onClick={() => paytomerchant(txn_id,customer_id,merchantId)}>Pay to Merchant</button><br/><br/>
                             <button className='btn btn-primary' >Pay to ThirdRoc</button></td> */}
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>


    )
}

export default Emibyorderid






