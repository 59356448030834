import { Image } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { MerchantBNPLDetail, getApi } from '../service/adminService';
import { Breadcrumbs } from '../../components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import Search from '../../components/Search/Search';

const TransactionById = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loader, setloader] = useState(true)
    const { id } = useParams('');
    const S3_prefix = process.env.REACT_APP_S3_BUCKET_URL
    const [loading, setloading] = useState(false);
    const [profileData, setprofileData] = useState({});
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const ref = useRef()
    const [disable, setDisable] = useState(false);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [merchantData, setMerchantData] = useState([])
    //   const [multippleArray, setMultippleArray] = useState([]);
    //   const [totalAmoumt, setTotalAmount] = useState(0);
    //   const [isChecked, setIsChecked] = useState(false);
    //   const [imageUpload, setimageUpload] = useState(null)
    const [datas, setDatas] = useState([])
    const [Count, setCount] = useState("");
    const [ValidPagination, setValidPagination] = useState(false)
    const admin = JSON.parse(localStorage.getItem("admin"));

    const MerchantBNPLDuesDetail = async (token) => {
        //   const response = await MerchantBNPLDetail(token, id, pagesCount, search, sortedBy, orderBy);
        const response = await getApi(`/bnpl-transactions-detail/${id}`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

        //   console.log("fdgdgdgddfgdg", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.rows);
                setTotalPagesCount(response.count);
                setCount(response.count);
                setMerchantData(response.merchant)
                setDatas(response.merchant)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }


    useEffect(() => {
        // setpagesCount(1);
        MerchantBNPLDuesDetail(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const copiedInfo = () => {
        toast.success("Copied")
    }
    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <Breadcrumbs order={{ first: { name: "Transaction" }, second: { name: "View Details" } }} />                */}
                                <Breadcrumbs order={{ first: { name: "BNPL Transaction", path: `${prefix}/bnpl_transaction` }, second: { name: `Transaction Details ${Count && `(${Count})`}` } }} />

                            </div>

                            <div className='page-rightheader'>
                                {/* <button className='btn btn-primary'  > Add Settlement</button> */}
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap"/*  ref={tableRef} */>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">TXN ID   {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                                                        <th className="font-weight-bold">Customer Details  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Employer   {sortedBy == "employer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Paid Amount  {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Lender Income  {sortedBy == "lender_commission_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_commission_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_commission_amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Thirdroc Income  {sortedBy == "thirdroc_income" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('thirdroc_income') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('thirdroc_income') }}></i>}</th>
                                                        <th className="font-weight-bold">Merchant Settled Amount  {sortedBy == "merchant_settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }}></i>}</th>
                                                    </tr>
                                                </thead>

                                                {loader == true ? <tbody>
                                                    <td /><td /><td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> : <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            const { first_name, last_name, mobile_number, employer_name, txn_id, employer_mobile, paid_amount, lender_commission_amount, thirdroc_income, merchant_settled_amount } = option;
                                                            var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });


                                                            return (
                                                                <tr key={serial_num} >
                                                                    <td><span className="font-weight-normal1"> {txn_id ? txn_id : "NA"} </span>
                                                                        <span><CopyToClipboard text={txn_id} ><button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button></CopyToClipboard></span></td>
                                                                    <td><span className="font-weight-normal1">{first_name ? first_name : "NA"}&nbsp;{last_name ? last_name : ""}<br />{mobile_number ? mobile_number : ""}</span></td>
                                                                    <td><span className="font-weight-normal1 ">{employer_name ? employer_name : "NA"}<br />{employer_mobile ? employer_mobile : ""}</span></td>
                                                                    <td><span className="font-weight-normal1"> {paid_amount ? "$" + paid_amount : "NA"} </span></td>
                                                                    <td><span className="font-weight-normal1">{lender_commission_amount ? "$" + lender_commission_amount : "NA"} </span></td>
                                                                    <td><span className="font-weight-normal1">{thirdroc_income ? "$" + thirdroc_income : "NA"} </span></td>
                                                                    <td><span className="font-weight-normal1">{merchant_settled_amount ? "$" + merchant_settled_amount : "NA"} </span></td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr><td colSpan={10} className='text-center'>No Data Available</td></tr>}
                                                    </tbody>
                                                </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>



                </div>

            }

        </>
    )
}

export default TransactionById
