import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const MultiSelectAll = ({ data, handleChangeQueryBuilder, customer_id, nfc_id }) => {
  let [array, setarray] = useState([])
  let [arrdata, setarrdata] = useState({})
  let [func, setfunc] = useState({})
  nfc_id = nfc_id?.split(",")
  let nfcarray = []
  const options = data?.length > 0 && data?.map((e) => {
    nfcarray.push(e.nfc_id)
    const label = e.site_name && e.location ? `${e.site_name} - ${e.location}` : e.site_name || e.location;
    return ({
      id: e.id,
      label: label,
      value: e.nfc_id,
    })
  })
  let times = 1
  let filtered = options?.map((e, i) => {
    // console.log(e , i)
    if (nfc_id?.includes(e.value)) {
      array.push(e)
    }
  })
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {

    if (array) {
      setSelectedOptions([...array]);
      // console.log("setarrdata(array)",array)
      setarrdata(array)
    }
  }, [array]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o?.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value?.length} selected`;
    }
  }

  function onChange(value, event) {

    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
      // } else if (value.length === this.options.length - 1) {
      //   this.setState(this.options);
    } else {
      this.setState(value);
    }
    let data = value.map((e) => {
      return (
        e.value
      )
    })
    setfunc(data)

  }
  const handleblur = () => {
    if (Object.keys(func)?.length > 0) {
      handleChangeQueryBuilder(func, customer_id)
    }
  }

  return (
    <ReactMultiSelectCheckboxes
      onBlur={(e) => { handleblur() }}
      options={[...options]}
      placeholderButtonLabel="NFC Tag"
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions}
      onChange={onChange}
      setState={setSelectedOptions}
    />
  );
};

export default MultiSelectAll;
