import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PaginationComponent from '../../../../PaginationComponent';
import { GetCountry, GetPermission, getApi, postApi, uploadEmployees } from '../../../service/employService'
import { toast } from 'react-hot-toast';
import { EXCEL_EMPLOYEE_TEMPLATE } from '../../../../constant';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as XLSX from 'xlsx';
import Loader from '../../../../components/Loader/loader';
import { Switch } from 'antd';
import { Breadcrumbsecond } from '../../../../components';
import DeductionAmountByID from './DeductionAmountByID';

// import { useDispatch, useSelector } from 'react-redux';
// import { modal, modal1 } from '../../../Redux/modalerrorReducer';


const Employee = () => {
    // const [viewTeamMember, SetViewTeamMember] = useState()
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [employee, setEmployee] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [dataArrays, setDataArrays] = useState([]);
    const [search, setSearch] = useState('');
    const [dsaDetail, setdsaDetail] = useState(false);
    const [loader, setloader] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [sortedBy, setSortedBy] = useState("updatedAt")
    const [orderBy, setOrderBy] = useState('desc')
    const tableRef = useRef(null);
    const handleModal = useRef()
    const [excelFile, setExcelFile] = useState(null);
    const [fileToupload, setFileToUpload] = useState('');
    const ref = useRef()
    const navigate = useNavigate();
    const [multippleArray, setMultippleArray] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX
    const [permission_data, setPermission_data] = useState([])
    const [sendEmployeeID, setSendEmployeeID] = useState()
    const [deductionAmount, setDeductionAmount] = useState(0)

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response.data);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        if (user_type == "SUB-EMPLOYER") {
            GetPermissions(employer.employer_token);
        }
    }, []);
    const employer = JSON.parse(localStorage.getItem("employer"));
    const employerId = employer.user_id;
    const user_type = employer.user_type
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);

    } else {

    }

    const getEmployeesData = async (token) => {
        const response = await getApi("/get-employee", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
        //const response = await getApi(token, employerId, pagesCount, search, sortedBy, orderBy);
        console.log("🚀  file: epmloyer.js:25  getEmployeesData ~ response:", response)
        try {
            if (response.status == true) {
                setTimeout(() => {
                    setEmployee(response.data);
                    setDataArray(response.data);

                    setTotalPagesCount(response.allEmployeeCount)
                    setloader(false)
                }, 2000);
                setloader(true)
                //setTotalPagesCount(response.data["count"])
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
                // toast.error(response.message)
                // if (response.message == "Access denied! you're not allowed to access this tab") {
                //     navigate('/employer/dashboard')
                // }
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }

    const [employee_view, setemployee_view] = useState(false)
    const [employee_upload_bulk, setemployee_upload_bulk] = useState(false)
    const [employee_add, setemployee_add] = useState(false)
    const [employee_edit, setemployee_edit] = useState(false)
    const [employee_delete, setemployee_delete] = useState(false)
    useEffect(() => {
        if (role) {
            const options = role;
            options.length > 0 && options.forEach((option) => {
                if (option.key == "employee_view") {
                    setemployee_view(true);
                }
                if (option.key == "employee_upload_bulk") {
                    setemployee_upload_bulk(true);
                }
                if (option.key == "employee_add") {
                    setemployee_add(true);
                }
                if (option.key == "employee_edit") {
                    setemployee_edit(true);
                }
                if (option.key == "employee_delete") {
                    setemployee_delete(true);
                }
            });
        }
    }, [role]);




    useEffect(() => {
        // setpagesCount(1);
        getEmployeesData(employer.employer_token);
    }, [pagesCount, search, refresh, , sortedBy, orderBy]);


    const downloadTemplate = (fileN) => {

        const template = EXCEL_EMPLOYEE_TEMPLATE;
        let sliceSize = 1024;
        let byteCharacters = atob(template);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        saveAs(
            new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
            fileN
        );

    }

    const readExcel = (file) => {
        if (file) {

            setFileToUpload(file)
            uploadExcelForm.setFieldValue('isExcelFile', true)
            const promise = new Promise((resolve, reject) => {
                if (file) {
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(file);

                    reader.onload = (e) => {
                        const bufferArray = e.target.result;
                        const workBook = XLSX.read(bufferArray, { type: 'buffer' });
                        const workSheetName = workBook.SheetNames[0];
                        const workSheet = workBook.Sheets[workSheetName];
                        const data = XLSX.utils.sheet_to_json(workSheet);
                        resolve(data)


                    }
                    reader.onerror = (error) => {
                        reject(error)
                    }
                }
            })
            promise.then((d) => {
                setExcelFile(d)
                console.log('json data ', d);
            })

        } else {
            console.log('please select your file');
            // setExcelFileError('please select only excelfile')
        }
    }

    const uploadExcelForm = useFormik({
        initialValues: {
            // employer_id: '',
            isExcelFile: false,
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // employer_id: yup.string().required('Please enter Amount'),
            isExcelFile: yup.boolean().oneOf([true], ""),

        }),

        onSubmit: async (values) => {
            if (fileToupload) {
                const formData = new FormData();
                formData.append("file", fileToupload);
                const employer_id = JSON.parse(localStorage.getItem("employer"));
                const response = await uploadEmployees(formData, employer_id.employer_token);
                if (response.data.alreadyexists) {
                    ref.current.click()
                }
                if (response.status) {
                    toast.success(response.message + " & " + response.data.alreadyexistsCount + " Already Exist");
                    navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/employees');
                    getEmployeesData(employer.employer_token);
                    // window.$('#modal-center').modal('hide')	
                    ref.current.click()
                } else {
                    toast.error(response.message);
                }
            }
        },
    })

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }


    const getRoleandchild = async (token) => {
        // const response = await GetRoleandchild(token);
        const response = await getApi("/role-and-child-role");
        try {
            if (response.status == true) {
                setDataArrays(response.data.rows);
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getRoleandchild(employer.employer_token);
    }, []);

    const selectTransactions = async (e, id) => {
        let checkArray = await multippleArray;
        let rowIndex = checkArray.findIndex(v => v == id);
        if (rowIndex > -1) {

            checkArray.splice(rowIndex, 1);

        } else {
            checkArray.push(id);

        }

        setMultippleArray(checkArray);
        setDataArray((old_data) => [...old_data]);
        if (dataArray.length == multippleArray.length) {
            setIsChecked(true);
        }
        if (dataArray.length != multippleArray.length) {
            setIsChecked(false);
        }


    }

    function handleCheckClick() {
        if (isChecked == false) {
            let newdata = dataArray.map(v => parseInt(v.customer_id)).join(',')
            let arrayData = newdata.split(',').map(Number);
            setMultippleArray((old_data) => [...arrayData]);
            setIsChecked(true);
        } else {
            setIsChecked(false);
            setMultippleArray([]);
        }
        setDataArray((old_data) => [...old_data]);
    }

    const checheked = (item) => {
        return multippleArray.includes(item) ? 1 : 0;
    }


    const AssignRoletoEmployee = async (e) => {
        // const data = JSON.stringify({
        //     customer_ids: multippleArray,
        //     role_id: e.target.value
        // })

        // const response = await UpdateAssignRoletoEmployee(employer.employer_token, data)
        const response = await postApi("/assign-role", {
            customer_ids: multippleArray,
            role_id: e.target.value
        })

        // console.log(response)
        if (response.status == true) {
            toast.success(response.message)
            setMultippleArray([])
            getEmployeesData(employer.employer_token);
            // getUsermanager(employer.employer_token);
        } else {
            console.log(response.message)
        }
    }


    const UpdateModal = () => {
        handleModal.current.click()
        // setDeductionAmount('')
    }

    const Updatestatus = async (id) => {
        // console.log("first ", id)
        try {
            const res = await getApi(`/employee-disable/${id}`)
            if (res.status == true) {
                getEmployeesData(employer.employer_token);
                toast.success(res.message)
            } else {
                toast.error(res.message)
            }
        } catch (error) {
            console.log(error)
        }

    }

    const [inputFields, setInputFields] = useState([{ deductionName: '', deductionAmount: '' }]);

    const handleAddFields = () => {
        setInputFields([...inputFields, { deductionName: '', deductionAmount: '' }]);
    };

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        if (event.target.name === "value") {
            values[index].deductionName = event.target.value;
        } else {
            values[index].deductionAmount = event.target.value;
            setDeductionAmount(event.target.value);
        }
        setInputFields(values);
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };


    // console.log(inputFields, "============")

    return (
        <>
            {!dsaDetail &&
                <div className="main-container container-fluid px-0">
                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">
                                <Breadcrumbsecond order={{ first: { name: "Employee" }, second: { name: "Employee List" } }} />
                            </h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                {/* {(employee_upload_bulk || user_type == "employer") && (<button type="button" data-toggle="modal" data-target="#modal-center"
                                    className="btn btn-primary btn-pill mt-3"><i className="fa fa-plus me-2"></i> Upload Bulk </button>)} */}
                                <Link to={prefix + "/bulk_generate_payslip"} className="btn btn-primary btn-pill mt-3" ><i className="fa fa-plus me-2"></i> Bulk Generate Payslip</Link>
                                {(user_type == "employer" || employee_add) && (<Link to={prefix + "/add_employee"} className="btn btn-primary btn-pill mt-3" ><i className="fa fa-plus me-2"></i> Add Employee</Link>)}
                                <a href={exportfix + `/export-employee/${employer.user_name} `} className="btn btn-primary btn-pill mt-3" download >
                                    <i className="fa fa-download me-2"></i> Export Excel</a>
                            </div>
                        </div>
                        <div className="modal fade" id="modal-center" tabIndex="-1" role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered emplyoyemodal" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">

                                        <h5 className="modal-title" id="modal-centerLabel">Add Employees</h5>
                                        <button type="submit" className="btn btn-primary" onClick={() => downloadTemplate('employee-template.xlsx')}> Download Template</button>

                                    </div>
                                    <div className="modal-body">
                                        <div className="formbox row">
                                            {/* <div className="form-group form-group-mtb col-md-5 mt-2">
                                                <button type="submit" className="btn btn-primary" onClick={() => downloadTemplate('employee-template.xlsx')}> Download Employee Templete</button>
                                            </div> */}
                                            <form className="" onSubmit={uploadExcelForm.handleSubmit}  >
                                                {uploadExcelForm.values.wrong_message ?
                                                    <div className="invalid-feedback " style={{ display: "block", textAlign: "center" }}>{uploadExcelForm.values.wrong_message}</div>
                                                    : ''}
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="uploader" />
                                                        <div>
                                                            <div className="page-rightheader" style={{ marginLeft: '10px' }}>
                                                                <div className="btn-list ">
                                                                    <button className="btn btn-outline-primary uploadbmodal">
                                                                        <label htmlFor="formFile">
                                                                            <i className="fa fa-upload fa-beat me-2"></i>
                                                                        </label>
                                                                        <input cstyle={{ marginLeft: '10px' }} type="file" id='formFile' onChange={(e) => {
                                                                            const file = e.target.files[0]; readExcel(file);
                                                                        }} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="modalboxEmployee">
                                                                <button type="submit" className="btn btn-primary  w-md mb-0 mt-3 mb-0" > Save and Update</button>
                                                                <button ref={ref} type="button" className="close mt-3" data-dismiss="modal" aria-label="Close"> Close </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            {multippleArray.length > 0 && <div className="form-group">
                                <label className="form-label">Assign Role</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control" name="parent_id" onChange={(e) => AssignRoletoEmployee(e)} >
                                                <option>Select Role</option>
                                                {dataArrays.map((e, id) => {
                                                    return (
                                                        <>
                                                            <option key={id} value={e.id}>{e.title}</option>
                                                            {e?.child.map((children, id) => {
                                                                return (
                                                                    <>
                                                                        <option key={id} value={children.id}>&nbsp;&nbsp; -- {children.title}</option>
                                                                        {
                                                                            children?.child.map((subchild, id) => {
                                                                                return (
                                                                                    <>
                                                                                        <option key={id} value={subchild.id}>&nbsp;&nbsp;&nbsp; --- {subchild.title}</option>
                                                                                        {subchild?.child.map((superchild, id) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <option key={id} value={superchild.id}>&nbsp;&nbsp;&nbsp;&nbsp; ---- {superchild.title}</option>
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                    </>
                                                                                )
                                                                            })}
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    {user_type == "employer" && <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>}
                                                    <th className="font-weight-bold">Status</th>
                                                    <th className="font-weight-bold">Employee Details  {sortedBy == "name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('name') }}></i>}</th>
                                                    <th className="font-weight-bold">Pay Cycle  {sortedBy == "pay_cycle" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('pay_cycle') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('pay_cycle') }}></i>}</th>
                                                    {/* <th className="font-weight-bold">Country  {sortedBy == "name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('name') }}></i>}</th> */}
                                                    <th className="font-weight-bold">Emp Code/Salary {sortedBy == "employee_code" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('employee_code') }}></i>}</th>
                                                    {/* <th className="font-weight-bold">Deduction Amount Limit  {sortedBy == "deduction_amount_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_amount_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('deduction_amount_limit') }}></i>}</th> */}
                                                    {/* <th className="font-weight-bold">Age  {sortedBy == "age" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('age') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('age') }}></i>}</th> */}
                                                    {/* <th className="font-weight-bold">  {sortedBy == "salary" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('salary') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('salary') }}></i>}</th> */}
                                                    <th className="font-weight-bold">Role & Department {sortedBy == "title" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('title') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('title') }}></i>}</th>
                                                    {/* <th className="font-weight-bold">Address  {sortedBy == "postal_address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('postal_address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('postal_address') }}></i>}</th> */}
                                                    {/* <th className="font-weight-bold">Site Name  {sortedBy == "nfc_site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('nfc_site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('nfc_site_name') }}></i>}</th> */}
                                                    {/* <th className="font-weight-bold">Department  {sortedBy == "department_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('department_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('department_name') }}></i>}</th> */}
                                                    <th className="font-weight-bold">BNPL Status</th>
                                                    <th className="font-weight-bold">EWA Status <span type="button" data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Note: You must have updated employee Salary detail from 'Edit Employee' button so employee can access EWA, please avoid if already done!">
                                                        <i className='mx-1 fa-solid fa-circle-info'></i></span></th>
                                                    <th className="font-weight-bold">EWA Agreement</th>
                                                    {(employee_edit || user_type == "employer") && (<th className="font-weight-bold">Action</th>)}


                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /><td /><td /><td /><td />
                                                <div >
                                                    <Loader

                                                    /> </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray.length > 0 &&
                                                            dataArray.map((e, i) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                                const { user_active_status, name, department_name, customer_id, salary, pay_cycle, deduction_code, deduction_amount_limit, employee_code, mobile_number, email, postal_address, age, first_name, last_name, per_hour_wage, title, ewa_agreement_doc, ewa_status, personal_email } = e;

                                                                return (
                                                                    <tr key={serial_num}>
                                                                        {user_type == "employer" && <td><input type="checkbox" name={e.customer_id} checked={checheked(e.customer_id) === 1 ? true : false} onChange={(event) => selectTransactions(event, e.customer_id)} /></td>}
                                                                        <td>{user_active_status == '1' ?
                                                                            <div>
                                                                                <Switch tabIndex={serial_num} checkedChildren="Enable" onClick={(e) => Updatestatus(customer_id)} unCheckedChildren="Disable" defaultChecked={true} /* onChange={(e) => setToggle(e)} */ /></div> :
                                                                            <div /* className="col-auto ms-auto d-print-none" */>
                                                                                <Switch tabIndex={serial_num} checkedChildren="Enable" onClick={(e) => Updatestatus(customer_id)} unCheckedChildren="Disable" /* onChange={(e) => setToggle(e)}  */ /></div>}</td>
                                                                        <td> <Link className="text-primary" to={{ pathname: `${prefix}/viewemployee_detail/${customer_id}`, state: { customer_id } }} ><span className="font-weight-normal1">{first_name ? first_name : "--"}&nbsp;{e?.last_name ? last_name : ""}<br />{e?.mobile_number ? mobile_number : ""}<br />{e?.name ? name : ""}<br /><span style={{ textTransform: 'none' }}>{e?.personal_email ? personal_email : ""}</span></span></Link></td>
                                                                        <td>{e?.pay_cycle ? pay_cycle : "--"}</td>
                                                                        {/* <td>{e?.name ? name : "--"}</td> */}
                                                                        <td>{e?.employee_code ? employee_code : "--"}<br />{e?.salary ? "$" + salary : ""}</td>
                                                                        {/* <td>{e?.deduction_amount_limit ? "$" + deduction_amount_limit : ""}</td> */}
                                                                        {/* <td>{e?.age ? age : "--"}</td> */}
                                                                        {/* <td></td> */}
                                                                        <td>{e?.title ? title : "--"}<br />{department_name ? department_name : "--"}</td>
                                                                        {/* <td>{postal_address ? postal_address : "--"}</td> */}
                                                                        {/* <td><span className="font-weight-normal1 department_name">{site_name ? site_name : "--"}</span></td> */}
                                                                        {/* <td><span className="font-weight-normal1 ">{department_name ? department_name : "--"}</span></td> */}
                                                                        <td>{e.bnpl_status == 'submitted' ? <span className="badge rounded-pill bg-success">Active</span>
                                                                            : <span className="badge rounded-pill bg-danger" style={{}}>Inactive</span>}
                                                                        </td>
                                                                        <td>
                                                                            {ewa_status == 1
                                                                                ? <span className="badge rounded-pill bg-success">Active</span>
                                                                                : <span className="badge rounded-pill bg-danger" style={{}}>Inactive</span>}
                                                                        </td>
                                                                        {ewa_agreement_doc
                                                                            ? <td className="text-center">
                                                                                <a href={ewa_agreement_doc} download>
                                                                                    <img
                                                                                        src="/assets/img/pdf.jpeg"
                                                                                        style={{ width: '20px', height: '25px', border: '1px solid silver' }}
                                                                                        className="card-img-top m-1"
                                                                                        alt="PDF"
                                                                                    />
                                                                                </a>
                                                                            </td>
                                                                            : <td>
                                                                                {ewa_status == 2
                                                                                    ? <div className="btn btn-sm btn-outline-primary" onClick={() => { navigate("/employer/signature/" + customer_id, { state: { customer_id } }) }}> Agreement &nbsp;&nbsp;&nbsp;</div>
                                                                                    : <div>-- --</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        <td>
                                                                            <div className="dropdown-action">
                                                                                <button className="btn view-pro dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                                    Action
                                                                                    <span className="caret"></span>
                                                                                </button>
                                                                                <ul className="dropdown-menu actionfix" aria-labelledby="dropdownMenu1">
                                                                                    <li> <Link className="font-weight-normal1" to={`${prefix}/edit_employee/${customer_id}`} state={{ data: e }}>Edit </Link></li>
                                                                                    <li> <Link className="font-weight-normal1" data-bs-toggle="modal" to="#exampleModalToggle" role="button" onClick={() => setSendEmployeeID(customer_id)}>Process Payslip</Link></li>
                                                                                    <li> <Link className="font-weight-normal1" to={`${prefix}/payslip_by_id/${customer_id}`}>View All Payslip</Link></li>
                                                                                    <li> <Link className="font-weight-normal1" to={{ pathname: `${prefix}/viewemployee_detail/${customer_id}`, state: { customer_id } }}
                                                                                        state={{ data: 'Documents' }}>View Documents</Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                        {/* <button className="btn btn-primary"
                                                                            data-bs-dismiss="modal" onClick={() => {
                                                                                setCurrentCustomerID(customer_id);
                                                                                getEmployeeDetailByID(customer_id)
                                                                            }}
                                                                            data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap"
                                                                        >Edit Profile</button> */}
                                                                    </tr>
                                                                )
                                                            })
                                                        } </tbody>
                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody> </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && dataArray.length > 0 ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div >
            }


            <DeductionAmountByID sendEmployeeID={sendEmployeeID} type={"employees"} />
        </>
    )
}

export default Employee