
const LenderRegistration = async (data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        let requestOptions = {
            method: 'POST',
            body: data,
            headers: {
                'Timezone': Timezone,
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/lender-registration", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
    }
}

const LenderLoign = async (data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Timezone", Timezone);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/login", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
    }

}

const Employerforgot = async (data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'POST',
            headers: {
                // "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: data,
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/forgot-password", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error: ", error)
    }

}

const EmployerVerifyOTP = async (data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'POST',
            headers: {
                // "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: data,
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/forgot-password-verify-otp", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error: ", error)
    }

}

const EmployerResetPassword = async (token, data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: data,
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/reset-password", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error: ", error)
    }

}

// const GetEmployees = async (token = "", search = "", pagesCount = 1, sortedBy, orderBy) => {
//     try {
//          let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/all-submit-form-employee?filter=Assigned&search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const Getnewborrowers = async (token = "", /*  statusdrop = "Unassigned" , */  search = "", pagesCount = 1, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/all-submit-form-employee?filter=Unassigned&search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const AddEmployees = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/add-employee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const GetEmployerbyID = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-employer-byid/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:124 ~ LenderGetEmployerbyID ~ error:", error)
//     }

// }
// const ViewInvestmentdetail = async (token = "", id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/lender-investment-amount/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:141 ~ ViewInvestmentdetail ~ error:", error)
//     }

// }


const Getsettlementcycle = async (token = "", id) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/employer-payment-detail/${id}`, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:141 ~ ViewInvestmentdetail ~ error:", error)
    }

}

// const ViewInvestmentdetails = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/return-of-invesments?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:146 ~ ViewemibyOrderID ~ error:", error)
//     }

// }

const GetsettlementDetail = async (token) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },

            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/employer-payments`, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:146 ~ ViewemibyOrderID ~ error:", error)
    }

}

// const ViewemibyOrderID = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/transaction-emi-detail/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:146 ~ ViewemibyOrderID ~ error:", error)
//     }

// }

// const GetEmployeeEMI = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-emi-byId/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:124 ~ LenderGetEmployerbyID ~ error:", error)
//     }

// }

// const GetEmployee = async (token = "", id) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             data :data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-employee-num`, requestOptions);
//         console.log(res,">>>>>>>>>>.");
//         const result = await res.json();
//         // get-employeebyid
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const GetEmployee = async (token = "", number) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             body: JSON.stringify({
//                 "mobile_number": number
//             }),
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             // redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-employee-num`, requestOptions);
//         const result = await res.json();
//         // get-employeebyid
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const synchInvestment = async ( body) => {
//     const lender = JSON.parse(localStorage.getItem("lender"));
//     try {

//         let requestOptions = {
//             method: 'POST',
//             body: [body],
//             headers: {

//                 "Authorization": `Bearer ${lender.lender_token}`
//             },
//              redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/sync-interest`, requestOptions);
//         const result = await res.json();
//         // get-employeebyid
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const DeleteEmployees = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/deleteEmployee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const GetProfile = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/get-profile", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const UpdateProfile = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/edit_profile", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

const GetCountry = async (token = "") => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'GET',
            headers: {
                'Timezone': Timezone
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + "/get-country", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
    }

}

const ChangeUserPassword = async (data, token = "") => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: data,
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/change-password", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderService.js:31 ~ EmployerRegistration ~ error:", error)
    }
}


// const GetEmployers = async (token = "", pageNumber = 1, searchVlu = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-employer?page_number=${pageNumber}&search=${searchVlu}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const GetEmployerDetail = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-employer-byId/` + id, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const ManageEmployeeLimit = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/manage-employee-limit`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }


// const GetLimit = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/limit-list/" + id, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const GetTransctions = async (token = "", lenderId, page_number = 1, search, sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/lender-transctions/${lenderId}?page_number=${page_number}&search=${search}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const GetEmi = async (token = "", search, pageNumber = 1, sortby, orderby, statusdrop) => {
//     const lender = JSON.parse(localStorage.getItem("lender"));
//     // console.log(lender.user_id)
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/emi-list?search=${search}&page_number=${pageNumber}&orderby=${orderby}&sortby=${sortby}&filter=${statusdrop}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const GetDues = async (token = "",pageNumber, pageSize, filterVlu, id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/due-list/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const GetDues = async (token, userId, pagesCount, search, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/due-list/${userId}?page_number=${pagesCount}&search=${search}`, requestOptions)
//         const result = await res.json();

//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }


// const GetEmiPaidList = async (token = "", id, pageNumber = 1, filterVlu, sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/Paid-emi-list/${id}?page_number=${pageNumber}&search=${filterVlu}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const GetDashboard = async (token) => {
//     // const lender = JSON.parse(localStorage.getItem("lender"));
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/lender-dashboard`, requestOptions);
//         const result = await res.json();
//         // console.log(result)
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//         console.log(error)
//     }

// }

// const payEmis = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // 'content-type': 'form-data'
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/pay-emi", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const UploadLogo = async (token, data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/upload-logo", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ EmployerRegistration ~ error:", error)
//     }
// }

// const Settledinterestdetail = async (token = "", filterVlu, pageNumber = 1, sortby, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/settled-interest?&search=${filterVlu}&page_number=${pageNumber}&sortby=${sortby}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const viewpendingsettlement = async (token = "", search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/merchant-dues?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const GetAllTransactionsByMerchanttId = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/pending-settalemnt-by-merchant/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }


// const AddSettlement = async (data) => {
//     try {
//         const lender = JSON.parse(localStorage.getItem("lender"));
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${lender.lender_token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/add-settlement", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const Thirdrocsettlementview = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/thirdroc-dues?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }


// const AddSettlementthirdroc = async (data) => {
//     try {
//         const lender = JSON.parse(localStorage.getItem("lender"));
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${lender.lender_token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/thirdroc-transfer-payment", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const getThirdroctransaction = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/thirdroc-transfer-history?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const Getmerchantpaidtxn = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/merchant-paid/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const Getpaidtransaction = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/thirdroc-transfer-history-detail/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const getMerchanttransaction = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/merchant-paid-transactions?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const getAllEmitransaction = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/all-paid-emi-list?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const MerchantUpdate = async (token, data) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/update-ewa-merchant-paid", requestOptions);

//         const result = await res.json();


//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:31 ~ PaytoMerchant ~ error:", error)

//     }

// }

// const paytothirdrocUpdate = async (token, data) => {
//     try {
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");
//         var requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/update-thirdroc-paid", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:31 ~ PaytoMerchant ~ error:", error)
//     }
// }

// const addUserrole = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/add-lender-role-permission", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:1015 ~ addUserrole ~ error:", error)
//     }
// }


// const GetUsermanager = async (token = "", pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-role-permission?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         // console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:1035 ~ GetUsermanager ~ error: ", error)
//     }

// }

// const deleteRole = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/delete-role-permission/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:1056 ~ deleteRole ~ error:", error)
//     }
// }

// const EditUserrole = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/update-role-permission", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:128 ~ addNFCdetails ~ error:", error)
//     }

// }


// const GetUsersDetails = async (token, pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-sub-users?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions)
//         const result = await res.json();
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:1100 ~ GetUsersDetails ~ error:", error)
//     }
// }

// const addUsers = async (token, data) => {
//     try {
//         let requestOptions = {
//             method: "POST",
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + '/add-sub-users', requestOptions)
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:1119 ~ addUsers ~ error:", error)
//     }
// }

// const GetUsermanagers = async (token = "") => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/get-role-permission", requestOptions);
//         const result = await res.json();
//         // console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:254 ~ GetEmployeesRepayment ~ error: ", error)
//     }

// }

// const deleteUser = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/delete-sub-users/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:1159 ~ deleteRole ~ error:", error)
//     }
// }

// const UpdateManageUser = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/update-sub-users", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

const GetPermission = async (token = "") => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Timezone': Timezone,
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/my-permissions/", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: lenderServices.js:1200 ~ GetPermission ~ error:", error)
    }
}

const postVerification = async (token = "", data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/submit-loan-application", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
    }

}
const Addborrower = async (token = "", data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/customer-detail-submit", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
    }

}

const LoanRiskAnalysis = async (token = "", data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/loan-risk-analysis", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
    }

}

const GetEmployeeDetail = async (token = "", data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/get-user-detail", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:969 ~ GetEmployers ~ error:", error)
    }

}


const AddEmployeeDocument = async (token, data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        var requestOptions = {
            method: 'POST',
            headers: {

                //  "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                'Timezone': Timezone,
            },
            // headers: myHeaders,
            body: data,
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/document-upload", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:31 ~ AddEmployee ~ error:", error)
    }

}

const getEmployeeDocument = async (token, data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {
        var requestOptions = {
            method: 'POST',
            headers: {

                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                'Timezone': Timezone,
            },
            // headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/get-doc", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: adminService.js:31 ~ AddEmployee ~ error:", error)
    }

}
// const GetLoanCasesDetail = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/get-loan-application", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderServices.js:1200 ~ GetPermission ~ error:", error)
//     }
// }

// const GetLoanStatusDetail = async (token = "", search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-loan-status-list?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         // console.log(res)
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:500 ~ GetCountry ~ error:", error)
//     }
// }

// const addLoanStatusdetails = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/add-loan-status", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:128 ~ addNFCdetails ~ error:", error)
//     }

// }
// const EditStatusName = async (token = "", data, id) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/edit-loan-status/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:128 ~ addNFCdetails ~ error:", error)
//     }

// }

// const ViewLoanCasesData = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/user-loan-application/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
//     }

// }

// const GetLoanStatus = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-loan-status-list`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
//     }

// }

// const EditLoanStatus = async (token = "", data, id) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/update-loan-status/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: EmployerService.js:1166 ~ EditNFCDetail ~ error:", error)
//     }
// }

// const deleteStatus = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/delete-loan-status/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:1056 ~ deleteRole ~ error:", error)
//     }
// }

// const ViewActivityLogs = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-loan-application-activity/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:146 ~ ViewemibyOrderID ~ error:", error)
//     }

// }

// const GetBorrowerChatdetail = async (token, id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-loan-chat/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:1514 ~ GetBorrowerChatdetail ~ error:", error)
//     }
// }

// const updatechat = async (token, data) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/loan-chat-with-user", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:", error)
//     }

// }

// const AddNotice = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/add-loan-note", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const ViewApplicantEMI = async (token, id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-loan-emi-data/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js:146 ~ ViewemibyOrderID ~ error:", error)
//     }

// }


// const updateloanstatus = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/update-emi", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: lenderService.js: ~ updateloanstatus ~ error:", error)
//     }

// }


// const EditLoanStatus1 = async (token, data, id) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/update-loan-status/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:", error)
//     }

// }

// const addloannote = async (token, data) => {
//     try {
//         // var myHeaders = new Headers();
//         // myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + "/add-loan-note", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1927 ~ EditAgreeMessage ~ error:", error)
//     }

// }

// const getnotesmessage = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/get-loan-notes/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:1907 ~ getErrorMessage ~ error:", error)
//     }

// }

const Deletedocument = async (token, id) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone,
            },
            // body: data,
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + `/delete_loan_doc/${id}`, requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:31 ~ AdminService ~ error:", error)
    }
}

const getApi = (path, parameter = '', object = {}) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        const lender = JSON.parse(localStorage.getItem("lender"));
        let headers = { 'Authorization': `Bearer ${lender.lender_token}` }
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone, }
        if (object) {
            headers = { ...headers, ...object }
        }

        const queryParams = parameter ? '?' + new URLSearchParams(parameter) : '';

        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + '' + path + queryParams, {
            method: 'GET',
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        reject(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((error) => {
                console.log("🚀 ~ .then ~ error:", error)
            });
    });
}

const postApi = (path, body = {}, is_formData = false, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("lender"))?.lender_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        if (is_formData == false) {
            headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        } else {
            headers = { ...headers, /* 'Content-Type': 'multipart/form-data' */ 'Timezone': Timezone }
        }
        if (object) {
            headers = { ...headers, ...object, 'Timezone': Timezone }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + '' + path, {
            method: 'POST',
            headers: headers,
            body: is_formData ? body : JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

const deleteApi = (path, body = {}, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("lender"))?.lender_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }

        if (object) {
            headers = { ...headers, ...object }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX + '' + path, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}


export { getApi, postApi, deleteApi, EmployerVerifyOTP, EmployerResetPassword, Employerforgot, GetsettlementDetail, Getsettlementcycle, LenderRegistration, LenderLoign, GetCountry, ChangeUserPassword, GetPermission, postVerification, Addborrower, GetEmployeeDetail, AddEmployeeDocument, getEmployeeDocument, Deletedocument, LoanRiskAnalysis };

