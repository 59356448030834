import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast'
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import socketModule from '../../../socketModule'
import socketModule from '../../../../../socketModule'
import { isDraft } from '@reduxjs/toolkit'
import { getApi, postApi } from '../../../../service/employService';
import { Breadcrumbsecond } from '../../../../../components';
import JobTypeAssignModal from './JobTypeAssignModal'


// *************************************************************************** //
//********** THIS IS NEW SCHEDULE FOR MANUFACTURING BUSINESS TYPE  ***********//
// ************************************************************************* //

const Schedule = () => {
    const navigate = useNavigate()
    const employer = JSON.parse(localStorage.getItem("employer"));
    const [weeklyDatesArr, setweeklyDatesArr] = useState([])
    const [scheduleArr, setScheduleArr] = useState([])
    const [jobTypeAndChild, setJobTypeAndChild] = useState([])
    const [leaveRequest, setLeaveRequest] = useState([])
    const [shiftArray, setShiftArray] = useState([])
    const [dataArray, setDataArray] = useState([]);
    const [leaveNotificationCount, setLeaveNotificationCount] = useState(0)
    const [selectedJobType, setSelectedJobType] = useState({});
    const [selectedShiftDetail, setSelectedShiftDetail] = useState({});
    const [liveLeaveRequest, setLiveLeaveRequest] = useState([])
    const [startDate, setstartDate] = useState(moment().startOf('week').format('YYYY-MM-DD'));
    const [endDate, setendDate] = useState(moment().endOf('week').format('YYYY-MM-DD'));
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [sortedBy, setSortedBy] = useState("customer_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [schedulerModal, setSchedulerModal] = useState(false)
    const [assignJobTypeModal, setAssignJobTypeModal] = useState(false)
    const [copyShiftModal, setCopyShiftModal] = useState(false)
    const [jobType, setJobType] = useState('')
    const [jobtypeId, setJobTypeId] = useState()
    const [jobtypeName, setJobTypeName] = useState()
    const [jobtypeLimit, setJobTypeLimit] = useState()
    const [payloadType, setPayloadType] = useState('')
    const [payloadlimit, setPayLoadLimit] = useState('')

    const [copyScheduleArr, setCopyScheduleArr] = useState([])
    const [copyDate, setCopyDate] = useState(null)

    useEffect(() => {
        socketModule.on("new-leave-request", (data) => {

            if (data?.employer_id == employer.employer_id) {
                // console.log("🚀 ~ socketModule.on ~ data?.employer_id == employer.employer_id:", data?.employer_id == employer.employer_id)
                generateCurrentWeekDate()
                getLeaveRequest()
                LeaveNotification()
                toast(`${data.first_name} ${data.last_name} request for leave`, {
                    duration: 4000,
                    position: 'top-center',
                    icon: <span className='fas fa-user me-2 text-info'></span>,
                    style: { marginTop: '4.5rem' }
                })

            }
        });

        socketModule.on("leave-request-updated", (data) => {

            if (data?.employer_id == employer.employer_id) {
                generateCurrentWeekDate()
                getLeaveRequest()
            }
        })
    }, [socketModule]);

    const generateCurrentWeekDate = () => {
        const tempStartDate = moment(startDate)

        const datesArray = []

        while (tempStartDate.isSameOrBefore(endDate)) {
            datesArray.push(tempStartDate.format('Do MMMM dddd'));
            tempStartDate.add(1, 'day');
        }

        setweeklyDatesArr(datesArray)
        getAllShifts(datesArray)
    }

    const getJobTypeAndClild = async (propDatesArray, popShiftArray) => {

        try {
            let publishedShift = await getPublishedShifts()

            // console.log("🚀 ~ getJobTypeAndClild ~ publishedShift:", publishedShift)

            const response = await getApi('/get-job-type')

            if (response.status) {

                if (response?.data?.rows) {
                    setJobTypeAndChild(response?.data?.rows)

                    const finalJobTypeData = response?.data?.rows
                    // console.log("🚀 ~ finalJobTypeData:", finalJobTypeData)
                    const tempData = []

                    // *****************************************************************
                    // *************** LOOPIN ALL JOB TYPES ****************************
                    // *****************************************************************

                    for (let i = 0; i < finalJobTypeData.length; i++) {

                        const tempDatesArray = []

                        for (let j = 0; j < propDatesArray.length; j++) {

                            const tempShiftArray = []

                            for (let k = 0; k < popShiftArray.length; k++) {
                                let tempObj = {
                                    shift_date: propDatesArray[j],
                                    shift_id: popShiftArray[k].id,
                                    shift_name: popShiftArray[k].shift_name,
                                    shift_start_time: popShiftArray[k].shift_start_time,
                                    shift_end_time: popShiftArray[k].shift_end_time,
                                    isDraft: false,
                                    jobDetail: {
                                        type: 'parent',
                                        id: finalJobTypeData[i].id,
                                        limit: finalJobTypeData[i].limit,
                                        used_limit: 0,
                                        job_name: finalJobTypeData[i].job_type,
                                        teamMemberArray: []
                                    }
                                }

                                if (publishedShift?.length > 0) {

                                    for (let l = 0; l < publishedShift.length; l++) {

                                        let indexData = publishedShift[l]

                                        const parsedDate = moment(indexData.shift_date, 'YYYY-MM-DD');
                                        const modifiedShiftDate = parsedDate.format('Do MMMM dddd');

                                        // if (indexData.shift_id == popShiftArray[k].id && (indexData.job_id == finalJobTypeData[i].id || indexData.sub_job_id == finalJobTypeData[i].id || indexData.sub_l2_job_id == finalJobTypeData[i].id) && indexData.job_type == 'parent' && modifiedShiftDate == propDatesArray[j]) {
                                        if (indexData.shift_id == popShiftArray[k].id && indexData.shift_job_id == finalJobTypeData[i].id && indexData.job_type == 'parent' && modifiedShiftDate == propDatesArray[j]) {

                                            tempObj.jobDetail.teamMemberArray.push({
                                                ...indexData,
                                                isPublished: true
                                            })

                                        }

                                    }
                                }


                                tempShiftArray.push(tempObj)
                            }

                            tempDatesArray.push({
                                date: propDatesArray[j],
                                shiftArray: tempShiftArray
                            })
                        }

                        tempData.push({
                            id: finalJobTypeData[i].id,
                            type: 'parent',
                            datesArray: tempDatesArray
                        })

                        // *****************************************************************
                        // *************** LOOPIN SUB JOB TYPES ****************************
                        // *****************************************************************

                        if (finalJobTypeData[i].subJobType.length > 0) {

                            let finalJobTypeData2 = finalJobTypeData[i].subJobType

                            for (let i = 0; i < finalJobTypeData2.length; i++) {

                                const tempDatesArray = []

                                for (let j = 0; j < propDatesArray.length; j++) {

                                    const tempShiftArray = []

                                    for (let k = 0; k < popShiftArray.length; k++) {

                                        const tempObj = {
                                            shift_date: propDatesArray[j],
                                            shift_id: popShiftArray[k].id,
                                            shift_name: popShiftArray[k].shift_name,
                                            shift_start_time: popShiftArray[k].shift_start_time,
                                            shift_end_time: popShiftArray[k].shift_end_time,
                                            isDraft: false,
                                            jobDetail: {
                                                type: 'child',
                                                id: finalJobTypeData2[i].id,
                                                limit: finalJobTypeData2[i].sub_limit,
                                                used_limit: 0,
                                                job_name: finalJobTypeData2[i].sub_job_type,
                                                teamMemberArray: []
                                            }
                                        }

                                        if (publishedShift?.length > 0) {

                                            for (let l = 0; l < publishedShift.length; l++) {

                                                let indexData = publishedShift[l]

                                                const parsedDate = moment(indexData.shift_date, 'YYYY-MM-DD');
                                                const modifiedShiftDate = parsedDate.format('Do MMMM dddd');

                                                if (indexData.shift_id == popShiftArray[k].id && indexData.shift_job_id == finalJobTypeData2[i].id && indexData.job_type == 'child' && modifiedShiftDate == propDatesArray[j]) {

                                                    tempObj.jobDetail.teamMemberArray.push({
                                                        ...indexData,
                                                        isPublished: true
                                                    })

                                                }

                                            }
                                        }

                                        tempShiftArray.push(tempObj)
                                    }

                                    tempDatesArray.push({
                                        date: propDatesArray[j],
                                        shiftArray: tempShiftArray
                                    })
                                }

                                tempData.push({
                                    id: finalJobTypeData2[i].id,
                                    type: 'child',
                                    datesArray: tempDatesArray
                                })

                                // *************************************************************************
                                // *************** LOOPIN LEVEL 2 SUB JOB TYPES ****************************
                                // *************************************************************************

                                if (finalJobTypeData2[i].subL2JobType.length > 0) {

                                    let finalJobTypeData3 = finalJobTypeData2[i].subL2JobType

                                    for (let i = 0; i < finalJobTypeData3.length; i++) {

                                        const tempDatesArray = []

                                        for (let j = 0; j < propDatesArray.length; j++) {

                                            const tempShiftArray = []

                                            for (let k = 0; k < popShiftArray.length; k++) {
                                                const tempObj = {
                                                    shift_date: propDatesArray[j],
                                                    shift_id: popShiftArray[k].id,
                                                    shift_name: popShiftArray[k].shift_name,
                                                    shift_start_time: popShiftArray[k].shift_start_time,
                                                    shift_end_time: popShiftArray[k].shift_end_time,
                                                    isDraft: false,
                                                    jobDetail: {
                                                        type: 'subChild',
                                                        id: finalJobTypeData3[i].id,
                                                        limit: finalJobTypeData3[i].sub_l2_limit,
                                                        used_limit: 0,
                                                        job_name: finalJobTypeData3[i].sub_l2_job_type,
                                                        teamMemberArray: []
                                                    }
                                                }

                                                if (publishedShift?.length > 0) {

                                                    for (let l = 0; l < publishedShift.length; l++) {

                                                        let indexData = publishedShift[l]

                                                        const parsedDate = moment(indexData.shift_date, 'YYYY-MM-DD');
                                                        const modifiedShiftDate = parsedDate.format('Do MMMM dddd');

                                                        // if (indexData.shift_id == popShiftArray[k].id && (indexData.job_id == finalJobTypeData3[i].id || indexData.sub_job_id == finalJobTypeData3[i].id || indexData.sub_l2_job_id == finalJobTypeData3[i].id) && indexData.job_type == 'subChild' && modifiedShiftDate == propDatesArray[j]) {
                                                        if (indexData.shift_id == popShiftArray[k].id && indexData.shift_job_id == finalJobTypeData3[i].id && indexData.job_type == 'subChild' && modifiedShiftDate == propDatesArray[j]) {

                                                            tempObj.jobDetail.teamMemberArray.push({
                                                                ...indexData,
                                                                isPublished: true
                                                            })

                                                        }

                                                    }
                                                }

                                                tempShiftArray.push(tempObj)
                                            }

                                            tempDatesArray.push({
                                                date: propDatesArray[j],
                                                shiftArray: tempShiftArray
                                            })
                                        }

                                        tempData.push({

                                            id: finalJobTypeData3[i].id,
                                            type: 'subChild',
                                            datesArray: tempDatesArray
                                        })
                                    }
                                }
                            }
                        }
                    }

                    setScheduleArr(tempData)
                    // console.log("🚀 ~ getJobTypeAndClild ~ tempData:", tempData)

                }

            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAllShifts = async (datesArray) => {
        try {
            const response = await getApi('/get-all-shift')
            if (response.status) {
                if (response?.data?.rows) {
                    setShiftArray(response?.data?.rows)

                    getJobTypeAndClild(datesArray, response?.data?.rows)
                }

            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const LeaveNotification = async () => {
        try {
            const response = await getApi('/leave-notification', { sortby: "created_at", orderby: 'desc' })
            if (response.status) {
                // setNotificationData(response.data)
                setLeaveNotificationCount(response.unReadCount)
                setLiveLeaveRequest(response.data)
            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getPublishedShifts = async () => {
        try {
            const response = await getApi('/get-employee-shifts-v2', { start_date: startDate, end_date: endDate })
            if (response.status) {
                if (response?.teamMemberArray) {
                    return response?.teamMemberArray
                }
                return null

            } else {
                console.log(response)
                return null
            }
        } catch (error) {
            console.log(error)
            return null
        }
    }

    useEffect(() => {
        // if (startDate !== '' || endDate !== '') {
        // }
        generateCurrentWeekDate()
    }, [startDate, endDate])

    useEffect(() => {
        LeaveNotification()
    }, [])

    const getEmployeesData = async (token) => {
        const response = await getApi("/employee-list-v2", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
        try {
            if (response.status == true) {
                setDataArray(response.data);
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }

    useEffect(() => {
        getEmployeesData()
    }, [pagesCount, search, sortedBy, orderBy])


    const handleDragStart = (event, dataObj) => {
        // setDragStart(true)
        event.dataTransfer.setData('text/plain', JSON.stringify(dataObj));
    };

    const handleDrop = (event, data, edit = false) => {
        event.preventDefault();

        const droppedData = JSON.parse(event.dataTransfer.getData('text/plain'));

        const updateData = {
            ...selectedShiftDetail,
            jobDetail: {
                ...selectedShiftDetail.jobDetail,
                teamMemberArray: [
                    ...selectedShiftDetail.jobDetail.teamMemberArray,
                    { ...droppedData, isPublished: false, on_leave: 0 }
                ]
            }
        }

        setSelectedShiftDetail(updateData)
    };

    const checkIfDraftShiftAvailabile = (checkingDisable) => {
        const updatedFullRow = JSON.parse(JSON.stringify(scheduleArr)).filter((mainItem) => {
            mainItem.datesArray = mainItem.datesArray.filter((childItem) => {
                childItem.shiftArray = childItem.shiftArray.filter((subChildItem) => {
                    subChildItem.jobDetail.teamMemberArray = subChildItem.jobDetail.teamMemberArray.filter((member) => {
                        return member.isPublished == false
                    })
                    return subChildItem.jobDetail.teamMemberArray.length > 0;
                });
                return childItem.shiftArray.length > 0;
            });
            return mainItem.datesArray.length > 0;
        })

        if (checkingDisable) {
            return updatedFullRow.length == 0 ? true : false;
        }
        else {
            return updatedFullRow
        }
    }

    const handlePublishShift = async () => {
        try {

            const updatedFullRow = checkIfDraftShiftAvailabile(false)


            const response = await postApi('/create-shifts-v2', {
                finallArray: updatedFullRow
            })
            if (response.status) {
                toast.success(response.message)
                generateCurrentWeekDate()
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleCallback = (event, picker) => {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');

        picker.element.val(startDate + ' - ' + endDate);
        setstartDate(startDate);
        setendDate(endDate);
    };

    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate(moment().startOf('week').format('YYYY-MM-DD'))
        setendDate(moment().endOf('week').format('YYYY-MM-DD'))
    }

    const handleCopyShift = (dataItem) => {

        let targetDate1 = dataItem
        let dateArray = weeklyDatesArr

        let updatedArr = JSON.parse(JSON.stringify(copyScheduleArr))
        let newScheduleArr = JSON.parse(JSON.stringify(scheduleArr))

        for (let i = 0; i < dateArray.length; i++) {

            const newArr = newScheduleArr.map(item => {
                console.log("🚀 ~ newArr ~ item:", item)

                // const targetDate2Item = item.datesArray.find(dateItem => dateItem.date === targetDate1);
                let targetDate2Item = null

                for (const arrItem of updatedArr) {

                    // console.log("🚀 ~ updatedArr ~ arrItem:", arrItem)

                    const match = arrItem.datesArray.find(dateItem => dateItem.date === targetDate1 && arrItem.id == item.id && arrItem.type === item.type);
                    if (match) {
                        targetDate2Item = match
                        break;
                    }
                }

                if (!targetDate2Item) return item;

                let targetDate2 = dateArray[i]

                const newDatesArray = item.datesArray.map(dateItem => {
                    if (dateItem.date === targetDate2) {
                        return {
                            ...dateItem,
                            shiftArray: dateItem.shiftArray.map(shiftItem => {
                                const sourceShift = targetDate2Item.shiftArray.find(shift => shift.shift_id === shiftItem.shift_id && shift.jobDetail.id === shiftItem.jobDetail.id);

                                if (sourceShift) {
                                    return {
                                        ...shiftItem,
                                        isDraft: (sourceShift.jobDetail.teamMemberArray.filter((member) => {
                                            return !shiftItem.jobDetail.teamMemberArray.some((existing) => existing.customer_id === member.customer_id);
                                        }).length > 0
                                            || shiftItem.jobDetail.teamMemberArray.length > 0 && shiftItem.jobDetail.teamMemberArray.filter((existing) => existing.isPublished == false).length > 0
                                        ) ? true : false,
                                        jobDetail: {
                                            ...shiftItem.jobDetail,
                                            teamMemberArray: [
                                                ...shiftItem.jobDetail.teamMemberArray,
                                                ...sourceShift.jobDetail.teamMemberArray.filter((member) => {
                                                    return !shiftItem.jobDetail.teamMemberArray.some((existing) => existing.customer_id === member.customer_id);
                                                })
                                                    .map((member) => ({ ...member, isPublished: false })),
                                            ]
                                        }
                                    };
                                }
                                return shiftItem;
                            })
                        };
                    }
                    return dateItem;
                });

                return { ...item, datesArray: newDatesArray };
            });

            newScheduleArr = newArr
        }

        setScheduleArr(newScheduleArr);
        setCopyScheduleArr([])

    }

    const getLeaveRequest = async (token) => {
        try {
            const response = await getApi("/employee-leave-requests", { /* search: search, page_number: pagesCount,  */sortby: 'created_at', orderby: 'desc' });

            if (response.status == true) {

                setLeaveRequest(response.data.rows);

            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        getLeaveRequest();
    }, []);

    const HandleLeaveCount = async () => {
        // console.log("first", leaveNotificationCount)
        if (leaveNotificationCount > 0) {
            const response = await postApi('/mark-notification-read')
            if (response.status) {
                LeaveNotification()
            } else {
                toast.error(response.message)
            }
        }

    }

    const HandleRequestById = (id) => {
        const getDetailByID = leaveRequest.filter((data => data.id == id))
        setLeaveRequest(getDetailByID)
        // console.log(getDetailByID, "***********************", id, leaveRequest)
    }

    const ViewAllLeaveRequest = () => {
        const getAllDetail = leaveRequest.filter((data => data.status != "rejected"))
        setLeaveRequest(getAllDetail)
    }

    // console.log(leaveRequest, "----------------------------")

    const handleDisableUpdate = useRef()


    const UpdateLeaveRequestDetail = async () => {
        try {
            const response = await postApi('/update-leave-requests', {
                employeeLeaveRequestArray: leaveRequest
            })
            if (response.status) {
                handleDisableUpdate.current.click()
                toast.success(response.message);
                getLeaveRequest();
                LeaveNotification()
            } else {
                handleDisableUpdate.current.click()
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const formatTimeDiff = (created_at) => {
        let timeDiff = Date.now() - Date.parse(created_at);
        let minutes = Math.floor(timeDiff / 60000);
        let hours = Math.floor(timeDiff / 3600000);
        let days = Math.floor(timeDiff / 86400000);

        if (days > 1) {
            return `${days} days ago`;
        } else if (days === 1) {
            let remainingHours = hours % 24;
            if (remainingHours > 0) {
                return `1 day and ${remainingHours} hrs${remainingHours === 1 ? '' : 's'} ago`;
            } else {
                return `1 day ago`;
            }
        } else if (hours > 1) {
            let remainingMinutes = minutes % 60;
            if (remainingMinutes > 0) {
                return `${hours} hrs and ${remainingMinutes} min${remainingMinutes === 1 ? '' : 's'} ago`;
            } else {
                return `${hours} hrs ago`;
            }
        } else if (hours === 1) {
            let remainingMinutes = minutes % 60;
            if (remainingMinutes > 0) {
                return `1 hrs and ${remainingMinutes} min${remainingMinutes === 1 ? '' : 's'} ago`;
            } else {
                return `1 hrs ago`;
            }
        } else {
            return `${minutes} min${minutes === 1 ? '' : 's'} ago`;
        }
    }

    const handleupdatejobtype = useRef()

    const UpdateJobdetails = async () => {
        const data = {};
        data[payloadType] = jobtypeName;
        data[payloadlimit] = jobtypeLimit;
        const endpoint = (jobType == 'Job Type' ? `/edit-job-type/${jobtypeId}` : jobType == 'Sub-Job Type' ? `/edit-sub-job-type/${jobtypeId}` : `/edit-l2-sub-job-type/${jobtypeId}`);
        const payload = data;
        const response = await postApi(endpoint, payload);
        if (response.status) {
            toast.success(response.message)
            handleupdatejobtype.current.click()
            generateCurrentWeekDate()
        } else {
            toast.error(response.message)
        }
    }

    return (
        <div className="main-container container-fluid px-0">

            <div className="page-header mb-0">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">
                        <Breadcrumbsecond order={{ first: { name: "WFM" }, second: { name: "Schedule" } }} />
                    </h4>
                </div>
            </div>

            <div className="row mt-0">
                <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group mb-2">
                        <label className="form-label">Select Date</label>
                        <div className="row g-sm">
                            <div className="input-group" >
                                <DateRangePicker
                                    onApply={handleCallback} onCancel={handleCallbackOnCancel}
                                    initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                    <input
                                        placeholder="Search By Date"
                                        className="form-control fc-datepicker hasDatepicker"
                                        type="text" defaultValue=""
                                        style={{ cursor: 'pointer' }}
                                    />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                    {/* <div className="form-group mb-2">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keys..." />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>


                            </div>
                        </div>
                    </div> */}


                </div>
                <div className="col-xl-2 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12 mt-4">
                    <button
                        className="btn btn-primary ms-2"
                        style={{ height: '3rem' }}
                        onClick={() =>
                            // handlePublishShift()
                            setAssignJobTypeModal(true)
                            // dispatch(setPublishShiftData([]));
                        }

                    >Assign Job Types</button>
                    <button
                        className="btn btn-dark ms-2"
                        style={{ height: '3rem' }}
                        onClick={() =>
                            handlePublishShift()
                            // dispatch(setPublishShiftData([]));
                        }

                        disabled={checkIfDraftShiftAvailabile(true)}

                    >Publish</button>
                    {/* <div className="btn-list"> */}
                    {/* <div className="dropdown-action"> */}

                    <button className="btn fas fa-bell text-white"
                        style={{ borderRadius: '30%', backgroundColor: '#2a3bac', padding: '10px', border: 'none', marginLeft: '10px' }}
                        type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                        onClick={() => {
                            HandleLeaveCount();
                        }}
                    >
                        {leaveNotificationCount > 0
                            && <span
                                className="bg-danger"
                                style={{
                                    position: 'absolute', top: '0', right: '5',
                                    color: 'white', fontSize: '12px', padding: '2px 5px', borderRadius: '40%'
                                }}
                            >{leaveNotificationCount}</span>
                        }
                    </button>


                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu1" style={{ width: "18rem", left: '-5.2rem', cursor: "pointer", overflow: 'scroll', maxHeight: '300px' }}>
                        {liveLeaveRequest?.length > 0 && liveLeaveRequest.filter(data => data.status !== "rejected").map((cusDetail) => {
                            return (
                                <>
                                    <li className='px-2' onClick={() => HandleRequestById(cusDetail.id)}
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <div className='d-flex align-items-center'>
                                            <span className='fas fa-user text-primary px-2' style={{ fontSize: '1.4rem' }}></span>
                                            <div className='px-2' style={{ width: '100%' }}>
                                                <div className='d-flex justify-content-between' /* style={{ fontSize: '0.9rem' }} */>
                                                    <span class="dropdown-item5 fw-bold">{cusDetail?.employeeDetail?.first_name} {cusDetail?.employeeDetail?.last_name}</span>
                                                    <span className="text-primary" >({cusDetail?.leave_type})</span>
                                                </div>

                                                <span style={{ fontSize: '0.8rem' }}>{cusDetail?.start_date} to {cusDetail?.end_date} </span>

                                                <div className='d-flex justify-content-between'>
                                                    {/* <h6 className='' style={{ fontSize: '0.8rem' }}>{momentTimeZone.utc(cusDetail?.created_at).fromNow()}</h6> */}
                                                    <h6 className='' style={{ fontSize: '0.8rem' }}>
                                                        {formatTimeDiff(moment.utc(cusDetail?.created_at).format("YYYY-MM-DD HH:mm:ss"))}
                                                    </h6>
                                                    <h6 style={{ fontSize: '0.8rem', textTransform: 'uppercase' }}>
                                                        {cusDetail?.status === 'pending' ? (
                                                            <span className='text-warning'>{cusDetail?.status}</span>
                                                        ) : cusDetail?.status === "rejected" ? (
                                                            <span className='text-danger'>{cusDetail?.status}</span>
                                                        ) : (
                                                            <span className='text-success'>{cusDetail?.status}</span>
                                                        )}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <hr className='dropdown-divider' />
                                </>
                            )
                        })}
                        <li className='px-2'
                            data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <div className='text-center text-primary' onClick={() => ViewAllLeaveRequest()}>View All Request</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-md-2 p-0" style={{ backgroundColor: 'white' }}>
                    <h6 className='mt-3 mb-0 px-2 text-center' style={{ paddingBottom: '16px' }}>Job Type List</h6>

                    {/* <div className='' style={{ borderBottom: '1px solid #0f2f82', padding: '1.3rem', backgroundColor: '#ece5e1' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ALL SHIFT's {' >>'}</div> */}

                    <div className='btn btn-info rounded-pill' style={{ padding: '.2rem', backgroundColor: '#ece5e1', fontWeight: 'bolder', width: '100%', cursor: 'none' }}>ALL SHIFT's {' >>'}</div>



                    {
                        jobTypeAndChild?.length > 0 && jobTypeAndChild.map((item) => (
                            <>
                                <div className='py-2'
                                    style={{ fontSize: '13px' }}>
                                    {item.job_type}
                                    <span
                                        style={{ float: 'right' }}
                                        /* className='text-primary fw-bold' */>
                                        {item.limit}
                                        <i className='fas fa-edit text-primary ms-1'
                                            onClick={() => {
                                                setJobTypeId(item.id);
                                                setJobTypeName(item.job_type);
                                                setJobTypeLimit(item.limit);
                                                setJobType('Job Type');
                                                setPayloadType("job_type");
                                                setPayLoadLimit("limit")
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop1"
                                            style={{ cursor: 'pointer' }}
                                        /></span>

                                    {/* <Link style={{ float: 'right', height: '25px' }} className='fas fa-plus btn btn-primary btn-sm'
                                        to={`${prefix}/assign_job_type/${item.id}`}
                                        state={{ data: datateast, from: item?.job_type }}>
                                    </Link> */}
                                </div>

                                {
                                    item?.subJobType?.length > 0 && item.subJobType.map((subItem) => (

                                        <>
                                            <div className='py-2'

                                                style={{ fontSize: '11px' }}>&nbsp;&nbsp;&nbsp;&nbsp;{'>> ' + subItem.sub_job_type}
                                                <span
                                                    style={{ float: 'right' }}
                                                    /* className='text-primary fw-bold' */>
                                                    {subItem.sub_limit}
                                                    <i className='fas fa-edit text-primary ms-1'
                                                        onClick={() => {
                                                            setJobTypeId(subItem.id);
                                                            setJobTypeName(subItem.sub_job_type);
                                                            setJobTypeLimit(subItem.sub_limit);
                                                            setJobType('Sub-Job Type');
                                                            setPayloadType("sub_job_type");
                                                            setPayLoadLimit("sub_limit")
                                                        }}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#staticBackdrop1"
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </span></div>

                                            {
                                                subItem?.subL2JobType?.length > 0 && subItem.subL2JobType.map((subL2Item) => (
                                                    <div className='py-2'

                                                        style={{ fontSize: '11px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'>> ' + subL2Item.sub_l2_job_type}
                                                        <span
                                                            style={{ float: 'right' }}
                                                            /* className='text-primary fw-bold' */>
                                                            {subL2Item.sub_l2_limit}
                                                            <i className='fas fa-edit text-primary ms-1'
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setJobTypeId(subL2Item.id);
                                                                    setJobTypeName(subL2Item.sub_l2_job_type);
                                                                    setJobTypeLimit(subL2Item.sub_l2_limit);
                                                                    setJobType('L2 Sub-Job Type');
                                                                    setPayloadType("sub_l2_job_type");
                                                                    setPayLoadLimit("sub_l2_limit")
                                                                }}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#staticBackdrop1" />
                                                        </span></div>
                                                ))
                                            }
                                        </>
                                    ))
                                }
                            </>
                        ))
                    }

                </div>

                <div className="col-md-10 ">
                    <div className="card">
                        <div className="card-body p-0">
                            {/* <div className="table-responsive"> */}
                            <div className="table-responsive">

                                {/* ******************************************************************* */}
                                {/* ********************* CUSTOM BEAUTIFUL UI ************************  */}
                                {/* ******************************************************************* */}

                                {/* <table className="table table-hover card-table table-vcenter text-nowrap" style={{ width: '100%', borderCollapse: 'collapse' }}> */}




                                {/* ********************************************************* */}
                                {/* ********************* NEW TABLE ************************  */}
                                {/* ********************************************************* */}

                                <table className="" style={{ width: '-webkit-fill-available' }} >

                                    <thead className="border-bottom-0 pt-3 pb-3 text-center" >
                                        <tr>
                                            {weeklyDatesArr.length > 0 && weeklyDatesArr.map((dataItem => (

                                                <th className="font-weight-bold py-3" style={{ fontSize: '12px' }}>{dataItem.split(' ')[0]} {dataItem.split(' ')[1].substr(0, 3)} {dataItem.split(' ')[2].substr(0, 3)} <span className='fas fa-copy text-primary'
                                                    // onClick={() => handleCopyShift(dataItem)}
                                                    onClick={() => {
                                                        setCopyShiftModal(true)
                                                        setCopyDate(dataItem)
                                                        setCopyScheduleArr(scheduleArr)
                                                    }}
                                                >
                                                </span>
                                                </th>
                                            )))}
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {/* ********************** MAIN HEAD FOR SHIT NAME's Start ******************** */}

                                        <tr style={{ backgroundColor: '#ece5e1' }}>
                                            {weeklyDatesArr.length > 0 && weeklyDatesArr.map((dataItem => (
                                                <th>

                                                    <table style={{ width: '-webkit-fill-available' }}>
                                                        <tbody>
                                                            <tr>
                                                                {
                                                                    shiftArray.length > 0 && shiftArray.map((Item, index) => (
                                                                        <td style={{ border: '1px solid #0f2f82', padding: '.15rem' }}>
                                                                            <span style={{ paddingLeft: '.3rem' }} key={index}>
                                                                                {Item.shift_name.substr(0, 3)}
                                                                            </span>
                                                                        </td>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </th>
                                            )))}

                                        </tr>

                                        {/* ********************** MAIN HEAD FOR SHIT NAME's End ******************** */}


                                        {/***************** View Employee Limit Content Start ***********************/}

                                        {scheduleArr?.length > 0 && scheduleArr.map((item) => (
                                            <>
                                                <tr>
                                                    {item?.datesArray?.length > 0 && item?.datesArray.map(((dataItem, index) => {

                                                        return (

                                                            <th style={{ backgroundColor: item.type == 'parent' && '#029aff59' }}>
                                                                <table style={{ width: '-webkit-fill-available' }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            {
                                                                                dataItem?.shiftArray?.length > 0 && dataItem?.shiftArray.map((shiftItem, index) => {
                                                                                    return (
                                                                                        // <td style={{ border: '1px solid #0f2f82', padding: '.18rem .15rem', backgroundColor: shiftItem?.jobDetail?.teamMemberArray?.length < shiftItem?.jobDetail?.limit && '#ef4e4e' }}
                                                                                        <td style={{
                                                                                            border: '1px solid #0f2f82',
                                                                                            padding: '.18rem .15rem',
                                                                                            backgroundColor: shiftItem?.isDraft
                                                                                                ? 'yellow'
                                                                                                : shiftItem.jobDetail?.teamMemberArray?.some(member => member.on_leave == 2 || (member.on_leave == 1 && shiftItem?.jobDetail?.teamMemberArray?.filter((memData) => memData.on_leave == 0).length < shiftItem?.jobDetail?.limit))
                                                                                                    ? 'orange'
                                                                                                    : shiftItem?.jobDetail?.teamMemberArray?.filter((memData) => memData.on_leave == 0).length < shiftItem?.jobDetail?.limit && '#ef4e4e'
                                                                                        }}

                                                                                            onClick={() => {
                                                                                                if (shiftItem?.jobDetail?.limit > 0) {
                                                                                                    setSchedulerModal(true)
                                                                                                    setSelectedJobType(shiftItem.jobDetail)
                                                                                                    setSelectedShiftDetail(shiftItem)
                                                                                                }
                                                                                            }}

                                                                                        >{shiftItem?.jobDetail?.teamMemberArray?.filter((memData) => memData.on_leave == 0).length}/{shiftItem?.jobDetail?.limit}</td>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </th>
                                                        )
                                                    }))}
                                                </tr>
                                            </>
                                        ))}


                                        {/***************** View Employee Limit Content End ***********************/}


                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div >

            </div >



            {/* ********************************** Start Modal For Assign Employee Shift Wise  ******************************* */}
            {copyShiftModal &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} data-bs-backdrop="static">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Copy Shift &nbsp;{copyDate}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className="modal-body">

                                    <div className="form-group mb-2">
                                        <label className="form-label">Select Date</label>
                                        <div className="row g-sm">
                                            <div className="input-group" >
                                                <DateRangePicker
                                                    onApply={handleCallback} onCancel={handleCallbackOnCancel}
                                                    initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                                    <input
                                                        placeholder="Search By Date"
                                                        className="form-control fc-datepicker hasDatepicker"
                                                        type="text" defaultValue=""
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button"
                                        className="btn btn-dark"
                                        onClick={() => {
                                            setCopyShiftModal(false)
                                            setCopyScheduleArr([])
                                        }}
                                    >Cancel</button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            handleCopyShift(copyDate)
                                            setCopyShiftModal(false)
                                        }}
                                    >Copy</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-backdrop fade show"></div>
                </>
            }


            {/* ********************************** Start Modal For Assign Employee Shift Wise  ******************************* */}
            {
                schedulerModal &&
                <>
                    <div className="modal fade show" style={{ display: 'block' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='d-flex fs-12'>
                                        <div className='me-2'>
                                            <p className='m-0 fw-bold text-wrap'>Date</p>
                                            <p className='m-0 fw-bold text-wrap'>Job Type </p>
                                        </div>
                                        <div>
                                            <p className='m-0 text-wrap'>: {selectedShiftDetail.shift_date}</p>
                                            <p className='m-0 text-wrap'>: {selectedJobType?.job_name ? selectedJobType?.job_name : "--"} </p>
                                        </div>
                                    </div>
                                    {/* <h3 className="modal-title fs-6 text-center text-dark" id="staticBackdropLabel"> 
                                                <br /><span className="text-primary" style={{ fontSize: '14px', marginLeft: '-45px' }}></span>
                                            </h3> */}
                                    <div>
                                        <span className='btn btn-sm btn-primary me-1' style={{ fontSize: '10px' }}>{selectedShiftDetail.jobDetail.limit} <br /> REQUIRED</span>
                                        <span className='btn btn-sm btn-secondary me-1' style={{ fontSize: '10px' }}>{selectedShiftDetail.jobDetail.teamMemberArray?.filter((memData) => memData.on_leave == 0).length} <br /> SCHEDULED</span>
                                        <span className='btn btn-sm btn-danger me-1' style={{ fontSize: '10px' }}>{selectedShiftDetail.jobDetail.limit - selectedShiftDetail.jobDetail.teamMemberArray.filter((memData) => memData.on_leave == 0).length} <br /> OPEN</span>
                                    </div>
                                </div>
                                <div className="modal-body" style={{ padding: "0.50rem" }}>

                                    <div className='row '>

                                        <div className='col-md-4'>

                                            <div className='d-flex align-items-center my-2'>
                                                <input
                                                    type="text"
                                                    className="form-control m-0"
                                                    style={{ width: "120px", fontSize: "12px", height: "35px", padding: "0px 10px" }}
                                                    value={search} onChange={(e) => setSearch(e.target.value)}
                                                    placeholder="Search here..."
                                                />

                                                <Form.Select aria-label="Default select example" onChange={(e) => { setSortedBy(e.target.value) }}>

                                                    <option value="first_name">First Name</option>
                                                    <option value="last_name">Last Name</option>
                                                    <option value="mobile_number">Mobile Number</option>
                                                    <option value="">Clear Sorting</option>

                                                </Form.Select>

                                                <i className={orderBy != 'asc' ? "fa-solid fa-arrow-up-a-z" : "fa-solid fa-arrow-down-z-a"} onClick={e => { orderBy == 'asc' ? setOrderBy("desc") : setOrderBy("asc") }} style={{ fontSize: "20px" }}></i>


                                            </div>

                                            <div style={{ overflow: 'scroll', height: '400px' }}>
                                                {dataArray.length > 0 ? dataArray.filter((data) =>
                                                    selectedJobType.type == 'parent' ? selectedJobType.id == data.job_id
                                                        : selectedJobType.type == 'child' ? selectedJobType.id == data.sub_job_id
                                                            : selectedJobType.id == data.sub_l2_job_id).length == 0
                                                    ? <div className='d-flex justify-content-center align-items-center mt-5 text-danger' style={{ textTransform: 'none' }}>No Employee Assigned to this Job Type</div>

                                                    : dataArray.filter((data) =>
                                                        selectedJobType.type == 'parent' ? selectedJobType.id == data.job_id
                                                            : selectedJobType.type == 'child' ? selectedJobType.id == data.sub_job_id
                                                                : selectedJobType.id == data.sub_l2_job_id).map((e, id) => {

                                                                    // ******* CHECKING IS USER SHIFT IS ALREADY PUBLISHED OR ALREADY MOVED TO A DRAFT SHIFT ********
                                                                    let checkIfMemberAlreadyAssigned = selectedShiftDetail.jobDetail?.teamMemberArray?.some(member => member.customer_id === e.customer_id)

                                                                    // **************************************************************************
                                                                    // ******* SHIFT IS ASSIGNED TO ANY JOB TYPE FOR THE SAME DATE AND SHIFT THEN USER WON't ABLE TO ASSIGN IN ANOTHER FOR THE SAME DAY AND SHIFT ********
                                                                    // **************************************************************************
                                                                    const hasDate = (datesArray, shiftDate) => {
                                                                        return datesArray.some((item) => item.date === shiftDate);
                                                                    };

                                                                    const hasShift = (shiftArray, shiftId) => {
                                                                        return shiftArray.some((item) => item.shift_id === shiftId);
                                                                    };

                                                                    const hasMember = (teamMemberArray, customerId) => {
                                                                        return teamMemberArray?.some((member) => member.customer_id === customerId);
                                                                    };

                                                                    const assignedInAnotherShift = scheduleArr.some((item1) => {
                                                                        return hasDate(item1.datesArray, selectedShiftDetail.shift_date) &&
                                                                            hasShift(item1.datesArray.find((item2) => item2.date === selectedShiftDetail.shift_date).shiftArray, selectedShiftDetail.shift_id) &&
                                                                            hasMember(item1.datesArray.find((item2) => item2.date === selectedShiftDetail.shift_date).shiftArray.find((item3) => item3.shift_id === selectedShiftDetail.shift_id).jobDetail.teamMemberArray, e.customer_id);
                                                                    });


                                                                    // **************************************************************************
                                                                    // ******* EMPLOYEE IS ON LEAVE WHICH IS APPROVED ********
                                                                    // **************************************************************************
                                                                    const isEmployeeOnLeave = selectedShiftDetail.jobDetail?.teamMemberArray?.some(member => member.customer_id === e.customer_id && member.on_leave == 1)

                                                                    // **************************************************************************
                                                                    // ******* EMPLOYEE REQUEST FOR LEAVE WHICH IS PRNDING ********
                                                                    // **************************************************************************
                                                                    const isLeavePendingLeaveRequest = selectedShiftDetail.jobDetail?.teamMemberArray?.some(member => member.customer_id === e.customer_id && member.on_leave == 2)

                                                                    // **************************************************************************
                                                                    // ******* CASE: SHIFT IS NOT CREATED YET BUT EMPOYEE REQUEST FOR LEAVE ********
                                                                    // **************************************************************************
                                                                    let isAlreadyAppliedForLeave = false
                                                                    let leaveStatus = "rejected"

                                                                    leaveRequest.map((leaveOpt) => {
                                                                        if (e.customer_id == leaveOpt.customer_id && leaveOpt.status != 'rejected') {
                                                                            let a = moment(selectedShiftDetail.shift_date, 'Do MMMM dddd').isBetween(moment(leaveOpt.start_date), moment(leaveOpt.end_date), null, '[]')
                                                                            if (a) {
                                                                                isAlreadyAppliedForLeave = true
                                                                                leaveStatus = leaveOpt.status
                                                                            }
                                                                        }
                                                                    })

                                                                    return (
                                                                        <>
                                                                            <div

                                                                                draggable={!checkIfMemberAlreadyAssigned && !isAlreadyAppliedForLeave && !assignedInAnotherShift}
                                                                                onDragStart={(event) => handleDragStart(event, e)}

                                                                                key={e.customer_id}
                                                                                className='d-flex  align-items-center py-2 mb-2'
                                                                                style={{ borderBottom: "1px solid #d1cbcb", cursor: "pointer", userSelect: "none", MozUserSelect: "none", msUserSelect: "none", WebkitUserSelect: "none" }}
                                                                            >

                                                                                <div className='mx-2'>
                                                                                    <div className='fas fa-user mx-3 text-primary' style={{ fontSize: "20px" }}></div>
                                                                                </div>
                                                                                <div className='text-start'>
                                                                                    <span style={{ fontSize: "13px" }}>{e.first_name ? e.first_name : "--"}&nbsp;{e?.last_name ? e.last_name : ""}</span>
                                                                                    <br />
                                                                                    <small>{e?.mobile_number ? e.mobile_number : ""}</small> <br />

                                                                                    {isEmployeeOnLeave || (isAlreadyAppliedForLeave && leaveStatus == 'approved')
                                                                                        ? <small className='btn btn-danger btn-sm rounded' style={{ fontSize: '0.8rem', padding: '0px 8px' }}>On Leave</small>
                                                                                        : isLeavePendingLeaveRequest || (isAlreadyAppliedForLeave && leaveStatus == 'pending')
                                                                                            ? <small className='btn btn-warning btn-sm rounded' style={{ fontSize: '0.8rem', padding: '0px 8px' }}>Pending Leave Request</small>
                                                                                            : (checkIfMemberAlreadyAssigned || assignedInAnotherShift) &&
                                                                                            <small className='btn btn-info btn-sm rounded' style={{ fontSize: '0.8rem', padding: '0px 8px' }}>Shift Assigned</small>
                                                                                    }

                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                    )
                                                                }) :
                                                    <div className='d-flex justify-content-center align-items-center mt-5'>No Data Found</div>
                                                }

                                            </div>
                                        </div>

                                        <div className='col-md-8'>
                                            <div className='d-flex justify-content-center flex-column h-100 mt-3' style={{ overflowX: "scroll" }}>
                                                <h5 className='text-center'>{selectedShiftDetail.shift_name}</h5>

                                                <div
                                                    onDrop={(e) => handleDrop(e, selectedShiftDetail)}
                                                    onDragOver={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                >
                                                    {(selectedShiftDetail.jobDetail.limit - selectedShiftDetail.jobDetail.teamMemberArray.filter((memData) => memData.on_leave == 0).length) > 0 &&
                                                        <div className='text-center' style={{ padding: '10px', minHeight: '20px', border: "2px solid black", borderStyle: "dashed" }}>
                                                            Drop it here
                                                        </div>
                                                    }

                                                </div>


                                                <div style={{ overflow: 'scroll', height: '355px' }}>

                                                    {selectedShiftDetail.jobDetail?.teamMemberArray?.length > 0 && selectedShiftDetail.jobDetail?.teamMemberArray?.reverse().map((memberData, id) => {
                                                        // console.log(memberData, "*************************")
                                                        return (
                                                            <>
                                                                <div key={id} className='d-flex justify-content-between align-items-center py-2 my-2' style={{ borderBottom: "1px solid #d1cbcb" }}>

                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='mx-2'>
                                                                            <div className='fas fa-user mx-3 text-primary' style={{ fontSize: "20px" }}></div>
                                                                        </div>
                                                                        <div className='text-start'>
                                                                            <span style={{ fontSize: "13px" }}>{memberData?.first_name ? memberData?.first_name : "--"}&nbsp;{memberData?.last_name ? memberData?.last_name : ""}</span> <br />
                                                                            <small>{memberData?.mobile_number ? memberData?.mobile_number : ""}</small> <br />
                                                                        </div>
                                                                    </div>

                                                                    <div className=''>
                                                                        {!memberData?.isPublished ?
                                                                            <div
                                                                                className='fas fa-trash mx-3 text-danger'
                                                                                style={{ fontSize: "20px" }}
                                                                                onClick={() => {
                                                                                    const updateData = {
                                                                                        ...selectedShiftDetail,
                                                                                        jobDetail: {
                                                                                            ...selectedShiftDetail.jobDetail,
                                                                                            teamMemberArray: selectedShiftDetail.jobDetail.teamMemberArray.filter(member => member.customer_id !== memberData.customer_id)
                                                                                        }
                                                                                    }

                                                                                    setSelectedShiftDetail(updateData)

                                                                                }}
                                                                            ></div>
                                                                            : memberData?.on_leave == '1'
                                                                                ? <small className='btn btn-danger btn-sm rounded-pill'>On Leave</small>
                                                                                : memberData?.on_leave == '2'
                                                                                    ? <small className='btn btn-warning rounded-pill'>Pending Leave Request</small>
                                                                                    : <small className='btn btn-success rounded-pill'>Published</small>

                                                                            // <div className='text-success fw-bold'>Shift Published</div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>




                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-dark" onClick={() => { setSchedulerModal(false); setSearch('') }}>Cancel</button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {

                                            const updatedFullRow = scheduleArr.map((mainItem) => {

                                                if (mainItem.type == selectedShiftDetail.jobDetail.type) {

                                                    mainItem.datesArray = mainItem.datesArray.map((childItem) => {
                                                        if (childItem.date == selectedShiftDetail.shift_date) {

                                                            childItem.shiftArray = childItem.shiftArray.map((subChildItem) => {
                                                                if (subChildItem.shift_id == selectedShiftDetail.shift_id && subChildItem.jobDetail.id == selectedShiftDetail.jobDetail.id) {
                                                                    return {
                                                                        ...subChildItem,
                                                                        isDraft: selectedShiftDetail.jobDetail.teamMemberArray.some(member => member.isPublished == false),
                                                                        jobDetail: selectedShiftDetail.jobDetail
                                                                    }
                                                                }

                                                                return subChildItem
                                                            })
                                                        }
                                                        return childItem
                                                    })

                                                }
                                                return mainItem
                                            })

                                            // console.log("🚀 ~ updatedFullRow ~ updatedFullRow:", updatedFullRow)

                                            setSchedulerModal(false)
                                            setSearch('')

                                        }}
                                    // disabled={selectedShiftDetail.jobDetail.teamMemberArray.length == 0}
                                    >Save Shifts</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-backdrop fade show"></div>
                </>
            }
            {/* ********************************** End Modal For Assign Employee Shift Wise  ******************************* */}

            <JobTypeAssignModal assignJobTypeModal={assignJobTypeModal} setAssignJobTypeModal={setAssignJobTypeModal} employeeList={dataArray} jobTypeAndChild={jobTypeAndChild} />



            {/* ************************************************************************************************** */}
            {/* ************************************ LEAVE REQUEST MODAL START *********************************** */}
            {/* ************************************************************************************************** */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ width: '108%' }}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Update Leaves Request</h5>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>

                        <div class="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                        <tr>
                                                            <th className="font-weight-bold">Employee Detail  </th>
                                                            <th className="font-weight-bold"> Leaves Detail </th>
                                                            <th className="font-weight-bold"> Start Date </th>
                                                            <th className="font-weight-bold"> End Date </th>
                                                            <th className="font-weight-bold">Customer Comment </th>
                                                            <th className="font-weight-bold">Employer Comment </th>
                                                            <th className="font-weight-bold">Status </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {leaveRequest.length > 0 && leaveRequest.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            return (
                                                                <>
                                                                    <tr key={serial_num} >
                                                                        <td><span className="font-weight-normal1">{option?.employeeDetail?.first_name ? option?.employeeDetail?.first_name : "--"} {option?.employeeDetail?.last_name ? option?.employeeDetail?.last_name : ""}</span></td>
                                                                        <td>
                                                                            <span className="font-weight-normal1">
                                                                                <div className='d-flex'>
                                                                                    <div className='me-2'>
                                                                                        <p className='m-0 fw-bold text-wrap'>Type</p>
                                                                                        <p className='m-0 fw-bold text-wrap'>Count</p>
                                                                                        <p className='m-0 fw-bold text-wrap'>Period</p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className='m-0 text-wrap'>: {option?.leave_type ? option?.leave_type : "--"}</p>
                                                                                        <p className='m-0 text-wrap'>: {option?.used_leave_balance ? option?.used_leave_balance : "0"}/{option?.total_leave_balance ? option?.total_leave_balance : "0"} </p>
                                                                                        <p className='m-0 text-wrap'>: {option?.period ? option?.period : "--"}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type='date'
                                                                                value={option?.start_date}
                                                                                className="form-control"
                                                                                disabled={option.status !== 'approved'}
                                                                                onChange={event => {
                                                                                    const updatedData = [...leaveRequest];
                                                                                    updatedData[index].start_date = event.target.value;
                                                                                    setLeaveRequest(updatedData);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type='date'
                                                                                value={option?.end_date}
                                                                                className="form-control"
                                                                                disabled={option.status !== 'approved'}
                                                                                onChange={event => {
                                                                                    const updatedData = [...leaveRequest];
                                                                                    updatedData[index].end_date = event.target.value;
                                                                                    setLeaveRequest(updatedData);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td><span className="font-weight-normal1">{option?.customer_comment}</span></td>
                                                                        <td>
                                                                            <input
                                                                                type='text'
                                                                                value={option?.employer_comment}
                                                                                className="form-control"
                                                                                placeholder='Employer Comment...'
                                                                                onChange={event => {
                                                                                    const updatedData = [...leaveRequest];
                                                                                    updatedData[index].employer_comment = event.target.value;
                                                                                    setLeaveRequest(updatedData);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                className="form-control mb-0"
                                                                                value={option?.status}
                                                                                onChange={event => {
                                                                                    const updatedData = [...leaveRequest];
                                                                                    updatedData[index].status = event.target.value;
                                                                                    // console.log("🚀 ~ {leaveRequest.length>0&&leaveRequest.map ~ event.target.value:", event.target.value)
                                                                                    // console.log("🚀 ~ {leaveRequest.length>0&&leaveRequest.map ~ option.actualStartDate:", option.actualStartDate)
                                                                                    // console.log("🚀 ~ option.actualEndDate:", option.actualEndDate)

                                                                                    if (event.target.value !== 'approved') {
                                                                                        updatedData[index].start_date = option.actualStartDate;
                                                                                        updatedData[index].end_date = option.actualEndDate;
                                                                                    }
                                                                                    setLeaveRequest(updatedData);
                                                                                }}
                                                                            >
                                                                                <option value="">-- SELECT -- </option>
                                                                                <option value="rejected">Reject </option>
                                                                                <option value="approved">Approve </option>
                                                                            </select>
                                                                        </td>

                                                                    </tr>


                                                                    {option?.status == "approved" && option?.shiftArray?.length > 0 && option?.shiftArray?.map((item, subIndex) => {
                                                                        return (
                                                                            <tr>
                                                                                <td></td>

                                                                                <td>
                                                                                    <div className='grid-item'>
                                                                                        <label>Date: {item.shift_date}</label>
                                                                                        <label>JOB Type: {item?.job_type == 'parent' ? item.JT_type : item?.job_type == 'child' ? item.SJT_type : item?.job_type == 'subChild' && item.SLJT_type}</label>

                                                                                    </div>
                                                                                </td>

                                                                                <td></td>

                                                                                <td>
                                                                                    <div className='grid-item'>

                                                                                        <div style={{ position: 'relative' }}>
                                                                                            <select
                                                                                                data-style="btn-primary"
                                                                                                className="form-select mb-0"
                                                                                                name="job_id"
                                                                                                aria-label="size 2 select example"
                                                                                                value={item?.replaceCustomerId}
                                                                                                onChange={(e) => {
                                                                                                    const updatedArray = option?.shiftArray?.map((data => {
                                                                                                        if (data.job_id === item.job_id && data.shift_id == item.shift_id && data.shift_date == item.shift_date) {
                                                                                                            data.replaceCustomerId = parseInt(e.target.value)
                                                                                                        }
                                                                                                        return data
                                                                                                    }))

                                                                                                    const updatedData = [...leaveRequest];
                                                                                                    updatedData[index].shiftArray = updatedArray
                                                                                                    setLeaveRequest(updatedData);

                                                                                                }}
                                                                                            >
                                                                                                <option value="" >--  --</option>
                                                                                                <optgroup label="Available Replacement">
                                                                                                    {item?.replacementEmployees?.map((data) => (

                                                                                                        <option data-icon="fa-heart" key={data.customer_id} value={data.customer_id}>
                                                                                                            {data.first_name}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </optgroup>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>

                                                                                <td></td>

                                                                                <td>
                                                                                    <div className='grid-item'>
                                                                                        <div className='text-center'>{item.shift_name} <br /> {moment(item?.shift_start_time, "HH:mm:ss").format("hh:mm A")} - {moment(item?.shift_end_time, "HH:mm:ss").format("hh:mm A")}</div>
                                                                                    </div>
                                                                                </td>

                                                                                <td></td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button ref={handleDisableUpdate} type="button" class="btn btn-dark" data-bs-dismiss="modal"
                                onClick={() => {
                                    setSearch(''); getLeaveRequest()
                                }}>Close</button>
                            <button type="button" class="btn btn-primary"
                                onClick={() => { UpdateLeaveRequestDetail(); setSearch(''); getLeaveRequest() }
                                }>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ************************************************************************************************** */}
            {/* ************************************ LEAVE REQUEST MODAL END ************************************* */}
            {/* ************************************************************************************************** */}


            {/* ************************************************************************************************** */}
            {/********************************** Edit Job type Modal Start **********************/}
            {/* ************************************************************************************************** */}

            <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Update {jobType}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form >

                            <div class="modal-body">
                                <div className='row'>

                                    <div className='col-md-6'>
                                        <label>{jobType} </label>

                                        <input /* placeholder='Enter limit' */ value={jobtypeName}
                                            onChange={(e) => setJobTypeName(e.target.value)}
                                            className='form-control' />
                                    </div>
                                    <div className='col-md-6'>
                                        <label>{jobType} Limit</label>
                                        <input type='number'
                                            /* placeholder='Enter limit' */
                                            value={jobtypeLimit}
                                            onChange={(e) => {
                                                let inputValue = e.target.value;
                                                if (inputValue === '' || /^\d{0,3}$/.test(inputValue)) {
                                                    const parsedValue = inputValue === '' ? '' : parseInt(inputValue);
                                                    setJobTypeLimit(parsedValue)
                                                }
                                            }}
                                            // onChange={(e) => setJobTypeLimit(e.target.value)}
                                            className='form-control'></input>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button"
                                    ref={handleupdatejobtype}
                                    class="btn btn-dark"
                                    data-bs-dismiss="modal">Close</button>
                                <button type="button"
                                    disabled={!jobtypeName || jobtypeLimit == ""}
                                    onClick={() => UpdateJobdetails()}
                                    class="btn btn-primary" >Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* ************************************************************************************************** */}
            {/*********************** Edit Job type Modal End **********************/}
            {/* ************************************************************************************************** */}
        </div >
    )
}

export default Schedule
