import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import PaginationComponent from '../../PaginationComponent';
import { GetBNPLSettlementDetails, GetEwaEarningDetails, GetEwaEarningDetailsbyID, getApi } from '../service/adminService';
import { Breadcrumbs } from '../../components';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import { Image } from 'antd';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const EwaEarningdetail = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loading, setloading] = useState(false);
    const [profileData, setprofileData] = useState({});
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const { id } = useParams()
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [detail, setDetail] = useState([]);
    const admin = JSON.parse(localStorage.getItem("admin"));

    const GetEwaEarningdetail = async (token) => {
        // const response = await GetEwaEarningDetailsbyID(token,id, search, pagesCount, sortedBy, orderBy)
        const response = await getApi(`/ewa-earning-detail/${id}`);
        console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data)
                setTotalPagesCount(response.count)
                setloader(false)
            }, 2000);
            setloader(true)

        } else if (response.status == false) {
            console.log(response)
        }
    }

    useEffect(() => {
        GetEwaEarningdetail(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h4 className="page-title mb-0 text-primary">Talk To Us </h4> */}
                                <Breadcrumbs order={{ first: { name: "Earning Report" }, second: { name: "Earning EWA" } }} />
                            </div>



                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link> */}

                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">TXN ID  {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                                                        <th className="font-weight-bold">Customer Details  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Merchant Details   {sortedBy == "employer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                        <th className="font-weight-bold">TXN Charges  {sortedBy == "txn_charge" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_charge') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_charge') }}></i>}</th>
                                                        <th className="font-weight-bold">Thirdroc Income  {sortedBy == "thirdroc_income" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('thirdroc_income') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('thirdroc_income') }}></i>}</th>
                                                        <th className="font-weight-bold">Merchant Settled Amount  {sortedBy == "merchant_settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }}></i>}</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { first_name, last_name, mobile_number, txn_id, employer_name, employer_mobile, amount, txn_charge, thirdroc_income, merchant_settled_amount } = option;
                                                                var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td><span className="font-weight-normal1"> {txn_id ? txn_id : "NA"} </span>
                                                                            <span><CopyToClipboard text={txn_id}><button className='btn btn-primary btn-sm ms-3' /* onClick={() => copiedInfo()} */><i className="fa-solid fa-copy"></i></button></CopyToClipboard></span></td>
                                                                        <td><span className="font-weight-normal1">{first_name ? first_name : "NA"}&nbsp;{last_name ? last_name : ""}<br />{mobile_number ? mobile_number : ""}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{employer_name ? employer_name : "NA"}<br />{employer_mobile ? employer_mobile : ""}</span></td>
                                                                        <td><span className="font-weight-normal1"> {amount ? "$" + amount : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">{txn_charge ? "$" + txn_charge : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">{thirdroc_income ? "$" + thirdroc_income : "NA"} </span></td>
                                                                        <td><span className="font-weight-normal1">{merchant_settled_amount ? "$" + merchant_settled_amount : "NA"} </span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>}
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>

                    {/* Merchant Information Start */}
                    <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header"> <h6 className="modal-title">Merchant Transaction Details</h6>
                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                <div className="modal-body application-modal">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="">
                                                <div className="main-content-body main-content-body-contacts">
                                                    {/* <h6>Marchant Information</h6> */}
                                                    <div className="main-contact-info-header bg-contacthead pb15">
                                                        <div className="media">
                                                            <div className="media-body text-white ml-0">
                                                                <h4 className="text-white">Company Name: {detail && detail ? detail.company_name : ''}</h4>
                                                                <p className="mb-1">Person Name: {detail && detail ? detail.full_name : ''}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="main-contact-action"> <Link data-bs-dismiss="modal" onClick={() => hideModel(detail)} className="btn btn-primary"> Edit</Link></div> */}
                                                    </div>
                                                    <div className="main-contact-info-body">
                                                        <div className="media-list p-0">
                                                            <div className="media py-4 mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Total Amount</label> <span className="font-weight-normal1 fs-14">{detail && detail ? "$" + detail.amount : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Merchant Settled Amount</label> <span className="font-weight-normal1 fs-14">{detail && detail ? "$" + detail.merchant_settled_amount : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-dollar "></i> </div>
                                                                        <div className="w-70"> <label>TXN Charges:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? "$" + detail.txn_charge : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Merchant Commission</label> <span className="font-weight-normal1 fs-14">{detail && detail ? "$" + detail.mrch_commission : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-percent "></i> </div>
                                                                        <div className="w-70"> <label>Merchant Commission %</label> <span className="font-weight-normal1 fs-14">{detail && detail ? +detail.thirdroc_roi + "%" : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                        <div className="w-70"> <label>Transaction ID</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.txn_id : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Merchant Information End */}

                </div>

            }
        </>
    )
}

export default EwaEarningdetail
