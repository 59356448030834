
import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Header from './layout/header'
import Footer from './layout/footer'
import Service from './../lenderService';
import Dashboard from './user/dashboard';
import Profile from './user/profile/profile';
import Transctions from './user/Transactions/Transctions';
import EditProfile from './user/profile/editProfile';
import ChangePassword from './user/profile/changePassword';
// import config from '../config.json';
import QRCode from './user/QRCode';
// import Settlement from './user/settlement';
import StoreList from './user/store/storeList';
import AddStore from './user/store/addStore';
import { UseAppContext } from '../Context/AppContext';
import AddDocument from './user/profile/addDocument';
import Addcounter from './user/store/addcounter';
import { Merchantinformation } from './user/store/merchantinformation';
import Counter from './user/store/Counter';
import StoreAddcounter from './user/store/storeAddcounter';
import Editstore from './user/store/Editstore';
import ViewtransactionID from './user/Settlement/ViewtransactionID';
import Reconciliation from './Reconciliation/Reconciliation';
import Settlement from './user/Settlement/settlement';


const api = new Service();
const Index = (props) => {
    const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;
    // const { role_id,permission_id } = useParams();
    const { profile_id, user_id, caseStatus, dsa_id, lender_id, id } = useParams();
    // const navigate = useNavigate();
    const { header } = UseAppContext();
    const [locationName, setlocationName] = useState(prefix + '/dashboard');
    const location = useLocation();

    useEffect(() => {
        setlocationName(location.pathname)
    }, [location.pathname])


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="page">
                <div className="page-main">
                    <Header prefix={prefix} profile_id={profile_id} caseStatus={caseStatus} dsa_id={dsa_id} lender_id={lender_id} user_id={user_id} />
                    <div className={header ? "app_content_res app-content main-content" : "app-content main-content"}>
                        <div className="side-app">
                            {locationName == prefix + '/dashboard' || locationName == prefix ?
                                <Dashboard api={api} toast={toast} prefix={prefix} />
                                : locationName == prefix + '/profile' ?
                                    <Profile api={api} toast={toast} prefix={prefix} />
                                    : locationName == prefix + '/transactions' ?
                                        <Transctions api={api} toast={toast} prefix={prefix} />
                                        : locationName == prefix + '/settlement' ?
                                            <Settlement api={api} toast={toast} prefix={prefix} />
                                            : locationName == prefix + '/store_list' ?
                                                <StoreList api={api} toast={toast} prefix={prefix} />
                                                : locationName == prefix + "/merchant_information" ?
                                                    <Merchantinformation api={api} toast={toast} prefix={prefix} />
                                                    : locationName == prefix + '/add_store' ?
                                                        <AddStore api={api} toast={toast} prefix={prefix} />
                                                        : locationName == prefix + '/qr_code' ?
                                                            <QRCode api={api} toast={toast} prefix={prefix} />
                                                            : locationName == prefix + '/editprofile/' + user_id ?
                                                                <EditProfile api={api} toast={toast} prefix={prefix} user_id={user_id} />
                                                                : locationName == prefix + '/changepassword/' + user_id ?
                                                                    <ChangePassword api={api} toast={toast} prefix={prefix} />
                                                                    : locationName == prefix + '/add_document' ?
                                                                        <AddDocument api={api} toast={toast} prefix={prefix} />
                                                                        : locationName == prefix + '/addcounter/' + id ?
                                                                            <Addcounter api={api} toast={toast} prefix={prefix} />
                                                                            : locationName == prefix + '/counter' ?
                                                                                <Counter api={api} toast={toast} prefix={prefix} />
                                                                                : locationName == prefix + '/store_addcounter' ?
                                                                                    <StoreAddcounter api={api} toast={toast} prefix={prefix} />
                                                                                    : locationName == prefix + '/editstore/' + id ?
                                                                                        <Editstore api={api} toast={toast} prefix={prefix} />
                                                                                        : locationName == prefix + '/view_transaction_id/' + id ?
                                                                                            <ViewtransactionID api={api} toast={toast} prefix={prefix} />
                                                                                            : locationName == prefix + '/reconciliation' ?
                                                                                                <Reconciliation api={api} toast={toast} prefix={prefix} />
                                                                                                : ''}
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>

    )



}

export default Index