import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import PaginationComponent from '../../../PaginationComponent';
import { getApi, postApi } from '../../service/adminService';
import { GetCountry } from '../../../employer/service/employService';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import Search from '../../../components/Search/Search';

const Pf = () => {
    const tableRef = useRef(null);
    const closemodal = useRef()
    const [pagesCount, setpagesCount] = useState(1);
    const [search, setSearch] = useState('');
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [country, setCountry] = useState([]);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [ValidPagination, setValidPagination] = useState(false)

    const viewPlan = async () => {
        try {
            const response = await getApi('/get-pf-plan', { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
            if (response.status) {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
            } else {
                console.log(response)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        viewPlan()
    }, [search, pagesCount, search, orderBy, sortedBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // getEmployeesData(token, pagesCount, search );
    }

    const AddForm = useFormik({
        initialValues: {
            title: "",
            employer_percentage: "",
            employee_percentage: "",
            eligible_country: [],
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup
                .string()
                .required('Please Enter Title')
                .matches(/^\S.*$/, 'Title cannot start with a space')
                .matches(/^[\w\s]+$/, 'Title cannot contain special characters'),
            employer_percentage: yup
                .string()
                .matches(/^\d{1,4}$/, 'Employer % must be up to 4 digits')
                .typeError('Please Enter a valid Employer %')
                .required('Please Enter Employer %'),
            employee_percentage: yup
                .string()
                .matches(/^\d{1,4}$/, 'Employee % must be up to 4 digits')
                .typeError('Please Enter a valid Employee %')
                .required('Please Enter Employee %'),
            eligible_country: yup
                .array()
                .of(yup.string().required('Country is required'))
                .min(1, 'Please select at least one country'),

        }),
        onSubmit: async (values, { resetForm }) => {

            const url = /* HandleName === "Edit Plan" ? `/edit-wfm-plan/${id}` :  */"/add-pf-plan";
            const data = {
                title: values.title,
                employer_percentage: values.employer_percentage /* === "" ? null : values.employer_percentage */,
                employee_percentage: values.employee_percentage /* ? values.employee_percentage : null */,
                eligible_country: values.eligible_country,

            };
            const response = await postApi(url, data);
            if (response.status) {
                closemodal.current.click()
                toast.success(response.message);
                resetForm({ values: '' });
                viewPlan()

            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    });


    const GetCountrys = async () => {
        try {
            const response = await GetCountry();
            console.log('response', response);
            if (response.status) {
                setCountry(response.data);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetCountrys();
    }, []);

    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">PF Plan</h4>
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    <Link to="" className="btn btn-primary btn-pill"
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        <i className="fa fa-plus me-1"></i> Add PF Plan</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"> */}

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"></div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"></div>
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            {/* </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">PF Title {sortedBy == "title" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('title') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('title') }}></i>}</th>
                                                        <th className="font-weight-bold">Employer % {sortedBy == "employer_percentage" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_percentage') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_percentage') }}></i>}</th>
                                                        <th className="font-weight-bold">Employee % {sortedBy == "employee_percentage" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employee_percentage') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employee_percentage') }}></i>}</th>
                                                        <th className="font-weight-bold">Country {sortedBy == "eligible_country" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('eligible_country') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('eligible_country') }}></i>}</th>
                                                        <th className="font-weight-bold">Created Date {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                        <th className="font-weight-bold">Updated Date {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                                    </tr>
                                                </thead>
                                                {dataArray?.length > 0 && dataArray?.map((Item, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    const { title, employer_percentage, employee_percentage, created_at, updated_at, eligible_country } = Item
                                                    const data = eligible_country ? eligible_country : [];
                                                    const updateData = data.length > 0 ? data.join(", ") : "--";
                                                    // console.log(updateData);
                                                    return (
                                                        <tbody>
                                                            <tr key={serial_num}>
                                                                <td><span className="font-weight-normal1">{Item?.title ? title : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{Item?.employer_percentage ? employer_percentage + " %" : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{Item?.employee_percentage ? employee_percentage + " %" : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{updateData}</span></td>
                                                                <td><span className="font-weight-normal1">{Item?.created_at ? TimeZone(created_at).timeZone : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{Item?.updated_at ? TimeZone(updated_at).timeZone : "--"}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                }
                                                )}
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>
            }

            <div className="modal fade" id="staticBackdrop"
                data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{/* {HandleName} */} PF Plan Modal</h5>
                            {/* <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">x</button> */}
                        </div>
                        <form onSubmit={AddForm.handleSubmit}>
                            <div className="modal-body">
                                <div className=" row">
                                    <div className=" col-md-6 mb-3">
                                        <label for="recipient-name" className="col-form-label">Title:</label>
                                        <input type="text" className="form-control" placeholder='Enter PF Title' {...AddForm.getFieldProps('title')} />
                                        {AddForm.touched.title && AddForm.errors.title ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.title}</div> : ''}
                                    </div>
                                    <div className=" col-md-6 mb-3">
                                        <label for="recipient-name" className="col-form-label">Employer Percentage:</label>
                                        <input type="number" className="form-control" placeholder='Enter Employer Percentage'{...AddForm.getFieldProps('employer_percentage')} />
                                        {AddForm.touched.employer_percentage && AddForm.errors.employer_percentage ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.employer_percentage}</div> : ''}
                                    </div>
                                    <div className=" col-md-6 mb-3">
                                        <label for="recipient-name" className="col-form-label" style={{ textTransform: 'none' }}>Employee Percentage:</label>
                                        <input type="number" className="form-control" placeholder='Enter Employee Percentage'{...AddForm.getFieldProps('employee_percentage')} />
                                        {AddForm.touched.employee_percentage && AddForm.errors.employee_percentage ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.employee_percentage}</div> : ''}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="col-form-label">Select  Country:</label>
                                        <div>
                                            {country.map((item, index) => (
                                                <div key={index} className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`country-${item.id}`}
                                                        value={item.id}
                                                        checked={AddForm.values.eligible_country.includes(item.id)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                AddForm.setFieldValue('eligible_country', [...AddForm.values.eligible_country, item.id]);
                                                            } else {
                                                                AddForm.setFieldValue('eligible_country', AddForm.values.eligible_country.filter(id => id !== item.id));
                                                            }
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`country-${item.id}`}>{item.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                        {AddForm.touched.eligible_country && AddForm.errors.eligible_country ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.eligible_country}</div> : ''}
                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button ref={closemodal} type="button" className="btn btn-dark"/*  onClick={() => handleUpdatedetial()} */ data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">{/* {HandleName == "Add Plan" ? "Add" : "Update"} */} Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pf