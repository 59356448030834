import Swal from "sweetalert2"
import { deleteApi } from "../../service/employService"
import toast from "react-hot-toast"

export const HandleDeleteJobType = (id, GetJobType) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await deleteApi(`/delete-job-type/${id}`)

            if (response?.status) {
                Swal.fire(
                    'Deleted!',
                    'Job Type has been deleted Successfully.',
                    'success'
                )
                GetJobType();
            }
            else {
                toast.error(response.message)
            }
        }
    })
}

export const HandleDeleteSubJobType = (id, GetJobType) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await deleteApi(`/delete-sub-job-type/${id}`)
            if (response?.status) {
                Swal.fire(
                    'Deleted!',
                    'Sub-Job type has been deleted Successfully.',
                    'success'
                )
                GetJobType()
            }
            else {
                toast.error(response.message)
            }
        }
    })
}

export const HandleDeleteSubJobTypeLevel2 = (id, GetJobType) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await deleteApi(`/delete-l2-sub-job-type/${id}`)
            if (response?.status) {
                Swal.fire(
                    'Deleted!',
                    'L2 Sub-Job type has been deleted Successfully.',
                    'success'
                )
                GetJobType()
            }
            else {
                toast.error(response.message)
            }
        }
    })
}