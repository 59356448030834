import React from 'react'

const Liabilities = ({ employeeDetail }) => {

    const Total_Liabilities = parseFloat(employeeDetail?.mortgage_loan_balnce ? employeeDetail?.mortgage_loan_balnce : 0) + parseFloat(employeeDetail?.home_loan ? employeeDetail?.home_loan : 0) + parseFloat(employeeDetail?.otherloan_balance ? employeeDetail?.otherloan_balance : 0)

    return (
        <div style={{ marginTop: "30px" }}>
            <h3>Liabilities</h3>
            <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                <div className="media-list p-0">
                    <div className="media py-4 mt-0">
                        <div className="media-body">
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                <div className="w-70"> <label>Mortgage Loan Balance : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.mortgage_loan_balnce ? "$" + employeeDetail?.mortgage_loan_balnce : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                <div className="w-70"> <label>Home Loan :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.home_loan ? "$" + employeeDetail?.home_loan : '--'}</span> </div>
                            </div>
                        </div>
                    </div>
                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">

                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="material-icons mt-1" >&#xe84f;</i> </div>
                                <div className="w-70"> <label>Other Loan Balance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.otherloan_balance ? "$" + employeeDetail?.otherloan_balance : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="material-icons mt-1" >&#xe84f;</i> </div>
                                <div className="w-70"> <label>Total Liabilities :</label> <span className="font-weight-normal1 fs-14">{Total_Liabilities ? "$" + Total_Liabilities : 0}</span> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Liabilities


