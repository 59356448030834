import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { getApi, postApi } from '../service/adminService';

const EwaAgree = () => {
    const [message, setMessage] = useState("")
    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token

    const GetErrorMessage = async () => {
        // const response = await getErrorMessage(token)
        const response = await getApi("/ewa-display-message")

        //  console.log(response)
        if (response.status == true) {
            setMessage(response.data.display_message)
        } else {
            console.log(response.message)
        }
    }

    useEffect(() => {
        GetErrorMessage(token)
    }, [])
    const FormEdit = useFormik({
        initialValues: {
            message: message,

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            message: yup.string().required('Please Add Message').min(50, "Message must contain min 50 Character").max(200, "Message must contain max 200 Character"),


        }),
        onSubmit: async values => {
            const data = JSON.stringify({
                message: values.message,

            })
            // "/add-display-message"
            // const response = await EditAgreeMessage(token, data)
            const response = await postApi("/add-display-message", { message: values.message })

            if (response.status === true) {
                toast.success(response.message);


            } else {
                console.log('error', response);
                toast.error(response);
            }
        }
    });
    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">EWA Agree</h4>
                    </div>
                </div>
                <form className="row " id='registerForm' onSubmit={FormEdit.handleSubmit} >
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">EWA Agree Message</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <textarea placeholder='Enter Message' {...FormEdit.getFieldProps("message")} className="form-control formtestarea" />
                                        {FormEdit.touched.message && FormEdit.errors.message ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{FormEdit.errors.message}</div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1"> Save </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EwaAgree
