import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { EmployerRegistration, GetCountry } from '../../employer/service/employService';
import { Breadcrumbs } from '../../components';
import { AddEmployerOnboard, EditEmployerDetail, postApi } from '../service/adminService';

const AddEmployer = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [Img, setImg] = useState("")
    const [country, setCountry] = useState([]);
    const ids = useLocation();
    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token
    const regex = /[A-Za-z]/;
    const urlR = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    // console.log(ids, "Mission Completed ________________________--")
    const profileData = ids.state
    const [apiError, setApiError] = useState({
        email: '',
        mobile_number: ''
    })

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
        marginRight: '10px'
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '327px',
        marginTop: '16px',
    }

    const getCountry = async () => {
        const response = await GetCountry();
        console.log('response', response);
        if (response.status == true) {
            setCountry(response.data);
        } else {
            console.log("get Country data response", response);
        }
    }

    useEffect(() => {
        getCountry();
    }, [])

    const [logoValue, setDocValue] = useState(profileData?.logo || {})
    const navigate = useNavigate();
    // const uploadLogoFunc = async (e) => {
    //     // console.log('e.target.files[0]', e.target.files[0]);
    //     const file = e.target.files[0];
    //     setDocValue(file);
    // }

    const uploadLogoFunc = async (e = "") => {
        if (e.target && e.target.files) {
            const file = e.target.files[0] || "";
            if (Object.keys(logoValue).length === 0 && e.target.files[0] &&
                (e.target.files[0].type === "image/png" ||
                    e.target.files[0].type === "image/jpeg" ||
                    e.target.files[0].type === "image/svg+xml")) {
                setDocValue(file);
                form.setValues({
                    ...form.values,
                    logo: file,
                });
            } else {
                toast.error("Invalid file type. Please upload a PNG, JPEG, or SVG file.")
                form.setValues({
                    ...form.values,
                    logo: null,
                });
                setDocValue({});
            }

        }
        else {
            setDocValue({});
            form.setValues({
                ...form.values,
                logo: null,
            });
        }
    };


    const form = useFormik({
        initialValues: {
            company_name: profileData?.company_name || "",
            full_name: profileData?.full_name || "",
            address: profileData?.address || "",
            mobile_number: profileData?.mobile_number || "",
            email: profileData?.email || "",
            website: profileData?.website || "",
            pay_cycle: profileData?.pay_cycle || "",
            platform_fee: profileData?.platform_fee || "",
            country: profileData?.country || "",
            password: profileData?.password || "",
            confirmedPassword: profileData?.confirmedPassword || "",
            logo: profileData?.logo || "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            company_name: yup.string().required('Please Enter Company Name').matches(regex, 'Please Enter Only Character Values'),
            full_name: yup.string().required('Please Enter Full Name').matches(regex, 'Please Enter Only Character Values'),
            address: yup.string().required('Please Enter Address').matches(regex, 'Please Enter Only Character Values'),
            mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number values') */.min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Max Contain 15 Number"),
            email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
            // gst_number: yup.string().required('Please enter GST number'),
            platform_fee: yup.string().required('Please Enter Platform Fee'),
            website: yup.string().required('Please Enter Website').matches(urlR, 'Please Enter Valid Website URL'),
            country: yup.string().required('Please Select Country'),
            pay_cycle: yup.string().required('Please Select Pay Cycle'),
            password: profileData == null && yup.string().required('Please Enter Password')/* .matches(regex, 'Please Enter Only Character Values') */,
            confirmedPassword: profileData == null && yup.string().required('Please Enter Confirm Password')/* .matches(regex, 'Please Enter Only Character Values') */
                .oneOf([yup.ref("password"), null], "Password Must Be Same"),
            // logo: yup.string().required('Please Upload Your Logo'),
            logo: yup.string().nullable().required('Please upload your logo'),

        }),

        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("logo", profileData?.logo || Img);
            formData.append("company_name", form.getFieldProps('company_name').value);
            formData.append("full_name", form.getFieldProps('full_name').value);
            formData.append("address", form.getFieldProps('address').value);
            formData.append("mobile_number", form.getFieldProps('mobile_number').value);
            formData.append("country", form.getFieldProps('country').value);
            formData.append("email", form.getFieldProps('email').value);
            formData.append("platform_fee", form.getFieldProps('platform_fee').value);
            formData.append("pay_cycle", form.getFieldProps('pay_cycle').value);
            formData.append("website", form.getFieldProps('website').value);
            profileData == null && formData.append("password", form.getFieldProps('password').value);
            profileData == null && formData.append("confirmedPassword", form.getFieldProps('confirmedPassword').value);

            let response
            if (profileData == null) {
                // "/add-employer"
                // response = await AddEmployerOnboard(token, formData);
                response = await postApi("/add-employer", formData, true);

            } /* else {
                response = await EditEmployerDetail(token, formData, profileData?.user_id)
            } */
            if (response.status == true) {
                // toast.success(response.message);
                toast.success(response.message);
                navigate(process.env.REACT_APP_ADMIN_PRIFIX + '/employer')
            } else {
                if (response.message == 'mobile_number must be unique') {
                    toast.error('Mobile Number Must Be Unique');
                } else {
                    toast.error(response.message);
                }
            }
        },
    });

    const imageUpload = (e, path, s3_path, co_index = 0) => {
        setImg(profileData?.logo || e.target.files[0]);
    }


    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">

                    <h4 className="page-title mb-0 text-primary">
                        {profileData == null ?
                            <Breadcrumbs order={{ first: { name: "Employer", path: `${prefix}/employer` }, second: { name: "Add Employer" } }} />
                            :
                            <Breadcrumbs order={{ first: { name: "Employer", path: `${prefix}/employer` }, second: { name: "Edit Employer" } }} />
                        }
                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Company Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Company Name" {...form.getFieldProps("company_name")} />
                                </div>
                                {form.touched.company_name && form.errors.company_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.company_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Name Of Contact Person</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Name" {...form.getFieldProps("full_name")} />
                                </div>
                                {form.touched.full_name && form.errors.full_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Country</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <select className="form-control" name="country" {...form.getFieldProps("country")}>
                                        <option value="">Select Country</option>
                                        {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                                    </select>
                                </div>
                                {form.touched.country && form.errors.country ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.country}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Mobile Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Mobile Number" {...form.getFieldProps("mobile_number")} />
                                </div>
                                {form.touched.mobile_number && form.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Address</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Address" {...form.getFieldProps("address")} />
                                </div>
                                {form.touched.address && form.errors.address ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.address}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Pay Cycle</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <select className="form-control mb-0" name="pay_cycle" {...form.getFieldProps("pay_cycle")} >
                                        <option value=''>Select Pay Cycle</option>
                                        <option value='Weekly'>Weekly</option>
                                        <option value='Fortnightly'>Fortnightly</option>
                                        <option value='Monthly'>Monthly</option>
                                    </select>
                                </div>
                                {form.touched.pay_cycle && form.errors.pay_cycle ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.pay_cycle}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Platform Fee</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Platform Fee" {...form.getFieldProps("platform_fee")} />
                                </div>
                                {form.touched.platform_fee && form.errors.platform_fee ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.platform_fee}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Website</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Website" {...form.getFieldProps("website")} />
                                </div>
                                {form.touched.website && form.errors.website ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.website}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Enter Email" {...form.getFieldProps("email")} />
                                </div>
                                {form.touched.email && form.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>
                    {profileData == null && <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Password</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" {...form.getFieldProps("password")} type={values.showPassword ? "text" : "password"} name="password" placeholder="Password" id="password-field" />


                                    {!values.showPassword ?
                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                        :
                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                    }
                                </div>
                                {form.touched.password && form.errors.password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                            </div>
                        </div>
                    </div>}
                    {profileData == null && <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Confirm Password</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" {...form.getFieldProps("confirmedPassword")} type={valuesConfP.showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />

                                    {!valuesConfP.showConfPassword ?
                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                        :
                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                    }
                                </div>
                                {form.touched.confirmedPassword && form.errors.confirmedPassword ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirmedPassword}</div> : ''}
                            </div>
                        </div>
                    </div>}
                    <div className="input-group file-browser mb-0 widthHundred">
                        <input type="text" className="form-control browse-file" placeholder="Upload your logo" disabled />
                        <label className="input-group-text btn btn-primary"> Browse <input type="file" className="file-browserinput" onChangeCapture={uploadLogoFunc} /* onChange={(e) => imageUpload(e, 'dsa_doc', 'uploads/merchant/pancard')} */ style={{ display: 'none' }} multiple /> </label>
                        {form.touched.logo && form.errors.logo ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}
                    </div>
                    {profileData?.logo == null ? <span>
                        {logoValue && logoValue.name ?
                            <ul className="imgpreview-newbx">
                                <li className="registerboximg">

                                    <img src={URL.createObjectURL(logoValue)} alt="" />
                                    <Link to="" onClick={() => { setDocValue({}); uploadLogoFunc() }} className="upload__img-close"><i className="fa-solid fa-close fa-fw"></i></Link>
                                    <p>img preview name here</p>
                                </li>
                            </ul> : ""} </span> : <ul className="imgpreview-newbx">
                        <li className="registerboximg">

                            <img src={logoValue} alt="" />
                            {/* <Link to="" onClick={() => setDocValue({})} className="upload__img-close">
                                <i className="fa-solid fa-close fa-fw"></i></Link> */}
                            <p>img preview name here</p>
                        </li>
                    </ul>}

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Save Changes</button>
                    </div>


                </div>
            </form>

        </div>


    )
}

export default AddEmployer

