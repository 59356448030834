import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { getApi } from '../service/adminService';


const EditEmiplan = () => {
  const { id } = useParams();
  console.log(id)
  const Admin = JSON.parse(localStorage.getItem("admin"));
  const token = Admin.admin_token;
  console.log(id)
  console.log(token)

  const getEmiplan = async (id) => {

    // const response = await getEmiplanbyID(token , id);
    const response = await getApi(`/create-emi-plans-byId/${id}`);

    console.log(response);
    if (response.status == true) {

      //   setprofileData(response.data);
    } else {
      console.log("get employees data response", response);
    }
  }
  useEffect(() => {
    getEmiplan(token, id)
  })
  return (
    <>
      <h1>dfgggggggggggggggggggggggggggggggfd</h1>
      <h1>fgggggggggggggggggggggd</h1>
    </>
    // <div className="main-container container-fluid px-0">
    //     <div className="page-header">
    //       <div className="page-leftheader">
    //         <h4 className="page-title mb-0 text-primary">Update Lender Profile</h4>
    //       </div>
    //     </div>
    //     <form className="mt-5 row" id='registerForm' onSubmit={EditForm.handleSubmit}>
    //       <div className="row">

    //         <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
    //           <div className="form-group">
    //             <label className="form-label">Company Name</label>
    //             <div className="row g-xs">
    //               <div className="input-group">
    //                 <input type="text" className="form-control" placeholder="Enter Company Name" {...EditForm.getFieldProps("company_name")} />
    //               </div>
    //               {/* {EditForm.touched.company_name && EditForm.errors.company_name ?
    //                 <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.company_name}</div> : ''} */}
    //             </div>
    //           </div>
    //         </div>

    //         <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
    //           <div className="form-group">
    //             <label className="form-label">Name Of Contact Person</label>
    //             <div className="row g-xs">
    //               <div className="input-group">
    //                 <input type="text" className="form-control" placeholder="Enter name" {...EditForm.getFieldProps("full_name")} />
    //               </div>
    //               {/* {EditForm.touched.full_name && EditForm.errors.full_name ?
    //                 <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''} */}
    //             </div>
    //           </div>
    //         </div>

    //         <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
    //           <div className="form-group">
    //             <label className="form-label">Country</label>
    //             <div className="row g-xs">
    //               <select className="form-control" name="country" {...EditForm.getFieldProps("country")}>
    //                 <option value="">Select Country</option>
    //                 {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

    //               </select>
    //               {/* {EditForm.touched.country && EditForm.errors.country ?
    //                 <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.country}</div> : ''} */}
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
    //           <div className="form-group">
    //             <label className="form-label">Mobile Number</label>
    //             <div className="row g-xs">
    //               <div className="input-group">
    //                 <input type="text" className="form-control" placeholder="Enter Mobile number" {...EditForm.getFieldProps("mobile_number")} />
    //               </div>
    //               {/* {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
    //                 <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''} */}
    //             </div>
    //           </div>
    //         </div>

    //         <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
    //           <div className="form-group">
    //             <label className="form-label">Address</label>
    //             <div className="row g-xs">
    //               <div className="input-group">
    //                 <input type="text" className="form-control" placeholder="Enter Address" {...EditForm.getFieldProps("address")} />
    //               </div>
    //               {/* {EditForm.touched.address && EditForm.errors.address ?
    //                 <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.address}</div> : ''} */}
    //             </div>
    //           </div>
    //         </div>

    //         <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
    //           <div className="form-group">
    //             <label className="form-label">Gst Number</label>
    //             <div className="row g-xs">
    //               <div className="input-group">
    //                 <input type="text" className="form-control" placeholder="Enter GST Number" {...EditForm.getFieldProps("gst_number")} />
    //               </div>
    //               {/* {EditForm.touched.gst_number && EditForm.errors.gst_number ?
    //                 <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.gst_number}</div> : ''} */}
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
    //           <div className="form-group">
    //             <label className="form-label">Website</label>
    //             <div className="row g-xs">
    //               <div className="input-group">
    //                 <input type="text" className="form-control" placeholder="Enter Website" {...EditForm.getFieldProps("website")} />
    //               </div>
    //               {/* {EditForm.touched.website && EditForm.errors.website ?
    //                 <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.website}</div> : ''} */}
    //             </div>
    //           </div>
    //         </div>    

    //         <div className="col-md-12">
    //           <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1">Submit</button>
    //         </div>


    //       </div>

    //     </form>




    //   </div>
  )
}

export default EditEmiplan
