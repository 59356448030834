
// const EmployerRegistration = async (data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/employer-registration", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:1 ~ EmployerRegistration ~ error:", error)
//     }
// }

// const EmployerLoign = async (data) => {
//     try {
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/login", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:17 ~ EmployerLoign ~ error:", error)
//     }

// }

// const Employerforgot = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 // "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         // const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/add-employee", requestOptions );
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/send-otp-forgot-pass", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:37 ~ Employerforgot ~ error: ", error)
//     }

// }

// const EmployerVerifyOTP = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 // "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         // const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/add-employee", requestOptions );
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/verify-otp", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:60 ~ EmployerVerifyOTP ~ error:", error)
//     }

// }
// const EmployerResetPassword = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 // "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         // const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/add-employee", requestOptions );
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/reset-password", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:82 ~ EmployerResetPassword ~ error: ", error)
//     }

// }

// const AddEmployees = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/add-employee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:105 ~ AddEmployees ~ error:", error)
//     }

// }

// const addNFCdetails = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/add-nfc-detail", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:128 ~ addNFCdetails ~ error:", error)
//     }

// }
// const addUsers = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/add-sub-users", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:128 ~ addNFCdetails ~ error:addUserrole", error)
//     }

// }

// const addUserrole = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/add-role-permission", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:128 ~ addNFCdetails ~ error:", error)
//     }

// }
// const EditUserrole = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/update-role-permission", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:128 ~ addNFCdetails ~ error:", error)
//     }

// }
// const UpdateemployeeDetail = async (token = "", datas) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: datas,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/update-employee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:150 ~ UpdateemployeeDetail ~ error:", error)
//     }

// }

// const GetEmployees = async (token = "", empId, pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-employee?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:173 ~ GetEmployees ~ error:", error)
//     }

// }



// const GetAttendancebyID = async (token = "", id, date, year, date_Format) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-all-attendance/${id}?month=${date}&year=${year}&time_zone=${date_Format}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:213 ~ GetAttendancebyID ~ error:", error)
//     }

// }

// const GetAttendancelog = async (token = "", id, customer_id, date_Format) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/attendance-logs/${id}?cst_id=${customer_id}&time_zone=${date_Format}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:213 ~ GetAttendancebyID ~ error:", error)
//     }

// }



// const GetEmployeesExport = async (token="", id) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/export-settlement/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }

// }

// const GetEmployeesRepayment = async (token = "", empId, pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-employee-repayment?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         // console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:254 ~ GetEmployeesRepayment ~ error: ", error)
//     }

// }

// const GetManageUser = async (token = "", pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-sub-users?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         // console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:254 ~ GetEmployeesRepayment ~ error: ", error)
//     }

// }
// const GetUsermanager = async (token = "", pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-role-permission?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         // console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:254 ~ GetEmployeesRepayment ~ error: ", error)
//     }

// }

// const GetUsermanagers = async (token = "") => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-role-permission`, requestOptions);
//         const result = await res.json();
//         // console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:254 ~ GetEmployeesRepayment ~ error: ", error)
//     }

// }


// const GetEWARepayment = async (token = "", pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/ewa-repayment-list?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:276 ~ GetEWARepayment ~ error: ", error)
//     }

// }

// const GetsettlementDetail = async (token = "", pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/getsettlements?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:297 ~ GetsettlementDetail ~ error: ", error)
//     }

// }

// const GetpaidEWAtransaction = async (token = "", pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/ewa-payment-history?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:318 ~ GetpaidEWAtransaction ~ error: ", error)
//     }

// }


// const Getpaidtransaction = async (token = "", pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/getsettlements?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error: Getpaidtransaction", error)
//     }

// }


// const Getsettlementcycle = async (token = "", id, pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/getSettlmentDetail/${id}?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:362 ~ Getsettlementcycle ~ error: ", error)
//     }

// }

// const GetsettlementEWA = async (token = "", id, pagesCount, search, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/ewa-payment-history-detail/${id}?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:383 ~ GetsettlementEWA ~ error: ", error)
//     }

// }


// const AddSettlementlender = async (data) => {
//     try {
//         const employer = JSON.parse(localStorage.getItem("employer"));
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //   "Content-Type": "application/json",
//                 "Authorization": `Bearer ${employer.employer_token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/pay-to-lender", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:405 ~ AddSettlementlender ~ error: ", error)
//     }

// }

// const AddSettlementEWA = async (data) => {
//     try {
//         const employer = JSON.parse(localStorage.getItem("employer"));
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //   "Content-Type": "application/json",
//                 "Authorization": `Bearer ${employer.employer_token}`
//             },
//             // headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/ewa-pay-to-thirdroc", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:432 ~ AddSettlementEWA ~ error:", error)
//     }

// }


// const GetEmployeesDetails = async (token = "", empId) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         // const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-employee/?pageNumber=${pageNumber}&search=${searchVlu}&pageSize=${pageSize}`, requestOptions);
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-employeebyid/${empId}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:460 ~ GetEmployeesDetails ~ error:", error)
//     }

// }

// const GetEmployee = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-employeebyid/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:480 ~ GetEmployee ~ error:", error)
//     }

// }


// const GetNFCDetail = async (token = "", search = "", pagesCount = "", sortedBy = "", orderBy = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-nfc-list?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         // console.log(res)
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:500 ~ GetCountry ~ error:", error)
//     }
// }

// const GetNFCDetails = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-nfc-list`, requestOptions);
//         // console.log(res)
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:500 ~ GetCountry ~ error:", error)
//     }
// }

// const EditEmployees = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/update-employee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:520 ~ EditEmployees ~ error:", error)
//     }

// }
// const AssignNFC = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/edit-employee-nfc-detail", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:520 ~ EditEmployees ~ error:", error)
//     }

// }

// const DeleteEmployees = async (token = "", customer_id) => {
//     try {
//         console.log('customer_id',);
//         let raw = JSON.stringify({ customer_id });
//         console.log('raw', raw);
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: raw,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/deleteEmployee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:542 ~ DeleteEmployees ~ error:", error)
//     }

// }



// const GetProfile = async (token = "") => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },

//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/get-profile", requestOptions);

//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }
// }

// const UpdateProfile = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/edit_profile", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }
// }

// const ChangeUserPassword = async (data, token = "") => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/changePassword", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }
// }


// const uploadAttendance = async (data, token = "") => {
//     console.log('data upload attendance', data);
//     console.log('upload-data', token);

//     // let request = new FormData();
//     // request.append("file", data)
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "mimeType": "multipart/form-data"
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/attendance-upload", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }
// }

// const GetDashboard = async (token) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/dashboard-employer`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }

// }
// const UploadLogo = async (token, data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/upload-logo", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }
// }

// const employeeStatus = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/edit-employee-nfc-status", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:754 ~ employeeStatus ~ error: ", error)
//     }

// }

// const Time_Schedule_Update = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify(data),
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/update-attendance-v2", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:754 ~ employeeStatus ~ error: ", error)
//     }

// }



// const UpdateManageUser = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/update-sub-users", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const deleteUser = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/delete-sub-users/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }
// const deleteRole = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/delete-role-permission/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const GetDepartmentDetail = async (token = "", search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/get-nfc-site-list?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         // console.log(res)
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:500 ~ GetCountry ~ error:", error)
//     }
// }

// const addDepartmentdetails = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/add-nfc-site", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:128 ~ addNFCdetails ~ error:", error)
//     }

// }

// const deleteDeparment = async (token, id) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/delete-nfc-site/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ error:", error)
//     }
// }

// const EditNFCSites = async (token = "", data) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/edit-nfc-site`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: EmployerService.js:1166 ~ EditNFCDetail ~ error:", error)
//     }
// }

// const UpdateAssignRoletoEmployee = async (token = "", data) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/assign-role`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: EmployerService.js:1166 ~ EditNFCDetail ~ error:UpdateAssignRoletoEmployee", error)
//     }
// }

// const GetRoleandchild = async (token = "") => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/role-and-child-role`, requestOptions);
//         const result = await res.json();
//         // console.log(res)
//         return result;

//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:254 ~ GetEmployeesRepayment ~ error: ", error)
//     }

// }
// const GetAttendancelist = async (token = "", page_number, search, sortedBy, orderBy, startDate, endDate, date_Format, Departmentname) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/attendance-list?page_number=${page_number}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}&start_date=${startDate}&end_date=${endDate}&userTimezone=${date_Format}&department=${Departmentname}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:193 ~ GetAttendancelist ~ error:", error)
//     }
// }

// const changeStatus = async (token, id, date_Format) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/mark-attendance/${id}?time_zone=${date_Format}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ Merchant ~ error: changeStatus", error)
//     }

// }

const GetCountry = async (token = "") => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'GET',
            headers: {
                'Timezone': Timezone
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + "/get-country", requestOptions);
        // console.log(res)
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:500 ~ GetCountry ~ error:", error)
    }
}
const GetPermission = async (token = "") => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Timezone': Timezone
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/my-permissions/", requestOptions);
        // console.log(res)
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:500 ~ GetCountry ~ error:", error)
    }
}

const uploadEmployees = async (data, token = "") => {
    // console.log('data uploadEmployees', data);
    // console.log('upload-data', token);
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    // let request = new FormData();
    // request.append("file", data)
    try {
        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "mimeType": "multipart/form-data",
                'Timezone': Timezone
            },
            body: data,
            redirect: 'follow'
        };
        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + "/bulk-employee-upload", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
    }
}

const getApi = (path, parameter = '', object = {}) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        // const admin = JSON.parse(localStorage.getItem("admin"));
        const employer = JSON.parse(localStorage.getItem("employer"));
        let headers = { 'Authorization': `Bearer ${employer.employer_token}` }
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone, }
        if (object) {
            headers = { ...headers, ...object }
        }

        const queryParams = parameter ? '?' + new URLSearchParams(parameter) : '';

        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + '' + path + queryParams, {
            method: 'GET',
            headers: headers
        })
            .then((response) => {

                if (!response.ok) {
                    if (response.headers.get('Content-Type').includes('application/json')) {
                        response.json().then(json => {
                            reject(json)
                        })

                    } else {
                        resolve(response.text());
                    }

                } else {
                    if (response.headers.get('Content-Type').includes('application/json')) {
                        response.json().then(json => {

                            if (path !== '/dashboard-employer' && json.message == 'Payment due') {
                                localStorage.removeItem("employer");
                            }

                            resolve(json)
                        })
                    }
                    else {
                        resolve(response.text());
                    }

                }
            }).catch((error) => {
                console.log(error)
            });
    });
}

const postApi = (path, body = {}, is_formData = false, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("employer"))?.employer_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        if (is_formData == false) {
            headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        } else {
            headers = { ...headers, 'Timezone': Timezone /* 'Content-Type': 'multipart/form-data' */ }
        }
        if (object) {
            headers = { ...headers, ...object, 'Timezone': Timezone }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + '' + path, {
            method: 'POST',
            headers: headers,
            body: is_formData ? body : JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

const deleteApi = (path, body = {}, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("employer"))?.employer_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }

        if (object) {
            headers = { ...headers, ...object }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + '' + path, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

const posttoken = (path, body = {}, is_formData = false, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("employer"))?.employer_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        if (is_formData == false) {
            headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        } else {
            headers = { ...headers, 'Timezone': Timezone /* 'Content-Type': 'multipart/form-data' */ }
        }
        // if (object) {
        //     headers = { ...headers, ...object }
        // }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + '' + path, {
            method: 'POST',
            headers: headers,
            body: is_formData ? body : JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

const Syncdata = async (id, token) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone, 'Authorization': `Bearer ${token}`
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + `/add-referral-employee/${id}`, requestOptions);
        // console.log(res)
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:500 ~ GetCountry ~ error:", error)
    }
}

export { Syncdata, getApi, postApi, posttoken, deleteApi, uploadEmployees, GetPermission, GetCountry };

