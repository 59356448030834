import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router'
import { GetNFCDetail, Getsettlementcycle, getApi } from '../../service/employService';
import { CSVLink } from 'react-csv';
import { Breadcrumbs } from '../../../components';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Oval } from 'react-loader-spinner';
import PaginationComponent from '../../../PaginationComponent';
import Loader from '../../../components/Loader/loader';
import { Toaster, toast } from 'react-hot-toast';
import { TimeZone } from '../../../components/TimeZone/TimeZone';


const ViewNFC = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const employer = JSON.parse(localStorage.getItem("employer"));
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("nfc_id")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate();
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX


    const GetNFC = async (token) => {
        try {
            const response = await getApi("/get-nfc-list", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
            // const response = await GetNFCDetail(token, search , pagesCount ,sortedBy , orderBy);
            console.log('response', response);
            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data);
                    setTotalPagesCount(response.count)
                    //setTotalPagesCount(response.data["count"])
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetNFC(employer.employer_token);
    }, [search, pagesCount, sortedBy, orderBy]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        // getAllCityData(admin.admin_token, pagesCount, search);
    }

    return (

        <>
            <Toaster position="top-right" reverseOrder={false} />

            <div>
                <div className="main-container container-fluid px-0">
                    <div className="page-header mb-3">
                        {/* <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">City({dataArray.length})</h4>
                            </div> */}
                        <div className="page-leftheader">
                            {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                            <h6 className="page-title mb-0 text-primary">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >NFC</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">View NFC({dataArray.length})</li>
                                    </ol>
                                </nav>
                            </h6>
                        </div>

                    </div>
                    <div className="row">

                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">NFC ID  {sortedBy == "nfc_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('nfc_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('nfc_id') }}> </i>}</th>
                                                    <th className="font-weight-bold">Tag Name  {sortedBy == "site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }}> </i>}</th>
                                                    <th className="font-weight-bold " > Site Name  {sortedBy == "other" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('other') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('other') }}> </i>}</th>
                                                    <th className="font-weight-bold " > Created Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}> </i>}</th>
                                                    <th className="font-weight-bold " > Updated Date  {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}> </i>}</th>
                                                </tr>
                                            </thead>

                                            {loader == true ? <tbody>
                                                <td />
                                                <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                    <Loader />
                                                </div> </tbody> : <>
                                                <tbody>
                                                    {dataArray.length > 0 && dataArray.map((option, index) => {
                                                        let serial_num = ((10 * (pagesCount - 1)) + index + 1);

                                                        return (
                                                            <tr key={serial_num} >
                                                                <td><span className="font-weight-normal1">{option.nfc_id ? option.nfc_id : "-"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.site_name ? option.site_name : "-"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.location ? option.location : "-"}</span></td>
                                                                <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                <td><span className="font-weight-normal1">{TimeZone(option.updated_at).timeZone}</span></td>
                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Transaction Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody></>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && dataArray.length > 0 ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>
            </div>


        </>

    )
}



export default ViewNFC;
