import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { postApi } from '../../service/MerchantService';
import { useFormik } from "formik";

const StoreAddcounter = () => {
  const navigate = useNavigate();
  const Form = useFormik({
    initialValues: {
      name: "",
      mobile_number: "",
      counter_number: "",
      password: "",
      confirm_password: ""
    },
    validationSchema: yup.object({
      name: yup.string().required('Please Enter Name'),
      mobile_number: yup.string().required('Please Enter  Mobile Number').min(7, "Mobile number must contain 7 number").max(15, "Mobile number must contain 15 number"),
      counter_number: yup.string().required('Please Enter Counter Number'),
      password: yup.string().required('Please Enter Password'),
      confirm_password: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("password"), null], "Password Must Be Same"),
    }),
    onSubmit: async values => {
      console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
      const response = await postApi("/add-counter", {
        store_id: "",
        name: values.name,
        mobile_number: values.mobile_number,
        counter_number: values.counter_number,
        password: values.password,
        confirm_password: values.confirm_password,
        wrong_message: ""
      })
      if (response.status == false) {
        toast.error(response.message);
      }
      if (response.status === true) {
        // toast.success(response.message); 
        toast.success("Counter Added Successfully");
        navigate(prefix + '/counter');

      }
    }
  });

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [valuesConfP, setValuesConfP] = React.useState({
    password: "",
    showConfPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfPassword = () => {
    setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
  };
  const eyeBtnStyle = {
    position: 'absolute',
    marginLeft: '0px',
    marginTop: '0px',
  }

  const eyeBtnStyle1 = {
    position: 'absolute',
    marginLeft: '330px',
    marginTop: '12px',

  }

  const eyeBtnStyle2 = {
    position: 'absolute',
    marginLeft: '0px',
    marginTop: '0px',
  }

  const eyeBtnStyle3 = {
    position: 'absolute',
    marginLeft: '330px',
    marginTop: '12px',
  }

  const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;


  return (

    <div className="main-container container-fluid px-0">
      <div className="page-header">
        <div className="page-leftheader">
          <h4 className="page-title mb-0 text-primary">Add Counter</h4>
        </div>
      </div>
      <form className="row" id='registerForm' onSubmit={Form.handleSubmit}>
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
            <div className="form-group">
              <label className="form-label">Mobile Number</label>
              <div className="row g-xs">
                <div className="input-group">
                  <input type="text" {...Form.getFieldProps("mobile_number")} className="form-control" placeholder="Enter Mobile Number" />
                </div>
              </div>
              {Form.touched.mobile_number && Form.errors.mobile_number ?
                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.mobile_number}</div> : ''}
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
            <div className="form-group">
              <label className="form-label">Name</label>
              <div className="row g-xs">
                <div className="input-group">
                  <input type="text" {...Form.getFieldProps("name")} className="form-control" placeholder="Enter Name" />
                </div>
              </div>
              {Form.touched.name && Form.errors.name ?
                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.name}</div> : ''}
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
            <div className="form-group">
              <label className="form-label">Counter Number</label>
              <div className="row g-xs">
                <div className="input-group">
                  <input type="text" {...Form.getFieldProps("counter_number")} className="form-control" placeholder="Enter Counter Number" />
                </div>
              </div>
              {Form.touched.counter_number && Form.errors.counter_number ?
                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.counter_number}</div> : ''}
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
            <div className="form-group">
              <label className="form-label">Enter Password</label>
              <div className="row g-xs">
                <div className="input-group">
                  <input className="form-control" type={values.showPassword ? "text" : "password"} placeholder="Enter Password"
                    autoComplete="new-password"
                    name="password" {...Form.getFieldProps("password")} />
                  {!values.showPassword ?
                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                    :
                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                  }
                </div>
              </div>
              {Form.touched.password && Form.errors.password ?
                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.password}</div> : ''}
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
            <div className="form-group">
              <label className="form-label">Confirm Password</label>
              <div className="row g-xs">
                <div className="input-group">
                  <input className="form-control" type={valuesConfP.showConfPassword ? "text" : "password"} placeholder="Confirm Password"
                    name="confirmedPassword"  {...Form.getFieldProps("confirm_password")} />

                  {!valuesConfP.showConfPassword ?
                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                    :
                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                  }
                </div>
              </div>
              {Form.touched.confirm_password && Form.errors.confirm_password ?
                <div className="invalid-feedback" style={{ display: "block" }}>{Form.errors.confirm_password}</div> : ''}
            </div>
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1"> Save </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default StoreAddcounter