import { Link } from 'react-router-dom';

import '../website/assets/css/bootstrap.css';
import '../website/assets/css/style.css';
import '../website/assets/css/line-awesome.min.css';
import '../website/assets/css/newhome.css';
const Header = () => {


   return (
      <>
         <section className="header header--2">
            <div className="top_bar top--bar2 d-flex align-items-center bg-primary">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="d-flex topbar_content justify-content-between">

                           <div className="top_bar--info  d-lg-block align-self-center">
                              <ul>
                                 <li>
                                    <span className="la la-envelope"></span>
                                    <p>support@thirdroc.com</p>
                                 </li>
                              </ul>
                           </div>
                           <div className="top_bar--social">
                              <ul>
                                 <li><a href="https://www.linkedin.com/company/thirdroc/" target='blank'><span className="fab fa-linkedin-in"></span></a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="menu_area menu1">
               <div className="container">
                  <nav className="navbar navbar-expand-lg navbar-light bg-light px-0">
                     <Link className="navbar-brand" to="/"><img src="website/assets/img/logo-thirdroc.png" className="w-65-ogo " alt="" /></Link>
                     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="la la-bars"></span>
                     </button>
                     <div className="collapse navbar-collapse order-md-1" id="navbarSupportedContent2">
                        <ul className="navbar-nav ml-auto">
                           <li className="nav-item">
                              <Link className="nav-link" to="/">Home
                                 <span className="sr-only">(current)</span>
                              </Link>
                           </li>
                           <li className="nav-item">
                              <Link className="nav-link" to="/platform">Platform</Link>
                           </li>
                           <li className="nav-item dropdown">
                              <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Solutions <i className="las la-angle-down"></i></a>
                              <div className="dropdown-menu">
                                 <Link className="dropdown-item" to="/ewa">EWA</Link>
                                 <Link className="dropdown-item" to="/bnpl">BNPL</Link>
                                 <Link className="dropdown-item" to="/api">API</Link>
                                 <Link className="dropdown-item" to="/digital-lending">Digital Lending</Link>
                              </div>
                           </li>
                           <li className="nav-item dropdown">
                              <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Company <i className="las la-angle-down"></i></a>
                              <div className="dropdown-menu">
                                 <Link className="dropdown-item" to="/know-us">Know Us</Link>
                              </div>
                           </li>
                           {/* <li className="nav-item">
                              <Link className="nav-link" to="/account_deletion">Account Deletion
                              </Link>
                           </li> */}
                        </ul>
                     </div>
                  </nav>
               </div>
            </div>
         </section>
      </>
   )
}

export default Header;