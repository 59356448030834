import React, { useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import Loader from '../../../components/Loader/loader';
import Search from '../../../components/Search/Search';
import PaginationComponent from '../../../PaginationComponent';
import { getApi } from '../../service/employService';

const ManageEwaAdvance = () => {
    const [ValidPagination, setValidPagination] = useState(false)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [adminData, setDsaData] = useState(localStorage.getItem("admin"));
    const [token, setToken] = useState(JSON.parse(adminData)?.admin_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    // const [transactionPrimaryId, setTransactionPrimaryId] = useState([]);
    const [employeeDataID, setemployeeDataID] = useState("")
    const [Count, setCount] = useState();
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [detail, setDetail] = useState([]);
    // const [isPinUpdated, setIsPinUpdated] = useState()

    const navigate = useNavigate();
    const ViewLoanCases = async (token) => {
        const response = await getApi(`/ewa-advance-application`, { lender_id: employeeDataID, search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setCount(response.data.count)
                // setIsPinUpdated(response?.isPinUpdated)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        ViewLoanCases(token, search, pagesCount)

    }, [search, pagesCount, sortedBy, orderBy, employeeDataID])


    const getDetail = async (user_id) => {
        const response = await getApi(`/get-lender/${user_id}`)
        console.log('response getDetail', response);
        if (response.data) {
            setDetail(response.data);

        } else {
            toast.error(response.message);
        }
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    // const selectTransactions = async (e, id) => {
    //     let checkArray = await multippleArray;
    //     let rowIndex = checkArray.findIndex(v => v == id);
    //     if (rowIndex > -1) {

    //         checkArray.splice(rowIndex, 1);

    //     } else {
    //         checkArray.push(id);
    //     }

    //     setMultippleArray(checkArray);
    //     setDataArray((old_data) => [...old_data]);
    //     if (dataArray.length == multippleArray.length) {
    //         setIsChecked(true);
    //     }
    //     if (dataArray.length != multippleArray.length) {
    //         setIsChecked(false);
    //     }
    // }

    // const checheked = (item) => {
    //     return multippleArray.includes(item) ? 1 : 0;
    // }

    const handleNavigate = () => {
        navigate(`${prefix}/repayment_report`);
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Manage EWA Advance {Count ? "(" + Count + ")" : ""}</h4>
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <button className="btn btn-primary" onClick={() => handleNavigate()}><span className="font-weight-normal1">Report</span></button> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />


                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>

                                                        {/* {dataArray.length > 0 &&
                                                            <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>} */}

                                                        <th className="font-weight-bold">Loan ID</th>
                                                        <th className="font-weight-bold">Borrower Name  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Lender Detail {sortedBy == "lender_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_id') }}> </i>}</th>
                                                        <th className='font-weight-bold'>Require Amount  {sortedBy == "require_loan_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('require_loan_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('require_loan_amount') }}> </i>}</th>
                                                        <th className='font-weight-bold'>Disbursed Amount  {sortedBy == "disbursed_loan_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('disbursed_loan_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('disbursed_loan_amount') }}> </i>}</th>
                                                        <th className="font-weight-bold">EWA Advance Type  {sortedBy == "loan_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_type') }}> </i>}</th>
                                                        <th className="font-weight-bold">EWA Advance Status  {sortedBy == "loan_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('loan_status') }}> </i>}</th>
                                                        <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}> </i>}</th>
                                                        <th className="font-weight-bold">Docs</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><td /><td /><td /><td /><Loader /></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { lender_id, user_loan_id, loan_status, disbursed_loan_amount, loan_type, last_name, first_name, mobile_number, require_loan_amount } = option;
                                                                const rowStyle = lender_id == null ? { backgroundColor: '#eaacac' } : {};

                                                                return (
                                                                    <tr key={serial_num} style={rowStyle}>
                                                                        {/* {lender_id == null ? <td><input type="checkbox" name={option?.user_loan_id} checked={checheked(option.user_loan_id) === 1 ? true : false} onChange={(event) => selectTransactions(event, option.user_loan_id)} /></td> : <td />} */}
                                                                        <td><span className="font-weight-normal1"> {option?.user_loan_id ? user_loan_id : "- -"} </span></td>
                                                                        <td><Link className="text-primary" to={{ pathname: `${prefix}/viewemployee_detail/${user_loan_id}`, state: { user_loan_id } }}>
                                                                            <span className="font-weight-normal1">{first_name ? first_name : "--"}  {last_name ? last_name : ""}<br />{mobile_number}</span></Link></td>

                                                                        <td>{lender_id != null ?
                                                                            <Link to="" onClick={() => getDetail(lender_id)}
                                                                                data-bs-target="#applicaiton-report" data-bs-toggle="modal" >
                                                                                <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>
                                                                                    {option.lenderData?.company_name ? option.lenderData?.company_name : "--"} <br />
                                                                                    {option.lenderData?.full_name ? option.lenderData?.full_name : ""}<br />
                                                                                    {option.lenderData?.mobile_number ? option.lenderData?.mobile_number : ""}</span></Link> :
                                                                            <span>--</span>}</td>
                                                                        <td className='fw-bold text-bold'>{option?.require_loan_amount ? require_loan_amount : "-"}</td>
                                                                        <td className='fw-bold text-success'>{option?.disbursed_loan_amount ? disbursed_loan_amount : "-"}</td>
                                                                        <td ><span className="font-weight-normal1">{option?.loan_type == "personal_loan" ? "Personal" : option.loan_type == "business_loan" ? "Business" : option.loan_type}</span></td>
                                                                        <td><span className="font-weight-normal1"> {/* option?.loan_status ? loan_status?.status_name : "- -" */
                                                                            option?.loan_status?.status_name === "Disbursal"
                                                                                ? <span>Disbursed</span>
                                                                                : <span>{option?.loan_status?.status_name} </span>}</span></td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                        {/* <td><button className="btn btn-primary" onClick={() => handleNavigate(user_loan_id)}><span className="font-weight-normal1">Borrower Details</span></button></td> */}
                                                                        <td><span className="font-weight-normal1">   <div class="dropdown">
                                                                            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                View Docs
                                                                            </button>
                                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                                {/* <button class="dropdown-item " type="button">
                                                                                            <a className="text-dark" href={option.agreement_signature_1} download><i className='fa fa-download me-1 text-primary' />Employee Signature 1</a></button>
                                                                                        <button class="dropdown-item" type="button">
                                                                                            <a className="text-dark" href={option.agreement_signature_2} download><i className='fa fa-download me-1 text-primary' />Employee Signature 2</a></button>
                                                                                        {option?.employer_signature && <button class="dropdown-item" type="button">
                                                                                            <a className="text-dark" href={option.employer_signature} download><i className='fa fa-download me-1 text-primary' />Employer Signature </a></button>} */}
                                                                                <button class="dropdown-item " type="button">
                                                                                    <a className="text-dark" href={option.agreement_doc_1} download><i className='fa fa-download me-1 text-primary' />Employee Agreement Doc 1</a></button>
                                                                                <button class="dropdown-item" type="button">
                                                                                    <a className="text-dark" href={option.agreement_doc_2} download><i className='fa fa-download me-1 text-primary' />Employee Agreement Doc 2</a></button>
                                                                            </div>
                                                                        </div></span></td>

                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}


                        </div>
                    </div>


                </div>
            }
        </>
    )
}

export default ManageEwaAdvance
