
// const MerchantRegistration = async (data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             body: data,
//             redirect: 'follow'
//         };
//         // const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/lender-registration", requestOptions);
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/merchant-registration", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error);
//     }
// }

// const MerchantLoign = async (data) => {
//     try {
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/login", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ MerchantRegistration ~ error:", error)
//     }

// }

// const Merchantforgot = async (data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 // "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/forgot-password", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ Merchant ~ error: ", error)
//     }

// }

// const CounterChangePasword = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/user-change-password", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ Merchant ~ error: ", error)
//     }

// }
// const MerchantVerifyOTP = async (data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 // "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/forgot-password-verify-otp", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ Merchant ~ error: ", error)
//     }

// }


// const GetEmployer = async (token = "", pageNumber = 1, pageSize = 5, searchVlu = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/all-employees?page_number=${pageNumber}&search=${searchVlu}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const AddEmployees = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/add-employee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const AddStores = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/add-store", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }


// const addCounter = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/update-counter", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const addmerchantCounter = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/add-counter", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const deleteCounter = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/delete-counter/${data}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const addCounterr = async (token, data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/add-store-counter", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀  file: employService.js:31  LenderRegistration ~ error:", error)
//     }
// }
// const GetEmployee = async (token = "", id) => {
//     try {
//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/get-employeebyid/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const DeleteEmployees = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/deleteEmployee", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const UpdateAccount = async (token = "", data) => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/update-bank-account", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const DeleteAccount = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/delete-bank-account/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: MerchantService.js:157 ~ DeleteAccount ~ error:", error)
//     }

// }

// const GetProfile = async (token = "") => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/get-profile", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const UpdateProfile = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/edit_profile", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }

// const UpdateStoreDetail = async (token = "", data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/edit-store", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
// }


// const UploadLogo = async (token, data) => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/upload-logo", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }
// }



// const GetTransctions = async (token, store_id, counter_id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/merchant-transctions?store_id=${store_id}&counter_id=${counter_id}&search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const Getstorelistdetail = async (token) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/txn-store-list", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const Getcounterlistdetail = async (token, store_id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/txn-counter-list?store_id=${store_id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const GetSettlement = async (token = "", page_number, search, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/merchant-settlement?page_number=${page_number}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }
// const GetStore = async (token, pagesCount, search, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/get-allStores?page_number=${pagesCount}&search=${search}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const GetMerchantrDetailbyID = async (token = "", id) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/get-store-byId/" + id, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
//     }

// }


// const GetgetCounterDetail = async (token = "", id, search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/counter-list/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
//     }

// }

// const GetgetCounterDetails = async (token = "", search, pagesCount, sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/counter-list/0?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:39 ~ GetEmployers ~ error:", error)
//     }

// }




// const GetDashboard = async (token) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/dashboard-merchant`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const AddDocuments = async (data, token = "") => {
//     try {

//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/add-bank-account", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:UpdateemployeeDetail", error)
//     }

// }
// const Viewtransaction = async (token = "", id, txn_from) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/settlement-detail/${id}?txn_from=${txn_from}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }
//     // GetReconciliation(token , search , pagesCount , sortedBy , orderBy)
// }

// const GetReconciliation = async (token, search, pagesCount, sortedBy, orderBy) => {
//     try {
//         let requestOptions = {
//             method: "GET",
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: "follow"
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/reconciliation-list?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:699 ~ GetReconciliation ~ error:", error)
//     }
// }

// const Promotiondetail = async (token = "", pageNumber = 1, searchVlu = "", sortedBy, orderBy) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/promotion-banner-list?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: MerchantService.js:39 ~ GetEmployers ~ error:", error)
//     }

// }

// const deletePromotion = async (token, id) => {
//     try {

//         let requestOptions = {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             // body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/delete-promotion-banner/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: MerchantService.js:31 ~  error:", error)
//     }
// }

// const EditPromotionDetail = async (token = "", data, id) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 // "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/edit-promotion/${id}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: MerchantService.js:1166 ~ EditMerchantDetail ~ error:", error)
//     }
// }

// const Addpromotion = async (token, data) => {
//     try {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var requestOptions = {
//             method: 'POST',
//             headers: {

//                 //  "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`
//             },
//             body: data,
//             redirect: 'follow'
//         };
//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/add-promotion", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: adminService.js:31 ~ PaytoMerchant ~ error:", error)
//     }

// }

// const GetGraphData = async (token, days, startDate, endDate,date_Format) => {
//     try {

//         let requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/transaction-and-income-data?days=${days}&start_date=${startDate}&end_date=${endDate}&time_zone=${date_Format}`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
//     }

// }

// const sendnotification = async (token = "", data) => {

//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/banner-notification`, requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: AdminService.js:1188 ~ EditLenderDetail ~ error:", error)
//     }
// }
const MerchantResetPassword = async (token, data) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                'Timezone': Timezone
            },
            body: data,
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/reset-password", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:81 ~ Merchant ~ error: ", error)
    }

}
const GetCountry = async (token = "") => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'GET',
            headers: {
                'Timezone': Timezone
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + "/get-country", requestOptions);
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:31 ~ LenderRegistration ~ error:", error)
    }

}
// const ChangeUserPassword = async (data, token = "") => {
//     try {
//         let requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 "Content-Type": "application/json"
//             },
//             body: data,
//             redirect: 'follow'
//         };

//         const res = await fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + "/change-password", requestOptions);
//         const result = await res.json();
//         return result;
//     } catch (error) {
//         console.log("🚀 ~ file: employService.js:31 ~ EmployerRegistration ~ error:", error)
//     }
// }
const getApi = (path, parameter = '', object = {}) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        const merchant = JSON.parse(localStorage.getItem("merchant"));
        let headers = { 'Authorization': `Bearer ${merchant.merchant_token}` }
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        if (object) {
            headers = { ...headers, ...object }
        }

        const queryParams = parameter ? '?' + new URLSearchParams(parameter) : '';

        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + '' + path + queryParams, {
            method: 'GET',
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        reject(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((error) => {
                console.log(error)
            });
    });
}

const postApi = (path, body = {}, is_formData = false, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("merchant"))?.merchant_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        if (is_formData == false) {
            headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        } else {
            headers = { ...headers, 'Timezone': Timezone /* 'Content-Type': 'multipart/form-data' */ }
        }
        if (object) {
            headers = { ...headers, ...object, 'Timezone': Timezone }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + '' + path, {
            method: 'POST',
            headers: headers,
            body: is_formData ? body : JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

const deleteApi = (path, body = {}, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("merchant"))?.merchant_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }

        if (object) {
            headers = { ...headers, ...object }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + '' + path, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

const posttoken = (path, body = {}, is_formData = false, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("merchant"))?.merchant_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        if (is_formData == false) {
            headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        } else {
            headers = { ...headers, 'Timezone': Timezone /* 'Content-Type': 'multipart/form-data' */ }
        }
        // if (object) {
        //     headers = { ...headers, ...object }
        // }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + '' + path, {
            method: 'POST',
            headers: headers,
            body: is_formData ? body : JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

export { getApi, postApi, posttoken, deleteApi, MerchantResetPassword, GetCountry };

