import React from 'react'
// import AgreementModal from '../user/Dashboard/agreementModal'
import { useLocation } from 'react-router-dom';
import AgreementModal from './agrrementModal';

const Agreement = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get('customer');
    const loanApplication = queryParams.get('type')
    const user_loan_id = queryParams.get('user_loan_id')
    const email = queryParams.get('email')

    // console.log("first*********************")

    return (

        <AgreementModal customerID={customerId} loanApplication={loanApplication} user_loan_id={user_loan_id} email={email} />

    )
}

export default Agreement