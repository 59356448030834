import React, { useEffect, useRef, useState, useHistory } from 'react'
import { Toaster } from 'react-hot-toast';
import Loader from '../../../components/Loader/loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PaginationComponent from '../../../PaginationComponent';
import { GetAttendancebyID, GetTimezone, changeStatus, getApi } from '../../service/employService';
import { Breadcrumbs, Breadcrumbsecond } from '../../../components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Switch } from 'antd';
import { Gettime, TimeZone } from '../../../components/TimeZone/TimeZone';
import moment from 'moment';

const AttendancebyID = () => {
  // const history = useHistory();
  // const back = history.goBack()
  const [loader, setloader] = useState(true)
  const [pagesCount, setpagesCount] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(10);
  const [dataArray, setDataArray] = useState([]);
  const [search, setSearch] = useState('');
  const tableRef = useRef(null);
  const [input, setInput] = useState(true);
  const employer = JSON.parse(localStorage.getItem("employer"));
  const user_Name = (employer.user_name)
  const token = employer.employer_token
  const [dsaDetail, setdsaDetail] = useState(false);
  const [date, setDate] = useState("")
  const [year, setYear] = useState("")
  const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
  const base_url = process.env.REACT_APP_API_URL;
  const { id } = useParams()
  const navigate = useNavigate();
  const [customer_id, setcustomerID] = useState()
  const [status, setStatus] = useState()
  const [attendance_date, setAttendance_date] = useState()
  const [in_time, setIn_time] = useState()
  const [out_time, setOut_time] = useState()
  const current_date_format = new moment().format("YYYY-MM-DD")
  const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)

  const handleChangeQueryBuilder = (e) => {
    // console.log(e, "checkingssssss")
    setDate(e)
  }

  const handleChangeQueryBuilders = (e) => {
    // console.log(e, "checkingssssss")
    setYear(e)
  }

  const ViewGetAttendancebyID = async (token) => {
    //  const response = await GetAttendancebyID(token, id, date, year, date_Format)
    try {
      const response = await getApi(`/get-all-attendance/${id}`, { month: date, year: year, time_zone: date_Format });
      if (response.status == true) {
        setTimeout(() => {
          setDataArray(response.data.rows);
          setInput(response.data.employeeDetail)
          setloader(false)
        }, 2000);
        setloader(true)
        //setTotalPagesCount(response.data["count"])
      } else if (response.status === false) {
        setDataArray("");
        setloader(false)
        console.log("first")
        // console.log("get employees data response", response);
        // console.log("failed============")
        // console.log(dataArray.length, "=====-------------")
      }
      else if (response.message === 'Session expired') {
        localStorage.removeItem("employer");
        navigate('/employer/login')
      }
    } catch (error) {
      console.log(error)
      if (error.message === 'Session expired') {
        localStorage.removeItem("employer");
        navigate('/employer/login')
      }
    }

    //  else {
    //   console.log("get employees data response", response);
    //   if (error.message == 'Session expired') {
    //     localStorage.removeItem("employer");
    //     navigate('/employer/login')
    //   }
    // }
  }

  useEffect(() => {
    ViewGetAttendancebyID(token)
  }, [date, year])

  const EditForm = useFormik({

    initialValues: {
      customer_id: `${customer_id}`,
      status: status,
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      status: yup.string().required('Please select Pay Cycle')
    }),
    onSubmit: values => {
      // console.log('values', values);
      // console.log('inside edit Form');
      let datas = JSON.stringify(values);
      // console.log(datas)
      // updateAccount(employer.employer_token, datas);
    }
  });



  return (
    <>

      {!dsaDetail &&
        <div className="main-container container-fluid px-0">
          <div className="page-header my-3 py-2">
            <div className="page-leftheader">
              <Breadcrumbsecond order={{ first: { name: "EWA Attendance", path: `${prefix}/employees_attendance` }, second: { name: "Employee Details" } }} />

              <h6 className="page-title mb-0 text-primary">Employee Name - {input.first_name} {input.last_name}</h6>
              {/* <Breadcrumbs order={{first:{name:"Borrower"},second:{name: "Details",path:`${prefix}/usedlimitdetails/${customer}/Existing` } ,third:{name:"EMI"} }} /> */}

            </div>

            <div className="page-rightheader">
              <div className="btn-list">
                <a href={base_url + prefix + `/export-employee-attendance/${user_Name}?id=${id}&year=${year}&month=${date}`}
                  className="btn btn-outline-primary mt-3 text-primary" download >
                  <i className="fa fa-download me-2"></i>Export Attendance</a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Select Month and Year</label>
                <div className="row g-xs">
                  <div className="wd-200 mg-b-30">
                    <div className="input-group">
                      <select className="form-control mb-0" onChange={(e) => handleChangeQueryBuilder(e.target.value)} >
                        <option value="">Select Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                      &nbsp;
                      <select className="form-control mb-0" onChange={(e) => handleChangeQueryBuilders(e.target.value)} >
                        <option value="">Select Year</option>
                        <option value="">2023</option>
                        <option value="2024">2024</option>

                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              {/* <div className="form-group">
                <label className="form-label">Search</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                      <thead className="border-bottom-0 pt-3 pb-3">
                        <tr>
                          {/* <th className="font-weight-bold">Employee Name  </th>
                          <th className="font-weight-bold">Employee Code  </th> */}
                          <th className="font-weight-bold">Status  </th>
                          <th className="font-weight-bold">In Time </th>
                          <th className="font-weight-bold">Out Time  </th>
                          <th className="font-weight-bold">Total Hours  </th>
                          <th className="font-weight-bold">Check In From  </th>
                          <th className="font-weight-bold">Check Out From  </th>
                          <th className="font-weight-bold">Attendance Date  </th>
                          <th className="font-weight-bold">Time Zone  {/* {sortedBy == "time_zone" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }}></i>} */}</th>
                          <th className="font-weight-bold">Action  </th>
                        </tr>
                      </thead>
                      {loader == true ? <tbody>
                        <td />
                        <div>
                          <Loader />
                        </div> </tbody> :
                        <>
                          <tbody>
                            {dataArray.length > 0 && dataArray.map((option, i) => {

                              let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                              const { id, customer_id, attendance_date, status, in_time, out_time, time_zone, in_from, out_from } = option;
                              var timeZonesIN = TimeZone(option.in_time).timeZone
                              // var timeZonesOUT = moment.utc(option.out_time).format("HH:mm")
                              var timeZonesOUT = TimeZone(option.out_time).timeZone
                              // moment.utc(option.out_time).format("YYYY-MM-DDTHH:mm");

                              const calculateTotalTime = (inTime, outTime) => {
                                if (!in_time || !out_time) {
                                  return "-";
                                }
                                //   const inTimeDate = new Date(`${attendance_date}  ${inTime}`);
                                //   const outTimeDate = new Date(`${attendance_date} ${outTime}`);
                                //   const totalTimeMs = outTimeDate - inTimeDate;
                                //   const hours = Math.floor(totalTimeMs / 3600000);
                                //   const minutes = Math.floor((totalTimeMs % 3600000) / 60000);
                                //   return `${hours} hours ${minutes} minutes`;
                                // };
                                const inTimeDate = moment(inTime)
                                const outTimeDate = moment(outTime)

                                // Get the difference in minutes
                                const diffInMinutes = outTimeDate.diff(inTimeDate, 'minutes');

                                // Calculate hours and remaining minutes
                                const hours = Math.floor(diffInMinutes / 60);
                                const remainingMinutes = diffInMinutes % 60;
                                return `${hours} hours ${remainingMinutes} minutes`;
                              };

                              return (
                                <tr key={serial_num}>
                                  {/* <td>{input.first_name ? input.first_name : "NA"}  {input.last_name ? input.last_name : ""}</td> */}
                                  {/* <td>{input.employee_code ? input.employee_code : "NA"}</td> */}
                                  <td>{status ? status : "NA"}</td>
                                  <td>{option.in_time ? timeZonesIN : "-"}</td>
                                  <td>{option.out_time ? timeZonesOUT : "-"}</td>
                                  <td>{calculateTotalTime(option.in_time, option.out_time)}</td>
                                  <td><span className="content_fix" /* style={{ fontSize: 'smaller' }} */>{option?.in_from != null ? in_from : "--"}</span></td>
                                  <td><span className="content_fix" /* style={{ fontSize: 'smaller' }} */>{option?.out_from != null ? out_from : "--"}</span></td>
                                  <td>{attendance_date ? attendance_date : "NA"}</td>
                                  <td>{option?.time_zone ? time_zone : "--"}</td>

                                  {/* <td>{input.mobile_number ? input.mobile_number : "NA"}</td> */}
                                  {/* <td> <button type="submit" className="btn btn-primary" 
                                  data-bs-target="#assign-rm" data-bs-toggle="modal"
                                  onClick={() => {setcustomerID(customer_id); setAttendance_date(attendance_date);
                                  setIn_time(in_time); setOut_time(out_time); setStatus(status)}
                                   }> Edit Attendance</button></td> */}
                                  {/* <td> {status=="P"?<button type="submit" className="btn btn-danger" > Mark as Absent</button>:
                                    <button type="submit" className="btn btn-primary" > Mark as Present</button>}</td> */}
                                  <td><Link className='btn btn-outline-primary '
                                    to={{ pathname: `${prefix}/attendancelogs/${id}/${customer_id}`, state: { customer_id } }} >View Logs </Link></td>
                                </tr>
                              )
                            })
                            } </tbody>
                          <tbody>
                            {dataArray.length == 0 &&
                              <tr>
                                <td colSpan={5} className='text-center'>No Data Available</td>
                              </tr>
                            }
                          </tbody> </>}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Edit Employee Start */}
          <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog" style={{ display: 'block !important' }}>
            <div className="modal-dialog" role="document" style={{ overflowY: 'initial !important' }}>
              <div className="modal-content modal-content-demo">
                <div className="modal-header"> <h6 className="modal-title">Edit Employee Attendance</h6>
                  <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button" /* onClick={emptycheck} */><span aria-hidden="true">×</span></button>
                </div>
                <form className="" id='registerForm' onSubmit={EditForm.handleSubmit} >
                  <div className="modal-body application-modal" >
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="row g-xs">
                        <div className="wd-200 mg-b-30">
                          <div className="input-group">
                            <select className="form-control mb-0" name="status"  /* value={status} *//* onKeyUp={() => setValidate(true)} */
                              /* onChange={(e) => setpaycycle(e.target.value)} */ /* onSelect={() => setValidate(true)} */
                              {...EditForm.getFieldProps("status")} >
                              {/* <option>Select Pay Cycle</option> */}
                              <option value='P'>Present</option>
                              <option value='A'>Absent</option>
                            </select>
                          </div>
                          {EditForm.touched.status && EditForm.errors.status ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.status}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Date</label>
                      <div className="row g-xs">
                        <div className="input-group">
                          <input type="date" className="form-control" value={attendance_date}
                          // onChange={(e) => setemployeecode(e.target.value)}
                          //   onKeyUp={() => setValidate(true)} 
                          //    {...EditForm.getFieldProps("employee_code")}
                          />
                        </div>
                        {/* {EditForm.touched.employee_code && EditForm.errors.employee_code ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.employee_code}</div> : ''} */}
                      </div>
                    </div>


                    {/*  <div className="form-group">
                                    <label className="form-label">In Time</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={in_time} onChange={(e) => setIn_time(e.target.value)}
                                             onKeyUp={() => setValidate(true)} 
                                                 {...EditForm.getFieldProps("salary")}
                                            />
                                        </div>
                                        {EditForm.touched.salary && EditForm.errors.salary ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.salary}</div> : ''}
                                    </div>
                                </div> 
                                <div className="form-group">
                                    <label className="form-label">Out Time</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control"value={out_time == "" ? "NA": out_time}  
                                             onChange={(e) => setdeductionamount(e.target.value)}
                                                onKeyUp={() => setValidate(true)} 
                                                 {...EditForm.getFieldProps("salary")}
                                            />
                                        </div>
                                        {EditForm.touched.salary && EditForm.errors.salary ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.salary}</div> : ''}
                                    </div>
                                </div>*/}


                    <div className="col-12 mt-2 ">
                      <div className="row" style={{ display: 'flex' }}>
                        <div className="">
                          {setStatus == status ? <button type="submit" className='btn btn-primary'>Update</button>
                            :
                            <button className='btn btn-primary' disabled>Update</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* Edit Employee End */}

        </div>
      }



    </>
  )
}

export default AttendancebyID
