import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader/loader';
import Search from '../../../components/Search/Search';
import { Breadcrumbsecond } from '../../../components';
import { getApi } from '../../service/employService';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import PaginationComponent from '../../../PaginationComponent';

const EwaAdvanceTransactionByID = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [Entrieslimit, setEntrieslimit] = useState(10)
    const [ValidPagination, setValidPagination] = useState(false)
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    // const employer = JSON.parse(localStorage.getItem("employer"));
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate();

    const SettlementBYidDetail = async () => {
        try {
            const response = await getApi(`/paid-ewa-advance-emi-list/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy, limit: Entrieslimit });

            // console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
            if (response.data) {

                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    setloader(false)
                }, 2000);
                setloader(true)


            } else {
                console.log("get merchant data response", response);
            }
        } catch (error) {
            if (error.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }

    }
    useEffect(() => {
        SettlementBYidDetail()
    }, [search, pagesCount, sortedBy, orderBy, Entrieslimit])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }


    }
    const copiedInfo = () => {
        toast.success("Copied")
    }


    // const [location, setLocation] = useState(null);

    // useEffect(() => {
    //     const successHandler = (position) => {
    //         const { latitude, longitude } = position.coords;
    //         setLocation({ latitude, longitude });
    //     };

    //     const errorHandler = (error) => {
    //         console.error('Error getting location:', error.message);
    //     };

    //     // Check if the Geolocation API is available in the browser
    //     if ('geolocation' in navigator) {
    //         // Get the current location
    //         navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    //     } else {
    //         console.error('Geolocation is not supported by your browser');
    //     }
    // }, []);

    // console.log(location, "__________________________-")

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4 pb-0">
                <div className="page-leftheader">
                    <Breadcrumbsecond order={{ first: { name: "Paid EWA Repayments", path: `${prefix}/ewa_paid` }, second: { name: "Transaction Details" } }} />
                </div>

                {/* <div className="page-rightheader">
                    <div className="btn-list">
                        <a href={exportfix + `/export-ewa-settlement/${id} `} className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                </div> */}

            </div>
            <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                {/* <Limitfilter Entrieslimit={Entrieslimit} setEntrieslimit={setEntrieslimit} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} /> */}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Loan ID  {sortedBy == "user_loan_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('user_loan_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('user_loan_id') }}> </i>}</th>
                                            <th className="font-weight-bold">Repayment No.  {sortedBy == "emi_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('emi_number') }}> </i>}</th>
                                            <th className='font-weight-bold'> Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}> </i>}</th>
                                            {/* <th className='font-weight-bold'>Paid Amount  {sortedBy == "total_paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_paid_amount') }}> </i>}</th> */}
                                            <th className="font-weight-bold">Due Date  {sortedBy == "due_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }}> </i>}</th>
                                            {/* <th className="font-weight-bold">Paid Date  {sortedBy == "paid_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_date') }}> </i>}</th> */}
                                            {/* <th className="font-weight-bold">Late Charges  {sortedBy == "late_charges" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }}> </i>}</th> */}
                                            {/* <th className="font-weight-bold">Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}> </i>}</th> */}
                                            {/* <th className="font-weight-bold">Notes  {sortedBy == "notes" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('notes') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('notes') }}> </i>}</th> */}
                                            <th className="font-weight-bold">Created Date {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}> </i>} </th>
                                            {/* <th className="font-weight-bold">Updated Date {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}> </i>} </th> */}
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody><td /><td /><Loader /></tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    const { user_loan_id, status, total_paid_amount, paid_date, due_date, amount, emi_number, notes, late_charges } = option;


                                                    return (
                                                        <tr key={serial_num}>
                                                            <td><span className="font-weight-normal1"> {option?.user_loan_id ? user_loan_id : "- -"} </span></td>
                                                            <td><span className="font-weight-normal1"> {option?.emi_number ? emi_number : "- -"} </span></td>
                                                            <td className='fw-bold text-bold'>{option?.amount ? amount : "-"}</td>
                                                            {/* <td className='fw-bold text-success'>{option?.total_paid_amount ? total_paid_amount : "-"}</td> */}
                                                            <td className='fw-bold text-success'>{option?.due_date ? due_date : "-"}</td>
                                                            {/* <td className='fw-bold text-success'>{option?.paid_date ? paid_date : "-"}</td> */}
                                                            {/* <td><span className="font-weight-normal1">{option?.late_charges ? late_charges : "--"}</span></td> */}
                                                            {/* <td><span className="font-weight-normal1"> {option?.status ? status : "- -"} </span></td> */}
                                                            {/* <td><span className="font-weight-normal1"> {option?.notes ? notes : "- -"} </span></td> */}
                                                            <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                                                            {/* <td><span className="font-weight-normal1">{TimeZone(option?.updated_at).timeZone}</span></td> */}
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={Entrieslimit}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>



        </div>
    )
}

export default EwaAdvanceTransactionByID