const getApi = (path, token, parameter = '', object = {}) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = { 'Authorization': `Bearer ${token}` }
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone, }
        if (object) {
            headers = { ...headers, ...object }
        }

        const queryParams = parameter ? '?' + new URLSearchParams(parameter) : '';

        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + '' + path + queryParams, {
            method: 'GET',
            headers: headers
        })
            .then((response) => {

                if (!response.ok) {
                    if (response.headers.get('Content-Type').includes('application/json')) {
                        response.json().then(json => {
                            reject(json)
                        })

                    } else {
                        resolve(response.text());
                    }

                } else {
                    if (response.headers.get('Content-Type').includes('application/json')) {
                        response.json().then(json => {

                            resolve(json)
                        })
                    }
                    else {
                        resolve(response.text());
                    }

                }
            }).catch((error) => {
                console.log(error)
            });
    });
}

const postApi = (path, token, body = {}, is_formData = false) => {
    console.log(token, "servisespage")
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        if (is_formData == false) {
            headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        } else {
            headers = { ...headers, 'Timezone': Timezone /* 'Content-Type': 'multipart/form-data' */ }
        }
        if (token) {
            headers = { ...headers, Authorization: `Bearer ${token}`, 'Timezone': Timezone }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX + '' + path, {
            method: 'POST',
            headers: headers,
            body: is_formData ? body : JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

export { getApi, postApi };
