import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { LoanRiskAnalysis, getApi } from '../../service/lenderService';

const Riskanalysis = () => {
    const [employeeDetail, setEmployeeDetail] = useState(Object);
    const [originalEmployeeDetail, setOriginalEmployeeDetail] = useState(Object);

    const { id } = useParams()
    const lender = JSON.parse(localStorage.getItem("lender"));


    const updateEmployeeData = async (e) => {

        setEmployeeDetail({ ...employeeDetail, [e.target.name]: e.target.value !== "" && parseInt(e.target.value) })


        // await LoanRiskAnalysis(lender.lender_token, { [e.target.name]: e.target.value === "" ? "0" : e.target.value, user_loan_id: id }).then((res) => {
        //     if (res.status == true) {
        //         viewLoanCasesData();

        //     } else {

        //     }
        // })
    }

    const viewLoanCasesData = async () => {
        const response = await getApi(`/user-loan-application/${id}`)
        if (response.status === true) {
            setEmployeeDetail(response.data.userData)
            setOriginalEmployeeDetail(response.data.userData)

        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        viewLoanCasesData();
    }, []);

    const TotalOutgoing = parseFloat(employeeDetail.rent + employeeDetail.electricity + employeeDetail?.bill_for_phone + employeeDetail?.bill_for_water
        + employeeDetail.house_keeping + employeeDetail.school_fees + employeeDetail.travelling + employeeDetail?.entertainment + employeeDetail?.hire_payments)

    const annualSalary = parseFloat(employeeDetail?.annual_salary ? employeeDetail?.annual_salary : 0);
    const otherIncome = parseFloat(employeeDetail?.other_income ? employeeDetail?.other_income : 0);

    const monthly_salary = ((annualSalary / 12) + otherIncome).toFixed(2);

    const Surplus = (parseFloat(monthly_salary ? monthly_salary : 0) - TotalOutgoing)
    const AddInsurance = ((employeeDetail?.require_loan_amount ? parseFloat(employeeDetail?.require_loan_amount) : 0 * 3 / 100) * 24 / 12)
    const Establishment = (employeeDetail?.require_loan_amount ? parseFloat(employeeDetail?.require_loan_amount) : 0 * 1 / 100)
    const totalSum = (
        parseFloat(employeeDetail?.require_loan_amount ? employeeDetail?.require_loan_amount : 0) +
        parseFloat(AddInsurance) +
        parseFloat(Establishment)
    );
    const interestflat = ((totalSum.toFixed(2) * 20 / 100) * 2)
    const TotalRepayable = (
        parseFloat(totalSum.toFixed(2)) +
        parseFloat(interestflat.toFixed(2))
    );


    const monthlyInstallment = ((TotalRepayable / 24).toFixed(2))

    let Employerfeesperinstall

    if (employeeDetail?.employerData?.length !== 0) {
        Employerfeesperinstall = ((monthlyInstallment * 3 / 100).toFixed(2))
    } else {
        Employerfeesperinstall = ((monthlyInstallment * 6 / 100).toFixed(2))
        // console.log("second")

    };

    const SurplusAvailable = (
        parseFloat(Surplus.toFixed(2)) -
        monthlyInstallment -
        Employerfeesperinstall
    );

    // const currencyFormat = (num) => {
    //     if (num < 0) {
    //         return "-$" + Math.abs(num).toFixed(2);
    //     } else {
    //         return "$" + num.toFixed(2);
    //     }
    // }

    const netWeeklysurplus = parseFloat((SurplusAvailable * 12) / 52).toFixed(2);
    if (/-/g.test(netWeeklysurplus)) {
        var netWeeklysurpluss = netWeeklysurplus?.replace(/-/g, '-$');
    } /* else {
        var netWeeklysurpluss = "$" + netWeeklysurplus?.trim()
    }
 */
    const DebtIncomeRatio = parseFloat(monthlyInstallment / Surplus).toFixed(2)
    var new_str = DebtIncomeRatio
    // console.log("===========", employeeDetail);

    return (
        <div style={{ marginTop: "30px" }}>
            <div className='d-flex justify-content-between mb-3'>
                <h3>Income</h3>
                <button className='btn btn-primary' onClick={() => setEmployeeDetail(originalEmployeeDetail)}>Reset</button>
            </div>
            <div className="stepform-newdesign">
                <div className="row">
                    <div className="form-group col-md-4">
                        <label className="form-label">EWA Advance Amount <small className='text-primary'>(*{originalEmployeeDetail?.require_loan_amount})</small>
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.require_loan_amount} placeholder="Enter Require EWA Advance Amount " name="require_loan_amount"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">

                        <div className="d-flex">
                            <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-chart-pie"></i> </div>
                            <div className="d-grid w-70"> <label>DTI (Debt to Income Ratio) :</label> <span className="font-weight-normal1 fs-14">
                                {new_str ? ((new_str * 100).toFixed(2) + "% ") : "--"}</span>
                                {(netWeeklysurplus >= 150 && new_str * 100 <= 50) ? (
                                    <div className='badge rounded-pill bg-success text-dark'> Very Low Risk</div>
                                ) : (netWeeklysurplus < 150 && netWeeklysurplus > 0 && new_str * 100 <= 50) ? (
                                    <div className='badge rounded-pill bg-warning text-dark'> Cautionary</div>
                                ) : (netWeeklysurplus >= 150 && new_str * 100 > 50 && new_str * 100 <= 55) ? (
                                    <div className='badge rounded-pill bg-warning text-dark'> Cautionary</div>
                                ) : (
                                    <div className='badge rounded-pill bg-danger text-dark' /* style={{ width: '120px' }} */> Unacceptable</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        {/* <label className="form-label">EWA Advance Amount <small className='text-primary'>(*{originalEmployeeDetail?.require_loan_amount})</small>
                        </label> */}
                        <div className="row g-xs">
                            <div className="input-group">
                                {/* <input type="number" className="form-control"
                                    value={employeeDetail.require_loan_amount} placeholder="Enter Require EWA Advance Amount " name="require_loan_amount"
                                    onChange={updateEmployeeData} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="form-label">Annual Salary
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail?.annual_salary} placeholder="Enter Other Income" name="annual_salary"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="form-label">Other Monthly Income
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail?.other_income} placeholder="Enter Other Income" name="other_income"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group col-md-3">
                        <label className="form-label">Total Monthly Income
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={monthly_salary ? monthly_salary : 0} placeholder="Enter Total Monthly Income." name="monthly_income"
                                    disabled
                                   /* onChange={updateEmployeeData} */ />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h3>Living Cost</h3>
            <div className="stepform-newdesign">
                <div className="row">
                    <div className="form-group col-md-4">
                        <label className="form-label">Rent
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.rent} placeholder="Enter Rent " name="rent"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Electricity
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.electricity} placeholder="Enter Electricity" name="electricity"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Total Telephone Cost
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.bill_for_phone} placeholder="Enter Total Telephone Cost." name="bill_for_phone"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Water
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.bill_for_water} placeholder="Enter Water." name="bill_for_water"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">House Keeping
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.house_keeping} placeholder="Enter House Keeping." name="house_keeping"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">School Fees
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.school_fees} placeholder="Enter School Fees." name="school_fees"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Travelling/Petrol/Bus
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.travelling} placeholder="Enter Travelling/Petrol/Bus." name="travelling"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label"> Entertainment/Video
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.entertainment} placeholder="Enter Entertainment/Video." name="entertainment"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="form-label">Total Hire Purchase Payments
                        </label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="number" className="form-control"
                                    value={employeeDetail.hire_payments} placeholder="Enter Total Hire Purchase Payments." name="hire_payments"
                                    onChange={updateEmployeeData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-contact-info-body" style={{ marginTop: "30px" }}>
                <h3>Risk Analysis</h3>
                <div className="media-list p-0">
                    <div className="media py-4 mt-0">
                        <div className="media-body">
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-calendar"></i> </div>
                                <div className="w-70"> <label>Total Outgoing : </label> <span className="font-weight-normal1 fs-14">{TotalOutgoing ? TotalOutgoing : "0"}</span> </div>
                            </div>
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-hand-holding-usd"></i> </div>
                                <div className="w-70"> <label>Surplus :</label> <span className="font-weight-normal1 fs-14">{Surplus ? Surplus : "0"}</span> </div>
                            </div>
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fab fa-amazon-pay"></i> </div>
                                <div className="w-70"> <label>New MFL Repayment :</label> <span className="font-weight-normal1 fs-14">{monthlyInstallment ? "$" + monthlyInstallment : "--"}</span> </div>
                            </div>
                        </div>
                    </div>
                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">

                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1 "> <i className="fas fa-sort-amount-up" ></i> </div>
                                <div className="w-70"> <label>Surplus Available :</label> <span className="font-weight-normal1 fs-14">{SurplusAvailable ? SurplusAvailable.toFixed(2) : "0"}</span> </div>
                            </div>
                            <div className="d-flex">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-sort-amount-up"></i> </div>
                                <div className="w-70"> <label>Net Weekly Surplus :</label> <span className="font-weight-normal1 fs-14">{netWeeklysurpluss ? netWeeklysurpluss : "0"}</span> </div>

                            </div>
                            <div className="d-flex"></div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Riskanalysis 
