import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import toast from 'react-hot-toast';
// import { Link, useNavigate } from 'react-router-dom'
import { postApi } from '../../service/employService';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Image } from 'antd';
import TextArea from 'antd/es/input/TextArea';

const AddionalInfo = ({ EmployerID, CustomerID, viewAdditionalInfo }) => {
    // const navigate = useNavigate();
    // const employer = JSON.parse(localStorage.getItem("employer"));
    const [image, setImage] = useState(viewAdditionalInfo?.data_photo ? viewAdditionalInfo?.data_photo : "")
    const [cv, setCV] = useState(viewAdditionalInfo?.data_cv ? viewAdditionalInfo?.data_cv : "")
    // const [photo, setPhoto] = useState(viewAdditionalInfo?.data_photo ? viewAdditionalInfo?.data_photo : "")
    // const [cvfile, setCVfile] = useState(viewAdditionalInfo?.data_cv ? viewAdditionalInfo?.data_cv : "")
    // const [imagePreview, setImagePreview] = useState("");
    // const [imagePreview1, setImagePreview1] = useState("");
    const [activeTab, setActiveTab] = useState("PF");
    const [selectedImage, setSelectedImage] = useState();
    const [selectedCV, setSelectedCV] = useState();

    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };


    const imageChange = (e) => {
        setImage(e.target.files[0])
        setSelectedImage(e.target.files[0])
        updateAdditionalInfo.setFieldValue('photo', e.target.files[0].name)
    };

    const CVChange = (e) => {
        setCV(e.target.files[0])
        setSelectedCV(e.target.files[0])
        console.log(e.target.files[0].name, "************************")
        updateAdditionalInfo.setFieldValue('cv', e.target.files[0].name)
    };

    const updateAdditionalInfo = useFormik({
        initialValues: {
            fnpf_member: viewAdditionalInfo?.fnpf_member ? viewAdditionalInfo?.fnpf_member : "",
            fnpf_code: viewAdditionalInfo?.fnpf_code ? viewAdditionalInfo?.fnpf_code : "",
            fnpf_firstname: viewAdditionalInfo?.fnpf_firstname ? viewAdditionalInfo?.fnpf_firstname : "",
            fnpf_othernames: viewAdditionalInfo?.fnpf_othernames ? viewAdditionalInfo?.fnpf_othernames : "",
            ytd_fnpf: viewAdditionalInfo?.ytd_fnpf ? viewAdditionalInfo?.ytd_fnpf : "",
            fnpf_employers_contribution_till_date: viewAdditionalInfo?.fnpf_employers_contribution_till_date ? viewAdditionalInfo?.fnpf_employers_contribution_till_date : "",
            fnpf_total_till_date: viewAdditionalInfo?.fnpf_total_till_date ? viewAdditionalInfo?.fnpf_total_till_date : "",

            security_licence: viewAdditionalInfo?.security_licence ? viewAdditionalInfo?.security_licence : "",
            security_licence_expiry: viewAdditionalInfo?.security_licence_expiry ? viewAdditionalInfo?.security_licence_expiry : "",

            full_name: viewAdditionalInfo?.full_name ? viewAdditionalInfo?.full_name : "",
            // photo: viewAdditionalInfo?.photo ? viewAdditionalInfo?.photo : "",
            photo: image?.name ? image?.name : "",

            gender: viewAdditionalInfo?.gender ? viewAdditionalInfo?.gender : "",
            fathers_name: viewAdditionalInfo?.fathers_name ? viewAdditionalInfo?.fathers_name : "",
            height: viewAdditionalInfo?.height ? viewAdditionalInfo?.height : "",
            weight: viewAdditionalInfo?.weight ? viewAdditionalInfo?.weight : "",
            spouse: viewAdditionalInfo?.spouse ? viewAdditionalInfo?.spouse : "",
            spouse_occupation: viewAdditionalInfo?.spouse_occupation ? viewAdditionalInfo?.spouse_occupation : "",

            transport_mode: viewAdditionalInfo?.transport_mode ? viewAdditionalInfo?.transport_mode : "",
            street: viewAdditionalInfo?.street ? viewAdditionalInfo?.street : "",
            suburb: viewAdditionalInfo?.suburb ? viewAdditionalInfo?.suburb : "",
            emergency_contact: viewAdditionalInfo?.emergency_contact ? viewAdditionalInfo?.emergency_contact : "",
            emergency_number: viewAdditionalInfo?.emergency_number ? viewAdditionalInfo?.emergency_number : "",

            management: viewAdditionalInfo?.management ? viewAdditionalInfo?.management : "",
            start_date: viewAdditionalInfo?.start_date ? viewAdditionalInfo?.start_date : "",
            finish_date: viewAdditionalInfo?.finish_date ? viewAdditionalInfo?.finish_date : "",
            years_service: viewAdditionalInfo?.years_service ? viewAdditionalInfo?.years_service : "",
            employment_type: viewAdditionalInfo?.employment_type ? viewAdditionalInfo?.employment_type : "",
            contract: viewAdditionalInfo?.contract ? viewAdditionalInfo?.contract : "",
            job_role: viewAdditionalInfo?.job_role ? viewAdditionalInfo?.job_role : "",
            system_role: viewAdditionalInfo?.system_role ? viewAdditionalInfo?.system_role : "",
            status: viewAdditionalInfo?.status ? viewAdditionalInfo?.status : "",
            jointcard: viewAdditionalInfo?.jointcard ? viewAdditionalInfo?.jointcard : "",
            identification_num: viewAdditionalInfo?.identification_num ? viewAdditionalInfo?.identification_num : "",
            cv: cv?.name ? cv?.name : "",
            sector: viewAdditionalInfo?.sector ? viewAdditionalInfo?.sector : "",
            hobbies: viewAdditionalInfo?.hobbies ? viewAdditionalInfo?.hobbies : "",

            previous_employers_tin: viewAdditionalInfo?.previous_employers_tin ? viewAdditionalInfo?.previous_employers_tin : "",
            total_paid_till_date: viewAdditionalInfo?.total_paid_till_date ? viewAdditionalInfo?.total_paid_till_date : "",
            qualifications: viewAdditionalInfo?.qualifications ? viewAdditionalInfo?.qualifications : "",
            certifications: viewAdditionalInfo?.certifications ? viewAdditionalInfo?.certifications : "",
            tertiary: viewAdditionalInfo?.tertiary ? viewAdditionalInfo?.tertiary : "",

            high_school: viewAdditionalInfo?.high_school ? viewAdditionalInfo?.high_school : "",

            smoker: viewAdditionalInfo?.smoker ? viewAdditionalInfo?.smoker : "",
            health_report: viewAdditionalInfo?.health_report ? viewAdditionalInfo?.health_report : "",

            familycare_leave_available: viewAdditionalInfo?.familycare_leave_available ? viewAdditionalInfo?.familycare_leave_available : "",
            paternity_leave_available: viewAdditionalInfo?.paternity_leave_available ? viewAdditionalInfo?.paternity_leave_available : "",
            ytd_sick_leave: viewAdditionalInfo?.ytd_sick_leave ? viewAdditionalInfo?.ytd_sick_leave : "",
            ytd_annual_leave: viewAdditionalInfo?.ytd_annual_leave ? viewAdditionalInfo?.ytd_annual_leave : "",
            ytd_bereavement_leave: viewAdditionalInfo?.ytd_bereavement_leave ? viewAdditionalInfo?.ytd_bereavement_leave : "",
            ytd_familycare_leave: viewAdditionalInfo?.ytd_familycare_leave ? viewAdditionalInfo?.ytd_familycare_leave : "",
            ytd_paternity_leave: viewAdditionalInfo?.ytd_paternity_leave ? viewAdditionalInfo?.ytd_paternity_leave : "",
            ytd_maternity_leave: viewAdditionalInfo?.ytd_maternity_leave ? viewAdditionalInfo?.ytd_maternity_leave : "",

            cyclevalue: viewAdditionalInfo?.cyclevalue ? viewAdditionalInfo?.cyclevalue : "",
            payment_method: viewAdditionalInfo?.payment_method ? viewAdditionalInfo?.payment_method : "",

            bank_code: viewAdditionalInfo?.bank_code ? viewAdditionalInfo?.bank_code : "",
            second_bankname: viewAdditionalInfo?.second_bankname ? viewAdditionalInfo?.second_bankname : "",
            second_accountnumber: viewAdditionalInfo?.second_accountnumber ? viewAdditionalInfo?.second_accountnumber : "",
            second_accountname: viewAdditionalInfo?.second_accountname ? viewAdditionalInfo?.second_accountname : "",
            second_amount: viewAdditionalInfo?.second_amount ? viewAdditionalInfo?.second_amount : "",
            total_bonuspaid_to_date: viewAdditionalInfo?.total_bonuspaid_to_date ? viewAdditionalInfo?.total_bonuspaid_to_date : "",
            completed_pay_period: viewAdditionalInfo?.completed_pay_period ? viewAdditionalInfo?.completed_pay_period : "",
            annual_income_plus_bonus: viewAdditionalInfo?.annual_income_plus_bonus ? viewAdditionalInfo?.annual_income_plus_bonus : "",
            ytd_gross: viewAdditionalInfo?.ytd_gross ? viewAdditionalInfo?.ytd_gross : "",
            ytd_income_tax_withheld: viewAdditionalInfo?.ytd_income_tax_withheld ? viewAdditionalInfo?.ytd_income_tax_withheld : "",
            ytd_srt_tax_withheld: viewAdditionalInfo?.ytd_srt_tax_withheld ? viewAdditionalInfo?.ytd_srt_tax_withheld : "",
            ytd_ecal_tax_withheld: viewAdditionalInfo?.ytd_ecal_tax_withheld ? viewAdditionalInfo?.ytd_ecal_tax_withheld : "",
            non_resident: viewAdditionalInfo?.non_resident ? viewAdditionalInfo?.non_resident : "",

            time_group: viewAdditionalInfo?.time_group ? viewAdditionalInfo?.time_group : "",
            current_sites: viewAdditionalInfo?.current_sites ? viewAdditionalInfo?.current_sites : "",
            other_approved_sites: viewAdditionalInfo?.other_approved_sites ? viewAdditionalInfo?.other_approved_sites : "",
            contracted_client: viewAdditionalInfo?.contracted_client ? viewAdditionalInfo?.contracted_client : "",
            termination_date: viewAdditionalInfo?.termination_date ? viewAdditionalInfo?.termination_date : "",
            incremental_date: viewAdditionalInfo?.incremental_date ? viewAdditionalInfo?.incremental_date : "",
            remarks: viewAdditionalInfo?.remarks ? viewAdditionalInfo?.remarks : "",
            log: viewAdditionalInfo?.log ? viewAdditionalInfo?.log : "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            ytd_fnpf: activeTab === 'PF' ? yup.string().max(8, 'Ytd Fnpf number max contain 8 number') : "",
            fnpf_employers_contribution_till_date: activeTab === 'PF' ? yup.string().max(8, 'Fnpf Employers Contribution number max contain 8 number') : "",
            fnpf_total_till_date: activeTab === 'PF' ? yup.string().max(10, 'Fnpf Total Till number max contain 10 number') : "",


            height: activeTab === 'personal' ? yup.string().max(5, 'Height max contain 5 number') : "",
            weight: activeTab === 'personal' ? yup.string().max(5, 'Weight max contain 5 number') : "",


            emergency_number: activeTab === 'transportation' ? yup.string().max(10, 'Emergency number max contain 10 number') : "",
            identification_num: activeTab === 'Professional' ? yup.string().max(10, 'Identification number max contain 10 number') : "",
            years_service: activeTab === 'Professional' ? yup.string().max(2, 'Years Service number max contain 2 Digit') : "",
            contract: activeTab === 'Professional' ? yup.string().max(2, 'Contract number max contain 2 number') : "",

            total_paid_till_date: activeTab === 'history' ? yup.string().max(10, 'Total Paid Amount max contain 10 Digit') : "",

            familycare_leave_available: activeTab === 'leaves' ? yup.string().max(2, 'Leave max contain 2 Digit') : "",
            paternity_leave_available: activeTab === 'leaves' ? yup.string().max(2, 'Leave max contain 2 Digit') : "",
            ytd_sick_leave: activeTab === 'leaves' ? yup.string().max(2, 'Leave max contain 2 Digit') : "",
            ytd_annual_leave: activeTab === 'leaves' ? yup.string().max(2, 'Leave max contain 2 Digit') : "",
            ytd_bereavement_leave: activeTab === 'leaves' ? yup.string().max(2, 'Leave max contain 2 Digit') : "",
            ytd_familycare_leave: activeTab === 'leaves' ? yup.string().max(2, 'Leave max contain 2 Digit') : "",
            ytd_paternity_leave: activeTab === 'leaves' ? yup.string().max(2, 'Leave max contain 2 Digit') : "",
            ytd_maternity_leave: activeTab === 'leaves' ? yup.string().max(2, 'Leave max contain 2 Digit') : "",


            second_amount: activeTab === 'bank' ? yup.string().max(10, 'Amount max contain 10 Digit') : "",
            total_bonuspaid_to_date: activeTab === 'bank' ? yup.string().max(10, 'Amount max contain 10 Digit') : "",
            completed_pay_period: activeTab === 'bank' ? yup.string().max(10, 'Amount max contain 10 Digit') : "",
            annual_income_plus_bonus: activeTab === 'bank' ? yup.string().max(10, 'Amount max contain 10 Digit') : "",
            ytd_gross: activeTab === 'bank' ? yup.string().max(10, 'Amount max contain 10 Digit') : "",
            ytd_income_tax_withheld: activeTab === 'bank' ? yup.string().max(10, 'Amount max contain 10 Digit') : "",
            ytd_srt_tax_withheld: activeTab === 'bank' ? yup.string().max(10, 'Amount max contain 10 Digit') : "",
            ytd_ecal_tax_withheld: activeTab === 'bank' ? yup.string().max(10, 'Amount max contain 10 Digit') : "",

        }),
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append('employer_id', EmployerID);
            formData.append('customer_id', CustomerID);
            // const url = cvfile
            // const segment = url.split('/');
            // const CVfileName = segment[segment.length - 1];
            activeTab === 'PF' && formData.append('fnpf_member', values.fnpf_member == "" ? 'A' : values.fnpf_member);
            activeTab === 'PF' && formData.append('fnpf_code', values.fnpf_code);
            activeTab === 'PF' && formData.append('fnpf_firstname', values.fnpf_firstname);
            activeTab === 'PF' && formData.append('fnpf_othernames', values.fnpf_othernames);
            activeTab === 'PF' && formData.append('ytd_fnpf', values.ytd_fnpf == "" ? 0 : values.ytd_fnpf);
            activeTab === 'PF' && formData.append('fnpf_employers_contribution_till_date', values.fnpf_employers_contribution_till_date == "" ? 0 : values.fnpf_employers_contribution_till_date);
            activeTab === 'PF' && formData.append('fnpf_total_till_date', values.fnpf_total_till_date == "" ? 0 : values.fnpf_total_till_date);

            activeTab === 'security' && formData.append('security_licence', values.security_licence);
            activeTab === 'security' && formData.append('security_licence_expiry', values.security_licence_expiry == "" ? null : values.security_licence_expiry);

            activeTab === 'personal' && formData.append('full_name', values.full_name);
            // activeTab === 'personal' && formData.append('photo', PhotofileName ? PhotofileName : photo)
            activeTab === 'personal' && formData.append('photo', image)
            activeTab === 'personal' && formData.append('gender', values.gender == "" ? 'male' : values.gender);
            activeTab === 'personal' && formData.append('fathers_name', values.fathers_name);
            activeTab === 'personal' && formData.append('height', values.height == "" ? 0 : values.height);
            activeTab === 'personal' && formData.append('weight', values.weight == "" ? 0 : values.weight);
            activeTab === 'personal' && formData.append('spouse', values.spouse);
            activeTab === 'personal' && formData.append('spouse_occupation', values.spouse_occupation);

            activeTab === 'transportation' && formData.append('transport_mode', values.transport_mode);
            activeTab === 'transportation' && formData.append('street', values.street);
            activeTab === 'transportation' && formData.append('suburb', values.suburb);
            activeTab === 'transportation' && formData.append('emergency_contact', values.emergency_contact);
            activeTab === 'transportation' && formData.append('emergency_number', values.emergency_number == "" ? 0 : values.emergency_number);

            activeTab === 'Professional' && formData.append('management', values.management);
            activeTab === 'Professional' && formData.append('start_date', values.start_date == "" ? null : values.start_date);
            activeTab === 'Professional' && formData.append('finish_date', values.finish_date == "" ? null : values.finish_date);
            activeTab === 'Professional' && formData.append('years_service', values.years_service == "" ? 0 : values.years_service);
            activeTab === 'Professional' && formData.append('employment_type', values.employment_type);
            activeTab === 'Professional' && formData.append('contract', values.contract == "" ? 0 : values.contract);
            activeTab === 'Professional' && formData.append('job_role', values.job_role);
            activeTab === 'Professional' && formData.append('system_role', values.system_role);
            activeTab === 'Professional' && formData.append('status', values.status == "" ? 'Active' : values.status);
            activeTab === 'Professional' && formData.append('jointcard', values.jointcard == "" ? 'yes' : values.jointcard);
            activeTab === 'Professional' && formData.append('identification_num', values.identification_num);
            activeTab === 'Professional' && formData.append('cv', cv);
            activeTab === 'Professional' && formData.append('sector', values.sector);
            activeTab === 'Professional' && formData.append('hobbies', values.hobbies);

            activeTab === 'history' && formData.append('previous_employers_tin', values.previous_employers_tin);
            activeTab === 'history' && formData.append('total_paid_till_date', values.total_paid_till_date == "" ? 0 : values.total_paid_till_date);
            activeTab === 'history' && formData.append('qualifications', values.qualifications);
            activeTab === 'history' && formData.append('certifications', values.certifications);
            activeTab === 'history' && formData.append('tertiary', values.tertiary);

            activeTab === 'history' && formData.append('high_school', values.high_school);

            activeTab === 'history' && formData.append('smoker', values.smoker == "" ? "no" : values.smoker);
            activeTab === 'history' && formData.append('health_report', values.health_report);

            activeTab === 'leaves' && formData.append('familycare_leave_available', values.familycare_leave_available == "" ? 0 : values.familycare_leave_available);
            activeTab === 'leaves' && formData.append('paternity_leave_available', values.paternity_leave_available == "" ? 0 : values.paternity_leave_available);
            activeTab === 'leaves' && formData.append('ytd_sick_leave', values.ytd_sick_leave == "" ? 0 : values.ytd_sick_leave);
            activeTab === 'leaves' && formData.append('ytd_annual_leave', values.ytd_annual_leave == "" ? 0 : values.ytd_annual_leave);
            activeTab === 'leaves' && formData.append('ytd_bereavement_leave', values.ytd_bereavement_leave == "" ? 0 : values.ytd_bereavement_leave);
            activeTab === 'leaves' && formData.append('ytd_familycare_leave', values.ytd_familycare_leave == "" ? 0 : values.ytd_familycare_leave);
            activeTab === 'leaves' && formData.append('ytd_paternity_leave', values.ytd_paternity_leave == "" ? 0 : values.ytd_paternity_leave);
            activeTab === 'leaves' && formData.append('ytd_maternity_leave', values.ytd_maternity_leave == "" ? 0 : values.ytd_maternity_leave);

            activeTab === 'leaves' && formData.append('cyclevalue', values.cyclevalue == "" ? 0 : values.cyclevalue);
            activeTab === 'leaves' && formData.append('payment_method', values.payment_method);

            activeTab === 'bank' && formData.append('bank_code', values.bank_code);
            activeTab === 'bank' && formData.append('second_bankname', values.second_bankname);
            activeTab === 'bank' && formData.append('second_accountnumber', values.second_accountnumber == "" ? 0 : values.second_accountnumber);
            activeTab === 'bank' && formData.append('second_accountname', values.second_accountname);
            activeTab === 'bank' && formData.append('second_amount', values.second_amount == "" ? 0 : values.second_amount);
            activeTab === 'bank' && formData.append('total_bonuspaid_to_date', values.total_bonuspaid_to_date == "" ? 0 : values.total_bonuspaid_to_date);
            activeTab === 'bank' && formData.append('completed_pay_period', values.completed_pay_period == "" ? 0 : values.completed_pay_period);
            activeTab === 'bank' && formData.append('annual_income_plus_bonus', values.annual_income_plus_bonus == "" ? 0 : values.annual_income_plus_bonus);
            activeTab === 'bank' && formData.append('ytd_gross', values.ytd_gross == "" ? 0 : values.ytd_gross);
            activeTab === 'bank' && formData.append('ytd_income_tax_withheld', values.ytd_income_tax_withheld == "" ? 0 : values.ytd_income_tax_withheld);
            activeTab === 'bank' && formData.append('ytd_srt_tax_withheld', values.ytd_srt_tax_withheld == "" ? 0 : values.ytd_srt_tax_withheld);
            activeTab === 'bank' && formData.append('ytd_ecal_tax_withheld', values.ytd_ecal_tax_withheld == "" ? 0 : values.ytd_ecal_tax_withheld);
            activeTab === 'bank' && formData.append('non_resident', values.non_resident);

            activeTab === 'client' && formData.append('time_group', values.time_group);
            activeTab === 'client' && formData.append('current_sites', values.current_sites);
            activeTab === 'client' && formData.append('other_approved_sites', values.other_approved_sites);
            activeTab === 'client' && formData.append('contracted_client', values.contracted_client);
            activeTab === 'client' && formData.append('termination_date', values.termination_date == "" ? null : values.termination_date);
            activeTab === 'client' && formData.append('incremental_date', values.incremental_date == "" ? null : values.incremental_date);
            activeTab === 'client' && formData.append('remarks', values.remarks);
            activeTab === 'client' && formData.append('log', values.log);

            const response = await postApi(`/edit-additional-information/${CustomerID}`, formData, true)
            if (response.status == true) {
                toast.success(response.message);
                // resetForm({ values: '' });
                // navigate("/employer/employees");
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    }
    );

    return (

        <div className="main-container container-fluid px-0">
            <div className="page-header my-3 py-4">

                <div style={{ width: "100%" }}>
                    <div className="col-md-12 tabsbtm">
                        <Tabs
                            defaultActiveKey="PF"
                            id="fill-tab-example"
                            className="mb-3"
                            onSelect={handleTabSelect}
                            fill
                        >
                            {/* <form onSubmit={updateAdditionalInfo.handleSubmit}> */}
                            <Tab eventKey="PF" title="PF"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 " >
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between">
                                                                    <h6 className="mb-3 text-primary">PF Detail</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Fnpf Member</label>
                                                                            <select
                                                                                className='form-control'
                                                                                placeholder='Enter FNPF Member'
                                                                                {...updateAdditionalInfo.getFieldProps('fnpf_member')}  >

                                                                                <option value="A">A</option>
                                                                                <option value="N">N</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Fnpf Code</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter FNPF Code'
                                                                                {...updateAdditionalInfo.getFieldProps('fnpf_code')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Fnpf First Name</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter FNPF First Name'
                                                                                {...updateAdditionalInfo.getFieldProps('fnpf_firstname')} />
                                                                        </div>

                                                                        <div className="col-md-3">
                                                                            <label>Fnpf Other Names</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter FNPF Other Names'
                                                                                {...updateAdditionalInfo.getFieldProps('fnpf_othernames')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>YTD FNPF</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                // max={5}
                                                                                placeholder='Enter YTD Fnpf'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_fnpf')} />
                                                                            {updateAdditionalInfo.touched.ytd_fnpf && updateAdditionalInfo.errors.ytd_fnpf ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_fnpf}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Fnpf Employers Contribution Till Date</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Employers Contribution Till Date'
                                                                                {...updateAdditionalInfo.getFieldProps('fnpf_employers_contribution_till_date')} />
                                                                            {updateAdditionalInfo.touched.fnpf_employers_contribution_till_date && updateAdditionalInfo.errors.fnpf_employers_contribution_till_date ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.fnpf_employers_contribution_till_date}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Fnpf Total Till Date</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter FNPF Total Till Date'
                                                                                {...updateAdditionalInfo.getFieldProps('fnpf_total_till_date')} />
                                                                            {updateAdditionalInfo.touched.fnpf_total_till_date && updateAdditionalInfo.errors.fnpf_total_till_date ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.fnpf_total_till_date}</div> : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="security" title="Security"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 ">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between">
                                                                    <h6 className="mb-3 text-primary">Security</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Security Licence</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Security Licence'
                                                                                {...updateAdditionalInfo.getFieldProps('security_licence')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Security Licence Expiry</label>
                                                                            <input type="date"
                                                                                className='form-control'
                                                                                placeholder='Enter Security Licence Expiry'
                                                                                {...updateAdditionalInfo.getFieldProps('security_licence_expiry')} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="personal" title="Personal"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 ">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between">
                                                                    <h6 className="mb-3 text-primary">Personal Details</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Full Name</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Full Name'
                                                                                {...updateAdditionalInfo.getFieldProps('full_name')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Gender</label>
                                                                            <select className='form-control'
                                                                                placeholder='Enter Select Gender'
                                                                                {...updateAdditionalInfo.getFieldProps('gender')} >
                                                                                <option value="male">Male</option>
                                                                                <option value="female">Female</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Fathers Name</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Fathers Name'
                                                                                {...updateAdditionalInfo.getFieldProps('fathers_name')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Height</label>
                                                                            <div className="input-group">
                                                                                <input type="text"
                                                                                    className='form-control'
                                                                                    placeholder='Enter Height'
                                                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                                                    {...updateAdditionalInfo.getFieldProps('height')} />
                                                                                <span className="input-group-text" id="basic-addon1">CM</span></div>
                                                                            {updateAdditionalInfo.touched.height && updateAdditionalInfo.errors.height ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.height}</div> : ''}

                                                                        </div>
                                                                        {/* <div className="col-md-3">
                                    <label>Height</label>
                                    <input type="text" 
                                    className='form-control'
                                     />
                                </div> */}
                                                                        <div className="col-md-3">
                                                                            <label>Weight</label>
                                                                            <div className="input-group">
                                                                                <input type="text"
                                                                                    className='form-control'
                                                                                    placeholder='Enter Weight'
                                                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                                                    {...updateAdditionalInfo.getFieldProps('weight')} />
                                                                                <span className="input-group-text" id="basic-addon1">KG</span></div>
                                                                            {updateAdditionalInfo.touched.weight && updateAdditionalInfo.errors.weight ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.weight}</div> : ''}

                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Spouse</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Spouse'
                                                                                {...updateAdditionalInfo.getFieldProps('spouse')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Spouse Occupation</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Spouse occupation'
                                                                                {...updateAdditionalInfo.getFieldProps('spouse_occupation')}
                                                                            />
                                                                        </div>
                                                                        {/* <div className="col-xl-9 col-lg-6 col-md-6 col-xm-12">
                                    <label className="form-label">Photo</label>
                                    <div className="upload__box">
                                        <div className="upload__btn-box">
                                            <label className="upload__btn">
                                                <i className="fa-solid fa-arrow-up-from-bracket fa-fw"></i>
                                                <p>Upload images <span className="addmore-plus">
                                                    <i className="fa-solid fa-plus fa-fw"></i></span>
                                                    <small style={{ display: "block", color: "#a5a5a5" }}>(JPG,
                                                        PNG only)</small>
                                                </p>
                                                <input type="file" accept=".jpg, .png"
                                                    onClick={(e) => setPhoto(e.target.files[0])}
                                                    name="logo" className="upload__inputfile" />
                                            </label>
                                        </div>
                                    </div>
                                </div> */}

                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                                                                            <label>Select Photo</label>
                                                                            <div className='d-flex'>
                                                                                <input type="text" className="form-control browse-file"
                                                                                    placeholder="Upload your Photo" disabled />
                                                                                <label className="input-group-text btn btn-primary">Browse
                                                                                    <input type="file" className="file-browserinput"
                                                                                        onChange={(e) => imageChange(e)} style={{ display: 'none' }}
                                                                                        multiple /> </label> </div>
                                                                            {/* <input
                                                                                type="file"
                                                                                onChange={handlePhotoChange}
                                                                                className="form-control m-0 me-2"
                                                                                placeholder={photo ? photo : "Select Photo"}
                                                                                accept=".jpg, .png"
                                                                            /> */}
                                                                            {/* {imagePreview && (
                                                                                <Image
                                                                                    src={imagePreview}
                                                                                    style={{ width: '70px', height: '70px', border: "1px solid silver" }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="Selected"
                                                                                />
                                                                            )} */}
                                                                            {!selectedImage ? <ul className="imgpreview-newbx">
                                                                                <li className="registerboximg">
                                                                                    <Image src={image ? image : process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt="/" /> </li>
                                                                            </ul> :
                                                                                <ul className="imgpreview-newbx">
                                                                                    <li className="registerboximg">
                                                                                        <Image
                                                                                            src={URL.createObjectURL(selectedImage)}
                                                                                            alt="..."
                                                                                        /> </li>
                                                                                </ul>}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="transportation" title="Transport & Address"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 ">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between">
                                                                    <h6 className="mb-3 text-primary">Transportation and Address Detail</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Transport Mode</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Transport Mode'
                                                                                {...updateAdditionalInfo.getFieldProps('transport_mode')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Street</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Street'
                                                                                {...updateAdditionalInfo.getFieldProps('street')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Suburb</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Suburb'
                                                                                {...updateAdditionalInfo.getFieldProps('suburb')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Emergency Contact</label>

                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Emergency Contact'
                                                                                {...updateAdditionalInfo.getFieldProps('emergency_contact')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Emergency Number</label>
                                                                            <div className="input-group">
                                                                                <input type="number"
                                                                                    className='form-control'
                                                                                    placeholder='Enter Emergency Contact'
                                                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                                                    {...updateAdditionalInfo.getFieldProps('emergency_number')} />
                                                                                <span className="input-group-text fa fa-phone" id="basic-addon1"></span></div>
                                                                            {updateAdditionalInfo.touched.emergency_number && updateAdditionalInfo.errors.emergency_number ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.emergency_number}</div> : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="Professional" title="Professional"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 ">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between">
                                                                    <h6 className="mb-3 text-primary">Employee Professional Details</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Management</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Management'
                                                                                {...updateAdditionalInfo.getFieldProps('management')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Start Date</label>
                                                                            <input type="date"
                                                                                className='form-control'
                                                                                placeholder='Enter Start Date'
                                                                                {...updateAdditionalInfo.getFieldProps('start_date')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Finish Date</label>
                                                                            <input type="date"
                                                                                className='form-control'
                                                                                placeholder='Enter Finish Date'
                                                                                {...updateAdditionalInfo.getFieldProps('finish_date')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Years Service</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Years Service'
                                                                                {...updateAdditionalInfo.getFieldProps('years_service')} />
                                                                            {updateAdditionalInfo.touched.years_service && updateAdditionalInfo.errors.years_service ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.years_service}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Employment Type</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Employment Type'
                                                                                {...updateAdditionalInfo.getFieldProps('employment_type')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Contract</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Contract'
                                                                                {...updateAdditionalInfo.getFieldProps('contract')} />
                                                                            {updateAdditionalInfo.touched.contract && updateAdditionalInfo.errors.contract ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.contract}</div> : ''}

                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Job Role</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Job Role'
                                                                                {...updateAdditionalInfo.getFieldProps('job_role')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>System Role</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter System Role'
                                                                                {...updateAdditionalInfo.getFieldProps('system_role')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Status</label>
                                                                            <select
                                                                                className='form-control'
                                                                                placeholder='Enter Status'
                                                                                {...updateAdditionalInfo.getFieldProps('status')} >
                                                                                <option value="active">Active</option>
                                                                                <option value="inactive">Inactive</option>
                                                                                <option value="terminated">Terminated</option>
                                                                            </select>

                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Jointcard</label>
                                                                            <select
                                                                                className='form-control'
                                                                                placeholder='Enter Jointcard'
                                                                                {...updateAdditionalInfo.getFieldProps('jointcard')}  >
                                                                                <option value="yes">Yes</option>
                                                                                <option value="no">No</option>
                                                                            </select>

                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Identification Num</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Identification Num'
                                                                                {...updateAdditionalInfo.getFieldProps('identification_num')} />
                                                                            {updateAdditionalInfo.touched.identification_num && updateAdditionalInfo.errors.identification_num ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.identification_num}</div> : ''}

                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Sector</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Sector'
                                                                                {...updateAdditionalInfo.getFieldProps('sector')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Hobbies</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Hobbies'
                                                                                {...updateAdditionalInfo.getFieldProps('hobbies')} />
                                                                        </div>
                                                                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                                    <label>Upload CV</label>
                                    <input
                                        type="file"
                                        onChange={(e) => setCVfile(e.target.files[0])}
                                        className="form-control m-0 me-2"
                                        placeholder="Select CV"
                                        accept=".jpg, .png"
                                    />
                                </div> */}

                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                                                                            <label>Select CV</label>
                                                                            <div className='d-flex'>
                                                                                <input type="text" className="form-control browse-file"
                                                                                    placeholder="Upload your CV" disabled />
                                                                                <label className="input-group-text btn btn-primary">Browse
                                                                                    <input type="file" className="file-browserinput"
                                                                                        accept='.pdf , .png , .jpg , .jpeg'
                                                                                        onChange={(e) => CVChange(e)} style={{ display: 'none' }}
                                                                                        multiple /> </label> </div>
                                                                            {/* <input
                                                                                type="file"
                                                                                onChange={handlePhotoChange1}
                                                                                className="form-control m-0 me-2"
                                                                                accept=".jpg, .png"
                                                                            /> */}
                                                                            {!selectedCV ? <ul className="imgpreview-newbx">
                                                                                <li className="registerboximg">
                                                                                    <Image src={cv ? cv : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt="/" />
                                                                                    {/* <Image src={cv && !cv.includes('null') ? cv : process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt="Default Image" /> */}
                                                                                </li>
                                                                            </ul> :
                                                                                <ul className="imgpreview-newbx">
                                                                                    <li className="registerboximg">
                                                                                        <Image
                                                                                            src={URL.createObjectURL(selectedCV)}
                                                                                            alt="..."
                                                                                        /> </li>
                                                                                </ul>}
                                                                            {/* {imagePreview1 && (
                                                                                <Image
                                                                                    src={imagePreview1}
                                                                                    style={{ width: '70px', height: '70px', border: "1px solid silver" }}
                                                                                    className="card-img-top m-1"
                                                                                    alt="Selected"
                                                                                />
                                                                            )} */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="history" title="History"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 ">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12"><div className="lineHr"></div></div>
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between"><h6 className="mb-3 text-primary">History</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Previous Employers Tin</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Previous Employers Tin'
                                                                                {...updateAdditionalInfo.getFieldProps('previous_employers_tin')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Total Paid Till Date</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Total Paid Till Date'
                                                                                {...updateAdditionalInfo.getFieldProps('total_paid_till_date')} />
                                                                            {updateAdditionalInfo.touched.total_paid_till_date && updateAdditionalInfo.errors.total_paid_till_date ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.total_paid_till_date}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Qualifications</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Qualifications'
                                                                                {...updateAdditionalInfo.getFieldProps('qualifications')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Certifications</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Certifications'
                                                                                {...updateAdditionalInfo.getFieldProps('certifications')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Tertiary</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Tertiary'
                                                                                {...updateAdditionalInfo.getFieldProps('tertiary')} />
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-md-12 mt-3"><h6 className="mb-3 text-primary">Education</h6></div>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>High School(name)</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter High School'
                                                                                {...updateAdditionalInfo.getFieldProps('high_school')} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12"><div className="lineHr"></div></div>
                                                                    <div className="col-md-12 mt-3"><h6 className="mb-3 text-primary">Health Related</h6></div>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Smoker</label>
                                                                            <select
                                                                                className='form-control'
                                                                                placeholder='Select Smoker'
                                                                                {...updateAdditionalInfo.getFieldProps('smoker')} >
                                                                                <option value="no">No</option>
                                                                                <option value="yes">Yes</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Health Report</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Health Report'
                                                                                {...updateAdditionalInfo.getFieldProps('health_report')} />
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="leaves" title="Leaves & Pay"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 ">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between">
                                                                    <h6 className="mb-3 text-primary">Leaves</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Familycare Leave Available</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Leave'
                                                                                {...updateAdditionalInfo.getFieldProps('familycare_leave_available')} />
                                                                            {updateAdditionalInfo.touched.familycare_leave_available && updateAdditionalInfo.errors.familycare_leave_available ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.familycare_leave_available}</div> : ''}

                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Paternity Leave Available</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Leave'
                                                                                {...updateAdditionalInfo.getFieldProps('paternity_leave_available')} />
                                                                            {updateAdditionalInfo.touched.paternity_leave_available && updateAdditionalInfo.errors.paternity_leave_available ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.paternity_leave_available}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Sick Leave</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Leave'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_sick_leave')} />
                                                                            {updateAdditionalInfo.touched.ytd_sick_leave && updateAdditionalInfo.errors.ytd_sick_leave ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_sick_leave}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Annual Leave</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Leave'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_annual_leave')} />
                                                                            {updateAdditionalInfo.touched.ytd_annual_leave && updateAdditionalInfo.errors.ytd_annual_leave ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_annual_leave}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Bereavement Leave</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Leave'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_bereavement_leave')} />
                                                                            {updateAdditionalInfo.touched.ytd_bereavement_leave && updateAdditionalInfo.errors.ytd_bereavement_leave ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_bereavement_leave}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Familycare Leave</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Leave'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_familycare_leave')} />
                                                                            {updateAdditionalInfo.touched.ytd_familycare_leave && updateAdditionalInfo.errors.ytd_familycare_leave ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_familycare_leave}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Paternity Leave</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Leave'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_paternity_leave')} />
                                                                            {updateAdditionalInfo.touched.ytd_paternity_leave && updateAdditionalInfo.errors.ytd_paternity_leave ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_paternity_leave}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Maternity Leave</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Leave'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_maternity_leave')} />
                                                                            {updateAdditionalInfo.touched.ytd_maternity_leave && updateAdditionalInfo.errors.ytd_maternity_leave ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_maternity_leave}</div> : ''}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12"><div className="lineHr"></div></div>
                                                                    <div className="col-md-12 mt-3 "><h6 className="mb-3 text-primary">Pay Related</h6></div>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Cyclevalue</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Cyclevalue'
                                                                                {...updateAdditionalInfo.getFieldProps('cyclevalue')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Payment Method</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Payment Method'
                                                                                {...updateAdditionalInfo.getFieldProps('payment_method')} />
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="bank" title="Bank Detail"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 ">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between">
                                                                    <h6 className="mb-3 text-primary">Bank Detail and Transaction</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Bank Code</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Bank Code'
                                                                                {...updateAdditionalInfo.getFieldProps('bank_code')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Second Bankname</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Second Bankname'
                                                                                {...updateAdditionalInfo.getFieldProps('second_bankname')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Second Account Number</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Second Accountnumber'
                                                                                {...updateAdditionalInfo.getFieldProps('second_accountnumber')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Second Account Name</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Second Accountname'
                                                                                {...updateAdditionalInfo.getFieldProps('second_accountname')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Second Amount</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Second Amount'
                                                                                {...updateAdditionalInfo.getFieldProps('second_amount')} />
                                                                            {updateAdditionalInfo.touched.second_amount && updateAdditionalInfo.errors.second_amount ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.second_amount}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Total Bonuspaid To Date</label>
                                                                            <div className="input-group">
                                                                                <input type="number"
                                                                                    className='form-control'
                                                                                    placeholder='Enter Total Bonuspaid Date'
                                                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                                                    {...updateAdditionalInfo.getFieldProps('total_bonuspaid_to_date')} />
                                                                                <span className="input-group-text" id="basic-addon1">$</span></div>
                                                                            {updateAdditionalInfo.touched.total_bonuspaid_to_date && updateAdditionalInfo.errors.total_bonuspaid_to_date ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.total_bonuspaid_to_date}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Completed Pay Period</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Completed Pay Period'
                                                                                {...updateAdditionalInfo.getFieldProps('completed_pay_period')} />
                                                                            {updateAdditionalInfo.touched.completed_pay_period && updateAdditionalInfo.errors.completed_pay_period ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.completed_pay_period}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Annual Income Plus Bonus</label>
                                                                            <div className="input-group">
                                                                                <input type="number"
                                                                                    className='form-control'
                                                                                    placeholder='Enter Annual Income Plus Bonus'
                                                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                                                    {...updateAdditionalInfo.getFieldProps('annual_income_plus_bonus')} />
                                                                                <span className="input-group-text" id="basic-addon1">$</span></div>
                                                                            {updateAdditionalInfo.touched.annual_income_plus_bonus && updateAdditionalInfo.errors.annual_income_plus_bonus ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.annual_income_plus_bonus}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Gross</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Ytd Gross'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_gross')} />
                                                                            {updateAdditionalInfo.touched.ytd_gross && updateAdditionalInfo.errors.ytd_gross ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_gross}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Income Tax Withheld</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Ytd Income Tax Withheld'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_income_tax_withheld')} />
                                                                            {updateAdditionalInfo.touched.ytd_income_tax_withheld && updateAdditionalInfo.errors.ytd_income_tax_withheld ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_income_tax_withheld}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Srt Tax Withheld</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Ytd Srt Tax Withheld'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_srt_tax_withheld')} />
                                                                            {updateAdditionalInfo.touched.ytd_srt_tax_withheld && updateAdditionalInfo.errors.ytd_srt_tax_withheld ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_srt_tax_withheld}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Ytd Ecal Tax Withheld</label>
                                                                            <input type="number"
                                                                                className='form-control'
                                                                                placeholder='Enter Ytd Ecal Tax Withheld'
                                                                                {...updateAdditionalInfo.getFieldProps('ytd_ecal_tax_withheld')} />
                                                                            {updateAdditionalInfo.touched.ytd_ecal_tax_withheld && updateAdditionalInfo.errors.ytd_ecal_tax_withheld ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{updateAdditionalInfo.errors.ytd_ecal_tax_withheld}</div> : ''}
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Non Resident</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Non Resident'
                                                                                {...updateAdditionalInfo.getFieldProps('non_resident')} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="client" title="Client Detail"  >
                                <form onSubmit={updateAdditionalInfo.handleSubmit}>
                                    <div className="main-container container-fluid px-0 ">
                                        <div className="page-header my-3 py-2">
                                            <div className="modal-body application-modal px-0">
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="tabsiconBox">
                                                            <div className="main-content-body main-content-body-contacts">
                                                                <div className="col-md-12 mt-3 d-flex justify-content-between"><h6 className="mb-3 text-primary">Client Details</h6>
                                                                    <button type='submit' className='btn btn-primary'>Update </button></div>
                                                                <div className='btn_group' style={{}}>
                                                                    <div className='col-md-12 row mb-3'>
                                                                        <div className="col-md-3">
                                                                            <label>Time Group</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Time Group'
                                                                                {...updateAdditionalInfo.getFieldProps('time_group')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Current Sites</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Current Sites'
                                                                                {...updateAdditionalInfo.getFieldProps('current_sites')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Other Approved Sites</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Other Approved Sites'
                                                                                {...updateAdditionalInfo.getFieldProps('other_approved_sites')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Contracted Client</label>
                                                                            <input type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Contracted Client'
                                                                                {...updateAdditionalInfo.getFieldProps('contracted_client')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Termination Date</label>
                                                                            <input type="date"
                                                                                className='form-control'
                                                                                placeholder='Enter Termination Date'
                                                                                {...updateAdditionalInfo.getFieldProps('termination_date')} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label>Incremental Date</label>
                                                                            <input type="date"
                                                                                className='form-control'
                                                                                placeholder='Enter Incremental Date'
                                                                                {...updateAdditionalInfo.getFieldProps('incremental_date')} />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label>Remarks</label>
                                                                            <TextArea type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Remarks'
                                                                                {...updateAdditionalInfo.getFieldProps('remarks')} />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label>Logs</label>
                                                                            <TextArea type="text"
                                                                                className='form-control'
                                                                                placeholder='Enter Log'
                                                                                {...updateAdditionalInfo.getFieldProps('log')} />
                                                                        </div>



                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            {/* </form> */}
                        </Tabs>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default AddionalInfo