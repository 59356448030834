import React, { useState, useEffect, useRef } from 'react';
import { Toaster } from 'react-hot-toast';
import PaginationComponent from '../../PaginationComponent';
import { getApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';

const Country = (props) => {
    const [loader, setloader] = useState(true)

    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [searchpage, setSearchpage] = useState('');
    const [endDate, setendDate] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [pagesCheck, setpagesCheck] = useState(1);
    // const [salaryModeList, setSalaryModeList] = useState([]);


    const admin = JSON.parse(localStorage.getItem("admin"));

    const getAllCityData = async (token, pageNumber, filterVlu) => {
        // const response = await Getcities(token, pageNumber, filterVlu, sortedBy, orderBy);
        const response = await getApi("/get-all-country", { page_number: pageNumber, search: filterVlu, sortby: sortedBy, orderby: orderBy });

        console.log("fdgdgdgddfgdg", response)
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.AllCountry);
                settotalItems(response.data)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get city data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getAllCityData(admin.admin_token, pagesCount, search);
    }, [search, pagesCount]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        getAllCityData(admin.admin_token, pagesCount, search);
    }

    return (

        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            {/* <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">City({dataArray.length})</h4>
                            </div> */}
                            <div className="page-leftheader">
                                {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                <h6 className="page-title mb-0 text-primary">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Setting</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Country({dataArray.length})</li>
                                        </ol>
                                    </nav>
                                </h6>
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Country</th>
                                                        <th className="font-weight-bold">status</th>
                                                        {/* <th className="font-weight-bold " > <span style={{ marginLeft: "50px" }}>Action</span></th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                {loader == true ? <tbody>
                                                    <td />
                                                    <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            let Capitalize = (option) => {
                                                                return option.charAt(0).toUpperCase() + option.slice(1);
                                                            }
                                                            return (
                                                                <tr key={option.id} >
                                                                    <td><span className="font-weight-normal1">{option.name ? Capitalize(option.name) : ""}</span></td>
                                                                    <td><span className="font-weight-normal1">Active</span></td>

                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>
            }
            <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header"> <h6 className="modal-title">Assign Employer</h6>
                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                        <div className="modal-body application-modal">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword"
                                    value={searchpage} onChange={(e) => setSearchpage(e.target.value)} /* onKeyDown={getEmployeesData} */ />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div><hr />
                            {pagesCheck > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={setTotalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCheck(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default Country;
