
import Header from '../website/header';
import Footer from '../website/footer';
const DigitalAutomation = () => {


    return (
        <>
            <Header />
            <div className="intro-four bgimage">
                <div className="bg_image_holder" style={{ backgroundImage: "url('./website/assets/img/slider_bg4.jpg')", opacity: 1 }}>
                    <img src="./website/assets/img/slider_bg4.jpg" alt="slider_bg4" /></div>
                <div className="intro-four--contents content_above">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <h1 className="display-3 mb-1">Digital Lending</h1>
                                <p className="mb-2">We provide web apps &amp; mobile apps with highly intuitive user interfaces that helps our target customers to simply focus on servicing their customers and not worry about tech</p>
                                <a href="./website/assets/img/pdf/Thirdrocs-Digital-Lending-Platform_website.pdf" className="bnplPage brNone"><i className="fa-regular fa-file-pdf"></i> Digital Lending, Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="features-area default-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-10">
                            <div className="my-title text-center">
                                <h4>Meant for customers who do not have tech infrastructure or have inadequate tech infrastructure in dealing with the risk assessment in various ways:</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="card card-shadow card-four">
                                <figure><img src="./website/assets/img/c7.jpg" alt="c7" /></figure>
                                <div className="card-body">
                                    <h5><a href="#">Financial Sustainability</a></h5>
                                    <p className="card-text">Fintechs, Banks, NBFCs, Enterprises, Retail- Retailers, Distributors, Suppliers, Vendors in the Supply chain, eCommerce App Aggregators, Logistics &amp; Warehousing</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="card card-shadow card-four">
                                <figure><img src="./website/assets/img/c8.jpg" alt="c8" /></figure>
                                <div className="card-body">
                                    <h5><a href="#">Make Business Strategy</a></h5>
                                    <p className="card-text">No unnecessary cost or time wastage to setup this whole infrastructure. Take advantage of the existing stable secure tech infrastructure &amp; focus on offering services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DigitalAutomation;