import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Service from '../adminService';
import { GetDashboard, GetEmployeeallTXNDetail, getApi } from './service/adminService';
import Loader from '../components/Loader/loader';
import { Line } from 'react-chartjs-2';
import { TimeZone } from '../components/TimeZone/TimeZone';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

const Dashboard = (props) => {
	const [loader, setloader] = useState(true)
	const [totalEmployer, setTotalEmployer] = useState('');
	const [totalLender, setTotalLender] = useState('');
	const [totalMerchant, setTotalMerchant] = useState('');
	const [totalSettlement, setTotalSettlement] = useState('');
	const [totalBorrower, setTotalBorrower] = useState('');
	const [totalLenderInterest, settotalLenderInterest] = useState('');
	const [totalTransaction, settotalTransaction] = useState("")
	const admin = JSON.parse(localStorage.getItem("admin"));
	const navigate = useNavigate()
	const prefix = process.env.REACT_APP_ADMIN_PRIFIX
	const [search, setSearch] = useState('');
	const [pagesCount, setpagesCount] = useState(1);
	const [totalPagesCount, setTotalPagesCount] = useState(10);
	const [dataArray, setDataArray] = useState([]);
	const [sortedBy, setSortedBy] = useState("id")
	const [orderBy, setOrderBy] = useState('desc')
	const [days, setDays] = useState("365")
	const [Graph, setGraph] = useState([]);
	const [customDate, setCustomDate] = useState(false)
	const [label, setLabels] = useState([])
	const [startDate, setstartDate] = useState("")
	const [endDate, setendDate] = useState("")
	// console.log(prefix + "/login")
	const getDashBoard = async (token) => {
		// const response = await GetDashboard(token, days, startDate, endDate);
		const response = await getApi('/dashboard-admin', { days: days, start_date: startDate, end_date: endDate })
		if (response.status) {
			setTimeout(() => {
				setTotalEmployer(response.totalEmployers)
				setTotalLender(response.totalLenders)
				setTotalMerchant(response.totalMerchants)
				setTotalSettlement(response.totalSettelments)
				setTotalBorrower(response.totalSubmittedBorrower)
				settotalLenderInterest(response.totalLenderInterest)
				settotalTransaction(response.totalTransaction)
				setGraph(response?.data?.rows)
				setLabels(response?.data?.labels)
				setloader(false)
			}, 2000);
			setloader(true)
		} else if (response.message === 'Session expired') {
			localStorage.removeItem("admin");
			navigate(prefix + "/login")
		} else {
			console.log("get employees data response", response);
		}
	}
	useEffect(() => {
		getDashBoard(admin.admin_token)
	}, [days])

	const getEmployeeTransactionDetail = async (token) => {
		// const response = await GetEmployeeallTXNDetail(token, search, pagesCount, sortedBy, orderBy);
		const response = await getApi('/borrower-transaction', { search: search, page_number: pagesCount, sortby: sortedBy, orderBy: orderBy });
		// console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
		if (response.status == true) {
			setDataArray(response.rows);
			setTotalPagesCount(response.count)

		} else {
			console.log("get merchant data response", response);
		}
	}
	useEffect(() => {
		getEmployeeTransactionDetail(admin.admin_token)
	}, [search, pagesCount, sortedBy, orderBy])

	const copiedInfo = () => {
		toast.success("Copied")
	}
	const option = {
		indexAxis: 'x',
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				// text: 'Chart Title',
			},
		},
	};

	let labels
	if (label.length > 0) {
		labels = label
	} else {
		labels = Graph.map((graph) => graph.created_at)
	}

	const data = {
		labels,
		datasets: [
			{
				label: 'Transactions',
				// data: [10, 12, 13, 14, 25, 40, 87],
				data: Graph.map((graph) => graph.total_txn),
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: 'Income',
				data: Graph.map((graph) => graph.amount),
				// data: [10, 19, 18, 1, 85, 47, 8],
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
		],
	};

	return (
		<>
			<div className="main-container container-fluid px-0">
				<div className="page-header">
					<div className="page-leftheader">
						<h4 className="page-title mb-0 text-primary">Dashboard</h4>
					</div>
					<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
						<div className="form-group">
							<label className="form-label">Select Days</label>
							<div className="row g-xs">
								<div className="wd-200 mg-b-30">
									<div className="input-group">
										<select className="form-control mb-0" name="loan_type"
											style={{ cursor: 'pointer' }}
											onChange={(e) => {
												if (e.target.value !== "custom") {
													setDays(e.target.value)
													setCustomDate(false)
													setstartDate("")
													setendDate("")
												}
												else {
													setCustomDate(true)
												}
											}}
										>
											<option>Select Days</option>
											<option value="7">Weekly</option>
											<option value="30">Monthly</option>
											<option value="365">Yearly</option>
											<option value="custom">Custom Date</option>
										</select>
										{customDate && (
											<DateRangePicker
												onApply={(event, picker) => {
													setstartDate(picker.startDate.format('YYYY-MM-DD'))
													setendDate(picker.endDate.format('YYYY-MM-DD'))
													setDays("custom")
													// const selectedRange = `${startDate} to ${endDate}`;
													//   setDateRange(selectedRange);												
												}}
												initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}
											>
												<input
													placeholder="Search By Date"
													className="form-control fc-datepicker hasDatepicker"
													type="text"
													defaultValue=""
													style={{ cursor: 'pointer' }}
												/>
											</DateRangePicker>
										)}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loader == true ?
					<div style={{ marginLeft: '400px', marginTop: '200px' }}  >
						<Loader

						/> </div> :
					<>
						<div className="row">
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Lenders</p>
										<h2 className="mb-1 font-weight-bold">{totalLender ? totalLender : 0}</h2>
										{(totalLender !== 0 && totalLender !== null) && (<span className="mb-1"><Link to={'/admin/lender'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
										)}</div>
								</div>
							</div>

							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Merchants</p>
										<h2 className="mb-1 font-weight-bold">{totalMerchant ? totalMerchant : 0}</h2>
										{(totalMerchant !== 0 && totalMerchant !== null) && (<span className="mb-1">
											<Link to={'/admin/merchant'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>)}
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Borrower(Active)</p>
										<h2 className="mb-1 font-weight-bold">{totalBorrower ? totalBorrower : 0}</h2>
										{(totalBorrower !== 0 && totalBorrower !== null) && (
											<span className="mb-1"><Link to={'/admin/borrower'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>)}
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Employers</p>
										<h2 className="mb-1 font-weight-bold">{totalEmployer ? totalEmployer : 0}</h2>
										{(totalEmployer !== 0 && totalEmployer !== null) && (
											<span className="mb-1"><Link to={'/admin/employer'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
										)}</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Settlements</p>
										<h2 className="mb-1 font-weight-bold">{totalSettlement ? totalSettlement : 0}</h2>
										{(totalSettlement !== 0 && totalSettlement !== null) && (
											<span className="mb-1"><Link to={'/admin/bnpl_settlement'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
										)}</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Lender Interest</p>
										<h2 className="mb-1 font-weight-bold">{totalLenderInterest ? totalLenderInterest : 0}</h2>
										{(totalLenderInterest !== 0 && totalLenderInterest !== null) && (
											<span className="mb-1"><Link to={'/admin/lenderinterest'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
										)}</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Transaction</p>
										<h2 className="mb-1 font-weight-bold">{totalTransaction ? totalTransaction : 0}</h2>
										{(totalTransaction !== 0 && totalTransaction !== null) && (
											<span className="mb-1"><Link to={'/admin/employee_transaction'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Transaction</Link></span>
										)}</div>
								</div>
							</div>
						</div>
						<div className="row"><div className='col-xl-6 col-lg-6 col-md-12'>
							<div className="table-responsive">
								<div className="horizontal-scroll">
									<table className="table table-hover card-table table-vcenter text-nowrap ">
										<thead className="border-bottom-0 pt-3 pb-3">
											<tr>
												<th className="font-weight-bold">TXN No.   </th>
												<th className="font-weight-bold">Merchant    </th>
												<th className="font-weight-bold"> Settled Amount   </th>
												<th className="font-weight-bold">TXN Date  </th>
											</tr>
										</thead>
										{loader == true ? <tbody>
											<td /><td />
											<Loader />
										</tbody> :
											<>
												<tbody>
													{dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
														const { txn_id, is_settled } = option;
														let serial_num = ((10 * (pagesCount - 1)) + index + 1);
														const commissionRate = option?.merchantDetail?.commission
														const commission = (commissionRate / 100) * option.paid_amount;
														const netAmount = option.paid_amount - commission;
														var num2 = parseFloat(option.earned_amount).toFixed(2)
														return (
															<tr key={serial_num}>
																<td> <span className="font-weight-normal1">{option.txn_id ? txn_id : "NA"}</span>
																	<span>
																		<CopyToClipboard text={txn_id}>
																			<button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
																		</CopyToClipboard>
																	</span>
																</td>
																<td><span className="font-weight-normal1">{option?.merchantTxnDetail?.company_name ? option.merchantTxnDetail?.company_name : "NA"}
																	<br />{option?.merchantTxnDetail?.mobile_number ? option.merchantTxnDetail?.mobile_number : ""}</span></td>
																<td><span className="font-weight-normal1">{is_settled == '1' ? "$" + option.settled_amount : "Pending"}</span></td>
																<td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
															</tr>)
													}
													)}
												</tbody>
												<tbody>
													{dataArray.length == 0 &&
														<tr>
															<td colSpan={10} className='text-center'>No Data Available</td>
														</tr>
													}
												</tbody>
											</>
										}
									</table>
								</div>
							</div>
						</div>
							<div className='col-xl-6 col-lg-6 col-md-12'>
								<div className="card-body" /* style={{ backgroundColor: 'whitesmoke' }} */>
									<Line options={option} data={data} />
								</div>
							</div>
						</div>
					</>}
			</div>
		</>
	)
}

export default Dashboard;