const Reducer = (state, action) => {
    switch (action.type) {

        case "TOGGLE_HEADER": return {
            ...state,
            header: !state.header
        }
        case "Logo_Change": return {
            ...state,
            logo: !state.logo
        }
        case "counterData": return {
            ...state,
            counterData: action.data
        }
        case "counterState": return {
            ...state,
            counterState: !state.counterState
        }


        default: return state
    }
}

export default Reducer;