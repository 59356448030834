import React, { useRef, useState, useEffect } from 'react'
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import PaginationComponent from '../../PaginationComponent';
import { GetTransaction, getApi } from '../service/adminService';
import { Breadcrumbs } from '../../components';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Image } from 'antd';
import Limitfilter from '../../components/Limitfilter/Limitfilter';
import Search from '../../components/Search/Search';

const BNPLtransaction = () => {
    const [loader, setloader] = useState(true)
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("merchant_id")
    const [orderBy, setOrderBy] = useState('desc')
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [Entrieslimit, setEntrieslimit] = useState("10")
    const [Count, setCount] = useState("");
    const [ValidPagination, setValidPagination] = useState(false)

    const GetTransactionDetail = async (token) => {
        // const response = await GetTransaction(token, pagesCount, search, sortedBy, orderBy);
        const response = await getApi("/get-bnpl-transactions", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy, limit: Entrieslimit });

        console.log("fdgdgdgddfgdg", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.rows);
                setTotalPagesCount(response.count);
                setCount(response.count);
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        GetTransactionDetail(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy, Entrieslimit]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h4 className="page-title mb-0 text-primary">Talk To Us </h4> */}
                                <Breadcrumbs order={{ first: { name: "BNPL Transaction" }, second: { name: `Transaction ${Count && `(${Count})`}` } }} />
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link> */}

                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" /> */}
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            <Limitfilter Entrieslimit={Entrieslimit} setEntrieslimit={setEntrieslimit} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">logo  </th>
                                                        <th className="font-weight-bold">Merchant Detail {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Address  {sortedBy == "address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }}></i>}</th>
                                                        <th className="font-weight-bold">ROI  {sortedBy == "roi" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('roi') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('roi') }}></i>}</th>
                                                        <th className="font-weight-bold">Balance {sortedBy == "balance" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('balance') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('balance') }}></i>}</th>
                                                        <th />
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> : <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            const { logo, merchant_name, mobile_number, address, roi, balance, merchant_id } = option;
                                                            var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });


                                                            return (
                                                                <tr key={serial_num} >
                                                                    {option.logo != null ?
                                                                        <td><span className="font-weight-normal1"><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.logo} alt='' /></span></td> :
                                                                        <td><span className="font-weight-normal1" ><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='' /></span></td>}
                                                                    <td><Link className="text-primary" to={prefix + `/merchantdetail/${option.merchant_id}`}><span className="font-weight-normal1">{merchant_name ? merchant_name : "NA"}<br />{mobile_number ? mobile_number : ""}</span></Link></td>
                                                                    <td><span className="font-weight-normal1 ">{address ? address : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{roi ? roi + "%" : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{balance ? "$" + balance : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1"><Link to={prefix + `/transactionbyid/${merchant_id}`} className="view-pro" style={{ width: '150px' }}>View TXN Detail</Link></span></td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr><td colSpan={10} className='text-center'>No Data Available</td></tr>}
                                                    </tbody>
                                                </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={Entrieslimit}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default BNPLtransaction
