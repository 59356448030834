
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import SignatureCanvas from 'react-signature-canvas';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { getApi } from '../admin/service/adminService';
import { GetAgreementdoc } from './Service/webservice';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
import { postApi } from '../employer/service/employService';
import PdfViewer from '../components/PDF/PdfViewer';


const Agreementsignature = () => {
    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //     'pdfjs-dist/build/pdf.worker.min.js',
    //     import.meta.url,
    // ).toString();
    const location = useLocation().search;
    const [pdfdata, setpdfdata] = useState('')
    const [pageNumber, setPageNumber] = useState('1')
    const data = new URLSearchParams(location).get('mob_no')

    console.log(data)
    const getagreementdoc = async () => {
        const response = await GetAgreementdoc(data)
        if (response.status == true) {
            setpdfdata(response.data.text)
        }
    }

    useEffect(() => {
        getagreementdoc()
    }, [])

    const sigRef = useRef();
    const [signature, setSignature] = useState(null);
    const [Img, setImg] = useState("");
    const binaryDataRef = useRef(null);  // Using a ref for persistent value

    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL());
    };

    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    };

    const imageUpload = (e) => {
        // setSignature(e.target.files[0]);
    }



    useEffect(() => {
        if (signature) {
            const base64String = signature.split(',')[1];
            binaryDataRef.current = atob(base64String);  // Store in ref instead of variable
        }
    }, [signature]);

    const form = useFormik({
        initialValues: {
            logo: "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // logo: yup.string().required('Please select image'),

        }),

        onSubmit: async (values) => {
            if (signature) {
                const base64String = signature.split(',')[1];
                const binaryData = atob(base64String);
                const byteArray = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    byteArray[i] = binaryData.charCodeAt(i);
                }
                const formData = new FormData();
                // formData.append("logo", binaryDataRef.current); 
                formData.append("logo", new Blob([byteArray]));
                let response = await postApi("/ewa-pay-to-thirdroc", formData, true);
                sigRef.current.clear();
                setSignature(null);
                if (response.status === true) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }
        }
    });

    console.log(pdfdata, "agreement-signature")

    return (
        <>
            <div className='column has-text-centered' id='url' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '0px' }}>
                {/* <h3 className='btn btn-primary' >Agreement Detail And Attach Signature </h3> */}
                {/* <Document file={pdfdata} >
                    <Document file='/21_9179370347_Direct_Deduction_Authorisation_Thirdroc.pdf' >
                    <Page pageNumber={1} />
                </ Document> */}

                {/* <PdfViewer pdfdata={pdfdata} /> */}
                <iframe
                    title="PDF Viewer"
                    src='./Earned Wage Access Agreement_automation template.pdf'
                    style={{ width: '100%', height: '500px', border: 'none' }}
                />
                {/* <pre style={{ color: "black" }}>{pdfdata}</pre> */}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {/* <div className="page-header"> */}
                    <div className="page-leftheader">
                        <h4 className="page-title text-primary">Signature
                        </h4>
                        {/* </div> */}
                    </div>
                    <form onSubmit={form.handleSubmit}>
                        <div /* style={{ display: 'flex', alignItems: 'center' }} */>
                            <div >
                                <br />
                                <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{ className: 'signature' }}
                                    ref={sigRef}
                                    onEnd={handleSignatureEnd}
                                    onChange={(e) => imageUpload(e)}
                                />
                                {form.touched.logo && form.errors.logo ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}
                            </div>
                            {/* {signature && (
                        <div>
                            <h5>Signature Preview:</h5>
                            <Image src={signature} alt="Signature" style={{ border: '1px solid #000', margin: '5px' }} />
                        </div>
                    )} */}
                        </div>
                        <button className="btn btn-primary" onClick={clearSignature}>Clear</button>
                        <button type="submit" className="btn btn-primary m-2">Save</button>
                    </form>
                </div>
            </div>
        </>

    )
}

export default Agreementsignature