import React, { useEffect, useState } from 'react'
import PaginationComponent from '../../../PaginationComponent'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Breadcrumbs } from '../../../components'
// import { Gettime, TimeZone } from '../../../components/TimeZone/TimeZone'
import Loader from '../../../components/Loader/loader'
import { getApi } from '../../service/employService'
import moment from 'moment'
import { TimeZone } from '../../../components/TimeZone/TimeZone'

const Attendancelogs = () => {
    const base_url = process.env.REACT_APP_API_URL;
    const [datasArray, setDatasArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const employer = JSON.parse(localStorage.getItem("employer"));
    const token = employer.employer_token
    const { id, customer_id } = useParams()
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const location = useLocation();
    const current_date_format = new moment().format("YYYY-MM-DD")
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    // const history = useHistory();
    // console.log(id, customer_id)
    // console.log(location)
    const GetAttendancelogs = async () => {
        // const response = await GetAttendancelog(token, id, customer_id, date_Format);
        const response = await getApi(`/attendance-logs/${id}`, { cst_id: customer_id, time_zone: date_Format });

        // console.log(response);
        if (response?.status) {
            setTimeout(() => {
                setDatasArray(response.data)
                setTotalPagesCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        }

        else {

            console.log("get Employer data response", response);
        }
    }
    useEffect(() => {
        GetAttendancelogs(token)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Stores</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/merchant/dashboard" >Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Stores</li>
                        </ol>
                    </nav> */}
                            {/* <Breadcrumbs order={{ first: { name: "Users Log" } }} /> */}
                            <Breadcrumbs order={{ first: { name: "EWA Attendance", path: `${prefix}/employees_attendance` }, second: { name: "Employee Details", path: `${prefix}/employees_attendance/${customer_id}` }, third: { name: "Employee Log" } }} />

                            {/* <Breadcrumbs order={{ first: { name: "Employee Details" }, second: { name: "Employee Log" } }} /> */}
                        </h4>
                    </div>

                    <div className="page-rightheader">
                        <div className="btn-list">
                            <Link to={base_url + prefix + `/export-attendance-logs/${employer.user_name}/${id}`} className="btn btn-outline-primary" ><i className="fa fa-download"></i> Download</Link> </div>
                    </div>

                </div>

                <div className="row">
                    {/* <div className="col-sm-12 col-lg-12">
                <div className="card">
                    <div className="card-header br-0">
                        <h3 className="card-title">Counter List</h3>
                    </div>
                </div>
            </div> */}
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                            <thead className="border-bottom-0 pt-3 pb-3">
                                <tr>
                                    {/* <th className="font-weight-bold">S No.</th> */}
                                    <th className="font-weight-bold">In Time   {/* {sortedBy == "user_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_id') }}></i>} */}</th>
                                    <th className="font-weight-bold">Out Time   {/* {sortedBy == "user_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_type') }}></i>} */}</th>
                                    <th className="font-weight-bold">Total Hours  </th>
                                    <th className="font-weight-bold">Check In From  </th>
                                    <th className="font-weight-bold">Check Out From  </th>
                                    <th className="font-weight-bold">Time Zone  {/* {sortedBy == "time_zone" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }}></i>} */}</th>
                                    <th className="font-weight-bold">Attendance Date </th>
                                    <th className="font-weight-bold">In Time GPS Location</th>
                                    <th className="font-weight-bold">Out Time GPS Location</th>
                                    {/* {sortedBy == "user_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_type') }}></i>} */}
                                </tr>
                            </thead>
                            {loader == true ? <tbody>
                                <td />
                                <div >
                                    <Loader /> </div> </tbody> :
                                <>

                                    <tbody>
                                        {datasArray?.length > 0 && datasArray?.map((option, i) => {
                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                            const { in_time, out_time, attendance_date, time_zone, in_gps_location, out_gps_location, in_from, out_from } = option;
                                            var timeZonesIN = TimeZone(option.in_time).timeZone
                                            // var timeZonesOUT = moment.utc(option.out_time).format("HH:mm")
                                            var timeZonesOUT = TimeZone(option.out_time).timeZone
                                            // moment.utc(option.out_time).format("YYYY-MM-DDTHH:mm");

                                            const calculateTotalTime = (inTime, outTime) => {
                                                if (!in_time || !out_time) {
                                                    return "-";
                                                }
                                                const inTimeDate = moment(inTime)
                                                const outTimeDate = moment(outTime)
                                                const diffInMinutes = outTimeDate.diff(inTimeDate, 'minutes');
                                                const hours = Math.floor(diffInMinutes / 60);
                                                const remainingMinutes = diffInMinutes % 60;
                                                return `${hours} hours ${remainingMinutes} minutes`;
                                            };
                                            return (
                                                <tr key={serial_num}>
                                                    <td>{option.in_time ? timeZonesIN : "-"}</td>
                                                    <td>{option.out_time ? timeZonesOUT : "-"}</td>
                                                    <td>{calculateTotalTime(option.in_time, option.out_time)}</td>
                                                    <td><span className="content_fix" /* style={{ fontSize: 'smaller' }} */>{option?.in_from != null ? in_from : "--"}</span></td>
                                                    <td><span className="content_fix" /* style={{ fontSize: 'smaller' }} */>{option?.out_from != null ? out_from : "--"}</span></td>
                                                    <td>{option?.time_zone ? time_zone : "--"}</td>
                                                    <td>{option.attendance_date ? attendance_date : "-"}</td>
                                                    <td>{option?.in_gps_location ? in_gps_location : "--"}</td>
                                                    <td>{option?.out_gps_location ? out_gps_location : "--"}</td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                    <tbody>
                                        {datasArray.length == 0 &&
                                            <tr>
                                                <td colSpan={8} className='text-center'>No Data Available</td>
                                            </tr>}
                                    </tbody>
                                </>}
                        </table>
                    </div>
                </div>
                {/* {pagesCount > 0 && datasArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''} */}
            </div>
        </>
    )
}

export default Attendancelogs
