import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { postApi, posttoken } from '../service/employService';
import { error } from 'jquery';
import { setEmployerBusinessType, setEmployerSystemType } from '../../Redux/modalerrorReducer';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader/loader';

const Login = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerId = queryParams.get('customer');
  const loanApplication = queryParams.get('type')
  const user_loan_id = queryParams.get('user_loan_id')
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const ref = useRef()
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const phoneRegExp7 = /^[0-9]{7,15}$/;
  const urlR1 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const [showOtpField, setshowOtpField] = useState(false);
  const [hideResetForm, sethideResetForm] = useState(true);
  const [selected, setSelected] = useState('mobile');
  const [handleSpinner, setHandleSpinner] = useState(false)
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(1);
  const [resendCounter, setResendCounter] = useState(0);
  const [values, setValues] = React.useState({
    password: "", showPassword: false,
  });
  const [valuesConfP, setValuesConfP] = React.useState({
    password: "", showConfPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfPassword = () => {
    setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
  };

  const form = useFormik({
    initialValues: {
      mobile_number: "",
      password: "",
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      // mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number') */.min(7, 'Mobile Number Must Contain 7 Number').max(15, 'Mobile Number Max Contain 15 Number'),
      mobile_number: yup.string()
        .required('Please enter mobile number or email')
        .test(
          'mobile_number',
          'Please enter a valid mobile number or email',
          value => {
            return phoneRegExp7.test(value) || yup.string().email().isValidSync(value);
          }
        )
        .min(7, 'Mobile Number Must Contain 7 Digits')
        .max(320, 'Email Address Must Be Less Than 320 Characters'),
      password: yup.string().required('Please Enter Password'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const response = await postApi("/login", {
        mobile_number: values.mobile_number,
        password: values.password
      });

      if (response.status == false) {
        toast.error(response.message);
      }
      if (response.status == true) {
        resetForm({ values: '' });
        if (response.status) {
          toast.success(response.message);
          let employer = {
            user_type: response.user_type == 'EMPLOYER' ? response.user_type.toLowerCase() : response.user_type,
            employer_token: response.token,
            employer_id: response.employer_id,
            company_name: response.company_name,
            mobile_number: response.mobile_number,
            logo: response.logo,
            user_name: response.user_name,
            role: response.role,
            is_nfc_active: response.is_nfc_active,
            business_type: response?.business_type,
            plan: response.plan,
            payment_status: response.payment_status,
            basic_details_update: response.basic_details_update,
            plan_expire_on: response.plan_expire_on,
            employer_system_type: response.employer_system_type,

          }

          localStorage.setItem('employer', JSON.stringify(employer));
          dispatch(setEmployerBusinessType(employer?.business_type));
          dispatch(setEmployerSystemType(employer?.employer_system_type))
          navigate(loanApplication ?
            `${process.env.REACT_APP_EMPLOYER_PRIFIX}/dashboard?customer=${customerId}&type=${loanApplication}&user_loan_id=${user_loan_id}` :
            `${process.env.REACT_APP_EMPLOYER_PRIFIX}/dashboard`
          )
        } else {
          toast.error(response.message);
          localStorage.removeItem('employer');
          // console.log("#@#@#1234", error);
        }
      }
    }
  });

  const eyeBtnStyle = {
    position: 'absolute',
    marginLeft: '0px',
    marginTop: '0px',
  }

  const eyeBtnStyle1 = {
    position: 'absolute',
    marginLeft: '262px',
    marginTop: '16px',
  }

  const eyeBtnStyle2 = {
    position: 'absolute',
    marginLeft: '0px',
    marginTop: '0px',
  }

  const eyeBtnStyle3 = {
    position: 'absolute',
    marginLeft: '262px',
    marginTop: '16px',
  }

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelected(value);
    forgotPasswordForm.setTouched({})
    forgotPasswordForm.setErrors({})
    forgotPasswordForm.validateForm();
  };

  const forgotPasswordForm = useFormik({
    initialValues: {
      mobile_number: "",
      email: "",
      otp_type: "",
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      mobile_number: selected === "mobile" ? yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number') */.min(7, 'Mobile Number Must Contain 7 Number').max(15, 'Mobile Number Max Contain 15 Number') : "",
      email: selected === "email" ? yup.string().required('Please Enter Email').matches(urlR1, 'Please Enter Valid Email Url') : "",
    }),
    onSubmit: async values => {
      setHandleSpinner(true)
      const phone = {
        mobile_number: values.mobile_number,
        otp_type: selected
      }
      const email = {
        email: values.email,
        otp_type: selected
      }
      const endpoint = selected === "mobile" ? phone : email;
      const response = await posttoken("/send-otp-forgot-pass", endpoint,
      );
      if (response.status == true) {
        setHandleSpinner(false)
        toast.success(response.message);
        setshowOtpField(true);
      } else {
        toast.error(response.message);
        setHandleSpinner(false)
        console.log("#@#@#1234", error);
      }

    }
  });

  const otpForm = useFormik({
    initialValues: {
      otp: "",
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      // mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number') */.min(7, 'Mobile Number Must Contain 7 Number').max(15, 'Mobile Number Max Contain 15 Number'),
      otp: yup.string().required('Please enter otp').matches(phoneRegExp, 'Please enter otp'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const response = await posttoken("/verify-otp", {
        mobile_number: forgotPasswordForm.values.mobile_number,
        email: forgotPasswordForm.values.email,
        otp_type: selected,
        otp: values.otp
      });

      if (response.status == true) {
        resetForm({ values: '' });
        toast.success(response.message);
        sethideResetForm(false)
        setshowOtpField(false)
        // setSeconds(0)
        // setMinutes(0)
        ref.current.click()


      } else {
        toast.error(response.message);
        console.log("#@#@#1234", error);
      }

    }
  });

  const resetPasswordForm = useFormik({
    initialValues: {
      password: "",
      confirmedPassword: "",
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      // mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number') */.min(7, 'Mobile Number Must Contain 7 Number').max(15, 'Mobile Number Max Contain 15 Number'),
      password: yup.string().required('Please Enter Password'),
      confirmedPassword: yup.string().required('Please Enter Confirm Password')/* .matches(regex, 'Please Enter Only Character Values') */
        .oneOf([yup.ref("password"), null], "Password Must Be Same"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const response = await posttoken("/reset-password", {
        mobile_number: forgotPasswordForm.values.mobile_number,
        email: forgotPasswordForm.values.email,
        otp_type: selected,
        password: values.password,
        confirmedPassword: values.confirmedPassword
      });

      if (response.status == true) {
        resetForm({ values: '' });
        toast.success(response.message);
        sethideResetForm(true)
        forgotPasswordForm.setFieldValue("email", "")
        forgotPasswordForm.setFieldValue("mobile", "")
        setSelected('mobile')
      } else {
        toast.error(response.message);
        console.log("#@#@#1234", error);
      }

    }
  });

  const handleClose = () => {
    setshowOtpField(false);
  }

  useEffect(() => {
    if (selected == "mobile") {
      forgotPasswordForm.setFieldValue("email", "")
    } else if (selected == "email") {
      forgotPasswordForm.setFieldValue("mobile", "")
    }
  }, [selected])


  useEffect(() => {
    if (showOtpField) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            if (minutes > 0) {
              setMinutes(prevMinutes => prevMinutes - 1);
              return 59;
            } else {
              clearInterval(interval);
              return 0;
            }
          }
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [showOtpField, minutes]);

  const resendOTP = () => {
    forgotPasswordForm.handleSubmit()
    setResendCounter(resendCounter + 1)
    setMinutes(3);
    setSeconds(0);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="page">
        <div className="page-single bg-back">
          <div className="container">
            <div className="row">
              <div className="col mx-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-12">
                    <div className="mb-5 br-7 text-center"><Link to="/"><img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img loginlogo" alt="logo" /></Link></div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-12">
                    <div className="row p-0 m-0 bg-white br-7">
                      <div className="col-lg-6 p-0">
                        <div className="text-justified text-white p-5 register-1 overflow-hidden">
                          <div className="custom-content">
                            <div className="">
                              <div className="fs-22 mb-4 font-weight-bold text-white">Welcome Back To ThirdRoc!</div>
                              {/* <h6>Personal Loans</h6>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> No Collateral/Security Required. Personal loans don't need you to provide any collateral such as a house or car to avail a personal loan.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible End Use.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible Tenure.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Minimal Documentation.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Quick Disbursal.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible Loan Amount.</div>


                              <h6 className="mt-5">Business Loans</h6>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Unsecured Business Loans/ No Collateral Required</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Minimum KYC</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Furnishes your Working Capital Requirement, Manage operational cost, growth & expansion of Business</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {hideResetForm &&
                        <div className="col-md-8 col-lg-6 p-0 mx-auto">
                          <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                            <div className="card-body">
                              <div className="text-center mb-3">
                                <h3 className="mb-2">Login As Employer</h3>
                              </div>
                              <form className="mt-5" onSubmit={form.handleSubmit}>
                                {form.values.wrong_message ?
                                  <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{form.values.wrong_message}</div>
                                  : ''}
                                <div className="input-group mb-4">
                                  <div className="input-group-text"> <i className="fa-solid fa-user fa-fw" /> </div>
                                  <input type="text" className="form-control" name="mobile_number" {...form.getFieldProps("mobile_number")} placeholder="enter mobile number or email "
                                    autoComplete="off new-password"

                                  />
                                  {form.touched.mobile_number && form.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                                </div>
                                <div className="input-group">
                                  <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true" /> </div>
                                  <input className="form-control" name="password" {...form.getFieldProps("password")} type={values.showPassword ? "text" : "password"} placeholder="Please enter password" id="password-field" autoComplete="off" />
                                  {/* <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes r20"></span> */}
                                  {!values.showPassword ?
                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                    :
                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                  }
                                  {form.touched.password && form.errors.password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                                </div>

                                <div className='text-center mb-3 mt-3'>
                                  <Link to="" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >FORGOT PASSWORD</Link>
                                  {/* <a href="/lender/registration" style={{textAlign:"right"}}>SIGNUP</a> */}
                                </div>
                                <div className="form-group text-center mb-3 mt-3"> <button type="submit" className="btn btn-primary btn-lg w-100 br-7">Log In</button> </div>
                                {/* <div className='text-center mb-3 mt-3'>
                                  <a href="/lender/registration">SIGNUP</a>
                                </div> */}
                                <div className="form-group mt-3">Don't have account?
                                  <Link to={customerId && loanApplication ? `/employer/signup?customer=${customerId}&type=${loanApplication}&user_loan_id=${user_loan_id}` : customerId ? "/employer/registration?customer=" + customerId : "/employer/signup"}
                                    className="font-weight-bold"> {customerId && loanApplication ? "Sign Up" : customerId ? 'Register' : 'Sign Up'}
                                  </Link>
                                </div>
                                <div className="form-group fs-12 text-center"> By logging in, you agree to the following Credit report
                                  {/* <Link to="" className="font-weight-bold">Terms &amp; Conditions</Link> and have read and
                                  acknowledge our <Link to="" className="font-weight-bold">Privacy
                                    &amp; Services.</Link>  */}
                                  <a target="blank"
                                    href={'/terms-of-use'}
                                  /* href={config.PRODUCTION_MODE == 1 ? config.DOMAIN + '/terms-of-use' : config.DEV_DOMAIN + '/terms-of-use'}  */ alt="Terms of use"> &nbsp;Terms & Conditions </a> &nbsp;and have read and
                                  acknowledge our&nbsp;<a rel="noreferrer"
                                    href={'/privacy-policy'}
                                  /* href={config.PRODUCTION_MODE == 1 ? config.DOMAIN + '/privacy-policy' : config.DEV_DOMAIN + '/privacy-policy'} */ alt="Privacy Policy" target="_blank"> Privacy Policy </a>

                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      }
                      {/* ResetPassword modal */}
                      {!hideResetForm &&
                        <div className="col-md-8 col-lg-6 p-0 mx-auto">
                          <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                            <div className="card-body">
                              <div className="text-center mb-3">
                                <h3 className="mb-2">Reset Password</h3>
                                <div className="">Create New Password</div>
                              </div>
                              <form className="mt-5" onSubmit={resetPasswordForm.handleSubmit}>
                                {resetPasswordForm.values.wrong_message ?
                                  <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{resetPasswordForm.values.wrong_message}</div>
                                  : ''}
                                <div className="input-group mb-3">
                                  <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true"></i> </div>
                                  <input className="form-control" type={values.showPassword ? "text" : "password"} placeholder="New Password"
                                    name="password" {...resetPasswordForm.getFieldProps("password")} />
                                  {!values.showPassword ?
                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                    :
                                    <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                  }

                                  {resetPasswordForm.touched.password && resetPasswordForm.errors.password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{resetPasswordForm.errors.password}</div> : ''}
                                </div>
                                <div className="input-group">
                                  <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true"></i> </div>
                                  <input className="form-control" type={valuesConfP.showConfPassword ? "text" : "password"} placeholder="Confirm Password"
                                    name="confirmedPassword" {...resetPasswordForm.getFieldProps("confirmedPassword")} />

                                  {!valuesConfP.showConfPassword ?
                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                    :
                                    <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                  }

                                  {resetPasswordForm.touched.confirmedPassword && resetPasswordForm.errors.confirmedPassword ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{resetPasswordForm.errors.confirmedPassword}</div> : ''}
                                </div>
                                <div className="form-group text-center mb-3 mt-3"> <button type='submit' className="btn btn-primary btn-lg w-100 br-7">Reset Password</button> </div>

                              </form>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal For Forgot Password    */}
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-center">
          <div className="modal-content modal-content-demo">
            <div className="modal-header"> <h6 className="modal-title">Forgot Password</h6>
              <button aria-label="Close" ref={ref} onClick={handleClose} className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
            {showOtpField &&
              <form className="mt-2" onSubmit={otpForm.handleSubmit}>
                {otpForm.values.wrong_message ?
                  <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{otpForm.values.wrong_message}</div>
                  : ''}
                <div className="col-md-12 mt-2">
                  <div className="form-group m-0">
                    <div className="row g-xs">
                      <div className="col-12">
                        <div className="input-group mb-2">
                          <div className="input-group-text"> <i className="fa-solid fa-user fa-fw" /> </div>
                          <input type="text" className="form-control" name="otp" {...otpForm.getFieldProps("otp")} placeholder="Please enter otp" />
                          {otpForm.touched.otp && otpForm.errors.otp ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{otpForm.errors.otp}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="countdown-text">
                      {seconds > 0 || minutes > 0 ? (
                        <p>
                          Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </p>
                      ) : <span>{resendCounter <= 2 && <p>Didn't recieve code?</p>}
                      </span>}

                      {resendCounter <= 2 && <button
                        className='btn btn-primary'
                        disabled={seconds > 0 || minutes > 0}
                        style={{
                          color: seconds > 0 || minutes > 0 ? "#FF5630" : "#FF5630",
                        }}
                        onClick={resendOTP}>
                        Resend OTP
                      </button>}
                    </div>
                  </div>
                </div>
                <div className="form-footer mt-2 mb-2 ms-2 text-center mb-3 mt-3">
                  {resendCounter <= 2 ? <button type="submit" className="btn btn-primary">Submit</button> :
                    <button className='btn btn-primary' disabled>Submit</button>}
                </div>
              </form>}

            {!showOtpField && <form className="mt-2" onSubmit={forgotPasswordForm.handleSubmit}>
              {forgotPasswordForm.values.wrong_message ?
                <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{forgotPasswordForm.values.wrong_message}</div>
                : ''}
              <div className="modal-body application-modal">
                <div className="row">
                  <div className="row  mb-3 d-flex justify-content-around">
                    <div className="col-lg-12 ">
                      <label className="form-check-label">Get OTP:</label><br />
                      <input
                        className=""
                        type="checkbox"
                        value="mobile"
                        checked={selected === 'mobile'}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label ms-3 fs-16">Mobile Number</label>
                      <input
                        className="ms-3"
                        type="checkbox"
                        value="email"
                        checked={selected === 'email'}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label ms-3 fs-16">Email</label>
                    </div>
                  </div>
                  {selected === 'mobile' && <div className="col-md-12">
                    <label className="form-label">Mobile Number</label>
                    <div className="input-group mb-2">
                      <div className="input-group-text"> <i className="fa fa-phone"></i> </div>
                      <input type="number" className="form-control" name="mobile_number" placeholder="Enter Number"  {...forgotPasswordForm.getFieldProps("mobile_number")} />
                      {forgotPasswordForm.touched.mobile_number && forgotPasswordForm.errors.mobile_number ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{forgotPasswordForm.errors.mobile_number}</div> : ''}
                    </div>
                  </div>}
                  {selected === 'email' && <div className="col-md-12">
                    <label className="form-label">Email</label>
                    <div className="input-group mb-2">
                      <div className="input-group-text"> <i className="fa fa-envelope"></i> </div>
                      <input type="email" className="form-control" name="email" placeholder="Enter Email"  {...forgotPasswordForm.getFieldProps("email")} />
                      {forgotPasswordForm.touched.email && forgotPasswordForm.errors.email ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{forgotPasswordForm.errors.email}</div> : ''}
                    </div>
                  </div>}
                  <div className="text-center mb-3 mt-3 form-footer mt-2">
                    <button type="submit" className="btn btn-primary" disabled={handleSpinner}>Submit {handleSpinner && <Loader status={handleSpinner} />}</button>
                  </div>
                </div>
              </div>
            </form>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Login