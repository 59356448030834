import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../components';
import Loader from '../../components/Loader/loader';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import PaginationComponent from '../../PaginationComponent';
import Search from '../../components/Search/Search';

const Banktransfer = () => {
    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("user_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [ValidPagination, setValidPagination] = useState(false)

    // const GetPaidTransaction = async (token) => {
    //     const response = await GetBNPLSettlementDetails(token, search, pagesCount, sortedBy, orderBy)
    //     console.log(response)
    //     if (response.status == true) {
    //         setTimeout(() => {
    //             setDataArray(response.rows)
    //             setTotalPagesCount(response.count)
    //             setloader(false)
    //         }, 2000);
    //         setloader(true)

    //     } else if (response.status == false) {
    //         console.log(response)
    //     }
    // }

    // useEffect(() => {
    //     GetPaidTransaction(admin.admin_token);
    // }, [search, pagesCount, sortedBy, orderBy]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    return (
        <>
            {!showAddNewCases &&

                <div className="main-container container-fluid px-0">
                    <div className="page-header mb-3">
                        <div className="page-leftheader">
                            {/* <h4 className="page-title mb-0 text-primary">Talk To Us </h4> */}
                            <Breadcrumbs order={{ first: { name: "Bank Transfer Detail" } }} />
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                        <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Merchant Detail {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Paid Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                    <th className="font-weight-bold">TXN No.  {sortedBy == "txn_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }}></i>}</th>
                                                    <th className="font-weight-bold">Payment Mode  {sortedBy == "payment_mode" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }}></i>}</th>
                                                    <th className="font-weight-bold">Comment {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>}</th>
                                                    <th className="font-weight-bold">TXN Date {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /><td />
                                                <div  >
                                                    <Loader />
                                                </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            const { logo, txn_docs, merchant_name, merchant_mobile_number, txn_number, payment_mode, comment, updated_at, amount } = option;
                                                            return (
                                                                <tr key={serial_num} >
                                                                    <td><Link className="text-primary" /* to={prefix + `/merchantdetail/${option.merchant_id}`} */><span className="font-weight-normal1">{merchant_name ? merchant_name : "NA"}<br />{merchant_mobile_number ? merchant_mobile_number : ""}</span></Link></td>
                                                                    <td><span className="font-weight-normal1 ">{amount ? "$" + amount : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1 ">{txn_number ? txn_number : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{payment_mode ? payment_mode : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{comment ? comment : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.updated_at ? TimeZone(option.created_at).timeZone : "NA"}</span></td>
                                                                    <td><Link className='btn btn-outline-primary'>View TXN Details</Link></td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>}
                                                    </tbody>
                                                </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                        {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}

                    </div>
                </div>

            }
        </>
    )
}

export default Banktransfer


