import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { getApi, postApi } from '../../service/MerchantService';
import { Link, useNavigate } from 'react-router-dom'
import ImageViewer from 'react-simple-image-viewer';
import QRCode from 'react-qr-code';
import PaginationComponent from '../../../PaginationComponent';
import Loader from '../../../components/Loader/loader';
import { Breadcrumbs } from '../../../components';
import { UseAppContext } from '../../../Context/AppContext';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup';

const StoreList = (props) => {
    let context = UseAppContext()
    const tableRef = useRef(null);
    const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;
    const merchant = JSON.parse(localStorage.getItem("merchant"));
    const merchantUserId = merchant.user_type;
    // const merchantUserId = merchant.user_id
    // const [data, setData] = useState([])
    // const [clock , setClock] = useState("")
    const [loader, setloader] = useState(true)
    const [search, setSearch] = useState('');
    const [copied, setCopied] = useState("");
    const [value, setValue] = useState('');
    const [detail, setDetail] = useState([])
    const [dataArray, setDataArray] = useState([]);
    const [datasArray, setDatasArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const saveSvgAsPng = require('save-svg-as-png')
    const [counterid, setcounterid] = useState();
    const ref = useRef()
    const navigate = useNavigate()


    const getStore = async (token) => {
        // const response = await GetStore(token, pagesCount, search, sortedBy, orderBy);
        const response = await getApi(`/get-allStores`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

        console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.AllStores)
                // setClock(response.AllStores[0].created_at)
                setTotalPagesCount(response.total)
                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem("merchant");
            navigate('/merchant/login')

        }
    }
    //  console.log(clock)

    const getDetail = async (token, Id) => {
        // const response = await GetMerchantrDetailbyID(token, Id);
        const response = await getApi(`/get-store-byId/${Id}`,);

        console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
        console.log(response)
        if (response.status) {
            setDetail(response.data);
            setDatasArray(response.merchant_counter)
            navigate("/merchant/merchant_information", { state: { detail: response.data, datasArray: response.merchant_counter, pagesCount, Id } })
        } else if (response.message == 'Session expired') {
            localStorage.removeItem("merchant");
            navigate('/merchant/login')
        } else {
            console.log("get merchant data response", response);
        }
    }

    const qrValues = (id) => {
        setValue(id)
    }

    const imgValues = (img) => {
        setCopied(img)
    }

    useEffect(() => {
        getStore(merchant.merchant_token)

    }, [pagesCount, search, sortedBy, orderBy])
    // const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL

    const [images, setimages] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // getStore(merchant.merchant_token);
    }
    const imageOptions = {
        scale: 5,
        encoderOptions: 1,
        backgroundColor: 'white',
    }
    const downloadQR = async () => {
        var canvas = document.getElementById('myCanvasId');
        saveSvgAsPng.saveSvgAsPng(canvas, 'qr_code.png', imageOptions);
    };
    const form = useFormik({
        initialValues: {
            user_id: "",
            new_password: "",
            confirm_password: "",
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            new_password: yup.string().required('Please Enter New Password')/* .matches(regex, 'Please Enter Only Character Values') */,
            confirm_password: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("new_password"), null], "Password Must Be Same"),

        }),
        onSubmit: async (values, { resetForm }) => {
            console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
            const response = await postApi("/user-change-password", {
                user_id: counterid,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            });
            resetForm({ values: '' });
            ref.current.click()
            if (response.status == false) {
                console.log(response);
                toast.error(response.message);
                ref.current.click()
            }
            if (response.status == true) {
                toast.success(response.message);
                ref.current.click()
            }

        }
    });
    const [values, setValues] = React.useState({
        new_password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        new_password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',

    }

    const eyeBtnStyle2 = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle3 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',
    }

    const handleNavigate = (id) => {
        navigate(`${prefix}/editstore/` + id.user_id, { state: id })
    }

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbs order={{ first: { name: "Stores List" }/* , second: { name: "Add Store" } */ }} />

                        </h4>
                    </div>
                    {merchantUserId != 'STORE' ?
                        <div className="page-rightheader">
                            <div className="btn-list">
                                <Link to={props.prefix + "/add_store"} className="btn btn-outline-primary mt-2" style={{ cursor: 'pointer' }}><i className="fa fa-plus me-2"></i> Add Store</Link>
                            </div>
                        </div> : ""}

                </div>

                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="card">
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                <th className="font-weight-bold"><span style={{ marginLeft: '3px' }}>Logo</span></th>
                                                <th className="font-weight-bold">Store Detail  {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                {/* <th className="font-weight-bold">Authorize Person Name   {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }}></i>}</th> */}
                                                <th className="font-weight-bold">Company Reg/Tax ID  {sortedBy == "gst_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('gst_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('gst_number') }}></i>}</th>
                                                <th className="font-weight-bold">Website   {sortedBy == "website" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }}></i>}</th>
                                                <th className="font-weight-bold">Address   {sortedBy == "address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }}></i>}</th>
                                                <th className="font-weight-bold">Country   {sortedBy == "country" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('country') }}></i>}</th>
                                                <th className="font-weight-bold">QR code</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td /><td /><td />
                                            <Loader />
                                        </tbody> :
                                            <>
                                                <tbody>
                                                    {dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
                                                        //  const {logo , company_name , mobile_number , full_name , gst_number , website , address , country} = option;
                                                        let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                        return (
                                                            <tr key={serial_num} >
                                                                {option.logo != null ?
                                                                    <td><span className="font-weight-normal1">
                                                                        <img style={{ width: 50, height: 50, borderRadius: '8px' }} src={option.logo} alt='...'
                                                                            data-bs-target="#applicaiton-report" data-bs-toggle="modal" onClick={() => imgValues(option.logo)} /></span></td>
                                                                    : <td><span className="font-weight-normal1" >
                                                                        <img style={{ width: 50, height: 50, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='...' /></span></td>
                                                                }
                                                                <td onClick={() => { getDetail(merchant.merchant_token, option.user_id) }}  ><span className="font-weight-normal1" style={{ cursor: 'pointer' }}>
                                                                    <div className='text-primary'  > {option.company_name ? option.company_name : "--"}
                                                                        <br />  {option.full_name ? option.full_name : "--"}
                                                                        <br />{option.mobile_number ? option.mobile_number : ""}</div></span></td>
                                                                <td><span className="font-weight-normal1">{option.gst_number ? option.gst_number : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.website ? option.website : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.address ? option.address : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.country ? option.country : "--"}</span></td>
                                                                <td> <span className="font-weight-normal1">
                                                                    <button className='btn btn-primary' onClick={() => qrValues(option.mobile_number)}
                                                                        data-bs-target="#pay_emi" data-bs-toggle="modal">view</button>
                                                                </span>
                                                                </td>
                                                                <td><span className="font-weight-normal1" >
                                                                    <Link to={props.prefix + "/addcounter/" + option.user_id} className="btn btn-primary" ><i className="fa fa-plus"></i> {/* Add Counter */}</Link>
                                                                </span>
                                                                    &nbsp;&nbsp;
                                                                    <button className="btn btn-primary" onClick={() => handleNavigate(option)}><i className="fa-solid fa-user-pen"></i>{/*  Edit Store */}</button>
                                                                    {/* <Link to={{pathname:`${props.prefix}/Editstore/${option.user_id}` , state: {option}}} className="btn btn-outline-primary" ><i className="fa-solid fa-user-pen fa-fw me-2"></i> Edit Store</Link> */}
                                                                    &nbsp;&nbsp;

                                                                    <button onClick={() => { setcounterid(option.user_id) }} type="button" className="btn btn-primary"
                                                                        data-bs-target="#add_settlement" data-bs-toggle="modal" ><i className="fas fa-lock-open" />{/* Change Password */}</button>
                                                                    {/* to={{pathname: `${prefix}/employee_detail/${customer_mobile_number}/Existing`, state: {customer_mobile_number}}} */}
                                                                </td>
                                                                {/* <td><span className="font-weight-normal1">{option.status}</span></td>
                                                               {/* <td> <Dropdown>
                                                                        <Dropdown.Toggle className='view-pro bg-silver' id="dropdown-basic" >
                                                                            ACTION
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                           
                                                                            <Dropdown.Item>
                                                                               
                                                                                   
                                                                                    <Link to={props.prefix + '/editProfile/' + option.user_id}> <div style={{width:"50%"}}>Edit Store</div></Link>
                                                                                   
                                                                                    <Link to={props.prefix + "/Addcounter/" +  option.user_id}><div style={{width:"50%"}}> Add Counter</div></Link>
                                                                               
                                                                            </Dropdown.Item>
                                                                          
                                                                        </Dropdown.Menu>
                                                                    </Dropdown> </td> */}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </>
                                        }

                                    </table>
                                    {/* Change Password Start */}
                                    <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content modal-content-demo">
                                                <div className="modal-header">
                                                    <h3 className="modal-title">Change Password</h3>
                                                    <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                        aria-hidden="true">×</span></button>
                                                </div>

                                                <div className="modal-body application-modal">

                                                    <form className="row" onSubmit={form.handleSubmit}>

                                                        <div className="row">
                                                            <div className="row g-xs">

                                                                <div className="col-12">
                                                                    {/* <input type="password"  placeholder='Enter New Password' className="form-control" {...form.getFieldProps("new_password")}/>   */}
                                                                    <input className="form-control" type={values.showPassword ? "text" : "password"} placeholder="New Password"
                                                                        name="password" {...form.getFieldProps("new_password")} />
                                                                    {!values.showPassword ?
                                                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                        :
                                                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                    }
                                                                    {form.touched.new_password && form.errors.new_password ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.new_password}</div> : ''}
                                                                </div>
                                                            </div>
                                                            <div className="row g-xs">
                                                                <div className="col-12">
                                                                    {/* <input type="password"  placeholder='Enter Confirm Password' className="form-control"  {...form.getFieldProps("confirm_password")}/>  */}
                                                                    <input className="form-control" type={valuesConfP.showConfPassword ? "text" : "password"} placeholder="Confirm Password"
                                                                        name="confirmedPassword"  {...form.getFieldProps("confirm_password")} />

                                                                    {!valuesConfP.showConfPassword ?
                                                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                        :
                                                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                    }
                                                                    {form.touched.confirm_password && form.errors.confirm_password ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_password}</div> : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-footer mt-2">
                                                            <button type="submit" className="btn btn-primary" aria-hidden="true" >
                                                                Submit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Change Password End */}
                                    {/* QR Modal Start */}
                                    <div className="modal fade effect-scale show" id="pay_emi" aria-modal="true" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content modal-content-demo">
                                                <div className="modal-header">
                                                    <h6 className="modal-title">QR</h6>
                                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                                        aria-hidden="true">×</span></button>
                                                </div>
                                                <div className="modal-body application-modal">
                                                    {/* <h6>{value}</h6> */}
                                                    <QRCode
                                                        id="myCanvasId"
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={"" + value}
                                                        viewBox={`0 0 256 256`} />
                                                </div>
                                                <Link className='btn btn-primary '
                                                    onClick={() => downloadQR()} > Download QR</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* QR Modal End */}

                                    {/* Logo Modal Start */}
                                    <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                                        <div className="modal-dialog modal-sm" role="document">
                                            <div className="modal-content modal-content-demo">
                                                <div className="modal-header"> <h6 className="modal-title">Logo</h6>
                                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                                <div className="modal-body application-modal" >
                                                    <img src={copied} alt="..." width={80} style={{ marginLeft: "75px" }}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Logo Modal End */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* // View Details Modals Start  */}

                    {/* // View Details Modals End */}

                    {/* <button className='btn btn-primary' data-bs-target="#pay_emi" data-bs-toggle="modal" >pay</button> */}

                    {pagesCount > 0 && dataArray.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}

                </div>
            </div>
            {isViewerOpen && images && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </>
    )
}

export default StoreList;