import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export const Breadcrumbs = ({ order }) => {
  return (
    <>
      <h4 className="page-title mb-0 text-primary ">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {order.first?.path ? <li className="breadcrumb-item" style={order?.second?.name == undefined ? { color: "var(--primary-bg-color) !important" } : {}}>{order?.second?.name == undefined ? order.first.name : <Link className={order?.second?.name ? "breadcrumbslink " : ""} to={order?.first?.path} > {order?.first?.name} </Link>}  </li> : <li className="breadcrumb-item" style={order?.second?.name == undefined ? { color: "var(--primary-bg-color) !important" } : {}}>{order?.second?.name == undefined ? order.first.name : <a >{order.first.name}</a>}  </li>}
            {order?.second?.name ? <li className={order?.third?.name ? "breadcrumb-item " : "breadcrumb-item active"} aria-current="page"> <Link className={order?.third?.name ? "breadcrumbslink " : ""} to={order?.second?.path} > {order?.second?.name} </Link> </li> : null}
            {order?.third?.name ? <li className="breadcrumb-item active" aria-current="page"> <a href={order?.third?.path} >{order?.third?.name}</a>  </li> : null}
          </ol>
        </nav>
      </h4>
    </>
  )
}

export const Breadcrumbsecond = ({ order }) => {
  return (
    <>
      <h4 className="page-title mb-0 text-primary ">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {order.first?.path ? <li className="breadcrumb-item" style={order?.second?.name == undefined ? { color: "var(--primary-bg-color) !important" } : {}}>{order?.second?.name == undefined ? order.first.name : <Link className={order?.second?.name ? "breadcrumbslink " : ""} to={order?.first?.path} > {order?.first?.name} </Link>}  </li> : <li className="breadcrumb-item" style={order?.second?.name == undefined ? { color: "var(--primary-bg-color) !important" } : {}}>{order?.second?.name == undefined ? order.first.name : <a >{order.first.name}</a>}  </li>}
            {order?.second?.name ? <li className="breadcrumb-item active" aria-current="page"> <a href={order?.second?.path} >{order?.second?.name}</a>  </li> : null}
          </ol>
        </nav>
      </h4>
    </>
  )
}
