import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRef } from 'react';
import { GetAllTransactionsByMerchantId, AddSettlement } from '../service/adminService';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Moment from 'react-moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import $ from "jquery";
import PaginationComponent from '../../PaginationComponent';


const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
const AddSettle = () => {
  const tableRef = useRef(null);
  const merchant = JSON.parse(localStorage.getItem("admin"));
  const [data, setData] = useState([])
  const [merchantData, setMerchantData] = useState([])
  const [settlements, setSettlements] = useState([])
  const [search, setSearch] = useState('');
  const [pagesCount, setpagesCount] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(10);
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState('');
  const { id } = useParams('');
  const [transactionPrimaryId, setTransactionPrimaryId] = useState([]);
  const [transactionAmount, setTransactionAmount] = useState([]);
  const [merchantId, setMerchantId] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [adminId, setAdminId] = useState('');
  const [totalAmoumt, setTotalAmount] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [disable, setDisable] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [refreshPage, setPagerefresh] = useState(true);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  // const getAllPendingSettlement = async (token) => {
  //     const response = await GetAllPendingSettlement(token, '1', search);
  //     if (response.status) {
  //         setData(response.allPendingSettalment)
  //     } else {
  //         console.log(response);
  //     }
  // }


  // const getAllPendingSettlement = async (token) => {
  //   const response = await GetAllTransactionsByMerchantId(token,id, pagesCount, search, );
  //   console.log(response)
  //   if (response.status) {

  //     setData(response.PendingSettalmentById)
  //     setMerchantData(response.merchantData)
  //     setTotalPagesCount(response.totalPendingSettalment)
  //   } else {
  //     console.log(response);
  //   }
  // }

  // const addSettlements = async (data) => {
  //     console.log('data addSettlements', data);
  //     const response = await addSettlement(data);
  //     if (response.status) {
  //         setData(response.data)
  //         transction(merchant.merchant_token)
  //     } else {
  //         console.log(response);
  //     }
  // }


  // useEffect(()=>{
  //   getAllPendingSettlement()

  // },[refreshPage])

  useEffect(() => {
    console.log('transactionPrimaryId', transactionPrimaryId, 'transactionAmount', transactionAmount, 'merchantId', merchantId, 'adminId', adminId);
  }, [transactionPrimaryId, transactionAmount, merchantId, paymentMode])

  // useEffect(() => {
  //   getAllPendingSettlement(merchant.admin_token)

  // }, [search , refreshPage , pagesCount])

  const selectSettlement = (id) => {
    // settlements.push(id)
    // setSettlements(settlements.idArr.push(id))
    setSettlements(oldArr => [...oldArr, id]);
  }

  // const addSettlementForm = useFormik({
  //   initialValues: {
  //     amount: transactionAmount,
  //     primary_key: transactionPrimaryId,
  //     merchant_id: merchantId,
  //     admin_id: adminId,
  //     payment_mode: '',
  //     comment: "",
  //     wrong_message: ""
  //   },
  //   enableReinitialize: true,
  //   validationSchema: yup.object({
  //     comment: yup.string().required('Please add a comment'),
  //     payment_mode: yup.string().required('Please add a payment mode '),
  //   }),
  //   onSubmit: values => {
  //     const merchantData = { ...values }
  //     merchantData.merchantTransctionId = merchantData.primary_key
  //     let data = JSON.stringify(merchantData);

  //     AddSettlement(data).then((res) => {
  //       console.log('res', res);

  //       if (res.status == true) {
  //         toast.success("Settlement Done Successfully");
  //         setPagerefresh(false);
  //       }
  //     }).catch((err) => { })

  //   }

  // });
  // show singal amount data

  // const selectTransactions = async (e,id, amount) => {
  //   if (!transactionPrimaryId.includes(id)) {
  //     setTransactionPrimaryId(oldArr => [...oldArr, id]);
  //     let amountValue = [...transactionAmount, amount]
  //     let total = await amountValue.reduce((acc, curr) => {
  //       return Number(acc) + Number(curr)
  //     }, 0)
  //     console.log(e)

  //     if(e.target.checked){
  //     setTotalAmount(total);
  //     setTransactionAmount(oldArr => [...oldArr, amount]);
  //     setMerchantId(merchantData.user_id);
  //     setAdminId(merchant.user_id);
  //     }
  //     else{
  //       setTotalAmount(total-amount)
  //     }
  //   }
  // }

  // with unchecked function

  const selectTransactions = async (e, id, amount) => {
    if (!transactionPrimaryId.includes(id)) {
      setTransactionPrimaryId(oldArr => [...oldArr, id]);
      setTransactionAmount(oldArr => [...oldArr, amount]);
    }

    let total = await transactionAmount.reduce((acc, curr) => Number(acc) + Number(curr), 0);

    if (e.target.checked) {
      setTotalAmount(oldTotal => oldTotal + Number(amount));
      setMerchantId(merchantData.user_id);
      setAdminId(merchant.user_id);
    } else {
      setTotalAmount(oldTotal => oldTotal - Number(amount));
      setTransactionAmount(oldArr => oldArr.filter(transactionAmount => transactionAmount !== amount));
      if (transactionAmount.length === 1) {
        setMerchantId(null);
        setAdminId(null);
      }
    }

    // Check if any checkbox is checked

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let checked = false;
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checked = true;
      }
    });
    setIsChecked(checked);
  }

  // sum all amount

  // function handleButtonClick() {
  //   const rowData = [];
  //   data.forEach((row) => {
  //     rowData.push(row?.paid_amount);
  //   });

  //   let total = rowData.reduce((acc, curr) => {
  //     return Number(acc) + Number(curr)
  //   }, 0)
  //   setTotalAmount(total);
  // }

  function handleCheckClick() {
    const rowData = [];
    data.forEach((row) => {
      rowData.push(row?.paid_amount);
    });

    let total = rowData.reduce((acc, curr) => {
      return Number(acc) + Number(curr)
    }, 0)

    setTotalAmount(total);
    // When a checkbox is unchecked, subtract the corresponding amount from the total amount

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', (e) => {
        if (!e.target.checked) {
          const amount = Number(e.target.dataset.amount);
          setTotalAmount((prevTotal) => prevTotal - amount);
          window.location.reload();
        }
        console.log(totalAmoumt)
      });
    });

    const checkedboxe = document.querySelectorAll('input[type="checkbox"]');
    let checked = false;
    checkedboxe.forEach((checkbox) => {
      if (checkbox.checked) {
        checked = true;
      }
    });
    setIsChecked(checked);
  }

  console.log(totalAmoumt)
  // for disable button

  // const handleInputChange = (event) => {
  //   addSettlementForm.handleChange(event);
  //   if (event?.target?.value) {
  //     setDisable(true)
  //   }
  // }

  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="main-container container-fluid px-0">
        <div className="page-header mb-3">
          <div className="page-leftheader">
            <h4 className="page-title mb-0 text-primary">Merchant Name: <span className=''>{merchantData.company_name}</span>  </h4>
            <img className='ms-5' style={{ width: 80, height: 80, borderRadius: '8px' }} src={S3_BUCKET_URL + 'merchant-logo-' + merchantData.logo} alt="" />
          </div>
          <div style={{ marginLeft: '450px', marginTop: '10px' }}>

            <h4 className="ml-2">{totalAmoumt ? `Total: ${totalAmoumt}` : ""}</h4>
          </div>
          <div className='page-rightheader'>
            <button className='btn btn-primary' data-bs-target="#add_settlement"
              data-bs-toggle="modal" disabled={!isChecked}>+ Add Settlement</button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                    <thead className="border-bottom-0 pt-3 pb-3">
                      <tr>
                        <th><input type="checkbox" name="id" onClick={() => handleCheckClick()} /></th>
                        {/* <th className="font-weight-bold">Sr No.</th> */}
                        <th className="font-weight-bold">Customer</th>
                        <th className="font-weight-bold">Employer</th>
                        <th className="font-weight-bold">Amount</th>
                        <th className="font-weight-bold">Transaction ID</th>
                        <th className="font-weight-bold">Transaction Date</th>
                        {/* <th className="font-weight-bold">STATUS</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.map((option, index) => {

                        return (
                          <tr key={index} >
                            <td><input type="checkbox" name="id" value={option.id} onChange={(e) => selectTransactions(e, option.id, option.paid_amount)} /></td>
                            {/* <td><span className="font-weight-normal1">{index + 1}</span></td> */}
                            <td><span className="font-weight-normal1">{option.first_name}<br />{option.mobile_number}</span></td>
                            <td><span className="font-weight-normal1">{option.employer_name}<br />{option.employer_mobile}</span></td>
                            <td><span className="font-weight-normal1">{option.paid_amount ? "$" + option.paid_amount : ""}</span>
                            </td>

                            <td><span className="font-weight-normal1">{option.txn_id}</span>
                              <span>
                                <CopyToClipboard text={option.txn_id} onCopy={() => setCopied(true)}>
                                  <button className='btn btn-primary btn-sm ms-3'><i className="fa-solid fa-copy"></i></button>
                                </CopyToClipboard>
                              </span>
                            </td>
                            {/* <td><span className="font-weight-normal1">{option.title}</span></td> */}
                            <td><span className="font-weight-normal1"><Moment format="YYYY-MM-DD h:MM A">{option.created_at}</Moment></span></td>
                            {/* <td><span className="font-weight-normal1">{option.status}</span></td> */}
                            {/* <td><span className="font-weight-normal1">
                              <Link to={prefix + '/addSettle/' + option.merchant_id} className='btn btn-primary btn-md ms-3'>Settle</Link>
  
                            </span>  </td> */}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {pagesCount > 0 && data.length > 0 ?
            <>
              <div className="col-md-12">
                <div className="card-body">
                  <PaginationComponent className="justify-content-center"
                    totalItems={totalPagesCount}
                    pageSize={10}
                    maxPaginationNumbers={3}
                    onSelect={(e) => setpagesCount(e)}
                  />
                </div>
              </div>
            </>
            : ''}
        </div>
      </div>
      {/* {
        isOpen ? <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h3 className="modal-title">Settlement</h3>
                <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                  aria-hidden="true">×</span></button>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                <h4> {totalAmoumt ? `Total: ${totalAmoumt}` : ""}</h4>
              </div>
              <div className="modal-body application-modal">

                <form className="mt-5 row" onSubmit={addSettlementForm.handleSubmit}>
                  {addSettlementForm.values.wrong_message ?
                    <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{addSettlementForm.values.wrong_message}</div>
                    : ''}
                  <div className="row">
                    <div className="row g-xs">
                      <div className="col-12">
                        <select className="form-control" name="payment_mode" {...addSettlementForm.getFieldProps("payment_mode")}
                          placeholder="Enter Payment Mode"  >
                          <option value="null">Select One</option>
                          <option value="cash" >Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="bank transfer">Bank Transfer</option>
                        </select>
                        {addSettlementForm.touched.payment_mode && addSettlementForm.errors.payment_mode ?
                          <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.payment_mode}</div> : ''}
                      </div>
                      <div className="col-12">
                        <input type="text" onKeyUp={(e) => handleInputChange(e)} placeholder='comment' {...addSettlementForm.getFieldProps("comment")} value={addSettlementForm?.comment?.values} className="form-control" />
                        {addSettlementForm.touched.comment && addSettlementForm.errors.comment ?
                          <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.comment}</div> : ''}
                      </div>

                    </div>
                  </div>
                  <div className="form-footer mt-2">
                    <button type="submit" className="btn btn-primary" data-bs-dismiss={"modal"} aria-hidden="true" disabled={!disable ? "true" : ""}>
                      Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> : ""
      } */}
      {/* <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h3 className="modal-title">Settlement</h3>
              <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                aria-hidden="true">×</span></button>
            </div>

            <div className="modal-body application-modal">
              <form className="mt-5 row" onSubmit={addSettlementForm.handleSubmit} >
                {addSettlementForm.values.wrong_message ?
                  <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{addSettlementForm.values.wrong_message}</div>
                  : ''}
                <div className="row">
                  <div className="row g-xs">
                  <div className="col-12">
                      <select className="form-control" name="payment_mode" {...addSettlementForm.getFieldProps("payment_mode")}
                        placeholder="Enter Payment Mode"  >
                        <option value="null">Select One</option>
                        <option value="cash" >Cash</option>
                        <option value="cheque">Cheque</option>
                        <option value="bank transfer">Bank Transfer</option>
                      </select>
                      {addSettlementForm.touched.payment_mode && addSettlementForm.errors.payment_mode ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.payment_mode}</div> : ''}
                    </div>
                    <div className="col-12">
                      <input type="text" placeholder='comment' {...addSettlementForm.getFieldProps("comment")} className="form-control" />
                      {addSettlementForm.touched.comment && addSettlementForm.errors.comment ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.comment}</div> : ''}
                    </div>
                    
                  </div>
                </div>
                <div className="form-footer mt-2"><button type="submit" className="btn btn-primary" onClick={ () => setIsOpen(false)}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default AddSettle