import React, { useEffect, useState } from 'react'
import PaginationComponent from '../../PaginationComponent'
import { Breadcrumbsecond } from '../../components';
import Loader from '../../components/Loader/loader';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import { getApi } from '../service/adminService';
import { useParams } from 'react-router-dom';
import { Image } from 'antd';

const PlanHistory = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [sortedBy, setSortedBy] = useState("user_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(false)
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [employerDetail, setemployerDetail] = useState({})
    const [search, setSearch] = useState('');
    const { id } = useParams()


    // console.log(id, "***************************************")
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const viewPlan = async () => {
        try {
            setloader(true)
            const response = await getApi(`/employee-plan-extend-history/${id}`);
            // console.log(response, "***************************");
            if (response.status) {
                setTimeout(() => {
                    setloader(false)
                    setDataArray(response?.rows)
                    setemployerDetail(response.employerDetail)
                    setTotalPagesCount(response.count)
                }, 1000);
            } else {
                console.log(response)
            }

        } catch (error) {
            setloader(false)
            console.log(error)
        }
    }

    useEffect(() => {
        viewPlan()
    }, [])

    return (
        <div>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        <Breadcrumbsecond order={{ first: { name: "Employer", path: `${prefix}/employer` }, second: { name: "Plan History " } }} />
                    </div>
                </div>
                <div className="mb-1">
                    <h6 className='text-dark'> Employer Detail :</h6>
                    <div>
                        <span className='text-secondary mt-2'>{employerDetail ? employerDetail.company_name : ""}</span><br />
                        <span className='text-secondary'>{employerDetail ? employerDetail.email : ""}</span><br />
                        <span className='text-secondary'>{employerDetail ? employerDetail.mobile_number : ""}</span>
                    </div>

                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        {/* <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap">
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                <th className="font-weight-bold">Docs</th>
                                                <th className="font-weight-bold">Plan Detail
                                                    {/* {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>} */}
                                                </th>

                                                <th className="font-weight-bold">comment
                                                    {/* {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>} */}
                                                </th>
                                                <th className="font-weight-bold">Interval
                                                    {/* {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>} */}
                                                </th>
                                                <th className="font-weight-bold">Trail Period
                                                    {/* {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>} */}
                                                </th>

                                                <th className="font-weight-bold">Extend From
                                                    {/* {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>} */}
                                                </th>
                                                <th className="font-weight-bold">Extend Date
                                                    {/* {sortedBy == "extended_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('extended_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('extended_date') }}></i>} */}
                                                </th>
                                                <th className="font-weight-bold">Stripe ID</th>
                                                <th className="font-weight-bold">Created At
                                                    {/* {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>} */}
                                                </th>
                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td />
                                            <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                <Loader /> </div> </tbody> :
                                            <>
                                                <tbody>
                                                    {dataArray?.length > 0 && dataArray?.map((option, index) => {
                                                        let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                        return (
                                                            <tr key={serial_num} >
                                                                <td><span className="font-weight-normal1" ><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option?.txn_docs != null ? option.txn_docs : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' /></span></td>
                                                                <td><span className="font-weight-normal1" >{option.plan_name !== null ? option.plan_name : "--"}
                                                                    <br />{option?.price ? option?.currency == "inr" ? "₹" + option?.price / 100 : "$" + option?.price / 100 : ""}
                                                                </span></td>
                                                                <td><span className="font-weight-normal1">{option?.comment ? option?.comment : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{option?.interval ? option?.interval_count > 1 ? `Every ${option?.interval_count} ${option?.interval}` : option?.interval : "--"}
                                                                </span></td>
                                                                <td><span className="font-weight-normal1">{option?.trial_period_days ? option?.trial_period_days + " Days" : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{option?.extend_from ? option?.extend_from : "--"}</span></td>
                                                                <td className='font-weight-normal1'><span /* className="content_fix"  */>{option.extended_date ? option.extended_date : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{option?.stripe_subscription_id ? option?.stripe_subscription_id : "--"}</span></td>
                                                                <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                                <tbody>
                                                    {dataArray?.length == 0 &&
                                                        <tr>
                                                            <td colSpan={9} className='text-center'>No Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {pagesCount > 0 && dataArray?.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}
                </div>

            </div>
        </div>
    )
}

export default PlanHistory