import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../../service/employService';
import { Breadcrumbsecond } from '../../../../components';
import Loader from '../../../../components/Loader/loader';
import PaginationComponent from '../../../../PaginationComponent';
import moment from 'moment';
import Swal from 'sweetalert2';
import Limitfilter from '../../../../components/Limitfilter/Limitfilter';
// import { TimeZone } from '../../../../components/TimeZone/TimeZone';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import Search from '../../../../components/Search/Search';

const Timesheet = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [Entrieslimit, setEntrieslimit] = useState("10")
    const [dataArray, setDataArray] = useState([]);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [multippleArray, setMultippleArray] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [updateRequestid, setUpdateRequestid] = useState('')
    const [updateName, setUpdateName] = useState('')
    const [updateStartTime, setUpdateStartTime] = useState('')
    const [updateEndTime, setUpdateEndTime] = useState('')
    const [updateStartDate, setUpdateStartDate] = useState('')
    const [updateEndDate, setUpdateEndDate] = useState('')
    const [comment, setComment] = useState('')
    const [selectDate, setSelectDate] = useState(moment().format("YYYY-MM-DD"))
    const [GetUpdatedL2JobType, setgetUpdateL2JobType] = useState([])
    const [jobType, setJobType] = useState([]);
    const [subjobType, setSubJobType] = useState([]);
    const [l2jobType, setL2JobType] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [ValidPagination, setValidPagination] = useState(false)
    const [selectedSubJob, setSelectedSubJob] = useState([]);

    const GetTimesheetDetail = async (token) => {
        try {
            const response = await getApi("/time-sheet", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy, current_date: selectDate, job_id: selectedJob, sub_job_id: selectedSubJob, sub_l2_job_id: GetUpdatedL2JobType, limit: Entrieslimit });
            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }

    // console.log(updateRequestid, updateName, updateStartTime, updateEndTime)

    useEffect(() => {
        GetTimesheetDetail();
    }, [search, pagesCount, sortedBy, orderBy, selectDate, selectedSubJob, GetUpdatedL2JobType, Entrieslimit]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
    }

    const selectTransactions = async (e, id) => {
        let checkArray = await multippleArray;
        let rowIndex = checkArray.findIndex(v => v == id);
        if (rowIndex > -1) {
            checkArray.splice(rowIndex, 1);
            const selectedIndex = selectedData.findIndex(item => item.id === id);
            if (selectedIndex > -1) {
                selectedData.splice(selectedIndex, 1);
            }
        } else {
            checkArray.push(id);
            const selectedItem = dataArray.find(item => item.id === id);
            if (selectedItem) {
                selectedData.push(selectedItem);
            }
        }

        setMultippleArray(checkArray);
        setDataArray((old_data) => [...old_data]);
        if (dataArray.length == multippleArray.length) {
            setIsChecked(true);
        }
        if (dataArray.length != multippleArray.length) {
            setIsChecked(false);
        }
    }

    function handleCheckClick() {
        if (isChecked == false) {
            let pendingData = dataArray.filter(v => v.timesheet_status == 'Pending');
            setSelectedData(pendingData);
            let newdata = pendingData.map(v => parseInt(v.id)).join(',');
            let arrayData = newdata.split(',').map(Number);
            setMultippleArray((old_data) => [...arrayData]);
            setIsChecked(true);
        } else {
            setIsChecked(false);
            setMultippleArray([]);
            setSelectedData([]);
        }
        setDataArray((old_data) => [...old_data]);
    }

    // function handleCheckClick() {
    //     if (isChecked == false) {
    //         let newdata = dataArray.map(v => parseInt(v.id)).join(',')
    //         let arrayData = newdata.split(',').map(Number);
    //         setMultippleArray((old_data) => [...arrayData]);
    //         setSelectedData(dataArray);
    //         setIsChecked(true);
    //     } else {
    //         setIsChecked(false);
    //         setMultippleArray([]);
    //         setSelectedData([]);
    //     }
    //     setDataArray((old_data) => [...old_data]);
    // }

    const checheked = (item) => {
        return multippleArray.includes(item) ? 1 : 0;
    }



    const UpdateTimesheetStatus = () => {
        const dataFilter = dataArray.filter(data => multippleArray.includes(data.id));
        // console.log(dataFilter, "***********", multippleArray);
        const employeeList = dataFilter.map((item, index) => {
            return `
          <tr>
            <td>${index + 1}</td>
            <td>${item.employeeDetail.first_name} ${item.employeeDetail.last_name}</td>
            <td>${item.employeeDetail.mobile_number}</td>
          </tr>
        `;
        }).join('');

        const tableHtml = `
        
        <table style="${dataFilter.length > 3 ?
                'height: 150px; overflow-y: auto; display: block; marginTop: 19px; border: 1px solid #ddd; border-collapse: collapse; margin: 20px 0;'
                : 'display: block; marginTop: 19px; border: 1px solid #ddd; border-collapse: collapse; margin: 20px 0;'}">
      <thead>
        <tr>
          <th style="background-color: #f0f0f0; padding: 9px; border: 1px solid #ddd;">S.No</th>
          <th style="background-color: #f0f0f0; padding: 9px; border: 1px solid #ddd;">Employee Name</th>
          <th style="background-color: #f0f0f0; padding: 9px; border: 1px solid #ddd;">Mobile Number</th>
        </tr>
      </thead>
      <tbody>
        ${employeeList}
      </tbody>
    </table>
      `;
        Swal.fire({
            title: 'Are you sure?',
            text: 'hello',
            html: `You are about to update the timesheet status for the following employees:${tableHtml} You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Approved',
            cancelButtonText: 'Rejected',
            showDenyButton: true,
            denyButtonText: 'Cancel',
            denyButtonColor: '#2c2b2b',
        }).then(async (result) => {
            let status
            if (result.isConfirmed) {
                status = 'approved';
            } else if (result.isDenied) {
                return;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                status = 'rejected';
            }

            if (status) {
                const response = await postApi(`/update-time-sheet-status`, {
                    timeSheetArray: multippleArray,
                    timesheet_status: status
                })

                if (response?.status) {
                    Swal.fire(
                        'Updated!',
                        `Timesheet has been ${status} Successfully.`,
                        'success'
                    )
                    GetTimesheetDetail();
                    setMultippleArray([])
                } else {
                    toast.error("something went wrong")
                }
            }
        })
    }

    const handleEditModal = useRef()

    const EditTimeshtatusRequest = async () => {
        try {
            const response = await postApi('/edit-time-sheet', {
                id: updateRequestid,
                in_time: `${updateStartDate} ${updateStartTime}`,
                out_time: `${updateEndDate} ${updateEndTime}`,
                comment: comment
            })
            // console.log(response, "******************")
            if (response.status) {
                console.log(response)
                GetTimesheetDetail();
                handleEditModal.current.click()
                toast.success(response.message)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const GetJobType = async () => {
        try {
            const response = await getApi("/get-job-type");

            if (response.status) {
                setJobType(response.data.rows);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetJobType();
    }, []);

    const handleJobChange = (e) => {
        const jobId = e.target.value;
        setIsFilterApplied(true)
        setSelectedJob(jobId);
        const foundItem = jobType.find(item => item.id === parseInt(jobId));
        if (foundItem) {
            setSubJobType(foundItem.subJobType);
            setL2JobType([]);
            setgetUpdateL2JobType([])
        } else if (jobId == "") {
            setIsFilterApplied(false)
            setL2JobType([]);
            setgetUpdateL2JobType([])
            setSelectedSubJob([]);
            GetTimesheetDetail();
        }
    };

    const handleSubJobChange = (e) => {
        const subJobId = e.target.value;
        setSelectedSubJob(subJobId);
        const foundItem = jobType.find(item => item.id == selectedJob);
        if (foundItem) {
            setIsFilterApplied(true)
            const foundSubJob = foundItem.subJobType.find(subJob => subJob.id == parseInt(subJobId));
            if (foundSubJob) {
                setL2JobType(foundSubJob.subL2JobType);
                setIsFilterApplied(true)
            } else {
                setL2JobType([]);
                setgetUpdateL2JobType([])
                setIsFilterApplied(false)
            }
        } else {
            setIsFilterApplied(false)
            setL2JobType([]);
            setgetUpdateL2JobType([])
        }
    };

    const [isFilterApplied, setIsFilterApplied] = useState(false);

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbsecond order={{ first: { name: "Approval" }, second: { name: "Timesheet" } }} />
                        </h4>
                    </div>
                    <div className="page-rightheader">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setpagesCount('1'); setValidPagination(!ValidPagination) }} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    <button className='btn btn-primary ms-3' onClick={() => UpdateTimesheetStatus()} disabled={multippleArray.length == 0}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container container-fluid px-0">
                <div className="row">
                    <div className="col-xl-2 col-lg-6 col-md-6 col-xm-12" >
                        <div className="form-group">
                            <label className="form-label">Select Date</label>
                            <div className="row g-xs">
                                <div className="input-group" >
                                    <input
                                        placeholder="Search By Date"
                                        className="form-control fc-datepicker hasDatepicker"
                                        type="date"
                                        defaultValue=""
                                        value={selectDate}
                                        max={moment().format("YYYY-MM-DD")}
                                        onChange={(e) => { setSelectDate(e.target.value); setpagesCount(1); setIsFilterApplied(true) }}
                                        style={{ cursor: 'pointer' }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-6 col-md-6 col-xm-12" >
                        <label>Job Type</label>
                        <div>
                            <select
                                className="form-control mb-0"
                                name="job_id"
                                value={selectedJob}
                                onChange={handleJobChange}
                            // onClick={() => setpagesCount(1)}
                            >
                                <option value="">Select Job Type</option>
                                {jobType.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.job_type}
                                    </option>
                                ))}
                            </select>
                        </div>


                    </div>
                    <div className="col-xl-2 col-lg-6 col-md-6 col-xm-12" >
                        <label>Sub Job Type</label>
                        <div >
                            <select
                                className="form-control mb-0"
                                name="sub_job_id"
                                value={selectedSubJob}
                                onChange={handleSubJobChange}
                            // onClick={() => setpagesCount(1)}
                            >
                                <option value="">Select Sub Job Type</option>
                                {subjobType.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.sub_job_type}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>
                    <div className="col-xl-2 col-lg-6 col-md-6 col-xm-12" >
                        <label>Sub L2 Job Type</label>
                        <div>
                            <select
                                className="form-control mb-0"
                                name="subL2_job_id"
                                // value={l2jobType}
                                onChange={(e) => { setgetUpdateL2JobType(e.target.value); setIsFilterApplied(true) }}
                            // onClick={() => setpagesCount(1)}
                            >
                                <option value="">Select Sub L2 Job Type</option>
                                {l2jobType.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.sub_l2_job_type}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {/* <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} /> */}

                    {/* <Limitfilter Entrieslimit={Entrieslimit} setEntrieslimit={setEntrieslimit} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} /> */}
                    <div className="col-xl-2 col-lg-4 col-md-4 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Select Limit</label>
                            <div className="input-group">
                                <select className="form-control mb-0" name="loan_type" value={Entrieslimit} onChange={(e) => { setEntrieslimit(e.target.value); setpagesCount("1"); setValidPagination(!ValidPagination) }}  >
                                    <option value="10">Show 10 Entries</option>
                                    <option value="50">Show 50 Entries</option>
                                    <option value="100">Show 100 Entries</option>
                                    <option value="250">Show 250 Entries</option>
                                    <option value="500">Show 500 Entries</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-4 col-md-4 col-xm-12 mt-4">
                        <button
                            className='btn btn-dark mt-1 h-55'
                            onClick={() => {
                                setIsFilterApplied(false)
                                setSelectDate(moment().format("YYYY-MM-DD"));
                                setSelectedJob([]);
                                setSelectedSubJob([]);
                                setSearch('')
                                setL2JobType([]);
                                setgetUpdateL2JobType([]);
                                setEntrieslimit('10');
                            }}
                            disabled={!isFilterApplied && Entrieslimit == '10' && search === ""} // add a state to track if filter is applied
                        >
                            <span style={{ marginLeft: 10 }}>Clear filters</span>
                            <i class="fa-solid fa-filter-circle-xmark" style={{ marginLeft: 5 }} />
                        </button>

                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            {dataArray?.some((item) => item.timesheet_status === 'Pending') &&
                                                <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>
                                            }
                                            {/* <th className="font-weight-bold">Logo</th> */}
                                            <th className="font-weight-bold">Employee Detail  {/* {sortedBy == "site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }}> </i>} */}</th>
                                            <th className="font-weight-bold"> In Time {/* {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}> </i>} */}</th>
                                            <th className="font-weight-bold"> Out Time </th>
                                            <th className="font-weight-bold">Duration </th>
                                            <th className="font-weight-bold">Status </th>
                                            <th className="font-weight-bold">Comment </th>
                                            <th className="font-weight-bold">Created Date </th>
                                            <th className="font-weight-bold">Action</th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /> <td /> <td />
                                        <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                            <Loader />
                                        </div> </tbody> : <>
                                        <tbody>
                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                return (
                                                    <tr key={serial_num}
                                                        onClick={(event) => {
                                                            if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'IMG') {
                                                                const checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
                                                                if (checkbox) {
                                                                    checkbox.checked = !checkbox.checked;
                                                                    selectTransactions(event, option.id);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {dataArray?.some((item) => item.timesheet_status === 'Pending') &&
                                                            <td>
                                                                {option?.timesheet_status == 'Pending' &&
                                                                    <input
                                                                        type="checkbox"
                                                                        name={option?.id}
                                                                        checked={checheked(option?.id) === 1 ? true : false}

                                                                        onClick={(event) => {
                                                                            const checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
                                                                            if (checkbox) {
                                                                                checkbox.checked = !checkbox.checked;
                                                                                selectTransactions(event, option.id);
                                                                            }
                                                                        }}
                                                                    />}
                                                            </td>
                                                        }
                                                        <td><span className="font-weight-normal1">{option?.employeeDetail?.first_name ? option?.employeeDetail?.first_name : "--"}  {option?.employeeDetail?.last_name ? option?.employeeDetail?.last_name : ""}<br />
                                                            {option?.employeeDetail?.mobile_number ? option?.employeeDetail?.mobile_number : "--"}<br />
                                                            <span style={{ textTransform: 'none' }}> {option?.employeeDetail?.personal_email ? option?.employeeDetail?.personal_email : "--"}</span></span></td>
                                                        <td><span className="font-weight-normal1">{option.in_time ? moment.utc(option.in_time).format("YYYY-MM-DD") : "--"}</span> <br />
                                                            <span className="font-weight-normal1">{option.in_time ? moment.utc(option.in_time).format("hh:mm A") : ""}</span></td>
                                                        <td><span className="font-weight-normal1">{option.out_time ? moment.utc(option.out_time).format("YYYY-MM-DD") : "--"}</span> <br />
                                                            <span className="font-weight-normal1">{option.out_time ? moment.utc(option.out_time).format("hh:mm A") : ""}</span></td>

                                                        <td><span className="font-weight-normal1">{option?.workDuraion ? option?.workDuraion : "--"}</span></td>
                                                        <td>
                                                            <span className="font-weight-normal1">
                                                                {option?.timesheet_status === 'Pending' ? (
                                                                    <span className='text-warning fw-bold'>{option?.timesheet_status}</span>
                                                                ) : option?.timesheet_status === "Rejected" ? (
                                                                    <span className='text-danger'>{option?.timesheet_status}</span>
                                                                ) : (
                                                                    <span className='text-success'>{option?.timesheet_status}</span>
                                                                )}
                                                            </span>
                                                        </td>
                                                        <td><span className="font-weight-normal1">{option?.comment ? option?.comment : "--"}</span></td>
                                                        <td>
                                                            <span className="font-weight-normal1">{moment.utc(option.created_at).format("YYYY-MM-DD")}</span> <br />
                                                            <span className="font-weight-normal1">{moment.utc(option.created_at).format("hh:mm A")}</span>
                                                        </td>                                                           <td>
                                                            <button className='btn btn-primary'
                                                                data-bs-toggle="modal" data-bs-target="#staticBackdrop1"
                                                                disabled={option?.timesheet_status != 'Pending'}
                                                                onClick={() => {
                                                                    setUpdateRequestid(option.id);
                                                                    setUpdateName(`${option?.employeeDetail?.first_name}  ${option?.employeeDetail?.last_name ? option?.employeeDetail?.last_name : ""}`);
                                                                    setUpdateStartDate(moment.utc(option.in_time).format("YYYY-MM-DD"));
                                                                    setUpdateEndDate(moment.utc(option.out_time).format("YYYY-MM-DD"));
                                                                    setUpdateStartTime(moment.utc(option.in_time).format("hh:mm"));
                                                                    setUpdateEndTime(moment.utc(option.out_time).format("hh:mm"));
                                                                }}
                                                            >Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tbody>
                                            {dataArray.length == 0 &&
                                                <tr>
                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                </tr>
                                            }
                                        </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>



            {/* Update Leave Status Modat Start */}
            <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable modal-lg">
                    <div class="modal-content" /* style={{ width: '108%' }} */>
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Edit Modal</h5>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <form>
                            <div class="modal-body d-flex m-2">
                                <div className='col-md-2'>

                                    <label className="form-label">Employee Name</label>
                                    <div className='me-2 mt-3 text-primary'>{updateName ? updateName : "--"}</div>
                                </div>
                                <div className='col-md-3'>
                                    <label className="form-label">Start Time</label>
                                    <input
                                        type='time'
                                        value={updateStartTime}
                                        onChange={(e) => setUpdateStartTime(e.target.value)}
                                        className='form-control me-2'></input>
                                </div>

                                <div className='col-md-3'>
                                    <label className="form-label">End Time</label>
                                    <input
                                        type='time'
                                        value={updateEndTime}
                                        onChange={(e) => setUpdateEndTime(e.target.value)}
                                        className='form-control me-2'></input>
                                </div>
                                <div className='col-md-4'>
                                    <label className="form-label">Comment</label>
                                    <input
                                        className='form-control'
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        placeholder='Comment Here...'></input>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button ref={handleEditModal} type="button" class="btn btn-dark" data-bs-dismiss="modal"
                                >Close</button>
                                <button type="button" class="btn btn-primary" onClick={() => EditTimeshtatusRequest()}>Edit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* Update Leave Status Modat End */}

        </>
    )
}

export default Timesheet;