import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getApi } from '../../service/MerchantService';
import Loader from '../../../components/Loader/loader';
import PaginationComponent from '../../../PaginationComponent';
import { TimeZone } from '../../../components/TimeZone/TimeZone';

const Settlement = () => {
    const tableRef = useRef(null);
    const merchant = JSON.parse(localStorage.getItem("merchant"));
    const merchantUserId = merchant.user_id
    const [data, setData] = useState([])
    const [search, setSearch] = useState('');
    // const [copied, setCopied] = useState(false);
    const user_id = merchant.user_id
    // const [value, setValue] = useState('');
    // const [settlements, setSettlements] = useState([])
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const navigate = useNavigate()
    const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;

    //  console.log(merchant)

    const GetSettlementDetail = async (token) => {
        // const response = await GetSettlement(token, pagesCount, search,sortedBy,orderBy);
        const response = await getApi(`/merchant-settlement`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });
        // console.log(response)
        try {
            if (response.status == true) {
                setTimeout(() => {
                    setData(response.data)
                    setTotalPagesCount(response.totalSettlement)
                    setloader(false)
                }, 2000);
                setloader(true)

            } else if (response.message == 'Session expired') {
                localStorage.removeItem("merchant");
                navigate('/merchant/login')

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetSettlementDetail(merchant.merchant_token)
    }, [search, pagesCount, orderBy, sortedBy])



    const setCopied = () => {
        toast.success("Copied")
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // transction(merchant.merchant_token);
    }
    const handleNavigate = (option) => {
        navigate(`${prefix}/view_transaction_id/` + option.id, { state: option })
    }

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">Settlement</h4>
                    </div>

                    <div className="page-rightheader">
                        <div className="btn-list">
                            <a href={process.env.REACT_APP_API_URL + process.env.REACT_APP_MARCHNT_PRIFIX + `/export-all-settlement/${user_id} `} className="btn btn-outline-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="card">
                            {/* <div className="card-header br-0">
                                <h3 className="card-title"> Settlement Reports</h3>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword/Name/Transactions ID" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                <th className="font-weight-bold">TXN ID   {sortedBy == "txn_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }}></i>}</th>

                                                <th className="font-weight-bold">Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                <th className="font-weight-bold">Payment Mode  {sortedBy == "payment_mode" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('payment_mode') }}></i>}</th>
                                                <th className="font-weight-bold">Status {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}></i>}</th>
                                                <th className="font-weight-bold">Transaction Date {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                <th className="font-weight-bold">Action</th>
                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td /><td />
                                            <Loader />
                                        </tbody> :
                                            <>
                                                <tbody>
                                                    {data && data.map((option, index) => {
                                                        // var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });

                                                        return (
                                                            <tr key={index} >
                                                                <td><span className="font-weight-normal1">{option.txn_number}</span>
                                                                    <span>
                                                                        <CopyToClipboard text={option.txn_number} onCopy={() => setCopied(true)}>
                                                                            <button className='btn btn-primary btn-sm ms-3'><i className="fa-solid fa-copy"></i></button>
                                                                        </CopyToClipboard>
                                                                    </span>
                                                                </td>
                                                                {/* <td><span className="font-weight-normal1">{option.full_name}</span></td> */}
                                                                <td><span className="font-weight-normal1">{option.amount ? "$" + option.amount : "NA"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.payment_mode ? option.payment_mode : "NA"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.status}</span></td>
                                                                <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                <td> <button className="btn btn-primary" onClick={() => handleNavigate(option)}>  View Transaction </button></td>

                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                                <tbody>
                                                    {data.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {pagesCount > 0 && data.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}

                </div>
            </div>
        </>
    )
}

export default Settlement