import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PaginationComponent from '../../PaginationComponent';
import { getApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import { Breadcrumbs } from '../../components';
import { Toaster } from 'react-hot-toast';
const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const AllEmployeeOfEmployer = (props) => {

    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [count, setCount] = useState('')


    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token
    // console.log(admin.admin_token)
    const { id } = useParams();
    //  const getAllCityData = async (token,pageNumber,filterVlu)=>{
    //     const response = await Getcities(token, pageNumber, filterVlu , sortedBy , orderBy);
    //     console.log("fdgdgdgddfgdg" ,response)
    //    if (response.status) {
    //        setDataArray(response.data);
    //        setTotalPagesCount(response.AllCountry);
    //        settotalItems(response.data)
    //    } else {
    //        console.log("get city data response", response);
    //    }
    //  }

    const getEmployeesData = async () => {
        // const response = await getAllEmployeeByEmployer(token, id, search, pagesCount, sortedBy, orderBy);
        const response = await getApi(`/get-employee-by-employerId/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.AllEmployee);
                setCount(response.AllEmployee)
                //    settotalItems(response.data)
                setloader(false)
            }, 2000);
            setloader(true)

        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getEmployeesData(token, search, pagesCount);
    }, [search, pagesCount, orderBy, sortedBy]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    return (
        <>
            <>
                <Toaster position="top-right" reverseOrder={false} />
                {!showAddNewCases &&
                    <div>
                        <div className="main-container container-fluid px-0">
                            <div className="page-header mb-3">
                                <div className="page-leftheader">
                                    {/* <h4 className="page-title mb-0 text-primary">Employer - { dataArray && dataArray.length>0 ?dataArray[0].current_employer : ""}({count == "" ? 0 : count})</h4> */}
                                    <Breadcrumbs order={{ first: { name: "Employer", path: `${prefix}/employer` }, second: { name: "All Employee " } }} />
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                    <div className="form-group">
                                        <label className="form-label">Search</label>
                                        <div className="row g-xs">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                        <tr>
                                                            <th className="font-weight-bold">Employee  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                            <th className="font-weight-bold">Email   {sortedBy == "personal_email" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('personal_email') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('personal_email') }}></i>}</th>
                                                            <th className="font-weight-bold">Created At  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                            {/* <th className="font-weight-bold " > <span style={{ marginLeft: "50px" }}>Action</span></th> */}
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    {loader == true ? <tbody>
                                                        <td />
                                                        <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                            <Loader /> </div> </tbody> :
                                                        <>
                                                            <tbody>
                                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                    var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                                    return (
                                                                        <tr key={serial_num} >
                                                                            <td><span className="font-weight-normal1">{option.first_name}{option.middle_name}{option.last_name}<br />{option.mobile_number}<br />{option.personal_email}</span></td>
                                                                            <td><span className="font-weight-normal1">{option.personal_email ? option.personal_email : "--"}</span></td>
                                                                            <td><span className="font-weight-normal1">{timezone}</span></td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                )}
                                                            </tbody>
                                                            <tbody>
                                                                {dataArray.length == 0 &&
                                                                    <tr>
                                                                        <td colSpan={9} className='text-center'>No Employee Data Available</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {pagesCount > 0 && dataArray.length > 0 ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCount}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCount(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                }
                {/* <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header"> <h6 className="modal-title">Assign Employer</h6>
                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                        <div className="modal-body application-modal">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword"
                                    value={searchpage} onChange={(e) => setSearchpage(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div><hr />
                            {pagesCheck > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={setTotalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCheck(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>
            </div> */}
            </>
        </>
    )
}



export default AllEmployeeOfEmployer;
