import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getApi } from '../service/adminService';
import PaginationComponent from '../../PaginationComponent';
import Loader from '../../components/Loader/loader';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Search from '../../components/Search/Search';
import { Breadcrumbs } from '../../components';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const Requestlogsbyid = () => {
    const [datasArray, setDatasArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const { id } = useParams();
    const [endDate, setendDate] = useState('');
    const [startDate, setstartDate] = useState('');
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [loader, setloader] = useState(true)
    const [Count, setCount] = useState();
    const [ValidPagination, setValidPagination] = useState(false)
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

    const GetEmployeeLoginLogs = async (token) => {
        // const response = await getEmployeeLoginlogs(token, search, pagesCount, sortedBy, orderBy);
        const response = await getApi(`/borrower-api-request-logs/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log(response);
        if (response?.status) {
            setTimeout(() => {
                setDatasArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        }

        else {
            console.log("get merchant data response", response);
        }
    }
    useEffect(() => {
        GetEmployeeLoginLogs(admin.admin_token)
    }, [search, pagesCount, sortedBy, orderBy, endDate, startDate])

    const handleCallback = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        setstartDate(picker.startDate.format('MM/DD/YYYY'))
        setendDate(picker.endDate.format('MM/DD/YYYY'))
    }

    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate('')
        setendDate('')
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Stores</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/merchant/dashboard" >Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Stores</li>
                        </ol>
                    </nav> */}
                            {/* <Breadcrumbs order={{ first: { name: "Employee Logs" } }} /> */}
                            <Breadcrumbs order={{ first: { name: "Borrower", path: `${prefix}/borrower` }, second: { name: `Request Log ${Count ? '(' + Count + ')' : ''}` } }} />

                        </h4>
                    </div>
                    {/* <div className="page-rightheader">
                        <div className="btn-list">
                            <Link className="btn btn-outline-primary" ><i className="fa fa-download"></i> Download</Link>                    </div>
                    </div> */}
                </div>

                <div className="row">

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Select Date</label>
                            <div className="row g-xs">
                                <div className="wd-200 mg-b-30">
                                    <div className="input-group">
                                        <DateRangePicker onApply={handleCallback} onCancel={handleCallbackOnCancel} initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                            <input placeholder="Search By Date" className="form-control fc-datepicker hasDatepicker" type="text" defaultValue="" />
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                            <thead className="border-bottom-0 pt-3 pb-3">
                                <tr>
                                    {/* <th className="font-weight-bold">S No.</th> */}

                                    <th className="font-weight-bold">User Detail   {sortedBy == "user_info" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_info') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_info') }}></i>}</th>
                                    {/* <th className="font-weight-bold">Req Method {sortedBy == "req_method" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('req_method') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('req_method') }}></i>}</th> */}
                                    <th className="font-weight-bold">Req Status   {sortedBy == "req_from" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('req_from') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('req_from') }}></i>}</th>
                                    {/* <th className="font-weight-bold">URL {sortedBy == "url" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('url') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('req_from') }}></i>}</th> */}
                                    {/* <th className="font-weight-bold">Transaction From {sortedBy == "id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('id') }}></i>}</th> */}
                                    {/* <th className="font-weight-bold">Response Status  {sortedBy == "res_status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('res_status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('res_status') }}></i>}</th>
                                    <th className="font-weight-bold">Comment  {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('comment') }}></i>}</th> */}
                                    <th className="font-weight-bold">Req Data  {sortedBy == "customer_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }}></i>}</th>
                                    <th className="font-weight-bold">Headers  {sortedBy == "id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('id') }}></i>}</th>
                                </tr>
                            </thead>
                            {loader == true ? <tbody>
                                <td />
                                <div >
                                    <Loader /> </div> </tbody> :
                                <>
                                    <tbody>
                                        {datasArray?.length > 0 && datasArray?.map((option, i) => {
                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                            const { customer_id, req_from, req_method, url, comment, res_status, headers, req_data, user_info, req_id } = option;
                                            // const request_data = JSON.parse([option.req_data])
                                            // const response_data = JSON.parse([option?.headers])
                                            // console.log(response_data, "_________________________-")
                                            return (
                                                <tr key={serial_num}>
                                                    <td> <span className="content_fix"> <Link className="text-primary" to={{ pathname: `${prefix}/request/request_by_id/${req_id}`, state: { req_id } }} >{option?.user_info ? user_info : "NA"}<br /> {option?.req_method ? req_method : "--"}<br />{option?.url && url}<br />{TimeZone(option.created_at).timeZone}</Link> </span></td>
                                                    <td> {option?.req_from ? req_from : "--"} <br />{option?.res_status == "Error" ? <span className='text-danger'>{res_status}</span> : <span className='text-success'>{res_status}</span>}<br />{option?.comment ? comment : "--"}</td>
                                                    <td><code className="content_fix"> {req_data ? req_data : "--"}</code> </td>
                                                    <td><code className="content_fix"> {headers ? headers : "--"}</code> </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                    <tbody>
                                        {datasArray.length == 0 &&
                                            <tr>
                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                            </tr>
                                        }
                                    </tbody></>}
                        </table>
                    </div>
                </div>
                {pagesCount > 0 && datasArray.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && datasArray.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </>
    )
}


export default Requestlogsbyid