import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import { AddDocuments, postApi } from '../../service/MerchantService';


const AddDocument = () => {
    const prefix = process.env.REACT_APP_MERCHANT_PRIFIX;

    const navigate = useNavigate();
    const merchant = JSON.parse(localStorage.getItem("merchant"));


    const regex = /[A-Za-z]/;
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const AddForm = useFormik({
        initialValues: {
            account_name: "",
            bank_name: "",
            branch_name: "",
            bsb: "",
            account_number: "",
            confirm_account_number: "",
            status: "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            account_name: yup.string().required('Please Enter Account Holder Name'),
            bank_name: yup.string().required('Please Enter Bank Name'),
            branch_name: yup.string().required('Please Enter Branch Name'),
            bsb: yup.string().required('Please enter Bank State Branch').min(6, "Bank State Branch must contain 6 Character").max(10, "Bank State Branch max contain 10 Character")/* .matches(phoneRegExp, 'Please enter only number values') */,
            account_number: yup.string().required('Please Enter Account Number').max(18, `Account No. Max upto 18 Characters`)
            /* .matches(regex, 'Please Enter Only Character Values') */,
            confirm_account_number: yup.string().required('Please Enter Confirm Account Number').oneOf([yup.ref("account_number"), null], "Account Number Must Be Same").max(18, `Account No. Max upto 18 Characters`),
            status: yup.string().required('Please Select Status '),
        }),

        onSubmit: async values => {
            console.table(values);
            const data = JSON.stringify({
                account_name: values.account_name,
                bank_name: values.bank_name,
                branch_name: values.branch_name,
                bsb: values.bsb,
                account_number: values.account_number,
                confirm_account_number: values.confirm_account_number,
                status: values.status

            });

            // const response = await AddDocuments(data, merchant.merchant_token)
            const response = await postApi("/add-bank-account", {
                account_name: values.account_name,
                bank_name: values.bank_name,
                branch_name: values.branch_name,
                bsb: values.bsb,
                account_number: values.account_number,
                confirm_account_number: values.confirm_account_number,
                status: values.status
            })

            if (response.status) {
                console.log('inside lokendra', response);
                // toast.success(response.message);
                toast.success("Document Added Successfully");
                navigate('/merchant/profile');
                console.log(navigate)
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("merchant");
                navigate('/merchant/login')
            }
            else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    });

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Add Document</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/merchant/profile" >Merchant Profile</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Account</li>
                                </ol>
                            </nav>
                        </h4>

                    </div>
                    {/* <div className="page-rightheader">
                        <div className="btn-list">
                            <Link to={prefix + "/upload_employees"} className="btn btn-primary mt-2" ><i className="fa fa-plus me-2"></i> Upload Bulk Employee</Link>
                        </div>
                    </div> */}
                </div>
                <form className="row" id='registerForm' onSubmit={AddForm.handleSubmit}>
                    <div className="col">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Account Holder Name</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Account Holder Name" {...AddForm.getFieldProps("account_name")} />
                                    </div>
                                    {AddForm.touched.account_name && AddForm.errors.account_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.account_name}</div> : ''}
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Bank Name</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Bank Name" {...AddForm.getFieldProps("bank_name")} />
                                    </div>
                                    {AddForm.touched.bank_name && AddForm.errors.bank_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.bank_name}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Branch Name</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Branch Name" {...AddForm.getFieldProps("branch_name")} />
                                    </div>
                                    {AddForm.touched.branch_name && AddForm.errors.branch_name ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.branch_name}</div> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Status</label>
                            <div className="row g-xs">
                                <div className="wd-200 mg-b-30">
                                    <div className="input-group">
                                        <select className="form-control mb-0" name="status" {...AddForm.getFieldProps("status")} >
                                            <option>Select Status</option>
                                            <option value='primary'>Primary</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                    </div>
                                    {AddForm.touched.status && AddForm.errors.status ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.status}</div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Bank State Branch</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Branch State Branch" {...AddForm.getFieldProps("bsb")} />
                                    </div>
                                    {AddForm.touched.bsb && AddForm.errors.bsb ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.bsb}</div> : ''}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Account Number</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Account Number" {...AddForm.getFieldProps("account_number")} />
                                    </div>
                                    {AddForm.touched.account_number && AddForm.errors.account_number ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.account_number}</div> : ''}
                                </div>
                            </div> </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Confirm Account No.</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter Confirm Account No." {...AddForm.getFieldProps("confirm_account_number")} />
                                    </div>
                                    {AddForm.touched.confirm_account_number && AddForm.errors.confirm_account_number ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.confirm_account_number}</div> : ''}
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="mt-2 mb-5">
                        <div className="col-md-12">
                            <input type="submit" name="next" className="next action-button apply-now-btn ml-00 mt-1" value="Submit" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddDocument;
