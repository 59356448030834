import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../../components'
import { GetCountry, postApi } from '../../service/MerchantService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';

const Editstore = () => {
    const [country, setCountry] = useState([]);
    const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;
    const ids = useLocation();
    const navigate = useNavigate();
    const merchant = JSON.parse(localStorage.getItem("merchant"));
    const urlR1 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const storeData = ids.state
    const getCountry = async () => {
        const response = await GetCountry();
        if (response.status == true) {
            setCountry(response.data);
        } else {
            console.log("get Country data response", response);
        }
    }

    useEffect(() => {
        getCountry()
    }, [])

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const EditForm = useFormik({

        initialValues: {
            store_id: storeData.user_id,
            company_name: storeData.company_name,
            full_name: storeData.full_name,
            country: storeData.country,
            address: storeData.address,
            mobile_number: storeData.mobile_number,
            gst_number: storeData.gst_number,
            website: storeData.website,
            email: storeData.email,
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            company_name: yup.string().required('Please enter company name'),
            full_name: yup.string().required('Please enter company name'),
            address: yup.string().required('Please enter address'),
            country: yup.string().required('Please enter country'),
            //   mobile_number: yup.number().typeError('please enter a valid number').required('Please enter mobile number').positive(),
            mobile_number: yup.string().required('Please enter mobile number').min(7, "Mobile number must contain 7 number").max(15, "Mobile number max contain 15 number").matches(phoneRegExp, 'Please enter only number values'),
            gst_number: yup.string().required('Please enter valid GST number'),
            website: yup.string().required('Please enter valid website'),
            email: yup.string().required('Please Enter Email').matches(urlR1, 'Please Enter Valid Email Url'),

        }),
        onSubmit: async values => {
            // console.log('values', values);
            let data = JSON.stringify(values);

            // const response = await UpdateStoreDetail(merchant.merchant_token, data);
            const response = await postApi("/edit-store", {
                store_id: storeData.user_id,
                company_name: values.company_name,
                full_name: values.full_name,
                country: values.country,
                address: values.address,
                mobile_number: `${values.mobile_number}`,
                gst_number: values.gst_number,
                website: values.website,
                email: values.email
            });

            console.log(response)
            if (response.status == true) {
                navigate(prefix + '/store_list')
                console.log(response)
                toast.success(response.message)
            } else {
                console.log("get Country data response", response);
            }
        }
    });

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">

                    <h4 className="page-title mb-0 text-primary">
                        <Breadcrumbs order={{ first: { name: "Store", path: `${prefix}/store_list` }, second: { name: "Edit Store" } }} />

                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={EditForm.handleSubmit}>
                <div className="row">

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Merchant Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Merchant Name" {...EditForm.getFieldProps("company_name")} />
                                </div>
                                {EditForm.touched.company_name && EditForm.errors.company_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.company_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Name Of Contact Person</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Name" {...EditForm.getFieldProps("full_name")} />
                                </div>
                                {EditForm.touched.full_name && EditForm.errors.full_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Country</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <select className="form-control" name="country" {...EditForm.getFieldProps("country")}>
                                        <option value="">Select Country</option>
                                        {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                                    </select>
                                </div>
                                {EditForm.touched.email && EditForm.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Mobile Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Mobile Number" {...EditForm.getFieldProps("mobile_number")} />
                                </div>
                                {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Address</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Address" {...EditForm.getFieldProps("address")} />
                                </div>
                                {EditForm.touched.address && EditForm.errors.address ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.address}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">ID (Company Registration/Tax ID)</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter ID (Company Registration/Tax ID)" {...EditForm.getFieldProps("gst_number")} />
                                </div>
                                {EditForm.touched.gst_number && EditForm.errors.gst_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.gst_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Website</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Website" {...EditForm.getFieldProps("website")} />
                                </div>
                                {EditForm.touched.website && EditForm.errors.website ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.website}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Enter Email" {...EditForm.getFieldProps("email")} />
                                </div>
                                {EditForm.touched.email && EditForm.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Save Changes</button>
                    </div>


                </div>
            </form>

        </div>
    )
}

export default Editstore
