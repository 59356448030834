import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import Loader from '../../../components/Loader/loader'
import { TimeZone } from '../../../components/TimeZone/TimeZone'
import PaginationComponent from '../../../PaginationComponent'
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { getApi, manageEmployeelimit, postApi } from '../../service/adminService'
import CopyToClipboard from 'react-copy-to-clipboard'
import { error } from 'jquery'


const Wallet = () => {
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [totalAmount, setTotalAmount] = useState()
    const { id } = useParams()
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token
    const [open, setOpen] = useState(false);
    const [openDec, setopenDec] = useState(false);

    const getEmployeeWalletTransaction = async (token) => {
        // const response = await GetEmployeeWalletTransaction(token, id, search, pagesCount, sortedBy, orderBy);
        const response = await getApi(`/borrower-wallet/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.rows);
                setTotalPagesCount(response.count)
                setTotalAmount(response.available_balance)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }
    useEffect(() => {
        getEmployeeWalletTransaction(admin.admin_token)
    }, [search, pagesCount, sortedBy, orderBy])

    const addform = useFormik({
        initialValues: {
            limit: "",
            pay_for: "",
            comment: "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            limit: yup.number().required('Please Enter Limit'),
            pay_for: yup.string().required('Please Select Pay For'),
            comment: yup.string().required('Please Enter Comment'),

        }),
        onSubmit: async (values, { resetForm }) => {
            // const data = JSON.stringify({
            //     "amount": `${values.limit}`,
            //     "txn_type": "credit",
            //     "pay_for": values.pay_for,
            //     "comment": values.comment
            // })

            // const response = await manageEmployeelimit(token, data, id)
            const response = await postApi(`/manage-borrower-wallet/${id}`, {
                amount: `${values.limit}`,
                txn_type: "credit",
                pay_for: values.pay_for,
                comment: values.comment
            })
            console.log(response)
            if (response.status == true) {
                resetForm({ values: '' });
                setOpen(false)
                toast.success(response.message)
                getEmployeeWalletTransaction(admin.admin_token)

            } else {
                toast.error(response.message)
            }
        }
    })

    const debitform = useFormik({
        initialValues: {
            limit: "",
            pay_for: "",
            comment: "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            limit: yup.number().required('Please Enter Limit'),
            pay_for: yup.string().required('Please Select Pay For'),
            comment: yup.string().required('Please Enter Comment'),
        }),
        onSubmit: async (values, { resetForm }) => {
            // const data = JSON.stringify({
            //     "amount": `${values.limit}`,
            //     "txn_type": "debit",
            //     "pay_for": values.pay_for,
            //     "comment": values.comment
            // })
            // const response = await manageEmployeelimit(token, data, id)
            const response = await postApi(`/manage-borrower-wallet/${id}`, {
                amount: `${values.limit}`,
                txn_type: "debit",
                pay_for: values.pay_for,
                comment: values.comment
            })
            console.log(response)
            if (response.status == true) {
                resetForm({ values: '' });
                setopenDec(false)
                toast.success(response.message)
                getEmployeeWalletTransaction(admin.admin_token)

            } else {
                toast.error(response.message)
            }
        }
    })
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const copiedInfo = () => {
        toast.success("Copied")
    }
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_ADMIN_PRIFIX
    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header my-3">
                <div className="page-leftheader">

                    <h6 className="page-title mb-0 text-primary">Manage Wallet Detail</h6>

                </div>

                {/* <div className="page-rightheader">
                    <div className="btn-list">
                        <a href={exportfix + `/export-borrower-transactions?c_id=${id} `} className="btn btn-outline-primary text-primary" download ><i className="fa fa-download me-2"></i> Export Excel</a>
                    </div>
                    
                </div> */}
                <div className="page-rightheader">
                    <h4 className="page-title mb-0" style={{ color: "#3bc391" }}>Total Balance : {totalAmount ? "$" + totalAmount : "0"} </h4>
                </div>
                {totalAmount > 0 ? <div>
                    <button type="button" onClick={() => setOpen(true)} className="btn btn-primary  me-2"> Credit </button>
                    <button type="button" onClick={() => setopenDec(true)} className="btn btn-primary  "> Debit </button>
                </div> : <div><button type="button" onClick={() => setOpen(true)} className="btn btn-primary  me-2"> Credit </button> </div>}
            </div>
            <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
             <div className="form-group">
                <label className="form-label">Loan Type</label>
                <div className="row g-xs">
                    <div className="wd-200 mg-b-30">
                        <div className="input-group">
                            <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                <option>Select Loan</option>
                                <option value="1">BNPL</option>
                                <option value="2">Digital Lending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> 
        </div>*/}
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
        <div className="col-sm-12 col-lg-12">
            <div className="card">
                <div className="card-header br-0">
                    <h3 className="card-title">EMPLOYEE REPORT</h3>
                </div>
            </div>
        </div>
    </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">TXN ID   {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                                            <th className="font-weight-bold">Amount {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('amount') }}></i>} </th>
                                            <th className="font-weight-bold">Transaction Type   {sortedBy == "txn_type" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_type') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_type') }}></i>}</th>
                                            <th className="font-weight-bold">Pay For   {sortedBy == "pay_for" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('pay_for') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('pay_for') }}></i>}</th>
                                            <th className="font-weight-bold">Comment  {sortedBy == "comment" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comment') }}></i>}</th>
                                            <th className="font-weight-bold">Created At {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <td /><td />
                                        <div  >
                                            <Loader /> </div> </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((data, i) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                    const { txn_id, amount, txn_type, pay_for, comment, created_at } = data;

                                                    return (
                                                        <tr key={serial_num}>
                                                            <td><span className="font-weight-normal1"> {txn_id ? txn_id : "NA"} </span>
                                                                <span>
                                                                    <CopyToClipboard text={txn_id} >
                                                                        <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                    </CopyToClipboard>
                                                                </span>
                                                            </td>
                                                            <td>{data.amount ? "$" + amount : "NA"}</td>
                                                            <td>{txn_type == "debit" ? <span className='text-danger'>{data.txn_type ? txn_type : "NA"}</span> : <span className='text-success text-bold'>{data.txn_type ? txn_type : "NA"}</span>}</td>
                                                            <td>{data.pay_for ? pay_for : "NA"}</td>
                                                            <td>{data.comment ? comment : "NA"}</td>
                                                            <td>{TimeZone(created_at).timeZone}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {pagesCount > 0 && dataArray.length > 0 ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}

            </div>

            <Modal show={openDec} onHide={() => setopenDec(false)}>
                <Modal.Header>
                    <Modal.Title>Debit Amount</Modal.Title>
                    <i className="fa fa-close" style={{ cursor: "pointer" }} onClick={() => setopenDec(false)}></i>
                </Modal.Header>
                <Modal.Body>
                    <form action='' onSubmit={debitform.handleSubmit}>
                        <div className="formbox row">
                            <div className="col-12">
                                <input type="number" name='limit' {...debitform.getFieldProps("limit")} min={2} placeholder="Enter Amount" className="form-control" />
                                {debitform.touched.limit && debitform.errors.limit ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{debitform.errors.limit}</div> : ''}
                            </div>
                            <div className="col-12">
                                <select className="form-control" name="pay_for" {...debitform.getFieldProps("pay_for")}
                                    placeholder="Enter Payment Mode"  >
                                    <option value="null">Select One</option>
                                    <option value="ADD_MONEY" >Add Money</option>
                                    <option value="REFERRAL">Referral</option>
                                    <option value="PAY_TO_MERCHANT">Pay To Merchant</option>
                                    <option value="PROMOTION">Promotion</option>
                                </select>
                                {debitform.touched.pay_for && debitform.errors.pay_for ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{debitform.errors.pay_for}</div> : ''}
                            </div>
                            <div className="col-12">
                                <input type="text" placeholder='Enter Comment' {...debitform.getFieldProps("comment")} /* value={debitform?.comment?.values} */ className="form-control" />
                                {debitform.touched.comment && debitform.errors.comment ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{debitform.errors.comment}</div> : ''}
                            </div>
                            <div className="col-md-12 mt-2">
                                <button type="submit" className="btn btn-primary">Set Limit</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>

            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header>
                    <Modal.Title>Credit Amount</Modal.Title>
                    <i className="fa fa-close" style={{ cursor: "pointer" }} type='reset' onClick={() => { setOpen(false); }}></i>
                </Modal.Header>
                <Modal.Body>
                    <form action='' onSubmit={addform.handleSubmit}>
                        <div className="formbox row">
                            <div className="col-12">
                                <input type="number" name='limit' {...addform.getFieldProps("limit")} min={2} placeholder="Enter Amount" className="form-control" />
                                {addform.touched.limit && addform.errors.limit ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{addform.errors.limit}</div> : ''}
                            </div>
                            <div className="col-12">
                                <select className="form-control" name="pay_for" {...addform.getFieldProps("pay_for")}
                                    placeholder="Enter Payment Mode"  >
                                    <option value="null">Select One</option>
                                    <option value="ADD_MONEY" >Add Money</option>
                                    <option value="REFERRAL">Referral</option>
                                    <option value="PAY_TO_MERCHANT">Pay To Merchant</option>
                                    <option value="PROMOTION">Promotion</option>
                                </select>
                                {addform.touched.pay_for && addform.errors.pay_for ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{addform.errors.pay_for}</div> : ''}
                            </div>
                            <div className="col-12">
                                <input type="text" placeholder='Enter Comment' {...addform.getFieldProps("comment")} /* value={addform?.comment?.values} */ className="form-control" />
                                {addform.touched.comment && addform.errors.comment ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{addform.errors.comment}</div> : ''}
                            </div>
                            <div className="col-md-12 mt-2">
                                <button type="submit" className="btn btn-primary">Set Limit</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>

        </div>
    )
}

export default Wallet
