import React, { useEffect, useRef, useState } from 'react'
// import { getApi, postApi } from '../../service/employService';
import { getApi, postApi } from '../services/service'
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import SignatureCanvas from 'react-signature-canvas';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
// import Loader from '../../../components/Loader/loader';
import Loader from '../../components/Loader/loader'
import Swal from 'sweetalert2';
const TextSignature = require('text-signature')

const AgreementModal = (props) => {
    let navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get('customer');
    const type = queryParams.get('type')
    const user_loan_id = queryParams.get('user_loan_id')
    const email = queryParams.get('email')
    // console.log(location.state)
    const token = location?.state?.ewa_advance?.ewa_advance_token
    const sigRef = useRef();
    // const handleScroll = useRef()
    const [data, setdata] = useState("");
    const [errorMessage, setErrorMessage] = useState("")
    const [signType, setSignType] = useState('draw');
    const [signature, setSignature] = useState(null);
    const binaryDataRef = useRef(null);
    const [btnLoader, setBtnLoader] = useState(false)
    const [approve, setApprove] = useState(false)
    const [reject, setReject] = useState(false)

    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL());
    };

    const clearSignature = () => {
        if (sigRef?.current) {
            sigRef.current.clear();
        }
        setSignature("");
    };

    const imageUpload = (e) => {
        // setSignature(e.target.files[0]);
    }
    useEffect(() => {
        if (signature && signType == 'draw') {
            const base64String = signature.split(',')[1];
            binaryDataRef.current = atob(base64String);  // Store in ref instead of variable
        }
    }, [signature]);

    const LoanAppicationApproval = async () => {
        try {
            const response = await getApi(`/employee-loan-application-approval/${customerId}/${user_loan_id}`, token)
            if (response?.status != false) {
                setdata(response)
            }
            if (response.status === false) {
                setErrorMessage(response.message)
                // Swal.fire({
                //     title: `${response.message}`,
                //     icon: "warning",
                //     iconHtml: "!",
                //     showConfirmButton: false,
                //     allowOutsideClick: false,
                //     allowEscapeKey: false
                // });
            }
            // if (response.status === false) {
            //     Swal.fire({
            //         title: `${response.message}`,
            //         icon: "warning",
            //         iconHtml: "!",
            //         confirmButtonText: "LogOut",
            //         allowOutsideClick: false,
            //         allowEscapeKey: false
            //     }).then((result) => {
            //         if (result.isConfirmed) {
            //             localStorage.removeItem("ewa_token");
            //             navigate('/ewa_advance/verification')
            //         }
            //     });
            // }

        } catch (error) {
            console.log(error)


        }
    }


    const form = useFormik({
        initialValues: {
            logo: "",
            comment: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // logo: yup.string().required('Please select image'),
            comment: reject && yup.string().required('Please Add Comment')

        }),

        onSubmit: async (values) => {
            approve && setBtnLoader(true)

            let finalSignature
            // console.log("🚀 ~ onSubmit: ~ signature:", signature)

            if (signature && signType == 'draw') {
                const base64String = signature.split(',')[1];
                const binaryData = atob(base64String);
                const byteArray = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    byteArray[i] = binaryData.charCodeAt(i);
                }

                finalSignature = new Blob([byteArray])
                console.log("🚀 ~ base64String: ~ new Blob([byteArray]):", new Blob([byteArray]))
            }
            else if (signature && signType == 'text') {
                // const signatureContainer = document.getElementById('signature-container');
                var optionsParameter = {
                    width: 500,
                    height: 150,
                    paddingX: 100,
                    paddingY: 100,
                    canvasTargetDom: ".js-canvasTargetDom",
                    font: ["35px", "'Dancing Script'"],
                    color: "black",
                    textString: signature,
                    customFont: {
                        name: "'Dancing Script'",
                        url: "https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap"
                    }
                };

                const textSignature = new TextSignature(optionsParameter);
                textSignature.generateImage(optionsParameter);
                const dataUrl = textSignature.getImageData();
                // console.log("dataUrl------", dataUrl);
                const base64String = dataUrl.split(',')[1];
                const binaryData = atob(base64String);
                const byteArray = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    byteArray[i] = binaryData.charCodeAt(i);
                }

                finalSignature = new Blob([byteArray])
                // console.log("🚀 ~ domtoimage new Blob([byteArray]:", new Blob([byteArray]))
            }

            if (signature || reject) {
                const formData = new FormData();
                // formData.append("logo", binaryDataRef.current); 
                formData.append("image", finalSignature ? finalSignature : null);
                formData.append("employer_comment", form.values.comment);
                formData.append("employer_approval", reject ? "Rejected" : "Approved");

                let response = await postApi(`/loan-application-employer-sign/${customerId}/${user_loan_id}`, token, formData, true);
                // sigRef?.current?.clear();
                setSignature(null);
                if (response.status) {
                    navigate("/ewa_advance/verified_agreement")
                    toast.success(response.message);
                    form.setFieldValue('comment', "")
                    // localStorage.removeItem("ewa_advance");
                } else {
                    toast.error(response.message);
                }
            }

            setBtnLoader(false)
        }
    });


    const [handleModal, setHandleModal] = useState(false)

    useEffect(() => {
        if (customerId && type && user_loan_id && email && token) {
            setHandleModal(true)
            LoanAppicationApproval()

        }
        else {
            // localStorage.removeItem("ewa_advance");
            navigate(`/ewa_advance/verification`)
        }
    }, [])

    // console.log(customerId, loanApplication, user_loan_id)

    return (
        <>
            {data != "" && handleModal ?
                <div className="modal fade show " style={{ display: 'block', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }} id="staticBackdrop"
                    data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg ">
                        <div className="modal-content" style={{ overflowY: 'auto', maxHeight: '527px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">EWA Advance Application Agreement</h5>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}  >
                                <div className="modal-body">
                                    {data &&
                                        <div className="page-leftheader">
                                            <div dangerouslySetInnerHTML={{ __html: data }}></div>
                                        </div>
                                    }
                                </div>

                                <form onSubmit={form.handleSubmit}>
                                    {approve &&
                                        <div>
                                            <h4 className="page-title mb-0 text-primary">Signature
                                            </h4>
                                            <button onClick={(e) => { setSignType("draw"); setSignature("") }}
                                                className={signType == "draw" ? "btn btn-primary me-1 mb-2" : "btn btn-outline-primary mb-2"}
                                                style={{ padding: "10px 108px", borderRadius: "0px" }}>Draw</button>
                                            <button onClick={(e) => { setSignType("text"); setSignature("") }}
                                                className={signType == "draw" ? "btn btn-outline-primary mb-2" : "btn btn-primary me-1 mb-2"}
                                                style={{ padding: "10px 108px", borderRadius: "0px" }}>Text</button>
                                            <br />
                                            {signType == 'draw'
                                                ? <SignatureCanvas
                                                    penColor="black"
                                                    canvasProps={{ className: 'signature' }}
                                                    ref={sigRef}
                                                    onEnd={handleSignatureEnd}
                                                    onChange={(e) => imageUpload(e)}
                                                />
                                                : <div>
                                                    <input
                                                        type="text"
                                                        value={signature}
                                                        className="w-100 p-2 mb-2"
                                                        onChange={(e) => setSignature(e.target.value)}
                                                        placeholder="Enter your signature text"
                                                    />
                                                    <div id="signature-container" className="d-flex justify-content-center" style={{ border: '2px solid #0f2f82', borderStyle: 'dashed', fontSize: "35px", padding: '30px', fontFamily: 'Dancing Script', minHeight: "130px" }}>
                                                        {signature}
                                                    </div>
                                                </div>
                                            }
                                            {form.touched.logo && form.errors.logo ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}

                                            {btnLoader
                                                ? <div className='d-flex justify-content-center align-items-center mt-2'>
                                                    <Loader height={50} width={50} strokeWidthSecondary={4} />
                                                </div>
                                                : <>
                                                    <button className="btn btn-primary" onClick={clearSignature}>
                                                        Clear
                                                    </button>
                                                    <button type="submit" className="btn btn-primary m-2">Save Signature</button>
                                                </>
                                            }
                                        </div>}
                                    {reject &&

                                        <>
                                            <div /* className="col-xl-12 col-lg-12 col-md-12 col-xm-12" */>
                                                <label className='fw-bold text-primary'>Add Comment</label>
                                                <input className='form-control w-500' type='text' placeholder='Enter Comment here..' {...form.getFieldProps('comment')}></input>
                                                {form.touched.comment && form.errors.comment ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.comment}</div> : ''}
                                            </div>
                                            <button type="submit" className="btn btn-primary m-2">Reject Agreement</button>
                                        </>
                                    }
                                </form>
                                <div /* ref={handleScroll} */ className='d-flex justify-content-center mb-3'>
                                    <button className='btn btn-dark'
                                        onClick={() => {
                                            setApprove(true);
                                            setReject(false);
                                            form.setFieldValue('comment', "")
                                            // handleScroll.current.scrollTo({ top: 7000, behavior: 'smooth' });
                                        }}>Approve</button>
                                    <button className='btn btn-danger ms-2'
                                        onClick={() => {
                                            setReject(true);
                                            setApprove(false)
                                            // handleScroll.current.scrollIntoView({ behavior: 'smooth' });
                                        }}>Reject</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :

                <h1 className='d-flex justify-content-center'>{errorMessage}</h1>
            }
            {/* <div className="modal-backdrop fade show"></div> */}
        </>
    )
}

export default AgreementModal