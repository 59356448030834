import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import PaginationComponent from '../../../../PaginationComponent';
import { Breadcrumbs } from '../../../../components';
import { getApi } from '../../../service/lenderService';
import { Toaster } from 'react-hot-toast';

const Usedlimitdetails = () => {
    const [AddedCases, setAddedCases] = useState([]);
    const [TotalCount, setTotalCount] = useState('');
    const [totalinterest, setTotalinterest] = useState('')
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(false);
    const [checkbox, setCheckbox] = useState(false)
    const [dataArray, setDataArray] = useState([]);
    const [transactionPrimaryId, setTransactionPrimaryId] = useState([]);
    const [transactionTRXId, setTransactionTRXId] = useState([])
    const [transactionMerchantId, setTransactionMerchantId] = useState([])
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [refresh, setRefresh] = useState(true)
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [transactionAmount, setTransactionAmount] = useState([]);
    const [transactionAmountt, setTransactionAmountt] = useState([]);
    const [thirdrocamount, setthirdrocamount] = useState(0);
    const [totalAmoumt, setTotalAmount] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [data, setData] = useState([])

    const { id, type } = useParams();

    // const ViewInvestment = async(token) => {                                                                         
    //     const response = await axios.get(`https://thirdroc.ai:8888/lender/lender-investment-amount/${id}?search=${search}&page_number=${pagesCount}&sortby=${sortedBy}&orderby=${orderBy}`, { 
    //     headers: {
    //             "Authorization" : `Bearer ${token}`
    //         } 
    //     })
    //     console.log(response)
    //     setDataArray(response.data.data)
    //     setTotalPagesCount(response.data.count)
    //     setTotalCount(response.data.total_amount)
    //     setTotalinterest(response.data.total_interest)
    // }

    const ViewInvestment = async (token) => {
        const response = await getApi(`/lender-investment-amount/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        //  const response = await ViewInvestmentdetail(token , id , search , pagesCount , sortedBy , orderBy)
        console.log(response.data)
        if (response.status == true) {
            setDataArray(response.data)
            setTotalPagesCount(response.data.count)
            setTotalCount(response.data.total_amount)
            setTotalinterest(response.data.total_interest)
        } else {
            console.log(response)
        }

    }
    useEffect(() => {
        ViewInvestment(token, search, pagesCount)

    }, [search, pagesCount, refresh])


    // const paytomerchant = async(txn_id , merchantId) => {
    //     const check = {
    //         data: [
    //             {
    //                 "orderid": txn_id || transactionTRXId,                  
    //                 "merchantId": merchantId || transactionMerchantId,                  
    //                 "payment_mode": "cash"
    //             }
    //         ]
    //     }
    //     const response = await axios.post(`https://thirdroc.ai:8888/lender/pay-to-merchant`,(check), {
    //         headers: {
    //             "Authorization": `Bearer ${token}`
    //         }
    //     })
    //     console.log(response)
    //     if(response.data.status == true){
    //         toast.success("Pay to Merchant Successfully");
    //     }
    // }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        ViewInvestment(token, search, pagesCount)
    }


    // console.log("12345676" , token)
    // const updatesynch = async() => {
    //     setTransactionPrimaryId([])
    // const data = {
    //         order_id: transactionPrimaryId
    //     }
    //     const response = await axios.post("https://thirdroc.ai:8888/lender/sync-interest",(data), {
    //         headers: {
    //             "Authorization": `Bearer ${token}`
    //         },

    //     })
    //     console.log(response)
    //     if(response.data.status == true){
    //         toast.success("Emi Data sync Successfully");
    //         setCheckbox(false)
    //         settotalItems(false)
    //         setRefresh(false)
    //     //  let checked = false
    //         // ViewInvestment();
    //         // transactionPrimaryId(checked)
    //     }
    // }


    // const selectTransactions = async ( id  , e) => {
    //     // settotalItems(true)
    //     if (!transactionPrimaryId.includes(id)) {
    //       setTransactionPrimaryId(oldArr => [...oldArr, id]);
    //       setCheckbox(true)
    //       settotalItems()

    //     }else {
    //         let x = transactionPrimaryId.shift(id)
    //         console.log(x); 
    //         if(transactionPrimaryId.length == 0){
    //             setCheckbox(false);
    //        }
    //     }
    // }    

    var num3 = parseFloat(totalinterest).toFixed(2)

    //   console.log(transactionPrimaryId)
    const selectTransactionss = async (e, id, txn_id, merchantid, amount, thirRocCom) => {
        if (!transactionPrimaryId.includes(id)) {
            setTransactionPrimaryId(oldArr => [...oldArr, id]);
            setTransactionTRXId(oldArr => [...oldArr, txn_id]);
            setTransactionMerchantId(oldArr => [...oldArr, merchantid]);
            setTransactionAmount(oldArr => [...oldArr, amount]);
            setTransactionAmountt(oldArr => [...oldArr, thirRocCom])
        }

        // console.log(transactionMerchantId)

        let total = await transactionAmount.reduce((acc, curr) => Number(acc) + Number(curr), 0);

        if (e.target.checked) {
            setTotalAmount(oldTotal => oldTotal + Number(amount));
            setthirdrocamount(oldTotal => oldTotal + Number(thirRocCom));
            //   setMerchantId(id);
            //   setAdminId(merchantid);
        } else {
            setTotalAmount(oldTotal => oldTotal - Number(amount));
            setthirdrocamount(oldTotal => oldTotal - Number(thirRocCom));
            //    setTransactionAmount(oldArr => oldArr.filter(transactionAmount => transactionAmount !== amount));
            //    setthirdrocamount(oldArr => oldArr.filter(transactionAmount => transactionAmount !== thirRocCom));
            //   if (transactionAmount.length === 1) {
            //     setMerchantId(null);
            //     setAdminId(null);
            //   }
        }

        // Check if any checkbox is checked

        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let checked = false;
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                checked = true;
            }
            console.log(totalAmoumt)
        });
        setIsChecked(checked);
    }

    function handleCheckClick() {
        const rowData = [];
        dataArray.forEach((row) => {
            rowData.push(row?.merchant_amount && row?.amountPayToThirdroc);
        });

        let total = rowData.reduce((acc, curr) => {
            return Number(acc) + Number(curr)
        }, 0)

        setTotalAmount(total);
        // When a checkbox is unchecked, subtract the corresponding amount from the total amount

        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', (e) => {
                if (!e.target.checked) {
                    const amount = Number(e.target.dataset.amount);
                    setTotalAmount((prevTotal) => prevTotal - amount);
                    //   window.location.reload();
                }

            });
        });

        const checkedboxe = document.querySelectorAll('input[type="checkbox"]');
        let checked = false;
        checkedboxe.forEach((checkbox) => {
            if (checkbox.checked) {
                checked = true;
            }
        });
        setIsChecked(checked);
    }
    //    console.log(totalAmoumt)
    var total1 = parseFloat(thirdrocamount).toFixed(2)
    var total2 = parseFloat(totalAmoumt).toFixed(2)
    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h6 className="page-title mb-0 text-primary"><Link  to={{pathname: `${prefix}/employee`}}>Borrower</Link> <i className='fas fa-arrow-right'/> Details  </h6> */}
                                <Breadcrumbs order={{ first: { name: "Borrower" }, second: { name: `Existing Borrower`, path: `${prefix}/employee` }, third: { name: "Details" } }} />



                                {/* <h4 className="page-title mb-0 text-primary">Borrower</h4> */}
                                {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                                 <button className="btn btn-primary" disabled>Sync</button>} */}
                            </div>
                            <div style={{ marginLeft: '360px', marginTop: '10px' }}>
                                <h5 > <b>{totalAmoumt ? `Merchant Amount:  $${total2}` : ""}</b> </h5>
                                {/*  <button className='btn btn-primary'  onClick={() => paytomerchant()}
              disabled={!isChecked}>+ Pay to Merchant</button>  */} </div>
                            <div style={{ marginLeft: '0px', marginTop: '10px' }}>
                                <h5 > <b>{thirdrocamount ? `ThirdRoc Amount: $${total1}` : ""}</b></h5>
                                {/*  <button className='btn btn-primary' 
              disabled={!isChecked}>+ Pay to ThirdRoc</button>  */}</div>
                            <div className="page-rightheader">


                                {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                                <div className="btn-list">
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                                {/* <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>

                                                        {dataArray.length > 0 && <th> <input type="checkbox" name="id" onClick={() => handleCheckClick()} /> </th>}
                                                        <th className="font-weight-bold">Borrower Detail {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Employer Detail {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Merchant Detail</th>
                                                        {/* <th className="font-weight-bold">Next Due Date</th> */}
                                                        <th className="font-weight-bold">Amount {sortedBy == "total_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_amount') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_amount') }}> </i>}</th>
                                                        <th className="font-weight-bold">Lender Interest {sortedBy == "interest_rate" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('interest_rate') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('interest_rate') }}> </i>}</th>
                                                        <th className="font-weight-bold">Merchant Amount</th>
                                                        <th className="font-weight-bold">ThirdRoc Amount {sortedBy == "interest_rate" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('interest_rate') }} ></i> : <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('interest_rate') }}> </i>}</th>


                                                    </tr>
                                                </thead>

                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    const { id, customer_id, first_name, amount, totalInterest, merchantId, roi, employer_name, cust_merch_txn_id, txn_id, merchant_amount, employer_email, amountPayToThirdroc, mini_rate_of_intrest, employer_mobile, created_at, merchant_name, borrower_mobile, assign_limit, borrower_name, interest_of_days, merchant_mobile, merchant_email, employer_mobile_num, mobile_number, full_name, company_name, total_amount, due_date, interest, late_charges, status, employer_id } = option;
                                                    var num2 = parseFloat(option.merchant_amount).toFixed(2)
                                                    var num3 = parseFloat(option.amountPayToThirdroc).toFixed(2)
                                                    var num4 = parseFloat(option.totalInterest).toFixed(2)
                                                    var Capitalize = (option) => {
                                                        return option.charAt(0).toUpperCase() + option.slice(1);
                                                    }
                                                    var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                    return (
                                                        <tbody>
                                                            <tr key={serial_num} >
                                                                <td><input type="checkbox" name="id" value={option.id} onChange={(e) => selectTransactionss(e, id, cust_merch_txn_id, merchantId, merchant_amount, amountPayToThirdroc)} /></td>
                                                                <td><Link className="text-primary" to={{ pathname: `${prefix}/employee_detail/${borrower_mobile}`, state: { borrower_mobile } }}>
                                                                    <span className="font-weight-normal1">{borrower_name ? Capitalize(borrower_name) : ""}<br />{borrower_mobile}</span></Link></td>
                                                                <td><Link className="text-primary" to={{ pathname: `${prefix}/employer_detail/${employer_id}`, state: { employer_id } }}><span className="font-weight-normal1">
                                                                    {employer_name ? Capitalize(employer_name) : ""}
                                                                    <br />{employer_mobile ? (employer_mobile) : ""}<br />{employer_email ? Capitalize(employer_email) : ""}</span></Link></td>
                                                                <td><span className="font-weight-normal1">{merchant_name ? merchant_name : ""}<br />{merchant_mobile ? merchant_mobile : ""} <br />{merchant_email ? merchant_email : ""} </span></td>
                                                                <td><Link className="text-primary" to={{ pathname: `${prefix}/emibyorderid/${cust_merch_txn_id}`, state: { cust_merch_txn_id } }}>
                                                                    <span className="font-weight-normal1"> {option.amount ? "$" + amount : ""} </span> </Link> </td>
                                                                <td><span className="font-weight-normal1">{option.totalInterest ? "$" + num4 + "(" + roi + "%" + ")" : ""}</span></td>
                                                                <td><span className="font-weight-normal1">{merchant_amount ? "$" + num2 + "(98%)" : ""}</span></td>
                                                                <td><span className="font-weight-normal1"> {amountPayToThirdroc ? "$" + num3 : ""}</span></td>

                                                            </tr></tbody>

                                                    )
                                                }
                                                )}
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Existing Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>
                </div>

            }
        </>
    )

}
export default Usedlimitdetails
