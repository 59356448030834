import Reducer from "./AppReducer";
const { useContext, useReducer } = require("react");
const { createContext } = require("react");


const AppContext = createContext();

const initialValue = {
    header: false,
    logo:false,
    counterState:false,
    counterData:""
}
const AppProvider = ({ children }) => {

    const [state, dispatch] = useReducer(Reducer, initialValue);

    const ToggleHeader = () => {
        dispatch({ type: "TOGGLE_HEADER" })
    }
    const HeaderLogoChange = () => {
        dispatch({ type: "Logo_Change" })
    }
    const handleCounterData = (payload) => {
        dispatch({ type: "counterData",data:payload})
    }
    const handlecounterState = (payload) => {
        dispatch({ type: "counterState" })
    }


  


    return <AppContext.Provider value={{ ...state, ToggleHeader,HeaderLogoChange,handleCounterData,handlecounterState }}>{children}</AppContext.Provider>
}

const UseAppContext = () => {
    return useContext(AppContext);
}

export { AppProvider, UseAppContext };