import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import SignatureCanvas from 'react-signature-canvas';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getApi, postApi } from '../employer/service/employService';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Image } from 'antd';
import { Breadcrumbs } from '../components';
import Swal from 'sweetalert2';
import Loader from '../components/Loader/loader';
import domtoimage from 'dom-to-image';
// import TextSignature from 'text-signature';
const TextSignature = require('text-signature')



const Signature = () => {
    let params = useParams()
    let navigate = useNavigate()
    let customer_id = params?.id
    const sigRef = useRef();
    const [signType, setSignType] = useState('draw');
    const [signature, setSignature] = useState(null);
    const [Img, setImg] = useState("");
    const [data, setdata] = useState("");
    const [btnLoader, setBtnLoader] = useState(false)
    const binaryDataRef = useRef(null);  // Using a ref for persistent value

    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL());
    };

    const GetAgreementDoc = async (token) => {
        try {
            const response = await getApi("/employee-agreement-doc" + "/" + customer_id, { search: "", page_number: "", sortby: "", orderby: "" });
            // const response = await GetNFCDetail(token, search , pagesCount ,sortedBy , orderBy);

            if (response?.status != false) {
                setdata(response)
            }
            if (response.status === false) {
                Swal.fire({
                    title: `${response.message}`,
                    icon: "warning",
                    iconHtml: "!",
                    confirmButtonText: "Go Back",
                    // cancelButtonText: "لا",
                    // showCancelButton: true,
                    // showCloseButton: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/employer/employees")
                    }
                });;

            }
            else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                Navigate('/employer/login')
            }

        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }


    useEffect(() => {
        GetAgreementDoc()
    }, [])

    const clearSignature = () => {
        if (sigRef?.current) {
            sigRef.current.clear();
        }
        setSignature("");
    };

    const imageUpload = (e) => {
        // setSignature(e.target.files[0]);
    }
    useEffect(() => {
        if (signature && signType == 'draw') {
            const base64String = signature.split(',')[1];
            binaryDataRef.current = atob(base64String);  // Store in ref instead of variable
        }
    }, [signature]);

    const form = useFormik({
        initialValues: {
            logo: "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // logo: yup.string().required('Please select image'),

        }),

        onSubmit: async (values) => {

            setBtnLoader(true)

            let finalSignature
            console.log("🚀 ~ onSubmit: ~ signature:", signature)

            if (signature && signType == 'draw') {
                const base64String = signature.split(',')[1];
                const binaryData = atob(base64String);
                const byteArray = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    byteArray[i] = binaryData.charCodeAt(i);
                }

                finalSignature = new Blob([byteArray])
                console.log("🚀 ~ base64String: ~ new Blob([byteArray]):", new Blob([byteArray]))
            }
            else if (signature && signType == 'text') {
                const signatureContainer = document.getElementById('signature-container');

                // domtoimage.toPng(signatureContainer)
                //     .then(function (dataUrl) {
                //         console.log("dataUrl------", dataUrl);
                //         const base64String = dataUrl.split(',')[1];
                //         const binaryData = atob(base64String);
                //         const byteArray = new Uint8Array(binaryData.length);
                //         for (let i = 0; i < binaryData.length; i++) {
                //             byteArray[i] = binaryData.charCodeAt(i);
                //         }

                //         finalSignature = new Blob([byteArray])
                //         console.log("🚀 ~ domtoimage new Blob([byteArray]:", new Blob([byteArray]))
                //     })
                //     .catch(function (error) {
                //         console.error('Error generating signature:', error);
                //     });



                var optionsParameter = {
                    width: 500,
                    height: 150,
                    paddingX: 100,
                    paddingY: 100,
                    canvasTargetDom: ".js-canvasTargetDom",
                    font: ["35px", "'Dancing Script'"],
                    color: "black",
                    textString: signature,
                    customFont: {
                        name: "'Dancing Script'",
                        url: "https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap"
                    }
                };

                const textSignature = new TextSignature(optionsParameter);
                textSignature.generateImage(optionsParameter);
                const dataUrl = textSignature.getImageData();
                console.log("dataUrl------", dataUrl);
                const base64String = dataUrl.split(',')[1];
                const binaryData = atob(base64String);
                const byteArray = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    byteArray[i] = binaryData.charCodeAt(i);
                }

                finalSignature = new Blob([byteArray])
                console.log("🚀 ~ domtoimage new Blob([byteArray]:", new Blob([byteArray]))
            }

            if (signature) {
                const formData = new FormData();
                // formData.append("logo", binaryDataRef.current); 
                formData.append("image", finalSignature);
                formData.append("customer_id", customer_id);

                let response = await postApi(`/ewa-employer-sign/${customer_id}`, formData, true);
                sigRef?.current?.clear();
                setSignature(null);
                if (response.status === true) {
                    navigate("/employer/employees")
                    toast.success(response.message);
                } else {

                    toast.error(response.message);

                }
            }

            setBtnLoader(false)
        }
    });




    return (
        <div>
            <div className="main-container container-fluid px-0">

                <div style={{ marginTop: "2em" }} className="page-leftheader">
                    {/* <h4 className="page-title mb-0 text-primary">Talk To Us </h4> */}
                    <Breadcrumbs order={{ first: { name: "Employees", path: "/employer/employees" }, second: { name: `Agreement` } }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="page-header">


                        {data &&
                            <div className="page-leftheader">
                                <div dangerouslySetInnerHTML={{ __html: data }}></div>
                            </div>
                        }

                    </div>
                    <form onSubmit={form.handleSubmit}>
                        <div /* style={{ display: 'flex', alignItems: 'center' }} */>
                            <div >
                                <h4 className="page-title mb-0 text-primary">Signature
                                </h4>
                                <button onClick={(e) => { setSignType("draw"); setSignature("") }} className={signType == "draw" ? "btn btn-primary me-1 mb-2" : "btn btn-outline-primary mb-2"} style={{ padding: "10px 108px", borderRadius: "0px" }}>Draw</button>
                                <button onClick={(e) => { setSignType("text"); setSignature("") }} className={signType == "draw" ? "btn btn-outline-primary mb-2" : "btn btn-primary me-1 mb-2"} style={{ padding: "10px 108px", borderRadius: "0px" }}>Text</button>
                                <br />

                                {signType == 'draw'
                                    ? <SignatureCanvas
                                        penColor="black"
                                        canvasProps={{ className: 'signature' }}
                                        ref={sigRef}
                                        onEnd={handleSignatureEnd}
                                        onChange={(e) => imageUpload(e)}
                                    />

                                    : <div>
                                        <input
                                            type="text"
                                            value={signature}
                                            className="w-100 btn-outline-primary p-2 mb-2"
                                            onChange={(e) => setSignature(e.target.value)}
                                            placeholder="Enter your signature text"
                                        />
                                        <div id="signature-container" className="d-flex justify-content-center" style={{ border: '2px solid #0f2f82', borderStyle: 'dashed', fontSize: "35px", padding: '30px', fontFamily: 'Dancing Script', minHeight: "130px" }}>
                                            {signature}
                                        </div>

                                        {/* <div className="js-canvasTargetDom"></div> */}

                                    </div>
                                }


                                {form.touched.logo && form.errors.logo ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}
                            </div>
                            {/* {signature && (
                        <div>
                            <h5>Signature Preview:</h5>
                            <Image src={signature} alt="Signature" style={{ border: '1px solid #000', margin: '5px' }} />
                        </div>
                    )} */}
                        </div>



                        {btnLoader
                            ? <div className='d-flex justify-content-center align-items-center mt-2'>
                                <Loader height={50} width={50} strokeWidthSecondary={4} />
                            </div>
                            : <>
                                <button className="btn btn-primary" onClick={clearSignature}>
                                    Clear
                                </button>
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                            </>
                        }




                    </form>
                </div>
            </div></div>

    );
};

export default Signature;
