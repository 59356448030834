import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { GetTransctions, Getcounterlistdetail, Getstorelistdetail, getApi } from '../../service/MerchantService';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import Moment from 'react-moment';
import PaginationComponent from '../../../PaginationComponent';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Loader from '../../../components/Loader/loader';
import moment from 'moment';
import { io } from 'socket.io-client';
import { TimeZone } from '../../../components/TimeZone/TimeZone';

const Transctions = () => {
    const ids = useLocation();
    // console.log(ids.state)
    const tableRef = useRef(null);
    const counterID = ids.state
    const merchant = JSON.parse(localStorage.getItem("merchant"));
    const merchantUserId = merchant.user_type
    const merchantId = merchant.user_id

    // console.log(merchant)
    const [data, setData] = useState([])
    const [showAddNewCases, setshowAddNewCases] = useState(null);
    const [search, setSearch] = useState('');
    const [copied, setCopied] = useState(false);
    const [value, setValue] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [dataArray, setDataArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [store_id, setStoreID] = useState("")
    const [counter_id, setCounterID] = useState("")
    const [counter, setcounter] = useState([])

    const navigate = useNavigate()
    const prefix = process.env.REACT_APP_API_URL;
    //  const socket = io.connect(`https://devapi.thirdroc.ai/`)
    // console.log(prefix)
    // useEffect(() => {
    //      const socket = io(prefix, { transports: ['polling'] }); 

    //     socket.on("new transaction", (data) => {
    //         console.log(data, 'socket chekckckckckc');
    //       transction(merchant.merchant_token)
    //        setDataArray(data);

    //     });

    //     socket.on("disconnect", () => {
    //       console.log('Disconnected from server');
    //     });    


    //   }, []); 

    const transction = async (token) => {
        const response = await getApi(`/merchant-transctions`, { store_id: store_id, counter_id: counter_id, search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
        // const response = await GetTransctions(token, store_id, counter_id, search, pagesCount, sortedBy, orderBy);
        // console.log(response)
        try {
            if (response.status == true) {
                setDataArray(response.data)
                // console.log(response)
                setTotalPagesCount(response.allTransction)
                // setshowAddNewCases(dataArray[0].merchantDetail.commission)
                // const commission = dataArray[0]?.merchantDetail?.commission;

                // setshowAddNewCases(commission);
                setTimeout(() => {
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("merchant");
                navigate('/merchant/login')
            }
            else {
                console.log(response);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        transction(merchant.merchant_token)
    }, [search, pagesCount, sortedBy, orderBy, store_id, counter_id, showAddNewCases])

    useEffect(() => {
        if (dataArray.length > 0 && dataArray[0]?.merchantDetail?.commission) {
            const commission = dataArray[0].merchantDetail.commission;
            setshowAddNewCases(commission);
        }
    }, [dataArray]);
    const selectTransactions = (id) => {
        // settlements.push(id)
        // setSettlements(settlements.idArr.push(id))
        setTransactions(oldArr => [...oldArr, id]);

    }

    // useEffect(() => {
    //      console.log('transactions',transactions);
    // }, [transactions])

    const addSettlementByIds = () => {
        // addSettlements(settlements)
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // transction(merchant.merchant_token);
    }
    const copiedInfo = () => {
        toast.success("Transaction No. copied Successfully")
    }

    const refreshbutton = () => {
        // console.log("fgdhighfghljkf")
        transction(merchant.merchant_token)
    }
    const handleChangeQueryBuilders = (e) => {
        setStoreID(e)
    }

    const handleChangeQueryBuilder = (e) => {
        setCounterID(e)
    }

    const Getstorelist = async () => {

        const response = await getApi("/txn-store-list");
        // const response = await Getstorelistdetail(token);
        // console.log(response.data)
        if (response.status == true) {
            setValue(response.data)
        }
    }

    useEffect(() => {
        Getstorelist()
    }, [])

    const GetCounterlist = async (token) => {
        // const response = await Getcounterlistdetail(token, store_id);
        const response = await getApi(`/txn-counter-list`, { store_id: store_id });
        // console.log(response.data.length)
        if (response.status == true) {
            setcounter(response.data)
        } else if (response.data.length == 0) {
            setcounter()
        }
    }

    useEffect(() => {
        GetCounterlist(merchant.merchant_token)
    }, [])

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Manage Transactions</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/merchant/dashboard" >Dashboard</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Manage Transaction</li>
                                </ol>
                            </nav>
                        </h4>
                    </div>
                    <div className="page-rightheader">
                        <div className="btn-list">
                            {/* <Link to="" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Borrower</Link> */}
                            <button to="" className="btn btn-primary mt-2" onClick={() => refreshbutton()} ><i className="fa fa-refresh me-2"></i>Refresh</button>
                            <DownloadTableExcel
                                filename="users table"
                                sheet="users"
                                currentTableRef={tableRef.current}>
                                <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i>Download Details</Link>
                            </DownloadTableExcel>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {/* <div className="col-sm-12 col-lg-12">
                        <div className="card">
                            <div className="card-header br-0 d-flex justify-content-between">
                                <h3 className="card-title"> Transaction Reports</h3>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        {merchantUserId == "MERCHANT" ?
                            <div className="form-group">
                                <label className="form-label"> Store List</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" onChange={(e) => handleChangeQueryBuilders(e.target.value)} onClick={() => GetCounterlist(merchant.merchant_token, store_id)} >
                                                <option value="">Select Store</option>
                                                <option value={merchantId}>My Transactions</option>
                                                {value.map(item => (<option key={item.id} value={item.store_id}>{item.company_name ? item.company_name : "NA"}</option>))}
                                                {value.length == 0 &&
                                                    <option className='text-bold'>No Store Available</option>

                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}

                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        {merchantUserId == "COUNTER" ? "" :
                            <div className="form-group">
                                <label className="form-label"> Counter List</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" onChange={(e) => handleChangeQueryBuilder(e.target.value)} >

                                                <option value="">Select Counter </option>
                                                {merchantUserId == "STORE" && <option value={merchantId}>My Transaction </option>}
                                                {counter.map(item => (<option key={item.id} value={item.counter_id}>{item.full_name ? item.full_name : "NA"}</option>))}
                                                {counter.length == 0 &&
                                                    <option className='text-bold'>No Counter Available</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword/Name/Transactions ID" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                {/* <th></th> */}
                                                <th className="font-weight-bold">TXN No.   {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                                                <th className="font-weight-bold">Merchant    {sortedBy == "merchantDetail.company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchantDetail.company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchantDetail.company_name') }}></i>}</th>
                                                <th className="font-weight-bold">Store    {sortedBy == "storeDetail.company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('storeDetail.company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('storeDetail.company_name') }}></i>}</th>
                                                <th className="font-weight-bold">Counter    {sortedBy == "counterDetail.full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('counterDetail.full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('counterDetail.full_name') }}></i>}</th>
                                                <th className="font-weight-bold"> Gross Amount   {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }}></i>}</th>
                                                <th className="font-weight-bold"> Net Amount {/* less {showAddNewCases}% */}   {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }}></i>}</th>

                                                <th className="font-weight-bold"> Settled Amount   {sortedBy == "settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('settled_amount') }}></i>}</th>
                                                <th className="font-weight-bold"> Status   {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}></i>}</th>
                                                <th className="font-weight-bold">TXN Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                {/* <th className="font-weight-bold">STATUS</th> */}
                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td /><td />
                                            <Loader />
                                        </tbody> :
                                            <>
                                                <tbody>
                                                    {dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
                                                        const { txn_id, full_name, mobile_number, email, paid_amount, earned_amount, created_at, status, is_settled, settledAmount } = option;
                                                        let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                        // var Capitalize = (option) => {
                                                        //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                        //     }
                                                        // var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                        //  const percent = {(option.paid_amount)* 98%}
                                                        const commissionRate = option?.merchantDetail?.commission
                                                        const commission = (commissionRate / 100) * option.paid_amount;
                                                        const netAmount = option.paid_amount - commission;
                                                        var num2 = parseFloat(option.earned_amount).toFixed(2)
                                                        return (
                                                            <tr key={serial_num}>
                                                                {/* <td><input type="checkbox" name="id" value={option.id} onClick={() => selectTransactions(option.id)} /></td> */}

                                                                <td> <span className="font-weight-normal1">{option.txn_id ? txn_id : "NA"}</span>
                                                                    <span>
                                                                        <CopyToClipboard text={txn_id} onCopy={() => setCopied(true)}>
                                                                            <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                        </CopyToClipboard>
                                                                    </span>
                                                                </td>
                                                                <td><span className="font-weight-normal1">{option?.merchantDetail?.company_name ? option.merchantDetail?.company_name : "NA"}</span></td>
                                                                <td><span className="font-weight-normal1">{option?.storeDetail?.company_name ? option.storeDetail?.company_name : "NA"}</span></td>
                                                                <td> <span className="font-weight-normal1">{option?.counterDetail?.full_name ? option.counterDetail?.full_name : "NA"} - {option?.counterDetail?.counter_number ? option.counterDetail?.counter_number : " "}
                                                                </span></td>
                                                                <td><span className="font-weight-normal1">{option.paid_amount ? "$" + paid_amount : "NA"}</span></td>
                                                                {/* <td>{option.earned}</td> */}
                                                                <td><span className="font-weight-normal1">{("$" + netAmount.toFixed(2))}</span></td>

                                                                <td><span className="font-weight-normal1">{is_settled == '1' ? "$" + option.settled_amount : "Pending"}</span></td>
                                                                <td><span className="font-weight-normal1">{option.status ? status : "NA"}</span></td>
                                                                {/* <td><span className="font-weight-normal1">{option.txn_id}</span>
                                                    <span>
                                                            <CopyToClipboard text={option.txn_id} onCopy={() => setCopied(true)}>
                                                                <button className='btn btn-primary btn-sm ms-3'><i className="fa-solid fa-copy"></i></button>
                                                            </CopyToClipboard>
                                                        </span>
                                                    </td> */}
                                                                {/* <td><span className="font-weight-normal1">{option.title}</span></td> */}
                                                                {/* <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td> */}
                                                                <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                                                                {/* <td><span className="font-weight-normal1">{option.status}</span></td> */}

                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                                <tbody>
                                                    {dataArray.length == 0 &&
                                                        <tr>
                                                            <td colSpan={10} className='text-center'>No Data Available</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {pagesCount > 0 && data.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''} */}

                    {pagesCount > 0 && dataArray.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}

                </div>
            </div>
        </>
    )
}

export default Transctions