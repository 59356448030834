import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../components'
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GetCountry } from '../../employer/service/employService';
import { Merchantonboarding, postApi } from '../service/adminService';
const Addmerchant = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const navigate = useNavigate();
    const [country, setCountry] = useState([]);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token

    // const urlR = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const urlR = "^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$";
    const urlR1 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = /[A-Za-z]/;

    const getCountry = async () => {
        const response = await GetCountry();
        if (response.status == true) {
            setCountry(response.data);
        } else {
            console.log("get Country data response", response);
        }
    }

    useEffect(() => {
        getCountry();
    }, [])
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
        marginRight: '10px'
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '330px',
        marginTop: '16px',
    }

    const [logoValue, setDocValue] = useState({})

    const form = useFormik({
        initialValues: {
            company_name: "",
            full_name: "",
            mobile_number: "",
            gst_number: "",
            address: "",
            website: "",
            email: '',
            account_name: '',
            bank_name: '',
            branch_name: '',
            bsb: '',
            account_number: '',
            confirm_account_number: '',
            password: "",
            confirmedPassword: "",
            country: "",
            logo: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            company_name: yup.string().required('Please Enter Merchant Name'),
            full_name: yup.string().required('Please Enter Full Name').matches(regex, 'Please Enter Only Character Values'),
            mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please Enter only number values') */.min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Max Contain 15 Number"),
            gst_number: yup.string().required('Please Enter Business Registration No.'),
            address: yup.string().required('Please Enter Address'),
            website: yup.string().required('Please Enter website').matches(urlR, 'Please Enter Valid Website Url'),
            email: yup.string().required('Please Enter Email').matches(urlR1, 'Please Enter Valid Email ID'),
            account_name: yup.string().required('Please Enter Account Name'),
            bank_name: yup.string().required('Please Enter Bank Name'),
            branch_name: yup.string().required('Please Enter Branch Name'),
            bsb: yup.string().required('Please Enter Bank State Branch').min(6, "Bank State Branch Must Contain 6 Characters").max(10, "Bank State Branch Max Contain 10 Characters")/* .matches(phoneRegExp, 'Please enter only number values') */,
            account_number: yup.string().required('Please Enter Bank Account Number').min(9, "Account Number Must Contain 9 Number").max(18, `Account Number Max upto 18 Characters`),
            confirm_account_number: yup.string().required('Please Enter Confirm Account Number').oneOf([yup.ref("account_number"), null], "Account Number Must Be Same"),
            password: yup.string().required('Please Enter Password'),
            confirmedPassword: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("password"), null], "Password Must Be Same"),
            country: yup.string().required('Please Select Country'),
            logo: yup.string().nullable().required('Please upload your logo'),
            // logo: yup.string().required('Please upload your logo'),
        }),

        // onSubmit: values => {
        //   //console.log('hello');
        //   api.postApi('lender/lender-registration', values).then(response => {
        //     if (response.status === true) {
        //       toast.success(response.message);
        //       navigate('/lender/dashboard')
        //     }
        //   }).catch(error => {
        //     form.setFieldValue('wrong_message', error.message)
        //   });
        // }

        onSubmit: async (values) => {

            const formData = new FormData();
            formData.append("logo", logoValue);
            formData.append("company_name", form.getFieldProps('company_name').value);
            formData.append("country", form.getFieldProps('country').value);
            formData.append("full_name", form.getFieldProps('full_name').value);
            formData.append("address", form.getFieldProps('address').value);
            formData.append("mobile_number", form.getFieldProps('mobile_number').value);
            formData.append("gst_number", form.getFieldProps('gst_number').value);
            formData.append("website", form.getFieldProps('website').value);
            formData.append("email", form.getFieldProps('email').value);
            formData.append("account_name", form.getFieldProps('account_name').value);
            formData.append("bank_name", form.getFieldProps('bank_name').value);
            formData.append("branch_name", form.getFieldProps('branch_name').value);
            formData.append("bsb", form.getFieldProps('bsb').value);
            formData.append("account_number", form.getFieldProps('account_number').value);
            formData.append("confirm_account_number", form.getFieldProps('confirm_account_number').value);
            formData.append("password", form.getFieldProps('password').value);
            formData.append("confirmedPassword", form.getFieldProps('confirmedPassword').value);
            //   
            //   const response = await Merchantonboarding(token , formData);
            const response = await postApi("/add-merchant", formData, true);

            if (response.status === true) {
                // toast.success(response.message);
                toast.success("Merchant Registration Done Successfully");

                navigate(process.env.REACT_APP_ADMIN_PRIFIX + '/merchant');
            } else {
                if (response.message == 'mobile_number must be unique') {
                    toast.error('Mobile Number Already Exists');
                } else {
                    toast.error(response.message);
                }
                console.log(response);
            }

        },
    });



    const uploadLogoFunc = async (e = "") => {
        if (e.target && e.target.files) {
            const file = e.target.files[0] || "";
            if (Object.keys(logoValue).length === 0 && e.target.files[0] &&
                (e.target.files[0].type === "image/png" ||
                    e.target.files[0].type === "image/jpeg" ||
                    e.target.files[0].type === "image/svg+xml")) {
                setDocValue(file);
                form.setValues({
                    ...form.values,
                    logo: file,
                });
            } else {
                toast.error("Invalid file type. Please upload a PNG, JPEG, or SVG file.")
                form.setValues({
                    ...form.values,
                    logo: null,
                });
                setDocValue({});
            }

        }
        else {
            setDocValue({});
            form.setValues({
                ...form.values,
                logo: null,
            });
        }
    };

    const imageUpload = (e, path, s3_path, co_index = 0) => {
        console.log("event pancard", e);
        const file = e.target.files[0];
        form.setValues({
            ...form.values,
            logo: file
        })
    }
    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">

                    <h4 className="page-title mb-0 text-primary">

                        <Breadcrumbs order={{ first: { name: "Merchant", path: `${prefix}/merchant` }, second: { name: "Add Merchant" } }} />
                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Merchant Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Merchant Name" {...form.getFieldProps("company_name")} />
                                </div>
                                {form.touched.company_name && form.errors.company_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.company_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Name Of Contact Person</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Name" {...form.getFieldProps("full_name")} />
                                </div>
                                {form.touched.full_name && form.errors.full_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Mobile Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Mobile Number" {...form.getFieldProps("mobile_number")} />
                                </div>
                                {form.touched.mobile_number && form.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Business Registration No.</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" name="gst_number" {...form.getFieldProps("gst_number")} className="form-control" placeholder="Business Registration No." />

                                </div>
                                {form.touched.gst_number && form.errors.gst_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.gst_number}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Address</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Address" {...form.getFieldProps("address")} />
                                </div>
                                {form.touched.address && form.errors.address ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.address}</div> : ''}
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Website</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Website" {...form.getFieldProps("website")} />
                                </div>
                                {form.touched.website && form.errors.website ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.website}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Enter Email" {...form.getFieldProps("email")} />
                                </div>
                                {form.touched.email && form.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Country</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <select className="form-control" name="country" {...form.getFieldProps("country")}>
                                        <option value="">Select Country</option>
                                        {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                                    </select>
                                </div>
                                {form.touched.country && form.errors.country ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.country}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Account Holder Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="text" name="account_name" {...form.getFieldProps("account_name")} placeholder="Account Holder Name" id="password-field" />
                                </div>
                                {form.touched.account_name && form.errors.account_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.account_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Bank Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="text" name="bank_name" {...form.getFieldProps("bank_name")} placeholder="Bank Name" id="password-field" />

                                </div>
                                {form.touched.bank_name && form.errors.bank_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.bank_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Branch Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="text" name="branch_name" {...form.getFieldProps("branch_name")} placeholder="Branch Name" id="password-field" />


                                </div>
                                {form.touched.branch_name && form.errors.branch_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.branch_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Bank State Branch</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="text" name="bsb" {...form.getFieldProps("bsb")} placeholder="Bank-State-Branch" id="password-field" />

                                </div>
                                {form.touched.bsb && form.errors.bsb ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.bsb}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Account Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="number" name="account_number" {...form.getFieldProps("account_number")} placeholder="Account Number" id="password-field" />

                                </div>
                                {form.touched.account_number && form.errors.account_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.account_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Confirm Account Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="number" name="confirm_account_number" {...form.getFieldProps("confirm_account_number")} placeholder="Confirm Account Number" id="password-field" />

                                </div>
                                {form.touched.confirm_account_number && form.errors.confirm_account_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_account_number}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Password</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" {...form.getFieldProps("password")} type={values.showPassword ? "text" : "password"} name="password" placeholder="Password" id="password-field" />                                {!values.showPassword ?
                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                        :
                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                    }

                                </div>
                                {form.touched.password && form.errors.password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Confirm Password</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" {...form.getFieldProps("confirmedPassword")} type={valuesConfP.showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />

                                    {!valuesConfP.showConfPassword ?
                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                        :
                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                    }
                                </div>
                                {form.touched.confirmedPassword && form.errors.confirmedPassword ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirmedPassword}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Logo</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control browse-file" name={logoValue} placeholder="Upload your logo" />
                                    <label className="input-group-text btn btn-primary"> Browse <input type="file" className="file-browserinput" onChangeCapture={uploadLogoFunc} /* onChange={(e) => imageUpload(e, 'dsa_doc', 'uploads/merchant/pancard')} */ style={{ display: 'none' }} multiple /> </label>
                                    {form.touched.logo && form.errors.logo ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}
                                </div>
                            </div>

                            {logoValue && logoValue.name ? <ul className="imgpreview-newbx">
                                <li className="registerboximg">
                                    <img src={URL.createObjectURL(logoValue)} alt="" />
                                    <Link to="" onClick={() => { setDocValue({}); uploadLogoFunc() }} className="upload__img-close"><i className="fa-solid fa-close fa-fw"></i></Link>
                                    <p>img preview name here</p>
                                </li>
                            </ul> : ""}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Save Changes</button>
                    </div>


                </div>
            </form>

        </div>
    )
}

export default Addmerchant
