import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import PaginationComponent from '../../PaginationComponent';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from 'react-moment';
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup';
import { deleteApi, deleteLender, getApi, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import { Image, Switch } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import Dropdown from 'react-bootstrap/Dropdown';
import Search from '../../components/Search/Search';
import ManagePermission from './managePermission';


const Lender = (props) => {
	const [loading, setloading] = useState(false);
	const [pagesCount, setpagesCount] = useState(1);
	const [totalPagesCount, setTotalPagesCount] = useState(10);
	const [dataArray, setDataArray] = useState([]);
	const [totalItems, settotalItems] = useState(0);
	const [startDate, setstartDate] = useState('');
	const [endDate, setendDate] = useState('');
	const [search, setSearch] = useState('');
	const [detail, setDetail] = useState([]);
	const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
	const navigate = useNavigate();
	const lender = JSON.parse(localStorage.getItem("admin"));
	const token = lender.admin_token;
	const [sortedBy, setSortedBy] = useState("created_at")
	const [orderBy, setOrderBy] = useState('desc')
	const [loader, setloader] = useState(true)
	const ref = useRef()
	const [counterid, setcounterid] = useState();
	const [ValidPagination, setValidPagination] = useState(false)
	const [handlePermissionModal , setHandlePermissionModal] = useState(false)
    const [lenderId , setlenderId] = useState('')
	
	const getList = async () => {		
		try {
			setloading(true)
			const response = await getApi("/get-lender-list", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy })
			if (response.status) {
			setTimeout(() => {
				setDataArray(response.data);
				setTotalPagesCount(response.AllLender);
				settotalItems(response.AllLender)
				setloading(false)
				setloader(false)
			}, 2000);
			setloader(true)
		}else{
		toast.error(response.message)
		}
	} catch (error) {
			console.log(error)
		}
	
}
	useEffect(() => {
		getList();
	}, [search, pagesCount, sortedBy, orderBy])

	

	const getCasses = (status) => {
		if (detail && detail.cases && detail.cases.length) {
			let found = detail.cases.find(obj => {
				return obj.status === status;
			});
			return found?.value ? found.value : 0;
		} else {
			return 0
		}
	}

	const handleCallback = (event, picker) => {
		picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
		setstartDate(picker.startDate.format('MM/DD/YYYY'))
		setendDate(picker.endDate.format('MM/DD/YYYY'))
	}
	const handleCallbackOnCancel = (event, picker) => {
		picker.element.val('');
		setstartDate('')
		setendDate('')
	}
	const hideModel = (e) => {
		// console.log('e.userDetail.user_id', e.userDetail.user_id);
		navigate(props.prefix + '/edit_lender/' + e.userDetail.user_id)
	}
	const form = useFormik({
		initialValues: {
			user_id: "",
			new_password: "",
			confirm_password: "",
			wrong_message: ""
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			new_password: yup.string().required('Please Enter New Password')/* .matches(regex, 'Please Enter Only Character Values') */,
			confirm_password: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("new_password"), null], "Password Must Be Same"),

		}),
		onSubmit: async (values, { resetForm }) => {
			console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
			// const data = JSON.stringify({
			// 	user_id: counterid,
			// 	new_password: values.new_password,
			// 	confirm_password: values.confirm_password,

			// })
			// console.log(data)
			// const response = await AdminChangePasword(token, data);
			const response = await postApi("/changeUserPasswordByAdmin", {
				user_id: counterid,
				new_password: values.new_password,
				confirm_password: values.confirm_password,
			});

			console.log("#@#@#", response);
			resetForm({ values: '' });
			ref.current.click()
			//    ref.resetForm({ values: '' });
			if (response.status == false) {
				console.log(response.message);
				toast.error(response.message);
				ref.current.click()

			}
			if (response.status == true) {
				//    console.log(response.message);	
				toast.success(response.message);
				// window.$('#modal-center').modal('hide')	 
				ref.current.click()

			} else {

				console.log("#@#@#1234", response);
			}

		}
	});
	const [values, setValues] = React.useState({
		new_password: "",
		showPassword: false,
	});
	const [valuesConfP, setValuesConfP] = React.useState({
		new_password: "",
		showConfPassword: false,
	});
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleClickShowConfPassword = () => {
		setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
	};
	const eyeBtnStyle = {
		position: 'absolute',
		marginLeft: '0px',
		marginTop: '0px',
	}

	const eyeBtnStyle1 = {
		position: 'absolute',
		marginLeft: '400px',
		marginTop: '-40px',

	}

	const eyeBtnStyle2 = {
		position: 'absolute',
		marginLeft: '0px',
		marginTop: '0px',
	}

	const eyeBtnStyle3 = {
		position: 'absolute',
		marginLeft: '400px',
		marginTop: '-40px',
	}

	const sortChange = (col) => {
		if (col === sortedBy) {
			setSortedBy(col);
			orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
		} else {
			setSortedBy(col)
			setOrderBy("desc")
		}
		// getList(token, pagesCount, search );
	}
	const [toggle, setToggle] = useState(true)
	const Registration = async (id, e) => {
		// let data = {
		// 	"id": id,
		// 	"status": e == true ? '1' : '0',
		// }

		try {
			// const total = await changeStatus(token, data)
			const total = await getApi(`/verify/${id}`)
			console.log('changeStatus', total)
			//   changeStatusToast();
		} catch (error) {
			console.log(error)
		}
	}

	const handleNavigate = (id) => {
		navigate(`${prefix}/edit_lender/` + id.user_id, { state: id })
	}
	const handleDelete = (data) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				// const response = await deleteLender(token, data)
				const response = await deleteApi(`/delete-lender/${data}`)
				if (response?.status) {
					Swal.fire(
						'Deleted!',
						'User Account has been deleted Successfully.',
						'success'
					)
					//   setState(!state)
					getList(token, pagesCount, search);
				}
				else {
					toast.error("something went wrong")
				}
			}
		})
	}

	const [permission , setPermission] = useState([])
	return (
		<>
			<div className="main-container container-fluid px-0">
				<div className="page-header">
					<div className="page-leftheader">
						<h4 className="page-title mb-0 text-primary">Lender Reports {totalItems ? '(' + totalItems + ')' : ''}</h4>
					</div>
					<div className="page-rightheader">
						<div className="btn-list">
							<Link to={prefix + "/lender/trash"} className="btn btn-primary btn-pill mt-2" ><i className="fa fa-trash "></i> Recover</Link>
							<Link to={prefix + "/addlender"} className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Lender</Link>

						</div>
					</div>
				</div>
				<div className="row">
					{/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
						<div className="form-group">
							<label className="form-label">Select Date</label>
							<div className="row g-xs">
								<div className="wd-200 mg-b-30">
									<div className="input-group">
										<DateRangePicker onApply={handleCallback} onCancel={handleCallbackOnCancel} initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
											<input className="form-control fc-datepicker hasDatepicker" type="text" defaultValue="" placeholder='Search By Date' />
										</DateRangePicker>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					<Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

					{/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
						<div className="form-group">
							<label className="form-label">Search</label>
							<div className="row g-xs">
								<div className="input-group">
									<input type="text" className="form-control" placeholder="Enter Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
								</div>
							</div>
						</div>
					</div> */}
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body p-0">
								<div className="table-responsive">
									<table className="table table-hover card-table table-vcenter text-nowrap">
										<thead className="border-bottom-0 pt-3 pb-3">
											<tr>
												<th className="font-weight-bold">Logo </th>
												<th className=" font-weight-bold"> Lender {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}	</th>
												<th className="font-weight-bold">Authorize Person Name {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }}></i>}	</th>
												<th className="font-weight-bold">Min ROI  {sortedBy == "mini_rate_of_intrest" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('mini_rate_of_intrest') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('mini_rate_of_intrest') }}></i>}</th>
												<th className="font-weight-bold">Max ROI  {sortedBy == "max_rate_of_intrest" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('max_rate_of_intrest') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('max_rate_of_intrest') }}></i>}</th>
												<th className="font-weight-bold">Website  {sortedBy == "website" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }}></i>}</th>
												<th className="font-weight-bold">Address  {sortedBy == "address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }}></i>}</th>
												<th className="font-weight-bold">Created At {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}	</th>
												<th className="font-weight-bold">Updated At {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}></i>}	</th>
												<th className="font-weight-bold">Active/Inactive</th>
												<th className="font-weight-bold">Actions</th>
											</tr>
										</thead>
										{loader == true ? <tbody>
											<td /><td /><td /><td /><td />
											<div >
												<Loader />
											</div> </tbody> :
											<>
												<tbody>
													{dataArray.length > 0 && dataArray.map((option, index) => {
														let serial_num = ((10 * (pagesCount - 1)) + index + 1);
														const { company_name, full_name, mobile_number, email, user_id, address, max_rate_of_intrest, website, logo, total_invesment, created_at, updated_at, mini_rate_of_intrest } = option;
														var num2 = parseFloat(option.interest).toFixed(2)
														var Capitalize = (option) => {
															return option.charAt(0).toUpperCase() + option.slice(1);
														}


														return (
															<tr key={serial_num} >
																<td><span className="font-weight-normal1" ><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.logo != null ? option.logo : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' /></span></td>
																<td>{/* <Link to="" onClick={() => getDetail(user_id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal" > */}
																	<Link className="text-primary" to={prefix + `/lenderdetail/${option.user_id}`}>
																		<span className="font-weight-normal1" style={{ color: "#0f2f82" }}>
																			{company_name !== null ? company_name : ""}<br /> {mobile_number}<br /><span className='customtextformat'>{email}</span>
																		</span>
																	</Link>
																</td>
																<td><span className="font-weight-normal1">{full_name !== null ? full_name : ""}</span></td>
																<td><span className="font-weight-normal1">{option.mini_rate_of_intrest ? mini_rate_of_intrest + "%" : ""}</span></td>
																<td><span className="font-weight-normal1">{option.max_rate_of_intrest ? max_rate_of_intrest + "%" : ""}</span></td>
																<td className='font-weight-normal1'><span className="content_fix" > <Link href={website} style={{ color: "#0f2f82" }}><span className='customtextformat'>{website ? website : "NA"}</span></Link></span></td>
																<td className='font-weight-normal1'><span className="content_fix" >{address ? address : "NA"}</span></td>
																<td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
																<td><span className="font-weight-normal1">{TimeZone(option.updated_at).timeZone}</span></td>
																<td>{option.is_verified == '1' &&
																	<div className="col-auto ms-auto d-print-none">
																		<Switch checkedChildren="Active" onClick={(e) => Registration(option.user_id, e)} unCheckedChildren="Inactive" defaultChecked
																			onChange={(e) => setToggle(e)} /* style={{ backgroundColor: toggle === true ? 'green' : 'red' }} */ />
																		<span>
																		</span>
																	</div>}
																	{option.is_verified == '0' &&
																		<div className="col-auto ms-auto d-print-none">
																			<Switch checkedChildren="Active" onClick={(e) => Registration(option.user_id, e)} unCheckedChildren="Inactive"
																				onChange={(e) => setToggle(e)} /* style={{ backgroundColor: '#1a48aa' }} */ />
																			<span>
																			</span>
																		</div>
																	}
																</td>
																{/* <td>  <i onClick={() => handleNavigate(option)} className="fa fa-edit me-2 btn btn-primary"></i>
																<button onClick={() => { setcounterid(option.user_id) }} type="button" className="btn btn-primary"
																	data-bs-target="#add_settlement" data-bs-toggle="modal" ><i className="fas fa-lock-open " /></button>
																&nbsp;&nbsp;
																<button type="button" className='btn btn-primary'><i className="fas fa-trash " onClick={() => { handleDelete(option.user_id) }} /></button>
															</td> */}
																<td>
																	<div className="dropdown-action">
																		<button className="btn view-pro dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
																			Action
																			<span className="caret"></span>
																		</button>
																		<ul className="dropdown-menu actionfix" aria-labelledby="dropdownMenu1">
																			<li> <span onClick={() => handleNavigate(option)} className="font-weight-normal1" style={{ marginLeft: '17px', cursor: 'pointer', fontSize: 'small' }}>Edit</span></li>
																			<li><Link to={prefix + `/sub-lender_detail/${option.user_id}`}  > <span className="font-weight-normal1">View Sub-Lender</span></Link></li>
																			<li><Link><span onClick={() => { setcounterid(option.user_id) }} className='font-weight-normal1' data-bs-target="#add_settlement" data-bs-toggle="modal" >Change Password</span></Link></li>
																			<li><Link><span className='font-weight-normal1' onClick={() => { handleDelete(option.user_id) }} >Delete</span></Link></li>
																			<li><Link to="#" 
																			onClick={() => {setHandlePermissionModal(true); setlenderId(option?.user_id); setPermission(option?.permissions)}} 
																			><span className='font-weight-normal1'
																			/* data-bs-toggle="modal" data-bs-target="#staticBackdrop" */ >Manage Permissions</span></Link></li>
																		</ul>
																	</div>
																</td>
																{/* <td>
																	<Dropdown>
																		<Dropdown.Toggle className='view-pro' id="dropdown-basic" >	ACTION</Dropdown.Toggle>
																		<Dropdown.Menu>
																			<Dropdown.Item><span onClick={() => handleNavigate(option)} className="font-weight-normal1 text-dark">Edit</span></Dropdown.Item>
																			<Dropdown.Item><Link to={prefix + `/sub-lender_detail/${option.user_id}`}  > <span className="font-weight-normal1 text-dark">View Sub-Lender</span></Link></Dropdown.Item>
																			<Dropdown.Item><span onClick={() => { setcounterid(option.user_id) }} className='font-weight-normal1' data-bs-target="#add_settlement" data-bs-toggle="modal" >Change Password</span></Dropdown.Item>
																			<Dropdown.Item><span className='font-weight-normal1' onClick={() => { handleDelete(option.user_id) }} >Delete</span></Dropdown.Item>
																		</Dropdown.Menu>
																	</Dropdown>
																</td> */}
															</tr>
														)
													})}
												</tbody>
												<tbody>
													{dataArray.length == 0 &&
														<tr>
															<td colSpan={10} className='text-center'>No Data Available</td>
														</tr>}
												</tbody></>}
									</table>
								</div>
							</div>

						</div>
					</div>

					{pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
						<>
							<div className="col-md-12">
								<div className="card-body">
									<PaginationComponent className="justify-content-center"
										totalItems={totalPagesCount}
										pageSize={10}
										maxPaginationNumbers={3}
										onSelect={(e) => setpagesCount(e)}
									/>
								</div>
							</div>
						</>
						: ''}
					{pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
						<>
							<div className="col-md-12">
								<div className="card-body">
									<PaginationComponent className="justify-content-center"
										totalItems={totalPagesCount}
										pageSize={10}
										maxPaginationNumbers={3}
										onSelect={(e) => setpagesCount(e)}
									/>
								</div>
							</div>
						</>
						: ''}
				</div>
			</div>
			{/* Change Password Start */}
			<div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
				<div className="modal-dialog" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header">
							<h3 className="modal-title">Change Password</h3>
							<button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
								aria-hidden="true">×</span></button>
						</div>

						<div className="modal-body application-modal">

							<form className="row" onSubmit={form.handleSubmit}>

								<div className="row">
									<div className="row g-xs">

										<div className="col-12">
											{/* <input type="password"  placeholder='Enter New Password' className="form-control" {...form.getFieldProps("new_password")}/>   */}
											<input className="form-control" type={values.showPassword ? "text" : "password"} placeholder="New Password"
												name="password" {...form.getFieldProps("new_password")} />
											{!values.showPassword ?
												<span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
												:
												<span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

											}
											{form.touched.new_password && form.errors.new_password ?
												<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.new_password}</div> : ''}
										</div>
									</div>
									<div className="row g-xs">
										<div className="col-12">
											{/* <input type="password"  placeholder='Enter Confirm Password' className="form-control"  {...form.getFieldProps("confirm_password")}/>  */}
											<input className="form-control" type={valuesConfP.showConfPassword ? "text" : "password"} placeholder="Confirm Password"
												name="confirmedPassword"  {...form.getFieldProps("confirm_password")} />

											{!valuesConfP.showConfPassword ?
												<span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
												:
												<span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

											}
											{form.touched.confirm_password && form.errors.confirm_password ?
												<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_password}</div> : ''}
										</div>
									</div>
								</div>
								<div className="form-footer mt-2">
									<button type="submit" className="btn btn-primary" aria-hidden="true" >
										Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* Change Password End */}

			{/* <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header"> <h6 className="modal-title">Lender Information</h6>
							<button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

						<div className="modal-body application-modal">
							<div className="row">
								<div className="col-lg-12 col-xl-12">
									<div className="">
										<div className="main-content-body main-content-body-contacts">
											<div className="main-contact-info-header bg-contacthead pb15">
												<div className="media">
													<div className="media-body text-white ml-0">
														<h4 className="text-white">Company Name: {detail ? detail.company_name : ''}</h4>
														<p className="mb-1">Person Name: {detail ? detail.full_name : ''}</p>
													</div>
												</div>
											</div>
											<div className="main-contact-info-body">
												<div className="media-list p-0">
													<div className="media py-4 mt-0">
														<div className="media-body">
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
																<div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mobile_number : ''}</span> </div>
															</div>
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
																<div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail ? detail.email : ''}</span> </div>
															</div>
														</div>
													</div>
													<div className="media py-4 border-top mt-0">
														<div className="media-body">
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
																<div className="w-70"> <label>GST no:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.gst_number : ''}</span> </div>
															</div>
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
																<div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.address : ''}</span> </div>
															</div>
														</div>
													</div>
													<div className="media py-4 border-top mt-0">
														<div className="media-body">
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
																<div className="w-70"> <label>Minimum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_rate_of_intrest : ''} %</span> </div>
															</div>
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
																<div className="w-70"> <label>Maximum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_rate_of_intrest : ''} %</span> </div>
															</div>
														</div>
													</div>
													<div className="media py-4 border-top mt-0">
														<div className="media-body">
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
																<div className="w-70"> <label>Minimum Loan Range:</label> <span className="font-weight-normal1 fs-14"> ${detail ? detail.mini_loan_range : ''}</span> </div>
															</div>
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
																<div className="w-70"> <label>Maximum Loan Range:</label> <span className="font-weight-normal1 fs-14">${detail ? detail.max_loan_range : ''}</span> </div>
															</div>
														</div>
													</div>
													<div className="media py-4 border-top mt-0">
														<div className="media-body">
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
																<div className="w-70"> <label>Minimum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_tenure : ''} Months</span> </div>
															</div>
															<div className="d-flex">
																<div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
																<div className="w-70"> <label>Maximum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_tenure : ''} Months</span> </div>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}



			<div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog">
				<div className="modal-dialog" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header"> <h6 className="modal-title">Assign RM</h6>
							<button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

						<div className="modal-body application-modal">
							<div className="row">
								<div className="col-md-12">
									<div className="form-group m-0">
										<label className="form-label">Sale</label>
										<div className="row g-xs">
											<div className="col-12">
												<select className="form-control custom-select">
													<option value="">Select Sale</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group m-0">
										<label className="form-label">Operation</label>
										<div className="row g-xs">
											<div className="col-12">
												<select className="form-control custom-select">
													<option value="">Select Operation Member</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="form-footer mt-2"> <button type="submit" className="btn btn-primary">Save</button> </div>

							</div>
						</div>
					</div>
				</div>

			</div>


{handlePermissionModal && <ManagePermission  setHandlePermissionModal={setHandlePermissionModal} lenderId={lenderId} getList={getList} permission={permission}/>}
			


		</>
	)
}
export default Lender;