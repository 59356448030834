import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import $ from 'jquery';
import PaginationComponent from '../../PaginationComponent';
import { Oval } from 'react-loader-spinner';
import { GetMerchants, GetTalktoUs, getApi } from '../service/adminService';
import AssignToLender from '../layout/Model';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import { TimeZone } from '../../components/TimeZone/TimeZone';

const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const Talktous = () => {
    const [loader, setloader] = useState(true)

    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loading, setloading] = useState(false);
    const [profileData, setprofileData] = useState({});
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')

    const admin = JSON.parse(localStorage.getItem("admin"));


    const getTalktoUs = async (token) => {
        // `/talk-to-us?page_number=${pageNumber}&search=${searchVlu}&sortby=${sortedBy}&orderby=${orderBy}`
        // const response = await GetTalktoUs(token, pagesCount, search , sortedBy , orderBy);
        const response = await getApi("/talk-to-us", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

        console.log("fdgdgdgddfgdg", response)
        if (response.status == 'success') {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.count);
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getTalktoUs(admin.admin_token, pagesCount, search);
    }, [search, pagesCount, sortedBy, orderBy]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    return (
        <>

            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Talk To Us </h4>
                            </div>



                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link> */}

                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Name  {sortedBy == "name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('name') }}></i>}</th>
                                                        <th className="font-weight-bold">Mobile Number {sortedBy == "mobile_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('mobile_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('mobile_number') }}></i>}</th>
                                                        <th className="font-weight-bold">Email ID {sortedBy == "email" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('email') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('email') }}></i>}</th>
                                                        <th className="font-weight-bold">Comments  {sortedBy == "comments" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('comments') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('comments') }}></i>}</th>
                                                        <th className="font-weight-bold">Status {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}></i>}</th>
                                                        <th className="font-weight-bold">Date {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                    </tr>
                                                </thead>

                                                {loader == true ? <tbody>
                                                    <td /><td /><td />
                                                    <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            const { name, mobile_number, email, comments, status, created_at } = option;
                                                            // var timezone = new Date(option.created_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata', hour12:true  });  


                                                            return (
                                                                <tr key={serial_num} >
                                                                    <td><span className="font-weight-normal1">{name ? name : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{mobile_number ? mobile_number : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1 ">{email ? email : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{comments ? comments : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{status ? status : "NA"}</span></td>
                                                                    <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>



                </div>

            }
        </>
    )
}


export default Talktous;


