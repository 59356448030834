import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import PaginationComponent from '../../PaginationComponent';
import { getApi } from '../service/adminService';
import { Breadcrumbs } from '../../components';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import { Image } from 'antd';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Search from '../../components/Search/Search';
import toast from 'react-hot-toast';

const EwaEarning = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loading, setloading] = useState(false);
    const [profileData, setprofileData] = useState({});
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    // const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [detail, setDetail] = useState([]);
    const [totalAmounts, settotalAmounts] = useState({})
    const [Count, setCount] = useState("");
    const [ValidPagination, setValidPagination] = useState(false)
    const admin = JSON.parse(localStorage.getItem("admin"));

    const GetEwaEarning = async (token) => {
        // const response = await GetEwaEarningDetails(token, search, pagesCount, sortedBy, orderBy)
        const response = await getApi("/ewa-earning", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })

        console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data)
                setTotalPagesCount(response.count)
                settotalAmounts(response.totalAmounts)
                setCount(response.count)
                setloader(false)
            }, 2000);
            setloader(true)

        } else if (response.status == false) {
            console.log(response)
        }
    }

    useEffect(() => {
        GetEwaEarning(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy, ValidPagination]);


    const getDetail = async (token, id) => {
        // const response = await GetEwaEarningDetailsbyID(token, id);
        const response = await getApi(`/ewa-earning-detail/${id}`);

        console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
        if (response.status) {
            setDetail(response.data);

        } else {
            console.log("get merchant data response", response);
        }
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    const copiedInfo = () => {
        toast.success("Copied")
    }

    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <Breadcrumbs order={{ first: { name: "Earning Report" }, second: { name: `Earning EWA  ${Count && `(${Count})`}` } }} />
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link> */}

                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill=""> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Total Paid Amount</p>
                                        <h4 className="mb-1 font-weight-bold">
                                            {/* {totalAmounts?.total_paid_amount ? "$" + totalAmounts.total_paid_amount : 0} */}
                                            {typeof totalAmounts?.total_paid_amount === 'string'
                                                ? "$" + parseFloat(totalAmounts.total_paid_amount).toFixed(2)
                                                : typeof totalAmounts?.total_paid_amount === 'number'
                                                    ? "$" + totalAmounts.total_paid_amount.toFixed(2)
                                                    : "0"}
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Settled Amount</p>
                                        <h4 className="mb-1 font-weight-bold">
                                            {/* {totalAmounts?.total_merchant_settled_amount ? "$" + totalAmounts.total_merchant_settled_amount.toFixed(2) : 0} */}
                                            {/* {typeof totalAmounts?.total_merchant_settled_amount === 'number'
                                                ? "$" + totalAmounts.total_merchant_settled_amount.toFixed(2)
                                                : "0"} */}
                                            {typeof totalAmounts?.total_merchant_settled_amount === 'string'
                                                ? "$" + parseFloat(totalAmounts.total_merchant_settled_amount).toFixed(2)
                                                : typeof totalAmounts?.total_merchant_settled_amount === 'number'
                                                    ? "$" + totalAmounts.total_merchant_settled_amount.toFixed(2)
                                                    : "0"}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Merchant Commission</p>
                                        <h4 className="mb-1 font-weight-bold">
                                            {/* {totalAmounts?.total_merchant_commission ? "$" + totalAmounts.total_merchant_commission : 0} */}
                                            {typeof totalAmounts?.total_merchant_commission === 'string'
                                                ? "$" + parseFloat(totalAmounts.total_merchant_commission).toFixed(2)
                                                : typeof totalAmounts?.total_merchant_commission === 'number'
                                                    ? "$" + totalAmounts.total_merchant_commission.toFixed(2)
                                                    : "0"}
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Thirdroc Income</p>
                                        <h4 className="mb-1 font-weight-bold text-success">
                                            {/* {totalAmounts?.total_thirdroc_income ? "$" + totalAmounts.total_thirdroc_income : 0} */}
                                            {typeof totalAmounts?.total_thirdroc_income === 'string'
                                                ? "$" + parseFloat(totalAmounts.total_thirdroc_income).toFixed(2)
                                                : typeof totalAmounts?.total_thirdroc_income === 'number'
                                                    ? "$" + totalAmounts.total_thirdroc_income.toFixed(2)
                                                    : "0"}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">TXN ID {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}></i>}</th>
                                                        {/* <th className="font-weight-bold"> Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th> */}
                                                        {/* <th className="font-weight-bold">ROI {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th> */}
                                                        <th className="font-weight-bold">Thirdroc Income  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Thirdroc ROI  {sortedBy == "txn_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_number') }}></i>}</th>
                                                        <th className="font-weight-bold">Create Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                        <th className="font-weight-bold">Action</th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td />
                                                    <div  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { txn_id, thirdroc_income, thirdroc_roi, created_at } = option;

                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td><span className="font-weight-normal1">{txn_id ? txn_id : "NA"} </span>
                                                                            <CopyToClipboard text={txn_id} >
                                                                                <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                            </CopyToClipboard></td>
                                                                        {/* <td><span className="font-weight-normal1 ">{amount ? "$" + amount : "NA"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{amount ? + amount + "%" : "NA"}</span></td> */}
                                                                        <td><span className="font-weight-normal1 ">{thirdroc_income ? "$" + thirdroc_income : "NA"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{thirdroc_roi ? thirdroc_roi + "%" : "NA"}</span></td>
                                                                        <td><span className="font-weight-normal1 ">{TimeZone(option.created_at).timeZone}</span></td>
                                                                        <td> <Link className='btn btn-primary' onClick={() => getDetail(admin.admin_token, option.id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal">{/* <i className='fas fa-eye'></i> */}View Transaction</Link></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>}
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>


                    {/* Merchant Information Start */}
                    <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header"> <h6 className="modal-title">Transaction Information</h6>
                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                                <div className="modal-body application-modal">

                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="">
                                                <div className="main-content-body main-content-body-contacts">
                                                    {/* <h6>Marchant Information</h6> */}
                                                    <div className="main-contact-info-header bg-contacthead pb15">
                                                        <div className="media">
                                                            <div className="media-body text-white ml-0">
                                                                <h4 className="text-white">Customer Name: {detail[0] && detail[0].customerDetail ? detail[0].customerDetail.full_name : ''}</h4>
                                                                <p className="mb-1">Merchant Name: {detail[0] && detail[0].merchantDetail ? detail[0].merchantDetail.company_name : ''}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="main-contact-action"> <Link data-bs-dismiss="modal" onClick={() => hideModel(detail)} className="btn btn-primary"> Edit</Link></div> */}
                                                    </div>
                                                    <div className="main-contact-info-body">
                                                        <div className="media-list p-0">
                                                            <div className="media py-4 mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Amount</label> <span className="font-weight-normal1 fs-14">{detail[0] && detail[0] ? detail[0].amount : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>TXN Charges</label> <span className="font-weight-normal1 fs-14">{detail[0] && detail[0] ? detail[0].txn_charge : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Paid Amount</label> <span className="font-weight-normal1 fs-14">{detail[0] && detail[0] ? detail[0].paid_amount : ''}</span> </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">

                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-percent"></i> </div>
                                                                        <div className="w-70"> <label>Thirdroc ROI</label> <span className="font-weight-normal1 fs-14">{detail[0] && detail[0] ? detail[0].thirdroc_roi : ''}</span> </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Account Name</label> <span className="font-weight-normal1 fs-14">{detail[0] && detail[0] ? detail[0].thirdroc_paid_amount : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Merchant Settled Amount</label> <span className="font-weight-normal1 fs-14">{detail[0] && detail[0] ? detail[0].merchant_settled_amount : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-dollar"></i> </div>
                                                                        <div className="w-70"> <label>Merchant Commission</label> <span className="font-weight-normal1 fs-14">{detail[0] && detail[0] ? detail[0].mrch_commission : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-address-book fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>TXN ID</label> <span className="font-weight-normal1 fs-14">{detail[0] && detail[0] ? detail[0].txn_id : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        {/* <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-home fa-fw"></i> </div> */}
                                                                        {/* <div className="w-70"> <label>Bank State Branch</label> <span className="font-weight-normal1 fs-14">{bankData && bankData ? bankData.bsb : ''}</span> </div> */}
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            {/* <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.mini_rate_of_intrest : ''} %</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.max_rate_of_intrest : ''} %</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Loan Range:</label> <span className="font-weight-normal1 fs-14">₹ {detail && detail ? detail.mini_loan_range : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Loan Range:</label> <span className="font-weight-normal1 fs-14">₹ {detail && detail ? detail.max_loan_range : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.mini_tenure : ''} Months</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.max_tenure : ''} Months</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Merchant Information End */}

                </div>

            }
        </>
    )
}

export default EwaEarning
