import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { postApi } from '../service/adminService';

const ManagePermission = ( {setHandlePermissionModal, lenderId,getList ,permission}) => {
    const initialPermissions =  permission ? JSON.parse(permission) : [
        { key: 'dashboard', value: true },
        { key: 'borrower', value: true },
        { key: 'pay_to_merchant', value: true },
        { key: 'pay_to_thirdroc', value: true },
        { key: 'repayment', value: true },
        { key: 'role_and_permission', value: true },
        { key: 'ROI', value: true },
        { key: 'ewa_advance', value: true },
        { key: 'behaviour', value: true },
        { key: 'manage_investment', value: true },
    ];
    
    const [permissions, setPermissions] = useState(initialPermissions);
// console.log(lenderId,getList ,permission , "************")
    const updatePermissions = (e, key) => {
        const isChecked = e.target.checked;
        setPermissions((prevPermissions) => {
            return prevPermissions.map((perm) => {
                if (perm.key === key) {
                    return { ...perm, value: isChecked };
                }
                if (key == 'ewa_advance' && !isChecked) {
                    return perm.key == 'behaviour' ? { ...perm, value: false } : perm;
                }
                return perm;
            });
        });
    };


    const getPermissionValue = (key) => {
        const permission = permissions.find(perm => perm.key === key);
        return permission ? permission.value : false;
    };

    const PostupdatePermission = async() => {       
        try {
            const response = await postApi('/manage-lender-permission', {
                lender_id:lenderId,
                permissions: permissions
            })
            if(response.status){
                setHandlePermissionModal(false)
                toast.success(response.message)
                 getList()
            }else{
                toast.error(response.message)
            }
            
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>

            <div className="modal fade show" style={{ display: 'block', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">

                    {/* <div class="modal fade show" style={{}} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg"> */}
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Manage Permission</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <div className="checkbox-container">
                                {permissions.map((perm) => {
                                    if (perm.key == "behaviour" && !getPermissionValue("ewa_advance")) {
                                        return null;
                                    }
                                    return (
                                        <label key={perm.key} className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input NewCHeckBx"
                                                checked={perm.value}
                                                onChange={(e) => updatePermissions(e, perm.key)}
                                            />
                                            <span className="custom-control-label">{perm?.key?.charAt(0).toUpperCase() + perm?.key?.slice(1).replace(/_/g, ' ')}</span>
                                        </label>
                                    )
                                })}
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-dark" data-bs-dismiss="modal" onClick={() => setHandlePermissionModal(false)}>Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => PostupdatePermission()}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" />
        </>

    )
}

export default ManagePermission