import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { getApi } from '../service/adminService';
import { Breadcrumbsecond } from '../../components';
import Search from '../../components/Search/Search';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { TimeZone } from '../../components/TimeZone/TimeZone';

const Requestbyid = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [loader, setloader] = useState(true)
    const { id } = useParams('');
    const [pagesCount, setpagesCount] = useState(1);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const admin = JSON.parse(localStorage.getItem("admin"));

    const MerchantBNPLDuesDetail = async (token) => {
        //   const response = await MerchantBNPLDetail(token, id, pagesCount, search, sortedBy, orderBy);
        const response = await getApi(`/api-response-logs/${id}`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

        console.log("fdgdgdgddfgdg", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data);
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }


    useEffect(() => {
        // setpagesCount(1);
        MerchantBNPLDuesDetail(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const copiedInfo = () => {
        toast.success("Copied")
    }
    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <Breadcrumbs order={{ first: { name: "Transaction" }, second: { name: "View Details" } }} />                */}
                                <Breadcrumbsecond order={{ first: { name: "Request", path: `${prefix}/request` }, second: { name: "Request By ID" } }} />

                            </div>

                            <div className='page-rightheader'>
                                {/* <button className='btn btn-primary'  > Add Settlement</button> */}
                            </div>
                        </div>
                        <div className="modal-body application-modal">
                            <div className="row">
                                <div className="col-lg-12 col-xl-12">

                                    <div className="main-content-body main-content-body-contacts">
                                        <div className="main-contact-info-header">

                                            <div className="media-body">
                                                Result: <code className="mb-1">{dataArray?.result ? dataArray?.result : "No Data Found"} </code>
                                                <p className="mb-1">status : {dataArray?.status ? dataArray?.status : "--"}</p>
                                                <p className="">Created Date  : {dataArray?.created_at ? TimeZone(dataArray.created_at).timeZone : "--"}</p>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            }

        </>
    )
}



export default Requestbyid