import moment from 'moment';

// export const TimeZone = (created_at) => {
//     var stillUtc = moment.utc(created_at).toDate();
//     var timeZone = moment(stillUtc).local().format('YYYY-MM-DD HH:mm A');
//     return { stillUtc, timeZone }

// }

export const Gettime = (time) => {

    var stillUtcs = moment.utc(time).toDate();
    var timeZones = moment(stillUtcs).local().format('HH:mm:ss');
    return timeZones
}

export const TimeZone = (created_at) => {
    var timeZone = moment.utc(created_at).format('YYYY-MM-DD HH:mm A');
    return { timeZone }
}