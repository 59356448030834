import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../../service/employService';
import { Breadcrumbsecond } from '../../../../components';
import Loader from '../../../../components/Loader/loader';
import PaginationComponent from '../../../../PaginationComponent';
import moment from 'moment';
import UpdateLeaveModal from './UpdateLeaveModal';

const LeavesRequest = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [updateReplacement, setUpdateReplacement] = useState([]);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("site_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [multippleArray, setMultippleArray] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const handleDisableUpdate = useRef()
    const handleReplacementModal = useRef()

    const GetLeaveRequestDetail = async (token) => {
        try {
            const response = await getApi("/employee-leave-requests", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetLeaveRequestDetail();
    }, [search, pagesCount, sortedBy, orderBy]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
    }

    const selectTransactions = async (e, id) => {
        let checkArray = await multippleArray;
        let rowIndex = checkArray.findIndex(v => v == id);
        if (rowIndex > -1) {
            checkArray.splice(rowIndex, 1);
            const selectedIndex = selectedData.findIndex(item => item.id === id);
            if (selectedIndex > -1) {
                selectedData.splice(selectedIndex, 1);
            }
        } else {
            checkArray.push(id);
            const selectedItem = dataArray.find(item => item.id === id);
            if (selectedItem) {
                selectedData.push(selectedItem);
            }
        }

        setMultippleArray(checkArray);
        setDataArray((old_data) => [...old_data]);
        if (dataArray.length == multippleArray.length) {
            setIsChecked(true);
        }
        if (dataArray.length != multippleArray.length) {
            setIsChecked(false);
        }
    }

    function handleCheckClick() {
        if (isChecked == false) {
            let pendingData = dataArray.filter(v => v.status !== 'rejected');
            setSelectedData(pendingData);
            let newdata = pendingData.map(v => parseInt(v.id)).join(',');
            let arrayData = newdata.split(',').map(Number);
            setMultippleArray((old_data) => [...arrayData]);
            setIsChecked(true);
        } else {
            setIsChecked(false);
            setMultippleArray([]);
            setSelectedData([]);
        }
        setDataArray((old_data) => [...old_data]);
    }

    const checheked = (item) => {
        return multippleArray.includes(item) ? 1 : 0;
    }


    const HandleCloseButton = () => {
        handleDisableUpdate.current.click()
        setSelectedData([]);
        GetLeaveRequestDetail();
        setMultippleArray([])
    }

    const UpdateLeaveRequestDetail = async () => {
        // const filteredData = selectedData.map(obj => {
        //     return {
        //         "id": obj.id,
        //         "employer_comment": obj.employer_comment,
        //         "start_date": obj.start_date,
        //         "end_date": obj.end_date,
        //         "status": obj.status
        //     };
        // });

        try {
            const response = await postApi('/update-leave-requests', {
                employeeLeaveRequestArray: selectedData
            })
            if (response.status) {
                GetLeaveRequestDetail();
                setSelectedData([]);
                handleDisableUpdate.current.click()
                toast.success(response.message);
                setMultippleArray([])
            } else {
                // console.log(response.message)
                // GetLeaveRequestDetail();
                handleDisableUpdate.current.click()
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([
        { gridId: 1, selected: null },
        { gridId: 2, selected: null },
    ]);

    const handleCheckboxChange = (gridId, checkboxValue) => {
        const updatedSelectedCheckboxes = selectedCheckboxes.map((item) => {
            if (item.gridId === gridId) {
                return { ...item, selected: checkboxValue };
            } else {
                return item;
            }
        });
        setSelectedCheckboxes(updatedSelectedCheckboxes);
    };

    const handleReplaceEmployee = async () => {
        try {

            const filteredShift = updateReplacement?.shiftArray?.filter((item) => item?.replaceCustomerId)

            const response = await postApi('/replace-shift-employee', {
                shiftArray: filteredShift,
                ...updateReplacement
            })
            if (response.status) {
                GetLeaveRequestDetail();
                setUpdateReplacement([]);
                // handleReplacementModal.current.click()
                toast.success(response.message);
            } else {
                console.log(response.message)
                handleReplacementModal.current.click()
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbsecond order={{ first: { name: "Approval" }, second: { name: "Leaves Request" } }} />
                        </h4>
                    </div>
                </div>
            </div>

            <div className="main-container container-fluid px-0">
                <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-6 col-xm-12" >
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-2 col-lg-6 col-md-6 col-xm-12">
                        <button className='btn btn-primary ms-3'
                            data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                            disabled={multippleArray.length === 0}>Update Leaves</button>
                        {/* <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>

                        </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-hover card-table table-vcenter text-nowrap" >
                                        <thead className="border-bottom-0 pt-3 pb-3">
                                            <tr>
                                                {dataArray?.some((item) => item.status != 'rejected') &&
                                                    <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>
                                                }
                                                {/* <th className="font-weight-bold">Logo</th> */}
                                                <th className="font-weight-bold">Employee Detail  {sortedBy == "site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('site_name') }}> </i>}</th>
                                                <th className="font-weight-bold"> Leaves Detail {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}> </i>}</th>
                                                <th className="font-weight-bold"> Dates </th>
                                                <th className="font-weight-bold">Comments </th>
                                                <th className="font-weight-bold">Status </th>
                                                <th className="font-weight-bold">Created Date </th>
                                                <th className="font-weight-bold">Updated Date </th>
                                                <th className="font-weight-bold">Action</th>
                                            </tr>
                                        </thead>
                                        {loader == true ? <tbody>
                                            <td /> <td /> <td /> <td />
                                            <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                <Loader />
                                            </div> </tbody> : <>
                                            <tbody>
                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                    return (
                                                        <tr key={serial_num}
                                                        // onClick={(event) => {
                                                        //     if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'IMG') {
                                                        //         const checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
                                                        //         if (checkbox) {
                                                        //             checkbox.checked = !checkbox.checked;
                                                        //             selectTransactions(event, option.id);
                                                        //         }
                                                        //     }
                                                        // }}
                                                        >
                                                            {dataArray?.some((item) => item.status != 'rejected') &&
                                                                <td>
                                                                    {option?.status !== 'rejected' &&
                                                                        <input type="checkbox" name={option?.id} checked={checheked(option?.id) === 1 ? true : false} onChange={(event) => selectTransactions(event, option?.id)} />}
                                                                </td>
                                                            }
                                                            <td><span className="font-weight-normal1">{option?.employeeDetail?.first_name ? option?.employeeDetail?.first_name : "--"}  {option?.employeeDetail?.last_name ? option?.employeeDetail?.last_name : ""}<br />
                                                                <span style={{ textTransform: 'none' }}>{option?.employeeDetail?.personal_email ? option?.employeeDetail?.personal_email : "--"}</span></span></td>
                                                            <td><span className="font-weight-normal1">
                                                                <div className='d-flex'>
                                                                    <div className='me-2'>
                                                                        <p className='m-0 fw-bold'>Type</p>
                                                                        <p className='m-0 fw-bold'>Count</p>
                                                                        <p className='m-0 fw-bold'>Period</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className='m-0'>: {option?.leave_type ? option?.leave_type : "--"}</p>
                                                                        <p className='m-0'>:  {option?.used_leave_balance ? option?.used_leave_balance : "0"}/{option?.total_leave_balance ? option?.total_leave_balance : "0"} </p>
                                                                        <p className='m-0'>: {option?.period ? option?.period : "--"}</p>
                                                                    </div>
                                                                </div>
                                                            </span></td>
                                                            <td><span className="font-weight-normal1">
                                                                <div className='d-flex'>
                                                                    <div className='me-2'>
                                                                        <p className='m-0 fw-bold'>Start</p>
                                                                        <p className='m-0 fw-bold'>End</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className='m-0'>: {option?.start_date ? option?.start_date : "--"}</p>
                                                                        <p className='m-0'>: {option?.end_date ? option?.end_date : "--"} </p>
                                                                    </div>
                                                                </div>
                                                            </span></td>
                                                            <td><span className="font-weight-normal1">
                                                                <div className='d-flex'>
                                                                    <div className='me-2'>
                                                                        <p className='m-0 fw-bold text-wrap'>Customer</p>
                                                                        <p className='m-0 fw-bold text-wrap'>Employer</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className='m-0 text-wrap'>: {option?.customer_comment ? option?.customer_comment : "--"}</p>
                                                                        <p className='m-0 text-wrap'>: {option?.employer_comment ? option?.employer_comment : "--"} </p>
                                                                    </div>
                                                                </div>
                                                            </span></td>
                                                            <td>
                                                                <span className="font-weight-normal1">
                                                                    {option?.status === 'pending' ? (
                                                                        <span className='text-warning fw-bold'>{option?.status}</span>
                                                                    ) : option?.status === "rejected" ? (
                                                                        <span className='text-danger'>{option?.status}</span>
                                                                    ) : (
                                                                        <span className='text-success'>{option?.status}</span>
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="font-weight-normal1">{moment.utc(option.created_at).format("YYYY-MM-DD")}</span> <br />
                                                                <span className="font-weight-normal1">{moment.utc(option.created_at).format("hh:mm A")}</span>
                                                            </td>
                                                            <td>
                                                                <span className="font-weight-normal1">{moment.utc(option.updated_at).format("YYYY-MM-DD")}</span> <br />
                                                                <span className="font-weight-normal1">{moment.utc(option.updated_at).format("hh:mm A")}</span>
                                                            </td>                                                            <td>
                                                                <button className='btn btn-primary'
                                                                    disabled={option?.shiftArray?.length == 0 || option.status == "rejected"}
                                                                    data-bs-toggle="modal" data-bs-target="#staticBackdrop1"
                                                                    onClick={() => setUpdateReplacement(option)}
                                                                >
                                                                    <img src='/assets/img/user_replace.png' className='me-2' style={{ width: '1.3rem', filter: 'invert(1)' }} />
                                                                    Replace
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            <tbody>
                                                {dataArray.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody></>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {pagesCount > 0 && dataArray.length > 0 ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}
                </div>



                {/************************* Update Leave Status Modatl Start **************************/}
                <UpdateLeaveModal
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    GetLeaveRequestDetail={GetLeaveRequestDetail}
                    setMultippleArray={setMultippleArray}
                    setIsChecked={setIsChecked}
                />
                {/************************* Update Leave Status Moda End ******************************/}

                {/* <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable modal-xl">
                        <div class="modal-content" style={{ width: '108%' }}>
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Update Leaves Request</h5>
                              </div>

                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body p-0">
                                                <div className="table-responsive">
                                                    <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                        <thead className="border-bottom-0 pt-3 pb-3">
                                                            <tr>
                                                                <th className="font-weight-bold">Employee Detail  </th>
                                                                <th className="font-weight-bold"> Leaves Detail </th>
                                                                <th className="font-weight-bold"> Start Date </th>
                                                                <th className="font-weight-bold"> End Date </th>
                                                                <th className="font-weight-bold">Customer Comment </th>
                                                                <th className="font-weight-bold">Employer Comment </th>
                                                                <th className="font-weight-bold">Status </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {selectedData.length > 0 && selectedData.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                return (
                                                                    <>
                                                                        <tr key={serial_num} >
                                                                            <td><span className="font-weight-normal1">{option?.employeeDetail?.first_name ? option?.employeeDetail?.first_name : "--"} {option?.employeeDetail?.last_name ? option?.employeeDetail?.last_name : ""}</span></td>
                                                                            <td>
                                                                                <span className="font-weight-normal1">
                                                                                    <div className='d-flex'>
                                                                                        <div className='me-2'>
                                                                                            <p className='m-0 fw-bold text-wrap'>Type</p>
                                                                                            <p className='m-0 fw-bold text-wrap'>Count</p>
                                                                                            <p className='m-0 fw-bold text-wrap'>Period</p>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className='m-0 text-wrap'>: {option?.leave_type ? option?.leave_type : "--"}</p>
                                                                                            <p className='m-0 text-wrap'>: {option?.used_leave_balance ? option?.used_leave_balance : "0"}/{option?.total_leave_balance ? option?.total_leave_balance : "0"} </p>
                                                                                            <p className='m-0 text-wrap'>: {option?.period ? option?.period : "--"}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type='date'
                                                                                    value={option?.start_date}
                                                                                    className="form-control"
                                                                                    disabled={option.status !== 'approved'}
                                                                                    onChange={event => {
                                                                                        const updatedData = [...selectedData];
                                                                                        updatedData[index].start_date = event.target.value;
                                                                                        setSelectedData(updatedData);
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type='date'
                                                                                    value={option?.end_date}
                                                                                    className="form-control"
                                                                                    disabled={option.status !== 'approved'}
                                                                                    onChange={event => {
                                                                                        const updatedData = [...selectedData];
                                                                                        updatedData[index].end_date = event.target.value;
                                                                                        setSelectedData(updatedData);
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td><span className="font-weight-normal1">{option?.customer_comment}</span></td>
                                                                            <td>
                                                                                <input
                                                                                    type='text'
                                                                                    value={option?.employer_comment}
                                                                                    className="form-control"
                                                                                    placeholder='Employer Comment...'
                                                                                    onChange={event => {
                                                                                        const updatedData = [...selectedData];
                                                                                        updatedData[index].employer_comment = event.target.value;
                                                                                        setSelectedData(updatedData);
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    className="form-control mb-0"
                                                                                    value={option?.status}
                                                                                    onChange={event => {
                                                                                        const updatedData = [...selectedData];
                                                                                        updatedData[index].status = event.target.value;
                                                                                        if (event.target.value !== 'approved') {
                                                                                            updatedData[index].start_date = option.actualStartDate;
                                                                                            updatedData[index].end_date = option.actualEndDate;
                                                                                        }
                                                                                        setSelectedData(updatedData);
                                                                                    }}
                                                                                >
                                                                                    <option value="">-- SELECT -- </option>
                                                                                    <option value="rejected">Reject </option>
                                                                                    <option value="approved">Approve </option>
                                                                                </select>
                                                                            </td>

                                                                        </tr>


                                                                        {option?.status == "approved" && option?.shiftArray?.length > 0 && option?.shiftArray?.map((item, subIndex) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td></td>

                                                                                    <td>
                                                                                        <div className='grid-item'>
                                                                                            <label>Date: {item.shift_date}</label>
                                                                                            <label>JOB Type: {item?.job_type == 'parent' ? item.JT_type : item?.job_type == 'child' ? item.SJT_type : item?.job_type == 'subChild' && item.SLJT_type}</label>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td></td>

                                                                                    <td>
                                                                                        <div className='grid-item'>

                                                                                            <div style={{ position: 'relative' }}>
                                                                                                <select
                                                                                                    data-style="btn-primary"
                                                                                                    className="form-select mb-0"
                                                                                                    name="job_id"
                                                                                                    aria-label="size 2 select example"
                                                                                                    value={item?.replaceCustomerId}
                                                                                                    onChange={(e) => {
                                                                                                        const updatedArray = option?.shiftArray?.map((data => {
                                                                                                            if (data.job_id === item.job_id && data.shift_id == item.shift_id && data.shift_date == item.shift_date) {
                                                                                                                data.replaceCustomerId = parseInt(e.target.value)
                                                                                                            }
                                                                                                            return data
                                                                                                        }))

                                                                                                        const updatedData = [...selectedData];
                                                                                                        updatedData[index].shiftArray = updatedArray
                                                                                                        setSelectedData(updatedData);

                                                                                                    }}
                                                                                                >
                                                                                                    <option value="" >--  --</option>
                                                                                                    <optgroup label="Available Replacement">
                                                                                                        {item?.replacementEmployees?.map((data) => (

                                                                                                            <option data-icon="fa-heart" key={data.customer_id} value={data.customer_id}>
                                                                                                                {data.first_name}
                                                                                                            </option>
                                                                                                        ))}
                                                                                                    </optgroup>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td></td>

                                                                                    <td>
                                                                                        <div className='grid-item'>
                                                                                            <div className='text-center'>{item.shift_name} <br /> {moment(item?.shift_start_time, "HH:mm:ss").format("hh:mm A")} - {moment(item?.shift_end_time, "HH:mm:ss").format("hh:mm A")}</div>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td></td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer">
                                <button ref={handleDisableUpdate} type="button" class="btn btn-dark" data-bs-dismiss="modal"
                                    onClick={() => {}}>Close</button>
                                <button type="button" class="btn btn-primary"
                                    onClick={() => { UpdateLeaveRequestDetail() }
                                    }>Update</button>
                            </div>
                        </div>
                    </div>
                </div> */}




                {/********************************* Update Replacement Modal Start ***********************/}

                <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-6" id="staticBackdropLabel">Employee Repalcement Modal </h1>
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" /> */}
                                <h4 className='fs-5 text-primary'> {updateReplacement?.employeeDetail?.first_name} {updateReplacement?.employeeDetail?.last_name}</h4>
                            </div>
                            <div className="modal-body">


                                <table className="table table-hover card-table table-vcenter text-nowrap" style={{ alignItems: 'center', borderBottom: "1px solid #384b6e" }}>
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Employee Detail  </th>
                                            <th className="font-weight-bold"> Leaves Detail </th>
                                            <th className="font-weight-bold"> Start Date </th>
                                            <th className="font-weight-bold"> End Date </th>
                                            <th className="font-weight-bold">Customer Comment </th>
                                            <th className="font-weight-bold">Employer Comment </th>
                                            <th className="font-weight-bold">Status </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span className="font-weight-normal1">{updateReplacement?.employeeDetail?.first_name ? updateReplacement?.employeeDetail?.first_name : "--"} {updateReplacement?.employeeDetail?.last_name ? updateReplacement?.employeeDetail?.last_name : ""}</span></td>
                                            <td><span className="font-weight-normal1">
                                                <div className='d-flex'>
                                                    <div className='me-2'>
                                                        <p className='m-0 fw-bold text-wrap'>Type</p>
                                                        <p className='m-0 fw-bold text-wrap'>Count</p>
                                                        <p className='m-0 fw-bold text-wrap'>Period</p>
                                                    </div>
                                                    <div>
                                                        <p className='m-0 text-wrap'>: {updateReplacement?.leave_type ? updateReplacement?.leave_type : "--"}</p>
                                                        <p className='m-0 text-wrap'>: {updateReplacement?.used_leave_balance ? updateReplacement?.used_leave_balance : "0"}/{updateReplacement?.total_leave_balance ? updateReplacement?.total_leave_balance : "0"} </p>
                                                        <p className='m-0 text-wrap'>: {updateReplacement?.period ? updateReplacement?.period : "--"}</p>
                                                    </div>
                                                </div>
                                            </span></td>
                                            <td>
                                                <input
                                                    type='date'
                                                    value={updateReplacement?.start_date}
                                                    className="form-control"
                                                    disabled={updateReplacement.status != 'approved'}
                                                    onChange={event => {
                                                        let updatedState = {
                                                            ...updateReplacement,
                                                            start_date: event.target.value,
                                                        }
                                                        setUpdateReplacement(updatedState)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type='date'
                                                    value={updateReplacement?.end_date}
                                                    className="form-control"
                                                    disabled={updateReplacement.status != 'approved'}
                                                    onChange={event => {
                                                        let updatedState = {
                                                            ...updateReplacement,
                                                            end_date: event.target.value,
                                                        }
                                                        setUpdateReplacement(updatedState)
                                                    }}
                                                />
                                            </td>
                                            <td><span className="font-weight-normal1">{updateReplacement?.customer_comment}</span></td>
                                            <td>
                                                <input
                                                    type='text'
                                                    value={updateReplacement?.employer_comment}
                                                    className="form-control"
                                                    placeholder='Employer comment'
                                                    onChange={event => {
                                                        let updatedState = {
                                                            ...updateReplacement,
                                                            employer_comment: event.target.value,
                                                        }
                                                        setUpdateReplacement(updatedState)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    className="form-control mb-0"
                                                    value={updateReplacement?.status}
                                                    onChange={event => {
                                                        let updatedState = {
                                                            ...updateReplacement,
                                                            status: event.target.value,
                                                        }

                                                        if (event.target.value == "rejected") {
                                                            updatedState.start_date = updatedState.actualStartDate
                                                            updatedState.end_date = updatedState.actualEndDate
                                                        }

                                                        console.log("🚀 ~ updatedState:", updatedState)
                                                        setUpdateReplacement(updatedState)
                                                    }}
                                                >
                                                    <option value="">-- SELECT -- </option>
                                                    <option value="rejected">Reject </option>
                                                    <option value="approved">Approve </option>
                                                </select>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                                {updateReplacement?.status == "approved" && updateReplacement?.shiftArray?.length > 0 && updateReplacement?.shiftArray?.map((item, index) => {
                                    return (
                                        <div key={index} className='grid-container' style={{ alignItems: 'center', borderBottom: index !== updateReplacement?.shiftArray?.length - 1 ? "1px solid #384b6e" : "none", paddingBottom: index == updateReplacement?.shiftArray?.length - 1 ? '0px' : '', marginBottom: index == updateReplacement?.shiftArray?.length - 1 ? '0px' : '' }}>
                                            <div className='grid-item'>
                                                <label>Date: {item.shift_date}</label>
                                                <label>JOB Type: {item?.job_type == 'parent' ? item.JT_type : item?.job_type == 'child' ? item.SJT_type : item?.job_type == 'subChild' && item.SLJT_type}</label>

                                            </div>
                                            <div className='grid-item'>

                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        data-style="btn-primary"
                                                        className="form-select mb-0"
                                                        name="job_id"
                                                        aria-label="size 2 select example"
                                                        value={item?.replaceCustomerId}
                                                        onChange={(e) => {
                                                            const updatedArray = updateReplacement?.shiftArray?.map((data => {
                                                                if (data.job_id === item.job_id && data.shift_id == item.shift_id && data.shift_date == item.shift_date) {
                                                                    data.replaceCustomerId = parseInt(e.target.value)
                                                                }
                                                                return data
                                                            }))
                                                            setUpdateReplacement({ ...updateReplacement, shiftArray: updatedArray })
                                                        }}
                                                    >
                                                        <option value="" >--  --</option>
                                                        <optgroup label="Available Replacement">
                                                            {item?.replacementEmployees?.map((data) => (

                                                                <option data-icon="fa-heart" key={data.customer_id} value={data.customer_id}>
                                                                    {data.first_name}
                                                                </option>
                                                            ))}
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='grid-item'>
                                                <div className='text-center'>{item.shift_name} <br /> {moment(item?.shift_start_time, "HH:mm:ss").format("hh:mm A")} - {moment(item?.shift_end_time, "HH:mm:ss").format("hh:mm A")}</div>
                                            </div>
                                        </div>
                                    )
                                })}

                                <hr />

                            </div>
                            <div className="modal-footer">
                                <button
                                    ref={handleReplacementModal}
                                    type="button"
                                    className="btn btn-dark"
                                    data-bs-dismiss="modal"
                                // onClick={() => setUpdateReplacement(null)}
                                >Close</button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={(updateReplacement.status == 'pending' || updateReplacement.status == '') || (updateReplacement?.shiftArray?.filter((item) => item?.replaceCustomerId).length == 0 && updateReplacement.status == "approved")}
                                    onClick={() => handleReplaceEmployee()}
                                >Update</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/********************************* Update Replacement Modal END *************************/}

            </div >
        </>
    )
}

export default LeavesRequest;