import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../components'
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { GetCountry } from '../../employer/service/employService';
import { Link, useNavigate } from 'react-router-dom';
import { AddLenderOnboarding, postApi } from '../service/adminService';


const AddLender = () => {
    const prefix = process.env.REACT_APP_ADMIN_PRIFIX;
    const regex = /[A-Za-z]/;
    const urlR = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    const [country, setCountry] = useState([]);
    const [logoValue, setDocValue] = useState({})
    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token
    const navigate = useNavigate();
    const getCountry = async () => {
        const response = await GetCountry();
        if (response.status == true) {
            setCountry(response.data);
        } else {
            console.log("get Country data response", response);
        }
    }

    useEffect(() => {
        getCountry();
    }, [])

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
        marginRight: '10px'
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '325px',
        marginTop: '16px',
    }

    const form = useFormik({
        initialValues: {
            company_name: "",
            full_name: "",
            mobile_number: "",
            gst_number: "",
            address: "",
            website: "",
            email: "",
            country: "",
            mini_rate_of_intrest: "",
            max_rate_of_intrest: "",
            mini_loan_range: "",
            max_loan_range: "",
            mini_tenure: "",
            max_tenure: "",
            password: "",
            confirmedPassword: "",
            logo: "",
            dsa_doc: "",
            mobile_wrong_message: "",
            email_wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            company_name: yup.string().required('Please Enter Lender Name').matches(regex, 'Please Enter Only Character Values'),
            full_name: yup.string().required('Please Enter Full Name').matches(regex, 'Please Enter Only Character Values'),
            mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number values') */.min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Max Contain 15 Number"),
            email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
            gst_number: yup.string().required('Please Enter Bussiness Registration Number')/* .matches(res, 'Please Enter Only Numbers') */,
            address: yup.string().required('Please Enter Address').matches(regex, 'Please Enter Only Character Values'),
            website: yup.string().required('Please Enter website').matches(urlR, 'Please Enter Valid Website URL'),
            country: yup.string().required('Please Enter Country'),
            mini_rate_of_intrest: yup.number().required('Please Enter Minimum Rate Of Interest').typeError('Please Enter a Valid Rate Of Intrest'),
            max_rate_of_intrest: yup.number().required('Please Enter Maximum Rate Of Interest').moreThan(yup.ref('mini_rate_of_intrest'), "Max Rate Of Intrest Should Be Greater Than Min Rate Of Interest"),
            mini_loan_range: yup.number().required('Please Enter Minimum EWA Advance Range Amount').typeError('Please Enter Valid EWA Advance Range Amount'),
            max_loan_range: yup.number().required('Please Enter Maximum EWA Advance Range Amount').typeError('Please Enter Valid EWA Advance Range Amount').moreThan(yup.ref("mini_loan_range"), "Max EWA Advance Range Should Be Greater Than Min EWA Advance Range"),
            mini_tenure: yup.string().required('Please Enter Minimum Tenure')/* .matches(re ,'Please Enter Only Valid Month Count') */
                .max(2, `Min Tenure Max Contain 2 Number`),
            max_tenure: yup.number().required('Please Enter Maximum Tenure')/* .matches(res ,'Please Enter Only Valid Month Count') */
                .moreThan(yup.ref('mini_tenure'), "Max Tenure Should Be Greater Than Min Tenure")/* .max(2,`Max Tenure Max Contain 2 Number`) */,
            password: yup.string().required('Please Enter Password')/* .matches(regex, 'Please Enter Only Character Values') */,
            confirmedPassword: yup.string().required('Please Enter Confirm Password')/* .matches(regex, 'Please Enter Only Character Values') */
                .oneOf([yup.ref("password"), null], "Password Must Be Same"),
            // logo: yup.string().required('Please upload your logo'),
            logo: yup.string().nullable().required('Please upload your logo'),
        }),

        onSubmit: async values => {

            const formData = new FormData();
            formData.append("logo", logoValue);
            formData.append("company_name", form.getFieldProps('company_name').value);
            formData.append("full_name", form.getFieldProps('full_name').value);
            formData.append("address", form.getFieldProps('address').value);
            formData.append("mobile_number", form.getFieldProps('mobile_number').value);
            formData.append("country", form.getFieldProps('country').value);
            formData.append("gst_number", form.getFieldProps('gst_number').value);
            formData.append("website", form.getFieldProps('website').value);
            formData.append("email", form.getFieldProps('email').value);
            formData.append("password", form.getFieldProps('password').value);
            formData.append("confirmedPassword", form.getFieldProps('confirmedPassword').value);
            formData.append("mini_rate_of_intrest", form.getFieldProps('mini_rate_of_intrest').value);
            formData.append("max_rate_of_intrest", form.getFieldProps('max_rate_of_intrest').value);
            formData.append("mini_loan_range", form.getFieldProps('mini_loan_range').value);
            formData.append("max_loan_range", form.getFieldProps('max_loan_range').value);
            formData.append("mini_tenure", form.getFieldProps('mini_tenure').value);
            formData.append("max_tenure", form.getFieldProps('max_tenure').value);

            // const response = await AddLenderOnboarding(token, formData ,true);
            const response = await postApi("/add-lender", formData, true);

            console.log(response)


            if (response.status === true) {
                // toast.success(response.message);
                toast.success("Lender Registration Done Successfully");

                navigate(process.env.REACT_APP_ADMIN_PRIFIX + '/lender');
            } else {
                if (response.message == 'mobile_number must be unique') {

                    toast.error('Mobile Number Already Exists');
                } else {
                    toast.error(response.message);
                }
            }
        },

    });
    // const uploadLogoFunc = async (e = "") => {
    //     const file = (e.target.files[0] ? e.target.files[0] : "")
    //     if (logoValue == {}) {
    //         console.log("First11111111111111111")
    //         form.setValues({
    //             ...form.values,
    //             logo: null
    //         });
    //     } else {
    //         console.log("Second22222222222222")
    //         form.setValues({
    //             ...form.values,
    //             logo: file
    //         })
    //     }
    //     setDocValue(file);
    // }
    const uploadLogoFunc = async (e = "") => {
        if (e.target && e.target.files) {
            const file = e.target.files[0] || "";
            if (Object.keys(logoValue).length === 0 && e.target.files[0] &&
                (e.target.files[0].type === "image/png" ||
                    e.target.files[0].type === "image/jpeg" ||
                    e.target.files[0].type === "image/svg+xml")) {
                setDocValue(file);
                form.setValues({
                    ...form.values,
                    logo: file,
                });
            } else {
                toast.error("Invalid file type. Please upload a PNG, JPEG, or SVG file.")
                form.setValues({
                    ...form.values,
                    logo: null,
                });
                setDocValue({});
            }

        }
        else {
            setDocValue({});
            form.setValues({
                ...form.values,
                logo: null,
            });
        }
    };

    const imageUpload = (e) => {
        // console.log("event pancard", e);
        const file = e.target.files[0];
        form.setValues({
            ...form.values,
            logo: file
        })
    }
    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">

                    <h4 className="page-title mb-0 text-primary">

                        <Breadcrumbs order={{ first: { name: "Lender", path: `${prefix}/lender` }, second: { name: "Add Lender" } }} />
                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Lender Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Lender Name" {...form.getFieldProps("company_name")} />
                                </div>
                                {form.touched.company_name && form.errors.company_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.company_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Name Of Contact Person</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Name" {...form.getFieldProps("full_name")} />
                                </div>
                                {form.touched.full_name && form.errors.full_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Mobile Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Mobile Number" {...form.getFieldProps("mobile_number")} />
                                </div>
                                {form.touched.mobile_number && form.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Business Registration No.</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" name="gst_number" {...form.getFieldProps("gst_number")} className="form-control" placeholder="Business Registration No." />

                                </div>
                                {form.touched.gst_number && form.errors.gst_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.gst_number}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Address</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Address" {...form.getFieldProps("address")} />
                                </div>
                                {form.touched.address && form.errors.address ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.address}</div> : ''}
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Website</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Website" {...form.getFieldProps("website")} />
                                </div>
                                {form.touched.website && form.errors.website ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.website}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Enter Email" {...form.getFieldProps("email")} />
                                </div>
                                {form.touched.email && form.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Country</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <select className="form-control" name="country" {...form.getFieldProps("country")}>
                                        <option value="">Select Country</option>
                                        {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                                    </select>
                                </div>
                                {form.touched.country && form.errors.country ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.country}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Min Rate Of Interest</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" /* min="0" step="any" */ name="mini_rate_of_intrest" {...form.getFieldProps("mini_rate_of_intrest")} className="form-control" placeholder="Minimum Rate of Interest" />
                                </div>
                                {form.touched.mini_rate_of_intrest && form.errors.mini_rate_of_intrest ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mini_rate_of_intrest}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Max Rate Of Interest</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number"  /* min="0"  step="any" */ name="max_rate_of_intrest" {...form.getFieldProps("max_rate_of_intrest")}
                                        className="form-control" placeholder="Maximum Rate of Interest" />
                                </div>
                                {form.touched.max_rate_of_intrest && form.errors.max_rate_of_intrest ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.max_rate_of_intrest}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Min EWA Advance Range</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" name="mini_loan_range" {...form.getFieldProps("mini_loan_range")} className="form-control" placeholder="Minimum EWA Advance Range" />

                                </div>
                                {form.touched.mini_loan_range && form.errors.mini_loan_range ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mini_loan_range}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Max EWA Advance Range</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" name="max_loan_range" {...form.getFieldProps("max_loan_range")} className="form-control" placeholder="Maximum EWA Advance Range" />
                                </div>
                                {form.touched.max_loan_range && form.errors.max_loan_range ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.max_loan_range}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Min Tanure</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" min="0" name="mini_tenure" {...form.getFieldProps("mini_tenure")} className="form-control" placeholder="Minimum Tenure (Months)" />

                                </div>
                                {form.touched.mini_tenure && form.errors.mini_tenure ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mini_tenure}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Max Tanure</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" name="max_tenure" {...form.getFieldProps("max_tenure")} className="form-control" placeholder="Maximum Tenure (Months)" />
                                </div>
                                {form.touched.max_tenure && form.errors.max_tenure ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.max_tenure}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Password</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" {...form.getFieldProps("password")} type={values.showPassword ? "text" : "password"} name="password" placeholder="Password" id="password-field" />                                {!values.showPassword ?
                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                        :
                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                    }

                                </div>
                                {form.touched.password && form.errors.password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Confirm Password</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" {...form.getFieldProps("confirmedPassword")} type={valuesConfP.showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />

                                    {!valuesConfP.showConfPassword ?
                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                        :
                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                    }
                                </div>
                                {form.touched.confirmedPassword && form.errors.confirmedPassword ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirmedPassword}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Logo</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control browse-file" name={logoValue} placeholder="Upload your logo" />
                                    <label className="input-group-text btn btn-primary"> Browse <input type="file" className="file-browserinput" onChangeCapture={uploadLogoFunc} /* onChange={(e) => imageUpload(e, 'dsa_doc', 'uploads/merchant/pancard')} */ style={{ display: 'none' }} multiple /> </label>
                                    {form.touched.logo && form.errors.logo ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}
                                </div>
                            </div>

                            {logoValue && logoValue.name ? <ul className="imgpreview-newbx">
                                <li className="registerboximg">
                                    <img src={URL.createObjectURL(logoValue)} alt="" />
                                    <Link to="" onClick={() => { setDocValue({}); uploadLogoFunc() }} className="upload__img-close"><i className="fa-solid fa-close fa-fw" ></i></Link>
                                    <p>img preview name here</p>
                                </li>
                            </ul> : ""}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Save Changes</button>
                    </div>


                </div>
            </form>

        </div>
    )
}

export default AddLender
