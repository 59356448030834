import React from 'react'
import { Oval, RotatingLines } from 'react-loader-spinner';

const Loader = (props) => {
  /* height = 150, width = 120, strokeWidthSeco2dary = 2 */
  // console.log(props)
  return (
    <>
      {!props.status && <Oval
        // height='150'
        // width='120'
        height='70'
        width='50'
        color="#4239a4"
        wrapperStyle={{}}
        wrapperclassName=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="white"
        strokeWidth={2}
        strokeWidthSecondary={2}

      />}
      {props.status &&
        <RotatingLines
          visible={true}
          height="20"
          width="20"
          color="blue"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />}
    </>

  )
}

export default Loader
