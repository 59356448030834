import React from 'react'
import { Link, useParams } from 'react-router-dom';

const Merchantdetail = () => {
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const { id } = useParams();
  return (
    <div className="main-container container-fluid px-0">
    <div className="page-header my-3 py-5">
        <div className="modal-body application-modal">
            <div className="row">
            <div className="modal-header w-100" >
                        {/* <h3 className="modal-title">Merchant Information</h3> */}
                        <h4 className="page-title mb-0 text-primary">Borrower <i className='fas fa-arrow-right'/> Used Limit  <i className='fas fa-arrow-right'/> Merchant Information</h4>

                   
                    <Link to={prefix + '/employee'} >Back</Link>
                     </div>
                <div className="col-lg-12 col-xl-12">
                  
                        <div className="main-content-body main-content-body-contacts">
                                           
                            <div className="main-contact-info-body">
                                <div className="media-list p-0">

                                    <div className="media py-4 mt-0">
                                        <div className="media-body">
                                            <div className="d-flex">
                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-user"></i> </div>
                                                <div className="w-70"> <label>Full Name : </label> <span className="font-weight-normal1 fs-14">{/* {employeeDetail ? employeeDetail?.full_name : ''} */}</span> </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-user-circle"></i> </div>
                                                <div className="w-70"> <label>Company Name : </label> <span className="font-weight-normal1 fs-14">{/* {employeeDetail?.company_name} */}</span> </div>
                                            </div>
                                         
                                        </div>
                                    </div>

                                    <div className="media py-4 border-top mt-0">
                                        <div className="media-body">
                                           
                                            <div className="d-flex">
                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                <div className="w-70"> <label>Website :</label> <span className="font-weight-normal1 fs-14">{/* {employeeDetail ? employeeDetail?.website : ''} */}</span> </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-address-book"></i> </div>
                                                <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{/* {employeeDetail ? employeeDetail?.address : ''} */}</span> </div>
                                            </div>
                                        </div>
                                    </div>                                             
                                 

                                    <div className="media py-4 border-top mt-0">
                                        <div className="media-body">
                                           
                                            <div className="d-flex">
                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-phone"></i> </div>
                                                <div className="w-70"> <label>Mobile No. :</label> <span className="font-weight-normal1 fs-14">{/* {employeeDetail ? employeeDetail?.mobile_number : ''} */}</span> </div>
                                            </div>
                                         
                                            <div className="d-flex">
                                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-map-marker-alt"></i> </div>
                                                <div className="w-70"> <label>Country :</label> <span className="font-weight-normal1 fs-14">{/* {employeeDetail ? employeeDetail?.country : ''} */}</span> </div>
                                            </div>
                                        </div>
                                    </div>                                            

                               
                                  

<div className="col-lg-12 col-xl-12 p-0">
              <div className="box-widget widget-user">
                <div className="widget-user-image1 d-xl-flex d-block flexwrap">
                  <div className="col-md-12">
                    {/* <h4 className="pro-user-username mb-3 font-weight-bold">Employer Logo</h4> */}
                    <div className="w-70 "> <h5 style={{marginLeft: "66px"}}>Logo :</h5> </div>
                    <div className="row">
                      <div className="media col-md-4">
                        <div className="media-body">                        
                            <img src={process.env.PUBLIC_URL + '/assets/img/upload-logo-lender.png'}  style={{ height: "230px", width: "230px", objectFit: "contain" }} />                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                                
                                </div>
                            </div>
                        </div>
                   
                </div>
            </div>

        </div>
    </div>
</div>
  )
}

export default Merchantdetail
