import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import PaginationComponent from '../../PaginationComponent';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getApi, postApi, deleteApi } from '../service/adminService';
// import AssignToLender from '../layout/Model';
import Loader from '../../components/Loader/loader';
import { Image, Switch } from 'antd';
import Swal from 'sweetalert2';
import Search from '../../components/Search/Search';
const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const Merchant = (props) => {
    const ref = useRef()
    const [loader, setloader] = useState(true)
    const [counterid, setcounterid] = useState();
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [endDate, setendDate] = useState('');
    const [startDate, setstartDate] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const navigate = useNavigate()
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [ValidPagination, setValidPagination] = useState(false)

    const [queryFilter, setQueryFilter] = useState({
        status: props.caseStatus ? props.caseStatus : '',
        search: '',
        lender_id: props.lender_id ? props.lender_id : '',
        lender_id_op: "eq",
        dsa_id: props.dsa_id ? props.dsa_id : '',
        dsa_id_op: "eq",
        file_id: '',
        file_id_op: "eq",
        loan_type: '',
        loan_type_op: "eq",
        full_name: "",
        full_name_op: "eq",
        company_name: "",
        company_name_op: "eq",
        email: "",
        email_op: "eq",
        mobile_number: "",
        mobile_number_op: "",
        age: '',
        age_op: "eq",
        created_at: '',
        created_at_op: "eq",
        received_time: '',
        received_time_op: "eq",
        short_close_time: '',
        short_close_time_op: "eq",
        comment_time: '',
        comment_time_op: "eq",
        remark_time: '',
        remark_time_op: "eq",
        assigned_time: '',
        assigned_time_op: "eq",
        logged_time: '',
        logged_time_op: "eq",
        pending_time: '',
        pending_time_op: "eq",
        approved_time: '',
        approved_time_op: "eq",
        reject_time: '',
        reject_time_op: "eq",
        disbursed_time: '',
        disbursed_tim_op: '=',
        city: '',
        city_op: "eq",
        pincode: '',
        pincode_op: "eq",
        state: '',
        state_op: "eq",
        organization_type: '',
        organization_type_op: "eq",
        father_name: '',
        father_name_op: "eq",
        qualification: "",
        qualification_op: "eq",
        marital_status: "",
        marital_status_op: "eq",
        employer_name: "",
        employer_name_op: "eq",
        orderBy: "",
        orderBy_op: "",

    });


    const getMerchantsData = async (token, pageNumber, filterVlu) => {
        // const response = await GetMerchants(token, pageNumber, filterVlu, sortedBy, orderBy);
        const response = await getApi("/get-merchant-list", { page_number: pageNumber, search: filterVlu, sortedBy: sortedBy, orderBy: orderBy });

        if (response?.status == true) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.AllMerchant);
                settotalItems(response.AllMerchant)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get merchant data response", response);
        }
    }

    // const getDetail = async (token, id) => {
    //     const response = await GetMerchantrDetail(token, id);
    //     console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
    //     if (response.status) {
    //         setDetail(response.data);
    //         setBankData(response.bankData)
    //     } else {
    //         console.log("get merchant data response", response);
    //     }
    // }

    // const deleteEmployees = async (id) => {
    //     let raw = JSON.stringify({ id });
    //     const res = await DeleteEmployees(admin.admin_token, raw);
    //     if (res.status) {
    //         toast.success(res.message);
    //     } else {
    //         toast.error(res.message);
    //         console.log(res);
    //     }
    // }

    useEffect(() => {
        // setpagesCount(1);
        getMerchantsData(admin.admin_token, pagesCount, search);
    }, [search, pagesCount, sortedBy, orderBy]);

    const handleChange = e => {
        const { name, value } = e.target;
        setQueryFilter(prevState => ({
            ...prevState,
            [name]: value.trim()
        }));
    };

    const handleChangeQueryBuilder = e => {
        const { name, value } = e.target;
        setQueryFilter(prevState => ({
            ...prevState,
            [name]: value.trim()
        }));
    };

    const handleCallback = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        setstartDate(picker.startDate.format('MM/DD/YYYY'))
        setendDate(picker.endDate.format('MM/DD/YYYY'))
    }

    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate('')
        setendDate('')
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // getMerchantsData(admin.admin_token, pagesCount, search);
    }

    const form = useFormik({
        initialValues: {
            user_id: "",
            new_password: "",
            confirm_password: "",
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            new_password: yup.string().required('Please Enter New Password')/* .matches(regex, 'Please Enter Only Character Values') */,
            confirm_password: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("new_password"), null], "Password Must Be Same"),

        }),
        onSubmit: async (values, { resetForm }) => {
            console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
            const data = JSON.stringify({
                user_id: counterid,
                new_password: values.new_password,
                confirm_password: values.confirm_password,

            })
            console.log(data)
            // const response = await AdminChangePasword(admin.admin_token, data);
            const response = await postApi("/changeUserPasswordByAdmin", {
                user_id: counterid,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            });

            console.log("#@#@#", response);
            resetForm({ values: '' });
            ref.current.click()
            //    ref.resetForm({ values: '' });
            if (response.status == false) {
                console.log(response.message);
                toast.error(response.message);
                ref.current.click()

            }
            if (response.status == true) {
                //    console.log(response.message);	
                toast.success(response.message);
                // window.$('#modal-center').modal('hide')	 
                ref.current.click()

            } else {

                console.log("#@#@#1234", response);
            }

        }
    });
    const [values, setValues] = React.useState({
        new_password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        new_password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',

    }

    const eyeBtnStyle2 = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
    }

    const eyeBtnStyle3 = {
        position: 'absolute',
        marginLeft: '400px',
        marginTop: '-40px',
    }

    const [toggle, setToggle] = useState(true)
    const Registration = async (id, e) => {
        // let data = {
        //     "id": id,
        //     "status": e == true ? '1' : '0',
        // }
        try {
            // const total = await changeStatus(admin.admin_token, data)
            const total = await getApi(`/verify/${id}`)
            // console.log('changeStatus', total)
            //   changeStatusToast();
        } catch (error) {
            console.log(error)
        }
    }

    const handleNavigate = (id) => {
        // window.alert("check employer")
        navigate(`${prefix}/editmerchant/` + id.user_id, { state: id })
    }

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                // const response = await deleteMerchant(admin.admin_token, data)
                const response = await deleteApi(`/delete-merchant/${data}`)

                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'User Account has been deleted Successfully.',
                        'success'
                    )
                    //   setState(!state)
                    getMerchantsData(admin.admin_token, pagesCount, search);
                }
                else {
                    toast.error("something went wrong")
                }
            }
        })
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const openModal = (userId) => {
        setIsModalOpen(true);
        setSelectedUserId(userId);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Merchant Reports {totalItems ? '(' + totalItems + ')' : ''}</h4>
                            </div>
                            {/* <AssignToLender show={show} handleShow={handleShow} handleClose={handleClose} /> */}
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="/lender/add_cases" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Employer's</Link> */}
                                    <Link to={prefix + "/merchant/trash"} className="btn btn-primary btn-pill mt-2" ><i className="fa fa-trash "></i> Recover</Link>
                                    <Link to={prefix + "/addmerchant"} className="btn btn-primary btn-pill mt-2" ><i className="fa fa-plus me-2"></i> Add Merchant</Link>
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name="status" value={queryFilter.status} onChange={(e) => handleChangeQueryBuilder(e)}>
                                                    <option>Select Status</option>
                                                    <option value="Active Employer">Active Merchant</option>
                                                    <option value="InActive Employer">InActive Merchant</option>
                                                   
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Incomplete Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0"><option>Select Status</option></select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Loan Type</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name="loan_type" value={queryFilter.loan_type} onChange={(e) => handleChangeQueryBuilder(e)}>
                                                    <option>Select Loan</option>
                                                    <option value="1">Personal</option>
                                                    <option value="2">Business</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Select Date</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <DateRangePicker onApply={handleCallback} onCancel={handleCallbackOnCancel} initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                                    <input placeholder="Search By Date" className="form-control fc-datepicker hasDatepicker" type="text" defaultValue="" />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {/* <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="card">
                                    <div className="card-header br-0">
                                        <h3 className="card-title"> Cases Report {totalItems ? '(' + totalItems + ')' : ''} </h3> */}
                        {/* <div className="card-options"> 
							    <Link to="" className="btn btn-sm btn-outline-primary mx-sm-2"><i className="fa fa-file-upload"></i> Upload Cases</Link> 
								<Link to="" className="btn btn-sm btn-primary "><i className="fa fa-file-upload"></i> Update Cases</Link> 
							</div>  */}
                        {/* </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Logo</th>
                                                        <th className="font-weight-bold">Merchant {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Authorize Person Name {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }}></i>}</th>
                                                        <th className="font-weight-bold">Company Registration/Tax ID  {sortedBy == "gst_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('gst_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('gst_number') }}></i>}</th>
                                                        <th className="font-weight-bold">Address {sortedBy == "address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }}></i>}</th>
                                                        <th className="font-weight-bold">Wesbite {sortedBy == "website" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }}></i>}</th>
                                                        <th className="font-weight-bold"> Active/Inactive</th>
                                                        <th className="font-weight-bold"> Actions</th>
                                                    </tr>
                                                </thead>

                                                {loader == true ? <tbody>
                                                    <td /><td /><td />
                                                    <div >
                                                        <Loader /> </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                // var Capitalize = (option) => {
                                                                //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                                // }
                                                                // var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                                // var timezone1 = new Date(option.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                                return (
                                                                    <tr key={serial_num} >
                                                                        {/* <td><Link to={props.prefix + '/case/' + option?.loan_id+ '/' + option?.customer?.customer_id +'/' + option?.loan_type + '/' + option?.customer_lender_assign?.lender?.user_id + '/' + option?.customer_lender_assign?.file_id}>{option.customer_lender_assign ? option.customer_lender_assign.file_id : ''}<br /><span className="font-weight-normal1">{option.customer ? option.customer.full_name : ''}</span></Link></td>
                                                            <td>
                                                                <span className="font-weight-normal1">{option.customer_lender_assign ? option.customer_lender_assign.lender?.file_id : ''}</span>
                                                                <br />
                                                                <span className="font-weight-normal1">{option.customer_lender_assign ? option.customer_lender_assign.lender?.company_name : ''}</span>
                                                            </td> */}

                                                                        <td>
                                                                            <span className="font-weight-normal1" >
                                                                                <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={option.logo != null ? option.logo : process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' />
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <Link className="text-primary" to={prefix + `/merchantdetail/${option.user_id}`}>
                                                                                {/* <Link to="" onClick={() => getDetail(admin.admin_token, option.user_id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal" > */}
                                                                                <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>
                                                                                    {option.full_name !== null ? (option.company_name) : ''} <br />{option.mobile_number}
                                                                                </span>
                                                                            </Link>
                                                                        </td>
                                                                        {/* {isModalOpen && (
                                                                            <Merchantdetail
                                                                                token={admin.admin_token}
                                                                                id={selectedUserId}
                                                                                closeModal={closeModal}
                                                                            />
                                                                        )} */}
                                                                        <td><span className="font-weight-normal1">{option.full_name !== null ? (option.full_name) : ""}</span></td>
                                                                        <td className='font-weight-normal1'><span >{option.gst_number}</span></td>
                                                                        {/* <td><span className="font-weight-normal1">{option.mobile_number}</span></td> */}
                                                                        <td className='font-weight-normal1'><span className="content_fix" >{option.address ? (option.address) : ""}</span></td>
                                                                        <td className='font-weight-normal1'><span   ><a href={option.website} target="blank" style={{ color: "#0f2f82" }}>{option.website ? option.website : ""}</a></span></td>

                                                                        <td>{option.is_verified == '1' &&
                                                                            <div className="col-auto ms-auto d-print-none">
                                                                                <Switch checkedChildren="Active" onClick={(e) => Registration(option.user_id, e)} unCheckedChildren="Inactive" defaultChecked
                                                                                    onChange={(e) => setToggle(e)} /* style={{ backgroundColor: toggle === true ? 'green' : 'red' }} */ />
                                                                                <span>
                                                                                </span>
                                                                            </div>}
                                                                            {option.is_verified == '0' &&
                                                                                <div className="col-auto ms-auto d-print-none">
                                                                                    <Switch checkedChildren="Active" onClick={(e) => Registration(option.user_id, e)} unCheckedChildren="Inactive"
                                                                                        onChange={(e) => setToggle(e)} /* style={{ backgroundColor: '#1a48aa' }} */ />
                                                                                    <span>
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                        {/* <td> <i onClick={() => handleNavigate(option)} className="fa fa-edit me-2 btn btn-primary"></i>                                                                             
                                                                            <button onClick={() => { setcounterid(option.user_id) }} type="button" className="btn btn-primary"
                                                                            data-bs-target="#add_settlement" data-bs-toggle="modal" ><i className="fas fa-lock-open " /></button> &nbsp;&nbsp;
                                                                           <button type="button" className='btn btn-primary'><i className="fas fa-trash " onClick={()=>{handleDelete(option.user_id)} } /></button>
                                                                        </td> */}
                                                                        {/* <td>
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle className='view-pro' id="dropdown-basic" >	ACTION</Dropdown.Toggle>
                                                                                <Dropdown.Menu style={{ width: '100px' }}>
                                                                                    <Dropdown.Item><span onClick={() => handleNavigate(option)} className="font-weight-normal1 text-dark">Edit</span></Dropdown.Item>
                                                                                    <Dropdown.Item><Link to={prefix + `/store/${option.user_id}`}  > <span className="font-weight-normal1 text-dark">View Store</span></Link></Dropdown.Item>
                                                                                    <Dropdown.Item><span onClick={() => { setcounterid(option.user_id) }} className='font-weight-normal1' data-bs-target="#add_settlement" data-bs-toggle="modal" >Change Password</span></Dropdown.Item>
                                                                                    <Dropdown.Item><span className='font-weight-normal1' onClick={() => { handleDelete(option.user_id) }} >Delete</span></Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td> */}
                                                                        <td>
                                                                            <div className="dropdown-action">
                                                                                <button className="btn view-pro dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                                    Action
                                                                                    <span className="caret"></span>
                                                                                </button>
                                                                                <ul className="dropdown-menu actionfix" aria-labelledby="dropdownMenu1">
                                                                                    <li> <span onClick={() => handleNavigate(option)} className="font-weight-normal1" style={{ marginLeft: '17px', cursor: 'pointer', fontSize: 'small' }}>Edit</span></li>
                                                                                    <li><Link to={prefix + `/store/${option.user_id}`}  > <span className="font-weight-normal1">View Store</span></Link></li>
                                                                                    <li><Link><span onClick={() => { setcounterid(option.user_id) }} className='font-weight-normal1' data-bs-target="#add_settlement" data-bs-toggle="modal" >Change Password</span></Link></li>
                                                                                    <li><Link><span className='font-weight-normal1' onClick={() => { handleDelete(option.user_id) }} >Delete</span></Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>}
                                                        </tbody></>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                    {/* Change Password Start */}
                    <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header">
                                    <h3 className="modal-title">Change Password</h3>
                                    <button ref={ref} aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                        aria-hidden="true">×</span></button>
                                </div>

                                <div className="modal-body application-modal">

                                    <form className="row" onSubmit={form.handleSubmit}>
                                        <div className="row">
                                            <div className="row g-xs">
                                                <div className="col-12">
                                                    {/* <input type="password"  placeholder='Enter New Password' className="form-control" {...form.getFieldProps("new_password")}/>   */}
                                                    <input className="form-control" type={values.showPassword ? "text" : "password"} placeholder="New Password"
                                                        name="password" {...form.getFieldProps("new_password")} />
                                                    {!values.showPassword ?
                                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                        :
                                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                    }
                                                    {form.touched.new_password && form.errors.new_password ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.new_password}</div> : ''}
                                                </div>
                                            </div>
                                            <div className="row g-xs">
                                                <div className="col-12">
                                                    {/* <input type="password"  placeholder='Enter Confirm Password' className="form-control"  {...form.getFieldProps("confirm_password")}/>  */}
                                                    <input className="form-control" type={valuesConfP.showConfPassword ? "text" : "password"} placeholder="Confirm Password"
                                                        name="confirmedPassword"  {...form.getFieldProps("confirm_password")} />

                                                    {!valuesConfP.showConfPassword ?
                                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle2} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                        :
                                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle3} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                    }
                                                    {form.touched.confirm_password && form.errors.confirm_password ?
                                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_password}</div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-footer mt-2">
                                            <button type="submit" className="btn btn-primary" aria-hidden="true" >
                                                Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Change Password End */}

                    {/* Merchant Information Start */}
                    {/* <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header"> <h6 className="modal-title">Merchant Information</h6>
                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>
                                <div className="modal-body application-modal">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="">
                                                <div className="main-content-body main-content-body-contacts">
                                                    <div className="main-contact-info-header bg-contacthead pb15">
                                                        <div className="media">
                                                            <div className="media-body text-white ml-0">
                                                                <h4 className="text-white">Company Name: {detail && detail.company_name ? detail.company_name : ''}</h4>
                                                                <p className="mb-1">Person Name: {detail && detail ? detail.full_name : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="main-contact-info-body">
                                                        <div className="media-list p-0">
                                                            <div className="media py-4 mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                                        <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.mobile_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                        <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.email : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                        <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.address : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">

                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-earth-americas"></i> </div>
                                                                        <div className="w-70"> <label>Country:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.country : ''}</span> </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-circle"></i> </div>
                                                                        <div className="w-70"> <label>Account Name</label> <span className="font-weight-normal1 fs-14">{bankData && bankData ? bankData.account_name : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-edit fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Account No.</label> <span className="font-weight-normal1 fs-14">{bankData && bankData ? bankData.account_number : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-piggy-bank fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Bank Name</label> <span className="font-weight-normal1 fs-14">{bankData && bankData ? bankData.bank_name : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-address-book fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Branch Name</label> <span className="font-weight-normal1 fs-14">{bankData && bankData ? bankData.branch_name : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-home fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Bank State Branch</label> <span className="font-weight-normal1 fs-14">{bankData && bankData ? bankData.bsb : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Company Registration/Tax ID</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.gst_number : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>                                                     
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* Merchant Information End */}
                </div>

            }
        </>
    )
}


export default Merchant;
