import React from 'react'

const Monthly = ({ employeeDetail }) => {
    return (
        <div style={{ marginTop: "30px" }}>
            <h3>Living Cost</h3>

            <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                <div className="media-list p-0">

                    <div className="media py-4 mt-0">
                        <div className="media-body">
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-home"></i> </div>
                                <div className="w-70"> <label>Rent : </label> <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.rent ? "$" + employeeDetail?.rent : '--'}</b></span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-lightbulb"></i> </div>
                                <div className="w-70"> <label>Electricity : </label> <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.electricity ? "$" + employeeDetail?.electricity : '--'}</b></span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                <div className="w-70"> <label>Telephone Cost :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.bill_for_phone ? "$" + employeeDetail?.bill_for_phone : '--'}</span> </div>
                            </div>
                        </div>
                    </div>

                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">

                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-water"></i> </div>
                                <div className="w-70"> <label>Water :
                                </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.bill_for_water ? "$" + employeeDetail?.bill_for_water : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house-user"></i> </div>
                                <div className="w-70"> <label>House Keeping :</label> <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.house_keeping ? "$" + employeeDetail?.house_keeping : '--'}</b></span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-book-reader"></i> </div>
                                <div className="w-70">
                                    <label>School Fees :</label>
                                    <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.school_fees ? "$" + employeeDetail?.school_fees : '--'}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-gas-pump"></i> </div>
                                <div className="w-70"> <label>Travelling/Petrol/Bus :</label> <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.travelling ? "$" + employeeDetail?.travelling : '--'}</b></span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-tv"></i></div>
                                <div className="w-70">
                                    <label>Entertainment/Video :</label>
                                    <span className="font-weight-normal1 fs-14"><b>{employeeDetail?.entertainment ? "$" + employeeDetail?.entertainment : '--'}</b>  </span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="material-icons mt-1">&#xe8a1; </i></div>
                                <div className="w-70"> <label>Total Hire Purchase Payments :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.hire_payments ? "$" + employeeDetail?.hire_payments : '--'}</span> </div>
                            </div>
                        </div>
                    </div>

                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-shield-alt"></i> </div>
                                <div className="w-70"> <label>Total Hire Purchase Payments:</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.hire_payments ? "$" + employeeDetail?.hire_payments : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-shield-alt"></i> </div>
                                <div className="w-70"> <label>Life Insurance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.life_insurance ? "$" + employeeDetail?.life_insurance : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                {/* <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-shield-alt"></i> </div> */}
                                {/* <div className="w-70"> <label>Life Insurance :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.life_insurance ? "$" + employeeDetail?.life_insurance : '--'}</span> </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>)
}

export default Monthly