import React, { useRef } from 'react'
import { postApi } from '../../../service/employService';
import toast from 'react-hot-toast';
import moment from 'moment';

const UpdateLeaveModal = ({ selectedData, setSelectedData, GetLeaveRequestDetail, setMultippleArray, setIsChecked }) => {
    const handleDisableUpdate = useRef()

    const HandleCloseButton = () => {
        setSelectedData([]);
        GetLeaveRequestDetail();
        setMultippleArray([])
        setIsChecked(false)
        handleDisableUpdate.current.click()
    }

    const UpdateLeaveRequestDetail = async () => {

        try {
            const response = await postApi('/update-leave-requests', {
                employeeLeaveRequestArray: selectedData
            })
            if (response.status) {
                setSelectedData([]);
                GetLeaveRequestDetail();
                setMultippleArray([])
                handleDisableUpdate.current.click()
                toast.success(response.message);
            } else {
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>

            <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content" style={{ width: '108%' }}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Update Leaves Request</h5>
                            <button ref={handleDisableUpdate} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                        <tr>
                                                            <th className="font-weight-bold">Employee Detail  </th>
                                                            <th className="font-weight-bold"> Leaves Detail </th>
                                                            <th className="font-weight-bold"> Start Date </th>
                                                            <th className="font-weight-bold"> End Date </th>
                                                            <th className="font-weight-bold">Customer Comment </th>
                                                            <th className="font-weight-bold">Employer Comment </th>
                                                            <th className="font-weight-bold">Status </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectedData.length > 0 && selectedData.map((option, index) => {
                                                            // let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            return (
                                                                <>
                                                                    <tr key={index} >
                                                                        <td><span className="font-weight-normal1">{option?.employeeDetail?.first_name ? option?.employeeDetail?.first_name : "--"} {option?.employeeDetail?.last_name ? option?.employeeDetail?.last_name : ""}</span></td>
                                                                        <td>
                                                                            <span className="font-weight-normal1">
                                                                                <div className='d-flex'>
                                                                                    <div className='me-2'>
                                                                                        <p className='m-0 fw-bold text-wrap'>Type</p>
                                                                                        <p className='m-0 fw-bold text-wrap'>Count</p>
                                                                                        <p className='m-0 fw-bold text-wrap'>Period</p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className='m-0 text-wrap'>: {option?.leave_type ? option?.leave_type : "--"}</p>
                                                                                        <p className='m-0 text-wrap'>: {option?.used_leave_balance ? option?.used_leave_balance : "0"}/{option?.total_leave_balance ? option?.total_leave_balance : "0"} </p>
                                                                                        <p className='m-0 text-wrap'>: {option?.period ? option?.period : "--"}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type='date'
                                                                                value={option?.start_date}
                                                                                className="form-control"
                                                                                disabled={option.status !== 'approved'}
                                                                                onChange={event => {
                                                                                    const updatedData = [...selectedData];
                                                                                    updatedData[index].start_date = event.target.value;
                                                                                    setSelectedData(updatedData);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type='date'
                                                                                value={option?.end_date}
                                                                                className="form-control"
                                                                                disabled={option.status !== 'approved'}
                                                                                onChange={event => {
                                                                                    const updatedData = [...selectedData];
                                                                                    updatedData[index].end_date = event.target.value;
                                                                                    setSelectedData(updatedData);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td><span className="font-weight-normal1">{option?.customer_comment}</span></td>
                                                                        <td>
                                                                            <input
                                                                                type='text'
                                                                                value={option?.employer_comment}
                                                                                className="form-control"
                                                                                placeholder='Employer Comment...'
                                                                                onChange={event => {
                                                                                    const updatedData = [...selectedData];
                                                                                    updatedData[index].employer_comment = event.target.value;
                                                                                    setSelectedData(updatedData);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                className="form-control mb-0"
                                                                                value={option?.status}
                                                                                onChange={event => {
                                                                                    const updatedData = [...selectedData];
                                                                                    updatedData[index].status = event.target.value;
                                                                                    if (event.target.value !== 'approved') {
                                                                                        updatedData[index].start_date = option.actualStartDate;
                                                                                        updatedData[index].end_date = option.actualEndDate;
                                                                                    }
                                                                                    setSelectedData(updatedData);
                                                                                }}
                                                                            >
                                                                                <option value="">-- SELECT -- </option>
                                                                                <option value="rejected">Reject </option>
                                                                                <option value="approved">Approve </option>
                                                                            </select>
                                                                        </td>

                                                                    </tr>


                                                                    {option?.status == "approved" && option?.shiftArray?.length > 0 && option?.shiftArray?.map((item, subIndex) => {
                                                                        return (
                                                                            <tr>
                                                                                <td></td>

                                                                                <td>
                                                                                    <div className='grid-item'>
                                                                                        <label>Date: {item.shift_date}</label>
                                                                                        <label>JOB Type: {item?.job_type == 'parent' ? item.JT_type : item?.job_type == 'child' ? item.SJT_type : item?.job_type == 'subChild' && item.SLJT_type}</label>

                                                                                    </div>
                                                                                </td>

                                                                                <td></td>

                                                                                <td>
                                                                                    <div className='grid-item'>

                                                                                        <div style={{ position: 'relative' }}>
                                                                                            <select
                                                                                                data-style="btn-primary"
                                                                                                className="form-select mb-0"
                                                                                                name="job_id"
                                                                                                aria-label="size 2 select example"
                                                                                                value={item?.replaceCustomerId}
                                                                                                onChange={(e) => {
                                                                                                    const updatedArray = option?.shiftArray?.map((data => {
                                                                                                        if (data.job_id === item.job_id && data.shift_id == item.shift_id && data.shift_date == item.shift_date) {
                                                                                                            data.replaceCustomerId = parseInt(e.target.value)
                                                                                                        }
                                                                                                        return data
                                                                                                    }))

                                                                                                    const updatedData = [...selectedData];
                                                                                                    updatedData[index].shiftArray = updatedArray
                                                                                                    setSelectedData(updatedData);

                                                                                                }}
                                                                                            >
                                                                                                <option value="" >--  --</option>
                                                                                                <optgroup label="Available Replacement">
                                                                                                    {item?.replacementEmployees?.map((data) => (

                                                                                                        <option data-icon="fa-heart" key={data.customer_id} value={data.customer_id}>
                                                                                                            {data.first_name}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </optgroup>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>

                                                                                <td></td>

                                                                                <td>
                                                                                    <div className='grid-item'>
                                                                                        <div className='text-center'>{item.shift_name} <br /> {moment(item?.shift_start_time, "HH:mm:ss").format("hh:mm A")} - {moment(item?.shift_end_time, "HH:mm:ss").format("hh:mm A")}</div>
                                                                                    </div>
                                                                                </td>

                                                                                <td></td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark"
                                onClick={() => {
                                    HandleCloseButton()
                                }}>Close</button>
                            <button type="button" className="btn btn-primary"
                                data-bs-dismiss="modal"
                                onClick={() => { UpdateLeaveRequestDetail() }
                                }>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default UpdateLeaveModal