import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { postApi, getApi, deleteApi } from '../../service/MerchantService';
import Loader from '../../../components/Loader/loader';

const Profile = (props) => {
  const [profileData, setprofileData] = useState({});
  const [document, setdocument] = useState({});
  const [DeleteId, setDeleteId] = useState()
  const [Id, setId] = useState()
  const [account_name, setAccountname] = useState()
  const [bsb, setBsb] = useState()
  const [bank_name, setBankname] = useState()
  const [account_number, setAccountnumber] = useState()
  const [branch_name, setBranchname] = useState()
  const [status, setStatus] = useState()
  const [usertype, setUsertype] = useState()
  const navigate = useNavigate()
  const [loader, setloader] = useState(true)
  const ref = useRef()

  const prefix = process.env.REACT_APP_MARCHNT_PRIFIX;
  const merchant = JSON.parse(localStorage.getItem("merchant"));
  const merchantUserId = merchant.user_id;


  const getProfile = async () => {
    // const response = await GetProfile(token, merchantUserId);
    const response = await getApi("/get-profile");
    if (response.status) {
      response.data.logo = response.data.logo.substring(response.data.logo.lastIndexOf("https"))
      setTimeout(() => {
        setprofileData(response.data)
        setUsertype(response.data.user_type)
        setdocument(response.bankaccounts)
        setloader(false)
      }, 2000);

      //   if(response.bankaccounts.length!=0){
      //  setdocument(response.bankaccounts[0])
      //   }else {
      //     console.log("now data available")
      //   }

    } else if (response.message == 'Session expired') {
      localStorage.removeItem("merchant");
      navigate('/merchant/login')
    }
    else {
      console.log(response);
    }
  }
  useEffect(() => {
    getProfile()
  }, [])
  //   console.log("87879097979709087",document)
  // console.log(DeleteId,"Deleetetetetetet")


  const updateAccount = async (token, data) => {

  }
  useEffect(() => {
    EditForm.setValues({
      bank_account_id: Id,
      account_name: account_name,
      bank_name: bank_name,
      branch_name: branch_name,
      bsb: bsb,
      account_number: account_number,
      status: status,
    })
  }, [])

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const EditForm = useFormik({

    initialValues: {
      bank_account_id: Id,
      account_name: account_name,
      bank_name: bank_name,
      branch_name: branch_name,
      bsb: bsb,
      account_number: account_number,
      status: status,
      wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      account_name: yup.string().required('Please enter company name'),
      bank_name: yup.string().required('Please enter company name'),
      branch_name: yup.string().required('Please enter address'),
      status: yup.string().required('Please enter country'),
      bsb: yup.string().required('Please enter Bank State Branch').min(6, "Bank State Branch must contain 6 Character").max(10, "Bank State Branch max contain 10 Character")/* .matches(phoneRegExp, 'Please enter only number values') */,
      account_number: yup.string().required('Please enter valid GST number'),
    }),
    onSubmit: async values => {
      // let data = JSON.stringify(values);
      // updateAccount(merchant.merchant_token, data);    
      const response = await postApi("/update-bank-account", {
        bank_account_id: Id,
        account_name: values.account_name,
        bank_name: values.bank_name,
        branch_name: values.branch_name,
        bsb: values.bsb,
        account_number: values.account_number,
        status: values.status,
        wrong_message: ""
      });
      console.log(response)
      if (response.status) {
        toast.success(response.message);
        getProfile(merchant.merchant_token)
        window.$('#assign-rm').modal('hide')
      } else if (response.message == 'Session expired') {
        localStorage.removeItem("merchant");
        navigate('/merchant/login')
      } else {
        toast.error(response.message);
        window.$('#assign-rm').modal('hide')
      }

    }
  });

  const deleteAccount = async () => {
    // let raw = JSON.stringify({ id });    
    // const res = await DeleteAccount(merchant.merchant_token, DeleteId);
    const res = await deleteApi(`/delete-bank-account/${DeleteId}`);

    getProfile(merchant.merchant_token)
    if (res.status) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
      console.log(res);
    }
  }


  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {loader == true ?
        <div style={{ marginLeft: '400px', marginTop: '200px' }}  >
          <Loader /></div>
        :
        <div>
          <div className="main-container container-fluid px-0">
            <div className="page-header mb-3">
              <div className="page-leftheader">
                <h4 className="page-title mb-0 text-primary">{usertype != 'COUNTER' ? <span>{usertype != 'STORE' ? <span>Merchant Profile </span> : <span> Store Profile </span>}</span> : <span>Counter Profile</span>}</h4>
              </div>
              <div className="page-rightheader">
                <div className="btn-list">
                  <Link to={props.prefix + '/editprofile/' + profileData.user_id} className="btn btn-outline-primary" ><i className="fa-solid fa-user-pen fa-fw me-2"></i> Edit Profile</Link>
                  {usertype != 'COUNTER' ? <Link to={props.prefix + '/changepassword/' + profileData.user_id} className="btn btn-primary btn-pill" ><i className="fa-regular fa-pen-to-square fa-fw me-2"></i> Change Password</Link> : ""}
                </div>
              </div>
            </div>
            <div className="main-proifle">
              <div className="row">
                <div className="col-lg-12 col-xl-12 p-0">
                  <div className="box-widget widget-user">
                    <div className="widget-user-image1 d-xl-flex d-block flexwrap">
                      <div className="col-md-12">
                        <p className="mb-0"><small className="text-muted">Authorised Person Name: {profileData?.file_id}</small></p>
                        <h4 className="pro-user-username mb-3 font-weight-bold">{profileData?.full_name}</h4>

                        {/* <h4 className="pro-user-username mb-3 font-weight-bold">{profileData.user_name}</h4> */}
                        <div className="row">
                          <div className="media mb-5 col-md-4">
                            <div className="media-icon bg-info-transparent text-info me-3"> <i className="fa-regular fa-building fa-fw"></i> </div>
                            <div className="media-body">
                              <small className="text-muted">Merchant Name:</small>
                              <div className="font-weight-normal1">{profileData.company_name}</div>
                            </div>
                          </div>
                          {/* <div className="media mb-5 col-md-4">
                          <div className="media-icon bg-info-transparent text-info me-3"> <i className="fa-regular fa-building fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Full Name:</small>
                            <div className="font-weight-normal1">{profileData.full_name}</div>
                          </div>
                        </div> */}
                          <div className="media  mb-5 col-md-4">
                            <div className="media-icon bg-success-transparent text-success me-3"> <i className="fa-solid fa-mobile-screen-button fa-fw"></i> </div>
                            <div className="media-body">
                              <small className="text-muted">Mobile no:</small>
                              <div className="font-weight-normal1">{profileData.mobile_number}</div>
                            </div>
                          </div>
                          <div className="media  mb-5 col-md-4">
                            <div className="media-icon bg-danger-transparent text-danger me-3"> <i className="fas fa-mail-bulk"></i> </div>
                            <div className="media-body">
                              <small className="text-muted">Email:</small>
                              <div className="font-weight-normal1" style={{ textTransform: 'none' }}>{profileData.email}</div>
                            </div>
                          </div>
                          <div className="media  mb-5 col-md-4">
                            <div className="media-icon bg-primary-transparent text-primary me-3"> <i className="fa-solid fa-earth-americas fa-fw"></i> </div>
                            <div className="media-body">
                              <small className="text-muted">Country:</small>
                              <div className="font-weight-normal1">{profileData.country}</div>
                            </div>
                          </div>
                          <div className="media  mb-5 col-md-4">
                            <div className="media-icon bg-danger-transparent text-danger me-3"> <i className="fa-solid fa-globe fa-fw"></i> </div>
                            <div className="media-body">
                              <small className="text-muted">Website:</small>
                              <div className="font-weight-normal1" style={{ textTransform: 'none' }}>{profileData.website}</div>
                            </div>
                          </div>
                          <div className="media mb-5 col-md-4">
                            <div className="media-icon bg-warning-transparent text-warning me-3"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                            <div className="media-body">
                              <small className="text-muted">ID (Company Registration/Tax ID)</small>
                              <div className="font-weight-normal1">{profileData.gst_number}</div>
                            </div>
                          </div>
                          <div className="media mb-5 col-md-4">
                            <div className="media-icon bg-secondary-transparent text-secondary me-3"> <i className="fa-solid fa-location-dot fa-fw"></i> </div>
                            <div className="media-body">
                              <small className="text-muted">Address:</small>
                              <div className="font-weight-normal1">{profileData.address}</div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-xl-12 p-0">
                  <div className="box-widget widget-user">
                    <div className="widget-user-image1 d-xl-flex d-block flexwrap">
                      <div className="col-md-12">
                        <h4 className="pro-user-username mb-3 font-weight-bold">{usertype != 'COUNTER' ? <span>{usertype != 'STORE' ? <span>Merchant Logo</span> : <span>Store Logo</span>}</span> : <span>Counter Logo</span>}</h4>
                        <div className="row">
                          <div className="media col-md-4">
                            <div className="media-body">
                              {profileData.logo ?
                                <img src={profileData.logo} alt="no document here" style={{ height: "230px", width: "230px", objectFit: "contain" }} />
                                :
                                <img src={process.env.PUBLIC_URL + '/assets/img/upload-logo-lender.png'} style={{ height: "230px", width: "230px", objectFit: "contain" }} alt='' />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {usertype != 'COUNTER' ?

            <div className="main-container container-fluid px-0">
              <div className="page-header mb-3">
                <div className="page-leftheader">
                  <h4 className="page-title mb-0 text-primary">Bank Accounts</h4>

                </div>
                <div className="page-rightheader">
                  <div className="btn-list">
                    <Link to={props.prefix + '/add_document'} className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Account</Link>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                          <thead className="border-bottom-0 pt-3 pb-3">
                            <tr>
                              {/* <th></th> */}
                              <th className="font-weight-bold">Account Holder Name </th>
                              <th className="font-weight-bold">Bank Name </th>
                              <th className="font-weight-bold"> Bank State Branch </th>
                              <th className="font-weight-bold"> Account Number </th>
                              <th className="font-weight-bold"> Branch Name </th>
                              <th className="font-weight-bold"> Status </th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {document && document.length > 0 && document.map((option, index) => {
                              const { id, account_name, bank_name, bsb, account_number, branch_name, status } = option;
                              // let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                              var Capitalize = (option) => {
                                return option.charAt(0).toUpperCase() + option.slice(1);
                              }
                              var num2 = parseFloat(option.earned_amount).toFixed(2)
                              return (

                                <tr key={index} >
                                  <td> <span className="font-weight-normal1">{account_name}</span></td>
                                  <td><span className="font-weight-normal1">{bank_name} </span></td>
                                  <td><span className="font-weight-normal1">{bsb}</span></td>
                                  <td><span className="font-weight-normal1">{account_number}</span></td>
                                  <td><span className="font-weight-normal1">{branch_name}</span></td>
                                  <td><span className="font-weight-normal1">{status}</span></td>
                                  <td>
                                    <Link className="btn btn-outline-primary"
                                      onClick={() => {
                                        setId(id); setAccountname(account_name); setBankname(bank_name);
                                        setBsb(bsb); setAccountnumber(account_number); setBranchname(branch_name); setStatus(status)
                                      }}
                                      data-bs-target="#assign-rm" data-bs-toggle="modal"

                                    >Update</Link>
                                    {/*  <button className='btn btn-outline-primary'  onClick={() => setDeleteId(id)} 
                                                          data-bs-toggle="modal" data-bs-target="#modal-danger updateAccount">Update</button> */}&nbsp;&nbsp;&nbsp;
                                    <button className='btn btn-outline-danger w-37' onClick={() => setDeleteId(id)}
                                      data-bs-toggle="modal" data-bs-target="#modal-danger">Delete</button></td>
                                </tr>
                              )
                            }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Start Edit Modal */}
              <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog" style={{ display: 'block !important' }}>
                <div className="modal-dialog" role="document" style={{ overflowY: 'initial !important' }}>
                  <div className="modal-content modal-content-demo">
                    <div className="modal-header"> <h6 className="modal-title">Edit Account</h6>
                      <button /* ref={ref} */ aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button" /* onClick={emptycheck} */><span aria-hidden="true">×</span></button>
                    </div>
                    <form className="" id='registerForm' onSubmit={EditForm.handleSubmit} >
                      <div className="modal-body application-modal" >
                        <div className="form-group">
                          <label className="form-label">Account Holder Name</label>
                          <div className="row g-xs">
                            <div className="input-group">
                              <input type="text" className="form-control" /* value={account_name} onChange={(e) => setAccountname(e.target.value)} */
                                // onKeyUp={() => setValidate(true)}
                                {...EditForm.getFieldProps("account_name")}
                              />
                            </div>
                            {EditForm.touched.account_name && EditForm.errors.account_name ?
                              <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.account_name}</div> : ''}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Bank Name</label>
                          <div className="row g-xs">
                            <div className="input-group">
                              <input type="text" className="form-control" /* value={bank_name} onChange={(e) => setBankname(e.target.value)} */
                                // onKeyUp={() => setValidate(true)}
                                {...EditForm.getFieldProps("bank_name")}
                              />
                            </div>
                            {EditForm.touched.bank_name && EditForm.errors.bank_name ?
                              <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.bank_name}</div> : ''}
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="form-label">Bank State Branch</label>
                          <div className="row g-xs">
                            <div className="input-group">
                              <input type="text" className="form-control" /* value={bsb} onChange={(e) => setBsb(e.target.value)} */
                                // onKeyUp={() => setValidate(true)}
                                {...EditForm.getFieldProps("bsb")}
                              />
                            </div>
                            {EditForm.touched.bsb && EditForm.errors.bsb ?
                              <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.bsb}</div> : ''}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Account Number</label>
                          <div className="row g-xs">
                            <div className="input-group">
                              <input type="text" className="form-control" /* value={account_number} onChange={(e) => setAccountnumber(e.target.value)} */
                                // onKeyUp={() => setValidate(true)}
                                {...EditForm.getFieldProps("account_number")}
                              />
                            </div>
                            {EditForm.touched.account_number && EditForm.errors.account_number ?
                              <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.account_number}</div> : ''}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Branch Name</label>
                          <div className="row g-xs">
                            <div className="input-group">
                              <input type="text" className="form-control" /* value={branch_name} onChange={(e) => setBranchname(e.target.value)} */
                                // onKeyUp={() => setValidate(true)}
                                {...EditForm.getFieldProps("branch_name")}
                              />
                            </div>
                            {EditForm.touched.branch_name && EditForm.errors.branch_name ?
                              <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.branch_name}</div> : ''}
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="form-label">Status</label>
                          <div className="row g-xs">
                            <div className="wd-200 mg-b-30">
                              <div className="input-group">
                                <select className="form-control mb-0" name="paycycle" /* value={status} */ /* onKeyUp={() => setValidate(true)} */
                                  onChange={(e) => setStatus(e.target.value)} /* onSelect={() => setValidate(true)} */
                                  {...EditForm.getFieldProps("status")} >
                                  {/* <option>Select Pay Cycle</option> */}
                                  <option value='primary'>primary</option>
                                  <option value='Other'>Other</option>

                                </select>
                              </div>
                              {EditForm.touched.status && EditForm.errors.status ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.status}</div> : ''}
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-2 ">
                          <div className="row" style={{ display: 'flex' }}>
                            <div className="">
                              <button type="submit" className='btn btn-primary '>Update</button>
                              {/* <button  className='btn btn-primary m-2' style={{float: 'right'}} data-bs-dismiss="modal" onClick={emptycheck}>Close</button>  */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* End Edit Modal */}


              {/* Start Delete Modal */}
              <div className="modal modal-blur fade" id="modal-danger" tabIndex={-1} role="dialog" aria-hidden="true" data-bs-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-status " style={{ backgroundColor: '#1a48aa' }} />
                    <div className="modal-body text-center py-4">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 icon-lg" style={{ color: '#1a48aa' }} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
                      <h3>Are you sure?</h3>
                      <div className="text-muted">Do you really want to remove this Account?</div>
                    </div>
                    <div className="modal-footer">
                      <div className="w-100">
                        <div className="row">
                          <div className="col"><a href="#" className="btn w-100" data-bs-dismiss="modal" style={{ backgroundColor: '#1a48aa', color: 'white' }}>
                            Cancel
                          </a></div>
                          <div className="col"><button className="btn btn-danger w-100" data-bs-dismiss="modal"
                            onClick={() => {
                              deleteAccount()
                            }}
                          >
                            Delete
                          </button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End DeleteModal  */}
            </div>
            : ""}
        </div>
      }

    </>
  )
}
export default Profile;
