import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import PaginationComponent from '../../PaginationComponent';
import { getApi, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import { Breadcrumbs } from '../../components';
const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const InvestorSetting = (props) => {
    const [loader, setloader] = useState(true)
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [searchpage, setSearchpage] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("user_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [pagesCheck, setpagesCheck] = useState(1);
    const [input, setinput] = useState([])
    const [input1, setinput1] = useState([])
    const [inputType, setinputType] = useState([])
    const [input1Type, setinput1Type] = useState([])
    const [searchcheck, setsearchcheck] = useState(false)
    const [timevalue, settimevalue] = useState("")

    const admin = JSON.parse(localStorage.getItem("admin"));

    const getAllLenderCommission = async (token, pageNumber, filterVlu) => {
        try {
            // const response = await GetLenderCommission(token, pageNumber, filterVlu, sortedBy, orderBy);
            const response = await getApi("/investor-setting", { page_number: pageNumber, search: filterVlu, sortby: sortedBy, orderby: orderBy });
            console.log("fdgdgdgddfgdg", response)
            if (response.status == true) {
                settimevalue("")
                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setinput(response.data.investerROI[0].roi)
                    setinput1(response.data.investerROI[1].roi)
                    setinputType(response.data.investerROI[0].type)
                    setinput1Type(response.data.investerROI[1].type)
                    setTotalPagesCount(response.data.count);
                    settotalItems(response.data)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else {
                console.log("get city data response", response);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getAllLenderCommission(admin.admin_token, pagesCount, search);
    }, [search, pagesCount]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        getAllLenderCommission(admin.admin_token, pagesCount, search);
    }
    const updatefixedROI = async (val, id, date) => {
        try {
            console.log(val, date)
            val = Number(Number(val).toFixed(2))
            await postApi(`/update-fixed-roi-and-pay-cycle/${id}`, { fix_deposit_roi: val, date_of_billing: date, time_zone: date_Format })

            // await UpdateLenderCommission(admin.admin_token, id, val)
            getAllLenderCommission(admin.admin_token, pagesCount, search)
        } catch (error) {
            console.log(error);
        }
    }

    const updateinvestedROI = async (data, ROI, type) => {
        try {
            console.log(data, "data")
            if (ROI === "0" || ROI === "") {
                console.log(data === "0" || data == "")
                toast.error("ROI can not be zero or empty")
                getAllLenderCommission(admin.admin_token, pagesCount, search);

            } else {
                const response = await postApi("/update-invester-roi", { name: data, roi: ROI, type: type, time_zone: date_Format })
                console.log(response)
                if (response.status == true) {
                    toast.success(response.message)
                    getAllLenderCommission(admin.admin_token, pagesCount, search);
                } else {
                    toast.error(response.message)
                }
            }

        } catch (error) {
            console.log(error)
        }
    }
    console.log(input1Type, "input1Type")
    return (

        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            {/* <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Lender ROI({totalPagesCount})</h4>
                            </div> */}
                            <div className="page-leftheader">
                                {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                <Breadcrumbs order={{ first: { name: "Setting" }, second: { name: `Investor Setting` } }} />

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Institutional ROI/Type</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={input} onChange={(e) => setinput(e.target.value)} onBlur={(e) => updateinvestedROI("institutional_roi", e.target.value)} />
                                            <select className='form-control'
                                                defaultValue={inputType}
                                                onChange={(e) => updateinvestedROI("institutional_roi", input, e.target.value)}
                                            >
                                                {/* <option value="">Select Type</option> */}
                                                <option value="FIXED">Fixed Amount</option>
                                                <option value="PERCENT" >Percent</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Retail ROI/Type</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={input1} onChange={(e) => setinput1(e.target.value)} onBlur={(e) => updateinvestedROI("retail_roi", e.target.value)} />
                                            <select className='form-control'
                                                defaultValue={input1Type}
                                                onChange={(e) => updateinvestedROI("retail_roi", input1, e.target.value)}
                                            >
                                                {/* <option value="">Select Type</option> */}
                                                <option value="FIXED">Fixed Amount</option>
                                                <option value="PERCENT" >Percent</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setsearchcheck(true) }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Investor Info</th>
                                                        <th className="font-weight-bold">ROI(Per Annum)</th>
                                                        {/* <th className="font-weight-bold " > <span style={{ marginLeft: "50px" }}>Action</span></th> */}
                                                        <th className="font-weight-bold">Date Of Billing</th>
                                                    </tr>
                                                </thead>

                                                {loader == true ? <tbody>
                                                    <td />
                                                    <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {

                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            let Capitalize = (option) => {
                                                                return option.charAt(0).toUpperCase() + option.slice(1);
                                                            }
                                                            return (
                                                                <tr key={serial_num} >
                                                                    <td>
                                                                        <Link className="text-primary" to={prefix + `/lenderdetail/${option.user_id}`}>
                                                                            {/* <Link to="" onClick={() => getDetail(option.user_id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal" > */}
                                                                            <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>{option.full_name ? option?.full_name : ""}
                                                                                <br />{option.company_name}
                                                                                <br />{option.mobile_number}
                                                                                <br />{option.email}</span>
                                                                        </Link></td>
                                                                    {(searchcheck && timevalue == "") ? <>
                                                                        <td><input type="number" className='form-control'
                                                                            value={option?.fix_deposit_roi}
                                                                            onClick={() => { setsearchcheck(false) }}
                                                                            onBlur={(e) => updatefixedROI(e.target.value, option?.user_id, option?.date_of_billing)} /></td>
                                                                    </> : <>
                                                                        <td><input type="number" className='form-control'
                                                                            defaultValue={option?.fix_deposit_roi}
                                                                            onChange={(e) => { settimevalue(e.target.value) }}
                                                                            onBlur={(e) => updatefixedROI(e.target.value, option.user_id)} /></td>
                                                                    </>}
                                                                    <td>
                                                                        <input type="date" className='form-control' value={option?.date_of_billing} /* onChange={() => { }} */ onBlur={(e) => updatefixedROI(option.fix_deposit_roi, option?.user_id, e.target.value)} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                    {/* <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header"> <h6 className="modal-title">Applicants Report</h6>
                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                                <div className="modal-body application-modal">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="">
                                                <div className="main-content-body main-content-body-contacts">
                                                    <h6>Lender Information</h6>
                                                    <div className="main-contact-info-header bg-contacthead pb15">
                                                        <div className="media">
                                                            <div className="media-body text-white ml-0">
                                                                <h4 className="text-white">Company Name: {detail ? detail.company_name : ''}</h4>
                                                                <p className="mb-1">Person Name: {detail ? detail.full_name : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="main-contact-info-body">
                                                        <div className="media-list p-0">
                                                            <div className="media py-4 mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                                        <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mobile_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                        <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail ? detail.email : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>GST no:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.gst_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                        <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.address : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_rate_of_intrest : ''} %</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Rate of Interest Per annum:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_rate_of_intrest : ''} %</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Loan Range:</label> <span className="font-weight-normal1 fs-14">₹ {detail ? detail.mini_loan_range : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-landmark fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Loan Range:</label> <span className="font-weight-normal1 fs-14">₹ {detail ? detail.max_loan_range : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Minimum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.mini_tenure : ''} Months</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-calendar fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>Maximum Tenure:</label> <span className="font-weight-normal1 fs-14">{detail ? detail.max_tenure : ''} Months</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            }
            <div className="modal fade effect-scale show" id="assign-rm" aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header"> <h6 className="modal-title">Assign Employer</h6>
                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                        <div className="modal-body application-modal">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword"
                                    value={searchpage} onChange={(e) => setSearchpage(e.target.value)} /* onKeyDown={getEmployeesData} */ />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div><hr />
                            {pagesCheck > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={setTotalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCheck(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default InvestorSetting