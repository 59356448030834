import Header from '../website/header';
import Footer from '../website/footer';
const PrivacyPolicy = () => {


    return (
        <>
            <Header />
            <section className="her-bannerimg" style={{ paddingBottom: "100px" }}>
                <div className="container">
                    <div className="contact-heading"><h1 className="mb-0">PRIVACY POLICY</h1></div>
                </div>
            </section>
            <section className="p-top-100 p-bottom-55">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">1.	We respect your privacy</h4>
                            <p className="lead mb50">
                                (a)	Thirdroc PTY Ltd respects your right to privacy and is committed to safeguarding the privacy of our customers and software application users. We adhere to the National Privacy Principles established by the Privacy Act 1988 (Cth). This policy sets out how we collect and treat your personal information.
                            </p>
                            <p className="lead mb50">
                                (b)	"Personal information" is information we hold which is identifiable as being about you.
                            </p>
                        </div>
                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">2.	Collection of personal information</h4>
                            <p className="lead mb50">
                                (a) Thirdroc PTY Ltd will, from time to time, receive and store personal information you enter onto our software application Thirdroc, provide to us directly or give to us in other forms.
                            </p>
                            <p className="lead mb50">
                                (b)	You may provide basic information such as your name, phone number, address and email address to enable us to send information, provide updates and process your product or service order. We may collect additional information at other times, including but not limited to, when you provide feedback, when you provide information about your personal or business affairs, change your content or email preference, respond to surveys and/or promotions, provide financial or credit card information, or communicate with our customer support.
                            </p>
                            <p className="lead mb50">
                                (c)	Additionally, we may also collect any other information you provide while interacting with us.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">3.	How we collect your personal information</h4>
                            <p className="lead mb50">
                                (a)	Thirdroc PTY Ltd collects personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our software application and when we provide our services to you. We may receive personal information from third parties. If we do, we will protect it as set out in this Privacy Policy.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">4.	Use of your personal information</h4>
                            <p className="lead mb50">
                                (a)	Thirdroc PTY Ltd may use personal information collected from you to provide you with information, updates and our services. We may also make you aware of new and additional products, services and opportunities available to you. We may use your personal information to improve our products and services and better understand your needs
                            </p>
                            <p className="lead mb50">
                                (b)	The Application may make third party social media features available to its users. We cannot ensure the security of any information you choose to make public in a social media feature. Also, we cannot ensure that parties who have access to such publicly available information will respect your privacy.
                                Thirdroc PTY Ltd may contact you by a variety of measures including, but not limited to telephone, email, sms or mail.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">5.	Disclosure of your personal information</h4>
                            <p className="lead mb50">
                                (a)	We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this Policy. Personal information is only supplied to a third party when it is required for the delivery of our services.
                            </p>
                            <p className="lead mb50">
                                (b)	We may from time to time need to disclose personal information to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, in the course of a legal proceeding or in response to a law enforcement agency request.
                            </p>
                            <p className="lead mb50">
                                (c)	We may also use your personal information to protect the copyright, trademarks, legal rights, property or safety of Thirdroc PTY Ltd, its application, website and customers or third parties.
                            </p>
                            <p className="lead mb50">
                                (d)	Information that we collect may from time to time be stored, processed in or transferred between parties located in countries outside of Australia. These may include, but are not limited to Fiji, India, Africa..
                            </p>
                            <p className="lead mb50">
                                (e)	If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our user databases, together with any personal information and non-personal information contained in those databases. This information may be disclosed to a potential purchaser under an agreement to maintain confidentiality. We would seek to only disclose information in good faith and where required by any of the above circumstances.
                            </p>
                            <p className="lead mb50">
                                (f)	By providing us with personal information, you consent to the terms of this Privacy Policy and the types of disclosure covered by this Policy. Where we disclose your personal information to third parties, we will request that the third party follow this Policy regarding handling your personal information.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">6.	Security of your personal information</h4>
                            <p className="lead mb50">
                                (a)	Thirdroc PTY Ltd is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.
                            </p>
                            <p className="lead mb50">
                                (b)	The transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any information that you transmit to us, or receive from us. Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you that personal information that we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">7.	Access to your personal information</h4>
                            <p className="lead mb50">
                                (a)	You may request details of personal information that we hold about you in accordance with the provisions of the Privacy Act 1988(Cth). A small administrative fee may be payable for the provision of information. If you would like a copy of the information which we hold about you or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at
                                <a href="">support@thirdroc.com.</a>
                            </p>
                            <p className="lead mb50">
                                (b)	We reserve the right to refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">8.	Complaints about privacy</h4>
                            <p className="lead mb50">
                                (a)	If you have any complaints about our privacy practices, please feel free to send in details of your complaints to Level 5 111 Cecil Street, South Melbourne, Melbourne, Victoria, 3205. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">9.	Op out right</h4>
                            <p className="lead mb50">
                                (a)	You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network. You can also request to opt-out via email, at support@thirdroc.com.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">10. Changes to Privacy Policy</h4>
                            <p className="lead mb50">
                                (a)	Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy.
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <h4 className="mb-1">11. Software Application</h4>
                            <p className="lead mb50 mb-0">
                                <b>(a)	When you use our Application</b>
                            </p>
                            <p>When you come to our application we may collect certain information such as mobile unique device ID, the IP address of your mobile device, mobile operating system, the type of mobile internet browsers you use, and information about the way you use the Application. This information is used in an aggregated manner to analyse how people use our site, such that we can improve our service.</p>

                            <p className="lead mb50 mb-0">
                                <b>(b) Cookies</b>
                            </p>
                            <p>We may from time to time use cookies on our software application. Cookies are very small files which a website uses to identify you when you come back to the application and to store details about your use of the application. Cookies are not malicious programs that access or damage your computer, tablet or smartphone. Most devices automatically accept cookies but you can choose to reject cookies by changing your devise settings. However, this may prevent you from taking full advantage of our application.</p>

                            <p className="lead mb50 mb-0">
                                <b>(c) Automatic collection</b>
                            </p>
                            <p>The software Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.</p>

                            <p className="lead mb50 mb-0">
                                <b>(d) Third parties</b>
                            </p>
                            <p>Our software application may from time to time have links to other applications or websites not owned or controlled by us. These links are meant for your convenience only. Links to third party applications and websites do not constitute sponsorship or endorsement or approval of these third parties. Please be aware that Thirdroc PTY Ltd is not responsible for the privacy practises of other such applications or websites. We encourage our users to be aware, when they leave our application or website, to read the privacy statements of each and every application or website that collects personal identifiable information.</p>

                            <p className="lead mb50 mb-0">
                                <b>(e) Geo-location</b>
                            </p>
                            <p>When you visit the mobile application, we may use GPS technology (or other similar technology) to determine your current location in order to determine the city you are located within and display a location map with relevant advertisements. We will not share your current location with other users or partners.</p>
                            <p className="lead mb50 mb-0">
                                <b>(f) READ_CALL_LOG:</b>
                            </p>
                            <p>We request access to your call logs in order to verify your identity and assess your eligibility for earned wage access (EWA). This information also allows us to personalize options for you, making sure that the financial solutions we provide are the best fit for your specific situation. Additionally, by verifying your call history, we strengthen the security of our service, protecting you from unauthorized access.</p>
                            <p className="lead mb50 mb-0">
                                <b>(g) READ_SMS:</b>
                            </p>
                            <p>We request permission to access your SMS messages so we can retrieve key financial information, such as bank transactions, and other relevant notifications. This data helps us form a comprehensive understanding of your financial behavior and current standing. By analyzing these details, we can tailor the earned wage access services to suit your needs more accurately, ensuring that you receive the best possible financial assistance. Furthermore, this allows us to provide timely, customized recommendations and ensure that you have a clear and updated view of your financial options.</p>
                            <p className="lead mb50 mb-0">
                                <b>(h) READ_CONTACTS:</b>
                            </p>
                            <p>We access your contacts to facilitate easy communication regarding your earned wage access status and repayment reminders. This ensures that you stay informed throughout the process.</p>
                            <p className="lead mb50 mb-0">
                                <b>(i) READ_PHONE_STATE:</b>
                            </p>
                            <p>This permission allows us to identify your device and prevent fraudulent activities. It helps us ensure that your earned wage access application is secure and that we can provide support when needed.</p>

                        </div>



                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default PrivacyPolicy;