import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import Moment from 'react-moment';
import { getApi, postApi } from '../../service/lenderService';
import { Breadcrumbs } from '../../../components';
import Search from '../../../components/Search/Search';
import Loader from '../../../components/Loader/loader';
import PaginationComponent from '../../../PaginationComponent';
import UsePermissions from '../../layout/usePermission';
const prefix = process.env.REACT_APP_LENDER_PRIFIX;

const PendingRepayment = (props) => {
    const permissionsStatus = UsePermissions();
    useEffect(() => {
    if(!permissionsStatus.repayment){
        navigate('/lender/profile')
    }
    },[permissionsStatus])
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [userId, setUserId] = useState(JSON.parse(lenderData)?.user_id);
    const [pagesCount, setpagesCount] = useState(1);
    const [ValidPagination, setValidPagination] = useState(false)
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [endDate, setendDate] = useState('');
    const [startDate, setstartDate] = useState('');
    const [emiID, setEmiID] = useState('');
    const [disable, setDisable] = useState(false);
    const [TotalCount, setTotalCount] = useState('');
    const [totalinterest, setTotalinterest] = useState('')
    const [check, setCheck] = useState(false)
    const [loader, setloader] = useState(true)
    const [imageUpload, setimageUpload] = useState(null)
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const tableRef = useRef(null);
    const [statusdrop, setStatusdrop] = useState('')
    const navigate = useNavigate();
    const [queryFilter, setQueryFilter] = useState({
        filter: '',
        status: props.caseStatus ? props.caseStatus : '',
        search: '',
        lender_id: props.lender_id ? props.lender_id : '',
        lender_id_op: "eq",
        dsa_id: props.dsa_id ? props.dsa_id : '',
        dsa_id_op: "eq",
        file_id: '',
        file_id_op: "eq",
        loan_type: '',
        loan_type_op: "eq",
        full_name: "",
        full_name_op: "eq",
        company_name: "",
        company_name_op: "eq",
        email: "",
        email_op: "eq",
        mobile_number: "",
        mobile_number_op: "",
        age: '',
        age_op: "eq",
        created_at: '',
        created_at_op: "eq",
        received_time: '',
        received_time_op: "eq",
        short_close_time: '',
        short_close_time_op: "eq",
        comment_time: '',
        comment_time_op: "eq",
        remark_time: '',
        remark_time_op: "eq",
        assigned_time: '',
        assigned_time_op: "eq",
        logged_time: '',
        logged_time_op: "eq",
        pending_time: '',
        pending_time_op: "eq",
        approved_time: '',
        approved_time_op: "eq",
        reject_time: '',
        reject_time_op: "eq",
        disbursed_time: '',
        disbursed_tim_op: '=',
        city: '',
        city_op: "eq",
        pincode: '',
        pincode_op: "eq",
        state: '',
        state_op: "eq",
        organization_type: '',
        organization_type_op: "eq",
        father_name: '',
        father_name_op: "eq",
        qualification: "",
        qualification_op: "eq",
        marital_status: "",
        marital_status_op: "eq",
        employer_name: "",
        employer_name_op: "eq",
        orderBy: "",
        orderBy_op: "",

    });

    const lender = JSON.parse(localStorage.getItem("lender"));

    const getEmisData = async (token, search, pagesCount) => {
        // const response = await GetEmi(token, search, pagesCount, sortedBy, orderBy, statusdrop);
        const response = await getApi(`/emi-list`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy, filter: statusdrop });
        // console.log("🚀 ~ file: Employee.js:111 ~ getEmployeesData ~ response:", response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.totleDue)
                setTotalCount(response.totalAmount)
                setTotalinterest(response.totalInterest)
                setloader(false)
                //  console.log(response.totalAmount);
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        } else {
            console.log("get employees data response", response);
        }
    }
    // const deleteEmployees = async (id) => {
    //     let raw = JSON.stringify({ id });
    //     const res = await DeleteEmployees(lender.lender_token, raw);
    //     if (res.status) {
    //         toast.success(res.message);
    //     } else {
    //         toast.error(res.message);
    //         console.log(res);
    //     }
    // }

    useEffect(() => {
        //  setpagesCount(1);
        getEmisData(token, search, pagesCount);
    }, [search, pagesCount, queryFilter, check, statusdrop, sortedBy, orderBy, ValidPagination]);

    const handleChangeQueryBuilders = (value) => {
        console.log('value', value);
        setStatusdrop(value)
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setQueryFilter(prevState => ({
            ...prevState,
            [name]: value.trim()
        }));
    };

    const handleChangeQueryBuilder = e => {
        const { name, value } = e.target;
        console.log('name', name);
        console.log('value', value);

        setQueryFilter(prevState => ({
            ...prevState,
            [name]: value.trim()
        }));
    };

    const handleCallback = (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        setstartDate(picker.startDate.format('MM/DD/YYYY'))
        setendDate(picker.endDate.format('MM/DD/YYYY'))
    }
    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate('')
        setendDate('')
    }

    const payEmisForm = useFormik({
        initialValues: {
            lender_id: "",
            emi_id: "",
            payment_mode: "",
            notes: "",
            image: "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            notes: yup.string().required('Please add a comment before pay'),
            payment_mode: yup.string().required('Please add a payment mode '),
            // imageUpload:  yup.file().required('Please select files '),
        }),
        onSubmit: async values => {
            const formData = new FormData();
            formData.append("lender_id", userId);
            formData.append("emi_id", emiID);
            formData.append("payment_mode", payEmisForm.getFieldProps('payment_mode').value);
            formData.append("notes", payEmisForm.getFieldProps('notes').value);
            formData.append("image", imageUpload);
            // payEmis(token, formData)
            postApi("/pay-emi", formData, true).then((res) => {
                setCheck(true)
                console.log('res', res);
                getEmisData()
                if (res.status) {

                    toast.success("Successfully Pay");
                    // console.log("getApiget");
                } else {
                    // console.log("get employees data response", response);
                }
            }).catch((err) => { })

        }
    });


    const handleRefresh = () => {
        getEmisData();
    }

    // for disable buttom

    // var num3 = parseFloat(totalinterest).toFixed(2)


    const handleInputChange = (event) => {
        payEmisForm.handleChange(event);
        if (event?.target?.value) {
            setDisable(true)
        }
    }
    // const checking = () => {
    //     const strAscending = [...dataArray].sort((a, b) => {
    //         return a.first_name > b.first_name ? 1 : -1
    //     })
    //     setDataArray(strAscending)
    //     console.log("Ascending")
    // }

    // const checking1 = () => {
    //     const strDescending = [...dataArray].sort((a, b) => {
    //         return a.first_name > b.first_name ? -1 : 1
    //     })
    //     setDataArray(strDescending)
    //     console.log("descending")
    // }
    // console.log(strDescending);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // getEmisData(token,   search  , pagesCount );
    }

    // const selectTransactions = async (id, e) => {
    //     if (!transactionPrimaryId.includes(id)) {
    //         setTransactionPrimaryId(oldArr => [...oldArr, id]);
    //         setCheckbox(true)
    //         settotalItems()

    //     } else {
    //         let x = transactionPrimaryId.shift(id)
    //         console.log(x);
    //         if (transactionPrimaryId.length == 0) {
    //             setCheckbox(false);
    //         }
    //     }
    // }

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <Breadcrumbs order={{ first: { name: "Repayments" }, second: { name: "Pending" } }} />
                                {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                                 <button className="btn btn-primary" disabled>Sync</button>} */}
                            </div>
                            {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
                            <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
                            <h5 style={{marginTop: '10px'}}> <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    {/* <Link to="" className="btn btn-outline-primary mt-2" ><i className="fa fa-plus me-2"></i> Add Borrower</Link> */}
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i>Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"></div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"></div>
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)}  />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Borrower  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th >
                                                        <th className="font-weight-bold">Employer Detail   {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>} </th>
                                                        <th className="font-weight-bold">Repayment Amount  {sortedBy == "amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('amount') }}></i>}</th>
                                                        <th className="font-weight-bold">Lender Interest {sortedBy == "interest" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('interest') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('interest') }}></i>}</th>
                                                        <th className="font-weight-bold">Repayment Date  {sortedBy == "due_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }}></i>}</th>
                                                        <th className="font-weight-bold">Late Charges {sortedBy == "late_charges" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('late_charges') }}></i>}</th>
                                                        <th className="font-weight-bold">Status {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('status') }}></i>}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td /><td />
                                                    <Loader />
                                                </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { customer_id, id, status, amount, earning, first_name, late_charges, due_date, email, mobile_number, employer_name, employer_mobile, user_id, interest_of_days, interest, late_days, mini_rate_of_intrest } = option;
                                                                // var Capitalize = (option) => {
                                                                //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                                // }
                                                                var num2 = parseFloat(option.interest).toFixed(2)
                                                                // var num3 = parseFloat(option.earning).toFixed(2)
                                                                var num4 = parseFloat(option.amount).toFixed(2)
                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td><span className="font-weight-normal1">
                                                                            <Link className="text-primary" to={{ pathname: `${prefix}/employee_detail/${mobile_number}/Existing`, state: { mobile_number } }}>
                                                                                {first_name ? (first_name) : ""} <br />{mobile_number}<br /><span style={{ textTransform: 'none' }}>{email}</span>
                                                                            </Link></span></td>
                                                                        <td>
                                                                            <Link className="text-primary" to={{ pathname: `${prefix}/employer_detail/${user_id}/Existing`, state: { user_id } }}>
                                                                                <span className="font-weight-normal1"> {employer_name ? (employer_name) : ""}
                                                                                    <br />{employer_mobile} <br /> </span> </Link></td>
                                                                        <td><span className="font-weight-normal1">{option.amount ? "$" + num4 : ""}</span></td>
                                                                        <td><span className="font-weight-normal1">{option.interest == null ? 0 : "$" + num2}</span></td>
                                                                        <td><span className="font-weight-normal1"><Moment format="YYYY-MM-DD">{due_date}</Moment></span></td>
                                                                        <td><span className="font-weight-normal1">{late_charges !== null ? late_charges : 0} </span></td>
                                                                        <td><span className="font-weight-normal1">{option.status ? (status) : ""}</span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Pending Repayments Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>
                </div>

            }
            {/* <button className='btn btn-primary' data-bs-target="#pay_emi" data-bs-toggle="modal" >pay</button> */}
            <div className="modal fade effect-scale show" id="pay_emi" aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h6 className="modal-title">Pay</h6>
                            <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                                aria-hidden="true">×</span></button>
                        </div>

                        <div className="modal-body application-modal">
                            <form className="row" onSubmit={payEmisForm.handleSubmit} /* encType="multipart/form-data" */  >
                                {payEmisForm.values.wrong_message ?
                                    <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{payEmisForm.values.wrong_message}</div>
                                    : ''}
                                <div className="row">
                                    {/* <h6>{emiID }</h6>
                                    <h6>{userId}</h6> */}
                                    <div className="row g-xs">
                                        <div className="col-12">
                                            <select className="form-control" name="payment_mode"
                                                onKeyUp={(e) => handleInputChange(e)}
                                                {...payEmisForm.getFieldProps("payment_mode")}
                                                placeholder="Enter Payment Mode"  >
                                                <option value="null">Select One</option>
                                                <option value="cash" >Cash</option>
                                                <option value="cheque">Cheque</option>
                                                <option value="bank transfer">Bank Transfer</option>
                                            </select>
                                            {payEmisForm.touched.payment_mode && payEmisForm.errors.payment_mode ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{payEmisForm.errors.payment_mode}</div> : ''}
                                        </div>


                                        <div className="col-12">
                                            <input type="text" placeholder='comment' name='notes' onKeyUp={(e) => handleInputChange(e)} {...payEmisForm.getFieldProps("notes")}
                                                value={payEmisForm?.comment?.values}
                                                className="form-control" />
                                            {payEmisForm.touched.notes && payEmisForm.errors.notes ?
                                                <div className="invalid-feedback" style={{ display: "block" }}>{payEmisForm.errors.notes}</div> : ''}
                                        </div>
                                        {/* <div className="col-12">
                        <input type="file"  className="form-control" onChange={(e) => handleInputChange(e)} placeholder='choose Image' {...payEmisForm.getFieldProps("file")} value={payEmisForm?.file?.values}   />
                        {payEmisForm.touched.file && payEmisForm.errors.file ?
                          <div className="invalid-feedback" style={{ display: "block" }}>{payEmisForm.errors.file}</div> : ''}
                      </div> */}
                                        <div className="input-group file-browser">
                                            <input type="file" className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                                            {/* <label className="input-group-text btn btn-primary"> Browse <input type="file" className="file-browserinput"  onChange={(e) => setimageUpload(e.target.files[0])} style={{ display: 'none' }}  /> </label> */}
                                            {/* <input type='file'  onChange={(e) => setimageUpload(e.target.files[0])} /> */}
                                        </div>
                                        {/* <li className="registerboximg">
                                  <img src={imageUpload} alt="" />
                                  </li> */}
                                    </div>
                                </div>
                                <div className="form-footer mt-2"><button type="submit" onClick={handleRefresh} disabled={!disable ? true : false} data-bs-dismiss="modal" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default PendingRepayment;
