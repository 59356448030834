import React, { useEffect, useState } from 'react'
import { GetProfile, getApi } from '../service/adminService';
import images from "../../assets/img/1024-size.png"
const Adminprofile = () => {
   const [profileData, setprofileData] = useState({});
   const [selectedFile, setSelectedFile] = useState(null);
   const [imageUrl, setImageUrl] = useState('');


   const admin = JSON.parse(localStorage.getItem("admin"));
   const getProfile = async (token) => {

      // const response = await GetProfile(token);
      const response = await getApi("/admin-profile");

      console.log(response)
      if (response.status == "success") {
         setprofileData(response.data);
      } else {
         console.log("get employees data response", response);
      }
   }
   const { company_name, user_type, email, mobile_number } = profileData

   const handleFormSubmit = (event) => {
      event.preventDefault();
      // You can send the file to the server using AJAX or fetch here
      console.log(selectedFile);
   };

   useEffect(() => {
      const storedImageUrl = localStorage.getItem("profileImageUrl")
      if (storedImageUrl) {
         setImageUrl(storedImageUrl);
      }
   }, [])


   useEffect(() => {
      getProfile(admin.admin_token)
   }, [])

   const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      localStorage.setItem("profileImageUrl", imageUrl)
   };
   return (
      <>

         <div className="main-container container-fluid px-0">
            <div className="page-header">
               <div className="page-leftheader">
                  <h4 className="page-title mb-0 text-primary">Admin Profile</h4>
               </div>
            </div>
            <div className="row">
               <div className="col-xl-3 col-lg-4">
                  <div className="card box-widget widget-user">
                     {/* <div className="widget-user-image mx-auto mt-5" ><img alt="User Avatar" className="rounded-circle" src="../assets/img/1024-size.png" style={{ display: 'block', margin: 'auto' }} /></div> */}

                     <div className="widget-user-image mx-auto mt-5" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img alt="User Avatar" className="rounded-circle" src={imageUrl || images} style={{ display: 'block', margin: 'auto', width: '130px', height: '130px' }} />
                     </div>
                     <div className="card-body text-center pt-2">
                        <div className="pro-user">
                           <h3 className="pro-user-username  mb-1 fs-22">Admin</h3>
                        </div>

                        <div className="upload__box">
                           <div className="upload__btn-box">
                              <label className="upload__btn">
                                 <p>Change Profile Photo</p>
                                 {/* <input type="file" data-max_length="20" className="upload__inputfile" /> */}
                                 <input type="file" className="upload__inputfile" onChange={handleFileInputChange} />
                              </label>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>

               <div className="col-xl-9 col-lg-8">
                  <div className="card">
                     <div className="card-header ">
                        <div className="card-title">Admin Profile</div>
                     </div>
                     <div className="card-body">
                        <div className="card-title font-weight-bold">Basic info:</div>
                        <div className="row">
                           <div className="col-sm-6 col-md-6">
                              <div className="form-group"> <label className="form-label">Company Name</label>
                                 <input type="text" className="form-control" placeholder="First Name" value={company_name} /> </div>
                           </div>
                           <div className="col-sm-6 col-md-6">
                              <div className="form-group"> <label className="form-label">Name</label>
                                 <input type="text" className="form-control" placeholder="Name" value={user_type} /> </div>
                           </div>
                           <div className="col-sm-6 col-md-6">
                              <div className="form-group"> <label className="form-label">Email address</label>
                                 <input type="email" className="form-control" placeholder="Email" value={email} /> </div>
                           </div>
                           <div className="col-sm-6 col-md-6">
                              <div className="form-group"> <label className="form-label">Phone Number</label>
                                 <input type="text" className="form-control" placeholder="Number" value={mobile_number} /> </div>
                           </div>
                           {/* <div className="col-md-12">
                     <div className="form-group"> <label className="form-label">Address</label> 
					 <input type="text" className="form-control" placeholder="Home Address" value="3rd Lane,4th Phase,Street no-4 California"/> </div>
                  </div> */}
                        </div>
                     </div>
                     {/* <div className="card-footer text-left"> <input type="button" name="next" className="next btn btn-primary mb-6 w-md mb-1 mt-1" value="Update"/></div> */}
                  </div>
               </div>
            </div>

         </div>

      </>
   )
}

export default Adminprofile