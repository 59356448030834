import { Link, useLocation, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { GetsubLenderDetail, GetsubemployeeDetail, getApi } from '../service/adminService';
import { Breadcrumbsecond } from '../../components';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { TimeZone } from '../../components/TimeZone/TimeZone';

const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const Sublenderdetail = (props) => {

    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("user_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [count, setCount] = useState('')
    // const [salaryModeList, setSalaryModeList] = useState([]);


    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token
    const { id } = useParams();


    const getSubLenderData = async () => {
        // const response = await GetsubLenderDetail(token, id, search, pagesCount, sortedBy, orderBy);
        const response = await getApi("/get-sub-lender-list", { parent_id: id, search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
        //  console.log(response);
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.AllLender);
                setCount(response.AllLender)
                //    settotalItems(response.data)
                setloader(false)
            }, 2000);
            setloader(true)

        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getSubLenderData(token, search, pagesCount);
    }, [search, pagesCount, orderBy, sortedBy]);


    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    return (
        <>
            <>
                <Toaster position="top-right" reverseOrder={false} />
                {!showAddNewCases &&
                    <div>
                        <div className="main-container container-fluid px-0">
                            <div className="page-header mb-3">
                                <div className="page-leftheader">
                                    {/* <h4 className="page-title mb-0 text-primary">Employer - { dataArray && dataArray.length>0 ?dataArray[0].current_employer : ""}({count == "" ? 0 : count})</h4> */}
                                    <Breadcrumbsecond order={{ first: { name: "Lender", path: `${prefix}/lender` }, second: { name: "All Sub-Lender " } }} />
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                    <div className="form-group">
                                        <label className="form-label">Search</label>
                                        <div className="row g-xs">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                        <tr>
                                                            {/* <th className="font-weight-bold">Logo</th> */}
                                                            <th className="font-weight-bold">Sub Lender Detail  {sortedBy == "company_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('company_name') }}></i>}</th>
                                                            <th className="font-weight-bold">Authorize Person Name {sortedBy == "full_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('full_name') }}></i>}</th>
                                                            {/* <th className="font-weight-bold">Website {sortedBy == "website" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('website') }}></i>}</th> */}
                                                            <th className="font-weight-bold">Address  {sortedBy == "address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }}></i>}</th>
                                                            <th className="font-weight-bold">Created At {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    {loader == true ? <tbody>
                                                        <td />
                                                        <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                            <Loader /> </div> </tbody> :
                                                        <>
                                                            <tbody>
                                                                {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                    let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                    return (
                                                                        <tr key={serial_num} >
                                                                            {/* {option.logo != null ?
                                                                                <td><div style={{ width: 50, height: 35, borderRadius: '8px' }} >
                                                                                    <span className="font-weight-normal1"><img src={option.logo} alt='' /></span></div></td>
                                                                                : <td><div style={{ width: 50, height: 35, borderRadius: '8px' }} >
                                                                                    <span className="font-weight-normal1" ><img src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='' /></span></div></td>
                                                                            } */}
                                                                            <td>{/*  <Link className="text-primary" to={prefix + `/employer_detail/${option.user_id}`}> */}<span className="font-weight-normal1" >{
                                                                                option.company_name !== null ? option.company_name : ""}<br />{option.mobile_number}<br />{option.email}</span>{/* </Link> */}
                                                                            </td>
                                                                            <td><span className="font-weight-normal1">{option.full_name ? option.full_name : ""}</span></td>
                                                                            {/* <td className='font-weight-normal1'><span className="content_fix" ><a href={option.website} target="blank" style={{ color: "#0f2f82" }} >{option.website ? option.website : ""}</a></span></td> */}
                                                                            <td className='font-weight-normal1'><span className="content_fix" >{option.address ? option.address : "--"}</span></td>
                                                                            <td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                )}
                                                            </tbody>
                                                            <tbody>
                                                                {dataArray.length == 0 &&
                                                                    <tr>
                                                                        <td colSpan={9} className='text-center'>No Employee Data Available</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {pagesCount > 0 && dataArray.length > 0 ?
                                    <>
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <PaginationComponent className="justify-content-center"
                                                    totalItems={totalPagesCount}
                                                    pageSize={10}
                                                    maxPaginationNumbers={3}
                                                    onSelect={(e) => setpagesCount(e)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                }

            </>
        </>
    )
}







export default Sublenderdetail
