import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Toaster } from 'react-hot-toast';
import PaginationComponent from '../../PaginationComponent';
import { getApi, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';
import Search from '../../components/Search/Search';

const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const MerchantCommission = (props) => {
    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("user_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [input, setInput] = useState(0);
    const [searchcheck, setsearchcheck] = useState(false)
    const [timevalue, settimevalue] = useState("")
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [recordCount, setRecordCount] = useState("")
    const [ValidPagination, setValidPagination] = useState(false)

    const getAllMerchantCommission = async (token) => {
        try {
            // const response = await GetMerchantCommission(token, pagesCount, search, sortedBy, orderBy);
            const response = await getApi("/get-merchant-commission", { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy });

            console.log("fdgdgdgddfgdg", response)
            if (response.status == true) {
                settimevalue("")
                setTimeout(() => {
                    setDataArray(response.data);
                    setTotalPagesCount(response.AllMerchant);
                    setRecordCount(response.AllMerchant);
                    // settotalItems(response.data)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else {
                console.log("get city data response", response);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getAllMerchantCommission(admin.admin_token);
    }, [search, pagesCount, sortedBy, orderBy]);

    // const getDetail = async (token, id) => {
    //     const response = await GetMerchantrDetail(token, id);
    //     console.log("🚀 ~ file: Merchant.js:124 ~ getDetail ~ response:", response)
    //     if (response.status) {
    //         setDetail(response.data);
    //     } else {
    //         console.log("get merchant data response", response);
    //     }
    // }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        getAllMerchantCommission(admin.admin_token, pagesCount, search);
    }
    async function apiCall(val, id) {
        try {
            // console.log(val)
            val = Number(Number(val).toFixed(2))
            // await UpdateMerchantCommission(admin.admin_token, id, val)
            await postApi("/update-merchant-commission", { user_id: id, mini_rate_of_intrest: val })
            getAllMerchantCommission(admin.admin_token, pagesCount, search)
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        let clear = setTimeout(() => {
            apiCall(input.val, input.id)
        }, 1000)
        return (
            () => {
                clearTimeout(clear)
            }
        )
    }, [input])

    const updateCommission = async (val, id) => {
        setInput({ val, id })
        // try{
        //     console.log(val)
        //     val = Number(Number(val).toFixed(2))
        //     await UpdateMerchantCommission(admin.admin_token,id,val)
        //     getAllMerchantCommission(admin.admin_token, pagesCount, search)
        // }catch(e){
        //     console.log(e);
        // }

    }

    return (

        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h4 className="page-title mb-0 text-primary">Merchant Commission({totalPagesCount})</h4> */}

                                <div className="page-leftheader">
                                    {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                    <h4 className="page-title mb-0 text-primary">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a >Setting</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Merchant Commission{recordCount ? `(${recordCount})` : ""}</li>
                                            </ol>
                                        </nav>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <span onChange={() => setsearchcheck(true)}>
                                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
                            </span>
                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setsearchcheck(true) }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Merchant</th>
                                                        <th className="font-weight-bold">Commission%</th>
                                                        {/* <th className="font-weight-bold " > <span style={{ marginLeft: "50px" }}>Action</span></th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                {loader == true ? <tbody>
                                                    <td />
                                                    <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                        <Loader />
                                                    </div> </tbody> : <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            // let Capitalize = (option) => {
                                                            //     return option.charAt(0).toUpperCase() + option.slice(1);
                                                            //     }
                                                            return (
                                                                <tr key={serial_num} >
                                                                    <td>
                                                                        <Link className="text-primary" to={prefix + `/merchantdetail/${option.user_id}`}>
                                                                            {/* <Link to="" onClick={() => getDetail(admin.admin_token, option.user_id)} data-bs-target="#applicaiton-report" data-bs-toggle="modal" > */}
                                                                            <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>{option.full_name ? option.full_name : ""}
                                                                                <br />{option.company_name}
                                                                                <br />{option.mobile_number}</span>
                                                                        </Link>
                                                                    </td>
                                                                    {(searchcheck && timevalue == "") ? <>
                                                                        <td><input type="number" className="form-control"
                                                                            value={option.mini_rate_of_intrest}
                                                                            onClick={() => { setsearchcheck(false) }}
                                                                            onBlur={(e) => updateCommission(e.target.value, option.user_id)} />
                                                                        </td></> :
                                                                        <>
                                                                            <td><input type="number" className="form-control"
                                                                                defaultValue={option.mini_rate_of_intrest}
                                                                                onBlur={(e) => updateCommission(e.target.value, option.user_id)}
                                                                                onChange={(e) => { settimevalue(e.target.value) }} />
                                                                            </td>
                                                                        </>}
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody></>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                    {/* Merchant Detail Modal Start */}


                    {/* <div className="modal fade effect-fall show" id="applicaiton-report" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-content-demo">
                                <div className="modal-header"> <h6 className="modal-title">Merchant Information</h6>
                                    <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span aria-hidden="true">×</span></button> </div>

                                <div className="modal-body application-modal">

                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="">
                                                <div className="main-content-body main-content-body-contacts">
                                                    <div className="main-contact-info-header bg-contacthead pb15">
                                                        <div className="media">
                                                            <div className="media-body text-white ml-0">
                                                                <h4 className="text-white">Company Name: {detail && detail.company_name ? detail.company_name : ''}</h4>
                                                                <p className="mb-1">Person Name: {detail && detail ? detail.full_name : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="main-contact-info-body">
                                                        <div className="media-list p-0">
                                                            <div className="media py-4 mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-phone"></i> </div>
                                                                        <div className="w-70"> <label>Phone</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.mobile_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-envelope"></i> </div>
                                                                        <div className="w-70"> <label>Email</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.email : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                                                        <div className="w-70"> <label>GST no:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.gst_number : ''}</span> </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                        <div className="w-70"> <label>Address:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.address : ''}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="media py-4 border-top mt-0">
                                                                <div className="media-body">
                                                                    <div className="d-flex">
                                                                        <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-map-marker"></i> </div>
                                                                        <div className="w-70"> <label>Country:</label> <span className="font-weight-normal1 fs-14">{detail && detail ? detail.country : ''}</span> </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Merchant Detail Modal End */}

                </div>
            }
        </>

    )
}



export default MerchantCommission;
