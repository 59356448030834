import React, { useEffect, useRef, useState } from 'react'
import PaginationComponent from '../../../PaginationComponent'
import { Link } from 'react-router-dom'
import { Breadcrumbs } from '../../../components'
import { getApi, getEmployeeLoginlogs } from '../../service/adminService'
import { TimeZone } from '../../../components/TimeZone/TimeZone'
import Loader from '../../../components/Loader/loader'
import Search from '../../../components/Search/Search'

const EmployeeLogs = () => {
    const [datasArray, setDatasArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [loader, setloader] = useState(true)
    const [Count, setCount] = useState();
    const [ValidPagination, setValidPagination] = useState(false)

    const GetEmployeeLoginLogs = async (token) => {
        // const response = await getEmployeeLoginlogs(token, search, pagesCount, sortedBy, orderBy);
        const response = await getApi("/employee-login-logs", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log(response);
        if (response?.status) {
            setTimeout(() => {
                setDatasArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        }

        else {
            console.log("get merchant data response", response);
        }
    }
    useEffect(() => {
        GetEmployeeLoginLogs(admin.admin_token)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Stores</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/merchant/dashboard" >Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Stores</li>
                        </ol>
                    </nav> */}
                            {/* <Breadcrumbs order={{ first: { name: "Employee Logs" } }} /> */}
                            <Breadcrumbs order={{ first: { name: "Logs" }, second: { name: `Employees Log ${Count ? '(' + Count + ')' : ''}` } }} />

                        </h4>
                    </div>
                    <div className="page-rightheader">
                        <div className="btn-list">
                            <Link className="btn btn-outline-primary" ><i className="fa fa-download"></i> Download</Link>                    </div>
                    </div>
                </div>

                <div className="row">
                    {/* <div className="col-sm-12 col-lg-12">
                <div className="card">
                    <div className="card-header br-0">
                        <h3 className="card-title">Counter List</h3>
                    </div>
                </div>
            </div> */}
                </div>
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                {/* <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                            <thead className="border-bottom-0 pt-3 pb-3">
                                <tr>
                                    {/* <th className="font-weight-bold">S No.</th> */}
                                    <th className="font-weight-bold">Customer ID   {sortedBy == "customer_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }}></i>}</th>
                                    <th className="font-weight-bold">Name   {sortedBy == "Created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('Created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('Created_at') }}></i>}</th>
                                    <th className="font-weight-bold">Mobile No. {sortedBy == "id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('id') }}></i>}</th>
                                    <th className="font-weight-bold">Token  {sortedBy == "customer_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }}></i>}</th>
                                    <th className="font-weight-bold"> Created Date  {sortedBy == "Created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('Created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('Created_at') }}></i>}</th>
                                    <th className="font-weight-bold">Updated Date  {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('updated_at') }}></i>}</th>
                                    {/* <th className="font-weight-bold"> </th> */}
                                    {/* <th className="font-weight-bold"> </th> */}
                                </tr>
                            </thead>
                            {loader == true ? <tbody>
                                <td /><td /><td />
                                <div >
                                    <Loader /> </div> </tbody> :
                                <>
                                    <tbody>
                                        {datasArray?.length > 0 && datasArray?.map((option, i) => {
                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                            const { customer_id, counter_number, name, full_name, mobile_number, id, updated_at } = option;
                                            var timezone = new Date(option.updated_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                            const request_data = JSON.parse([option.request_data])
                                            const response_data = JSON.parse([option.response_data])
                                            console.log(response_data)
                                            return (
                                                <tr key={serial_num}>
                                                    <td>{customer_id ? customer_id : "NA"}</td>
                                                    <td>{response_data.full_name ? response_data.full_name : "NA"}</td>
                                                    <td>{request_data.mobile_number ? request_data.mobile_number : "NA"}</td>
                                                    <td> {request_data.otp ? request_data.otp : "NA"} </td>
                                                    <td>{TimeZone(option.Created_at).timeZone}</td>
                                                    <td>{TimeZone(option.updated_at).timeZone}</td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                    <tbody>
                                        {datasArray.length == 0 &&
                                            <tr>
                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                            </tr>
                                        }
                                    </tbody></>}
                        </table>
                    </div>
                </div>
                {pagesCount > 0 && datasArray.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && datasArray.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </>
    )
}

export default EmployeeLogs
