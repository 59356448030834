import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbsecond } from '../../../components';
import { getApi, postApi } from '../../service/lenderService';

const Addusers = () => {
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const navigate = useNavigate()
    const lender = JSON.parse(localStorage.getItem("lender"))
    const token = lender.lender_token
    const [dataArray, setDataArray] = useState([]);

    const form = useFormik({
        initialValues: {
            full_name: "",
            email: "",
            mobile_number: "",
            role: "",
            password: "",
            confirm_password: "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            full_name: yup.string().required('Please Enter Lender Name'),
            mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number values') */.min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Max Contain 15 Number"),
            email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
            role: yup.string().required('Please select Role'),
            password: yup.string().required('Please Enter Password')/* .matches(regex, 'Please Enter Only Character Values') */,
            confirm_password: yup.string().required('Please Enter Confirm Password')/* .matches(regex, 'Please Enter Only Character Values') */
                .oneOf([yup.ref("password"), null], "Password Must Be Same"),
        }),

        onSubmit: async (values) => {
            // const data = JSON.stringify({
            //     full_name: values.full_name,
            //     email: values.email,
            //     mobile_number: values.mobile_number,
            //     role_id: values.role,
            //     password: values.password,
            //     confirmedPassword: values.confirm_password,

            // })

            const response = await postApi('/add-sub-users', {
                full_name: values.full_name,
                email: values.email,
                mobile_number: values.mobile_number,
                role_id: values.role,
                password: values.password,
                confirmedPassword: values.confirm_password,
            })
            // const response = await addUsers(token, data)
            console.log(response)
            if (response.status == true) {
                toast.success(response.message)
                setTimeout(() => {
                    navigate(prefix + '/manage_user')
                }, 1000)

            } else if (response.status == false) {
                console.log(response)
                toast.error(response.message)
            }
        }
    })
    const handleChangeQueryBuilder = e => {
        const { name, value } = e.target;
        form.setValues({
            ...form.values,
            [name]: value.trim()
        })
    };

    const getUsermanager = async (token) => {
        // const response = await GetUsermanagers(token);
        const response = await getApi("/get-role-permission");

        console.log(response)
        try {
            if (response.status == true) {
                setDataArray(response.rows);


            } else if (response.message === 'Session expired') {
                localStorage.removeItem("lender");
                navigate('/lender/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        // setpagesCount(1);
        getUsermanager(token);
    }, []);

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">
                        {/* <Breadcrumbs order={{ first: { name: "User Management" } }} /> */}
                        <Breadcrumbsecond order={{ first: { name: "Manage User", path: `${prefix}/manage_user` }, second: { name: "Add User"/* ,path:`${back}` */ } }} />

                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Lender Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Lender Name" {...form.getFieldProps("full_name")} />
                                </div>
                                {form.touched.full_name && form.errors.full_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Email ID" {...form.getFieldProps("email")} />
                                </div>
                                {form.touched.email && form.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Mobile No.</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Mobile No." {...form.getFieldProps("mobile_number")} />
                                </div>
                                {form.touched.mobile_number && form.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Role</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <select className="form-control mb-0" name="role" {...form.getFieldProps("role")} onChange={(e) => handleChangeQueryBuilder(e)}>
                                        <option>Select Roles</option>
                                        {dataArray.map((e) => {
                                            return (
                                                <option key={e.id} value={e.id}>{e.title}</option>
                                            )
                                        })
                                        }
                                    </select>
                                    {/* <input type="text" className="form-control" placeholder="Enter Your Address" {...form.getFieldProps("address")} /> */}
                                </div>
                                {form.touched.role && form.errors.role ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.role}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Password</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="password" className="form-control" placeholder="Enter Password" {...form.getFieldProps("password")} />
                                </div>
                                {form.touched.password && form.errors.password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Confirm Password</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="password" className="form-control" placeholder="Enter Confirm Password" {...form.getFieldProps("confirm_password")} />
                                </div>
                                {form.touched.confirm_password && form.errors.confirm_password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_password}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Add</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Addusers
