import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import moment from 'moment-timezone'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getApi, GetPermission } from '../../../service/employService';
import Loader from '../../../../components/Loader/loader';
import { Breadcrumbsecond } from '../../../../components';
import PaginationComponent from '../../../../PaginationComponent';

const Attendance = () => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [dataArray1, setDataArray1] = useState([]);
    let [search, setSearch] = useState('');
    const [dsaDetail, setdsaDetail] = useState(false);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("status")
    const [orderBy, setOrderBy] = useState('desc')
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const [updatedate, setUpdatedate] = useState()
    const current_date_format = new moment().format("YYYY-MM-DD")
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX
    const [endDate, setendDate] = useState('');
    const [startDate, setstartDate] = useState('');
    const [Departmentname, setDepartmentname] = useState('')
    const [Departmentname1, setDepartmentname1] = useState('')
    const employer = JSON.parse(localStorage.getItem("employer"));
    const employer_name = employer.user_name;
    const [date, setDate] = useState("");


    const getEmployeesAttendance = async (token, data) => {
        if (data?.length) {
            search = data
        }
        const response = await getApi(`/attendance-list`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy, start_date: startDate, end_date: endDate, userTimezone: date_Format, department: Departmentname, department_id: Departmentname1 });

        try {
            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    if (response.data.rows.length > 0) {
                        setUpdatedate(response.data.rows[0].attendance_date)
                    }
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }
    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            // console.log('response', response.data);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            // toast.error("error :", error.message);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        if (user_type == "SUB-EMPLOYER") {
            GetPermissions(employer.employer_token);
        }
    }, []);

    const employerId = employer.user_id;
    const user_type = employer.user_type
    // const role = JSON.parse(employer.role?.permissions)
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);

    } else {

    }

    const [ewa_attendance_view, setewa_attendance_view] = useState(false)
    const [ewa_mark_attendance, setewa_mark_attendance] = useState(false)
    const [ewa_in_out_time, setewa_in_out_time] = useState(false)
    const [ewa_bulk_upload_attendance, setewa_bulk_upload_attendance] = useState(false)
    const [ewa_view_attendance, setewa_view_attendance] = useState(false)
    useEffect(() => {
        if (role) {
            // console.log(role, "role")
            const options = role;
            // console.log(options, "options")
            options.length > 0 && options.forEach((option) => {
                // console.log(option, "option")
                if (option.key == "ewa_attendance_view") {
                    setewa_attendance_view(true);
                }
                if (option.key == "ewa_mark_attendance") {
                    setewa_mark_attendance(true);
                }
                if (option.key == "ewa_in_out_time") {
                    setewa_in_out_time(true);
                }
                if (option.key == "ewa_bulk_upload_attendance") {
                    setewa_bulk_upload_attendance(true);
                }
                if (option.key == "ewa_view_attendance") {
                    setewa_view_attendance(true);
                }
            });
        }
    }, [role]);

    useEffect(() => {
        getEmployeesAttendance(employer.employer_token)
    }, [pagesCount, search, sortedBy, orderBy, date, startDate, endDate, Departmentname, Departmentname1])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const handleCallback = (event, picker) => {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');

        // Calculate the difference in days
        const diffInDays = picker.endDate.diff(picker.startDate, 'days');

        if (diffInDays > 31) {
            // If the selected range is more than 31 days, adjust the end date
            const newEndDate = picker.startDate.clone().add(31, 'days');
            picker.setEndDate(newEndDate);
            picker.element.val(startDate + ' - ' + newEndDate.format('YYYY-MM-DD'));
            setstartDate(startDate);
            setendDate(newEndDate.format('YYYY-MM-DD'));
        } else {
            picker.element.val(startDate + ' - ' + endDate);
            setstartDate(startDate);
            setendDate(endDate);
        }
    };

    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate('')
        setendDate('')
    }

    const [nfcsitename, setnfcsitename] = useState([])
    const GetNFC = async () => {
        try {
            const response = await getApi("/get-nfc-list", { search: "", page_number: "", sortby: "", orderby: "" });
            if (response.status == true) {
                setnfcsitename(response.data);
            }
            else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetNFC();
    }, []);

    const GetDepartment = async (token) => {
        try {
            // const response = await GetDepartmentDetail(token, search, pagesCount, sortedBy, orderBy);
            const response = await getApi("/get-nfc-site-list");
            console.log('response', response);
            if (response.status == true) {
                setDataArray1(response.data);

            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetDepartment();
    }, []);


    return (
        <>
            {!dsaDetail &&
                <div className="main-container container-fluid px-0">

                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">
                                <Breadcrumbsecond order={{ first: { name: "WFM / Reports" }, second: { name: "Attendance" } }} />
                            </h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                <a href={exportfix + `/attendance-export?start_date=${startDate ? startDate : current_date_format}&end_date=${endDate ? endDate : current_date_format}&username=${employer_name}&time_zone=${date_Format}`}
                                    className="btn btn-primary btn-pill mt-3" style={{ float: 'right' }} download >
                                    <i className="fa fa-download me-2"></i> Export Attendance</a>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Select Date</label>
                                <div className="row g-xs">
                                    <div className="input-group" >
                                        <DateRangePicker
                                            onApply={handleCallback} onCancel={handleCallbackOnCancel}
                                            initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                            <input
                                                placeholder="Search By Date"
                                                className="form-control fc-datepicker hasDatepicker"
                                                type="text" defaultValue=""
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Site Name</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="loan_type" value={Departmentname} onChange={(e) => setDepartmentname(e.target.value)} >
                                                <option value="">Select Site Name</option>
                                                {nfcsitename.map(nfcdata => (<option key={nfcdata.id} value={nfcdata.site_name}>{nfcdata.site_name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Department</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="loan_type" value={Departmentname1} onChange={(e) => setDepartmentname1(e.target.value)} >
                                                <option value="">Select Department</option>
                                                {dataArray1.map(nfcdata => (<option key={nfcdata.site_id} value={nfcdata.site_id}>{nfcdata.nfc_site_name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        {localStorage.getItem("search_result") ?
                                            <input type="text" defaultValue={localStorage.getItem("search_result")}
                                                className="form-control" placeholder="Search By Keyword"
                                                value={search} onChange={(e) => { setSearch(e.target.value) }} /> :
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value) }} />}
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Employee Name  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Status  {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>}</th>
                                                    <th className="font-weight-bold">In/Out Time  {sortedBy == "in_time" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_time') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_time') }}></i>}</th>
                                                    <th className="font-weight-bold">Total Hrs/Date {sortedBy == "customer_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }}></i>}</th>
                                                    <th className="font-weight-bold">Auto Checkout Status  {sortedBy == "auto_check_out" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('auto_check_out') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('auto_check_out') }}></i>}</th>
                                                    <th className="font-weight-bold">Check In/Out From  {sortedBy == "in_from" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_from') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_from') }}></i>}</th>
                                                    <th className="font-weight-bold">In/Out Time GPS Location  {sortedBy == "gps_location" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('gps_location') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('gps_location') }}></i>}</th>
                                                    <th className="font-weight-bold">Site Name/Department  {sortedBy == "site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('site_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Time Zone  {sortedBy == "time_zone" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }}></i>}</th>


                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /> <td /><td /><td /><td />
                                                <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                    <Loader /> </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, i) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                            const { status, in_from, out_from, site_name, customer_id, in_time, auto_check_out, out_time, out_gps_location, attendance_date, employee_code, gps_location, mobile_number, first_name, last_name, time_zone, department_name } = option;
                                                            // console.log(i , "checkingi" , serial_num,)                                                        

                                                            var timeZonesIN = moment.utc(option.in_time).format("HH:mm A")
                                                            // var timeZonesOUT = moment.utc(option.out_time).format("HH:mm")
                                                            var timeZonesOUT = moment.utc(option.out_time).format("YYYY-MM-DD HH:mm A");
                                                            const calculateTotalTime = (inTime, outTime) => {
                                                                if (!in_time || !out_time) {
                                                                    return "-";
                                                                }
                                                                const inTimeDate = moment(inTime)
                                                                const outTimeDate = moment(outTime)
                                                                const diffInMinutes = outTimeDate.diff(inTimeDate, 'minute');
                                                                const hours = Math.floor(diffInMinutes / 60);
                                                                const remainingMinutes = diffInMinutes % 60;
                                                                return `${hours} hours ${remainingMinutes} minute`;
                                                            };
                                                            return (
                                                                <tr key={serial_num}>
                                                                    <td><Link className="text-primary" to={{ pathname: `${prefix}/viewemployee_detail/${customer_id}`, state: { customer_id } }} >{first_name ? first_name : "-"}  {last_name ? last_name : ""}<br />{option?.mobile_number && mobile_number}<br />{employee_code ? employee_code : "-"}</Link></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.status ? option?.status : "--"}</span></td>
                                                                    <td>{option.in_time ? timeZonesIN : "-"}<br />{option.out_time ? timeZonesOUT : "-"}</td>
                                                                    <td>{calculateTotalTime(option.in_time, option.out_time)}<br />{attendance_date ? attendance_date : "-"}</td>
                                                                    <td>{auto_check_out == '1' ? <span className='text-danger'>YES </span> : <span className='text-success'>NO</span>}</td>
                                                                    <td><span className="content_fix" style={{ fontSize: 'smaller' }}>{option?.in_from != null ? in_from : "--"}<br />{option?.out_from != null ? out_from : "--"}</span></td>
                                                                    <td><span className="content_fix">{option?.gps_location ? gps_location : "--"}<br />{option?.out_gps_location ? out_gps_location : "--"}</span></td>
                                                                    <td>{option?.site_name ? site_name : "--"}<br />{option?.department_name ? department_name : "--"}</td>
                                                                    <td>{option?.time_zone ? time_zone : "--"}</td>
                                                                </tr>)
                                                        })
                                                        } </tbody>

                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody> </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && dataArray.length > 0 ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>
            }
        </>
    )
}

export default Attendance