import React from 'react'

const Asset = ({ employeeDetail }) => {
    return (
        <div style={{ marginTop: "30px" }}>
            <h3>Assets</h3>
            <div className="main-contact-info-body" style={{ marginTop: "0px" }}>
                <div className="media-list p-0">

                    <div className="media py-4 mt-0">
                        <div className="media-body">
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-house"></i> </div>
                                <div className="w-70"> <label>House Value : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.house_value ? "$" + employeeDetail?.house_value : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-car"></i> </div>
                                <div className="w-70"> <label>Car Value : </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.car_value ? "$" + employeeDetail?.car_value : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-landmark"></i> </div>
                                <div className="w-70"> <label>Land Value :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.land_value ? "$" + employeeDetail?.land_value : '--'}</span> </div>
                            </div>
                        </div>
                    </div>

                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">

                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                                <div className="w-70"> <label>Life Policy :
                                </label> <span className="font-weight-normal1 fs-14">{employeeDetail?.life_policy ? "$" + employeeDetail?.life_policy : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-credit-card"></i> </div>
                                <div className="w-70"> <label>debtors :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.debtors ? "$" + employeeDetail?.debtors : '--'}</span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                <div className="w-70">
                                    <label>Personal Effects :</label>
                                    <span className="font-weight-normal1 fs-14"> {employeeDetail?.personal_effects ? "$" + employeeDetail?.personal_effects : '--'} </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="media py-4 border-top mt-0">
                        <div className="media-body">
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i> </div>
                                <div className="w-70"> <label>Available Cash :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.cash_on_hand ? "$" + employeeDetail?.cash_on_hand : '--'} </span> </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-money-check-alt"></i></div>
                                <div className="w-70">
                                    <label>Cash At Bank :</label>
                                    <span className="font-weight-normal1 fs-14">{employeeDetail?.cash_at_bank ? "$" + employeeDetail?.cash_at_bank : '--'}</span>
                                </div>
                            </div>
                            <div className="media py-4 border-top mt-0">
                                <div className="media-body">
                                    <div className="d-flex align-items-center">
                                        {/* <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-toolbox"></i> </div>
<div className="w-70"> <label>Total Asset :</label> <span className="font-weight-normal1 fs-14">{employeeDetail?.total_asset ? "$" + employeeDetail?.total_asset : '--'} </span> </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>)
}

export default Asset