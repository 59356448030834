import React, { useEffect, useRef, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import PaginationComponent from '../../PaginationComponent'
import Loader from '../../components/Loader/loader'
import { UpdateTransferCharges, getApi, postApi } from '../service/adminService'

const Balancetransfer = () => {

    const [loader, setloader] = useState(true)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [count, setcount] = useState(0);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [input, setInput] = useState({ val: '', id: '' });
    const [Type, setType] = useState('');
    const [searchcheck, setsearchcheck] = useState(false)
    const [timevalue, settimevalue] = useState("")
    // const [input, setInput] = useState(true);
    // const [Type, setType] = useState()
    const admin = JSON.parse(localStorage.getItem("admin"));
    // const prefix = process.env.REACT_APP_ADMIN_PRIFIX;


    const getAllCityData = async (token, pageNumber, filterVlu) => {
        // const response = await Getcities(token, pageNumber, filterVlu, sortedBy, orderBy);
        const response = await getApi("/get-all-country", { page_number: pageNumber, search: filterVlu, sortby: sortedBy, orderby: orderBy });

        console.log("fdgdgdgddfgdg", response)
        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data);
                setTotalPagesCount(response.AllCountry);
                setcount(response.AllCountry);
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get city data response", response);
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getAllCityData(admin.admin_token, pagesCount, search);
    }, [search, pagesCount]);



    // async function apiCall(val, id) {
    //     try {
    //         // console.log(val)
    //         const data = {
    //             "id": id,
    //             "transfer_charge": val,
    //             "transfer_charge_type": Type
    //         }
    //         val = Number(Number(val).toFixed(2))
    //         await UpdateTransferCharges(admin.admin_token, data)
    //         getAllCityData(admin.admin_token, pagesCount, search);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    // useEffect(() => {
    //     let clear = setTimeout(() => {
    //         apiCall(input.val, input.id, Type)
    //     }, 1000)
    //     return (
    //         () => {
    //             clearTimeout(clear)
    //         }
    //     )
    // }, [input, Type])

    // const updateCommission = async (val, id, type) => {
    //     setInput({ val, id })
    //     setType(type)
    // }

    // const handleChangeQueryBuilders = async (e, val, id) => {
    //     setType(e)
    //     setInput({ val, id })

    // }

    const apiCall = async (val, id, Type) => {
        try {
            // const data = {
            //     "id": id,
            //     "transfer_charge": val,
            //     "transfer_charge_type": Type
            // };
            const formattedVal = Number(Number(val).toFixed(2));
            // 
            // await UpdateTransferCharges(admin.admin_token, data);
            await postApi("/set-transfer-charges", {
                id: id,
                transfer_charge: val,
                transfer_charge_type: Type
            });

            getAllCityData(admin.admin_token, pagesCount, search);
        } catch (e) {
            console.log(e);
        }
    };

    const handleBlur = () => {
        apiCall(input.val, input.id, Type);
    };

    useEffect(() => {
        handleBlur();

    }, [input, Type]);

    const updateCommission = (val, id, type) => {
        setInput({ val, id });
        setType(type);
    };

    const handleChangeQueryBuilders = (e, val, id) => {
        setType(e);
        setInput({ val, id });
    };
    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h4 className="page-title mb-0 text-primary">Merchant Commission({totalPagesCount})</h4> */}

                                <div className="page-leftheader">
                                    {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                    <h4 className="page-title mb-0 text-primary">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a>Setting</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Balance Transfer Charges ({count})</li>
                                            </ol>
                                        </nav>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setsearchcheck(true) }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Country</th>

                                                        <th className="font-weight-bold">Charges (amount per transaction OR % per transaction)</th>
                                                        <th className="font-weight-bold " > Transaction Type</th>
                                                        {/* <th></th><th></th><th></th> */}
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td />
                                                    <div >
                                                        <Loader />
                                                    </div> </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { id, name, transfer_charge, transfer_charge_type } = option;
                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td>
                                                                            <span className="font-weight-bold" >{name ? name : ""}
                                                                            </span>
                                                                        </td>
                                                                        {(searchcheck && timevalue == "") ? <>
                                                                            <td>
                                                                                <input type="number" className="form-control"
                                                                                    // defaultValue={transfer_charge ? transfer_charge : ""} 
                                                                                    value={transfer_charge || ""}
                                                                                    // onBlur={handleBlur}
                                                                                    onClick={() => { setsearchcheck(false) }}
                                                                                    onBlur={(e) => { updateCommission(e.target.value, option.id, transfer_charge_type); handleBlur() }} />

                                                                            </td></> : <>
                                                                            <td>
                                                                                <input type="number" className="form-control"
                                                                                    // defaultValue={transfer_charge ? transfer_charge : ""} 
                                                                                    defaultValue={transfer_charge || ""}
                                                                                    // onBlur={handleBlur}
                                                                                    onChange={(e) => { settimevalue(e.target.value) }}
                                                                                    onBlur={(e) => { updateCommission(e.target.value, option.id, transfer_charge_type); handleBlur() }} />

                                                                            </td></>}
                                                                        <td> <select className='form-control font-weight-bold'
                                                                            value={transfer_charge_type || ""}
                                                                            //  defaultValue={transfer_charge_type ? transfer_charge_type : ""} 
                                                                            onChange={(e) => handleChangeQueryBuilders(e.target.value, transfer_charge, id)} >
                                                                            <option value="">Select Type</option>
                                                                            <option value="FIXED">Fixed Amount</option>
                                                                            <option value="PERCENT" >Percent</option>
                                                                        </select></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={9} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export default Balancetransfer