import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../service/employService';
import { Breadcrumbsecond } from '../../../components';

const AddParentrole = () => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate()
    const employer = JSON.parse(localStorage.getItem("employer"))
    const token = employer.employer_token
    const [employeeData, setEmployeeData] = useState([]);
    const [dataArrays, setDataArrays] = useState([]);
    const [EmployeeView, setEmployeeView] = useState()
    const [EWAView, setEWAView] = useState()
    const [NFCView, setNFCView] = useState()
    const [RepaymentView, setRepaymentView] = useState()
    // const [MobileView, setMobileView] = useState()
    const updateEmployeeData = (e, key) => {
        const checkboxValue = e.target.value;
        const isChecked = e.target.checked;


        if (key === "employee_view") {
            setEmployeeView(isChecked);
        }
        if (key === "ewa_attendance_view") {
            setEWAView(isChecked)
        }
        if (key === "nfc_view") {
            setNFCView(isChecked)
        }
        if (key === "repayment_view_bnpl_list") {
            setRepaymentView(isChecked)
        }
        // if (key === ""){
        //     setMobileView(isChecked)
        // }
        if (isChecked) {
            setEmployeeData((prevData) => [...prevData, { value: checkboxValue, key }]);
        } else {
            setEmployeeData((prevData) => prevData.filter(item => item.value !== checkboxValue));

            // if (key === "employee_upload_bulk" || key === "employee_edit" || key === "employee_delete") {
            //     document.querySelector('.NewCHeckBx[value="true"]').checked = true;
            // }

        };
    }


    const form = useFormik({
        initialValues: {
            title: "",
            parent_id: "",
            permission_key: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Please Enter Role Title'),

        }),
        onSubmit: async (values) => {
            // const data = JSON.stringify({
            //     title: values.title,
            //     parent_id: values.parent_id,
            //     permissions: employeeData
            // })

            // const response = await addUserrole(token, data)
            const response = await postApi("/add-role-permission", {
                title: values.title,
                parent_id: values.parent_id,
                permissions: employeeData
            })

            // console.log(response)
            if (response.status == true) {
                toast.success(response.message)
                setTimeout(() => {
                    navigate(prefix + '/manage_role')
                }, 1000)

            } else if (response.status == false) {
                // console.log(response)
                toast.error(response.message)
            }
        }
    })

    const getRoleandchild = async (token) => {
        // const response = await GetRoleandchild(token);
        const response = await getApi("/role-and-child-role");

        try {
            if (response.status == true) {
                setDataArrays(response.data.rows);
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getRoleandchild(employer.employer_token);
    }, []);

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">
                        {/* <Breadcrumbs order={{ first: { name: "User Management" } }} /> */}
                        <Breadcrumbsecond order={{ first: { name: "Manage Role", path: `${prefix}/manage_role` }, second: { name: "Add Role"/* ,path:`${back}` */ } }} />

                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Title</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Role Title" {...form.getFieldProps("title")} />
                                </div>
                                {form.touched.title && form.errors.title ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.title}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Select Parent</label>
                            <div className="row g-xs">
                                <div className="wd-200 mg-b-30">
                                    <div className="input-group">
                                        {/* <select className="form-control mb-0" name="parent_id" {...form.getFieldProps("parent_id")}  onChange={(e) => handleChangeQueryBuilder(e)}> 
                                            <option>Select Parent</option>  */}
                                        <select className="form-control" name="parent_id"{...form.getFieldProps("parent_id")} >
                                            <option>Select Role</option>
                                            {dataArrays.map((e, id) => {
                                                return (
                                                    <>
                                                        <option key={id} value={e.id}>{e.title}</option>
                                                        {e?.child.map((children, id) => {
                                                            return (
                                                                <>
                                                                    <option key={id} value={children.id}>&nbsp;&nbsp; -- {children.title}</option>
                                                                    {
                                                                        children?.child.map((subchild, id) => {
                                                                            return (
                                                                                <>
                                                                                    <option key={id} value={subchild.id}>&nbsp;&nbsp;&nbsp; --- {subchild.title}</option>
                                                                                    {subchild?.child.map((superchild, id) => {
                                                                                        return (
                                                                                            <>
                                                                                                <option key={id} value={superchild.id}>&nbsp;&nbsp;&nbsp;&nbsp; ---- {superchild.title}</option>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                            )
                                                                        })}
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {form.touched.title && form.errors.title ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.title}</div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Employee
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    onChange={(e) => updateEmployeeData(e, "employee_view")} />
                                <span className="custom-control-label">View</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!EmployeeView}
                                    onChange={(e) => updateEmployeeData(e, "employee_upload_bulk")} />

                                <span className="custom-control-label">Upload Bulk</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!EmployeeView}
                                    onChange={(e) => updateEmployeeData(e, "employee_add")} />
                                <span className="custom-control-label">Add Employee</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!EmployeeView}
                                    onChange={(e) => updateEmployeeData(e, "employee_edit")} />
                                <span className="custom-control-label">Edit Employee</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!EmployeeView}
                                    onChange={(e) => updateEmployeeData(e, "employee_delete")} />
                                <span className="custom-control-label">Delete Employee</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>EWA Attendance
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "ewa_attendance_view")} />
                                <span className="custom-control-label">View</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!EWAView}
                                    onChange={(e) => updateEmployeeData(e, "ewa_mark_attendance")} />
                                <span className="custom-control-label">Mark Attendance</span>
                            </label>
                            {/* <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e,"ewa_in_out_time")}  />
                                <span className="custom-control-label">In/Out Time</span>
                            </label> */}
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!EWAView}
                                    onChange={(e) => updateEmployeeData(e, "ewa_bulk_upload_attendance")} />
                                <span className="custom-control-label">Bulk Upload Attendance</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!EWAView}
                                    onChange={(e) => updateEmployeeData(e, "ewa_view_attendance")} />
                                <span className="custom-control-label">View Attendance</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>NFC
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "nfc_view")} />
                                <span className="custom-control-label">View</span>
                            </label>

                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!NFCView}
                                    onChange={(e) => updateEmployeeData(e, "nfc_active_inactive")} />
                                <span className="custom-control-label">Active/Inactive</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Repayment
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "repayment_view_bnpl_list")} />
                                <span className="custom-control-label">View BNPL List</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!RepaymentView}
                                    onChange={(e) => updateEmployeeData(e, "repayment_pay_to_lender_bnpl")} />
                                <span className="custom-control-label">Pay to Lender(BNPL)</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!RepaymentView}
                                    onChange={(e) => updateEmployeeData(e, "repayment_view_ewa_list")} />
                                <span className="custom-control-label">View EWA List</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!RepaymentView}
                                    onChange={(e) => updateEmployeeData(e, "repayment_pay_to_lender_ewa")} />
                                <span className="custom-control-label">Pay to Lender(EWA)</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!RepaymentView}
                                    onChange={(e) => updateEmployeeData(e, "repayment_list_export")} />
                                <span className="custom-control-label">Export CSV</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Settlement
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" name="disclaimer_q2" value="true" onChange={(e) => updateEmployeeData(e, "settlement_bnpl_transaction")} />
                                <span className="custom-control-label">View BNPL Transaction</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" name="disclaimer_q2" value="true" onChange={(e) => updateEmployeeData(e, "settlement_ewa_transaction")} />
                                <span className="custom-control-label">View EWA Transaction</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" name="disclaimer_q2" value="true" onChange={(e) => updateEmployeeData(e, "settlement_transaction_export")} />
                                <span className="custom-control-label">Export CSV</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Mobile App
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" name="disclaimer_q2" value="true" onChange={(e) => updateEmployeeData(e, "face_register")} />
                                <span className="custom-control-label">Face Register</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" name="disclaimer_q2" value="true" onChange={(e) => updateEmployeeData(e, "nfc_register")} />
                                <span className="custom-control-label">NFC Register</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" name="disclaimer_q2" value="true" onChange={(e) => updateEmployeeData(e, "nfc_list")} />
                                <span className="custom-control-label">NFC List</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Add</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddParentrole
