
import { saveAs } from 'file-saver';

export const SampleTemplete = (templete, fileN) => {
    const template = templete;
    let sliceSize = 1024;
    let byteCharacters = atob(template);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    saveAs(
        new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
        fileN
    );

}