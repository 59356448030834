import { useState, useEffect } from 'react';
import { getApi } from '../service/lenderService';

const UsePermissions = () => {
    const [permissions, setPermissions] = useState({
        dashboard: true,
        borrower: true,
        payToMerchant: true,
        payToThirdroc: true,
        repayment: true,
        role_and_permission: true,
        ROI: true,
        ewa_advance: true,
        behaviour: true,
        manage_investment: true,
    });
    const [permissionDetail, setPermissionDetail] = useState([]);

    const getPermissionDetail = async () => {
        try {
            const response = await getApi('/side-bar-permissions');
            if (response.status) {
                setPermissionDetail(response?.data != null ? JSON.parse(response?.data?.permissions):[]);
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPermissionDetail();
    }, []);

    useEffect(() => {
        if (permissionDetail?.length > 0) {
            const permissionsMap = {};
            permissionDetail.forEach(permission => {
                permissionsMap[permission.key] = permission.value;
            });
            setPermissions(prevPermissions => ({
                ...prevPermissions,
                ...permissionsMap,
            }));
        }
    }, [permissionDetail]);

    return permissions;
};

export default UsePermissions;