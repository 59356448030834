import Swal from "sweetalert2"
import { deleteApi } from "../../service/employService"
import toast from "react-hot-toast"

export const HandleDeleteJobLocation = (id, GetTypeLocation) => {
    console.log(id)
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await deleteApi(`/delete-job-location/${id}`)
            if (response?.status) {
                Swal.fire(
                    'Deleted!',
                    'Location has been deleted Successfully.',
                    'success'
                )
                GetTypeLocation();
            }
            else {
                toast.error(response.message)
            }
        }
    })
}

export const HandleDeleteSubJobLocation = (subID, GetTypeLocation) => {
    // console.log(id)
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await deleteApi(`/delete-sub-job-location/${subID}`)
            if (response?.status) {
                Swal.fire(
                    'Deleted!',
                    'Sub-Location has been deleted Successfully.',
                    'success'
                )
                GetTypeLocation();
            }
            else {
                toast.error(response.message)
            }
        }
    })
}