import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { GetProfile, getApi } from '../../service/lenderService';
import { Breadcrumbs } from '../../../components';
import Loader from '../../../components/Loader/loader';

const Profile = (props) => {
  const [profileData, setprofileData] = useState({});
  const [loader, setloader] = useState(true)
  const navigate = useNavigate();
  const lender = JSON.parse(localStorage.getItem("lender"));
  const user_type = lender.user_type
  const getProfile = async (token) => {
    const response = await getApi("/get-profile");
    // const response = await GetProfile(token);
    console.log(response)
    if (response.status == true) {
      setTimeout(() => {
        setprofileData(response.data);
        setloader(false)
      }, 2000);



      // localStorage.setItem('lendermobile',lendermobile);


    } else if (response.message == 'Session expired') {
      localStorage.removeItem('lender');
      navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
    }
    else {
      console.log("get employees data response", response);
    }
  }


  useEffect(() => {
    getProfile(lender.lender_token);
  }, [])




  //   const lendernames = JSON.stringify(localStorage.getItem("lendername"));
  //   ");


  //  useEffect(() => {

  //  },[lendernames])
  // console.log(lendernames )

  // useEffect(() => {
  //   api.getApi('lender/profile').then(response => {

  //     if (response.status === true) {
  //       console.log('inside tanmay', response.data);
  //       setprofileData(response.data);
  //     }
  //   }).catch(error => {
  //     console.log('error', error);
  //   });
  // }, [])
  // console.log("checkinggggg",checking, "mobilesss",lendermobiles)
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {loader == true ? <div style={{ marginLeft: '400px', marginTop: '200px' }}><Loader /></div> :
        <div className="main-container container-fluid px-0">
          <div className="page-header mb-3">
            <div className="page-leftheader">
              {/* <h4 className="page-title mb-0 text-primary">Lender Profile</h4> */}
              {user_type == "lenders" ? <Breadcrumbs order={{ first: { name: "Lender Profile" } }} /> :
                <Breadcrumbs order={{ first: { name: "Sub-Lender Profile" } }} />}
            </div>
            <div className="page-rightheader">
              <div className="btn-list">
                <Link to={props.prefix + '/editprofile/' + profileData.user_id} className="btn btn-outline-primary" ><i className="fa-solid fa-user-pen fa-fw me-2"></i> Edit Profile</Link>
                <Link to={props.prefix + '/changepassword/' + profileData.user_id} className="btn btn-primary btn-pill" ><i className="fa-regular fa-pen-to-square fa-fw me-2"></i> Change Password</Link>
              </div>
            </div>
          </div>
          <div className="main-proifle">
            <div className="row">
              <div className="col-lg-12 col-xl-12 p-0">
                <div className="box-widget widget-user">
                  <div className="widget-user-image1 d-xl-flex d-block flexwrap">
                    <div className="col-md-12">
                      <p className="mb-0"><small className="text-muted">Authorized Person Name: {profileData?.file_id}</small></p>
                      <h4 className="pro-user-username mb-3 font-weight-bold">{profileData?.full_name}</h4>

                      {/* <h4 className="pro-user-username mb-3 font-weight-bold">{profileData.user_name}</h4> */}
                      <div className="row">
                        <div className="media mb-5 col-md-4">
                          <div className="media-icon bg-info-transparent text-info me-3"> <i className="fa-regular fa-building fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Company Name:</small>
                            <div className="font-weight-normal1">{profileData.company_name}</div>
                          </div>
                        </div>
                        {/* <div className="media mb-5 col-md-4">
                          <div className="media-icon bg-info-transparent text-info me-3"> <i className="fa-regular fa-building fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Full Name:</small>
                            <div className="font-weight-normal1">{profileData.full_name}</div>
                          </div>
                        </div> */}
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-success-transparent text-success me-3"> <i className="fa-solid fa-mobile-screen-button fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Mobile No:</small>
                            <div className="font-weight-normal1">{profileData.mobile_number}</div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-primary-transparent text-primary me-3"> <i className="fa-regular fa-envelope fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Country:</small>
                            <div className="font-weight-normal1">{profileData.country}</div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-danger-transparent text-danger me-3"> <i className="fa-solid fa-globe fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted" >Website:</small>
                            <div className="font-weight-normal1" style={{ textTransform: 'none' }}>{profileData.website}</div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-danger-transparent text-danger me-3"> <i className="fa-solid fa-globe fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Email:</small>
                            <div className="font-weight-normal1" style={{ textTransform: 'none' }}>{profileData.email}</div>
                          </div>
                        </div>
                        <div className="media mb-5 col-md-4">
                          <div className="media-icon bg-warning-transparent text-warning me-3"> <i className="fa-regular fa-address-card fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">GST No:</small>
                            <div className="font-weight-normal1">{profileData.gst_number}</div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-cyan-transparent text-cyan me-3"> <i className="fa-solid fa-location-dot fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Address:</small>
                            <div className="font-weight-normal1">{profileData.address}</div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-cyan-transparent text-cyan me-3"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Commission:</small>
                            <div className="font-weight-normal1">{profileData?.commission ? profileData?.commission : "-"} <i className="fa-solid fa-percent fa-fw"></i> </div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-cyan-transparent text-cyan me-3"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Minimum Rate Of Interest</small>
                            <div className="font-weight-normal1">{profileData?.mini_rate_of_intrest ? profileData?.mini_rate_of_intrest + "%" : "-"}  </div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-cyan-transparent text-cyan me-3"> <i className="fa-solid fa-percent fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Maximum Rate Of Interest:</small>
                            <div className="font-weight-normal1">{profileData?.max_rate_of_intrest ? profileData?.max_rate_of_intrest + "%" : "-"} </div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-cyan-transparent text-cyan me-3"> <i className="fa fa-dollar"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Minimum EWA Advance Range</small>
                            <div className="font-weight-normal1">{profileData?.mini_loan_range ? "$" + profileData?.mini_loan_range : "-"}  </div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-cyan-transparent text-cyan me-3"> <i className="fa fa-dollar"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Maximum EWA Advance Range</small>
                            <div className="font-weight-normal1">{profileData?.max_loan_range ? "$" + profileData?.max_loan_range : "-"} </div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-cyan-transparent text-cyan me-3"> <i className="fa-solid fa-calendar fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Minimum Tenure (Months)</small>
                            <div className="font-weight-normal1">{profileData?.mini_tenure ? profileData?.mini_tenure > 1 ? profileData?.mini_tenure + " Months" : profileData?.mini_tenure + " Month" : "-"}  </div>
                          </div>
                        </div>
                        <div className="media  mb-5 col-md-4">
                          <div className="media-icon bg-cyan-transparent text-cyan me-3"> <i className="fa-solid fa-calendar fa-fw"></i> </div>
                          <div className="media-body">
                            <small className="text-muted">Maximum Tenure (Months)</small>
                            <div className="font-weight-normal1">{profileData?.max_tenure ? profileData?.max_tenure + " Months" : '-'}  </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-12 p-0">
                <div className="box-widget widget-user">
                  <div className="widget-user-image1 d-xl-flex d-block flexwrap">
                    <div className="col-md-12">
                      <h4 className="pro-user-username mb-3 font-weight-bold"> {user_type == "lenders" ? "Lender Logo" : "Sub-Lender Logo"}</h4>
                      <div className="row">
                        <div className="media col-md-4">
                          <div className="media-body">
                            {profileData.logo ?
                              <img src={'https://thirdroc1.s3.ap-southeast-2.amazonaws.com/logo/' + profileData.logo} alt="no document here" style={{ height: "230px", width: "230px", objectFit: "contain" }} />
                              :
                              <img src={process.env.PUBLIC_URL + '/assets/img/upload-logo-lender.png'} style={{ height: "230px", width: "230px", objectFit: "contain" }} />

                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Profile;
