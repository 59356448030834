import Header  from '../website/header';
import Footer  from '../website/footer';
const PlateForm = () => {


    return (
       <>
	    <Header/>
        <section className="section-padding section-bg">
	   <div className="container">
		  <div className="row align-items-center">
			 <div className="col-lg-6 margin-md-60">
				<h2>Thirdroc’s cloud based platform allows multiple use cases to be handled securely &amp; with ease.</h2>
				<p>All the involved stakeholders derive benefits transparently. Our platform connects Borrowers, Lenders, Merchants &amp; Enterprises via a secure dataflow pipeline  with a combination of Web apps &amp; Mobile apps hosted on cloud infrastructure. </p>
			 </div>
			 <div className="col-lg-5 offset-lg-1"><img src="website/assets/img/platform-cloud.jpg" alt="" className="bordermg" /></div>
		  </div>
	   </div>
	</section>
	
	
	<section className="content-block content--block3 section-padding">
	   <div className="container">
		  <div className="row align-items-center justify-content-center">
			 <div className="col-lg-5 margin-md-60"><img src="website/assets/img/Learning-And-Machine-Learning.svg" alt="Learning-And-Machine-Learning" /></div>
			 <div className="col-lg-6 offset-lg-1">
				<h4>Machine Learning Algorithms</h4>
				<p>The platform is powered by highly trained Machine Learning Algorithms that help with credit analysis, generating probabilities, generating scores for borrowers in a non-subjective manner.</p>
			 </div>
			 <div className="col-lg-12">
				<hr className="hr" />
			 </div>
			 <div className="col-lg-6 margin-md-60">
				<h4>BNPL (Buy Now Pay Later)</h4>
				<p>It allows us to offer BNPL (Buy Now Pay Later), API based &amp; UI based (Digital Automation) services securely with ease &amp; in quick deployment time.</p>
			 </div>
			 <div className="col-lg-5 offset-lg-1"><img src="website/assets/img/Basiq-Payments.svg" alt="Basiq-Payments"/></div>
		  </div>
	   </div>
	</section>
	<Footer/>
       </>
    )
}

export default PlateForm;