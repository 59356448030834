import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Breadcrumbsecond } from '../../components';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import { GetCountry } from '../../employer/service/employService';
import * as yup from 'yup';
import { EditMerchantDetail, postApi } from '../service/adminService';

const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const EditMerchant = () => {
    const [country, setCountry] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const ids = useLocation();
    const admin = JSON.parse(localStorage.getItem("admin"));
    const token = admin.admin_token
    const navigate = useNavigate();
    // console.log(ids)
    const profileData = ids.state
    // console.log(profileData)
    const [image, setImage] = useState(profileData?.logo)
    const urlR = "^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$";
    const urlR1 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = /[A-Za-z]/;

    const getCountry = async () => {
        const response = await GetCountry();
        if (response.status == true) {
            setCountry(response.data);
        } else {
            console.log("get Country data response", response);
        }
    }

    useEffect(() => {
        getCountry();
    }, [])
    const imageChange = (e) => {
        setImage(e.target.files[0])
        setSelectedImage(e.target.files[0])
        form.setFieldValue('logo', e.target.files[0].name)
    };

    useEffect(() => {

    }, [profileData])

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const form = useFormik({
        initialValues: {
            bank_id: profileData?.bank_id,
            company_name: profileData?.company_name,
            full_name: profileData?.full_name,
            country: profileData?.country,
            mobile_number: profileData?.mobile_number,
            address: profileData?.address,
            website: profileData?.website,
            email: profileData?.email,
            gst_number: profileData?.gst_number,
            account_name: profileData?.account_name,
            account_number: profileData?.account_number,
            bank_name: profileData?.bank_name,
            branch_name: profileData?.branch_name,
            bsb: profileData?.bsb,
            logo: image?.name,
            logo_true: "",
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            company_name: yup.string().required('Please Enter Company Name'),
            full_name: yup.string().required('Please Enter Your Name'),
            country: yup.string().required('Please Select Country'),
            mobile_number: yup.string().required('Please enter mobile number').min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Contain Max Upto 15 Number").matches(phoneRegExp, 'Please enter only number values'),
            address: yup.string().required('Please Enter Address'),
            website: yup.string().required('Please Enter website').matches(urlR, 'Please Enter Valid Website Url'),
            email: yup.string().required('Please Enter Email').matches(urlR1, 'Please Enter Valid Email ID'),
            //   mobile_number: yup.number().typeError('please enter a valid number').required('Please enter mobile number').positive(),
            gst_number: yup.string().required('Please Enter Company Registration/Tax ID'),
            account_name: yup.string().required('Please Enter Account Holder Name'),
            account_number: yup.string().required('Please Enter Bank Account Number').min(9, "Account Number Must Contain 9 Number").max(18, `Account Number Max upto 18 Characters`),
            bank_name: yup.string().required('Please Enter Bank Name'),
            branch_name: yup.string().required('Please Enter Branch Name'),
            bsb: yup.string().required('Please Enter Bank State Branch'),
        }),
        onSubmit: async values => {
            const formData = new FormData();
            formData.append("bank_id", profileData.bank_id);
            formData.append("company_name", form.getFieldProps('company_name').value);
            formData.append("full_name", form.getFieldProps('full_name').value);
            formData.append("country", form.getFieldProps('country').value);
            formData.append("mobile_number", form.getFieldProps('mobile_number').value);
            formData.append("address", form.getFieldProps('address').value);
            formData.append("website", form.getFieldProps('website').value);
            formData.append("email", form.getFieldProps('email').value);
            formData.append("gst_number", form.getFieldProps('gst_number').value);
            formData.append("account_name", form.getFieldProps('account_name').value);
            formData.append("account_number", form.getFieldProps('account_number').value);
            formData.append("bank_name", form.getFieldProps('bank_name').value);
            formData.append("branch_name", form.getFieldProps('branch_name').value);
            formData.append("bsb", form.getFieldProps('bsb').value);
            formData.append("logo", image);

            // const response = await EditMerchantDetail(token, formData, profileData.user_id)
            const response = await postApi(`/edit-merchant/${profileData.user_id}`, formData, true)

            if (response.status === true) {
                toast.success(response.message);
                navigate(prefix + '/merchant');
            } else {
                console.log('error', response);
                toast.error(response);
            }
        }
    });
    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">
                        <Breadcrumbsecond order={{ first: { name: "Merchant", path: `${prefix}/merchant` }, second: { name: "Update Merchant's Profile" } }} />
                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Company Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Merchant Name" {...form.getFieldProps("company_name")} />
                                </div>
                                {form.touched.company_name && form.errors.company_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.company_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Name Of Contact Person</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Name" {...form.getFieldProps("full_name")} />
                                </div>
                                {form.touched.full_name && form.errors.full_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Country</label>
                            <div className="row g-xs">
                                <div className="input-group">

                                    <select className="form-control" name="country" {...form.getFieldProps("country")}>
                                        <option value="">Select Country</option>
                                        {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                                    </select>
                                </div>
                                {form.touched.country && form.errors.country ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.country}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Mobile Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="number" className="form-control" placeholder="Enter Mobile Number" {...form.getFieldProps("mobile_number")} />
                                </div>
                                {form.touched.mobile_number && form.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Address</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Address" {...form.getFieldProps("address")} />
                                </div>
                                {form.touched.address && form.errors.address ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.address}</div> : ''}
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Website</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Website" {...form.getFieldProps("website")} />
                                </div>
                                {form.touched.website && form.errors.website ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.website}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder="Enter Email" {...form.getFieldProps("email")} />
                                </div>
                                {form.touched.email && form.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Company Registration/Tax ID</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" name="gst_number" {...form.getFieldProps("gst_number")} className="form-control" placeholder="ID (Company Registration/Tax ID)" />
                                    {form.touched.gst_number && form.errors.gst_number ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.gst_number}</div> : ''}
                                </div>
                                {form.touched.gst_number && form.errors.gst_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.gst_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Account Holder Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="text" name="account_name" {...form.getFieldProps("account_name")} placeholder="Account Holder Name" id="password-field" />

                                </div>
                                {form.touched.account_name && form.errors.account_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.account_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Bank Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="text" name="bank_name" {...form.getFieldProps("bank_name")} placeholder="Bank Name" id="password-field" />

                                </div>
                                {form.touched.bank_name && form.errors.bank_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.bank_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Branch Name</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="text" name="branch_name" {...form.getFieldProps("branch_name")} placeholder="Branch Name" id="password-field" />

                                </div>
                                {form.touched.branch_name && form.errors.branch_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.branch_name}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Bank-State-Branch</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="text" name="bsb" {...form.getFieldProps("bsb")} placeholder="Bank-State-Branch" id="password-field" />

                                </div>
                                {form.touched.bsb && form.errors.bsb ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.bsb}</div> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Account Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="number" name="account_number" {...form.getFieldProps("account_number")} placeholder="Account Number" id="password-field" />

                                </div>
                                {form.touched.account_number && form.errors.account_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.account_number}</div> : ''}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Confirm Account Number</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input className="form-control" type="number" name="confirm_account_number" {...form.getFieldProps("confirm_account_number")} placeholder="Confirm Account Number" id="password-field" />

                                </div>
                                {form.touched.confirm_account_number && form.errors.confirm_account_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirm_account_number}</div> : ''}
                            </div>
                        </div>
                    </div> */}
                    <div className="input-group file-browser mb-0 widthHundred">
                        <input type="text" className="form-control browse-file" placeholder="Upload your logo" />
                        <label className="input-group-text btn btn-primary"> Browse   {/* <p onChange={imageChange}> */}<input type="file" className="file-browserinput" /* filename={image} */ onChange={(e) => imageChange(e)} style={{ display: 'none' }} multiple /> </label>

                    </div>
                    {!selectedImage ?
                        <img src={image ? image : process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt="/" height={150} style={{ width: 'auto' }} /> :
                        <div >
                            <img

                                src={URL.createObjectURL(selectedImage)}
                                /* src={process.env.REACT_APP_S3_BUCKET_URL +"logo/" + selectedImage.name} */ height={150} style={{ width: 'auto' }}
                                alt="..."
                            /></div>}

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Save Changes</button>
                    </div>


                </div>
            </form>

        </div>
    )
}

export default EditMerchant
