import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { EditUserrole, getApi, postApi } from '../../service/employService';
import { Breadcrumbsecond } from '../../../components';

const Editparentrole = () => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const navigate = useNavigate()
    const employer = JSON.parse(localStorage.getItem("employer"))
    const token = employer.employer_token
    const ids = useLocation();
    const [employeeData, setEmployeeData] = useState([]);
    const [dataArray, setDataArray] = useState([]);
    // const permissions = JSON.parse(ids.state.permissions);
    console.log(ids)
    const title = ids?.state?.title
    const parent_id = ids?.state.parent_data?.id
    const role_id = ids?.state?.id
    const role = ids.state?.permissions;

    const getRoleandchild = async (token) => {
        // const response = await GetRoleandchild(token);
        const response = await getApi("/role-and-child-role");

        try {
            if (response.status == true) {
                setDataArray(response.data.rows);
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // setpagesCount(1);
        getRoleandchild(employer.employer_token);
    }, []);
    if (role) {
        var permissions = JSON.parse(role);

    } else {

    }
    if (permissions.constructor.name == "Object") {
        let emptarray = []
        for (let i in permissions) {
            emptarray.push(permissions[i])
        }
        permissions = emptarray
    }
    console.log(permissions)
    const updateEmployeeData = (e, key) => {
        const checkboxValue = e.target.value;
        const isChecked = e.target.checked;

        if (isChecked) {
            setEmployeeData((prevData) => [...prevData, { value: checkboxValue, key }]);
        } else {
            setEmployeeData((prevData) => prevData.filter((item) => {/* console.log({item},item.key==key , item.value ==Boolean(isChecked) ); */ return (item.key != key) }));
        }
    };

    const [roles, setroles] = useState({})
    useEffect(() => {
        permissions.length > 0 && permissions?.map((options) => {
            console.log(options, "options")
            if (options.key == "employee_view") {
                roles.employee_view = true;
            }
            if (options.key == "employee_upload_bulk") {
                roles.employee_upload_bulk = true;
            }
            if (options.key == "employee_add") {
                roles.employee_add = true;
            }
            if (options.key == "employee_edit") {
                roles.employee_edit = true;
            }
            if (options.key == "employee_delete") {
                roles.employee_delete = true;
            }
            if (options.key == "ewa_attendance_view") {
                roles.ewa_attendance_view = true;
            }
            if (options.key == "ewa_mark_attendance") {
                roles.ewa_mark_attendance = true;
            }
            // if (options.key == "ewa_in_out_time") {
            //     roles.ewa_in_out_time=true;
            // }
            if (options.key == "ewa_bulk_upload_attendance") {
                roles.ewa_bulk_upload_attendance = true;
            }
            if (options.key == "ewa_view_attendance") {
                roles.ewa_view_attendance = true;
            }
            if (options.key == "nfc_view") {
                roles.nfc_view = true;
            }
            if (options.key == "nfc_active_inactive") {
                roles.nfc_active_inactive = true;
            }
            if (options.key == "repayment_view_bnpl_list") {
                roles.repayment_view_bnpl_list = true;
            }
            if (options.key == "repayment_pay_to_lender_bnpl") {
                roles.repayment_pay_to_lender_bnpl = true;
            }
            if (options.key == "repayment_view_ewa_list") {
                roles.repayment_view_ewa_list = true;
            }
            if (options.key == "repayment_pay_to_lender_ewa") {
                roles.repayment_pay_to_lender_ewa = true;
            }
            if (options.key == "repayment_list_export") {
                roles.repayment_list_export = true;
            }
            if (options.key == "settlement_bnpl_transaction") {
                roles.settlement_bnpl_transaction = true;
            }
            if (options.key == "settlement_ewa_transaction") {
                roles.settlement_ewa_transaction = true;
            }
            if (options.key == "settlement_transaction_export") {
                roles.settlement_transaction_export = true;
            }
            if (options.key == "user_management_add_employee") {
                roles.user_management_add_employee = true;
            }
            if (options.key == "face_register") {
                roles.face_register = true;
            }
            if (options.key == "nfc_register") {
                roles.nfc_register = true;
            }
            if (options.key == "nfc_list") {
                roles.nfc_list = true;
            }
        })
        setroles(roles)
    }, [permissions])

    useEffect(() => {
        let array = []
        for (let i in roles) {
            if (roles[`${i}`] == true) {
                array.push({ value: true, key: i })
            }
        }
        setEmployeeData([...array]);


    }, [roles])

    const form = useFormik({

        initialValues: {
            role_id: role_id,
            title: title,
            parent_id: parent_id,
            permission_key: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Please Enter Role Title'),

        }),
        onSubmit: async (values) => {

            // const data = JSON.stringify({
            //     role_id: role_id,
            //     title: values.title,
            //     parent_id: values.parent_id,
            //     permissions: employeeData
            // })

            // const response = await EditUserrole(token, data)
            const response = await postApi("/update-role-permission", {
                role_id: role_id,
                title: values.title,
                parent_id: values.parent_id,
                permissions: employeeData
            })

            if (response.status == true) {
                toast.success(response.message)
                setTimeout(() => {
                    navigate(prefix + '/manage_role')
                }, 1000)

            } else if (response.status == false) {
                toast.error(response.message)
            }
        }
    })

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">
                        {/* <Breadcrumbs order={{ first: { name: "User Management" } }} /> */}
                        <Breadcrumbsecond order={{ first: { name: "Manage Role", path: `${prefix}/manage_role` }, second: { name: "Edit Role"/* ,path:`${back}` */ } }} />

                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Title</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Role Title"  /* defaultValue={titles} */
                                        {...form.getFieldProps("title")}  /* onClick={() => setTitle()} */ />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Select Parent</label>
                            <div className="row g-xs">
                                <div className="wd-200 mg-b-30">
                                    <div className="input-group">
                                        <select className="form-control mb-0" name="parent_id" {...form.getFieldProps("parent_id")} /* onChange={(e) => handleChangeQueryBuilder(e)} */>
                                            <option value="">Select Parent</option>
                                            {dataArray.map((e, id) => {
                                                return (
                                                    <>
                                                        <option key={id} value={e.id}>{e.title}</option>
                                                        {e?.child.map((children, id) => {
                                                            return (
                                                                <>
                                                                    <option key={id} value={children.id}>&nbsp;&nbsp; -- {children.title}</option>
                                                                    {
                                                                        children?.child.map((subchild, id) => {
                                                                            return (
                                                                                <>
                                                                                    <option key={id} value={subchild.id}>&nbsp;&nbsp;&nbsp; --- {subchild.title}</option>
                                                                                    {subchild?.child.map((superchild, id) => {
                                                                                        return (
                                                                                            <>
                                                                                                <option key={id} value={superchild.id}>&nbsp;&nbsp;&nbsp;&nbsp; ---- {superchild.title}</option>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                            )
                                                                        })}

                                                                </>
                                                            )
                                                        })}

                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {form.touched.title && form.errors.title ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.title}</div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Employee  </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.employee_view ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "employee_view")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "employee_view")} />}
                                <span className="custom-control-label">View</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.employee_upload_bulk ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "employee_upload_bulk")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "employee_upload_bulk")} />}
                                <span className="custom-control-label">Upload Bulk</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.employee_add ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "employee_add")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "employee_add")} />}
                                <span className="custom-control-label">Add Employee</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.employee_edit ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "employee_edit")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "employee_edit")} />}
                                <span className="custom-control-label">Edit Employee</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.employee_delete ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "employee_delete")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "employee_delete")} />}
                                <span className="custom-control-label">Delete Employee</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>EWA Attendance
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.ewa_attendance_view ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "ewa_attendance_view")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "ewa_attendance_view")} />}
                                <span className="custom-control-label">View</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.ewa_mark_attendance ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "ewa_mark_attendance")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "ewa_mark_attendance")} />}
                                <span className="custom-control-label">Mark Attendance</span>
                            </label>
                            {/* <label className="custom-control custom-checkbox diclamerpage">
                                { roles.ewa_in_out_time ? <input type="checkbox" defaultChecked={true } 
                                className="custom-control-input NewCHeckBx" value="true" 
                                onClick={(e) => updateEmployeeData(e, "ewa_in_out_time")} /> :
                                <input type="checkbox"  className="custom-control-input NewCHeckBx" value="true" 
                                onClick={(e) => updateEmployeeData(e, "ewa_in_out_time")} /> }
                                <span className="custom-control-label">In/Out Time</span>
                            </label> */}
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.ewa_bulk_upload_attendance ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "ewa_bulk_upload_attendance")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "ewa_bulk_upload_attendance")} />}
                                <span className="custom-control-label">Bulk Upload Attendance</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.ewa_view_attendance ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "ewa_view_attendance")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "ewa_view_attendance")} />}
                                <span className="custom-control-label">View Attendance</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>NFC
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.nfc_view ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "nfc_view")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "nfc_view")} />}
                                <span className="custom-control-label">View</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.nfc_active_inactive ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "nfc_active_inactive")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "nfc_active_inactive")} />}
                                <span className="custom-control-label">Active/Inactive</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Repayment
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.repayment_view_bnpl_list ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "repayment_view_bnpl_list")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "repayment_view_bnpl_list")} />}
                                <span className="custom-control-label">View BNPL List</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.repayment_pay_to_lender_bnpl ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "repayment_pay_to_lender_bnpl")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "repayment_pay_to_lender_bnpl")} />}
                                <span className="custom-control-label">Pay to Lender(BNPL)</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.repayment_view_ewa_list ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "repayment_view_ewa_list")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "repayment_view_ewa_list")} />}

                                <span className="custom-control-label">View EWA List</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.repayment_pay_to_lender_ewa ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "repayment_pay_to_lender_ewa")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "repayment_pay_to_lender_ewa")} />}
                                <span className="custom-control-label">Pay to Lender(EWA)</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.repayment_list_export ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "repayment_list_export")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "repayment_list_export")} />}
                                <span className="custom-control-label">Export CSV</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Settlement
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.settlement_bnpl_transaction ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "settlement_bnpl_transaction")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "settlement_bnpl_transaction")} />}
                                <span className="custom-control-label">View BNPL Transaction</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.settlement_ewa_transaction ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "settlement_ewa_transaction")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "settlement_ewa_transaction")} />}
                                <span className="custom-control-label">View EWA Transaction</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.settlement_transaction_export ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "settlement_transaction_export")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "settlement_transaction_export")} />}
                                <span className="custom-control-label">Export CSV</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Mobile App
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.face_register ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "face_register")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "face_register")} />}
                                <span className="custom-control-label">Face Register</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.nfc_register ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "nfc_register")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "nfc_register")} />}
                                <span className="custom-control-label">NFC Register</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                {roles.nfc_list ? <input type="checkbox" defaultChecked={true}
                                    className="custom-control-input NewCHeckBx" value="true"
                                    onClick={(e) => updateEmployeeData(e, "nfc_list")} /> :
                                    <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                        onClick={(e) => updateEmployeeData(e, "nfc_list")} />}
                                <span className="custom-control-label">NFC List</span>
                            </label>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Add</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Editparentrole
