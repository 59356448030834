import React, { useEffect, useRef, useState } from 'react'
import PaginationComponent from '../../PaginationComponent';
import Loader from '../../components/Loader/loader';
import Search from '../../components/Search/Search';
import { getApi, postApi } from '../service/adminService';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';


const Reconciliation = () => {
    const [dataArray , setDataArray] = useState([])
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [loader, setloader] = useState(true)
    const [ValidPagination, setValidPagination] = useState(false)
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [lenderDetail , setlenderDetail] = useState([])
    const [selectedLenders, setSelectedLenders] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [totalPaidAmount , settotalPaidAmount] = useState(0)
    const [totalRemainingAmount , settotalRemainingAmount] = useState(0)

const viewReconciliationDetail = async() => {
    try {
        const response = await getApi('/ewa-advance-reconciliation', {lender_id: selectedLenders, search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
            if(response.status){
                setTimeout(() => {
                    setDataArray(response?.data?.rows);
                    setTotalPagesCount(response?.data?.count)
                    settotalPaidAmount(response?.data?.totalPaidAmount)
                    settotalRemainingAmount(response?.data?.totalRemainingAmount)
                    setloader(false)
                }, 2000);
    
                setloader(true)
            }else{
                console.log(response.message)
                toast.error(response.message)
            }
        
    } catch (error) {
        console.log(error)
    }
    
}
useEffect(() => {
    viewReconciliationDetail()
},[search, pagesCount,sortedBy,orderBy,selectedLenders])

const getLenderDetail = async () => {
    const response = await getApi('/get-lender-list');
    if (response.status) {
        setlenderDetail(response.data);

    } else {
        console.log("get employees data response", response);
    }
}

useEffect(() => {
    getLenderDetail();
}, []);

const sortChange = (col) => {
    if (col === sortedBy) {
        setSortedBy(col);
        orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
    } else {
        setSortedBy(col)
        setOrderBy("desc")
    }
}

    const handleCheckboxChange = (lenderId) => {
        setSelectedLenders((prevSelected) => {
            const currentSelected = prevSelected?.split(',').map(id => id.trim()).filter(Boolean); // Convert string to array
            if (currentSelected.includes(lenderId.toString())) {
                // Remove lenderId and convert back to string
                return currentSelected?.filter(id => id !== lenderId?.toString()).join(',');
            } else {
                // Add lenderId and convert back to string
                return [...currentSelected, lenderId].join(',');
            }
        });
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const dropdownMenuStyle = {
        position: 'absolute',
        zIndex: 1000,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '10px',
        maxHeight: '200px', // Set a maximum height for the dropdown
        overflowY: 'auto', // Enable vertical scrolling
        display: isOpen ? 'block' : 'none', // Show or hide based on isOpen
    };

    const dropdownContentStyle = {
        maxHeight: '150px', // Set the height limit for the content
        overflowY: 'auto', // Enable scrolling if content exceeds the height
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


  return (
    <div className="main-container container-fluid px-0">
            <div className="page-header mb-3">
                <div className="page-leftheader">
                    {/* <h4 className="page-title mb-0 text-primary">Paid</h4> */}

                    <h4 className="page-title mb-0 text-primary ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                {/* <li className="breadcrumb-item"><a>Repayments</a></li> */}
                                <li className="breadcrumb-item active" aria-current="page">Reconciliation</li>
                            </ol>
                        </nav>
                    </h4>

                </div>
                <div className="page-rightheader">


                    <div className="btn-list">
                    </div>
                </div>
            </div>
            <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill=""> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1"> Received Amount</p>
                                        <h4 className="mb-1 font-weight-bold text-success">
                                            {typeof totalPaidAmount == 'string'
                                                ? "$" + parseFloat(totalPaidAmount).toFixed(2)
                                                : typeof totalPaidAmount === 'number'
                                                    ? "$" + totalPaidAmount.toFixed(2)
                                                    : "0"}
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Remaining Amount</p>
                                        <h4 className="mb-1 font-weight-bold text-warning">
                                            {typeof totalRemainingAmount == 'string'
                                                ? "$" + parseFloat(totalRemainingAmount).toFixed(2)
                                                : typeof totalRemainingAmount === 'number'
                                                    ? "$" + totalRemainingAmount.toFixed(2)
                                                    : "0"}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                {/* <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Earning</p>
                                        <h4 className="mb-1 font-weight-bold">{typeof totalItems?.investedAmount === 'string'
                                            ? "$" + parseFloat(totalItems.investedAmount).toFixed(2)
                                            : typeof totalItems?.investedAmount === 'number'
                                                ? "$" + totalItems.investedAmount.toFixed(2)
                                                : "0"}
                                        </h4>
                                    </div>
                                </div> */}
                            </div>
                        
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12" >
       <div className="dropdown" style={{ position: 'relative' }}  ref={dropdownRef}>
           <label>Search by Lender</label>
           <input
               type="text"
               className="form-control"
               placeholder="Select Lenders"
               onClick={toggleDropdown}
               style={{ cursor: 'pointer' }}
               readOnly
           />
           {isOpen && (
               <div style={dropdownMenuStyle}>
                   <div style={dropdownContentStyle}>
                       {lenderDetail.length > 0 ? (
                           lenderDetail.map(item => (
                               <div key={item.id} className="form-check">
                                   <input
                                       type="checkbox"
                                       id={`lender-${item.user_id}`}
                                       className="form-check-input"
                                       checked={selectedLenders?.split(',').map(id => id?.trim()).includes(item?.user_id?.toString())}
                                       
                                       onChange={() => handleCheckboxChange(item.user_id)}
                                   />
                                   {/* <label className="form-check-label" htmlFor={`lender-${item.id}`} aria-disabled>
                                       {item.company_name ? item.company_name : "NA"} -- {item.mobile_number ? item.mobile_number : ""}
                                   </label> */}
                                   <label className="form-check-label" htmlFor={`lender-${item.id}`}>
                                       {item.company_name ? item.company_name : "NA"} -- {item.mobile_number ? item.mobile_number : ""}
                                   </label>
                               </div>
                           ))
                       ) : (
                           <div className='text-bold'>No Lender Available</div>
                       )}
                   </div>
               </div>
           )}
       {/* </div> */}
   </div>
                        </div>
                        <Search 
           search={search} 
           setSearch={setSearch} 
           setpagesCount={setpagesCount} 
           setValidPagination={setValidPagination} 
           ValidPagination={ValidPagination} 
       />
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" ></div>
                        </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card visiableCard">
                        <div className="card-body p-0 visiableCard">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" /* ref={tableRef} */>
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Loan ID</th>
                                            <th className="font-weight-bold">Customer Details  {/* {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }}> </i>} */} </th>
                                            <th className="font-weight-bold">Lender Detail</th>
                                            <th className="font-weight-bold">Actual Amount</th>
                                            <th className="font-weight-bold">EWA Advance Amount {/* {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>}  */}</th>
                                            <th className="font-weight-bold">Amount Paid {/* {sortedBy == "txn_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }}> </i>}  */}</th>
                                            <th className="font-weight-bold">Amount Outstanding  {/* {sortedBy == "due_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }}> </i>} */} </th>
                                            <th className="font-weight-bold">Pending Repayment  {/* {sortedBy == "late_days" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('late_days') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('late_days') }}> </i>} */} </th>
                                        </tr>
                                    </thead>
                                    { loader ==  true ? <tbody>
                                <td /><td /> <td /><td />
                                <Loader />
                            </tbody> :
                                <>
                                    <tbody>
                                        {dataArray?.length > 0 && dataArray?.map((option, index) => {
                                            const { first_name, last_name, mobile_number, total_paid_amount, total_remaining_amount, pending_repayment_count, upcoming_repayment_date , last_repayment_date,currency } = option;
                                            const symbol = option?.loanDisbursedData?.currency == "INR" ? "₹" : "$";
                                            return (
                                        <tr key={index} >
                                           <td><span className="font-weight-normal1"> {option?.user_loan_id} </span></td>
                                            <td><Link className="text-primary" ><span className="font-weight-normal1">{option?.first_name ? first_name : ""} {option?.last_name ? last_name : ""}<br />{option?.mobile_number ? mobile_number : ""}</span></Link></td>
                                            <td> <Link to="" /* onClick={() => getDetail(lender_id)} */
                                                                                /* data-bs-target="#applicaiton-report" data-bs-toggle="modal"  */>
                                                                                <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>
                                                                                    {option.lenderData?.company_name ? option.lenderData?.company_name : "--"} <br />
                                                                                    {option.lenderData?.full_name ? option.lenderData?.full_name : ""}<br />
                                                                                    {option.lenderData?.mobile_number ? option.lenderData?.mobile_number : ""}</span></Link></td>
                                            <td><span className="font-weight-normal1"> {option?.loanDisbursedData?.amount ? symbol  + option?.loanDisbursedData?.amount : "--"} </span></td>

                                            <td><span className="font-weight-normal1"> {option?.loanDisbursedData?.total_repayment_amount ? symbol + option?.loanDisbursedData?.total_repayment_amount : "--"} </span></td>
                                            <td><span className="font-weight-normal1"> {option?.total_paid_amount ? symbol  + total_paid_amount : "--"}</span></td>
                                            <td><span className="font-weight-normal1"> {option?.total_remaining_amount ? symbol  + total_remaining_amount : "--"} </span></td>
                                            <td className="topToBottom"><span className="font-weight-normal1">  Total Repayent Count: {option?.loanDisbursedData?.total_repayment_number ? option?.loanDisbursedData?.total_repayment_number : "--"}
                                            <br/>No. of Pending repayment: {option?.pending_repayment_count ? pending_repayment_count : "--"} <br />
                                                Date of Upcoming Repayment: {option?.upcoming_repayment_date ? upcoming_repayment_date : "--"}<br />
                                                Cycle: {option?.loanDisbursedData?.repayment_type ? option?.loanDisbursedData?.repayment_type : "--"}<br />
                                                Repayment Amount: {option?.loanDisbursedData?.repayment_amount ? symbol + option?.loanDisbursedData?.repayment_amount : "--"}<br />
                                                Last Repayment Date: {option?.last_repayment_date ? last_repayment_date : "--"}<br />
                                                {/* <button className='btn btn-primary'>View More</button> */}

                                            </span>
                                            <div className="dropdown">
                                                    <button className="btn btn-primary dropdown-toggle btn-sm"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        aria-expanded="false">View More</button>
                                                    <table
                                                        className="dropdown-menu"
                                                        aria-labelledby="dropdownMenuButton"
                                                        style={{
                                                            overflowY: 'scroll',
                                                            maxHeight: '160px',
                                                            marginTop: '20px'
                                                        }}
                                                    > <thead>
                                                            <tr >
                                                                <th>Dates</th>
                                                                <th>Amount</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {option?.loanEmiData?.length > 0 && option?.loanEmiData?.sort((a, b) => new Date(a.due_date) - new Date(b.due_date)).map((Items, index) => {
                                            const {due_date ,emi_number,amount, status } = Items;
                                            const dueDate = new Date(due_date);
                                            const today = new Date();                                        
                                            let backgroundColor = "white"; 
                                            let handleStatus = "false"
                                            if (status == "PENDING") {
                                                if (dueDate < today) {
                                                    backgroundColor = "#d95b5b"; 
                                                    handleStatus = 'true'
                                                } else {
                                                    backgroundColor = "#efef58"; 
                                                }
                                            }
                                            return (
                                                <tr key={index} style={{ backgroundColor }}>
                                                                <td>{Items?.due_date ? due_date :"--"}</td>
                                                                <td>{Items?.amount ? symbol + amount : "--"}</td>
                                                                <td>{Items?.status  ?  handleStatus == "true" ? "Overdue" : status : "--"}</td>
                                                            </tr>)})}
                                                        </tbody>
                                                    </table>
                                                </div></td>
                                        </tr>
                                      
                                        )
                                        }
                                        )}
                                    </tbody>
                                    <tbody>
                                        {dataArray?.length == 0 &&
                                            <tr>
                                                <td colSpan={10} className='text-center'>No Paid Transaction Data Available</td>
                                            </tr>
                                        }
                                    </tbody>
                                    </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {pagesCount > 0 && dataArray?.length > 0 && ValidPagination === true ?
            <>
                <div className="col-md-12">
                    <div className="card-body">
                        <PaginationComponent className="justify-content-center"
                            totalItems={totalPagesCount}
                            pageSize={10}
                            maxPaginationNumbers={3}
                            onSelect={(e) => setpagesCount(e)}
                        />
                    </div>
                </div>
            </>
            : ''}
        {pagesCount > 0 && dataArray?.length > 0 && ValidPagination === false ?
            <>
                <div className="col-md-12">
                    <div className="card-body">
                        <PaginationComponent className="justify-content-center"
                            totalItems={totalPagesCount}
                            pageSize={10}
                            maxPaginationNumbers={3}
                            onSelect={(e) => setpagesCount(e)}
                        />
                    </div>
                </div>
            </>
            : ''}
            </div>
        </div>
  )
}

export default Reconciliation