import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    publishShiftData: [],
    EmployersystemType: [],
    EmployerBusinessType: []
};

export const modalErrorSlice = createSlice({
    name: 'modalError',
    initialState,
    reducers: {
        setPublishShiftData: (state, action) => {
            // console.log({ state, action })
            state.publishShiftData = action.payload;
        },
        setEmployerSystemType: (state, action) => {
            state.EmployersystemType = action.payload;
        },
        setEmployerBusinessType: (state, action) => {
            state.EmployerBusinessType = action.payload;
        }
    },
});

export const { setPublishShiftData, setEmployerSystemType, setEmployerBusinessType } = modalErrorSlice.actions;

export default modalErrorSlice.reducer;
