
import { Link } from 'react-router-dom';

const Footer = () => {


    return (
        <>
            <footer className="footer5 footer--bw globalimg">
                <div className="footer__big">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                        <div className="widget text_widget"><img className="footer_logo" src="website/assets/img/logo-thirdroc.png" alt="logo" /></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="widget widget--links">
                            <ul className="links">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/know-us">Know Us</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3">
                        <div className="widget subcribe--widget">
                            <div className="widget__social">
                                <div className="social  ">
                                    <ul className="">
                                    <li><a href="https://www.linkedin.com/company/thirdroc/" className="linkedin" target="blank"><span className="fab fa-linkedin-in"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__small text-center">
                <p>©2022-23 ThirdRoc. All rights reserved.</p>
            </div>
            </footer>
            
        </>
    )
}

export default Footer;