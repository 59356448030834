
import Header  from '../website/header';
import Footer  from '../website/footer';
const Contact = () => {


    return (
        <>
         <Header/>
        <div className="back-contact pt-120 md-pt-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 md-mb-30">
                        <div className="contact-img"><img src="./website/assets/img/contact-img.jpg" alt="Contact Image" /></div>
                    </div>
                    <div className="col-lg-5">
                        <div className="contact-form-part">
                        <div className="back-sec-title mb-40 md-mb-30">
                            <h5 className="back-subtitle secondary-color mb-20">Contact Us</h5>
                            <h2 className="back-title mb-0">Let's get in touch <br/>only takes a minute.</h2>
                        </div>
                        <div id="form-messages"></div>
                        <form id="contact-form" action=""> 
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-field"><input type="text" placeholder="Name" id="name" name="name" required="" /></div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-field"><input type="email" placeholder="Email" id="email" name="email" required="" /></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-field"><input type="text" placeholder="Phone" id="phone_number" name="phone_number" required="" /></div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-field"><input type="text" placeholder="Subject" id="subject" name="subject" required="" /></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-field"><textarea placeholder="Your Message" rows="4" id="message" name="message" required=""></textarea></div>
                                    <div className="form-button"><button type="submit" className="back-btn secondary">Send Message</button></div>
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
            <div className="back-contact-box">
            <div className="container">
                <div className="back-sec-title text-center mb-50 md-mb-30">
                    <h5 className="back-subtitle white-color">Contact Us</h5>
                    <h2 className="back-title white-color">Get in touch by <br/>Selecting an Option.</h2>
                </div>
                <div className="row ">
                    <div className="offset-lg-2 wAuto"></div>
                    <div className="col-lg-4 col-md-6 sm-mb-30">
                        <div className="contact-box"><span className="icon-part bg-phone"><a href="tel:+800 567.890.576 "><img src="./website/assets/img/phone.svg" alt="Phone Icon" /></a></span><span className="text-part"><a href="tel:+800 567.890.576 ">+61 405 763 625</a></span></div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-box"><span className="icon-part primary-bg"><a href="mailto:support@thirdroc.com"><img src="./website/assets/img/chat.svg" alt="Phone Icon" /></a></span><span className="text-part"><a href="/contact">support@thirdroc.com</a></span></div>
                    </div>
                    <div className="offset-lg-2 wAuto"></div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Contact;