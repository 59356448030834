import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup';
import { postApi } from '../services/service';

const Verification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // const dispatch = useDispatch()
    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get('customer');
    const type = queryParams.get('type')
    const user_loan_id = queryParams.get('user_loan_id')
    const email = queryParams.get('email')
    // const queryParamss = new URLSearchParams(window.location.search)?.toString()
    // const type = new URLSearchParams(window.location.search).get('type')
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isRunning, setIsRunning] = useState(true);
    const [resendOTP, setResendOTP] = useState(false)
    const [handleButton, setHandleButton] = useState(false)
    const [count, setCount] = useState(0)
    const [handleinput, setHandleinput] = useState(false)
    const [showPassword, setshowPassword] = useState(false)
    const [showConfPassword, setshowConfPassword] = useState(false)
    const [handleCounter, sethandleCounter] = useState(false)



    const form = useFormik({
        initialValues: { otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', password: "", confirmedPassword: "" },
        enableReinitialize: true,
        validationSchema: yup.object({
            otp1: !handleinput ? yup.string().required('Please Enter Six digit OTP ')
                .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1) : "",
            otp2: !handleinput ? yup.string().when('otp1', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            otp3: !handleinput ? yup.string().when('otp2', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            otp4: !handleinput ? yup.string().when('otp3', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            otp5: !handleinput ? yup.string().when('otp4', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            otp6: !handleinput ? yup.string().when('otp5', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            password: handleinput ? yup.string().required('Please Enter Password') : "",
            confirmedPassword: handleinput ? yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("password"), null], "Password Must Be Same") : "",
        }),
        onSubmit: async (values) => {
            const data = {
                otp: `${values.otp1}${values.otp2}${values.otp3}${values.otp4}${values.otp5}${values.otp6}`,
                email: email
            }
            const endpoint = "/email-verify"
            let token = ""
            const response = await postApi(endpoint, token, data);
            if (response.status === true) {
                toast.success(response.message);
                form.setTouched({})
                form.setErrors({})
                form.validateForm();
                if (!response.isNewEmployee) {
                    let ewa_advance = {
                        ewa_advance_token: response.ewa_advance.token,
                    }

                    // localStorage.setItem('ewa_advance', JSON.stringify(ewa_advance));
                    navigate(`/ewa_advance/agreement?customer=${customerId}&type=${type}&user_loan_id=${user_loan_id}&email=${email}`, { state: { ewa_advance } })
                } else {
                    setHandleinput(true)
                    setResendOTP(true)
                }
            } else {
                setResendOTP(true)
                setHandleButton(false)
                // form.setTouched({})
                // form.setErrors({})
                // form.validateForm();
                // form.setFieldValue("otp1", "")
                // form.setFieldValue("otp2", "")
                // form.setFieldValue("otp3", "")
                // form.setFieldValue("otp4", "")
                // form.setFieldValue("otp5", "")
                // form.setFieldValue("otp6", "")
                toast.error(response.message);
                setHandleButton(false)
            }
        },
    })

    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else if (minutes > 0) {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                } else {
                    setIsRunning(false);
                    // setOtpVerify(0)
                    setResendOTP(true)
                    setHandleButton(false)
                    sethandleCounter(false)
                    if (count == 3) {
                        setCount(4)
                    }

                }
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, minutes, seconds]);

    const formatTime = () => {
        const formattedMinutes = minutes?.toString().padStart(2, '0');
        const formattedSeconds = seconds?.toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };


    const handleOTP = async () => {
        let token = ""
        try {
            const response = await postApi('/email-otp-send', token, {
                email: email,
            })
            if (response.status) {
                toast.success(response.message)
                setResendOTP(false)
                setHandleButton(false)
                setIsRunning(true);
                sethandleCounter(true)
                if (response.isOTPExpired) {
                    const minutesResponse = response.formattedTime;
                    const minutes = parseInt(minutesResponse.split(':')[0]);
                    const second = parseInt(minutesResponse.split(':')[1]);
                    setMinutes(minutes)
                    setSeconds(second)
                } else {
                    setMinutes(3)
                    setSeconds(0)
                }
            } else {
                setIsInvalidEmail(true)
                toast.error(response.message)
                setIsRunning(false)
            }

        } catch (error) {
            console.log(error.message)
        }
    }


    useEffect(() => {
        if (email) {
            handleOTP()
        }
    }, [])

    const eyeBtnStyle = {
        position: 'absolute',
        marginRight: '2px',
        marginTop: '0px',
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '250px',
        marginTop: '16px',
    }

    const handlepassword = async () => {
        if (form.values.password && form.values.confirmedPassword && form.values.password === form.values.confirmedPassword) {
            let token = ""
            const response = await postApi('/create-account', token, {
                email: email,
                password: form.values.password
            })
            if (response.status) {
                let ewa_advance = {
                    ewa_advance_token: response.ewa_advance.token,
                }
                navigate(`/ewa_advance/agreement?customer=${customerId}&type=${type}&user_loan_id=${user_loan_id}&email=${email}`, { state: { ewa_advance } })
            } else {
                toast.error(response.message)

            }
        }

    }

    const handleInput = (e, fieldName, nextFieldName) => {
        if (e.key === 'Backspace') {
            e.target.value = '';
            if (fieldName !== 'otp1') {
                const prevFieldName = getPreviousFieldName(fieldName);
                document.querySelector(`input[name="${prevFieldName}"]`)?.focus();
            }
        } else if (e.target.value.length > 1) {
            e.target.value = e.target.value.slice(0, 1);
        } else if (/^\d?$/.test(e.target.value)) {
            form.setFieldValue(fieldName, e.target.value);
            if (e.target.value.length === 1 && nextFieldName) {
                document.querySelector(`input[name="${nextFieldName}"]`).focus();
            }
        }
    };

    const getPreviousFieldName = (fieldName) => {
        switch (fieldName) {
            case 'otp1':
                return 'otp1';
            case 'otp2':
                return 'otp1';
            case 'otp3':
                return 'otp2';
            case 'otp4':
                return 'otp3';
            case 'otp5':
                return 'otp4';
            case 'otp6':
                return 'otp5';
            default:
                return null;
        }
    };

    return (

        <div className="page">
            <div className="page-single bg-back" /*  */ /* style={{ backgroundColor: '#eae6e6' }} */>
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto">
                            <div className="row justify-content-center mb-2">
                                <div className="col-xl-11 col-lg-12">
                                    <div className="row p-0 m-0 bg-white br-3">
                                        <div className="col-lg-5 p-0">
                                            <div className="text-justified text-white  register-2 overflow-hidden" style={{ padding: '1rem 3rem 4rem 3rem' }}>
                                                <div className="custom-content">
                                                    <div className="mb-2 br-1">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc1.png"} className="header-brand-img loginlogo" alt="logo" />
                                                    </div>

                                                    <div className="fs-22 mb-4 font-weight-bold text-white"> Start Your Remarkable Journey With Us ! </div>
                                                    <div className="mb-2 br-1 text-center">
                                                        <img src="/assets/img/slider5.png" className="" alt="logo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !isInvalidEmail
                                                ? <div className="col-md-8 col-lg-7 p-0 mx-auto">
                                                    <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                                                        <div className="form-group mt-4 d-flex justify-content-center">
                                                            <div className="text-center fs-20" style={{ marginLeft: '2rem' }}>
                                                                <i className="fas fa-lock fa-fw" /> Great! Almost Done

                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <form className="row lendersignup" id='registrationForm' onSubmit={form.handleSubmit}>
                                                                <div className="text-center mb-4">
                                                                    <span><i class="fa-solid fa-envelope"></i> Enter the 6-digit code sent to your email ID</span>

                                                                </div>
                                                                {!handleinput ?
                                                                    <>
                                                                        <div className="d-flex justify-content-center">
                                                                            <input type="number" className="form-control col-md-1 mb-4" name="otp1"
                                                                                // onInput={e => {
                                                                                //     if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                //         form.setFieldValue("otp1", e.target.value);
                                                                                //         if (e.target.value.length === 1) {
                                                                                //             document.querySelector('input[name="otp2"]').focus();
                                                                                //         }
                                                                                //     }
                                                                                // }}
                                                                                onInput={(e) => handleInput(e, "otp1", "otp2")}
                                                                                maxLength="1"
                                                                                onWheel={(e) => e.target.blur()}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                    if (e.key === 'Backspace') {
                                                                                        handleInput(e, "otp1", null);
                                                                                    }
                                                                                }}

                                                                            />
                                                                            <input type="number" name="otp2"
                                                                                className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={(e) => handleInput(e, "otp2", "otp3")}
                                                                                maxLength="1"
                                                                                onWheel={(e) => e.target.blur()}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                    if (e.key === 'Backspace') {
                                                                                        handleInput(e, "otp2", "otp1");
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp3"
                                                                                className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={(e) => handleInput(e, "otp3", "otp4")}
                                                                                maxLength="1"
                                                                                onWheel={(e) => e.target.blur()}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                    if (e.key === 'Backspace') {
                                                                                        handleInput(e, "otp3", "otp2");
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp4"
                                                                                className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={(e) => handleInput(e, "otp4", "otp5")}
                                                                                maxLength="1"
                                                                                onWheel={(e) => e.target.blur()}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                    if (e.key === 'Backspace') {
                                                                                        handleInput(e, "otp4", "otp3");
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp5"
                                                                                className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={(e) => handleInput(e, "otp5", "otp6")}
                                                                                maxLength="1"
                                                                                onWheel={(e) => e.target.blur()}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                    if (e.key === 'Backspace') {
                                                                                        handleInput(e, "otp5", "otp4");
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp6"
                                                                                className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={(e) => handleInput(e, "otp6")}
                                                                                maxLength="1"
                                                                                onWheel={(e) => e.target.blur()}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                    if (e.key === 'Backspace') {
                                                                                        handleInput(e, "otp6", "otp5");
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginLeft: '195px' }}>
                                                                            {Object.values(form.errors).some((error) => error) && (
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                    Please Enter Six digit OTP
                                                                                </div>
                                                                            )}
                                                                        </div></> :
                                                                    <>
                                                                        <div className="input-group" style={{ marginLeft: '10rem' }}>
                                                                            <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true" /> </div>
                                                                            <input className="form-control" name="password" {...form.getFieldProps("password")} type={showPassword ? "text" : "password"} placeholder="Please enter password" id="password-field" autoComplete="off" />
                                                                            {showPassword ?
                                                                                <span toggle="#password-field" onClick={() => setshowPassword(false)} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                                :
                                                                                <span toggle="#password-field" onClick={() => setshowPassword(true)} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                            }
                                                                            {form.touched.password && form.errors.password ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                                                                        </div>
                                                                        <div className="input-group mt-3" style={{ marginLeft: '10rem' }}>
                                                                            <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true" /> </div>
                                                                            <input className="form-control" {...form.getFieldProps("confirmedPassword")} type={showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />

                                                                            {showConfPassword ?
                                                                                <span toggle="#password-field" onClick={() => setshowConfPassword(false)} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                                :
                                                                                <span toggle="#password-field" onClick={() => setshowConfPassword(true)} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                                                            }
                                                                            {form.touched.confirmedPassword && form.errors.confirmedPassword ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirmedPassword}</div> : ''}
                                                                        </div>
                                                                    </>

                                                                }
                                                                {!handleinput && handleCounter && <div className='text-center mt-3'>
                                                                    <b>Time remaining: {formatTime()}</b>
                                                                </div>}
                                                                {resendOTP && count <= 2 && !handleinput && minutes == "00" && seconds == "00" && <div className='text-primary d-flex justify-content-center'
                                                                    style={{ cursor: 'pointer' }}
                                                                    disabled={handleButton}
                                                                    onClick={() => { handleOTP(); setCount(count + 1) }}>Resend OTP</div>
                                                                }
                                                                <div type="submit" className="form-group text-center mb-3 mt-3">
                                                                    {!handleinput ? <button type='submit' className="btn btn-primary btn-lg w-60 br-7" disabled={handleButton}>
                                                                        <i className="fas fa-check fa-fw" /> Verify
                                                                    </button> :
                                                                        <button type='submit' className="btn btn-primary btn-lg w-60 br-7" onClick={() => handlepassword()} >
                                                                            <i className="fas fa-check fa-fw" /> Register
                                                                        </button>
                                                                    }
                                                                    {resendOTP && count == 4 && !handleinput && seconds == "00" &&
                                                                        <div className="text-primary mt-3">Note: Limit exceeded. Go back to your email ID to try again.</div>
                                                                    }
                                                                </div>
                                                                {/* <div type="submit" className="form-group text-center mb-3 mt-3"> <button type='submit' className="btn btn-primary btn-lg w-100 br-7">Verify</button> </div> */}
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className="col-md-8 col-lg-7 p-0 mx-auto d-flex justify-content-center align-items-center text-center">
                                                    <div className=''>
                                                        <h4 className='' style={{ fontSize: "3rem" }}>401</h4>
                                                        <h4 className=''>Unauthorized Request</h4>
                                                        <h4 className='text-primary'>Go Back to your Gmail Account and Click on Proceed Button</h4>
                                                    </div>
                                                </div>
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Verification