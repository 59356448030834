import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbsecond } from '../../../components';
import { getApi, postApi } from '../../service/lenderService';


const Addrole = () => {
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const navigate = useNavigate()
    const lender = JSON.parse(localStorage.getItem("lender"))
    const token = lender.lender_token
    const [EmployeeView, setEmployeeView] = useState()
    const [existing_employee_view, setexisting_employee_view] = useState()
    const [merchant_pending_view, setmerchant_pending_view] = useState()
    const [merchant_paid_view, setmerchant_paid_view] = useState()
    const [thirdroc_pending_view, setthirdroc_pending_view] = useState()
    const [thirdroc_paid_view, setthirdroc_paid_view] = useState()
    const [view_loan_application, setview_loan_application] = useState()
    const [view_loan_status, setview_loan_status] = useState()
    const [status, setStatus] = useState([])
    const [lenderData, setLenderData] = useState([]);

    const updateEmployeeData = (e, key) => {
        // console.log(e.target.value)
        const checkboxValue = e.target.value;
        const isChecked = e.target.checked;
        if (key === "new_employee_view") {
            setEmployeeView(isChecked);
        }
        if (key === "existing_employee_view") {
            setexisting_employee_view(isChecked)
        }
        if (key === "merchant_pending_view") {
            setmerchant_pending_view(isChecked)
        }
        if (key === "merchant_paid_view") {
            setmerchant_paid_view(isChecked)
        }
        if (key === "thirdroc_pending_view") {
            setthirdroc_pending_view(isChecked)
        }
        if (key === "thirdroc_paid_view") {
            setthirdroc_paid_view(isChecked)
        }
        if (key === "merchant_paid_view") {
            setmerchant_paid_view(isChecked)
        }
        if (key === "view_loan_application") {
            setview_loan_application(isChecked)
        }
        if (key === "view_loan_status") {
            setview_loan_status(isChecked)
        }
        if (isChecked) {
            setLenderData((prevData) => [...prevData, { value: checkboxValue, key }]);
        } else {
            setLenderData((prevData) => prevData.filter(item => item.value !== checkboxValue));
        }
    };

    const form = useFormik({
        initialValues: {
            title: "",
            permission_key: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Please Enter Role Title')
                .matches(/[a-zA-Z0-9]/, 'Title Must Contain At Least One Alphanumeric Character')
                .test('noOnlySpaces', 'Title cannot be empty or contain only spaces', value => {
                    return value && value.trim() !== ''; // Add a check for value existence
                })
                .required('Please Enter Role Title')

        }),
        onSubmit: async (values) => {
            const response = await postApi("/add-lender-role-permission", {
                title: values.title,
                permissions: lenderData
            })
            console.log(response)
            if (response.status == true) {
                toast.success(response.message)
                setTimeout(() => {
                    navigate(prefix + '/manage_role')
                }, 1000)
            } else if (response.status == false) {
                console.log(response)
                toast.error(response.message)
            }
        }
    })


    const getStatus = async (token) => {
        const response = await getApi(`/get-loan-status-list`);
        if (response.status == true) {
            setStatus(response.data.rows);
        } else {
            console.log("get data response", response);
        }
    }

    useEffect(() => {
        getStatus(token);
    }, [])

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">
                        {/* <Breadcrumbs order={{ first: { name: "User Management" } }} /> */}
                        <Breadcrumbsecond order={{ first: { name: "Manage Role", path: `${prefix}/manage_role` }, second: { name: "Add Role"/* ,path:`${back}` */ } }} />

                    </h4>
                </div>
            </div>
            <form className="row" id='registerForm' onSubmit={form.handleSubmit}>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Title</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Role Title" {...form.getFieldProps("title")} />
                                </div>
                                {form.touched.title && form.errors.title ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.title}</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label>Borrower
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "new_employee_view")} />
                                <span className="custom-control-label">View New Borrower</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"
                                    disabled={!EmployeeView}
                                    value="true" onChange={(e) => updateEmployeeData(e, "new_employee_limit")} />
                                <span className="custom-control-label">Manage Limit</span>
                            </label>
                            {/* <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"  onChange={(e) => updateEmployeeData(e,"repayment_list_export")}  />
                                <span className="custom-control-label">Export CSV</span>
                            </label> */}
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "existing_employee_view")} />
                                <span className="custom-control-label">View Existing Borrower</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"
                                    disabled={!existing_employee_view}
                                    value="true" onChange={(e) => updateEmployeeData(e, "existing_employee_limit")} />
                                <span className="custom-control-label">Manage Limit</span>
                            </label>
                            {/* <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"  onChange={(e) => updateEmployeeData(e,"repayment_list_export")}  />
                                <span className="custom-control-label">Export CSV</span>
                            </label>        */}

                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Pay To Merchant
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "merchant_pending_view")} />
                                <span className="custom-control-label">View Pending</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!merchant_pending_view}
                                    onChange={(e) => updateEmployeeData(e, "merchant_pending_settlement_view")} />
                                <span className="custom-control-label">View Pending Settlement</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"
                                    disabled={!merchant_pending_view}
                                    value="true" onChange={(e) => updateEmployeeData(e, "merchant_add_settlement")} />
                                <span className="custom-control-label">Add Settlement</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "merchant_paid_view")} />
                                <span className="custom-control-label">View Paid</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"
                                    disabled={!merchant_paid_view}
                                    value="true" onChange={(e) => updateEmployeeData(e, "merchant_update_paid_txn")} />
                                <span className="custom-control-label">Update Paid TXN</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"
                                    disabled={!merchant_paid_view}
                                    value="true" onChange={(e) => updateEmployeeData(e, "merchant_paid_settlement")} />
                                <span className="custom-control-label">View Settlement</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Pay To Thirdroc
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "thirdroc_pending_view")} />
                                <span className="custom-control-label">View Pending</span>
                            </label>
                            {/* <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e,"thirdroc_pending_settlement_view")}  />
                                <span className="custom-control-label">View Pending Settlement</span>
                            </label> */}
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!thirdroc_pending_view}
                                    onChange={(e) => updateEmployeeData(e, "thirdroc_add_settlement")} />
                                <span className="custom-control-label">Add Settlement</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "thirdroc_paid_view")} />
                                <span className="custom-control-label">View Paid</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!thirdroc_paid_view}
                                    onChange={(e) => updateEmployeeData(e, "thirdroc_update_paid_txn")} />
                                <span className="custom-control-label">Update Paid TXN</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"
                                    disabled={!thirdroc_paid_view}
                                    value="true" onChange={(e) => updateEmployeeData(e, "thirdroc_paid_settlement")} />
                                <span className="custom-control-label">View Settlement</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Repayments
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "repayment_view_pending")} />
                                <span className="custom-control-label">View Pending</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "repayment_view_paid")} />
                                <span className="custom-control-label">View Paid</span>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>Manage EWA Advance Application
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "view_loan_application")} />
                                <span className="custom-control-label">View EWA Advance Application</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!view_loan_application}
                                    onChange={(e) => updateEmployeeData(e, "add_loan_application")} />
                                <span className="custom-control-label">Add EWA Advance Application  </span>
                            </label>
                            {/* <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"  value="true" onChange={(e) => updateEmployeeData(e,"edit_loan_application")} />
                                <span className="custom-control-label">Edit Loan Application</span>
                            </label> */}

                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"
                                    disabled={!view_loan_application}
                                    value="true" onChange={(e) => updateEmployeeData(e, "manage_loan_application")} />
                                <span className="custom-control-label">Manage EWA Advance Status</span>
                            </label>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label>Eligible EWA Advance Status
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            {
                                status?.map((item, index) => {
                                    return (
                                        <label key={index} className="custom-control custom-checkbox diclamerpage">
                                            <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, `${item.status_id}`)} />
                                            <span className="custom-control-label">{item.status_name}</span>
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label>Manage EWA Advance Status
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "view_loan_status")} />
                                <span className="custom-control-label">View EWA Advance Status</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!view_loan_status}
                                    onChange={(e) => updateEmployeeData(e, "add_loan_status")} />
                                <span className="custom-control-label">Add EWA Advance Status  </span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx"
                                    disabled={!view_loan_status}
                                    value="true" onChange={(e) => updateEmployeeData(e, "edit_loan_status")} />
                                <span className="custom-control-label">Edit EWA Advance Status</span>
                            </label>
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true"
                                    disabled={!view_loan_status}
                                    onChange={(e) => updateEmployeeData(e, "delete_loan_status")} />
                                <span className="custom-control-label">Delete EWA Advance Status</span>
                            </label>

                        </div>
                    </div>
                    <div className="row mb-3">
                        <label>ROI(Return Of Investment)
                        </label>
                        <div className="custom-controls-stacked d-flex mb-3">
                            <label className="custom-control custom-checkbox diclamerpage">
                                <input type="checkbox" className="custom-control-input NewCHeckBx" value="true" onChange={(e) => updateEmployeeData(e, "roi_paid_view")} />
                                <span className="custom-control-label">View </span>
                            </label>

                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary mb-6 w-md mt-1">Add</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Addrole


