import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getApi } from '../service/MerchantService';
import Loader from '../../components/Loader/loader';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import toast from 'react-hot-toast';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const Dashboard = () => {
	const merchant = JSON.parse(localStorage.getItem("merchant"));
	const merchantUserId = merchant.user_type;
	const [loader, setloader] = useState(true)
	const [merchantTransactions, setMerchantTransactions] = useState('');
	const [totalSettlement, setTotalSettlement] = useState('');
	const [total_counter, settotal_counter] = useState('')
	const [totalStores, setTotalStores] = useState('');
	const [days, setDays] = useState("365")
	const [Graph, setGraph] = useState([]);
	const [customDate, setCustomDate] = useState(false)
	const [label, setLabels] = useState([])
	const [startDate, setstartDate] = useState("")
	const [endDate, setendDate] = useState("")
	const navigate = useNavigate()
	const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
	const [dataArray, setDataArray] = useState([]);

	const getGraphData = async (token) => {
		const response = await getApi(`/transaction-and-income-data`, { days: days, start_date: startDate, end_date: endDate, time_zone: date_Format });

		if (response.status == true) {
			setTimeout(() => {
				setMerchantTransactions(response?.merchantTransctions)
				setTotalSettlement(response?.totalMerchantSettalment)
				setTotalStores(response?.totalStores)
				settotal_counter(response?.total_counter)
				setGraph(response?.data?.rows)
				setLabels(response?.data?.labels)
				setloader(false)

			}, 2000);
			setloader(true)

		} else if (response.message == 'Session expired') {
			localStorage.removeItem("merchant");
			navigate('/merchant/login')

		} else {
			console.log("get employees data response", response);
		}
	}
	useEffect(() => {
		getGraphData(merchant.merchant_token)
	}, [days])

	const option = {
		indexAxis: 'x',
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				// text: 'Chart Title',
			},
		},
	};

	let labels
	// const labels = ['January', 'February', 'March', 'April', 'January', 'June', 'July', 'Aug', 'Sep', "oct", 'nov', 'dec']
	if (label.length > 0) {
		labels = label
	} else /* if (label.length == 0) */ {
		labels = Graph.map((graph) => graph.created_at)
	}

	const data = {
		labels,
		datasets: [
			{
				label: 'Transactions',
				// data: [10, 12, 13, 14, 25, 40, 87],
				data: Graph.map((graph) => graph.total_txn),
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: 'Income',
				data: Graph.map((graph) => graph.amount),
				// data: [10, 19, 18, 1, 85, 47, 8],
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
		],
	};

	const transction = async (token) => {
		const response = await getApi(`/merchant-transctions`, { store_id: "", counter_id: "", page_number: 1, sortby: "updated_at", orderby: 'desc' });

		try {
			if (response.status == true) {
				setDataArray(response.data)
			} else if (response.message == 'Session expired') {
				localStorage.removeItem("merchant");
				navigate('/merchant/login')
			}
			else {
				console.log(response);
			}
		} catch (error) {
			console.log(error)
		}
	}
	const copiedInfo = () => {
		toast.success("Transaction No. copied Successfully")
	}

	useEffect(() => {
		transction(merchant.merchant_token)
	}, [])

	return (
		<div className="main-container container-fluid px-0">
			<div className="page-header">
				<div className="page-leftheader">
					<h4 className="page-title mb-0 text-primary">Dashboard</h4>
				</div>
				<div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
					<div className="form-group">
						<label className="form-label">Select Days</label>
						<div className="row g-xs">
							<div className="wd-200 mg-b-30">
								<div className="input-group">
									<select className="form-control mb-0" name="loan_type"
										onChange={(e) => {
											if (e.target.value !== "custom") {
												setDays(e.target.value)
												setCustomDate(false)
												setstartDate("")
												setendDate("")
											}
											else {
												setCustomDate(true)
											}
										}}
									>
										<option>Select Days</option>
										<option value="7">Weekly</option>
										<option value="30">Monthly</option>
										<option value="365">Yearly</option>
										<option value="custom">Custom Date</option>
									</select>
									{customDate && (
										<DateRangePicker
											onApply={(event, picker) => {
												setstartDate(picker.startDate.format('YYYY-MM-DD'))
												setendDate(picker.endDate.format('YYYY-MM-DD'))
												setDays("custom")
												// const selectedRange = `${startDate} to ${endDate}`;
												//   setDateRange(selectedRange);												
											}}
											initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}
										>
											<input
												placeholder="Search By Date"
												className="form-control fc-datepicker hasDatepicker"
												type="text"
												defaultValue=""
											/>
										</DateRangePicker>
									)}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
			</div>
			{loader == true ?
				<div style={{ marginLeft: '400px', marginTop: '200px' }}  >
					<Loader /></div>
				:
				<>

					<div className="row">

						{merchantUserId == 'MERCHANT' && <div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Settlement</p>
									<h2 className="mb-1 font-weight-bold">{totalSettlement ? totalSettlement : 0}</h2>
									{(totalSettlement !== 0 && totalSettlement !== null) && (<span className="mb-1"><Link to={'/merchant/settlement'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>}
						{merchantUserId == 'MERCHANT' &&
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Stores</p>
										<h2 className="mb-1 font-weight-bold">{totalStores ? totalStores : 0}</h2>
										{(totalStores !== 0 && totalStores !== null) && (<span className="mb-1"><Link to={'/merchant/store_list'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
										)}</div>
								</div>
							</div>}
						{/* {merchantUserId == 'MERCHANT' &&
					 <div className="col-xl-3 col-lg-6 col-md-12">
						<div className="card">
							<div className="card-body">
								<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
								<p className="fs-13 mb-1">Total Counter</p>
								<h2 className="mb-1 font-weight-bold">{total_counter ? total_counter : 0}</h2>
								<span className="mb-1"><Link to={'/merchant/counter'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
							</div>
						</div>
					</div>} */}
						{(merchantUserId == 'STORE' || merchantUserId == 'MERCHANT') &&
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div className="card">
									<div className="card-body">
										<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
										<p className="fs-13 mb-1">Total Counter</p>
										<h2 className="mb-1 font-weight-bold">{total_counter ? total_counter : 0}</h2>
										{(total_counter !== 0 && total_counter !== null) && (<span className="mb-1"><Link to={'/merchant/counter'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
										)}</div>
								</div>
							</div>}
						<div className="col-xl-3 col-lg-6 col-md-12">
							<div className="card">
								<div className="card-body">
									<svg className="card-custom-icon header-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
									<p className="fs-13 mb-1">Total Transactions</p>
									<h2 className="mb-1 font-weight-bold">{merchantTransactions ? merchantTransactions : 0}</h2>
									{(merchantTransactions !== 0 && merchantTransactions !== null) && (<span className="mb-1"><Link to={'/merchant/transactions'} className="text-azure"><i className="fa fa-eye  me-1"></i> View Detail</Link></span>
									)}</div>
							</div>
						</div>
					</div>
					<div className="row">

						<div className='col-xl-6 col-lg-6 col-md-12'>
							<div className="table-responsive">
								<div className="horizontal-scroll">
									<table className="table table-hover card-table table-vcenter text-nowrap ">
										<thead className="border-bottom-0 pt-3 pb-3">
											<tr>
												<th className="font-weight-bold">TXN No.   </th>
												<th className="font-weight-bold">Merchant    </th>
												<th className="font-weight-bold"> Settled Amount   </th>
												<th className="font-weight-bold">TXN Date  </th>
											</tr>
										</thead>
										{loader == true ? <tbody>
											<td /><td />
											<Loader />
										</tbody> :
											<>
												<tbody>
													{dataArray && dataArray.length > 0 && dataArray.map((option, index) => {
														const { txn_id, is_settled } = option;
														return (
															<tr key={index}>
																<td> <span className="font-weight-normal1">{option.txn_id ? txn_id : "NA"}</span>
																	<span>
																		<CopyToClipboard text={txn_id}>
																			<button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
																		</CopyToClipboard>
																	</span>
																</td>
																<td><span className="font-weight-normal1">{option?.merchantDetail?.company_name ? option.merchantDetail?.company_name : "NA"}</span></td>
																<td><span className="font-weight-normal1">{is_settled == '1' ? "$" + option.settled_amount : "Pending"}</span></td>
																<td><span className="font-weight-normal1">{TimeZone(option.created_at).timeZone}</span></td>														</tr>
														)
													}
													)}
												</tbody>
												<tbody>
													{dataArray.length == 0 &&
														<tr>
															<td colSpan={10} className='text-center'>No Data Available</td>
														</tr>
													}
												</tbody>
											</>
										}
									</table>
								</div>
							</div>
						</div>
						<div className='col-xl-6 col-lg-6 col-md-12'>
							<div className="card-body" /* style={{ backgroundColor: 'whitesmoke' }} */>
								<Line options={option} data={data} />
							</div>
						</div>
					</div>
				</>
			}
		</div>

	)
}

export default Dashboard;