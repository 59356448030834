import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import toast from "react-hot-toast";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EXCEL_EMPLOYEE_TEMPLATE } from '../../../constant';
import * as XLSX from 'xlsx';
import { uploadEmployees } from '../../service/employService';
import { SampleTemplete } from '../../../components/Base64Templete/sampleTemplete';

const UploadCases = () => {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [excelFile, setExcelFile] = useState(null);
    const [fileToupload, setFileToUpload] = useState('');
    const [pagesCount, setpagesCount] = useState(1);
    const [excel, setExcel] = useState();
    const [loader, setloader] = useState(false)
    const handleclicktoast = () => {
        setloader(true)
    }
    const readExcel = (file) => {
        // let selectedFile = e.target.files[0];
        if (file) {
            handleclicktoast()
            setFileToUpload(file)
            uploadExcelForm.setFieldValue('isExcelFile', true)

            // uploadEmployees
            const promise = new Promise((resolve, reject) => {
                // console.log('file.type', file.type);
                if (file) {
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(file);

                    reader.onload = (e) => {
                        const bufferArray = e.target.result;
                        // setExcelFileError(null);
                        const workBook = XLSX.read(bufferArray, { type: 'buffer' });
                        const workSheetName = workBook.SheetNames[0];
                        const workSheet = workBook.Sheets[workSheetName];
                        const data = XLSX.utils.sheet_to_json(workSheet);
                        // const data2 = XLSX.utils.s
                        // setExcelFile(e.target.result)
                        resolve(data)

                    }
                    reader.onerror = (error) => {
                        reject(error)
                    }
                }
            })
            promise.then((d) => {

                setExcelFile(d)
                setloader(false)
                console.log('json data ', d);
            })

        } else {
            console.log('please select your file');
            // setExcelFileError('please select only excelfile')
        }
    }


    const uploadExcelForm = useFormik({
        initialValues: {
            // employer_id: '',
            isExcelFile: false,
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // employer_id: yup.string().required('Please enter Amount'),
            isExcelFile: yup.boolean().oneOf([true], ""),

        }),

        onSubmit: async (values) => {
            if (fileToupload) {

                const formData = new FormData();
                formData.append("file", fileToupload);
                const employer_id = JSON.parse(localStorage.getItem("employer"));
                // const response = await postApi(" /bulk-employee-upload", formData, true);
                const response = await uploadEmployees(formData, employer_id.employer_token);
                console.log(response)
                if (response.data.alreadyexists) {
                    // console.log("helloooooo")
                }
                //   console.log(response.data.alreadyexists)
                if (response.status) {
                    //console.log('inside abhi', response);
                    toast.success(response.message + " & " + response.data.alreadyexistsCount + " Already Exist");
                    navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/employees');
                } else {
                    // console.log('inside abhi', response);
                    toast.error(response.message);
                }
            }

        },
    })

    const handleInputChange = (event) => {
        setExcel(event.target.files[0]);
        console.log("file ===>>>", event.target.files[0])
    }

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/employer/employees" >Employee</Link></li>
                                    <li className="breadcrumb-item " aria-current="page"><Link className='breadcrumbslink' to="/employer/add_employee" >Add Employee</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Upload Bulk Employees</li>
                                </ol>
                            </nav>
                        </h4>
                        {/* <h4 className="page-title mb-0 text-primary">Upload Bulk Employees</h4> */}
                    </div>
                    <div className="page-rightheader">
                        <div className="btn-list">
                            <button onClick={() => SampleTemplete(EXCEL_EMPLOYEE_TEMPLATE, 'employee-template.xlsx')} className="btn btn-primary btn-pill">
                                <i className="fa fa-download me-2"></i>{/* Download Employee */}Sample Template</button>
                        </div>
                    </div>
                </div>
                <form className="mt-5 row" onSubmit={uploadExcelForm.handleSubmit} >
                    {uploadExcelForm.values.wrong_message ?
                        <div className="invalid-feedback mb-3 mt-2" style={{ display: "block", textAlign: "center" }}>{uploadExcelForm.values.wrong_message}</div>
                        : ''}
                    <div className="row">
                        <div className="col-12">
                            <div className="uploader">
                            </div>
                            <div >
                                <div className="page-rightheader">
                                    <div className="btn-list">
                                        <button className="btn btn-outline-primary"><label htmlFor="formFile">
                                            <i className="fa fa-upload fa-beat me-2"></i>
                                        </label><input style={{ marginLeft: '10px' }} type="file" id='formFile'
                                            onChange={(e) => {
                                                const file = e.target.files[0]; readExcel(file);
                                            }} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {loader != true ?
                                <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1">  Save and Update</button> :
                                <button className="btn btn-success mb-6 w-md mb-1 mt-1">  Please Wait......</button>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Sr No.</th>
                                            <th className="font-weight-bold">First Name</th>
                                            <th className="font-weight-bold">Last Name</th>
                                            <th className="font-weight-bold">Country</th>
                                            <th className="font-weight-bold">Mobile Number</th>
                                            <th className="font-weight-bold">Employee Code</th>
                                            <th className="font-weight-bold">Pay Cycle</th>
                                            <th className="font-weight-bold">Monthly Salary</th>
                                            <th className="font-weight-bold">Department</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {excelFile && excelFile.length > 0 && excelFile.map((option, index) => {
                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                            return (
                                                <tr key={serial_num} >
                                                    <td><span className="font-weight-normal1">{serial_num}</span></td>
                                                    <td><span className="font-weight-normal1">{option.FirstName ? option.FirstName : "--"}</span></td>
                                                    <td><span className="font-weight-normal1">{option.LastName ? option.LastName : "--"}</span></td>
                                                    <td><span className="font-weight-normal1">{option.Country ? option.Country : "--"}</span></td>
                                                    <td><span className="font-weight-normal1">{option.Mobile ? option.Mobile : "--"}</span></td>
                                                    <td><span className="font-weight-normal1">{option.EmployeeCode ? option.EmployeeCode : "--"}</span></td>
                                                    <td><span className="font-weight-normal1">{option.PayCycle ? option.PayCycle : "--"}</span></td>
                                                    <td><span className="font-weight-normal1">{option.MonthlySalary ? "$" + option.MonthlySalary : "--"}</span></td>
                                                    <td><span className="font-weight-normal1">{option.Department ? option.Department : "--"}</span></td>

                                                </tr>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>

    )
}
export default UploadCases;