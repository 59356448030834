import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Service from '../../adminService';
import toast, { Toaster } from 'react-hot-toast';
import { postApi } from '../service/adminService';
const api = new Service();

const Changepassword = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();

  // const Form = useFormik({
  //     initialValues: {
  //       old_password: "",
  //       password: "",
  //       confirm_password: "",
  //       user_id: "",
  //       wrong_message: ""
  //     },
  //     enableReinitialize: true,
  //     validationSchema: yup.object({
  //       old_password: yup.string().required('Please enter old_password'),
  //       password: yup.string().required('Please enter new_password'),
  //       confirm_password: yup.string().required('Please enter confirm_password'),
  //     }),
  //     onSubmit: values => {
  //       console.log('values', values);
  //       console.log('inside edit Form');
  //       api.postApi('admin/changePassword', values).then(response => {    
  //         if (response.status === true) {
  //           toast.success(response.message);            
  //         }
  //       }).catch(error => {
  //         console.log('error', error);           
  //       });
  //     }
  //   });
  const Form = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
      // user_id: "",
      wrong_message: ""
    },
    validationSchema: yup.object({
      old_password: yup.string().required('Please enter old_password'),
      new_password: yup.string().required('Please enter  New Password'),
      confirm_password: yup.string().required('Please enter confirm_password'),
    }),
    onSubmit: async values => {
      console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
      // const data = JSON.stringify({
      //   old_password:values.old_password,
      //   new_password: values.new_password,
      //   confirm_password: values.confirm_password,     
      //   wrong_message: ""
      // })
      // console.log(data)

      // const response = await Adminchangepassword(admin.admin_token,data);
      const response = await postApi("/changePassword", {
        old_password: values.old_password,
        new_password: values.new_password,
        confirm_password: values.confirm_password,
        wrong_message: ""
      });

      // console.log("#@#@#", response);

      if (response.status == false) {
        toast.error(response.message);
      }
      if (response.status === true) {
        toast.success(response.message);
        localStorage.removeItem("admin");
        // localStorage.clear();
        navigate('/admin/login')

      }
    }
  });



  return (
    <>
      <div className="main-container container-fluid px-0">
        <div className="page-header">
          <div className="page-leftheader">
            <h4 className="page-title mb-0 text-primary">Change Password</h4>
          </div>
        </div>
        <form className="row" id='registerForm' onSubmit={Form.handleSubmit}>
          {Form.values.wrong_message ?
            <div className="invalid-feedback mb-3 mt-2" style={{ display: "block", textAlign: "center" }}>{Form.values.wrong_message}</div>
            : ''}
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Old Password</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" {...Form.getFieldProps("old_password")} className="form-control" placeholder="Enter Old Password" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">New Password</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" {...Form.getFieldProps("new_password")} className="form-control" placeholder="Enter new Password" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
              <div className="form-group">
                <label className="form-label">Confirm New Password</label>
                <div className="row g-xs">
                  <div className="input-group">
                    <input type="text" {...Form.getFieldProps("confirm_password")} className="form-control" placeholder="Enter Confirm Password" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <button type="submit" className="btn btn-primary mb-6 w-md mb-1 mt-1"> Save </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Changepassword