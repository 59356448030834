import React from 'react'

const Limitfilter = ({ Entrieslimit, setEntrieslimit, setpagesCount, setValidPagination, ValidPagination }) => {
    return (
        <div className="col-xl-4 col-lg-4 col-md-4 col-xm-12" style={{ width: '200px' }}>
            <div className="form-group">
                <label className="form-label">Select Limit</label>
                <div className="input-group">
                    <select className="form-control mb-0" name="loan_type" value={Entrieslimit} onChange={(e) => { setEntrieslimit(e.target.value); setpagesCount("1"); setValidPagination(!ValidPagination) }}  >
                        <option value="10">Show 10 Entries</option>
                        <option value="50">Show 50 Entries</option>
                        <option value="100">Show 100 Entries</option>
                        <option value="250">Show 250 Entries</option>
                        <option value="500">Show 500 Entries</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default Limitfilter